/**
 * Copyright 2021-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  Frequency,
  PolicyType,
  RetentionCondition,
} from "../../../generated/graphql";

export const TYPES = [
  {
    value: PolicyType.Rpo,
    label: "RPO",
  },
  {
    value: PolicyType.TimeAware,
    label: "Time aware",
  },
];

export const CONDITIONS = [
  {
    value: RetentionCondition.TimeInDays,
    label: "Time in days",
  },
  {
    value: RetentionCondition.Quantity,
    label: "Quantity",
  },
];

export const FREQUENCIES = [
  {
    value: Frequency.Hours,
    label: "Hours",
  },
  {
    value: Frequency.Days,
    label: "Days",
  },
  {
    value: Frequency.Weeks,
    label: "Weeks",
  },
  {
    value: Frequency.Months,
    label: "Months",
  },
];
