/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from "react";
import { notesData } from "./Notes";
import { Link } from "react-router-dom";
import { ROUTES } from "../../routes/routes";
import slugify from "@sindresorhus/slugify";

const ReleaseNotesList: React.FC = () => {
  return (
    <div className="section">
      <div className="box">
        <p className="title is-4 is-capitalized">Release Notes</p>
        {Notes.map((Note, i) => (
          <div className="container is-fluid" key={`note-${i}`}>
            <Note />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReleaseNotesList;

export const Notes = notesData.map((note) => () => (
  <div style={{ margin: "2rem" }} key={note.date}>
    <p className="title is-5 is-capitalized">
      <Link to={ROUTES.notes.note.generate({ id: slugify(note.title) })}>
        {note.title}
      </Link>
    </p>
    <p className="subtitle is-6">{note.date}</p>
    <ul>
      {note.details.map((entry, i) => (
        <li key={`${note.date}-${i}`}>{entry}</li>
      ))}
    </ul>
  </div>
));
