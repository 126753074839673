/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from "react";
import { rolesEnums } from "./CustomersUpdateRoleModal";

interface CustomersRolesProps {
  roles: any;
}

const setClassName = (role: string | undefined) => {
  switch (role) {
    case "admin":
      return "is-danger";
    default:
      return "is-success";
  }
};

const CustomersRoles: React.FC<CustomersRolesProps> = ({ roles }) => {
  return roles.map((el: any) => {
    const role = rolesEnums.find((r) => r.value === el)?.label;
    return (
      <span
        style={{ marginRight: "5px" }}
        className={`tag ${setClassName(role)}`}
        key={role}
      >
        {role}
      </span>
    );
  });
};

export default CustomersRoles;
