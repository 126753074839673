/**
 * Copyright 2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Link, useLocation, useParams } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import {
  NotificationGroup,
  NotificationChannel,
  EmailConfig,
  SlackConfig,
  PagerDutyConfig,
  useGetNotificationGroupQuery,
  Maybe,
} from "../../../generated/graphql";
import { CustomerContext } from "../../../context/customer";
import { Loading } from "../../../commonComponents/loading/Loading";
import { Error } from "../../../commonComponents/error/error";
import { ROUTES } from "../../../routes/routes";
import Button from "../../../commonComponents/button/Button";
import Input from "../../../commonComponents/input/Input";
import Segment from "../../../commonComponents/segment/Segment";
import InlineForm from "../../../commonComponents/inlineForm/InlineForm";
import { tabsButtons, typeButtons } from "./notificationsGroupsConstants";
import { NotificationGroupTooltips } from "../../../utils/constants";
import ReactTooltip from "react-tooltip";
import Select from "react-select";

type RouteParams = {
  id: string;
};

export const NotificationGroupDetails: React.FC = () => {
  const { id } = useParams<RouteParams>();
  const [notificationGroup, setNotificationGroup] =
    useState<Maybe<NotificationGroup>>();
  const customer = useContext(CustomerContext);
  const location = useLocation();

  const customerId = customer?.customer?.id || "";
  const notificationGroupId = id || location.pathname.split("/")[3];

  const { data, loading, error } = useGetNotificationGroupQuery({
    variables: {
      searchCriteria: {
        customerId: customerId,
        notificationGroupId: notificationGroupId,
      },
    },
  });

  useEffect(() => {
    setNotificationGroup(data?.notificationGroup);
  }, [data?.notificationGroup]);

  const emailChannel = notificationGroup?.channels.find(
    (channel: NotificationChannel) => channel.__typename === "EmailConfig"
  ) as EmailConfig;
  const slackChannel = notificationGroup?.channels.find(
    (channel: NotificationChannel) => channel.__typename === "SlackConfig"
  ) as SlackConfig;
  const pagerDutyChannel = notificationGroup?.channels.find(
    (channel: NotificationChannel) => channel.__typename === "PagerDutyConfig"
  ) as PagerDutyConfig;

  return (
    <div className="main-wide">
      <Loading loading={loading}>
        {error && <Error error={error} />}
        {notificationGroup && (
          <div className="box">
            <div className="columns is-vcentered border-bottom">
              <div className="column is-7">
                <h1 className="title is-marginless is-5 is-capitalized">
                  Notification Group Details
                </h1>
              </div>
              <div className="column is-5">
                <Link to={ROUTES.settings.notificationGroups.index.generate()}>
                  <Button className="is-pulled-right">
                    Back to Notification Groups
                  </Button>
                </Link>
              </div>
            </div>
            <div className="columns">
              <div className="column is-4-desktop">
                <div className="field">
                  <label className="label">Notification group ID</label>
                  <Input
                    value={notificationGroup.id}
                    type="text"
                    disabled={true}
                  />
                </div>
                <div className="field">
                  <label className="label">
                    Notification group name
                    <span
                      className="icon"
                      data-tip={NotificationGroupTooltips.name}
                    >
                      <i className="fa fa-question-circle" />
                    </span>
                  </label>
                  <Input
                    value={notificationGroup.name}
                    type="text"
                    disabled={true}
                  />
                </div>
                <div className="field">
                  <label className="label">
                    Notification type
                    <span
                      className="icon"
                      data-tip={NotificationGroupTooltips.type}
                    >
                      <i className="fa fa-question-circle" />
                    </span>
                  </label>
                  <Segment>
                    {typeButtons.map((button) => (
                      <Button
                        key={button.type}
                        className={
                          notificationGroup.severities.includes(button.type)
                            ? "active"
                            : ""
                        }
                        color={button.activeColor}
                        select
                        disabled={true}
                      >
                        {button.label}
                      </Button>
                    ))}
                  </Segment>
                </div>
                <div className="field">
                  <label className="label">
                    Notification frequency
                    <span
                      className="icon"
                      data-tip={NotificationGroupTooltips.frequency}
                    >
                      <i className="fa fa-question-circle" />
                    </span>
                  </label>
                  <Segment tabs>
                    {tabsButtons.map((button) => (
                      <Button
                        key={button.value}
                        className={
                          notificationGroup.frequency === button.value
                            ? "active"
                            : ""
                        }
                        select
                        disabled={true}
                      >
                        {button.label}
                      </Button>
                    ))}
                  </Segment>
                </div>
                <div className="field">
                  <label className="label">
                    Notification channels
                    <span
                      className="icon"
                      data-tip={NotificationGroupTooltips.channels}
                    >
                      <i className="fa fa-question-circle" />
                    </span>
                  </label>
                  <InlineForm>
                    <label>Email</label>
                    <Input
                      type="text"
                      placeholder="Email address"
                      disabled={true}
                      value={emailChannel?.address}
                    />
                  </InlineForm>
                  <InlineForm>
                    <label>Slack</label>
                    <Input
                      type="text"
                      placeholder="Channel name"
                      disabled={true}
                      value={slackChannel?.channelName}
                    />
                    <Input
                      type="text"
                      placeholder="Webhook url"
                      disabled={true}
                      value={slackChannel?.webHook}
                    />
                  </InlineForm>
                  <InlineForm>
                    <label>PagerDuty</label>
                    <Input
                      type="text"
                      placeholder="Messages routing key"
                      disabled={true}
                      value={pagerDutyChannel?.routingKey}
                    />
                  </InlineForm>
                </div>
                <div className="field">
                  <label className="label">
                    Excluded Accounts
                    <span
                      className="icon"
                      data-tip={NotificationGroupTooltips.excludedAccounts}
                    >
                      <i className="fa fa-question-circle" />
                    </span>
                  </label>
                  <Select
                    isMulti
                    isDisabled={true}
                    value={notificationGroup.excludedAccounts?.map(
                      (account) => ({
                        value: account.id,
                        label: account.name,
                      })
                    )}
                    placeholder={"No excluded accounts"}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                  />
                </div>
                <ReactTooltip
                  effect="solid"
                  className="setup-tooltip"
                  multiline={true}
                />
              </div>
            </div>
          </div>
        )}
      </Loading>
    </div>
  );
};
