/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { useContext } from "react";
import {
  useGetAccountsQuery,
  AccountType,
  useRemoveAccountMutation,
  GetUserRoleQuery,
  UserRole,
} from "../../../generated/graphql";
import { Link } from "react-router-dom";
import VaultAccountsList from "./VaultAccountsList";
import { CustomerContext } from "../../../context/customer";
import { Loading } from "../../../commonComponents/loading/Loading";
import { Error } from "../../../commonComponents/error/error";
import { toast } from "react-toastify";
import { toastMessages } from "../../../utils/constants";
import Button from "../../../commonComponents/button/Button";
import { ROUTES } from "../../../routes/routes";

interface Props {
  userRole: GetUserRoleQuery | any;
  userRoleLoading: boolean;
}

const VaultAccounts: React.FC<Props> = ({ userRole, userRoleLoading }) => {
  const customer = useContext(CustomerContext);
  const customerId =
    customer && customer.customer && customer.customer.id
      ? customer.customer.id
      : "";
  const {
    data,
    error,
    loading,
    refetch: reload,
  } = useGetAccountsQuery({
    variables: {
      searchCriteria: {
        customerId: customerId,
        accountType: AccountType.Vault,
      },
    },
  });

  const [
    removeAccount,
    { loading: removeAccountLoading, error: removeAccountError },
  ] = useRemoveAccountMutation();

  return (
    <Loading loading={loading || userRoleLoading}>
      <>
        {error && <Error error={error} />}
        {removeAccountError && <Error error={removeAccountError} />}

        <h1 className="title">Vault Accounts</h1>
        <h2 className="subtitle ">
          Vault accounts are supported only for AWS and GCP resources and are
          used to store copies of your data.
        </h2>
        {error && <div>{error.message}</div>}
        <div style={{ marginBottom: "1rem" }}>
          {userRole?.userRole !== UserRole.ReadOnly && (
            <Link to={ROUTES.accounts.vault.generate()}>
              <Button data-cy="new-vault-account">Add Vault Account</Button>
            </Link>
          )}
        </div>
        <div className="box">
          {data && data.accounts && data.accounts.data.length > 0 ? (
            <VaultAccountsList
              data={data.accounts.data}
              removeAccount={(removeId) => {
                removeAccount({
                  variables: {
                    input: {
                      customerId: customerId,
                      accountId: removeId,
                    },
                  },
                })
                  .then((e) => {
                    reload();
                    toast.success(toastMessages.ACCOUNT_REMOVED);
                  })
                  .catch((e) =>
                    toast.error(toastMessages.ACCOUNT_REMOVAL_ERROR)
                  );
              }}
              loading={removeAccountLoading}
              userRole={userRole}
              userRoleLoading={userRoleLoading}
              customerId={customerId}
            />
          ) : (
            <p>There are no vault accounts added.</p>
          )}
        </div>
      </>
    </Loading>
  );
};

export default VaultAccounts;
