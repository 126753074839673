/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from "react";
import { History } from "history";
import TemplateForm from "../components/TemplateForm";
import PlanForm from "../components/PlanForm";

interface Props {
  history: History;
}

export const DisasterRecoveryFormContainer: React.FC<Props> = ({ history }) => {
  const currentView = history.location.pathname.split("/")[2];

  return (
    <>
      {(currentView === "plans" || currentView === "") && (
        <PlanForm history={history} />
      )}
      {currentView === "templates" && <TemplateForm history={history} />}
    </>
  );
};
