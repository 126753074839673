/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { useContext, useEffect, useState } from "react";
import {
  CloudProvider,
  TemplateResourcesMappingInput,
  useGetDisasterRecoveryPlanQuery,
  useGetSupportedRegionsQuery,
  useGetUserRoleQuery,
  UserRole,
  useUpdateDisasterRecoveryPlanMutation,
} from "../../../generated/graphql";
import { Link, useLocation } from "react-router-dom";
import { CustomerContext } from "../../../context/customer";
import { Loading } from "../../../commonComponents/loading/Loading";
import { Error } from "../../../commonComponents/error/error";
import { ROUTES } from "../../../routes/routes";
import Button from "../../../commonComponents/button/Button";
import Input from "../../../commonComponents/input/Input";
import moment from "moment";
import { toast } from "react-toastify";
import {
  DisasterRecoveryPlanTooltips,
  toastMessages,
} from "../../../utils/constants";
import SimpleReactValidator from "simple-react-validator";
import ValidationMessage from "../../../commonComponents/validationMessage/ValidationMessage";
import Select from "react-select";
import DatePicker from "react-datepicker";
import RemoveEmailsButton from "./RemoveEmailsButton";
import AddEmailsButton from "./AddEmailsButton";
import ReactTooltip from "react-tooltip";
import TemplatesSelect from "./TemplatesSelect";
import { Icon } from "../../../commonComponents/icon/Icon";

const validator = new SimpleReactValidator();

const PlanDetails: React.FC = () => {
  const [plan, setPlan] = useState<any>();
  const [name, setName] = useState<string>("");
  const [restoreRegion, setRestoreRegion] = useState<string>("");
  const [startTimestamp, setStartTimestamp] = useState<number>(0);
  const [frequencyInMonths, setFrequencyInMonths] = useState<number>(1);
  const [remindDaysBefore, setRemindDaysBefore] = useState<number>(0);
  const [kmsKey, setKMSKey] = useState<string>("");
  const [emails, setEmails] = useState<Array<string>>([""]);
  const [templates, setTemplates] =
    useState<Array<TemplateResourcesMappingInput>>();

  const location = useLocation();

  const [planChanged, setPlanChanged] = useState<boolean>(false);

  const [validationError, setValidationError] = useState(false);
  let graphqlValidationError;
  const [graphqlValidationErrors, setGraphqlValidationErrors] =
    useState<any>(null);

  const { customer } = useContext(CustomerContext);
  const customerId = customer && customer.id ? customer.id : "";
  const planId = location.pathname.split("/")[3];

  const { data, loading, error } = useGetDisasterRecoveryPlanQuery({
    variables: {
      searchCriteria: {
        customerId: customerId,
        planId: planId,
      },
    },
  });

  const {
    data: getRegionsData,
    loading: getRegionsLoading,
    error: getRegionsError,
  } = useGetSupportedRegionsQuery({
    variables: {
      cloudProvider: plan?.cloudProvider,
    },
  });

  const { data: userRoleData, loading: userRoleLoading } = useGetUserRoleQuery({
    variables: {
      customerId: customerId,
    },
  });

  const { userRole } = userRoleData || {};

  useEffect(() => {
    setPlan(data?.disasterRecoveryPlan);
    setName(data?.disasterRecoveryPlan?.name || "");
    setRestoreRegion(data?.disasterRecoveryPlan?.restoreRegion || "");
    setStartTimestamp(data?.disasterRecoveryPlan?.startTimestamp || 0);
    setFrequencyInMonths(data?.disasterRecoveryPlan?.frequencyInMonths || 0);
    setRemindDaysBefore(data?.disasterRecoveryPlan?.remindDaysBefore || 0);
    setEmails(data?.disasterRecoveryPlan?.emails || []);
    setKMSKey(data?.disasterRecoveryPlan?.kmsKey || "");
    if (
      data?.disasterRecoveryPlan?.templatesResourcesMapping !== undefined &&
      data?.disasterRecoveryPlan?.templatesResourcesMapping!.length > 0
    ) {
      setTemplates(
        data?.disasterRecoveryPlan?.templatesResourcesMapping?.map(
          (mapping) => {
            return {
              templateId: mapping.template.id,
              resourcesMapping: mapping.resourcesMapping?.map(
                (parameterMapping) => {
                  return {
                    parameter: parameterMapping.parameter,
                    resourceId: parameterMapping.resourceId,
                  };
                }
              ),
            };
          }
        )
      );
    } else {
      setTemplates([
        {
          templateId: "",
          resourcesMapping: [],
        },
      ]);
    }
  }, [data]);

  const [updatePlan] = useUpdateDisasterRecoveryPlanMutation({
    variables: {
      input: {
        customerId: customerId,
        id: planId,
        name: name,
        restoreRegion: restoreRegion,
        startTimestamp: startTimestamp,
        frequencyInMonths: frequencyInMonths,
        remindDaysBefore: remindDaysBefore,
        emails: emails,
        templatesResourcesMapping: templates,
        kmsKey: kmsKey ? kmsKey : null,
      },
    },
  });

  return (
    <div className="main wide">
      <Loading loading={loading}>
        {error && <Error error={error} />}
        {getRegionsError && <Error error={getRegionsError} />}
        {plan && (
          <div className="box">
            <div className="columns is-vcentered border-bottom">
              <div className="column is-7">
                <h1 className="title is-marginless is-5 is-capitalized">
                  Plan Details
                </h1>
              </div>
              <div className="column is-5">
                <Link to={ROUTES.disasterRecovery.plans.index.generate()}>
                  <Button className="is-pulled-right">Back to Plans</Button>
                </Link>
                {!userRoleLoading && userRole !== UserRole.ReadOnly && (
                  <Button
                    className="is-pulled-right has-margin-right"
                    onClick={() => {
                      validationError && setValidationError(false);
                      if (validator.allValid()) {
                        updatePlan()
                          .then(() => {
                            toast.success(
                              toastMessages.DISASTER_RECOVERY_PLAN_UPDATED
                            );
                            validator.hideMessages();
                            setGraphqlValidationErrors(null);
                          })
                          .catch((e: any): any => {
                            toast.error(
                              toastMessages.DISASTER_RECOVERY_PLAN_UPDATE_ERROR
                            );
                            setGraphqlValidationErrors(e.graphQLErrors);
                          });
                      } else {
                        setValidationError(true);
                        validator.showMessages();
                        toast.error(toastMessages.VALIDATION_FAILED);
                      }
                    }}
                    disabled={!planChanged}
                  >
                    Save
                  </Button>
                )}
              </div>
            </div>
            <>
              <div className="columns">
                <div className="column is-4-desktop">
                  <div className="field">
                    <label className="label">Plan ID</label>
                    <Input value={plan.id} type="text" disabled />
                  </div>

                  <div className="field">
                    <label className="label">
                      Plan name*
                      <span
                        className="icon"
                        data-tip={DisasterRecoveryPlanTooltips.planName}
                      >
                        <i className="fa fa-question-circle" />
                      </span>
                    </label>
                    <Input
                      defaultValue={name}
                      onChange={(e) => {
                        setName(e.target.value);
                        setPlanChanged(true);
                      }}
                      type="text"
                      disabled={userRole === UserRole.ReadOnly}
                    />
                    <ValidationMessage>
                      {validator.message("name", name, "required|max:64")}
                    </ValidationMessage>
                    {graphqlValidationErrors &&
                      (graphqlValidationError = graphqlValidationErrors?.find(
                        (e: any) => e.errorInfo === "name"
                      )) && (
                        <p className="validation-error">
                          {graphqlValidationError.message}
                        </p>
                      )}
                  </div>

                  <div className="field">
                    <label className="label">
                      Plan cloud provider*
                      <span
                        className="icon"
                        data-tip={DisasterRecoveryPlanTooltips.cloudProvider}
                      >
                        <i className="fa fa-question-circle" />
                      </span>
                    </label>
                    <Icon
                      height="2.5rem"
                      image={plan.cloudProvider}
                      width="2.5rem"
                    />
                  </div>

                  <div className="field">
                    <label className="label">
                      Restore region*
                      <span
                        className="icon"
                        data-tip={DisasterRecoveryPlanTooltips.restoreRegion}
                      >
                        <i className="fa fa-question-circle" />
                      </span>
                    </label>
                    <Loading loading={getRegionsLoading}>
                      <Select
                        defaultValue={{
                          value: restoreRegion,
                          label: restoreRegion,
                        }}
                        onChange={(e: any) => {
                          setRestoreRegion(e.value);
                          setPlanChanged(true);
                        }}
                        options={getRegionsData?.supportedRegions.map(
                          (region) => ({ value: region, label: region })
                        )}
                        isDisabled={userRole === UserRole.ReadOnly}
                      />
                    </Loading>
                    <ValidationMessage>
                      {validator.message(
                        "restoreRegion",
                        restoreRegion,
                        "required"
                      )}
                    </ValidationMessage>
                    {graphqlValidationErrors &&
                      (graphqlValidationError = graphqlValidationErrors?.find(
                        (e: any) => e.errorInfo === "restoreRegion"
                      )) && (
                        <p className="validation-error">
                          {graphqlValidationError.message}
                        </p>
                      )}
                  </div>

                  <div className="field">
                    <label className="label">
                      Start date*
                      <span
                        className="icon"
                        data-tip={DisasterRecoveryPlanTooltips.startDate}
                      >
                        <i className="fa fa-question-circle" />
                      </span>
                    </label>
                    <DatePicker
                      className="input border"
                      dateFormat={"dd-MM-yyyy"}
                      selected={
                        startTimestamp !== undefined
                          ? new Date(startTimestamp * 1000)
                          : null
                      }
                      todayButton="Today"
                      onChange={(e: any) => {
                        if (e !== null) {
                          setStartTimestamp(
                            moment(e)
                              .add(moment().utcOffset(), "minutes")
                              .unix()
                          );
                          setPlanChanged(true);
                        }
                      }}
                      placeholderText="Select Date"
                      disabled={userRole === UserRole.ReadOnly}
                    />
                    <ValidationMessage>
                      {validator.message(
                        "startDate",
                        startTimestamp,
                        "required|numeric|min:1,num"
                      )}
                    </ValidationMessage>
                    {graphqlValidationErrors &&
                      (graphqlValidationError = graphqlValidationErrors?.find(
                        (e: any) => e.errorInfo === "startTimestamp"
                      )) && (
                        <p className="validation-error">
                          {graphqlValidationError.message}
                        </p>
                      )}
                  </div>

                  <div className="field">
                    <label className="label">
                      Test frequency (months)*
                      <span
                        className="icon"
                        data-tip={DisasterRecoveryPlanTooltips.testFrequency}
                      >
                        <i className="fa fa-question-circle" />
                      </span>
                    </label>
                    <Input
                      value={frequencyInMonths}
                      onChange={(e) => {
                        setFrequencyInMonths(e.target.value);
                        setPlanChanged(true);
                      }}
                      type="number"
                      disabled={userRole === UserRole.ReadOnly}
                    />
                    <ValidationMessage>
                      {validator.message(
                        "frequencyInMonths",
                        frequencyInMonths,
                        "required|numeric|min:1,num"
                      )}
                    </ValidationMessage>
                    {graphqlValidationErrors &&
                      (graphqlValidationError = graphqlValidationErrors?.find(
                        (e: any) => e.errorInfo === "frequencyInMonths"
                      )) && (
                        <p className="validation-error">
                          {graphqlValidationError.message}
                        </p>
                      )}
                  </div>

                  <div className="field">
                    <label className="label">
                      Number of days to send reminder before the test*
                      <span
                        className="icon"
                        data-tip={DisasterRecoveryPlanTooltips.remindDaysBefore}
                      >
                        <i className="fa fa-question-circle" />
                      </span>
                    </label>
                    <Input
                      value={remindDaysBefore}
                      onChange={(e) => {
                        setRemindDaysBefore(e.target.value);
                        setPlanChanged(true);
                      }}
                      type="number"
                      disabled={userRole === UserRole.ReadOnly}
                    />
                    <ValidationMessage>
                      {validator.message(
                        "remindDaysBefore",
                        remindDaysBefore,
                        "required|numeric|min:0,num"
                      )}
                    </ValidationMessage>
                    {graphqlValidationErrors &&
                      (graphqlValidationError = graphqlValidationErrors?.find(
                        (e: any) => e.errorInfo === "remindDaysBefore"
                      )) && (
                        <p className="validation-error">
                          {graphqlValidationError.message}
                        </p>
                      )}
                  </div>

                  {plan.cloudProvider === CloudProvider.Aws && (
                    <div className="field">
                      <label className="label">
                        KMS key
                        <span
                          className="icon"
                          data-tip={DisasterRecoveryPlanTooltips.kmsKey}
                        >
                          <i className="fa fa-question-circle" />
                        </span>
                      </label>
                      <Input
                        value={kmsKey}
                        onChange={(e) => {
                          setKMSKey(e.target.value);
                          setPlanChanged(true);
                        }}
                        type="text"
                      />
                    </div>
                  )}

                  <div className="field">
                    <label className="label">
                      Recipients
                      <span
                        className="icon"
                        data-tip={DisasterRecoveryPlanTooltips.recipients}
                      >
                        <i className="fa fa-question-circle" />
                      </span>
                    </label>
                    {emails.map((email: string, index: number) => (
                      <div key={`email-${index}`}>
                        <Input
                          value={email}
                          onChange={(e) => {
                            const newEmails = [...emails];
                            newEmails[index] = e.target.value;
                            setEmails(newEmails);
                            setPlanChanged(true);
                          }}
                          type="text"
                          disabled={userRole === UserRole.ReadOnly}
                        />
                        <ValidationMessage>
                          {validator.message("email", email, "required|email")}
                        </ValidationMessage>
                        {graphqlValidationErrors &&
                          (graphqlValidationError =
                            graphqlValidationErrors?.find(
                              (e: any) => e.errorInfo === `emails[${index}]`
                            )) && (
                            <p className="validation-error">
                              {graphqlValidationError.message}
                            </p>
                          )}
                        {emails.length > 1 &&
                          userRole !== UserRole.ReadOnly && (
                            <RemoveEmailsButton
                              recipients={emails}
                              setter={setEmails}
                              index={index}
                            />
                          )}
                      </div>
                    ))}
                    {userRole !== UserRole.ReadOnly && (
                      <AddEmailsButton recipients={emails} setter={setEmails} />
                    )}
                    <hr />

                    <div className="field">
                      <label className="label">
                        Templates
                        <span
                          className="icon"
                          data-tip={DisasterRecoveryPlanTooltips.templates}
                        >
                          <i className="fa fa-question-circle" />
                        </span>
                      </label>
                      {templates && templates.length > 0 ? (
                        <TemplatesSelect
                          customerId={customerId}
                          templatesResourcesMapping={templates}
                          templatesResourcesMappingSetter={(
                            templatesInput: Array<TemplateResourcesMappingInput>
                          ) => {
                            setTemplates(templatesInput);
                            setPlanChanged(true);
                          }}
                          cloudProvider={plan.cloudProvider}
                          readOnly={userRole === UserRole.ReadOnly}
                        />
                      ) : (
                        "No templates\n"
                      )}
                    </div>

                    <ReactTooltip
                      effect="solid"
                      className="setup-tooltip"
                      multiline={true}
                    />
                  </div>
                </div>
              </div>
            </>
          </div>
        )}
      </Loading>
    </div>
  );
};

export default PlanDetails;
