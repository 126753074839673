/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  MessageCategory,
  MessageType,
  useDismissMessageMutation,
  useGetMessagesQuery,
} from "../../generated/graphql";
import Message from "./Message";
import { Loading } from "../loading/Loading";
import { CustomerContext } from "../../context/customer";
import useInterval from "../../utils/useInterval";

const MessageContainer: React.FC = () => {
  const customer = useContext(CustomerContext);
  const customerId =
    customer && customer.customer && customer.customer.id
      ? customer.customer.id
      : "";
  const location = useLocation();
  const [messageId, setMessageId] = useState("");

  const {
    data,
    error,
    loading,
    refetch: reload,
  } = useGetMessagesQuery({
    variables: {
      searchCriteria: {
        customerId: customerId,
      },
    },
  });

  useInterval(() => {
    reload().catch((e) => {
      console.log(e);
    });
  }, 60 * 1000);

  const [dismissMessage] = useDismissMessageMutation({
    variables: {
      input: {
        customerId: customerId.toString(),
        messageId: messageId.toString(),
      },
    },
  });

  if (messageId) {
    setMessageId("");
    dismissMessage()
      .then(() => reload())
      .catch((e) => alert(e));
  }

  const setMessageIdHandler = (id: string) => {
    setMessageId(id);
  };

  return (
    <Loading loading={loading}>
      {data && data.messages && data.messages.data.length > 0 && (
        <div className="section">
          <div className="container is-fluid">
            {data?.messages.data.map(({ type, message, id, category }) => {
              if (type === MessageType.Critical)
                return <Message type={type} message={message} key={id} />;
              if (
                location.pathname === "/" &&
                category === MessageCategory.General
              )
                return (
                  <Message
                    clickHandler={() => setMessageIdHandler(id)}
                    type={type}
                    message={message}
                    key={id}
                  />
                );
              if (
                location.pathname.includes("disaster-recovery") &&
                category === MessageCategory.DisasterRecovery
              )
                return (
                  <Message
                    clickHandler={() => setMessageIdHandler(id)}
                    type={type}
                    message={message}
                    key={id}
                  />
                );
              return null;
            })}
          </div>
        </div>
      )}
    </Loading>
  );
};

export default MessageContainer;
