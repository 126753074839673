/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { useContext, useState } from "react";
import { CustomerContext } from "../../../context/customer";
import { Link, RouteComponentProps } from "react-router-dom";
import { Field } from "../../../commonComponents/form/field";
import { icons } from "../../../commonComponents/icon/icons";
import Button from "../../../commonComponents/button/Button";
import Card from "../../../commonComponents/card/Card";
import InfoCard from "../../../commonComponents/card/InfoCard";
import {
  AccountType,
  CloudProvider,
  CreateAccountAwsChinaInput,
  CreateAccountAwsInput,
  CreateAccountAzureInput,
  CreateAccountGcpInput,
  CreateAccountIbmCloudInput,
  useCreateAccountAwsChinaMutation,
  useCreateAccountAwsMutation,
  useCreateAccountAzureMutation,
  useCreateAccountGcpMutation,
  useCreateAccountIbmCloudMutation,
  useGetAwsAccountAccessRoleTemplateLazyQuery,
  useGetAwsChinaAccountAccessRoleTemplateLazyQuery,
} from "../../../generated/graphql";
import { Error } from "../../../commonComponents/error/error";
import { Loading } from "../../../commonComponents/loading/Loading";
import { toast } from "react-toastify";
import { toastMessages } from "../../../utils/constants";
import { ROUTES } from "../../../routes/routes";

const svgToMiniDataURI = require("mini-svg-data-uri");

const validateAzureSubscriptionId = (subscriptionId: string) => {
  const re = /^[0-9A-Fa-f]{8}-([0-9A-Fa-f]{4}-){3}[0-9A-Fa-f]{12}$/;
  return re.test(String(subscriptionId));
};
const validateIbmCloudProviderId = (providerId: string) => {
  const re = /^[0-9a-f]{32}$/;
  return re.test(String(providerId));
};

const isNotBlank = (value: string) => {
  const re = /^\s+$/;
  return !re.test(String(value));
};

const AccountsForm: React.FC<RouteComponentProps & any> = ({
  typeOfAccount,
}) => {
  const isProtected = typeOfAccount === "protected";
  const customer = useContext(CustomerContext);
  const customerId = customer?.customer?.id ? customer.customer.id : "";
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [uploadedFileName, setUploadedFileName] = useState<string>("");
  const [newAccountId, setNewAccountId] = useState<string | undefined>(
    undefined
  );
  const [wrongInput, setWrongInput] = useState<string>("");
  const [accountCloudProvider, setAccountCloudProvider] = useState<string>("");
  const [accountAws, setAccountAws] = useState<CreateAccountAwsInput>({
    number: "",
    name: "",
    accountType: isProtected ? AccountType.Protected : AccountType.Vault,
    customerId: customerId,
  });

  const [accountAwsChina, setAccountAwsChina] =
    useState<CreateAccountAwsChinaInput>({
      number: "",
      name: "",
      accountType: isProtected ? AccountType.Protected : AccountType.Vault,
      customerId: customerId,
      credentials: {
        staticCredentials: {
          secretAccessKey: "",
          accessKeyId: "",
        },
        rolesChain: null,
      },
    });

  const [accountAzure, setAccountAzure] = useState<CreateAccountAzureInput>({
    subscriptionId: "",
    name: "",
    customerId: customerId,
    credentials: {
      clientId: "",
      clientSecret: "",
      tenantId: "",
    },
  });

  const [accountIBM, setAccountIBM] = useState<CreateAccountIbmCloudInput>({
    ibmCloudAccountId: "",
    name: "",
    customerId: customerId,
    accountType: isProtected ? AccountType.Protected : AccountType.Vault,
    credentials: {
      apiKey: "",
    },
  });

  const [accountGcp, setAccountGcp] = useState<CreateAccountGcpInput>({
    projectId: "",
    name: "",
    customerId: customerId,
    accountType: isProtected ? AccountType.Protected : AccountType.Vault,
    credentials: {
      type: "",
      projectId: "",
      privateKey: "",
      privateKeyId: "",
      clientEmail: "",
      clientId: "",
      authUri: "",
      tokenUri: "",
      authProviderX509CertUrl: "",
      clientX509CertUrl: "",
    },
  });

  const [
    getAwsAccountAccessRole,
    { data: awsAccessRoleData, error: awsOnboardError },
  ] = useGetAwsAccountAccessRoleTemplateLazyQuery({
    variables: {
      input: {
        customerId,
        accountId: accountAws.number,
        accountType: accountAws.accountType,
      },
    },
  });

  const [
    getAwsChinaAccountAccessRole,
    {
      data: awsChinaAccessRoleData,
      loading: awsChinaAccessRoleLoading,
      error: awsChinaOnboardError,
    },
  ] = useGetAwsChinaAccountAccessRoleTemplateLazyQuery({
    variables: {
      input: {
        customerId,
        accountId: accountAwsChina.number,
        accountType: accountAwsChina.accountType,
        staticCredentials: {
          accessKeyId:
            accountAwsChina.credentials?.staticCredentials?.accessKeyId || "",
          secretAccessKey:
            accountAwsChina.credentials?.staticCredentials?.secretAccessKey ||
            "",
        },
      },
    },
  });

  const [createAwsAccount, { loading: awsLoading, error: awsError }] =
    useCreateAccountAwsMutation({
      variables: {
        input: accountAws,
      },
    });

  const [
    createAwsChinaAccount,
    { loading: awsChinaLoading, error: awsChinaError },
  ] = useCreateAccountAwsChinaMutation({
    variables: {
      input: accountAwsChina,
    },
  });

  const [
    createAzureAccount,
    { loading: azureLoading, error: azureOnboardError },
  ] = useCreateAccountAzureMutation({
    variables: {
      input: accountAzure,
    },
  });

  const [createIbmAccount, { loading: ibmLoading, error: ibmOnboardError }] =
    useCreateAccountIbmCloudMutation({
      variables: {
        input: accountIBM,
      },
    });

  const [createGcpAccount, { loading: gcpLoading, error: gcpOnboardError }] =
    useCreateAccountGcpMutation({
      variables: {
        input: accountGcp,
      },
    });

  const updateAccountAws = (field: string, value: any) => {
    setAccountAws({ ...accountAws, [field]: value });
  };

  const updateAccountAwsChina = (field: string, value: any) => {
    setAccountAwsChina({ ...accountAwsChina, [field]: value });
  };

  const updateAccountAzure = (field: string, value: any) => {
    setAccountAzure({ ...accountAzure, [field]: value });
  };

  const updateAccountIbm = (field: string, value: any) => {
    setAccountIBM({ ...accountIBM, [field]: value });
  };

  const updateAccountGcp = (field: string, value: any) => {
    setAccountGcp({ ...accountGcp, [field]: value });
  };

  const selectAccountHandler = (provider: CloudProvider) => {
    setAccountCloudProvider(provider);
    setCurrentStep(1);
  };

  const awsAccountNumberLength = 12;

  const createAwsPolicyHandler = () => {
    if (
      isNotBlank(accountAws.name) &&
      accountAws.number.length === awsAccountNumberLength
    ) {
      getAwsAccountAccessRole();
      setCurrentStep(2);
    }
    accountAws.number.length !== awsAccountNumberLength
      ? setWrongInput("number")
      : setWrongInput("");
  };

  const goToAwsChinaPolicy = () => {
    if (
      isNotBlank(accountAwsChina.name) &&
      accountAwsChina.number.length === awsAccountNumberLength
    ) {
      setCurrentStep(2);
    }
    accountAwsChina.number.length !== awsAccountNumberLength
      ? setWrongInput("number")
      : setWrongInput("");
  };

  const AzureCredentialsHandler = () => {
    if (
      isNotBlank(accountAzure.name) &&
      validateAzureSubscriptionId(accountAzure.subscriptionId)
    ) {
      setCurrentStep(2);
    }
    !validateAzureSubscriptionId(accountAzure.subscriptionId)
      ? setWrongInput("subscriptionId")
      : setWrongInput("");
  };

  const IBMCredentialsHandler = () => {
    if (
      isNotBlank(accountIBM.name) &&
      validateIbmCloudProviderId(accountIBM.ibmCloudAccountId)
    ) {
      setCurrentStep(2);
    }
    !validateIbmCloudProviderId(accountIBM.ibmCloudAccountId)
      ? setWrongInput("ibmCloudAccountId")
      : setWrongInput("");
  };

  const GcpCredentialsHandler = () => {
    if (isNotBlank(accountGcp.projectId) && isNotBlank(accountGcp.name)) {
      setCurrentStep(2);
    }
  };

  const finishAwsAccountOnboardingHandler = () => {
    createAwsAccount()
      .then((data) => {
        const accountId = data?.data?.createAccountAws?.accountId;
        setNewAccountId(accountId);
        setCurrentStep(3);
        toast.success(toastMessages.ACCOUNT_ADDED);
      })
      .catch(() => {
        toast.error(toastMessages.ACCOUNT_ADDED_ERROR);
      });
  };

  const finishAwsChinaAccountOnboardingHandler = () => {
    createAwsChinaAccount()
      .then((data) => {
        const accountId = data?.data?.createAccountAwsChina;
        setNewAccountId(accountId);
        setCurrentStep(3);
        toast.success(toastMessages.ACCOUNT_ADDED);
      })
      .catch(() => {
        toast.error(toastMessages.ACCOUNT_ADDED_ERROR);
      });
  };

  const finishAzureAccountOnboardingHandler = () => {
    createAzureAccount()
      .then((data) => {
        const accountId = data?.data?.createAccountAzure;
        setNewAccountId(accountId);
        setCurrentStep(3);
        toast.success(toastMessages.ACCOUNT_ADDED);
      })
      .catch(() => {
        toast.error(toastMessages.ACCOUNT_ADDED_ERROR);
      });
  };

  const finishIbmAccountOnboardingHandler = () => {
    createIbmAccount()
      .then((data) => {
        const accountId = data?.data?.createAccountIbmCloud;
        setNewAccountId(accountId);
        setCurrentStep(3);
        toast.success(toastMessages.ACCOUNT_ADDED);
      })
      .catch(() => {
        toast.error(toastMessages.ACCOUNT_ADDED_ERROR);
      });
  };

  const finishGcpAccountOnboardingHandler = () => {
    createGcpAccount()
      .then((data) => {
        const accountId = data?.data?.createAccountGcp;
        setNewAccountId(accountId);
        setCurrentStep(3);
        toast.success(toastMessages.ACCOUNT_ADDED);
      })
      .catch(() => {
        toast.error(toastMessages.ACCOUNT_ADDED_ERROR);
      });
  };

  let fileReader: any,
    inputFile: any = null;

  const uploadClick = () => {
    inputFile.click();
    return false;
  };

  const handleFileRead = () => {
    const content = JSON.parse(fileReader.result);
    const credentials = {
      type: content.type,
      projectId: content.project_id,
      privateKey: content.private_key,
      privateKeyId: content.private_key_id,
      clientEmail: content.client_email,
      clientId: content.client_id,
      authUri: content.auth_uri,
      tokenUri: content.token_uri,
      authProviderX509CertUrl: content.auth_provider_x509_cert_url,
      clientX509CertUrl: content.client_x509_cert_url,
    };

    setAccountGcp((prevAccountGcp) => ({
      ...prevAccountGcp,
      credentials: credentials,
    }));
  };

  const handleUploadedFile = (file: any) => {
    fileReader = new FileReader();
    fileReader.onloadend = handleFileRead;
    fileReader.readAsText(file);
    setUploadedFileName(file.name);
  };

  const steps = [
    {
      title: "Cloud Provider",
      subtitle: "Select your cloud provider",
      action: () => currentStep > 0 && currentStep !== 3 && setCurrentStep(0),
    },
    {
      title: "Account details",
      subtitle: "Provide account details.",
      action: () => currentStep === 2 && setCurrentStep(1),
    },
    {
      title: "Verification",
      subtitle: "Complete account onboarding",
    },
    {
      title: "Summary",
      subtitle: "What do you want to do next?",
    },
  ];

  const isActive = (step: number) => (step === currentStep ? "is-active" : "");
  const isCompleted = (step: number) =>
    step <= currentStep ? "is-completed" : "";
  const stepClasses = (step: number) =>
    `step-item ${isActive(step)} ${isCompleted(step)}`;

  return (
    <div className="main wide">
      <div className="container">
        <section className="hero">
          <div className="hero-body">
            <div className="container">
              <ul className="steps">
                {steps.map((step, index) => (
                  <li
                    className={stepClasses(index)}
                    onClick={step.action}
                    key={index}
                  >
                    <div className="step-marker">{index + 1}</div>
                    <div className="step-details is-primary">
                      <p className="step-title">{step.title}</p>
                      <p>{step.subtitle}</p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </section>
        {awsError && <Error error={awsError} />}
        {awsOnboardError && <Error error={awsOnboardError} />}
        {awsChinaError && <Error error={awsChinaError} />}
        {awsChinaOnboardError && <Error error={awsChinaOnboardError} />}
        {azureOnboardError && <Error error={azureOnboardError} />}
        {gcpOnboardError && <Error error={gcpOnboardError} />}
        {ibmOnboardError && <Error error={ibmOnboardError} />}
        <div className="container">
          {currentStep === 0 && (
            <div className="columns">
              <div className="column is-grow">
                <div
                  className="box is-fullheight account-box has-text-centered"
                  onClick={() => selectAccountHandler(CloudProvider.Aws)}
                  data-cy="aws-account"
                >
                  <img
                    src={svgToMiniDataURI(icons.AWS)}
                    className="provider-icon-size"
                    alt="AWS"
                  />
                  <p className="same-height title has-text-centered">AWS</p>
                </div>
              </div>
              <div className="column is-grow">
                <div
                  className="box is-fullheight account-box has-text-centered"
                  onClick={() => selectAccountHandler(CloudProvider.AwsChina)}
                >
                  <img
                    src={svgToMiniDataURI(icons.AWS_CHINA)}
                    className="provider-icon-size"
                    alt="AWS China"
                  />
                  <p className="same-height title has-text-centered">
                    AWS China
                  </p>
                </div>
              </div>
              <div className="column is-grow">
                <div
                  className="box is-fullheight account-box has-text-centered"
                  onClick={() => selectAccountHandler(CloudProvider.Gcp)}
                >
                  <img
                    src={svgToMiniDataURI(icons.GCP)}
                    className="provider-icon-size"
                    alt="GCP"
                  />
                  <p className="same-height title has-text-centered">GCP</p>
                </div>
              </div>
              {isProtected && (
                <div className="column is-grow">
                  <div
                    className="box is-fullheight account-box has-text-centered"
                    onClick={() => selectAccountHandler(CloudProvider.Ibmcloud)}
                  >
                    <img
                      src={svgToMiniDataURI(icons.IBMCLOUD)}
                      className="provider-icon-size"
                      alt="IBM_CLOUD"
                    />
                    <p className="same-height title has-text-centered">
                      IBM CLOUD
                    </p>
                  </div>
                </div>
              )}
              {isProtected && (
                <div className="column is-grow">
                  <div
                    className="box is-fullheight account-box has-text-centered"
                    onClick={() => selectAccountHandler(CloudProvider.Azure)}
                  >
                    <img
                      src={svgToMiniDataURI(icons.AZURE)}
                      className="provider-icon-size"
                      alt="Azure"
                    />
                    <p className="same-height title has-text-centered">Azure</p>
                  </div>
                </div>
              )}
            </div>
          )}

          {currentStep === 1 && (
            <div className="box">
              {accountCloudProvider === CloudProvider.Aws && (
                <>
                  <Field
                    value={accountAws.name}
                    onChange={updateAccountAws}
                    name="name"
                    type="text"
                    label="Account Name *"
                    required={true}
                    help={
                      "This is a name that will be used internally to identify this account"
                    }
                  />
                  <Field
                    value={accountAws.number}
                    onChange={updateAccountAws}
                    name="number"
                    type="text"
                    label="AWS Account *"
                    required={true}
                    placeholder="123456789012"
                    warning={wrongInput === "number"}
                    help={
                      "AWS account number - we need it to create policy documents for you"
                    }
                  />
                  <hr />
                  <Button
                    className="is-pulled-right"
                    disabled={
                      accountAws.name === "" || accountAws.number === ""
                    }
                    onClick={createAwsPolicyHandler}
                    data-cy="create-policy-document"
                  >
                    Create policy document
                  </Button>
                </>
              )}

              {accountCloudProvider === CloudProvider.AwsChina && (
                <>
                  <Field
                    value={accountAwsChina.name}
                    onChange={updateAccountAwsChina}
                    name="name"
                    type="text"
                    label="Account Name *"
                    required={true}
                    help={
                      "This is a name that will be used internally to identify this account"
                    }
                  />
                  <Field
                    value={accountAwsChina.number}
                    onChange={updateAccountAwsChina}
                    name="number"
                    type="text"
                    label="AWS Account *"
                    required={true}
                    placeholder="123456789012"
                    warning={wrongInput === "number"}
                    help={
                      "AWS account number - we need it to create policy documents for you"
                    }
                  />
                  <hr />
                  <Button
                    className="is-pulled-right"
                    onClick={goToAwsChinaPolicy}
                    disabled={
                      accountAwsChina.name === "" ||
                      accountAwsChina.number === ""
                    }
                  >
                    Next - Create Policy Document
                  </Button>
                </>
              )}

              {accountCloudProvider === CloudProvider.Gcp && (
                <>
                  <Field
                    value={accountGcp.name}
                    onChange={updateAccountGcp}
                    name="name"
                    type="text"
                    label="Account Name *"
                    required={true}
                    warning={wrongInput === "name"}
                    help={
                      "This is a name that will be used internally to identify this account"
                    }
                  />
                  <Field
                    value={accountGcp.projectId}
                    onChange={updateAccountGcp}
                    name="projectId"
                    type="text"
                    label="Project Id *"
                    required={true}
                    warning={wrongInput === "projectId"}
                    help={"GCP Project Id"}
                  />
                  <hr />
                  <Button
                    className="is-pulled-right"
                    onClick={GcpCredentialsHandler}
                    disabled={
                      accountGcp.name === "" || accountGcp.projectId === ""
                    }
                  >
                    Next - Provide credentials
                  </Button>
                </>
              )}

              {accountCloudProvider === CloudProvider.Azure && (
                <>
                  <Field
                    value={accountAzure.name}
                    onChange={updateAccountAzure}
                    name="name"
                    type="text"
                    label="Account Name *"
                    required={true}
                    help={
                      "This is a name that will be used internally to identify this account"
                    }
                  />
                  <Field
                    value={accountAzure.subscriptionId}
                    onChange={updateAccountAzure}
                    name="subscriptionId"
                    type="text"
                    label="Subscription Id *"
                    required={true}
                    warning={wrongInput === "subscriptionId"}
                    placeholder="92510729-badd-9610-38ee-f2f255699f70"
                    help={"Azure Subscription Id"}
                  />
                  <hr />
                  <Button
                    className="is-pulled-right"
                    onClick={AzureCredentialsHandler}
                    disabled={
                      accountAzure.name === "" ||
                      accountAzure.subscriptionId === ""
                    }
                  >
                    Next - Provide credentials
                  </Button>
                </>
              )}
              {accountCloudProvider === CloudProvider.Ibmcloud && (
                <>
                  <Field
                    value={accountIBM.name}
                    onChange={updateAccountIbm}
                    name="name"
                    type="text"
                    label="Account Name *"
                    required={true}
                    help={
                      "This is a name that will be used internally to identify this account"
                    }
                  />
                  <Field
                    value={accountIBM.ibmCloudAccountId}
                    onChange={updateAccountIbm}
                    name="ibmCloudAccountId"
                    type="text"
                    label="IBM Cloud Account Id *"
                    required={true}
                    warning={wrongInput === "ibmCloudAccountId"}
                    placeholder="a1b2c3d4e5f61234567890fedcba4321"
                    help={"IBM Cloud Account Id"}
                  />
                  <hr />
                  <Button
                    className="is-pulled-right"
                    onClick={IBMCredentialsHandler}
                    disabled={
                      accountIBM.name === "" ||
                      accountIBM.ibmCloudAccountId === ""
                    }
                  >
                    Next - Provide credentials
                  </Button>
                </>
              )}

              <div className="is-clearfix" />
            </div>
          )}

          {currentStep === 2 && (
            <div className="box">
              {accountCloudProvider === CloudProvider.Aws && (
                <Loading loading={awsLoading}>
                  <div className="columns">
                    <div className="column is-4">
                      <Button
                        outline
                        onClick={async () => {
                          window.open(
                            awsAccessRoleData &&
                              awsAccessRoleData.awsAccountAccessRoleTemplate
                          );
                        }}
                        data-cy="download-role-definition"
                      >
                        Download Role Definition
                      </Button>
                    </div>
                    <div className="column">
                      <p>
                        The role you downloaded needs to be applied on your AWS
                        account to complete account onboarding.
                      </p>
                    </div>
                  </div>
                  <hr />
                  {!awsLoading && (
                    <Button
                      className="is-pulled-right"
                      onClick={finishAwsAccountOnboardingHandler}
                      data-cy="finish-onboarding-aws-account"
                    >
                      Finish Account Onboarding
                    </Button>
                  )}
                  <div className="is-clearfix" />
                </Loading>
              )}

              {accountCloudProvider === CloudProvider.AwsChina && (
                <Loading loading={awsChinaLoading}>
                  <div className="columns">
                    <div className="column">
                      <Field
                        value={
                          accountAwsChina.credentials.staticCredentials
                            ?.accessKeyId
                        }
                        onChange={(_, value) => {
                          setAccountAwsChina((prevAccountAwsChina) => ({
                            ...prevAccountAwsChina,
                            credentials: {
                              ...prevAccountAwsChina.credentials,
                              staticCredentials: {
                                accessKeyId: value,
                                secretAccessKey:
                                  prevAccountAwsChina.credentials
                                    .staticCredentials?.secretAccessKey || "",
                              },
                            },
                          }));
                        }}
                        name="accessKeyId"
                        type="text"
                        label="Access Key Id *"
                        required={true}
                      />
                      <Field
                        value={
                          accountAwsChina.credentials.staticCredentials
                            ?.secretAccessKey
                        }
                        onChange={(_, value) => {
                          setAccountAwsChina((prevAccountAwsChina) => ({
                            ...prevAccountAwsChina,
                            credentials: {
                              ...prevAccountAwsChina.credentials,
                              staticCredentials: {
                                accessKeyId:
                                  prevAccountAwsChina.credentials
                                    .staticCredentials?.accessKeyId || "",
                                secretAccessKey: value,
                              },
                            },
                          }));
                        }}
                        name="secretAccessKey"
                        type="password"
                        label="Secret Access Key *"
                        required={true}
                        warning={awsChinaError && true}
                      />
                      <Button
                        className="is-pulled-right"
                        onClick={() => getAwsChinaAccountAccessRole()}
                        disabled={
                          accountAwsChina.credentials.staticCredentials
                            ?.accessKeyId === "" ||
                          accountAwsChina.credentials.staticCredentials
                            ?.secretAccessKey === ""
                        }
                      >
                        Create Policy Document
                      </Button>
                    </div>
                  </div>

                  <div className="columns">
                    <Loading loading={awsChinaAccessRoleLoading}>
                      {awsChinaAccessRoleData && (
                        <>
                          <div className="column is-4">
                            <Button
                              outline
                              onClick={async () => {
                                window.open(
                                  awsChinaAccessRoleData?.awsChinaAccountAccessRoleTemplate as string
                                );
                              }}
                            >
                              Download Role Definition
                            </Button>
                          </div>
                          <div className="column">
                            <p>
                              The role you downloaded need to be applied on your
                              AWS account to complete account onboarding.
                            </p>
                          </div>
                        </>
                      )}
                    </Loading>
                  </div>

                  {!awsChinaLoading && awsChinaAccessRoleData && (
                    <Button
                      className="is-pulled-right"
                      onClick={finishAwsChinaAccountOnboardingHandler}
                    >
                      Finish Account Onboarding
                    </Button>
                  )}
                  <div className="is-clearfix" />
                </Loading>
              )}

              {accountCloudProvider === CloudProvider.Gcp && (
                <Loading loading={gcpLoading}>
                  <div className="columns">
                    <div className="column is-4">
                      <p>Upload JSON file with credentials.</p>
                    </div>
                    <div className="column">
                      <Button
                        className="has-margin-right"
                        onClick={() => uploadClick()}
                      >
                        Choose file
                      </Button>
                      {uploadedFileName ? (
                        <span>{uploadedFileName}</span>
                      ) : (
                        <span>No file chosen</span>
                      )}
                      <input
                        type="file"
                        id="file"
                        ref={(input) => {
                          inputFile = input;
                        }}
                        className="input-file"
                        accept=".json"
                        onChange={(e) =>
                          handleUploadedFile(e.target.files?.[0])
                        }
                      />
                    </div>
                  </div>
                  <hr />
                  <Button
                    className="is-pulled-right"
                    disabled={!accountGcp.credentials.privateKey}
                    onClick={finishGcpAccountOnboardingHandler}
                  >
                    Finish Account Onboarding
                  </Button>
                  <div className="is-clearfix" />
                </Loading>
              )}

              {accountCloudProvider === CloudProvider.Azure && (
                <Loading loading={azureLoading}>
                  <Field
                    value={accountAzure.credentials.clientId}
                    onChange={(_, value) => {
                      setAccountAzure((prevAccountAzure) => ({
                        ...prevAccountAzure,
                        credentials: {
                          ...prevAccountAzure.credentials,
                          clientId: value,
                        },
                      }));
                    }}
                    name="clientId"
                    type="text"
                    label="Client ID *"
                    required={true}
                    help={""}
                  />
                  <Field
                    value={accountAzure.credentials.clientSecret}
                    onChange={(_, value) => {
                      setAccountAzure((prevAccountAzure) => ({
                        ...prevAccountAzure,
                        credentials: {
                          ...prevAccountAzure.credentials,
                          clientSecret: value,
                        },
                      }));
                    }}
                    name="clientSecret"
                    type="password"
                    label="Client secret *"
                    required={true}
                    help={""}
                  />
                  <Field
                    value={accountAzure.credentials.tenantId}
                    onChange={(_, value) => {
                      setAccountAzure((prevAccountAzure) => ({
                        ...prevAccountAzure,
                        credentials: {
                          ...prevAccountAzure.credentials,
                          tenantId: value,
                        },
                      }));
                    }}
                    name="tenantId"
                    type="password"
                    label="Tenant ID *"
                    required={true}
                    help={""}
                  />
                  <hr />
                  {!azureLoading && (
                    <Button
                      className="is-pulled-right"
                      onClick={finishAzureAccountOnboardingHandler}
                      disabled={
                        accountAzure.credentials.clientId === "" ||
                        accountAzure.credentials.clientSecret === "" ||
                        accountAzure.credentials.tenantId === ""
                      }
                    >
                      Finish Account Onboarding
                    </Button>
                  )}
                  <div className="is-clearfix" />
                </Loading>
              )}

              {accountCloudProvider === CloudProvider.Ibmcloud && (
                <Loading loading={ibmLoading}>
                  <Field
                    value={accountIBM.credentials.apiKey}
                    onChange={(_, value) => {
                      setAccountIBM((prevAccountIbm) => ({
                        ...prevAccountIbm,
                        credentials: {
                          ...prevAccountIbm.credentials,
                          apiKey: value,
                        },
                      }));
                    }}
                    name="apiKey"
                    type="password"
                    label="API Key *"
                    required={true}
                    help={""}
                  />
                  <hr />
                  {!ibmLoading && (
                    <Button
                      className="is-pulled-right"
                      onClick={finishIbmAccountOnboardingHandler}
                      disabled={accountIBM.credentials.apiKey === ""}
                    >
                      Finish Account Onboarding
                    </Button>
                  )}
                  <div className="is-clearfix" />
                </Loading>
              )}
            </div>
          )}

          {currentStep === 3 && (
            <div className="box">
              <InfoCard variant="success">
                <h2 className="title">Account Added</h2>
                <p className="description">
                  {isProtected
                    ? "We are scanning your account. This might take several minutes."
                    : "A vault account was added."}
                </p>
              </InfoCard>
              {isProtected && (
                <InfoCard variant="info">
                  <h2 className="title">What's next?</h2>
                  <p className="description">
                    In order to enable backup for your new resources{" "}
                    <strong>
                      you need to have a Policy and Setups configured
                    </strong>
                    .<br />
                    If you don’t have any yet choose the option below to
                    configure a new Policy or Setup now.
                  </p>
                </InfoCard>
              )}
              <div className="columns">
                <div className="column">
                  <Link to={ROUTES.settings.index.generate()}>
                    <Card>
                      <p className="description">
                        Configure Policies and Setups
                      </p>
                      <h2 className="title">Settings</h2>
                    </Card>
                  </Link>
                </div>
                <div className="column">
                  <Link
                    to={ROUTES.resources.index.generate(
                      {},
                      {
                        filters: `[{"id":"RESOURCES_ACCOUNT_ID","values":["${newAccountId}"]}]`,
                      }
                    )}
                  >
                    <Card>
                      <p className="description">
                        Check your resources for new account
                      </p>
                      <h2 className="title">Resource View</h2>
                    </Card>
                  </Link>
                </div>
                <div className="column">
                  <Link
                    to={ROUTES.accounts.index.generate()}
                    data-cy="add-new-account"
                  >
                    <Card>
                      <p className="description">Add another account</p>
                      <h2 className="title">Add new Account</h2>
                    </Card>
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AccountsForm;
