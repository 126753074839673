/**
 * Copyright 2021-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from "react";
import { ApolloError } from "apollo-client";
import { ApiKey } from "../../../../generated/graphql";
import { Error } from "../../../../commonComponents/error/error";
import DetailsNotFound from "../../../../commonComponents/detailsNotFound/DetailsNotFound";
import { Loading } from "../../../../commonComponents/loading/Loading";

interface ApiKeyDetailsWrapperProps {
  children: React.ReactNode;
  error?: ApolloError;
  apiKey?: ApiKey;
  redirect: string;
  loading: boolean;
}

const ApiKeyDetailsWrapper: React.FC<ApiKeyDetailsWrapperProps> = ({
  children,
  error,
  apiKey,
  redirect,
  loading,
}) => {
  return (
    <div className="main wide">
      <div className="container is-fluid container-padding">
        {error && <Error error={error} />}
        {apiKey ? (
          <>{children}</>
        ) : (
          <Loading loading={loading}>
            <DetailsNotFound
              message={error?.graphQLErrors
                .map((gqlError) => gqlError.message)
                .join(", ")}
              route={redirect}
            />
          </Loading>
        )}
      </div>
    </div>
  );
};
export default ApiKeyDetailsWrapper;
