/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from "react";

const Placeholder = ({ message }: any): any => {
  return (
    <>
      <p className="has-text-centered">
        <i style={{ fontSize: "6rem", opacity: 0.1 }} className="fa fa-times" />
      </p>
      <p className="has-text-centered">{message}</p>
    </>
  );
};

export default Placeholder;
