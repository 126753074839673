/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { useEffect, useState } from "react";
import { RouteComponentProps, useLocation } from "react-router-dom";
import queryString from "query-string";
import { unstable_batchedUpdates } from "react-dom";
import { useGetMyApiKeysQuery } from "../../../generated/graphql";
import ApiKeysTable from "../../../shared/apiKeys/ApiKeysTable";
import ApiKeyStringModal from "../../../shared/apiKeys/ApiKeyStringModal";
import MyApiKeyCreateModel from "../components/MyApiKeyCreateModal";
import useMyApiKeysColumns from "../../../shared/apiKeys/columns/useMyApiKeysColumns";

const MyApiKeysPage: React.FC<RouteComponentProps> = ({ history }) => {
  const location = useLocation();
  const pageFromUrl = (): number => {
    if (location.search === "") return 1;

    const params = queryString.parse(location.search);
    const pageUrl = params.page;
    if (pageUrl) {
      return +pageUrl;
    }

    return 1;
  };

  const [currentPage, setCurrentPage] = useState(pageFromUrl);
  const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
  const [apiKeyStringModalIsOpen, setApiKeyStringModalIsOpen] =
    useState<boolean>(false);
  const [apiKeyString, setApiKeyString] = useState<string | undefined>("");

  const { columns } = useMyApiKeysColumns();
  useEffect(() => {
    const search = `?page=${currentPage}`;
    if (location.search === search) return;

    if (location.search === "") {
      history.replace({ search });
    } else {
      history.push({ search });
    }
  }, [currentPage]);

  useEffect(() => {
    unstable_batchedUpdates(() => {
      setCurrentPage(pageFromUrl());
    });
  }, [location]);

  const { data, error, loading, refetch } = useGetMyApiKeysQuery({
    variables: {
      searchCriteria: {
        pageNumber: currentPage,
      },
    },
  });

  return (
    <div className="main wide">
      <div className="container is-fluid container-padding">
        <ApiKeysTable
          apiKeysData={data?.myApiKeys}
          apiKeysError={error}
          apiKeysLoading={loading}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          title="My API keys"
          columns={columns}
          setCreateModalIsOpen={setCreateModalIsOpen}
        />
        {createModalIsOpen && (
          <MyApiKeyCreateModel
            setIsOpen={setCreateModalIsOpen}
            setApiKeyString={setApiKeyString}
            setApiKeyStringModalOpen={setApiKeyStringModalIsOpen}
            refetch={refetch}
          />
        )}
        {apiKeyStringModalIsOpen && apiKeyString && !createModalIsOpen && (
          <ApiKeyStringModal
            setIsOpen={setApiKeyStringModalIsOpen}
            data={apiKeyString}
          />
        )}
      </div>
    </div>
  );
};

export default MyApiKeysPage;
