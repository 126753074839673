/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { useContext, useState } from "react";
import { CustomerContext } from "../../../context/customer";
import {
  GetUserRoleQuery,
  useGetBackupSetupsQuery,
  useRemoveBackupSetupMutation,
  UserRole,
} from "../../../generated/graphql";
import { Loading } from "../../../commonComponents/loading/Loading";
import Table from "../../../commonComponents/table/Table";
import { confirmations, toastMessages } from "../../../utils/constants";
import { toast } from "react-toastify";
import { History } from "history";
import { Error } from "../../../commonComponents/error/error";
import Placeholder from "../../../commonComponents/table/Placeholder";
import Popup from "react-popup";
import { ROUTES } from "../../../routes/routes";
import errorToast from "../../../utils/errorToast";

interface Props {
  userRole: GetUserRoleQuery | any;
  userRoleLoading: boolean;
  history: History;
}

const SetupsList: React.FC<Props> = ({
  userRole,
  userRoleLoading,
  history,
}) => {
  const { customer } = useContext(CustomerContext);
  const [removeId, setRemoveId] = useState("");

  const customerId = customer && customer.id ? customer.id : "";
  const { data, loading, refetch, error } = useGetBackupSetupsQuery({
    variables: {
      searchCriteria: {
        customerId: customerId,
      },
    },
  });

  const [removeSetup, { loading: removeSetupLoading }] =
    useRemoveBackupSetupMutation({
      variables: {
        input: {
          customerId: customerId,
          setupId: removeId,
        },
      },
    });

  if (removeId) {
    removeSetup()
      .then(() => {
        refetch();
        toast.success(toastMessages.SETUP_REMOVED);
      })
      .catch((e) => errorToast(e, toastMessages.SETUP_REMOVAL_ERROR));
    setRemoveId("");
  }

  const onDeleteHandler = (id: string) => {
    Popup.create({
      onClose: () => {
        Popup.close();
      },
      title: `Delete Setup`,
      content: (
        <div className="box">
          <p>{confirmations.DELETE_SETUP}</p>
        </div>
      ),
      buttons: {
        right: [
          {
            text: "Cancel",
            className: "btn",
            action: () => {
              Popup.close();
            },
          },
          {
            text: "Delete",
            className: "btn",
            action: async () => {
              setRemoveId(id);
              Popup.close();
            },
          },
        ],
      },
    });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Resource type",
        accessor: "resourceType",
      },
      {
        Header: " ",
        Cell: ({ row }: any) => (
          <div>
            <div className="navbar-item has-dropdown is-hoverable is-pulled-right">
              <span className="icon">
                <i className="fa fa-ellipsis-v" />
              </span>
              <div className="navbar-dropdown is-right">
                {userRole?.userRole !== UserRole.ReadOnly && (
                  <a
                    className="navbar-item"
                    onClick={() => onDeleteHandler(row.original.id)}
                    data-cy="delete-setup"
                  >
                    <span className="icon">
                      <i className="fa fa-trash" />
                    </span>
                    <span>Delete</span>
                  </a>
                )}
                <a
                  className="navbar-item"
                  onClick={() =>
                    history.push(
                      ROUTES.settings.setups.details.generate({
                        id: row.original.id,
                      })
                    )
                  }
                >
                  <span className="icon">
                    <i className="fa fa-eye" />
                  </span>
                  {userRole?.userRole !== UserRole.ReadOnly ? (
                    <span>View / Edit</span>
                  ) : (
                    <span>View</span>
                  )}
                </a>
              </div>
            </div>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <Loading loading={loading || userRoleLoading}>
      {error && <Error error={error} />}
      {data && data.backupSetups && data.backupSetups.length > 0 ? (
        <>
          <Loading loading={removeSetupLoading} />
          <Table columns={columns} data={data.backupSetups} disableSortBy />
        </>
      ) : (
        <Placeholder message="Currently there are no backup setups. Create one now." />
      )}
    </Loading>
  );
};

export default SetupsList;
