/**
 * Copyright 2021-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Modal } from "../../commonComponents/modal/Modal";
import SimpleReactValidator from "simple-react-validator";
import Select from "react-select";
import Button from "../../commonComponents/button/Button";
import { CustomerAccess, UserRole } from "../../generated/graphql";
import { toast } from "react-toastify";
import { toastMessages } from "../../utils/constants";
import { Loading } from "../../commonComponents/loading/Loading";
import Popup from "react-popup";
import { arrayCompare } from "../../utils/arrayCompare";
import { ObservableQuery } from "apollo-client/core/ObservableQuery";

interface CustomersUpdateRoleModalProps {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  customerData: CustomerAccess;
  type: "id" | "email";
  identifier: string;
  mutationGrant: any;
  mutationRevoke: any;
  refetch: ObservableQuery["refetch"];
}

export const rolesEnums = [
  { label: "admin", value: UserRole.Admin },
  { label: "read only", value: UserRole.ReadOnly },
];

const validator = new SimpleReactValidator();

const CustomersUpdateRoleModal = ({
  setIsOpen,
  customerData,
  type,
  identifier,
  mutationGrant,
  mutationRevoke,
  refetch,
}: CustomersUpdateRoleModalProps) => {
  const [validateError, setValidateError] = useState(false);
  const [settingsValidationErrors, setSettingsValidationErrors] =
    useState<any>(null);
  let validationError;
  const [loading, setLoading] = useState(false);

  const [roles, setRoles] = useState<Array<UserRole>>([]);

  useEffect(() => {
    setRoles(customerData.roles);
  }, []);

  const [grantRole] = mutationGrant();
  const [revokeRole] = mutationRevoke();

  const updatingCustomerRolesHandler = async () => {
    validateError && setValidateError(false);
    if (validator.allValid()) {
      setLoading(true);
      try {
        await Promise.all(
          roles.map(async (el) => {
            if (!customerData.roles.includes(el)) {
              return grantRole({
                variables: {
                  input: {
                    customerId: customerData.customer.id,
                    role: el,
                    [type]: identifier,
                  },
                },
              });
            }
          })
        );
        await Promise.all(
          customerData.roles.map(async (el) => {
            if (!roles.includes(el)) {
              return revokeRole({
                variables: {
                  input: {
                    customerId: customerData.customer.id,
                    role: el,
                    [type]: identifier,
                  },
                },
              });
            }
          })
        );
        toast.success(toastMessages.UPDATE_CUSTOMER_ROLE);
        await refetch();
      } catch (e) {
        toast.error(toastMessages.UPDATE_CUSTOMER_ROLE_ERROR);
        setSettingsValidationErrors(e.graphQLErrors);
      } finally {
        setLoading(false);
        setIsOpen(false);
      }
    }
  };

  const finishUpdatingCustomerHandler = async () => {
    if (roles.length === 0) {
      Popup.create({
        onClose: () => {
          Popup.close();
        },
        title: `Delete customer accesses`,
        content: (
          <div className="box">
            <p>{`Do you want to completely remove customer accesses from ${identifier}?`}</p>
          </div>
        ),
        buttons: {
          right: [
            {
              text: "Cancel",
              className: "btn",
              action: () => {
                Popup.close();
              },
            },
            {
              text: "Remove",
              className: "btn",
              action: async () => {
                await updatingCustomerRolesHandler();
                Popup.close();
              },
            },
          ],
        },
      });
    } else {
      await updatingCustomerRolesHandler();
    }
  };

  return (
    <Modal onHide={() => setIsOpen(false)} title="Grant accesses">
      <div style={{ minHeight: "10rem", position: "relative" }}>
        <div className="field">
          <label className="label">Roles*</label>
          <Loading loading={loading}>
            <Select
              isMulti
              isClearable={false}
              value={roles.map((el) => ({
                value: el,
                label: rolesEnums.find((role) => role.value === el)?.label,
              }))}
              onChange={(el: any) => {
                Array.isArray(el)
                  ? setRoles(el.map(({ value }: any) => value))
                  : setRoles([]);
              }}
              options={rolesEnums.map((role) => ({
                value: role.value,
                label: role.label,
              }))}
            />
            {settingsValidationErrors &&
              (validationError = settingsValidationErrors?.find(
                (e: any) => e.errorInfo === "roles"
              )) && (
                <p className="validation-error">{validationError.message}</p>
              )}
          </Loading>
        </div>
        <div style={{ position: "absolute", left: 0, bottom: 0 }}>
          <Button
            onClick={finishUpdatingCustomerHandler}
            disabled={arrayCompare(roles, customerData.roles)}
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CustomersUpdateRoleModal;
