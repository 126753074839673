/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { ReactNode } from "react";

type Props = {
  label: string;
  type: string;
  onChange: (field: string, value: any, parentField?: string) => void;
  name: string;
  value: any;
  className?: string;
  placeholder?: string;
  help?: string;
  required?: boolean;
  disabled?: boolean;
  warning?: boolean;
  parentField?: string;
  children?: ReactNode;
};

const Field: React.FC<Props> = ({
  label,
  type,
  className,
  placeholder,
  help,
  required,
  disabled,
  onChange,
  value,
  name,
  warning,
  parentField,
  children,
}) => (
  <div className={`field +${className}`}>
    <label className="label">{label}</label>
    <div className="control">
      {children ? (
        children
      ) : (
        <input
          className={`${
            type === "text" || type === "password" || !type ? "input" : ""
          } ${warning && "is-danger"}`}
          type={type}
          placeholder={placeholder}
          required={required}
          disabled={disabled}
          onChange={(e) =>
            onChange(
              name,
              type === "checkbox" ? !value : e.target.value,
              parentField
            )
          }
          value={value}
          checked={type === "checkbox" && value}
          data-cy={label}
        />
      )}
    </div>
    {help && <p className="help">{help}</p>}
  </div>
);

export { Field };
