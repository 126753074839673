/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from "react";
import { Link } from "react-router-dom";
import withBreadcrumbs from "react-router-breadcrumbs-hoc";

const eventIdRegex = /[0-9a-f]{64}/;
const policySetupIdRegex =
  /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/;
const userDetailsRegex = /@nordcloud.com/;

const PureBreadcrumbs = ({ breadcrumbs }: any) => (
  <div className="breadcrumbs">
    {breadcrumbs.map(({ breadcrumb, match }: any, index: number) => (
      <div className="bc" key={match.url}>
        <Link to={match.url || ""}>
          <span
            className={
              userDetailsRegex.test(breadcrumb.key) ||
              eventIdRegex.test(breadcrumb.key) ||
              policySetupIdRegex.test(breadcrumb.key)
                ? "lowercase"
                : ""
            }
          >
            {breadcrumb}
          </span>
        </Link>
        {index < breadcrumbs.length - 1 && "/"}
      </div>
    ))}
  </div>
);

export default withBreadcrumbs()(PureBreadcrumbs);
