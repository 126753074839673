/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { useEffect, useState } from "react";
import { RouteComponentProps, useHistory, useLocation } from "react-router-dom";
import ExternalResources from "../components/ExternalResources";
import Filters from "../../../commonComponents/filters/Filters";
import { FilteredDataType } from "../../../generated/graphql";
import queryString from "query-string";

const ExternalResourcesPage: React.FC<RouteComponentProps> = () => {
  const [filters, setFilters] = useState<any>();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (location.search && !filters) {
      const params = queryString.parse(location.search);
      const filtersFromUrl = JSON.parse(params.filters as string);
      setFilters(filtersFromUrl);
    }
  }, [location, filters]);

  useEffect(() => {
    history.replace({
      search: `filters=${JSON.stringify(filters ? filters : [])}`,
    });
  }, [filters, history]);

  return (
    <>
      <Filters
        dataType={FilteredDataType.ExternallyBackedUpResources}
        applyFiltersHandler={setFilters}
        appliedFilters={filters ? filters : []}
        size="is-3"
      />
      <ExternalResources filters={filters ? filters : []} />
    </>
  );
};

export default ExternalResourcesPage;
