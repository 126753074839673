/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  CloudProvider,
  TemplateResourcesMappingInput,
  useGetTemplatesQuery,
} from "../../../generated/graphql";
import React, { useEffect } from "react";
import Select from "react-select";
import { Loading } from "../../../commonComponents/loading/Loading";
import Input from "../../../commonComponents/input/Input";
import TemplateResources from "./TemplateResources";
import Popup from "react-popup";
import Button from "../../../commonComponents/button/Button";

interface Props {
  customerId: string;
  templatesResourcesMapping: Array<TemplateResourcesMappingInput>;
  templatesResourcesMappingSetter: (
    templates: Array<TemplateResourcesMappingInput>
  ) => void;
  cloudProvider: CloudProvider;
  readOnly: boolean;
}

const TemplatesSelect: React.FC<Props> = ({
  customerId,
  templatesResourcesMapping,
  templatesResourcesMappingSetter,
  cloudProvider,
  readOnly,
}) => {
  let currentPage = 1;

  const { data, loading, fetchMore } = useGetTemplatesQuery({
    variables: {
      searchCriteria: {
        customerId: customerId,
        cloudProvider: cloudProvider,
        pageNumber: currentPage,
      },
    },
  });

  const handleFetchMore = async () => {
    while (currentPage < (data?.templates.totalNumberOfPages || 0)) {
      currentPage = currentPage + 1;
      try {
        await fetchMore({
          variables: {
            searchCriteria: {
              customerId: customerId,
              cloudProvider: cloudProvider,
              pageNumber: currentPage,
            },
          },
          updateQuery: (previousResult: any, { fetchMoreResult }: any) => {
            if (!fetchMoreResult) return previousResult;
            const previousEntry = previousResult.templates;
            const newTemplates = fetchMoreResult.templates.data;
            return {
              templates: {
                data: [...newTemplates, ...previousEntry.data],
                totalNumberOfTemplates: previousEntry.totalNumberOfTemplates,
                totalNumberOfPages: previousEntry.totalNumberOfPages,
                __typename: previousEntry.__typename,
              },
            };
          },
        });
      } catch (e) {
        console.error(e);
      }
    }
  };

  useEffect(() => {
    handleFetchMore();
  });

  const AddTemplatesButton: React.FC<any> = ({ templates, setter }) => (
    <Button
      disabled={
        templates.length > 0 &&
        templates[templates.length - 1].templateId === ""
      }
      onClick={() => {
        setter([
          ...templates,
          {
            templateId: "",
            resourcesMapping: [],
          },
        ]);
      }}
    >
      Add another template
    </Button>
  );

  const RemoveTemplatesButton: React.FC<any> = ({
    templates,
    setter,
    index,
  }) => (
    <a
      className="is-pulled-right"
      onClick={() => {
        setter(
          templates.filter((template: string) => template !== templates[index])
        );
      }}
    >
      Remove template
    </a>
  );

  const showResources = (setter: (resourceId: string) => void) => {
    Popup.create({
      onClose: () => {
        Popup.close();
      },
      title: "Choose resource",
      content: (
        <TemplateResources
          resourceSetter={setter}
          cloudProvider={cloudProvider}
        />
      ),
      buttons: {
        right: [
          {
            text: "Close",
            className: "btn",
            action: async () => {
              Popup.close();
            },
          },
        ],
      },
    });
  };

  return (
    <Loading loading={loading}>
      {data && data.templates && (
        <div className="field">
          {templatesResourcesMapping.map(
            (
              templateResourcesMapping: TemplateResourcesMappingInput,
              index: number
            ) => (
              <div key={templateResourcesMapping.templateId}>
                <div className="field">
                  <Select
                    defaultValue={{
                      value: templateResourcesMapping.templateId,
                      label: data?.templates.data.find(
                        (template) =>
                          template.id === templateResourcesMapping.templateId
                      )?.name,
                    }}
                    isSearchable={true}
                    onChange={(e: any) => {
                      const newTemplates = [...templatesResourcesMapping];
                      newTemplates[index] = {
                        templateId: e.value,
                        resourcesMapping: [],
                      };
                      templatesResourcesMappingSetter(newTemplates);
                    }}
                    options={data.templates.data.map((template) => ({
                      value: template.id,
                      label: template.name,
                    }))}
                    isDisabled={readOnly}
                  />
                </div>
                {data?.templates.data
                  .find(
                    (template) =>
                      template.id === templateResourcesMapping.templateId
                  )
                  ?.parameters?.map((parameter) => (
                    <div
                      key={`${templateResourcesMapping.templateId}-${parameter}`}
                    >
                      <label className="label">{parameter}</label>
                      <Input
                        value={
                          templateResourcesMapping.resourcesMapping?.find(
                            (resourceMapping) =>
                              resourceMapping.parameter === parameter
                          )?.resourceId || ""
                        }
                        onClick={() => {
                          showResources((resourceId: string) => {
                            const newTemplates = [...templatesResourcesMapping];
                            const resourcesMapping =
                              newTemplates[index].resourcesMapping || [];
                            const parameterIndex = resourcesMapping.findIndex(
                              (resourceMapping) =>
                                resourceMapping.parameter === parameter
                            );
                            parameterIndex === -1
                              ? resourcesMapping.push({
                                  parameter: parameter,
                                  resourceId: resourceId,
                                })
                              : (resourcesMapping[parameterIndex] = {
                                  parameter: parameter,
                                  resourceId: resourceId,
                                });
                            newTemplates[index].resourcesMapping =
                              resourcesMapping;
                            templatesResourcesMappingSetter(newTemplates);
                          });
                        }}
                        onChange={() => {
                          // onChange supported in the onClick function
                        }}
                        type="text"
                        disabled={readOnly}
                      />
                    </div>
                  ))}
                {templatesResourcesMapping.length > 1 && !readOnly && (
                  <RemoveTemplatesButton
                    templates={templatesResourcesMapping}
                    setter={templatesResourcesMappingSetter}
                    index={index}
                  />
                )}
                <hr />
              </div>
            )
          )}
          {!readOnly && (
            <AddTemplatesButton
              templates={templatesResourcesMapping}
              setter={templatesResourcesMappingSetter}
            />
          )}
        </div>
      )}
    </Loading>
  );
};

export default TemplatesSelect;
