/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { useContext } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { CustomerContext } from "../../../context/customer";
import {
  PolicySettings,
  PolicyType,
  useGetBackupPolicyQuery,
} from "../../../generated/graphql";
import { Loading } from "../../../commonComponents/loading/Loading";
import { Error } from "../../../commonComponents/error/error";
import Input from "../../../commonComponents/input/Input";
import Button from "../../../commonComponents/button/Button";
import { ROUTES } from "../../../routes/routes";
import Segment from "../../../commonComponents/segment/Segment";
import { CONDITIONS, FREQUENCIES, TYPES } from "./policiesConstants";
import {
  conditionalRemovePrimary,
  conditionalRemoveReplica,
  convertTimestampToDate,
} from "../../../utils/helpers";
import ReactTooltip from "react-tooltip";
import { BackupPolicyTooltips } from "../../../utils/constants";

const PolicyDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();

  const { customer } = useContext(CustomerContext);
  const customerId = customer && customer.id ? customer.id : "";
  const backupPolicyId = id || location.pathname.split("/")[3];

  const { data, error, loading } = useGetBackupPolicyQuery({
    variables: {
      searchCriteria: {
        customerId: customerId,
        backupPolicyId: backupPolicyId,
      },
    },
  });

  const settingsLength = data?.backupPolicy?.policySettings?.length;

  return (
    <div className="main wide">
      <Loading loading={loading}>
        {error && <Error error={error} />}
        {data?.backupPolicy && (
          <div className="box">
            <div className="columns is-vcentered border-bottom">
              <div className="column is-7">
                <h1 className="title is-marginless is-5 is-capitalized">
                  Policy Details
                </h1>
              </div>
              <div className="column is-5">
                <Link to={ROUTES.settings.policies.index.generate()}>
                  <Button className="is-pulled-right">Back to Policies</Button>
                </Link>
              </div>
            </div>
            <>
              <div className="columns">
                <div className="column is-4-desktop">
                  <div className="field">
                    <label className="label">Policy ID</label>
                    <Input value={data?.backupPolicy.id} type="text" disabled />
                  </div>

                  <div className="field">
                    <label className="label">
                      Policy name
                      <span
                        className="icon"
                        data-tip={BackupPolicyTooltips.name}
                      >
                        <i className="fa fa-question-circle" />
                      </span>
                    </label>
                    <Input
                      value={data?.backupPolicy.name}
                      type="text"
                      disabled
                    />
                  </div>

                  <div className="field">
                    <label className="label">
                      Policy type
                      <span
                        className="icon"
                        data-tip={BackupPolicyTooltips.type}
                      >
                        <i className="fa fa-question-circle" />
                      </span>
                    </label>
                    <Segment tabs>
                      {TYPES.map((button) => (
                        <Button
                          key={`type-${button.value}`}
                          className={
                            data?.backupPolicy?.type === button.value
                              ? "active"
                              : ""
                          }
                          select
                          disabled
                        >
                          {button.label}
                        </Button>
                      ))}
                    </Segment>
                  </div>
                  {conditionalRemovePrimary(
                    data?.backupPolicy?.type,
                    data?.backupPolicy?.policySettings,
                    data?.backupPolicy?.timeAwarePolicySettings
                  ) && (
                    <div className="field-checkbox">
                      <input
                        checked={
                          data?.backupPolicy
                            .removePrimarySnapshotsAfterResourceDeletion
                        }
                        type="checkbox"
                        disabled
                      />
                      <label className="label">
                        Remove primary snapshots after resource deletion
                        <span
                          className="icon"
                          data-tip={
                            BackupPolicyTooltips.removePrimarySnapshotsAfterResourceDeletion
                          }
                        >
                          <i className="fa fa-question-circle" />
                        </span>
                      </label>
                    </div>
                  )}
                  {conditionalRemoveReplica(
                    data?.backupPolicy?.type,
                    data?.backupPolicy?.policySettings,
                    data?.backupPolicy?.timeAwarePolicySettings
                  ) && (
                    <div className="field-checkbox">
                      <input
                        checked={
                          data?.backupPolicy
                            .removeReplicaSnapshotsAfterResourceDeletion
                        }
                        type="checkbox"
                        disabled
                      />
                      <label className="label">
                        Remove replica snapshots after resource deletion
                        <span
                          className="icon"
                          data-tip={
                            BackupPolicyTooltips.removeReplicaSnapshotsAfterResourceDeletion
                          }
                        >
                          <i className="fa fa-question-circle" />
                        </span>
                      </label>
                    </div>
                  )}
                </div>
              </div>
              {data?.backupPolicy.type === PolicyType.Rpo &&
                data?.backupPolicy?.policySettings?.map(
                  (setting: PolicySettings, index: number) => (
                    <div key={`rpo-setting-${index}`}>
                      <div className="columns">
                        <div className="column">
                          <label className="label">
                            Snapshot frequency
                            <span
                              className="icon"
                              data-tip={BackupPolicyTooltips.snapshotFrequency}
                            >
                              <i className="fa fa-question-circle" />
                            </span>
                          </label>
                          <div className="field">
                            <Segment tabs>
                              {FREQUENCIES.map((button) => (
                                <Button
                                  key={`frequency-${button.value}`}
                                  className={
                                    setting.frequency === button.value
                                      ? "active"
                                      : ""
                                  }
                                  select
                                  disabled
                                >
                                  {button.label}
                                </Button>
                              ))}
                            </Segment>
                          </div>
                          <div className="field">
                            <label
                              className="label"
                              style={{ marginTop: "0.75rem" }}
                            >
                              Value
                              <span
                                className="icon"
                                data-tip={
                                  BackupPolicyTooltips.snapshotFrequencyValue
                                }
                              >
                                <i className="fa fa-question-circle" />
                              </span>
                            </label>
                            <Input value={setting.rpo} type="number" disabled />
                          </div>
                        </div>
                        <div className="column">
                          <div className="field">
                            <label className="label">
                              Primary snapshot condition
                              <span
                                className="icon"
                                data-tip={
                                  BackupPolicyTooltips.primarySnapshotCondition
                                }
                              >
                                <i className="fa fa-question-circle" />
                              </span>
                            </label>
                            <Segment tabs>
                              {CONDITIONS.map((button) => (
                                <Button
                                  key={`primary-condition-${button.value}`}
                                  className={
                                    setting.retention.primarySnapshotsRetention
                                      .condition === button.value
                                      ? "active"
                                      : ""
                                  }
                                  select
                                  disabled
                                >
                                  {button.label}
                                </Button>
                              ))}
                            </Segment>
                          </div>
                          <div className="field">
                            <label className="label">
                              Primary snapshot threshold
                              <span
                                className="icon"
                                data-tip={
                                  BackupPolicyTooltips.primarySnapshotThreshold
                                }
                              >
                                <i className="fa fa-question-circle" />
                              </span>
                            </label>
                            <Input
                              value={
                                setting.retention.primarySnapshotsRetention
                                  .threshold
                              }
                              type="number"
                              disabled
                            />
                          </div>
                        </div>
                        <div className="column">
                          <div className="field">
                            <label className="label">
                              Replica snapshot condition
                              <span
                                className="icon"
                                data-tip={
                                  BackupPolicyTooltips.replicaSnapshotCondition
                                }
                              >
                                <i className="fa fa-question-circle" />
                              </span>
                            </label>
                            <Segment tabs>
                              {CONDITIONS.map((button) => (
                                <Button
                                  key={`replica-condition-${button.value}`}
                                  className={
                                    setting.retention.replicaSnapshotsRetention
                                      .condition === button.value
                                      ? "active"
                                      : ""
                                  }
                                  select
                                  disabled
                                >
                                  {button.label}
                                </Button>
                              ))}
                            </Segment>
                          </div>
                          <div className="field">
                            <label className="label">
                              Replica snapshot threshold
                              <span
                                className="icon"
                                data-tip={
                                  BackupPolicyTooltips.replicaSnapshotThreshold
                                }
                              >
                                <i className="fa fa-question-circle" />
                              </span>
                            </label>
                            <Input
                              value={
                                setting.retention.replicaSnapshotsRetention
                                  .threshold
                              }
                              type="number"
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                      {settingsLength && index < settingsLength - 1 && <hr />}
                    </div>
                  )
                )}
              {data?.backupPolicy.type === PolicyType.TimeAware && (
                <div className="columns">
                  <div className="column">
                    <div className="field">
                      <div className="field">
                        <label className="label">
                          Cron expression
                          <span
                            className="icon"
                            data-tip={BackupPolicyTooltips.cronExpression}
                          >
                            <i className="fa fa-question-circle" />
                          </span>
                        </label>
                        <Input
                          value={
                            data?.backupPolicy.timeAwarePolicySettings
                              ?.cronExpression
                          }
                          disabled
                        />
                      </div>
                    </div>
                    <div className="field">
                      <div className="field">
                        <label className="label">
                          Backup time window duration in hours
                          <span
                            className="icon"
                            data-tip={
                              BackupPolicyTooltips.backupTimeWindowDurationInHours
                            }
                          >
                            <i className="fa fa-question-circle" />
                          </span>
                        </label>
                        <Input
                          value={
                            data?.backupPolicy.timeAwarePolicySettings
                              ?.timeWindowDuration
                          }
                          disabled
                        />
                      </div>
                    </div>
                    <div className="field">
                      <div className="field">
                        <label className="label">
                          Start date
                          <span
                            className="icon"
                            data-tip={BackupPolicyTooltips.startDate}
                          >
                            <i className="fa fa-question-circle" />
                          </span>
                        </label>
                        <Input
                          value={
                            data?.backupPolicy?.timeAwarePolicySettings
                              ?.startDateTimestamp &&
                            convertTimestampToDate(
                              data?.backupPolicy.timeAwarePolicySettings
                                .startDateTimestamp
                            )
                          }
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div className="column">
                    <div className="field">
                      <label className="label">
                        Primary snapshot condition
                        <span
                          className="icon"
                          data-tip={
                            BackupPolicyTooltips.primarySnapshotCondition
                          }
                        >
                          <i className="fa fa-question-circle" />
                        </span>
                      </label>
                      <Segment tabs>
                        {CONDITIONS.map((button) => (
                          <Button
                            key={`primary-condition-${button.value}`}
                            className={
                              data?.backupPolicy?.timeAwarePolicySettings
                                ?.retention.primarySnapshotsRetention
                                .condition === button.value
                                ? "active"
                                : ""
                            }
                            select
                            disabled
                          >
                            {button.label}
                          </Button>
                        ))}
                      </Segment>
                    </div>
                    <div className="field">
                      <label className="label">
                        Primary snapshot threshold
                        <span
                          className="icon"
                          data-tip={
                            BackupPolicyTooltips.primarySnapshotThreshold
                          }
                        >
                          <i className="fa fa-question-circle" />
                        </span>
                      </label>
                      <Input
                        value={
                          data?.backupPolicy.timeAwarePolicySettings?.retention
                            .primarySnapshotsRetention.threshold
                        }
                        type="number"
                        disabled
                      />
                    </div>
                  </div>
                  <div className="column">
                    <div className="field">
                      <label className="label">
                        Replica snapshot condition
                        <span
                          className="icon"
                          data-tip={
                            BackupPolicyTooltips.replicaSnapshotThreshold
                          }
                        >
                          <i className="fa fa-question-circle" />
                        </span>
                      </label>
                      <Segment tabs>
                        {CONDITIONS.map((button) => (
                          <Button
                            key={`replica-condition-${button.value}`}
                            className={
                              data?.backupPolicy?.timeAwarePolicySettings
                                ?.retention.replicaSnapshotsRetention
                                .condition === button.value
                                ? "active"
                                : ""
                            }
                            select
                            disabled
                          >
                            {button.label}
                          </Button>
                        ))}
                      </Segment>
                    </div>
                    <div className="field">
                      <label className="label">
                        Replica snapshot threshold
                        <span
                          className="icon"
                          data-tip={
                            BackupPolicyTooltips.replicaSnapshotThreshold
                          }
                        >
                          <i className="fa fa-question-circle" />
                        </span>
                      </label>
                      <Input
                        value={
                          data?.backupPolicy.timeAwarePolicySettings?.retention
                            .replicaSnapshotsRetention.threshold
                        }
                        type="number"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              )}
              <ReactTooltip
                effect="solid"
                className="setup-tooltip"
                multiline={true}
              />
            </>
          </div>
        )}
      </Loading>
    </div>
  );
};

export default PolicyDetails;
