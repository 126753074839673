/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { useContext, useEffect, useState } from "react";
import { CustomerContext } from "../../../context/customer";
import { useLocation } from "react-router-dom";
import { History } from "history";
import {
  DisasterRecoveryStatus,
  TemplateLink,
  UpdateDisasterRecoveryStatus,
  useGetDisasterRecoveryRecordsQuery,
  useUpdateDisasterRecoveryRecordMutation,
} from "../../../generated/graphql";
import { Loading } from "../../../commonComponents/loading/Loading";
import { Error } from "../../../commonComponents/error/error";
import Table from "../../../commonComponents/table/Table";
import Placeholder from "../../../commonComponents/table/Placeholder";
import Pagination from "../../../commonComponents/pagination/Pagination";
import Moment from "react-moment";
import {
  DisasterRecoveryRecordTooltips,
  toastMessages,
} from "../../../utils/constants";
import Popup from "react-popup";
import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";
import Textarea from "../../../commonComponents/textarea/Textarea";
import ReactTooltip from "react-tooltip";
import { Icon } from "../../../commonComponents/icon/Icon";

interface Props {
  currentPage: number;
  setCurrentPage: any;
  history: History;
}

const PAGE_SIZE = 50;

const RecordsList: React.FC<Props> = ({
  currentPage,
  setCurrentPage,
  history,
}) => {
  const { customer } = useContext(CustomerContext);
  const customerId = customer?.id || "";
  const location = useLocation();

  const [totalNumberOfRecords, setTotalNumberOfRecords] = useState<
    number | undefined
  >(0);
  const [totalNumberOfPages, setTotalNumberOfPages] = useState<
    number | undefined
  >(0);
  const [note, setNote] = useState<string | undefined>(undefined);
  const [recordId, setRecordId] = useState<string>("");
  const [status, setStatus] = useState<
    UpdateDisasterRecoveryStatus | undefined
  >(undefined);

  const nextPage = () => {
    if (totalNumberOfPages && currentPage < totalNumberOfPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const getRecordsNumber = () => {
    if (
      data?.disasterRecoveryRecords.data &&
      data?.disasterRecoveryRecords.data.length < PAGE_SIZE &&
      currentPage === 1
    ) {
      return data?.disasterRecoveryRecords.data.length;
    }
    if (
      data?.disasterRecoveryRecords.data &&
      data?.disasterRecoveryRecords.data.length < PAGE_SIZE &&
      currentPage === totalNumberOfPages
    ) {
      return (
        PAGE_SIZE * (totalNumberOfPages - 1) +
        data?.disasterRecoveryRecords.data.length
      );
    }
    return PAGE_SIZE * currentPage;
  };

  const { data, loading, refetch, error } = useGetDisasterRecoveryRecordsQuery({
    variables: {
      searchCriteria: {
        customerId: customerId,
        pageNumber: currentPage,
      },
    },
  });

  const [updateDisasterRecoveryRecord] =
    useUpdateDisasterRecoveryRecordMutation({
      variables: {
        input: {
          customerId: customerId,
          id: recordId,
          status: status!,
          note: note || "",
        },
      },
    });

  if (recordId) {
    updateDisasterRecoveryRecord()
      .then(() => {
        toast.success(toastMessages.DISASTER_RECOVERY_RECORD_UPDATED);
        refetch();
      })
      .catch(() => {
        toast.error(toastMessages.DISASTER_RECOVERY_RECORD_ERROR);
      });
    setRecordId("");
    setNote(undefined);
    setStatus(undefined);
  }

  useEffect(() => {
    const search = `?page=${currentPage}`;
    if (location.search === search) return;

    if (location.search === "") {
      history.replace({ search });
    } else {
      history.push({ search });
    }
  }, [currentPage, location.search, history]);

  useEffect(() => {
    setTotalNumberOfRecords(data?.disasterRecoveryRecords.totalNumberOfRecords);
    setTotalNumberOfPages(data?.disasterRecoveryRecords.totalNumberOfPages);
  }, [data]);

  const setLabelForStatus = (drStatus: DisasterRecoveryStatus) => {
    switch (drStatus) {
      case DisasterRecoveryStatus.InProgress:
        return { className: "is-info", label: "IN PROGRESS" };
      case DisasterRecoveryStatus.Success:
        return { className: "is-success", label: "SUCCESS" };
      case DisasterRecoveryStatus.Failed:
        return { className: "is-danger", label: "FAILED" };
      default:
        return { className: "is-light", label: drStatus };
    }
  };

  const onClick = (record: any, drStatus: UpdateDisasterRecoveryStatus) => {
    Popup.create({
      onClose: () => {
        Popup.close();
      },
      title: `Disaster recovery record status`,
      content: (
        <div className="box">
          <div className="content">
            <p>
              {`Are you sure that you want to change the status to ${drStatus} of the ${record.plan.name} 
              plan started at `}
              <Moment unix format="DD-MM-YYYY HH:mm">
                {record.startTimestamp}
              </Moment>
              ?
            </p>
            <p>
              <span className="icon">
                <i className="fa fa-exclamation-circle" />
              </span>
              <strong>Warning</strong>: Operation cannot be undone.
            </p>
            <p>
              <span className="icon">
                <i className="fa fa-info-circle" />
              </span>
              <strong>Info</strong>: AutoBackup will delete the created
              resources within 24 hours. If you do not want to wait you can
              delete them manually.
            </p>
            <div className="field">
              <label className="label">
                Note
                <span
                  className="icon"
                  data-tip={DisasterRecoveryRecordTooltips.note}
                >
                  <i className="fa fa-question-circle" />
                </span>
              </label>
              <Textarea
                rows={10}
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
            </div>
            <ReactTooltip
              effect="solid"
              className="setup-tooltip"
              multiline={true}
            />
          </div>
        </div>
      ),
      buttons: {
        right: [
          {
            text: "Cancel",
            className: "btn",
            action: () => {
              Popup.close();
            },
          },
          {
            text: "Confirm",
            className: "btn",
            action: async () => {
              setRecordId(record.id);
              setStatus(drStatus);
              Popup.close();
            },
          },
        ],
      },
    });
  };

  const showDRLinks = (record: any) => {
    Popup.create({
      onClose: () => {
        Popup.close();
      },
      title: `Disaster recovery links`,
      content: (
        <div className="box">
          {record.links.map((templateLink: TemplateLink) => (
            <div key={uuid()}>
              <strong>{templateLink.templateName}</strong>
              <a
                href={templateLink.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="link-trimmer-container">
                  <span className="link-trimmer">{templateLink.link}</span>
                </span>
              </a>
              <hr />
            </div>
          ))}
        </div>
      ),
      buttons: {
        left: [
          {
            text: "Mark as success",
            className: "btn",
            action: () => {
              Popup.close();
              onClick(record, UpdateDisasterRecoveryStatus.Success);
            },
          },
          {
            text: "Mark as failed",
            className: "btn",
            action: () => {
              Popup.close();
              onClick(record, UpdateDisasterRecoveryStatus.Failed);
            },
          },
        ],
        right: [
          {
            text: "Close",
            className: "btn",
            action: () => {
              Popup.close();
            },
          },
        ],
      },
    });
  };

  const showNote = (recordNote: string) => {
    Popup.create({
      onClose: () => {
        Popup.close();
      },
      title: `Disaster recovery record`,
      content: (
        <div className="box">
          <div className="field">
            <label className="label">Note</label>
            <Textarea rows={10} value={recordNote} disabled />
          </div>
        </div>
      ),
      buttons: {
        right: [
          {
            text: "Close",
            className: "btn",
            action: () => {
              Popup.close();
            },
          },
        ],
      },
    });
  };

  const showErrorsPopup = (messages: Array<string>) => {
    Popup.create({
      title: "Restore status error",
      content: (
        <div className="box">
          <div>
            <label className="label">Error messages:</label>
            {messages.map((message: string) => (
              <div>
                <hr />
                <p>{message}</p>
              </div>
            ))}
          </div>
        </div>
      ),
      buttons: {
        right: [
          {
            text: "Close",
            className: "btn",
            action: async () => {
              Popup.close();
            },
          },
        ],
      },
    });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Start time (local time)",
        accessor: "startTimestamp",
        Cell: ({ row }: any) => (
          <div style={{ fontWeight: "bold" }}>
            <Moment unix format="DD-MM-YYYY HH:mm">
              {row.original.startTimestamp}
            </Moment>
          </div>
        ),
      },
      {
        Header: "Finish time (local time)",
        accessor: "finishTimestamp",
        Cell: ({ row }: any) => (
          <div style={{ fontWeight: "bold" }}>
            {row.original.finishTimestamp !== null && (
              <Moment unix format="DD-MM-YYYY HH:mm">
                {row.original.finishTimestamp}
              </Moment>
            )}
          </div>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ row }: any) => {
          const { className, label } = setLabelForStatus(row.original.status);
          return <span className={`tag ${className}`}>{label}</span>;
        },
      },
      {
        Header: "Plan Name",
        accessor: "plan.name",
      },
      {
        Header: "Provider",
        Cell: ({ row }: any) => (
          <div style={{ padding: ".5rem" }}>
            {row.original.plan.cloudProvider && (
              <Icon
                height="1.75rem"
                image={row.original.plan.cloudProvider}
                width="1.75rem"
              />
            )}
          </div>
        ),
      },
      {
        Header: "Restore Region",
        accessor: "restoreRegion",
      },
      {
        Header: "Assignee",
        accessor: "assignee",
      },
      {
        id: "links",
        Cell: ({ row }: any) => (
          <div>
            {row.original.status === DisasterRecoveryStatus.Ready && (
              <a onClick={() => showDRLinks(row.original)}>
                <span className="icon">
                  <i className="fa fa-info-circle" />
                </span>
                <span>Show DR links</span>
              </a>
            )}
          </div>
        ),
      },
      {
        id: "actions",
        Cell: ({ row }: any) => (
          <div>
            {row.original.status === DisasterRecoveryStatus.Failed &&
              row.original.errorMessages?.length > 0 && (
                <div className="navbar-item has-dropdown is-hoverable is-pulled-right">
                  <span className="icon">
                    <i className="fa fa-ellipsis-v" />
                  </span>
                  <div className="navbar-dropdown is-right">
                    <a
                      onClick={() =>
                        showErrorsPopup(row.original.errorMessages)
                      }
                      className="navbar-item"
                    >
                      <span className="icon">
                        <i className="fa fa-exclamation-circle" />
                      </span>
                      <span>Show error</span>
                    </a>
                  </div>
                </div>
              )}
            {row.original.note && (
              <div className="navbar-item has-dropdown is-hoverable is-pulled-right">
                <span className="icon">
                  <i className="fa fa-ellipsis-v" />
                </span>
                <div className="navbar-dropdown is-right">
                  <a
                    onClick={() => showNote(row.original.note)}
                    className="navbar-item"
                  >
                    <span className="icon">
                      <i className="fa fa-info-circle" />
                    </span>
                    <span>Show note</span>
                  </a>
                </div>
              </div>
            )}
          </div>
        ),
      },
    ],
    []
  );

  return (
    <Loading loading={loading}>
      {error && <Error error={error} />}
      {data && data.disasterRecoveryRecords.data.length > 0 ? (
        <>
          <Table
            columns={columns}
            data={data.disasterRecoveryRecords.data}
            disableSortBy
          />
        </>
      ) : (
        <Placeholder message="Currently there are no records." />
      )}
      <Pagination
        totalItems={totalNumberOfRecords || 0}
        totalPages={totalNumberOfPages || 0}
        currentPage={currentPage}
        showing={getRecordsNumber()}
        changePage={setCurrentPage}
        previousPage={previousPage}
        nextPage={nextPage}
      />
    </Loading>
  );
};

export default RecordsList;
