/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { Loading } from "../loading/Loading";
import Button from "../button/Button";
import Input from "../input/Input";
import Segment from "../segment/Segment";
import {
  FilteredDataType,
  FilterType,
  useGetCategoriesWithResourcesMappingQuery,
  useGetFiltersQuery,
} from "../../generated/graphql";
import { CustomerContext } from "../../context/customer";
import {
  DATE_FORMAT_SHORT,
  filterValues as fiValues,
} from "../../utils/constants";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useLocation } from "react-router-dom";

interface Props {
  dataType: FilteredDataType;
  applyFiltersHandler: any;
  appliedFilters: any;
  size: string;
}

const capitalize = (string: string) =>
  string.replace(/(?:^|\s)\S/g, (word) => word.toUpperCase());

const Filters: React.FC<Props> = ({
  dataType,
  applyFiltersHandler,
  appliedFilters,
  size,
}) => {
  const [selectedFilters, setSelectedFilters] = useState<Array<any>>([]);
  const [filtersToSelect, setFiltersToSelect] = useState<Array<any>>([]);

  const location = useLocation();
  const customer = useContext(CustomerContext);
  const customerId =
    customer && customer.customer && customer.customer.id
      ? customer.customer.id
      : "";

  const { data: filtersData, loading: filtersLoading } = useGetFiltersQuery({
    variables: {
      searchCriteria: {
        customerId: customerId,
        filteredDataType: dataType,
      },
    },
  });

  const { data: cwrData } = useGetCategoriesWithResourcesMappingQuery();

  useEffect(() => {
    setSelectedFilters(appliedFilters);
  }, [location]);

  useEffect(() => {
    setFiltersToSelect(filtersData?.filters ? filtersData?.filters : []);
  }, [location, filtersData, selectedFilters]);

  const { account, type, provider, category } = fiValues;
  useEffect(() => {
    selectedFilters.map((el) => {
      if (el.id === type) {
        filterByType(el.values[0]);
      }
      if (el.id === category) {
        filterByCategory(el.values[0]);
      }
      if (el.id === provider) {
        filterByProvider(el.values[0]);
      }
      if (el.id === account) {
        filterByAccount(el.values[0]);
      }
    });
  }, [filtersData, location, selectedFilters]);

  const filterByType = (value: string) => {
    const prefix = value.split("-")[0].toLowerCase();
    const suffix = value.split("-")[1].toLowerCase();
    setFiltersToSelect((prevState) => {
      const newState = prevState.map((el) => {
        if (el.id === category) {
          let cat;
          cwrData?.categoriesWithResourcesMapping.forEach((e) => {
            if (e.resources.includes(value)) {
              const filter = el.values.filter(
                (val: { value: string }) => val?.value === e.category
              );
              cat = { ...el, values: filter };
            }
          });
          return cat;
        }
        if (el.id === provider) {
          const filter = el.values.filter((val: { value: string }) => {
            if (suffix === "cn") {
              return val?.value.toLowerCase() === "aws_china";
            }
            return val?.value.toLowerCase() === prefix;
          });
          return { ...el, values: filter };
        }
        if (el.id === account) {
          const filter = el.values.filter((val: { value: string }) => {
            if (suffix === "cn") {
              return val.value.split("__")[0].toLowerCase() === "aws_china";
            }
            return val.value.split("__")[0].toLowerCase() === prefix;
          });
          return { ...el, values: filter };
        }
        return el;
      });
      return [...newState];
    });
  };

  const filterByProvider = (value: string) => {
    const expression = value.toLowerCase();
    setFiltersToSelect((prevState) => {
      const newState = prevState.map((el) => {
        if (el.id === type) {
          const filter = el.values.filter((val: { value: string }) => {
            if (expression === "aws_china") {
              return val?.value.split("-")[1].toLowerCase() === "cn";
            }
            return (
              val?.value.split("-")[1].toLowerCase() !== "cn" &&
              val?.value.split("-")[0].toLowerCase() === expression
            );
          });
          return { ...el, values: filter };
        }
        if (el.id === account) {
          const filter = el.values.filter(
            (val: { value: string }) =>
              val.value.split("__")[0].toLowerCase() === expression
          );

          return { ...el, values: filter };
        }
        return el;
      });
      return [...newState];
    });
  };

  const filterByAccount = (value: string) => {
    const prefix = value.split("__")[0].toLowerCase();
    setFiltersToSelect((prevState) => {
      const newState = prevState.map((el) => {
        if (el.id === provider) {
          const filter = el.values.filter(
            (val: { value: string }) => val?.value.toLowerCase() === prefix
          );
          return { ...el, values: filter };
        }
        if (el.id === type) {
          const filter = el.values.filter((val: { value: string }) => {
            if (prefix === "aws_china") {
              return val?.value.split("-")[1].toLowerCase() === "cn";
            }
            return (
              val?.value.split("-")[1].toLowerCase() !== "cn" &&
              val?.value.split("-")[0].toLowerCase() === prefix
            );
          });
          return { ...el, values: filter };
        }
        return el;
      });
      return [...newState];
    });
  };

  const filterByCategory = (value: string) => {
    setFiltersToSelect((prevState) => {
      const newState = prevState.map((el) => {
        if (el.id === type) {
          let cat;
          cwrData?.categoriesWithResourcesMapping.forEach((e) => {
            if (e.category.includes(value)) {
              const filter = el.values.filter((val: { value: string }) =>
                e.resources.includes(val?.value)
              );
              cat = { ...el, values: filter };
            }
          });
          return cat;
        }
        return el;
      });
      return [...newState];
    });
  };

  const filterTemplate = (
    filterType: FilterType,
    filterId: object,
    filterObj: filterProps
  ) => {
    switch (filterType) {
      case FilterType.Text:
        return textFilter(filterObj);
      case FilterType.Timestamp:
        return dateFilter(filterObj);
      case FilterType.SelectMulti:
        return selectFilter(true, filterId, filterObj);
      case FilterType.SelectOne:
        return selectFilter(false, filterId, filterObj);
      case FilterType.Boolean:
        return selectFilter(false, filterId, filterObj);
      default:
        return selectFilter(false, filterId, filterObj);
    }
  };

  const selectFilter = (
    isFilterMulti: boolean,
    filterId: object,
    filterObj: any
  ) => {
    let values = [];
    const currentFilter = selectedFilters.find(
      (filter) => filter.id === filterId
    );
    const allButCurrentFilters = selectedFilters.filter(
      (filter) => filter.id !== filterId
    );
    if (currentFilter) {
      values = currentFilter.values.map((value: any) => ({
        label: findLabel(filterObj, value),
        value,
        filterId,
      }));
    }

    return (
      <Select
        value={values}
        isClearable={true}
        onChange={(filter: any, { action, removedValue }: any): any => {
          if (action === "select-option") {
            isFilterMulti ? addMultipleFilter(filter) : addSingleFilter(filter);
          }

          if (action === "clear") {
            setSelectedFilters(allButCurrentFilters);
          }

          if (action === "remove-value") {
            currentFilter.values = currentFilter.values.filter(
              (value: string) => value !== removedValue.value
            );
            if (currentFilter.values.length !== 0) {
              setSelectedFilters([...allButCurrentFilters, currentFilter]);
            } else {
              setSelectedFilters(allButCurrentFilters);
            }
          }
        }}
        isMulti={isFilterMulti}
        isSearchable={true}
        options={
          filterObj.values.map((value: filterValueProps): any => ({
            ...value,
            filterId: filterObj.id,
          })) || []
        }
      />
    );
  };

  const findLabel = (filterObj: filterProps, value: string) => {
    const filter = filterObj.values?.find(
      (val: filterValueProps) => val.value === value
    );
    return filter !== undefined ? filter.label : value;
  };

  const textFilter = (filterObj: filterProps) => {
    const selectedFilter = selectedFilters?.filter(
      (filter: any) => filter.id === filterObj.id
    );
    const restFilters = selectedFilters?.filter(
      (filter: any) => filter.id !== filterObj.id
    );
    const filterFromUrl = selectedFilters?.filter(
      (filter) => filter.id === filterObj.id
    );

    return (
      <Input
        type="text"
        value={filterFromUrl[0]?.values}
        onChange={(e: any) => {
          const value = e.target.value;
          if (selectedFilter.length === 0) {
            setSelectedFilters([
              ...selectedFilters,
              {
                id: filterObj.id,
                values: [value],
              },
            ]);
          } else {
            setSelectedFilters([
              ...restFilters,
              {
                id: filterObj.id,
                values: [value],
              },
            ]);
          }
        }}
      />
    );
  };

  const dateFilter = (filterObj: filterProps) => {
    const selectedFilter = selectedFilters.filter(
      (filter: any) => filter.id === filterObj.id
    );
    const restFilters = selectedFilters.filter(
      (filter: any) => filter.id !== filterObj.id
    );
    const filterFromUrl = selectedFilters.find(
      (filter) => filter.id === filterObj.id
    );
    const date = filterFromUrl?.values[0];

    return (
      <DatePicker
        className="input border"
        dateFormat={DATE_FORMAT_SHORT}
        selected={date !== undefined ? new Date(date * 1000) : null}
        todayButton="Today"
        onChange={(e: any) => {
          if (e !== null) {
            const eventValue = moment(e).unix().toString();
            if (selectedFilter.length === 0) {
              setSelectedFilters([
                ...selectedFilters,
                {
                  id: filterObj.id,
                  values: [eventValue],
                },
              ]);
            } else {
              setSelectedFilters([
                ...restFilters,
                {
                  id: filterObj.id,
                  values: [eventValue],
                },
              ]);
            }
          } else {
            setSelectedFilters(restFilters);
          }
        }}
        placeholderText="Select Date"
      />
    );
  };

  const addSingleFilter = (filterValue: any) => {
    const selectedFilter = selectedFilters.filter(
      (filter: any) => filter.id === filterValue.filterId
    );
    const restFilters = selectedFilters.filter(
      (filter: any) => filter.id !== filterValue.filterId
    );
    const newFilter = {
      id: filterValue.filterId,
      values: [filterValue.value],
    };

    if (selectedFilter.length === 0) {
      setSelectedFilters([...selectedFilters, newFilter]);
    } else {
      setSelectedFilters([...restFilters, newFilter]);
    }
  };

  const addMultipleFilter = (filterValues: Array<any>) => {
    if (filterValues.length > 0) {
      const filterId = filterValues[0].filterId;
      const selectedFilter = selectedFilters.filter(
        (filter: any) => filter.id === filterId
      );
      const restFilters = selectedFilters.filter(
        (filter: any) => filter.id !== filterId
      );
      if (selectedFilter.length === 0) {
        setSelectedFilters([
          ...selectedFilters,
          {
            id: filterId,
            values: [filterValues[0].value],
          },
        ]);
      } else {
        setSelectedFilters([
          ...restFilters,
          {
            id: filterId,
            values: filterValues.map((filter) => filter.value),
          },
        ]);
      }
    }
  };

  const formatFilters = () =>
    selectedFilters.map((filter: any) => ({
      id: filter.id,
      values: filter.values,
    }));

  return (
    <>
      <div className="box">
        <div className="columns is-vcentered border-bottom">
          <div className="column">
            <h1 className="title is-marginless is-5 is-capitalized">Filters</h1>
          </div>
        </div>
        <div className="columns is-multiline">
          <Loading loading={filtersLoading} />
          {filtersToSelect.map((filter: any): any => {
            const filterId = filter.id;
            return (
              <div className={`field column ${size}`} key={filter.id}>
                <label className="label">{capitalize(filter.name)}</label>
                <div className="control">
                  {filterTemplate(filter.type, filterId, filter)}
                </div>
              </div>
            );
          })}
        </div>
        <div className="columns">
          <div className="column">
            <Segment alignRight>
              <Button
                onClick={() => {
                  applyFiltersHandler(formatFilters());
                }}
              >
                Apply filters
              </Button>
            </Segment>
          </div>
        </div>
      </div>
    </>
  );
};

export default Filters;
