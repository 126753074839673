/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  BackupEventProgress as Progress,
  BackupEventStatus as Status,
  HookType,
} from "../generated/graphql";

export const DATE_FORMAT = "ddd, DD.MM.YYYY, HH:mm";
export const DATE_FORMAT_SHORT = "dd.MM.yyyy";
export const DATE_FORMAT_SHORT_YEAR = "DD.MM.YY";

export const toastMessages = {
  RESTORE_DATA_COPIED: "Restore Data copied to clipboard.",
  RESOURCE_ID_COPIED: "Resource Id copied to clipboard.",
  EVENT_ID_COPIED: "Event Id copied to clipboard.",
  BACKUP_ENABLED_VALUE_COPIED: "Backup enabled value copied to clipboard.",
  POLICY_TAG_VALUE_COPIED: "Policy tag value copied to clipboard.",
  SETUP_TAG_VALUE_COPIED: "Setup tag value copied to clipboard.",
  FAILED: "Something went wrong.",
  API_KEY_REMOVED: "API key successfully removed.",
  API_KEY_REMOVAL_ERROR: "Failed to remove API key.",
  ACCOUNT_REMOVED: "Account successfully removed.",
  ACCOUNT_ADDED: "Account successfully added.",
  ACCOUNT_ADDED_ERROR: "Failed to add the account.",
  ACCOUNT_REMOVAL_ERROR: "Failed to remove account.",
  ONBOARD_SUCCESS_AWS: "AWS account successfully onboarded.",
  POLICY_ATTACHED: "Policy successfully attached.",
  SETUP_ATTACHED: "Setup successfully attached.",
  BACKUP_ENABLED: "Backup successfully enabled",
  BACKUP_DISABLED: "Backup successfully disabled",
  NOTIFICATION_CREATED: "Notification group successfully created.",
  NOTIFICATION_CREATION_ERROR: "Failed to create notification group.",
  ACCOUNT_REGISTERED:
    "Account registered successfully, download policies and apply them on your AWS account to finish account onboarding.",
  VALIDATION_FAILED: "Form validation failed.",
  SETUP_REMOVED: "Backup setup successfully removed.",
  SETUP_REMOVAL_ERROR: "Failed to remove backup setup.",
  POLICY_REMOVED: "Backup policy successfully removed.",
  POLICY_REMOVAL_ERROR: "Failed to remove backup policy.",
  SETUP_CREATED: "Backup setup successfully created.",
  SETUP_CREATION_ERROR: "Failed to create new backup setup.",
  SETUP_UPDATED: "Backup setup successfully updated.",
  SETUP_UPDATE_ERROR: "Failed to update backup setup.",
  POLICY_CREATED: "Backup policy successfully created.",
  POLICY_CREATION_ERROR: "Failed to create backup policy.",
  RESOURCES_SCAN_STARTED: "Started scanning resources.",
  RESOURCES_SCAN_SUCCESS: "Resources scanned successfully.",
  RESOURCES_SCAN_ERROR: "Error while scanning resources.",
  RESOURCE_UPDATE: "Resources settings were successfully updated.",
  RESOURCE_UPDATE_ERROR:
    "Failed to update settings for the selected resources.",
  SCAN_TRIGGER_ERROR: "Failed to trigger resource scan.",
  TAG_BASED_CONFIGURATION_SAVED: "Tag-based configuration saved successfully.",
  USER_PROFILE_UPDATED: "Users profile updated successfully.",
  USER_PROFILE_ERROR: "Error while updating User profile.",
  CHANGE_BACKUP_POLICY_AND_SETUPS: "Backup Policy and Setup is cleared.",
  BACKUP_TRIGGERED: "On demand backup successfully triggered.",
  REPORT_DOWNLOAD_INFO:
    "Report not available yet, please try again in a moment.",
  TEMPLATE_CREATED: "Template successfully created.",
  TEMPLATE_CREATION_ERROR: "Failed to create template.",
  TEMPLATE_UPDATED: "Template successfully updated.",
  TEMPLATE_UPDATE_ERROR: "Failed to update template.",
  TEMPLATE_REMOVED: "Template successfully removed.",
  TEMPLATE_REMOVAL_ERROR: "Failed to remove template.",
  TEMPLATE_VALIDATION_ERROR: "Failed to validate template.",
  DISASTER_RECOVERY_RECORD_UPDATED: "Record updated successfully.",
  DISASTER_RECOVERY_RECORD_ERROR:
    "Error while updating Disaster recovery record.",
  DISASTER_RECOVERY_PLAN_CREATED: "Plan successfully created.",
  DISASTER_RECOVERY_PLAN_CREATION_ERROR: "Failed to create plan.",
  DISASTER_RECOVERY_PLAN_UPDATED: "Template successfully updated.",
  DISASTER_RECOVERY_PLAN_UPDATE_ERROR: "Failed to update template.",
  DISASTER_RECOVERY_PLAN_REMOVED: "Plan successfully removed.",
  DISASTER_RECOVERY_PLAN_REMOVAL_ERROR: "Failed to remove plan.",
  DISASTER_RECOVERY_TESTING_ERROR: "Failed to start disaster recovery.",
  USER_REMOVED: "Failed to start disaster recovery.",
  SUPER_ADMIN_REVOKE: "Super admin revoked successfully.",
  SUPER_ADMIN_REVOKE_ERROR: "Failed to revoke super admin.",
  SUPER_ADMIN_GRANT: "Super admin granted successfully.",
  SUPER_ADMIN_GRANT_ERROR: "Failed to grant super admin.",
  GRANT_CUSTOMER_ROLE: "Role successfully granted.",
  GRANT_CUSTOMER_ROLE_ERROR: "Failed to grant role.",
  UPDATE_CUSTOMER_ROLE: "Role successfully updated.",
  UPDATE_CUSTOMER_ROLE_ERROR: "Failed to update role.",
  INVALID_ACCESS: "You are not allowed to view this content.",
  CREATE_API_KEY: "API key successfully created.",
  CREATE_API_KEY_ERROR: "Failed to create API key.",
  COPY_API_KEY_TEXT: "API key successfully copied.",
  COPY_API_KEY_TEXT_ERROR: "Failed to copy API key.",
};

export const confirmations = {
  DELETE_NOTIFICATION_GROUP:
    "Are you sure you want to delete notification group?",
  DELETE_SETUP: "Are you sure you want to delete this backup setup?",
  DELETE_POLICY: "Are you sure you want to delete this backup policy?",
  SAVE_TAG_BASED_CONFIGURATION:
    "Are you sure you want to save current tag-based configuration?",
  ENABLE_TAG_BASED_BACKUP: "Are you sure you want to enable tag based backup?",
  DISABLE_TAG_BASED_BACKUP:
    "Are you sure you want to disable tag based backup?",
  DELETE_TEMPLATE: "Are you sure you want to delete template?",
  DELETE_DISASTER_RECOVERY_PLAN: "Are you sure you want to delete plan?",
};

export const COLORS = {
  success: "#05A58D",
  failure: "#DD0000",
  warning: "#ffdd57",
  label: "#696969",
};

export const PROGRESS_ID = "BACKUP_EVENT_PROGRESS_VALUES";
export const START_DATE_ORDER_ID = "BACKUP_EVENT_START_DATE_ORDER";

export { Status, Progress };

export const escapeKeyCode = 27;
export const filterValues = {
  backupEnabled: "RESOURCES_BACKUP_ENABLED",
  rpoConditionMet: "RESOURCES_RPO_CONDITION_MET",
  provider: "RESOURCES_PROVIDER",
  type: "RESOURCES_TYPE",
  category: "RESOURCES_CATEGORY",
  account: "RESOURCES_ACCOUNT_ID",
};

export const HookOptions = [
  { value: HookType.LambdaArn, label: "Lambda arn" },
  { value: HookType.Url, label: "Url" },
];

export const BackupSetupTooltips = {
  name: "Name of the setup. We recommend naming convention that indicates resource type and additional settings, eg. 'ec2_rep-eu-central-1'",
  hooks:
    "URL address or Lambda ARN (correct ARN format example: 'arn:aws:iam::lambda:[...]:function:[...]')",
};

export const TemplateTooltips = {
  name: "Custom name of the template.",
  cloudProvider:
    "Template's cloud provider. Determines the validation for template file.",
  content: "Template file in JSON or YAML format (depends on cloud provider).",
};

export const DisasterRecoveryTestingTooltips = {
  recoveryPoint:
    "The date from which resources will be recovered (AutoBackup chooses the snapshots created on or before this date)",
};

export const DisasterRecoveryPlanTooltips = {
  planName: "Custom name of the plan",
  cloudProvider:
    "Plan's cloud provider. Determines available restore regions, templates and resources to restore",
  restoreRegion: "Determines region where resources should be recovered",
  startDate:
    "Determines testing plan starting date (based on this field next testing dates will be calculated)",
  testFrequency: "Determines frequency of testing",
  remindDaysBefore:
    "Determines how many days before next test date notifications must be sent",
  templates:
    "List of templates attached to the plan (which templates should be tested in plan)",
  recipients: "Determines who should be notified about upcoming test date",
  kmsKey:
    "KMS key is used by AutoBackup to copy encrypted AWS snapshots to restore region. " +
    "Following formats are correct: key ID (for example, key/1234abcd-12ab-34cd-56ef-1234567890ab), key alias (for example, alias/ExampleAlias), " +
    "key ARN (for example, arn:aws:kms:us-east-1:012345678910:key/abcd1234-a123-456a-a12b-a123b4cd56ef).",
};

export const DisasterRecoveryRecordTooltips = {
  note: "Additional information about Disaster Recovery status, for example: error message, link to documents.",
};

export const BackupPolicyTooltips = {
  name: "Custom name of the policy.",
  type: "Determines policy settings and how future events will be generated.",
  removePrimarySnapshotsAfterResourceDeletion:
    "Removes primary snapshots after the resource is deleted from AutoBackup.",
  removeReplicaSnapshotsAfterResourceDeletion:
    "Removes replica snapshots after the resource is deleted from AutoBackup.",
  snapshotFrequency: "It specifies how often the backups should be performed.",
  snapshotFrequencyValue:
    "A desirable time interval between resource's backups.",
  cronExpression:
    "The cron expression specifies at what intervals the backup should be performed.",
  backupTimeWindowDurationInHours:
    "The time window where backup will be taken. Range 1-24 hours.",
  startDate: "The start date specifies when the first backup has begin.",
  primarySnapshotCondition:
    "Determines if primary snapshots should be removed after the specified number of days, or if the system should retain only the specified number of the newest snapshots.",
  primarySnapshotThreshold:
    "How many OR For how long the primary snapshots are retained.",
  replicaSnapshotCondition:
    "Determines if replica snapshots should be removed after the specified number of days, or if the system should retain only the specified number of the newest snapshots.",
  replicaSnapshotThreshold:
    "How many OR For how long the replica snapshots are retained.",
};

export const NotificationGroupTooltips = {
  name: "Custom name of notification group.",
  type: "Type of event after which the user will be notified. (at least one is required)",
  frequency: "How often notifications should be sent.",
  channels:
    "Which channels should be used to inform the user about an event. (at least one is required)",
  excludedAccounts: "List of accounts for which notification will not be sent.",
};
