/**
 * Copyright 2020-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { useContext, useEffect, useState } from "react";
import {
  AccountType,
  CustomerReportType,
  useGetAccountsQuery,
  useGetProtectedResourcesStatsQuery,
  useGetReportQuery,
  useGetUserRoleQuery,
  UserRole,
} from "../../../generated/graphql";
import { ResourcesWithBackup } from "../components/ResourcesWithBackup.widget";
import DataUnderProtection from "../components/DataUnderProtection.widget";
// import PolicyCompliance from "../components/PolicyCompliance.widget";
import { Loading } from "../../../commonComponents/loading/Loading";
import Button from "../../../commonComponents/button/Button";
import { CustomerContext } from "../../../context/customer";
import ScanStatus from "../components/ScanStatus";
import { COLORS, filterValues, toastMessages } from "../../../utils/constants";
import EventsList from "../components/Events";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../routes/routes";
import { toast } from "react-toastify";
import moment from "moment";

const DashboardPage: React.FC = () => {
  const customer = useContext(CustomerContext);
  const customerId = customer?.customer?.id || "";
  const [resourcesWithoutBackup, setResourcesWithoutBackup] = useState(0);
  const [totalNumberOfResources, setTotalNumberOfResources] = useState(0);
  const [dataSize, setDataSize] = useState<any>({
    totalAmount: "",
    protectedAmount: "",
  });

  const { data, error, loading } = useGetAccountsQuery({
    variables: {
      searchCriteria: {
        customerId: customerId,
        accountType: AccountType.Protected,
      },
    },
  });

  // const { data: policyComplianceData, loading: policyComplianceLoading } =
  //   useGetPolicyComplianceQuery({
  //     variables: { customerId },
  //   });

  const { data: protectedResourcesStats } = useGetProtectedResourcesStatsQuery({
    variables: {
      searchCriteria: { customerId },
    },
  });

  const { data: userRoleData, loading: userRoleLoading } = useGetUserRoleQuery({
    variables: { customerId },
  });
  const userRole = userRoleData?.userRole;

  const date = () => {
    return {
      previousReport: moment()
        .subtract(1, "months")
        .endOf("month")
        .format("YYYY-MM"),
      currentReport: moment().format("YYYY-MM"),
    };
  };

  const { previousReport, currentReport } = date();

  const { loading: reportLoading, refetch: getReport } = useGetReportQuery({
    variables: {
      input: {
        customerId,
        reportType: CustomerReportType.BackupEvent,
        reportDate: previousReport,
      },
    },
  });

  const backupDisabledFilter = JSON.stringify([
    {
      id: filterValues.backupEnabled,
      values: ["false"],
    },
  ]);

  useEffect(() => {
    const total =
      protectedResourcesStats?.protectedResourcesStats
        ?.totalNumberOfResources || 0;
    const wBackup =
      protectedResourcesStats?.protectedResourcesStats
        ?.numberOfResourcesWithEnabledBackup || 0;
    const nWithoutBackup = total - wBackup;
    setTotalNumberOfResources(total);
    setResourcesWithoutBackup(nWithoutBackup);

    setDataSize({
      totalAmount:
        protectedResourcesStats?.protectedResourcesStats
          ?.totalAmountOfDataInBytes,
      protectedAmount:
        protectedResourcesStats?.protectedResourcesStats
          ?.protectedAmountOfDataInBytes,
    });
  }, [protectedResourcesStats]);

  const onClick = async (
    month: "previous" | "current",
    reportType: CustomerReportType
  ) => {
    const reportData = await getReport({
      input: {
        customerId,
        reportType: reportType,
        reportDate: month === "previous" ? previousReport : currentReport,
      },
    });
    reportData?.data?.report?.url
      ? window.location.replace(reportData?.data?.report?.url)
      : toast.info(toastMessages.REPORT_DOWNLOAD_INFO);
  };

  return (
    <>
      <div className="container is-fluid">
        <Loading loading={loading || userRoleLoading}>
          {error && <div>{error.message}</div>}
          {userRole === UserRole.ReadOnly && (
            <div className="box">
              <p className="subtitle has-text-centered">
                You have <strong>read only</strong> access to AutoBackup.
              </p>
            </div>
          )}
          {data && data.accounts.data.length > 0 ? (
            <>
              {protectedResourcesStats && (
                <>
                  <div className="columns">
                    <div className="column is-4">
                      <Loading loading={loading}>
                        <h2 className="subtitle">Resources with Backup</h2>
                        {totalNumberOfResources !== 0 ? (
                          <ResourcesWithBackup
                            allResources={totalNumberOfResources}
                            nWithoutBackup={resourcesWithoutBackup}
                          />
                        ) : (
                          <div className="box is-clearfix has-text-centered">
                            No data available
                          </div>
                        )}
                      </Loading>
                    </div>
                    <div className="column is-4">
                      <h2 className="subtitle">Data Under Protection</h2>
                      {dataSize?.totalAmount !== "0" ? (
                        <DataUnderProtection
                          totalAmount={dataSize.totalAmount}
                          protectedAmount={dataSize.protectedAmount}
                        />
                      ) : (
                        <div className="box is-clearfix has-text-centered">
                          No data available
                        </div>
                      )}
                    </div>
                    <div className="column is-4">
                      <h2 className="subtitle">Policy Compliance</h2>
                      {/*<Loading loading={policyComplianceLoading}>*/}
                      {/*  {policyComplianceData?.policyCompliance ? (*/}
                      {/*    <PolicyCompliance*/}
                      {/*      numberOfBackedUpResources={*/}
                      {/*        policyComplianceData?.policyCompliance*/}
                      {/*          ?.numberOfBackedUpResources*/}
                      {/*      }*/}
                      {/*      numberOfCompliantResources={*/}
                      {/*        policyComplianceData?.policyCompliance*/}
                      {/*          ?.numberOfCompliantResources*/}
                      {/*      }*/}
                      {/*    />*/}
                      {/*  ) : (*/}
                      {/*    <div className="box is-clearfix has-text-centered">*/}
                      {/*      No data available*/}
                      {/*    </div>*/}
                      {/*  )}*/}
                      {/*</Loading>*/}
                      <div className="box is-clearfix has-text-centered">
                        No data available
                      </div>
                    </div>
                  </div>
                  <div className="columns">
                    <div
                      className={`column is-${
                        userRole !== UserRole.ReadOnly ? "third" : "half"
                      }`}
                    >
                      <h2 className="subtitle">Available Reports</h2>
                      <Loading loading={reportLoading}>
                        <div className="box is-clearfix">
                          <div className="columns">
                            <div className="column">Events report:</div>
                            <div className="column is-narrow">
                              <Button
                                size="small"
                                onClick={() =>
                                  onClick(
                                    "previous",
                                    CustomerReportType.BackupEvent
                                  )
                                }
                              >
                                Last month
                              </Button>{" "}
                              <Button
                                size="small"
                                onClick={() =>
                                  onClick(
                                    "current",
                                    CustomerReportType.BackupEvent
                                  )
                                }
                              >
                                Current month
                              </Button>
                            </div>
                          </div>
                          <div className="columns">
                            <div className="column">
                              Resources report (actual state):
                            </div>
                            <div className="column is-narrow">
                              <Button
                                size="small"
                                onClick={() =>
                                  onClick(
                                    "current",
                                    CustomerReportType.Resources
                                  )
                                }
                              >
                                Download
                              </Button>
                            </div>
                          </div>
                          <div className="columns">
                            <div className="column">
                              Orphaned snapshots report:
                            </div>
                            <div className="column is-narrow">
                              <Button
                                size="small"
                                onClick={() =>
                                  onClick(
                                    "current",
                                    CustomerReportType.OrphanedSnapshots
                                  )
                                }
                              >
                                Download
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Loading>
                    </div>
                    {userRole !== UserRole.ReadOnly && (
                      <div className="column is-third">
                        <h2 className="subtitle">Scan Status</h2>
                        <ScanStatus />
                      </div>
                    )}
                    <div
                      className={`column is-${
                        userRole !== UserRole.ReadOnly ? "third" : "half"
                      }`}
                    >
                      <h2 className="subtitle">Unprotected resources</h2>
                      {protectedResourcesStats?.protectedResourcesStats ? (
                        <div className="box is-clearfix">
                          <div className="columns">
                            <div className="column">
                              You have{" "}
                              <strong style={{ color: COLORS.failure }}>
                                {resourcesWithoutBackup} resources
                              </strong>{" "}
                              without backup enabled.
                            </div>
                            <div className="column is-narrow">
                              <Link
                                to={ROUTES.resources.index.generate(
                                  {},
                                  { filters: backupDisabledFilter }
                                )}
                              >
                                <Button
                                  size="small"
                                  className="is-pulled-right"
                                >
                                  Review resources list
                                </Button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="box is-clearfix">
                          <div className="columns">
                            <div className="column">
                              All resources have backup enabled!
                            </div>
                            <div className="column is-narrow">
                              <Link to={ROUTES.resources.index.generate()}>
                                <Button size="small">
                                  Review resources list
                                </Button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="columns">
                    <div className="column">
                      <EventsList />
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            <p className="subtitle is-4">
              Your dashboard is empty, please{" "}
              <Link to={ROUTES.accounts.index.generate()}>
                <strong>finish account onboarding process</strong>
              </Link>{" "}
              and enable backup for at least one account
            </p>
          )}
        </Loading>
      </div>
    </>
  );
};

export default DashboardPage;
