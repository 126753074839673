/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { useEffect, useState } from "react";
import { SnapshotType } from "../../../generated/graphql";
import { Loading } from "../../../commonComponents/loading/Loading";
import Table from "../../../commonComponents/table/Table";
import { Icon } from "../../../commonComponents/icon/Icon";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { toastMessages } from "../../../utils/constants";
import Button from "../../../commonComponents/button/Button";
import Popup from "react-popup";
import ReactTooltip from "react-tooltip";
import Pagination from "../../../commonComponents/pagination/Pagination";
import { Error } from "../../../commonComponents/error/error";

interface Props {
  resourcesData: any;
  resourcesError: any;
  resourcesLoading: boolean;
  currentPage: number;
  setCurrentPage: any;
}

const PAGE_SIZE = 50;

const EventResources: React.FC<Props> = ({
  resourcesData,
  resourcesError,
  resourcesLoading,
  currentPage,
  setCurrentPage,
}) => {
  const [resources, setResources] = useState<any>();
  const [totalNumberOfResources, setTotalNumberOfResources] = useState<
    number | undefined
  >(0);
  const [totalNumberOfPages, setTotalNumberOfPages] = useState<
    number | undefined
  >(0);

  const nextPage = () => {
    if (totalNumberOfPages && currentPage < totalNumberOfPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const getResourcesNumber = () => {
    if (resources && resources.length < PAGE_SIZE && currentPage === 1) {
      return resources.length;
    }
    if (
      resources &&
      resources.length < PAGE_SIZE &&
      currentPage === totalNumberOfPages
    ) {
      return PAGE_SIZE * (totalNumberOfPages - 1) + resources.length;
    }
    return PAGE_SIZE * currentPage;
  };

  useEffect(() => {
    setTotalNumberOfResources(resourcesData?.totalNumberOfResources);
    setTotalNumberOfPages(resourcesData?.totalNumberOfPages);
    setResources(resourcesData?.data);
  }, [resourcesData]);

  const showSnapshot = (snapshot: any, snapshotType: any) => {
    const title =
      snapshotType !== SnapshotType.Replica ? `Snapshot` : `Snapshot Replica`;

    if (snapshot) {
      Popup.create({
        onClose: () => {
          Popup.close();
        },
        title: title,
        content: (
          <div className="box">
            <div>
              <label className="label">Resource ID:</label>
              <p>{snapshot?.id}</p>
            </div>
            <div>
              <label className="label">Account ID:</label>
              <p>{snapshot?.accountId}</p>
            </div>
            <div>
              <label className="label">Region:</label>
              <p>{snapshot?.region}</p>
            </div>
          </div>
        ),
        buttons: {
          right: [
            {
              text: "Close",
              className: "btn",
              action: async () => {
                Popup.close();
              },
            },
          ],
        },
      });
    } else {
      Popup.create({
        onClose: () => {
          Popup.close();
        },
        title: title,
        content: (
          <div className="box">
            {snapshotType === SnapshotType.Primary ? (
              <p>There is no snapshot.</p>
            ) : (
              <p>There is no snapshot replica.</p>
            )}
          </div>
        ),
        buttons: {
          right: [
            {
              text: "Close",
              className: "btn",
              action: async () => {
                Popup.close();
              },
            },
          ],
        },
      });
    }
  };

  const showFailureReason = (failureReason: any, snapshotType: any) => {
    Popup.create({
      onClose: () => {
        Popup.close();
      },
      title: `${snapshotType} failure reason`,
      content: (
        <div className="box">
          <div>
            <p>{failureReason}</p>
          </div>
        </div>
      ),
      buttons: {
        right: [
          {
            text: "Close",
            className: "btn",
            action: async () => {
              Popup.close();
            },
          },
        ],
      },
    });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "",
        accessor: "backupEnabled",
        Cell: ({ row }: any) => (
          <div style={{ padding: ".5rem" }}>
            {row.original.resource.backupEnabled ? (
              <Icon
                height="1.75rem"
                image="STATUS_ENABLED"
                width="1.75rem"
                data-tip="Backup enabled"
              />
            ) : (
              <Icon
                height="1.75rem"
                image="STATUS_DISABLED"
                width="1.75rem"
                data-tip="Backup disabled"
              />
            )}
          </div>
        ),
      },
      {
        Header: "Resource ID",
        accessor: "id",
        Cell: ({ row }: any) => (
          <div>
            <div style={{ fontWeight: "bold" }}>
              {row.original.resource.id.split(":").pop()}
              <CopyToClipboard
                text={row.original.resource.id}
                onCopy={() => {
                  toast.success(toastMessages.RESOURCE_ID_COPIED);
                }}
              >
                <Button outline size="extra-small" margin="0 .25rem">
                  <i className="fa fa-copy" /> copy
                </Button>
              </CopyToClipboard>
            </div>
            <div className="is-flex" style={{ fontSize: ".75rem" }}>
              <div style={{ marginRight: ".5rem" }}>
                <b>Type:</b> {row.original.resource.type}
              </div>
              <div>
                <b>Category:</b> {row.original.resource.category}
              </div>
            </div>
          </div>
        ),
      },
      {
        Header: "Provider",
        accessor: "resource.cloudProvider",
        Cell: ({ row }: any) => (
          <div style={{ padding: ".5rem" }}>
            {row.original.resource.cloudProvider && (
              <Icon
                height="1.75rem"
                image={row.original.resource.cloudProvider}
                width="1.75rem"
              />
            )}
          </div>
        ),
      },
      {
        Header: "Account",
        accessor: "resource.accountName",
      },
      {
        Header: "Policy",
        accessor: "resource.backupPolicy.name",
      },
      {
        Header: "Setup",
        accessor: "resource.backupSetupName",
      },
      {
        id: "actions",
        Cell: ({ row }: any) => (
          <div>
            <div className="navbar-item has-dropdown is-hoverable is-pulled-right">
              <span className="icon">
                <i
                  className={
                    row.original.failureReason ||
                    row.original.replicaFailureReason
                      ? "fa fa-exclamation"
                      : "fa fa-ellipsis-v"
                  }
                />
              </span>
              <div className="navbar-dropdown is-right">
                {row.original.failureReason ? (
                  <a
                    onClick={() =>
                      showFailureReason(
                        row.original.failureReason,
                        "Primary snapshot"
                      )
                    }
                    className="navbar-item"
                  >
                    <span className="icon">
                      <i className="fa fa-exclamation-circle" />
                    </span>
                    <span>Snapshot failure reason</span>
                  </a>
                ) : (
                  <>
                    <a
                      onClick={() =>
                        showSnapshot(
                          row.original.snapshot,
                          SnapshotType.Primary
                        )
                      }
                      className="navbar-item"
                    >
                      <span className="icon">
                        <i className="fa fa-camera" />
                      </span>
                      <span>Snapshot</span>
                    </a>
                    {row.original.replicaFailureReason ? (
                      <a
                        onClick={() =>
                          showFailureReason(
                            row.original.replicaFailureReason,
                            "Replica snapshot"
                          )
                        }
                        className="navbar-item"
                      >
                        <span className="icon">
                          <i className="fa fa-exclamation-circle" />
                        </span>
                        <span>Snapshot replica failure reason</span>
                      </a>
                    ) : (
                      <a
                        onClick={() =>
                          showSnapshot(
                            row.original.snapshotReplica,
                            SnapshotType.Replica
                          )
                        }
                        className="navbar-item"
                      >
                        <span className="icon">
                          <i className="fa fa-camera" />
                        </span>
                        <span>Snapshot replica</span>
                      </a>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <>
      <Loading loading={resourcesLoading}>
        {resourcesError && <Error error={resourcesError} />}
        {resources && resources.length > 0 ? (
          <div className="box is-clearfix">
            <h1 className="title is-size-4">Resources</h1>
            <Table columns={columns} data={resources} disableSortBy />
            <ReactTooltip effect="solid" />
          </div>
        ) : (
          "There are no resources."
        )}
        <Pagination
          totalItems={totalNumberOfResources || 0}
          totalPages={totalNumberOfPages || 0}
          currentPage={currentPage}
          showing={getResourcesNumber()}
          changePage={setCurrentPage}
          previousPage={previousPage}
          nextPage={nextPage}
        />
      </Loading>
      <Popup />
    </>
  );
};

export default EventResources;
