/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from "react";
import Button from "../../../commonComponents/button/Button";

const AddEmailsButton: React.FC<any> = ({ recipients, setter }) => (
  <Button
    disabled={recipients.length > 0 && recipients[recipients.length - 1] === ""}
    onClick={() => {
      setter([...recipients, ""]);
    }}
  >
    Add another email
  </Button>
);

export default AddEmailsButton;
