/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { useContext, useEffect, useState } from "react";
import { History } from "history";
import {
  Template,
  useGetDisasterRecoveryPlansQuery,
  useRemoveDisasterRecoveryPlanMutation,
  UserRole,
} from "../../../generated/graphql";
import { CustomerContext } from "../../../context/customer";
import { useLocation } from "react-router-dom";
import { Loading } from "../../../commonComponents/loading/Loading";
import { Error } from "../../../commonComponents/error/error";
import Table from "../../../commonComponents/table/Table";
import Placeholder from "../../../commonComponents/table/Placeholder";
import Pagination from "../../../commonComponents/pagination/Pagination";
import Moment from "react-moment";
import { ROUTES } from "../../../routes/routes";
import Pluralize from "react-pluralize";
import { toast } from "react-toastify";
import { confirmations, toastMessages } from "../../../utils/constants";
import errorToast from "../../../utils/errorToast";
import Popup from "react-popup";
import { Icon } from "../../../commonComponents/icon/Icon";

interface Props {
  userRole?: UserRole | null;
  userRoleLoading: boolean;
  currentPage: number;
  setCurrentPage: any;
  history: History;
}

const PAGE_SIZE = 50;

const PlansList: React.FC<Props> = ({
  userRole,
  userRoleLoading,
  currentPage,
  setCurrentPage,
  history,
}) => {
  const { customer } = useContext(CustomerContext);
  const customerId = customer?.id || "";
  const [removeId, setRemoveId] = useState("");
  const location = useLocation();

  const [totalNumberOfPlans, setTotalNumberOfPlans] = useState<
    number | undefined
  >(0);
  const [totalNumberOfPages, setTotalNumberOfPages] = useState<
    number | undefined
  >(0);

  const nextPage = () => {
    if (totalNumberOfPages && currentPage < totalNumberOfPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const getPlansNumber = () => {
    if (
      data?.disasterRecoveryPlans.data &&
      data?.disasterRecoveryPlans.data.length < PAGE_SIZE &&
      currentPage === 1
    ) {
      return data?.disasterRecoveryPlans.data.length;
    }
    if (
      data?.disasterRecoveryPlans.data &&
      data?.disasterRecoveryPlans.data.length < PAGE_SIZE &&
      currentPage === totalNumberOfPages
    ) {
      return (
        PAGE_SIZE * (totalNumberOfPages - 1) +
        data?.disasterRecoveryPlans.data.length
      );
    }
    return PAGE_SIZE * currentPage;
  };

  const { data, loading, refetch, error } = useGetDisasterRecoveryPlansQuery({
    variables: {
      searchCriteria: {
        customerId: customerId,
        pageNumber: currentPage,
      },
    },
  });

  const [removePlan, { loading: removePlanLoading }] =
    useRemoveDisasterRecoveryPlanMutation({
      variables: {
        input: {
          customerId: customerId,
          planId: removeId,
        },
      },
    });

  useEffect(() => {
    const search = `?page=${currentPage}`;
    if (location.search === search) return;

    if (location.search === "") {
      history.replace({ search });
    } else {
      history.push({ search });
    }
  }, [currentPage, location.search, history]);

  useEffect(() => {
    setTotalNumberOfPlans(data?.disasterRecoveryPlans.totalNumberOfPlans);
    setTotalNumberOfPages(data?.disasterRecoveryPlans.totalNumberOfPages);
  }, [data]);

  if (removeId) {
    removePlan()
      .then(() => {
        refetch();
        toast.success(toastMessages.DISASTER_RECOVERY_PLAN_REMOVED);
      })
      .catch((e) =>
        errorToast(e, toastMessages.DISASTER_RECOVERY_PLAN_REMOVAL_ERROR)
      );
    setRemoveId("");
  }

  const onDeleteHandler = (id: string) => {
    Popup.create({
      onClose: () => {
        Popup.close();
      },
      title: `Delete Plan`,
      content: (
        <div className="box">
          <p>{confirmations.DELETE_DISASTER_RECOVERY_PLAN}</p>
        </div>
      ),
      buttons: {
        right: [
          {
            text: "Cancel",
            className: "btn",
            action: () => {
              Popup.close();
            },
          },
          {
            text: "Delete",
            className: "btn",
            action: async () => {
              setRemoveId(id);
              Popup.close();
            },
          },
        ],
      },
    });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Provider",
        Cell: ({ row }: any) => (
          <div style={{ padding: ".5rem" }}>
            {row.original.cloudProvider && (
              <Icon
                height="1.75rem"
                image={row.original.cloudProvider}
                width="1.75rem"
              />
            )}
          </div>
        ),
      },
      {
        Header: "Restore region",
        accessor: "restoreRegion",
      },
      {
        Header: "Start date",
        Cell: ({ row }: any) => (
          <Moment unix format="DD-MM-YYYY">
            {row.original.startTimestamp}
          </Moment>
        ),
      },
      {
        Header: "Frequency",
        Cell: ({ row }: any) => (
          <Pluralize
            singular={"Month"}
            count={row.original.frequencyInMonths}
          />
        ),
      },
      {
        Header: "Reminder",
        Cell: ({ row }: any) => (
          <Pluralize singular={"Day"} count={row.original.remindDaysBefore} />
        ),
      },
      {
        Header: "Recipients",
        Cell: ({ row }: any) => (
          <>
            {row.original.emails?.map((email: string, index: number) => (
              <div key={`${row.original.id}-email-recipient-${index}`}>
                {email}
              </div>
            ))}
          </>
        ),
      },
      {
        Header: "Templates",
        Cell: ({ row }: any) => (
          <>
            {row.original.templatesResourcesMapping?.map(
              (template: { template: Template }) => (
                <div key={`${row.original.id}-${template.template.id}`}>
                  {template.template.name}
                </div>
              )
            )}
          </>
        ),
      },
      {
        id: "actions",
        Cell: ({ row }: any) => (
          <div>
            <div className="navbar-item has-dropdown is-hoverable is-pulled-right">
              <span className="icon">
                <i className="fa fa-ellipsis-v" />
              </span>
              <div className="navbar-dropdown is-right">
                {userRole !== UserRole.ReadOnly && (
                  <>
                    <a
                      className="navbar-item"
                      onClick={() =>
                        history.push(
                          ROUTES.disasterRecovery.plans.testing.generate({
                            id: row.original.id,
                          })
                        )
                      }
                    >
                      <span className="icon">
                        <i className="fa fa-plus-square" />
                      </span>
                      <span>Start DR</span>
                    </a>
                    <a
                      className="navbar-item"
                      onClick={() => onDeleteHandler(row.original.id)}
                    >
                      <span className="icon">
                        <i className="fa fa-trash" />
                      </span>
                      <span>Delete</span>
                    </a>
                  </>
                )}
                <a
                  className="navbar-item"
                  onClick={() =>
                    history.push(
                      ROUTES.disasterRecovery.plans.details.generate({
                        id: row.original.id,
                      })
                    )
                  }
                >
                  <span className="icon">
                    <i className="fa fa-eye" />
                  </span>
                  {userRole !== UserRole.ReadOnly ? (
                    <span>View / Edit</span>
                  ) : (
                    <span>View</span>
                  )}
                </a>
              </div>
            </div>
          </div>
        ),
      },
    ],
    [history, userRole]
  );

  return (
    <Loading loading={loading || userRoleLoading}>
      {error && <Error error={error} />}
      {data && data.disasterRecoveryPlans.data.length > 0 ? (
        <>
          <Loading loading={removePlanLoading} />
          <Table
            columns={columns}
            data={data.disasterRecoveryPlans.data}
            disableSortBy
          />
        </>
      ) : (
        <Placeholder message="Currently there are no plans." />
      )}
      <Pagination
        totalItems={totalNumberOfPlans || 0}
        totalPages={totalNumberOfPages || 0}
        currentPage={currentPage}
        showing={getPlansNumber()}
        changePage={setCurrentPage}
        previousPage={previousPage}
        nextPage={nextPage}
      />
    </Loading>
  );
};

export default PlansList;
