/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

interface ColorVariants {
  main: string;
  shadowed: string;
}

type Color<T> = T | "Dark" | "Light" | "Unique";

export const COLORS: Record<Color<string>, ColorVariants> = {
  Dark: {
    main: "rgb(22,22,50)",
    shadowed: "rgba(22,22,50, 0.25)",
  },
  Light: {
    main: "rgb(250, 250, 250)",
    shadowed: "rgba(250, 250, 250, 0.25)",
  },
  Unique: {
    main: "rgb(231, 76, 60)",
    shadowed: "rgba(231, 76, 60, 0.25)",
  },
  Something: {
    main: "",
    shadowed: "",
  },
};

type Transformations<T> = T | "rotate" | "translate";

export const TRANSFORM: Record<Transformations<string>, string> = {
  rotate: "rotate(90deg)",
};

const setTransition = (property: string | Array<string>) => {
  if (Array.isArray(property)) {
    const newArr = property.map((prop) => `${prop} 0.3s ease-out`);
    return newArr.join(", ");
  }
  if (typeof property === "string") return `${property} 0.3s ease-out`;
  if (typeof property === undefined) return `all 0.3s ease-out`;
};

export const TRANSITION = { set: setTransition };

const CONSTANTS = {
  TRANSFORM,
  COLORS,
  TRANSITION,
};

export default CONSTANTS;
