/**
 * Copyright 2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Status } from "../../../utils/constants";

export const PAGE_SIZE = 50;

export const setLabelForStatus = (status: Status) => {
  switch (status) {
    case Status.Success:
      return { className: "is-success", label: "SUCCESS" };
    case Status.Failed:
      return { className: "is-danger", label: "FAILED" };
    case Status.FailedStopped:
      return { className: "is-warning", label: "FAILED STOPPED" };
    case Status.ReplicationFailed:
      return { className: "is-warning", label: "REPLICATION FAILED" };
    default:
      return { className: "", label: status };
  }
};
