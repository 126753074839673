/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { useContext } from "react";
import NotificationGroups from "../components/NotificationGroups";
import Button from "../../../commonComponents/button/Button";
import { Loading } from "../../../commonComponents/loading/Loading";
import { Link, RouteComponentProps } from "react-router-dom";
import Segment from "../../../commonComponents/segment/Segment";
import { CustomerContext } from "../../../context/customer";
import { useGetUserRoleQuery, UserRole } from "../../../generated/graphql";
import { ROUTES } from "../../../routes/routes";

export const NotificationGroupPage: React.FC<RouteComponentProps> = ({
  history,
}) => {
  const { customer } = useContext(CustomerContext);
  const customerId = customer?.id || "";

  const { data: userRoleData, loading: userRoleLoading } = useGetUserRoleQuery({
    variables: {
      customerId: customerId,
    },
  });

  const { userRole } = userRoleData || {};

  return (
    <div className="box">
      <div className="columns is-vcentered border-bottom">
        <div className="column is-7">
          <h1 className="title is-marginless is-5 is-capitalized">
            Notification groups
          </h1>
        </div>
        <div className="column is-5">
          {userRole !== UserRole.ReadOnly && (
            <Segment alignRight>
              <Link to={ROUTES.settings.notificationGroups.create.generate()}>
                <Button className="is-pulled-right">Create new</Button>
              </Link>
            </Segment>
          )}
        </div>
      </div>
      <Loading loading={userRoleLoading}>
        <NotificationGroups
          userRole={userRole}
          userRoleLoading={userRoleLoading}
          history={history}
        />
      </Loading>
    </div>
  );
};
