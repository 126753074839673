/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { toast } from "react-toastify";
import { toastMessages } from "./constants";

const errorToast = (error: any, errorMessage?: string) => {
  const message = error.message.replace("GraphQL error: ", "");
  return toast.error(
    message !== ""
      ? message
      : errorMessage
      ? errorMessage
      : toastMessages.FAILED
  );
};

export default errorToast;
