/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { useState } from "react";
import { useGetApiKeysQuery } from "../../../generated/graphql";
import ApiKeysTable from "../../../shared/apiKeys/ApiKeysTable";
import useApiKeysColumns from "../../../shared/apiKeys/columns/useApiKeysColumns";

interface UserApiKeysTableProps {
  owner: string;
}

const UserApiKeysTable: React.FC<UserApiKeysTableProps> = ({ owner }) => {
  const [currentPage, setCurrentPage] = useState<number>(1);

  const { columns } = useApiKeysColumns();

  const { data, error, loading } = useGetApiKeysQuery({
    variables: {
      searchCriteria: {
        pageNumber: currentPage,
        owner,
      },
    },
  });

  return (
    <ApiKeysTable
      apiKeysData={data?.apiKeys}
      apiKeysError={error}
      apiKeysLoading={loading}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      title="User API keys"
      columns={columns}
    />
  );
};

export default UserApiKeysTable;
