/**
 * Copyright 2021-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { useEffect, useState } from "react";
import { RouteComponentProps, useLocation } from "react-router-dom";
import queryString from "query-string";
import { useGetApiKeysQuery } from "../../../generated/graphql";
import { unstable_batchedUpdates } from "react-dom";
import ApiKeysTable from "../../../shared/apiKeys/ApiKeysTable";
import ApiKeyStringModal from "../../../shared/apiKeys/ApiKeyStringModal";
import ApiKeyCreateModal from "../components/ApiKeyCreateModal";
import useApiKeysColumns from "../../../shared/apiKeys/columns/useApiKeysColumns";
import SearchFilter from "../../../commonComponents/filters/SearchFilter";
import {
  LABELS,
  PLACEHOLDERS,
} from "../../../commonComponents/filters/constants";

const ApiKeysPage: React.FC<RouteComponentProps> = ({ history }) => {
  const location = useLocation();
  const filtersFromUrl = () => {
    if (location.search === "") return "";

    const params = queryString.parse(location.search);
    return JSON.parse(params.filters as string);
  };

  const pageFromUrl = (): number => {
    if (location.search === "") return 1;

    const params = queryString.parse(location.search);
    const pageUrl = params.page;
    if (pageUrl) {
      return +pageUrl;
    }

    return 1;
  };

  const [filters, setFilters] = useState<string>(filtersFromUrl);
  const [currentPage, setCurrentPage] = useState<number>(pageFromUrl);
  const [createModalIsOpen, setCreateModalIsOpen] = useState<boolean>(false);
  const [apiKeyStringModalIsOpen, setApiKeyStringModalIsOpen] =
    useState<boolean>(false);
  const [apiKeyString, setApiKeyString] = useState<string | undefined>("");

  const { columns } = useApiKeysColumns();
  useEffect(() => {
    const search = `?filters=${JSON.stringify(filters)}&page=${currentPage}`;
    if (location.search === search) return;

    if (location.search === "") {
      history.replace({ search });
    } else {
      history.push({ search });
    }
  }, [filters, currentPage]);

  useEffect(() => {
    unstable_batchedUpdates(() => {
      setFilters(filtersFromUrl());
      setCurrentPage(pageFromUrl());
    });
  }, [location]);

  const { data, error, loading, refetch } = useGetApiKeysQuery({
    variables: {
      searchCriteria: {
        pageNumber: currentPage,
        ...(filters && { owner: filters }),
      },
    },
  });

  const applyFilter = (newFilters: string) => {
    setFilters(newFilters);
    setCurrentPage(1);
  };

  return (
    <div className="main wide">
      <div className="container is-fluid container-padding">
        <SearchFilter
          filters={filters}
          applyFilter={applyFilter}
          label={LABELS.owner}
          placeholder={PLACEHOLDERS.owner}
        />
        <ApiKeysTable
          apiKeysData={data?.apiKeys}
          apiKeysError={error}
          apiKeysLoading={loading}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          title="API keys"
          columns={columns}
          setCreateModalIsOpen={setCreateModalIsOpen}
        />
        {createModalIsOpen && (
          <ApiKeyCreateModal
            setIsOpen={setCreateModalIsOpen}
            setApiKeyString={setApiKeyString}
            setApiKeyStringModalOpen={setApiKeyStringModalIsOpen}
            refetch={refetch}
          />
        )}
        {apiKeyStringModalIsOpen && apiKeyString && !createModalIsOpen && (
          <ApiKeyStringModal
            setIsOpen={setApiKeyStringModalIsOpen}
            data={apiKeyString}
          />
        )}
      </div>
    </div>
  );
};

export default ApiKeysPage;
