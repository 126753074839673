/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from "react";
import { Redirect, Route, RouteComponentProps } from "react-router-dom";
import useUserRole from "../../utils/useUserRole";
import { StaticContext } from "react-router";
import { History } from "history";
import { UserRole } from "../../generated/graphql";
import { ROUTES } from "../../routes/routes";
import { toast } from "react-toastify";
import { toastMessages } from "../../utils/constants";

interface ProtectedRouteProps {
  component?: React.FC<
    RouteComponentProps<{}, StaticContext, History.UnknownFacade>
  >;
  exact?: boolean;
  requiredRoles: [UserRole];
  path: string;
  children?: any;
}

const ProtectedRoute = ({
  component: Component,
  exact,
  path,
  requiredRoles,
  children,
}: ProtectedRouteProps) => {
  const role = useUserRole();

  if (role && !requiredRoles.includes(role)) {
    toast.error(toastMessages.INVALID_ACCESS);
    return <Redirect to={ROUTES.index.route} />;
  }

  return (
    <Route
      exact={exact ? exact : undefined}
      path={path}
      component={Component ? Component : undefined}
    >
      {children}
    </Route>
  );
};

export default ProtectedRoute;
