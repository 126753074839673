/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { useContext, useState } from "react";
import { History } from "history";
import Button from "../../../commonComponents/button/Button";
import {
  CloudProvider,
  useCreateTemplateMutation,
} from "../../../generated/graphql";
import { toast } from "react-toastify";
import { TemplateTooltips, toastMessages } from "../../../utils/constants";
import Input from "../../../commonComponents/input/Input";
import ValidationMessage from "../../../commonComponents/validationMessage/ValidationMessage";
import SimpleReactValidator from "simple-react-validator";
import { CustomerContext } from "../../../context/customer";
import { ROUTES } from "../../../routes/routes";
import Textarea from "../../../commonComponents/textarea/Textarea";
import ReactTooltip from "react-tooltip";
import { GetSupportedFilesFormat } from "./constants";
import ProviderSelector from "./ProviderSelector";
import { Loading } from "../../../commonComponents/loading/Loading";

interface Props {
  history: History;
}

const URL_EC2_RESTORE_TEMPLATE =
  "https://nordcloud-pat-public-assets.s3-eu-west-1.amazonaws.com/templates/autobackup/DefaultEC2Recovery.yml";
const URL_RDS_INSTANCE_RESTORE_TEMPLATE =
  "https://nordcloud-pat-public-assets.s3-eu-west-1.amazonaws.com/templates/autobackup/DefaultRDSInstanceRecovery.yml";
const URL_AZURE_VM_RESTORE_TEMPLATE =
  "https://nordcloud-pat-public-assets.s3-eu-west-1.amazonaws.com/templates/autobackup/DefaultAzureVMRecovery.json";

const validator = new SimpleReactValidator();

const TemplateForm: React.FC<Props> = ({ history }) => {
  const { customer } = useContext(CustomerContext);
  const customerId = customer && customer.id ? customer.id : "";
  const [uploadedFileName, setUploadedFileName] = useState<string>("");

  const [name, setName] = useState<string>("");
  const [templateContent, setTemplateContent] = useState<string>("");
  const [cloudProvider, setCloudProvider] = useState<CloudProvider>(
    CloudProvider.Aws
  );
  const [isLoading, setIsLoading] = useState(false);

  const [validationError, setValidationError] = useState(false);
  let graphqlValidationError;
  const [graphqlValidationErrors, setGraphqlValidationErrors] =
    useState<any>(null);

  const [createTemplate] = useCreateTemplateMutation({
    variables: {
      input: {
        customerId: customerId,
        name: name,
        content: templateContent,
        cloudProvider: cloudProvider,
      },
    },
  });

  const onSubmit = () => {
    setIsLoading(true);
    validationError && setValidationError(false);
    if (validator.allValid()) {
      createTemplate()
        .then(() => {
          toast.success(toastMessages.TEMPLATE_CREATED);
          validator.hideMessages();
          history.push(ROUTES.disasterRecovery.templates.index.generate());
          setIsLoading(false);
        })
        .catch((e: any): any => {
          toast.error(toastMessages.TEMPLATE_CREATION_ERROR);
          setGraphqlValidationErrors(e.graphQLErrors);
          setIsLoading(false);
        });
    } else {
      setValidationError(true);
      validator.showMessages();
      toast.error(toastMessages.VALIDATION_FAILED);
      setIsLoading(false);
    }
  };

  let fileReader: FileReader,
    inputFile: any = null;

  const uploadClick = () => {
    inputFile.click();
    return false;
  };

  const handleFileRead = () => {
    setTemplateContent(fileReader.result!.toString());
  };

  const handleUploadedFile = (file: any) => {
    fileReader = new FileReader();
    fileReader.onloadend = handleFileRead;
    fileReader.readAsText(file);
    setUploadedFileName(file.name);
  };

  const { supportedFiles, supportedFilesMessage } =
    GetSupportedFilesFormat(cloudProvider);

  return (
    <div className="main-wide">
      <div className="container">
        <>
          <div className="box">
            <div className="columns is-vcentered border-bottom">
              <div className="column">
                <h1 className="title is-marginless is-5 is-capitalized">
                  Example templates
                </h1>
              </div>
            </div>
            <div>
              <a
                href={URL_EC2_RESTORE_TEMPLATE}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  outline
                  size="extra-small"
                  margin="0 .25rem 1rem .25rem"
                >
                  <i className="fa fa-download" /> AWS EC2 Cloudformation
                  template
                </Button>
              </a>
              <a
                href={URL_RDS_INSTANCE_RESTORE_TEMPLATE}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  outline
                  size="extra-small"
                  margin="0 .25rem 1rem .25rem"
                >
                  <i className="fa fa-download" /> AWS RDS Instance
                  Cloudformation template
                </Button>
              </a>
              <a
                href={URL_AZURE_VM_RESTORE_TEMPLATE}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  outline
                  size="extra-small"
                  margin="0 .25rem 1rem .25rem"
                >
                  <i className="fa fa-download" /> Azure VM ARM template
                </Button>
              </a>
            </div>
          </div>
          <div className="box">
            <div className="field">
              <label className="label">
                Template name*
                <span className="icon" data-tip={TemplateTooltips.name}>
                  <i className="fa fa-question-circle" />
                </span>
              </label>
              <Input
                value={name}
                onChange={(e) => setName(e.target.value)}
                type="text"
              />
              <ValidationMessage>
                {validator.message("name", name, "required|max:64")}
              </ValidationMessage>
              {graphqlValidationErrors &&
                (graphqlValidationError = graphqlValidationErrors?.find(
                  (e: any) => e.errorInfo === "name"
                )) && (
                  <p className="validation-error">
                    {graphqlValidationError.message}
                  </p>
                )}
            </div>

            <div className="field">
              <label className="label">
                Template cloud provider*
                <span
                  className="icon"
                  data-tip={TemplateTooltips.cloudProvider}
                >
                  <i className="fa fa-question-circle" />
                </span>
              </label>
              <ProviderSelector
                selectedCloudProvider={cloudProvider}
                onSelect={(provider: CloudProvider) => {
                  setCloudProvider(provider);
                  setTemplateContent("");
                  setUploadedFileName("");
                  inputFile.value = "";
                }}
              />
              {graphqlValidationErrors &&
                (graphqlValidationError = graphqlValidationErrors?.find(
                  (e: any) => e.errorInfo === "cloudProvider"
                )) && (
                  <p className="validation-error">
                    {graphqlValidationError.message}
                  </p>
                )}
            </div>

            <label className="label">
              Template file*
              <span className="icon" data-tip={TemplateTooltips.content}>
                <i className="fa fa-question-circle" />
              </span>
            </label>
            <div className="columns">
              <div className="column is-4">
                <p>{supportedFilesMessage}</p>
              </div>
              <div className="column">
                <Button
                  className="has-margin-right"
                  onClick={() => uploadClick()}
                  disabled={supportedFiles === ""}
                >
                  Choose file
                </Button>
                {uploadedFileName ? (
                  <span>{uploadedFileName}</span>
                ) : (
                  <span>No file chosen</span>
                )}
                <input
                  type="file"
                  id="file"
                  ref={(input) => {
                    inputFile = input;
                  }}
                  className="input-file"
                  accept={supportedFiles}
                  onChange={(e) => handleUploadedFile(e.target.files?.[0])}
                />
              </div>
            </div>
            {graphqlValidationErrors &&
              (graphqlValidationError = graphqlValidationErrors?.find(
                (e: any) => e.errorInfo === "content"
              )) && (
                <p className="validation-error">
                  {graphqlValidationError.message}
                </p>
              )}
            <Textarea rows={20} value={templateContent} disabled />
            <hr />
            <div className="columns">
              <div className="column is-5-desktop">
                <div className="columns">
                  <div className="column">
                    <small>* - required</small>
                  </div>
                </div>
              </div>
              <ReactTooltip
                effect="solid"
                className="setup-tooltip"
                multiline={true}
              />
            </div>
            <div className="is-pulled-right">
              <Loading loading={isLoading}>
                <Button
                  onClick={() => onSubmit()}
                  disabled={uploadedFileName === ""}
                >
                  Create template
                </Button>
              </Loading>
            </div>
            <div className="is-clearfix" />
          </div>
        </>
      </div>
    </div>
  );
};

export default TemplateForm;
