/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from "react";
import {
  Account,
  AccountType,
  CloudProvider,
  GetUserRoleQuery,
  useGetAwsAccountAccessRoleTemplateQuery,
  UserRole,
} from "../../../generated/graphql";
import { Loading } from "../../../commonComponents/loading/Loading";
import Popup from "react-popup";
import { Icon } from "../../../commonComponents/icon/Icon";

interface Props {
  data: Array<Account>;
  removeAccount: (removeId: string) => void;
  loading: boolean;
  userRole: GetUserRoleQuery | any;
  userRoleLoading: boolean;
  customerId: string;
}

const VaultAccountsList: React.FC<Props> = ({
  data,
  removeAccount,
  loading,
  userRole,
  userRoleLoading,
  customerId,
}) => {
  const onDeleteHandler = (name: string, id: string) => {
    Popup.create({
      onClose: () => {
        Popup.close();
      },
      title: `Delete account`,
      content: (
        <div className="box">
          <p>{`Do you want to remove the account ${name}`}</p>
        </div>
      ),
      buttons: {
        right: [
          {
            text: "Cancel",
            className: "btn",
            action: () => {
              Popup.close();
            },
          },
          {
            text: "Delete",
            className: "btn",
            action: async () => {
              removeAccount(id);
              Popup.close();
            },
          },
        ],
      },
    });
  };

  const { refetch: refetchAwsAccountAccessRoleTemplate } =
    useGetAwsAccountAccessRoleTemplateQuery();

  const onDownloadAwsRoleHandler = async (cloudProviderId: string) => {
    const role = await refetchAwsAccountAccessRoleTemplate({
      input: {
        customerId: customerId,
        accountId: cloudProviderId,
        accountType: AccountType.Vault,
      },
    });

    return window.open(role.data.awsAccountAccessRoleTemplate);
  };

  return (
    <>
      <Loading loading={loading} />
      <table className="table is-fullwidth is-striped" data-cy="vault-accounts">
        <thead>
          <tr style={{ fontSize: ".9rem" }}>
            <th className="is-uppercase">Account Name</th>
            <th className="is-uppercase">Account ID</th>
            <th className="is-uppercase">Provider</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {data.map((el, index) => (
            <tr key={index}>
              <td>{el.name}</td>
              <td>{el.cloudProviderId}</td>
              <td>
                <Icon
                  height="1.75rem"
                  image={el.cloudProvider}
                  width="1.75rem"
                />
              </td>
              <td>
                <Loading loading={userRoleLoading}>
                  {userRole?.userRole !== UserRole.ReadOnly && (
                    <div>
                      <div className="navbar-item has-dropdown is-hoverable is-pulled-right">
                        <span className="icon">
                          <i className="fa fa-ellipsis-v" />
                        </span>
                        <div className="navbar-dropdown is-right">
                          <a
                            className="navbar-item"
                            onClick={() => onDeleteHandler(el.name, el.id)}
                            data-cy="delete-vault-account"
                          >
                            <span className="icon">
                              <i className="fa fa-trash" />
                            </span>
                            <span>Delete</span>
                          </a>
                          {el.cloudProvider === CloudProvider.Aws && (
                            <a
                              className="navbar-item"
                              onClick={() =>
                                onDownloadAwsRoleHandler(el.cloudProviderId)
                              }
                            >
                              <span className="icon">
                                <i className="fa fa-download" />
                              </span>
                              <span>Download role</span>
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </Loading>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default VaultAccountsList;
