/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  useTable,
  useRowSelect,
  useSortBy,
  useMountedLayoutEffect,
  TableOptions,
} from "react-table";
import React from "react";
import * as StyledTable from "./StyledTable";

const Table = ({
  columns,
  data,
  initialState,
  onSelectedRowsChange,
  disableSortBy,
}: TableOptions<{}>) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data,
      initialState,
      disableSortBy,
    },
    useSortBy,
    useRowSelect
  );

  useMountedLayoutEffect(() => {
    const selectedRowsIds = selectedFlatRows
      .map((row) => row.original)
      .map(({ id }: any) => id);
    onSelectedRowsChange && onSelectedRowsChange(selectedRowsIds);
  }, [onSelectedRowsChange, selectedFlatRows]);

  // @ts-ignore
  return (
    <>
      <StyledTable.StyledTable {...getTableProps()}>
        <StyledTable.TableHead>
          {headerGroups.map((headerGroup) => (
            <StyledTable.TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <StyledTable.TableHeadData
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render("Header")}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <i className="fa fa-caret-down" />
                      ) : (
                        <i className="fa fa-caret-up" />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </StyledTable.TableHeadData>
              ))}
            </StyledTable.TableRow>
          ))}
        </StyledTable.TableHead>
        <StyledTable.TableBody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <StyledTable.TableRow {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <StyledTable.TableData {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </StyledTable.TableData>
                  );
                })}
              </StyledTable.TableRow>
            );
          })}
        </StyledTable.TableBody>
      </StyledTable.StyledTable>
    </>
  );
};

export default Table;
