/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from "react";
import { Link, useLocation } from "react-router-dom";

interface NavigationLinkProps {
  name: string;
  path: string;
}
export const NavigationLink: React.FC<NavigationLinkProps> = ({
  name,
  path,
}) => {
  const location = useLocation();
  const pageName = location.pathname.split("/")[1];
  const label = pageName ? pageName : "dashboard";
  const isActive =
    name.toLowerCase().replace(/\s/g, "") === label.toLowerCase();

  return (
    <Link
      className={`navbar-item  ${
        isActive ? "has-text-black has-text-weight-bold" : ""
      }`}
      to={path}
      data-cy={name}
    >
      <span>{name}</span>
    </Link>
  );
};
export const NavigationLinkExternal: React.FC<NavigationLinkProps> = ({
  name,
  path,
}) => {
  return (
    <a className="navbar-item" href={path} target="_blank">
      <span className="icon">
        <i className="fa fa-file" />
      </span>
      <span>{name}</span>
    </a>
  );
};
