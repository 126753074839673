/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { useContext, useState } from "react";
import { Link, RouteComponentProps, useLocation } from "react-router-dom";
import { CustomerContext } from "../../../context/customer";
import { useGetUserRoleQuery, UserRole } from "../../../generated/graphql";
import Segment from "../../../commonComponents/segment/Segment";
import { ROUTES } from "../../../routes/routes";
import Button from "../../../commonComponents/button/Button";
import { Loading } from "../../../commonComponents/loading/Loading";
import TemplatesList from "../components/TemplatesList";
import queryString from "query-string";

export const TemplatesPage: React.FC<RouteComponentProps> = ({ history }) => {
  const { customer } = useContext(CustomerContext);
  const customerId = customer?.id || "";
  const location = useLocation();

  const { data: userRoleData, loading: userRoleLoading } = useGetUserRoleQuery({
    variables: {
      customerId: customerId,
    },
  });

  const { userRole } = userRoleData || {};

  const pageFromUrl = (): number => {
    if (location.search === "") return 1;

    const params = queryString.parse(location.search);
    const page = params.page;
    if (page) {
      return +page;
    }

    return 1;
  };

  const [currentPage, setCurrentPage] = useState<number>(pageFromUrl);

  return (
    <div className="box">
      <div className="columns is-vcentered border-bottom">
        <div className="column">
          <h1 className="title is-marginless is-5 is-capitalized">Templates</h1>
        </div>
        <div className="column">
          {userRole !== UserRole.ReadOnly && (
            <Segment alignRight>
              <Link to={ROUTES.disasterRecovery.templates.create.generate()}>
                <Button className="is-pulled-right">Create new</Button>
              </Link>
            </Segment>
          )}
        </div>
      </div>
      <Loading loading={userRoleLoading}>
        <TemplatesList
          userRole={userRole}
          userRoleLoading={userRoleLoading}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          history={history}
        />
      </Loading>
    </div>
  );
};
