/**
 * Copyright 2021-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from "react";
import { ApiKey } from "../../generated/graphql";
import Moment from "react-moment";
import Popup from "react-popup";
import { toast } from "react-toastify";
import { toastMessages } from "../../utils/constants";
import { useHistory } from "react-router-dom";

interface ApiKeyBasicInformationProps {
  apiKeyData: ApiKey;
  id: string;
  redirect: string;
  removeApiKey: any;
}

const ApiKeyBasicInformation: React.FC<ApiKeyBasicInformationProps> = ({
  apiKeyData,
  id,
  redirect,
  removeApiKey,
}) => {
  const history = useHistory();

  const onDeleteHandler = () => {
    Popup.create({
      onClose: () => {
        Popup.close();
      },
      title: `Delete API key`,
      content: (
        <div className="box">
          <p>{`Do you want to remove ${id}`}</p>
        </div>
      ),
      buttons: {
        right: [
          {
            text: "Cancel",
            className: "btn",
            action: () => {
              Popup.close();
            },
          },
          {
            text: "Delete",
            className: "btn",
            action: async () => {
              try {
                await removeApiKey();
                history.push(redirect);
                toast.success(toastMessages.API_KEY_REMOVED);
              } catch (e) {
                toast.error(toastMessages.API_KEY_REMOVAL_ERROR);
              }
              Popup.close();
            },
          },
        ],
      },
    });
  };

  return (
    <div className="box">
      <div className="columns border-bottom">
        <div className="column">
          <h1 className="title is-marginless is-5 is-capitalized">
            API key basic information
          </h1>
        </div>
        <div className="column">
          <div className="navbar-item has-dropdown is-hoverable is-pulled-right">
            <span className="icon">
              <i className="fa fa-ellipsis-v" />
            </span>
            <div className="navbar-dropdown is-right">
              <a className="navbar-item" onClick={onDeleteHandler}>
                <span className="icon">
                  <i className="fa fa-trash" />
                </span>
                <span>Delete</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <strong className="is-desc-label">API ID</strong>
          <br />
          {apiKeyData.id}
        </div>
        <div className="column">
          <strong className="is-desc-label">Alias</strong>
          <br />
          {apiKeyData.alias}
        </div>
        <div className="column">
          <strong className="is-desc-label">Owner</strong>
          <br />
          {apiKeyData.owner}
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <strong className="is-desc-label">Created at</strong>
          <br />
          <Moment format="DD-MM-YYYY HH:mm">{apiKeyData.createdAt}</Moment>
        </div>
        <div className="column">
          <strong className="is-desc-label">Created by</strong>
          <br />
          {apiKeyData.createdBy}
        </div>
        <div className="column">
          <strong className="is-desc-label">Last used</strong>
          <br />
          {apiKeyData.lastUsedAt ? (
            <Moment format="DD-MM-YYYY HH:mm">{apiKeyData.lastUsedAt}</Moment>
          ) : (
            <>Not used yet</>
          )}
        </div>
      </div>
    </div>
  );
};

export default ApiKeyBasicInformation;
