/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useCreateApiKeyMutation } from "../../../generated/graphql";
import SimpleReactValidator from "simple-react-validator";
import Input from "../../../commonComponents/input/Input";
import ValidationMessage from "../../../commonComponents/validationMessage/ValidationMessage";
import { Loading } from "../../../commonComponents/loading/Loading";
import Button from "../../../commonComponents/button/Button";
import { Modal } from "../../../commonComponents/modal/Modal";
import { toast } from "react-toastify";
import { toastMessages } from "../../../utils/constants";
import { ObservableQuery } from "apollo-client/core/ObservableQuery";

interface ApiKeyCreateModalProps {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setApiKeyStringModalOpen: Dispatch<SetStateAction<boolean>>;
  setApiKeyString: Dispatch<SetStateAction<string | undefined>>;
  refetch: ObservableQuery["refetch"];
}

const validator = new SimpleReactValidator();

const ApiKeyCreateModal: React.FC<ApiKeyCreateModalProps> = ({
  setIsOpen,
  setApiKeyStringModalOpen,
  setApiKeyString,
  refetch,
}) => {
  const [alias, setAlias] = useState("");
  const [owner, setOwner] = useState("");
  const [loading, setLoading] = useState(false);

  const [validateError, setValidateError] = useState(false);
  const [settingsValidationErrors, setSettingsValidationErrors] =
    useState<any>(null);
  let validationError;

  const [createApiKey, { data }] = useCreateApiKeyMutation({
    variables: {
      input: {
        alias,
        owner,
      },
    },
  });

  useEffect(() => {
    setApiKeyString(data?.createApiKey.apiKeyString);
  }, [data]);

  const finishCreatingApiKeyHandler = async () => {
    validateError && setValidateError(false);
    if (validator.allValid()) {
      setLoading(true);
      try {
        await createApiKey();
        validator.hideMessages();
        toast.success(toastMessages.CREATE_API_KEY);
        await refetch();
      } catch (e) {
        toast.error(toastMessages.CREATE_API_KEY_ERROR);
        setSettingsValidationErrors(e.graphQLErrors);
      } finally {
        setApiKeyStringModalOpen(true);
        setIsOpen(false);
        setLoading(false);
      }
    } else {
      validator.showMessages();
      setValidateError(true);
    }
  };

  return (
    <Modal onHide={() => setIsOpen(false)} title="Create an API key">
      <div className="field">
        <label className="label">Alias*</label>
        <Input
          value={alias}
          onChange={(e) => {
            setAlias(e.target.value);
          }}
          type="text"
        />
        {settingsValidationErrors &&
          (validationError = settingsValidationErrors?.find(
            (e: any) => e.errorInfo === "alias"
          )) && <p className="validation-error">{validationError.message}</p>}
        <ValidationMessage>
          {validator.message("alias", alias, "required|max:255")}
        </ValidationMessage>
      </div>
      <div className="field">
        <label className="label">Owner*</label>
        <Input
          value={owner}
          onChange={(e) => {
            setOwner(e.target.value);
          }}
          type="text"
        />
        {settingsValidationErrors &&
          (validationError = settingsValidationErrors?.find(
            (e: any) => e.errorInfo === "owner"
          )) && <p className="validation-error">{validationError.message}</p>}
        <ValidationMessage>
          {validator.message("owner", owner, "required|max:255")}
        </ValidationMessage>
      </div>
      <Loading loading={loading}>
        <Button onClick={finishCreatingApiKeyHandler}>Create</Button>
      </Loading>
    </Modal>
  );
};

export default ApiKeyCreateModal;
