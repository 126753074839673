/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { useContext, useEffect, useState } from "react";
import { CustomerContext } from "../../../context/customer";
import Segment from "../../../commonComponents/segment/Segment";
import Button from "../../../commonComponents/button/Button";
import DatePicker from "react-datepicker";
import moment from "moment";
import ValidationMessage from "../../../commonComponents/validationMessage/ValidationMessage";
import SimpleReactValidator from "simple-react-validator";
import { useDisasterRecoveryRecordsReportLazyQuery } from "../../../generated/graphql";
import { Error } from "../../../commonComponents/error/error";
import { toast } from "react-toastify";
import { toastMessages } from "../../../utils/constants";

const validator = new SimpleReactValidator();

const RecordsReport: React.FC = () => {
  const customer = useContext(CustomerContext);
  const customerId =
    customer && customer.customer && customer.customer.id
      ? customer.customer.id
      : "";

  const [validationError, setValidationError] = useState(false);
  const [startTimestamp, setStartTimestamp] = useState<number>();
  const [endTimestamp, setEndTimestamp] = useState<number>();

  const [getReport, { data, error }] =
    useDisasterRecoveryRecordsReportLazyQuery();

  useEffect(() => {
    if (typeof window !== "undefined" && data) {
      window.location.href = `data:text/csv;charset=utf-8,${encodeURIComponent(
        data.disasterRecoveryRecordsReport.data || ""
      )}`;
    }
  }, [data]);

  const onSubmit = async () => {
    validationError && setValidationError(false);
    if (validator.allValid()) {
      await getReport({
        variables: {
          input: {
            customerId: customerId,
            timeRange: {
              dateFromTimestamp: startTimestamp || 0,
              dateToTimestamp: endTimestamp || 0,
            },
          },
        },
      });
    } else {
      setValidationError(true);
      validator.showMessages();
      toast.error(toastMessages.VALIDATION_FAILED);
    }
  };

  return (
    <div className="box">
      {error && <Error error={error} />}
      <div className="columns is-vcentered border-bottom">
        <div className="column">
          <h1 className="title is-marginless is-5 is-capitalized">Report</h1>
        </div>
      </div>
      <div className="columns is-multiline">
        <div className="field column is-4">
          <label className="label">Start date</label>
          <div className="control">
            <DatePicker
              className="input border"
              dateFormat={"dd-MM-yyyy"}
              selected={
                startTimestamp !== undefined
                  ? new Date(startTimestamp * 1000)
                  : null
              }
              todayButton="Today"
              onChange={(e: any) => {
                if (e !== null) {
                  setStartTimestamp(
                    moment(e).add(moment().utcOffset(), "minutes").unix()
                  );
                }
              }}
              placeholderText="Select Date"
            />
            <ValidationMessage>
              {validator.message(
                "startTimestamp",
                startTimestamp,
                "required|numeric|min:1,num"
              )}
            </ValidationMessage>
          </div>
        </div>
        <div className="field column is-4">
          <label className="label">End date</label>
          <div className="control">
            <DatePicker
              className="input border"
              dateFormat={"dd-MM-yyyy"}
              selected={
                endTimestamp !== undefined
                  ? new Date(endTimestamp * 1000)
                  : null
              }
              todayButton="Today"
              onChange={(e: any) => {
                if (e !== null) {
                  setEndTimestamp(
                    moment(e).add(moment().utcOffset(), "minutes").unix()
                  );
                }
              }}
              placeholderText="Select Date"
              minDate={
                startTimestamp !== undefined
                  ? new Date(startTimestamp * 1000)
                  : null
              }
            />
            <ValidationMessage>
              {validator.message(
                "endTimestamp",
                endTimestamp,
                "required|numeric|min:1,num"
              )}
            </ValidationMessage>
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <Segment alignRight>
            <Button onClick={() => onSubmit()}>Download</Button>
          </Segment>
        </div>
      </div>
    </div>
  );
};

export default RecordsReport;
