/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, {
  useMemo,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import Pagination from "../../../commonComponents/pagination/Pagination";
import Placeholder from "../../../commonComponents/table/Placeholder";
import { Loading } from "../../../commonComponents/loading/Loading";
import { Error } from "../../../commonComponents/error/error";
import Table from "../../../commonComponents/table/Table";
import { History } from "history";
import { Maybe, UsersPage, User } from "../../../generated/graphql";
import { ROUTES } from "../../../routes/routes";
import { ApolloError } from "apollo-client";
import Button from "../../../commonComponents/button/Button";

interface Props {
  usersData?: UsersPage;
  usersError?: ApolloError;
  usersLoading: boolean;
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  history: History;
  setCreateModalIsOpen: Dispatch<SetStateAction<boolean>>;
}

const PAGE_SIZE = 50;

const UsersTable: React.FC<Props> = ({
  usersData,
  usersError,
  usersLoading,
  currentPage,
  setCurrentPage,
  history,
  setCreateModalIsOpen,
}) => {
  const [users, setUsers] = useState<Maybe<Array<User>>>();
  const [totalNumberOfUsers, setTotalNumberOfUsers] = useState<
    number | undefined
  >(0);
  const [totalNumberOfPages, setTotalNumberOfPages] = useState<
    number | undefined
  >(0);

  const nextPage = () => {
    if (totalNumberOfPages && currentPage < totalNumberOfPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const getUserDataNumber = () => {
    if (users && users.length < PAGE_SIZE && currentPage === 1) {
      return users.length;
    }
    if (
      users &&
      users?.length < PAGE_SIZE &&
      currentPage === totalNumberOfPages
    ) {
      return PAGE_SIZE * (totalNumberOfPages - 1) + users.length;
    }
    return PAGE_SIZE * currentPage;
  };

  useEffect(() => {
    setTotalNumberOfUsers(usersData?.totalNumberOfUsers);
    setTotalNumberOfPages(usersData?.totalNumberOfPages);
    setUsers(usersData?.data);
  }, [usersData]);

  const columns = useMemo(
    () => [
      {
        Header: "User",
        accessor: "email",
        Cell: ({ row }: any) => {
          const { email } = row.original;

          return (
            <div>
              <div
                className="navbar-item is-hoverable"
                onClick={(e) => {
                  e.preventDefault();
                  history.push(ROUTES.users.user.generate({ email }));
                }}
              >
                <span>{email}</span>
              </div>
            </div>
          );
        },
      },
      {
        Header: "Type",
        accessor: "superAdmin",
        Cell: ({ row }: any) => {
          const { superAdmin } = row.original;
          return superAdmin ? <span>Super Admin</span> : <span>User</span>;
        },
      },
      {
        Header: "Customer accesses",
        accessor: "totalNumberOfResources",
        Cell: ({ row }: any) => {
          const { customerAccesses } = row.original;
          return <span>{customerAccesses?.data.length}</span>;
        },
      },
    ],
    [history]
  );

  return (
    <div className="box is-clearfix">
      <div className="columns is-vcentered border-bottom">
        <div className="column is-7">
          <h1 className="title is-marginless is-5 is-capitalized">Users</h1>
        </div>
        <div className="column">
          <div style={{ float: "right" }}>
            <Button onClick={() => setCreateModalIsOpen(true)}>
              Create a user
            </Button>
          </div>
        </div>
      </div>
      <Loading loading={usersLoading}>
        {usersError && <Error error={usersError} />}
        {users && users.length > 0 ? (
          <Table columns={columns} data={users} disableSortBy />
        ) : (
          <Placeholder message="No data found with current filters." />
        )}
        <Pagination
          totalItems={totalNumberOfUsers || 0}
          totalPages={totalNumberOfPages || 0}
          currentPage={currentPage}
          showing={getUserDataNumber()}
          changePage={setCurrentPage}
          previousPage={previousPage}
          nextPage={nextPage}
        />
      </Loading>
    </div>
  );
};

export default UsersTable;
