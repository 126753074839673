/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { CustomerContext } from "../../context/customer";
import { NordcloudLogo } from "../../assets/nordcloudLogo";
import { NavigationLink, NavigationLinkExternal } from "./NavigationLink";
import { ROUTES } from "../../routes/routes";
import { useAuth0 } from "../auth/Auth0";
import { UserRole } from "../../generated/graphql";
import useUserRole from "../../utils/useUserRole";

const NavigationHeader: React.FC = () => {
  const { logout, user } = useAuth0();
  const { customer, update } = useContext(CustomerContext);

  const role = useUserRole();

  return (
    <nav id="navbar" className="navbar is-spaced">
      <div className="navbar-brand">
        <div className="navbar-item">
          <Link to={ROUTES.index.generate()}>
            <NordcloudLogo fillColor="#161632" />
            <b>AutoBackup</b>
          </Link>
        </div>
      </div>
      <div className="navbar-start">
        <NavigationLink name="Dashboard" path={ROUTES.index.generate()} />
        <NavigationLink name="Events" path={ROUTES.events.index.generate()} />
        <NavigationLink
          name="Resources"
          path={ROUTES.resources.index.generate()}
        />
        <NavigationLink
          name="Accounts"
          path={ROUTES.accounts.index.generate()}
        />
        <NavigationLink
          name="Disaster Recovery"
          path={ROUTES.disasterRecovery.index.generate()}
        />

        <NavigationLink
          name="Settings"
          path={ROUTES.settings.index.generate()}
        />

        <div className="navbar-item has-dropdown is-hoverable">
          <div className="navbar-link">
            Permissions
            <span className="icon">
              <i className="fa fa-chevron-down" />
            </span>
          </div>
          <div className="navbar-dropdown is-right">
            {role === UserRole.SuperAdmin && (
              <>
                <NavigationLink
                  name="Customer Accesses"
                  path={ROUTES.customerAccesses.index.generate()}
                />
                <NavigationLink
                  name="Users"
                  path={ROUTES.users.index.generate()}
                />
                <NavigationLink
                  name="API Keys"
                  path={ROUTES.apiKeys.index.generate()}
                />
              </>
            )}
            <NavigationLink
              name="My API Keys"
              path={ROUTES.myApiKeys.index.generate()}
            />
          </div>
        </div>
      </div>
      <div className="navbar-end">
        <div className="navbar-item has-dropdown is-hoverable">
          <div className="navbar-link">
            {customer && customer.name}
            <span className="icon">
              <i className="fa fa-chevron-down" />
            </span>
          </div>
          <div className="navbar-dropdown is-right">
            <NavigationLinkExternal
              name="User guide"
              path="https://docs.autobackup.nordcloudapp.com"
            />
            <a
              className="navbar-item"
              href="#toggle"
              onClick={(e) => {
                e.preventDefault();
                update({ name: null, id: null });
              }}
            >
              <span className="icon">
                <i className="fa fa-building" />
              </span>
              <span>Switch Customer</span>
            </a>
            <a
              className="navbar-item"
              href="#logout"
              onClick={() => {
                logout({
                  returnTo: window.location.origin,
                });
              }}
            >
              <span className="icon">
                <i className="fa fa-user" />
              </span>
              <span>
                Logout <strong>{user?.name ?? user?.email ?? ""}</strong>
              </span>
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavigationHeader;
