/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

// Process of communicated release is described in: https://nordcloud.atlassian.net/wiki/spaces/NB/pages/1590101019/Process+of+Communicating+Changes+and+Releases
// modify 'lastChange' each time you update release notes: check the timestamp using Date.now() in console
export const lastChange = "1638887923889";

export const notesData = [
  {
    title: "IBM Cloud VMs support",
    date: "13 December, 2021",
    entries: [
      "AutoBackup functionality has been extended with this release to support IBM Cloud VM resources.",
    ],
    details: [
      "AutoBackup functionality has been extended with this release to support IBM Cloud VM resources.",
      "With this now following cloud platforms and resources can be backed up by AutoBackup:",
      "AWS RDS instances",
      "AWS RDS clusters",
      "AWS EC2 instances",
      "AWS DynamoDB tables",
      "AWS S3 buckets",
      "AWS Redshift clusters (without replication)",
      "AWS EFS",
      "AWS Route53",
      "Azure VM",
      "Azure SAP HANA",
      "GCP VM",
      "IBM VM (for primary snapshots) - new!",
    ],
  },
  {
    title: "Manual DR testing of Azure resources",
    date: "24 November, 2021",
    entries: [
      "We've extended Disaster Recovery testing functionality.",
      "Currently supported resources are: AWS RDS instances, AWS RDS clusters, AWS EC2, Azure VM.",
    ],
    details: [
      "We've extended Disaster Recovery testing functionality.",
      "Currently supported resources are:",
      "AWS RDS instances (copying a snapshot to the restore region)",
      "AWS RDS clusters (copying a snapshot to the restore region)",
      "AWS EC2 (creating an AMI in the restore region)",
      "Azure VM (creating a DR resource group, creating disks from Recovery Point and deploying the template)",
    ],
  },
  // {
  //   title: "Azure SAP HANA support",
  //   date: "20 October, 2021",
  //   entries: [
  //     "From this day, AutoBackup supports backup of Azure SAP HANA. Next releases of this feature will allow to setup FULL or DIFFERENTIAL snapshots (currently we support differential).",
  //   ],
  //   details: [
  //     "From this day, AutoBackup supports backup of Azure SAP HANA. Next releases of this feature will allow to setup FULL or DIFFERENTIAL snapshots (currently we support differential).",
  //   ],
  // },
  {
    title: "Start your DR journey in AutoBackup!",
    date: "22 September, 2021",
    entries: [
      "The first release allows you to perform manual DR testing for EC2 and RDS instances. Go to ‘Disaster Recovery’ tab to see how it works",
    ],
    details: [
      "The first release allows you to perform manual DR testing by providing your CF template (or using our example templates that you can download from AutoBackup UI).",
      "You start your DR journey by defining a Plan - it contains parameters populated from the CF template and allows you to specify the restore region.",
      "Thanks to the Plan, you can schedule test frequency and when AutoBackup should remind you about the test. You can also execute the plan ad hoc - so in case of an emergency or any time you need it!",
      "Where the time comes you click ‘Start DR’ and and choose the Recovery Point - the date from which the snapshot should come from.",
      "AutoBackup generates links that will redirect you to CF console, where you can check manually if the resource can be restored.",
      "After you finish the test, you can record it as ‘Success’ or ‘Failed’ in AutoBackup. We keep the history of DR tests, that can be downloaded as a report and used eg. for audits.",
      "To restore EC2, AutoBackup requires additional permissions - ec2:RegisterImage, ec2:DeregisterImage and ec2:CopyImage.",
    ],
  },
  {
    title: "Define custom retention schemes",
    date: "17 March, 2021",
    entries: [
      "You can now define different snapshot frequencies and retention schemes for them, in the scope of a single policy. " +
        "Therefore you will be able to have different retention for yearly, monthly, weekly and daily backups.",
    ],
    details: [
      "You can now define different snapshot frequencies and retention schemes for them, in the scope of a single policy. " +
        "Therefore you will be able to have different retention for yearly, monthly, weekly and daily backups.",
    ],
  },
  {
    title:
      "Backup GCP VMs and use external API for even faster backup configuration",
    date: "22 September, 2020",
    entries: [
      "You can now onboard GCP Protected and Vault account.",
      "Backup your GCP VMs and replicate snapshots to Vault account.",
      "Use external API for onboarding accounts, configuring backup setups or policies and enabling backup on resources!",
    ],
    details: [
      "You can now onboard GCP Protected and Vault account.",
      "Backup your GCP VMs and replicate snapshots to Vault account.",
      "Use external API for onboarding accounts, configuring backup setups or policies and enabling backup on resources!",
    ],
  },
  {
    title: "Check new reporting features and usability improvements!",
    date: "3 August, 2020",
    entries: [
      "You can now download CSV reports available on the dashboard. They contain information about performed backups and their result.",
      "Improved events view - clearer filtering and details about errors.",
      "Browse policies and setups - you can now see details about existing policies and setups by checking the details view.",
    ],
    details: [
      "You can now download CSV reports available on the dashboard. They contain information about performed backups and their result.",
      "Improved events view - clearer filtering and details about errors.",
      "Browse policies and setups - you can now see details about existing policies and setups by checking the details view.",
    ],
  },
];
