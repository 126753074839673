/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { useContext, useEffect, useState } from "react";
import Segment from "../../../commonComponents/segment/Segment";
import Button from "../../../commonComponents/button/Button";
import { ROUTES } from "../../../routes/routes";
import ValidationMessage from "../../../commonComponents/validationMessage/ValidationMessage";
import DatePicker from "react-datepicker";
import moment from "moment";
import { History } from "history";
import SimpleReactValidator from "simple-react-validator";
import { CustomerContext } from "../../../context/customer";
import { toast } from "react-toastify";
import {
  DisasterRecoveryPlanTooltips,
  DisasterRecoveryTestingTooltips,
  toastMessages,
} from "../../../utils/constants";
import {
  CloudProvider,
  useGetDisasterRecoveryPlanQuery,
  useGetDisasterRecoverySnapshotsLazyQuery,
  useGetSupportedRegionsQuery,
  useStartDisasterRecoveryMutation,
} from "../../../generated/graphql";
import { useLocation, useParams } from "react-router-dom";
import { useAuth0 } from "../../../commonComponents/auth";
import ReactTooltip from "react-tooltip";
import { Loading } from "../../../commonComponents/loading/Loading";
import Select from "react-select";
import { Error } from "../../../commonComponents/error/error";
import Table from "../../../commonComponents/table/Table";
import Moment from "react-moment";
import { truncate } from "../../../utils/helpers";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Input from "../../../commonComponents/input/Input";

interface Props {
  history: History;
}

type RouteParams = {
  id: string;
};

const PlansTesting: React.FC<Props> = ({ history }) => {
  const { id } = useParams<RouteParams>();
  const location = useLocation();
  const { customer } = useContext(CustomerContext);
  const { user } = useAuth0();
  const customerId = customer && customer.id ? customer.id : "";
  const planId = id || location.pathname.split("/")[3];
  const [validationError, setValidationError] = useState(false);
  let graphqlValidationError;
  const [graphqlValidationErrors, setGraphqlValidationErrors] =
    useState<any>(null);
  const validator = new SimpleReactValidator();
  const [recoveryPointTimestamp, setRecoveryPointTimestamp] =
    useState<number>();
  const [restoreRegion, setRestoreRegion] = useState<string>("");
  const [kmsKey, setKmsKey] = useState<string>("");

  const {
    data: plan,
    loading: planLoading,
    error: planError,
  } = useGetDisasterRecoveryPlanQuery({
    variables: {
      searchCriteria: {
        customerId: customerId,
        planId: planId,
      },
    },
  });

  const [startDisasterRecovery] = useStartDisasterRecoveryMutation({
    variables: {
      input: {
        customerId: customerId,
        planId: planId,
        recoveryPointTimestamp: recoveryPointTimestamp || 0,
        assignee: user?.email || "",
        restoreRegion: restoreRegion,
        kmsKey: kmsKey ? kmsKey : null,
      },
    },
  });

  const {
    data: regions,
    loading: regionsLoading,
    error: regionsError,
  } = useGetSupportedRegionsQuery({
    variables: {
      cloudProvider:
        plan?.disasterRecoveryPlan?.cloudProvider || CloudProvider.Aws,
    },
  });

  const [
    getSnapshots,
    { data: snapshotsData, loading: snapshotsLoading, error: snapshotsError },
  ] = useGetDisasterRecoverySnapshotsLazyQuery({
    variables: {
      searchCriteria: {
        customerId: customerId,
        planId: planId,
        recoveryPointTimestamp: recoveryPointTimestamp || 0,
      },
    },
  });

  useEffect(() => {
    setRestoreRegion(plan?.disasterRecoveryPlan?.restoreRegion || "");
    setKmsKey(plan?.disasterRecoveryPlan?.kmsKey || "");
  }, [plan]);

  const onSubmit = () => {
    validationError && setValidationError(false);
    if (validator.allValid()) {
      startDisasterRecovery()
        .then(() => {
          validator.hideMessages();
          history.push(ROUTES.disasterRecovery.records.index.generate());
        })
        .catch((e: any): any => {
          toast.error(toastMessages.DISASTER_RECOVERY_TESTING_ERROR);
          setGraphqlValidationErrors(e.graphQLErrors);
        });
    } else {
      setValidationError(true);
      validator.showMessages();
      toast.error(toastMessages.VALIDATION_FAILED);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Resource Id",
        accessor: "resourceId",
        Cell: ({ row }: any) => (
          <div>
            {truncate(row.original.resourceId)}
            <CopyToClipboard
              text={row.original.resourceId}
              onCopy={() => {
                toast.success(toastMessages.RESOURCE_ID_COPIED);
              }}
            >
              <Button outline size="extra-small" margin="0 .25rem">
                <i className="fa fa-copy" /> copy
              </Button>
            </CopyToClipboard>
          </div>
        ),
      },
      {
        Header: "Snapshot Id",
        accessor: "id",
      },
      {
        Header: "Creation date",
        Cell: ({ row }: any) => (
          <Moment unix format="DD-MM-YYYY HH:mm">
            {row.original.creationTimestamp}
          </Moment>
        ),
      },
    ],
    []
  );

  const showSnapshots = () => {
    if (snapshotsError) {
      return <Error error={snapshotsError} />;
    }
    return snapshotsData &&
      snapshotsData.disasterRecoverySnapshots &&
      snapshotsData.disasterRecoverySnapshots?.length > 0 ? (
      <Table
        columns={columns}
        data={snapshotsData.disasterRecoverySnapshots}
        disableSortBy
      />
    ) : (
      <p>
        Select <b>Recovery point date</b> to list snapshots that will be used
        for recovery.
      </p>
    );
  };

  return (
    <div className="box">
      {regionsError && <Error error={regionsError} />}
      {planError && <Error error={planError} />}
      <div className="columns is-vcentered border-bottom">
        <div className="column is-7">
          <h1 className="title is-marginless is-5 is-capitalized">
            Start Disaster recovery
          </h1>
        </div>
        <div className="column is-5">
          <Segment alignRight>
            <Button
              className="margin-right"
              outline
              onClick={() => {
                validator.hideMessages();
                history.push(
                  ROUTES.disasterRecovery.templates.index.generate()
                );
              }}
            >
              Cancel
            </Button>
            <Button className="margin-right" onClick={() => onSubmit()}>
              Start
            </Button>
          </Segment>
        </div>
      </div>
      <Loading loading={planLoading}>
        <div className="columns">
          <div className="column is-4-desktop">
            <div className="field">
              <label className="label">
                Recovery point date*
                <span
                  className="icon"
                  data-tip={DisasterRecoveryTestingTooltips.recoveryPoint}
                >
                  <i className="fa fa-question-circle" />
                </span>
              </label>
              <DatePicker
                className="input border"
                dateFormat={"dd-MM-yyyy"}
                selected={
                  recoveryPointTimestamp !== undefined
                    ? new Date(recoveryPointTimestamp * 1000)
                    : null
                }
                todayButton="Today"
                maxDate={moment().toDate()}
                onChange={(e: any) => {
                  if (e !== null) {
                    setRecoveryPointTimestamp(
                      moment(e).add(moment().utcOffset(), "minutes").unix()
                    );
                    getSnapshots();
                  }
                }}
                placeholderText="Select Date"
              />
              <ValidationMessage>
                {validator.message(
                  "recoveryPointDate",
                  recoveryPointTimestamp,
                  "required|numeric|min:1,num"
                )}
              </ValidationMessage>
              {graphqlValidationErrors &&
                (graphqlValidationError = graphqlValidationErrors?.find(
                  (e: any) => e.errorInfo === "recoveryPointTimestamp"
                )) && (
                  <p className="validation-error">
                    {graphqlValidationError.message}
                  </p>
                )}
            </div>

            <div className="field">
              <label className="label">
                Restore region*
                <span
                  className="icon"
                  data-tip={DisasterRecoveryPlanTooltips.restoreRegion}
                >
                  <i className="fa fa-question-circle" />
                </span>
              </label>
              <Loading loading={regionsLoading}>
                <Select
                  value={{ value: restoreRegion, label: restoreRegion }}
                  onChange={(e: any) => setRestoreRegion(e.value)}
                  options={regions?.supportedRegions.map((region) => ({
                    value: region,
                    label: region,
                  }))}
                />
              </Loading>
              <ValidationMessage>
                {validator.message("restoreRegion", restoreRegion, "required")}
              </ValidationMessage>
              {graphqlValidationErrors &&
                (graphqlValidationError = graphqlValidationErrors?.find(
                  (e: any) => e.errorInfo === "restoreRegion"
                )) && (
                  <p className="validation-error">
                    {graphqlValidationError.message}
                  </p>
                )}
            </div>

            {plan?.disasterRecoveryPlan?.cloudProvider ===
              CloudProvider.Aws && (
              <div className="field">
                <label className="label">
                  KMS key
                  <span
                    className="icon"
                    data-tip={DisasterRecoveryPlanTooltips.kmsKey}
                  >
                    <i className="fa fa-question-circle" />
                  </span>
                </label>
                <Input
                  value={kmsKey}
                  onChange={(e) => setKmsKey(e.target.value)}
                  type="text"
                />
              </div>
            )}

            <ReactTooltip
              effect="solid"
              className="setup-tooltip"
              multiline={true}
            />
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <small>* - required</small>
          </div>
        </div>
      </Loading>

      <div className="columns is-vcentered border-bottom">
        <div className="column">
          <h1 className="title is-marginless is-5 is-capitalized">Snapshots</h1>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <Loading loading={snapshotsLoading}>{showSnapshots()}</Loading>
        </div>
      </div>
    </div>
  );
};

export default PlansTesting;
