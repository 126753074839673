/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from "react";
import Select from "react-select";

interface Props {
  type: string;
  numberOfResources: number;
  setups: Array<any>;
  onChange: (setup: any) => void;
  defaultValue: string;
}

const SetupSelect: React.FC<Props> = ({
  type,
  numberOfResources,
  setups,
  onChange,
  defaultValue,
}) => {
  return (
    <div className="field">
      <label className="label">
        {type}({numberOfResources})
      </label>
      {setups.length ? (
        <Select
          maxMenuHeight={90}
          isClearable={false}
          defaultInputValue={defaultValue}
          onChange={(setup: any) => onChange(setup)}
          options={setups.map((setup) => ({
            value: setup.id,
            label: setup.name,
          }))}
        />
      ) : (
        <p>No setups available for this resource type.</p>
      )}
    </div>
  );
};

export default SetupSelect;
