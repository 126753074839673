/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export const arrayCompare = (_arr1: Array<any>, _arr2: Array<any>) => {
  if (
    !Array.isArray(_arr1) ||
    !Array.isArray(_arr2) ||
    _arr1.length !== _arr2.length
  ) {
    return false;
  }

  const arr1 = _arr1.concat().sort();
  const arr2 = _arr2.concat().sort();

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  return true;
};
