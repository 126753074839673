/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import moment from "moment";
import {
  CloudProvider,
  Maybe,
  PolicySettings,
  PolicyType,
  RetentionCondition,
  TimeAwarePolicySettings,
} from "../generated/graphql";

export const truncate = (id: string, length: number = 40) => {
  const splitId = id.split("/");

  if (splitId[1] === CloudProvider.Azure) {
    return `(...)/${splitId[4]}/${splitId[5]}/(...)/${splitId[8]}/${splitId[9]}`;
  }

  if (
    splitId[1] === CloudProvider.Aws ||
    splitId[1] === CloudProvider.AwsChina
  ) {
    const truncated =
      splitId[3] || splitId[splitId.length - 1].split(":").slice(-1)[0];

    return truncated ? `...${truncated.slice(-length)}` : id;
  }

  return `${id.length > length ? `...${id.slice(-length)}` : id}`;
};

export const countPercentage = (inTotal: number, partToCount: number) => {
  if (partToCount !== 0) {
    return parseFloat(((partToCount / inTotal) * 100).toFixed());
  }
  return 0;
};

export const convertTimestampToDate = (timestamp: number) =>
  moment.unix(timestamp).format("DD-MM-YYYY");

export const formatType = (type: PolicyType) => {
  switch (type) {
    case PolicyType.Rpo:
      return "RPO";
    case PolicyType.TimeAware:
      return "Time aware";
  }
};

export const conditionalRemovePrimary = (
  type?: PolicyType,
  policySettings?: Maybe<Array<PolicySettings>>,
  timeAwarePolicySettings?: Maybe<TimeAwarePolicySettings>
) => {
  if (type === PolicyType.Rpo) {
    return policySettings?.every(
      (setting) =>
        setting.retention.primarySnapshotsRetention.condition ===
        RetentionCondition.TimeInDays
    );
  }
  return (
    timeAwarePolicySettings?.retention?.primarySnapshotsRetention.condition ===
    RetentionCondition.TimeInDays
  );
};

export const conditionalRemoveReplica = (
  type?: PolicyType,
  policySettings?: Maybe<Array<PolicySettings>>,
  timeAwarePolicySettings?: Maybe<TimeAwarePolicySettings>
) => {
  if (type === PolicyType.Rpo) {
    return policySettings?.every(
      (setting) =>
        setting.retention.replicaSnapshotsRetention.condition ===
        RetentionCondition.TimeInDays
    );
  }

  return (
    timeAwarePolicySettings?.retention?.replicaSnapshotsRetention.condition ===
    RetentionCondition.TimeInDays
  );
};
