/**
 * Copyright 2021-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  ApiKey,
  CustomerAccess,
  useGetApiKeyQuery,
  useGetCustomersListQuery,
  useGrantCustomerRoleToApiKeyMutation,
  useRemoveApiKeyMutation,
  useRevokeCustomerRoleFromApiKeyMutation,
} from "../../../../generated/graphql";
import queryString from "query-string";
import CustomersTable from "../../../../shared/customers/CustomersTable";
import Button from "../../../../commonComponents/button/Button";
import CustomersGrantRoleModal from "../../../../shared/customers/CustomersGrantRoleModal";
import CustomersUpdateRoleModal from "../../../../shared/customers/CustomersUpdateRoleModal";
import Popup from "react-popup";
import { unstable_batchedUpdates } from "react-dom";
import ApiKeyDetailsWrapper from "./ApiKeyDetailsWrapper";
import ApiKeyBasicInformation from "../../../../shared/apiKeys/ApiKeyBasicInformation";
import { ROUTES } from "../../../../routes/routes";

const ApiKeyDetails: React.FC = () => {
  const [apiKeyData, setApiKeyData] = useState<ApiKey>();
  const [grantModalIsOpen, setGrantModalIsOpen] = useState<boolean>(false);
  const [updateModalIsOpen, setUpdateModalIsOpen] = useState<boolean>(false);
  const [customerData, setCustomerData] = useState<CustomerAccess>();

  const history = useHistory();
  const location = useLocation();
  // Filter
  const filtersFromUrl = () => {
    if (location.search === "") return "";

    const params = queryString.parse(location.search);
    return JSON.parse(params.filters as string);
  };
  const [filters, setFilters] = useState<string>(filtersFromUrl);

  // Page
  const pageFromUrl = (): number => {
    if (location.search === "") return 1;

    const params = queryString.parse(location.search);
    const pageUrl = params.page;
    if (pageUrl) {
      return +pageUrl;
    }

    return 1;
  };

  const [currentPage, setCurrentPage] = useState<number>(pageFromUrl);

  const { id: apiKeyId = "" } = useParams();

  useEffect(() => {
    unstable_batchedUpdates(() => {
      setFilters(filtersFromUrl());
      setCurrentPage(pageFromUrl());
    });
  }, [location]);

  const { data, loading, refetch, error } = useGetApiKeyQuery({
    variables: {
      id: apiKeyId,
      customerSearchCriteria: {
        pageNumber: currentPage,
        ...(filters && { customerId: filters }),
      },
    },
  });

  const [removeApiKey] = useRemoveApiKeyMutation({
    variables: {
      id: apiKeyId,
    },
  });

  const { apiKey } = data || {};

  useEffect(() => {
    setApiKeyData(apiKey);
  }, [setApiKeyData, apiKey]);

  const { data: customersList, loading: customersLoading } =
    useGetCustomersListQuery();

  return (
    <ApiKeyDetailsWrapper
      loading={loading}
      error={error}
      apiKey={apiKeyData}
      redirect={ROUTES.apiKeys.index.generate()}
    >
      {apiKey && (
        <>
          <ApiKeyBasicInformation
            apiKeyData={apiKey}
            id={apiKeyId}
            redirect={ROUTES.apiKeys.index.generate()}
            removeApiKey={removeApiKey}
          />
          <div className="box is-clearfix">
            <CustomersTable
              history={history}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              setIsModalOpen={setUpdateModalIsOpen}
              isModalOpen={updateModalIsOpen}
              setFilters={setFilters}
              filters={filters}
              loading={loading}
              setCustomerData={setCustomerData}
              customersData={apiKey.customerAccesses}
              customersList={customersList}
              customersLoading={customersLoading}
              property="customersList"
            />
            <Button onClick={() => setGrantModalIsOpen(true)}>
              Grant customers accesses
            </Button>
          </div>
          <Popup />
          {grantModalIsOpen && (
            <CustomersGrantRoleModal
              setIsOpen={setGrantModalIsOpen}
              customersData={apiKey.customerAccesses.data}
              identifier={apiKeyId}
              mutation={useGrantCustomerRoleToApiKeyMutation}
              type="id"
              customersList={customersList}
              customersLoading={customersLoading}
              property="customersList"
              refetch={refetch}
            />
          )}
          {updateModalIsOpen && customerData && (
            <CustomersUpdateRoleModal
              setIsOpen={setUpdateModalIsOpen}
              customerData={customerData}
              identifier={apiKeyId}
              type="id"
              mutationGrant={useGrantCustomerRoleToApiKeyMutation}
              mutationRevoke={useRevokeCustomerRoleFromApiKeyMutation}
              refetch={refetch}
            />
          )}
        </>
      )}
    </ApiKeyDetailsWrapper>
  );
};

export default ApiKeyDetails;
