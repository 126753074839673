/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Status, Progress } from "../../utils/constants";

export const setStatusAndProgress = ({
  status,
  progress,
}: {
  status: Status;
  progress: Progress;
}) => {
  if (status === Status.ReplicationFailed) {
    return { className: "is-warning", label: "REPLICATION FAILED" };
  }

  if (status === Status.PartiallyFailed) {
    return { className: "is-warning", label: "PARTIALLY FAILED" };
  }

  if (status === Status.Waiting) {
    return progress === Progress.Created
      ? { className: "is-light", label: "WAITING" }
      : { className: "is-info", label: "IN PROGRESS" };
  }

  if (progress === Progress.Finished) {
    return status === Status.Success
      ? { className: "is-success", label: "FINISHED - SUCCESS" }
      : { className: "is-danger", label: "FINISHED - FAILED" };
  }

  return { className: "is-light", label: "NO DATA" };
};
