/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { useMemo, useEffect, useState } from "react";
import Pagination from "../../../commonComponents/pagination/Pagination";
import Placeholder from "../../../commonComponents/table/Placeholder";
import { Loading } from "../../../commonComponents/loading/Loading";
import Segment from "../../../commonComponents/segment/Segment";
import { Error } from "../../../commonComponents/error/error";
import Button from "../../../commonComponents/button/Button";
import Table from "../../../commonComponents/table/Table";
import { setStatusAndProgress } from "../eventsShared";
import { History } from "history";
import Moment from "react-moment";
import { ROUTES } from "../../../routes/routes";

import {
  BackupEventProgress,
  BackupEventsPage,
  BackupEvent,
  Maybe,
} from "../../../generated/graphql";

interface Props {
  eventsData?: BackupEventsPage;
  eventsError: any;
  eventsLoading: boolean;
  currentPage: number;
  setCurrentPage: any;
  progressFilter: Maybe<BackupEventProgress>;
  setProgressFilter: any;
  history: History;
}

const PAGE_SIZE = 50;

const EventsTable: React.FC<Props> = ({
  eventsData,
  eventsError,
  eventsLoading,
  currentPage,
  setCurrentPage,
  progressFilter,
  setProgressFilter,
  history,
}) => {
  const [events, setEvents] = useState<Maybe<Array<BackupEvent>>>();
  const [totalNumberOfEvents, setTotalNumberOfEvents] = useState<
    number | undefined
  >(0);
  const [totalNumberOfPages, setTotalNumberOfPages] = useState<
    number | undefined
  >(0);

  const nextPage = () => {
    if (totalNumberOfPages && currentPage < totalNumberOfPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const getEventsNumber = () => {
    if (events && events.length < PAGE_SIZE && currentPage === 1) {
      return events.length;
    }
    if (
      events &&
      events?.length < PAGE_SIZE &&
      currentPage === totalNumberOfPages
    ) {
      return PAGE_SIZE * (totalNumberOfPages - 1) + events.length;
    }
    return PAGE_SIZE * currentPage;
  };

  const onEventProgressClickHandle = (value: BackupEventProgress) => {
    if (progressFilter === value) {
      setProgressFilter(null);
    } else {
      setProgressFilter(value);
    }
  };

  useEffect(() => {
    // @ts-ignore
    setTotalNumberOfEvents(eventsData?.totalNumberOfEvents);
    setTotalNumberOfPages(eventsData?.totalNumberOfPages);
    setEvents(eventsData?.data);
  }, [eventsData]);

  const columns = useMemo(
    () => [
      {
        Header: "Start Time (local time)",
        accessor: "startTimestamp",
        Cell: ({ row }: any) => (
          <div style={{ fontWeight: "bold" }}>
            <Moment unix format="DD-MM-YYYY HH:mm">
              {row.original.startTimestamp}
            </Moment>
          </div>
        ),
      },
      {
        Header: "Plan name",
        accessor: "planName",
      },
      {
        Header: "Number of resources",
        accessor: "totalNumberOfResources",
      },
      {
        Header: "Status & Progress",
        accessor: "statusAndProgress",
        Cell: ({ row }: any) => {
          const { status, progress } = row.original;
          const { className, label } = setStatusAndProgress({
            status,
            progress,
          });
          return className !== "" ? (
            <span className={`tag ${className}`}>{label}</span>
          ) : (
            <span />
          );
        },
      },
      {
        id: "actions",
        Cell: ({ row }: any) => (
          <div>
            <div
              className="navbar-item is-hoverable is-pulled-right"
              onClick={(e) => {
                e.preventDefault();
                history.push(
                  ROUTES.events.event.generate({ id: row.original.id })
                );
              }}
            >
              <span className="icon">
                <i className="fa fa-info-circle" />
              </span>
            </div>
          </div>
        ),
      },
    ],
    [history]
  );

  return (
    <div className="box is-clearfix">
      <div className="columns is-vcentered border-bottom">
        <div className="column is-7">
          <h1 className="title is-marginless is-5 is-capitalized">
            Events list
          </h1>
        </div>
        <div className="column is-5">
          <Segment tabs>
            <Button
              select
              onClick={() =>
                onEventProgressClickHandle(BackupEventProgress.Created)
              }
              className={
                progressFilter === BackupEventProgress.Created ? "active" : ""
              }
            >
              Upcoming Events
            </Button>
            <Button
              select
              onClick={() =>
                onEventProgressClickHandle(BackupEventProgress.Finished)
              }
              className={
                progressFilter === BackupEventProgress.Finished ? "active" : ""
              }
            >
              Past Events
            </Button>
          </Segment>
        </div>
      </div>
      <Loading loading={eventsLoading}>
        {eventsError && <Error error={eventsError} />}
        {events && events.length > 0 ? (
          <Table columns={columns} data={events} disableSortBy />
        ) : (
          <Placeholder message="No data found with current filters." />
        )}
        <Pagination
          totalItems={totalNumberOfEvents || 0}
          totalPages={totalNumberOfPages || 0}
          currentPage={currentPage}
          showing={getEventsNumber()}
          changePage={setCurrentPage}
          previousPage={previousPage}
          nextPage={nextPage}
        />
      </Loading>
    </div>
  );
};

export default EventsTable;
