/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { useContext, useState } from "react";
import { CustomerContext } from "../../../context/customer";
import {
  useGetNotificationGroupsQuery,
  useRemoveNotificationGroupMutation,
  GetUserRoleQuery,
  UserRole,
  MessagesSendingFrequency,
  NotificationGroup,
} from "../../../generated/graphql";
import Table from "../../../commonComponents/table/Table";
import { Loading } from "../../../commonComponents/loading/Loading";
import { confirmations } from "../../../utils/constants";
import { Error } from "../../../commonComponents/error/error";
import Placeholder from "../../../commonComponents/table/Placeholder";
import Popup from "react-popup";
import { ROUTES } from "../../../routes/routes";
import { History } from "history";
import { Cell } from "react-table";

interface Props {
  userRole: GetUserRoleQuery | any;
  userRoleLoading: boolean;
  history: History;
}

const NotificationGroups: React.FC<Props> = ({
  userRole,
  userRoleLoading,
  history,
}) => {
  const { customer } = useContext(CustomerContext);
  const customerId = customer && customer.id ? customer.id : "";
  const {
    data,
    loading,
    refetch: reload,
  } = useGetNotificationGroupsQuery({
    variables: { customerId },
  });
  const [notificationGroupId, setNotificationGroupId] = useState("");

  const [removeNotificationGroup, { error: removeNotificationGroupError }] =
    useRemoveNotificationGroupMutation({
      variables: {
        input: {
          customerId: customerId,
          id: notificationGroupId,
        },
      },
    });

  if (notificationGroupId) {
    removeNotificationGroup()
      .then(() => reload())
      .catch((e) => console.log(e));
    setNotificationGroupId("");
  }

  const notificationGroupsData = data ? data.notificationGroups : [];

  const formatFrequency = (frequency: MessagesSendingFrequency) => {
    switch (frequency) {
      case MessagesSendingFrequency.OncePerDay:
        return `Once per Day`;
      case MessagesSendingFrequency.OncePerEvent:
        return `Once per Event`;
      default:
        return ``;
    }
  };

  const onDeleteHandler = (id: string) => {
    Popup.create({
      onClose: () => {
        Popup.close();
      },
      title: `Delete Notification Group`,
      content: (
        <div className="box">
          <p>{confirmations.DELETE_NOTIFICATION_GROUP}</p>
        </div>
      ),
      buttons: {
        right: [
          {
            text: "Cancel",
            className: "btn",
            action: () => {
              Popup.close();
            },
          },
          {
            text: "Delete",
            className: "btn",
            action: async () => {
              setNotificationGroupId(id);
              Popup.close();
            },
          },
        ],
      },
    });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Severities",
        Cell: ({ row }: Cell<NotificationGroup>) =>
          row.original.severities.join(", "),
      },
      {
        Header: "Frequency",
        Cell: ({ row }: Cell<NotificationGroup>) => (
          <div>{formatFrequency(row.original.frequency)}</div>
        ),
      },
      {
        Header: "Channels",
        Cell: ({ row }: Cell<NotificationGroup>) =>
          row.original.channels &&
          row.original.channels
            .map((c: any): any => c.__typename.replace(/Config/g, ""))
            .join(", "),
      },
      {
        Header: "Excluded accounts",
        Cell: ({ row }: Cell<NotificationGroup>) => (
          <>
            {row.original.excludedAccounts?.length || 0 >= 1 ? (
              <div>
                {row.original.excludedAccounts?.map((account) => {
                  return (
                    <div>
                      {account.name} ({account.cloudProviderId})
                    </div>
                  );
                })}
              </div>
            ) : (
              <span>No excluded accounts</span>
            )}
          </>
        ),
      },
      {
        Header: " ",
        Cell: ({ row }: Cell<NotificationGroup>) => (
          <div>
            <div className="navbar-item has-dropdown is-hoverable is-pulled-right">
              <span className="icon">
                <i className="fa fa-ellipsis-v" />
              </span>
              <div className="navbar-dropdown is-right">
                {!userRoleLoading && userRole?.userRole !== UserRole.ReadOnly && (
                  <a
                    className="navbar-item"
                    onClick={() => onDeleteHandler(row.original.id)}
                  >
                    <span className="icon">
                      <i className="fa fa-trash" />
                    </span>
                    <span>Delete</span>
                  </a>
                )}
                <a
                  className="navbar-item"
                  onClick={() =>
                    history.push(
                      ROUTES.settings.notificationGroups.details.generate({
                        id: row.original.id,
                      })
                    )
                  }
                >
                  <span className="icon">
                    <i className="fa fa-eye" />
                  </span>
                  <span>View</span>
                </a>
              </div>
            </div>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <>
      <Loading loading={loading}>
        {removeNotificationGroupError && (
          <Error error={removeNotificationGroupError} />
        )}
        {notificationGroupsData.length > 0 ? (
          <Table
            columns={columns}
            data={notificationGroupsData}
            disableSortBy
          />
        ) : (
          <Placeholder message="No data found." />
        )}
      </Loading>
    </>
  );
};

export default NotificationGroups;
