/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Link, useLocation, useParams } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { CustomerContext } from "../../../context/customer";
import {
  CloudProvider,
  Template,
  useGetTemplateQuery,
  useGetUserRoleQuery,
  UserRole,
  useUpdateTemplateMutation,
  useValidateTemplateMutation,
} from "../../../generated/graphql";
import { Loading } from "../../../commonComponents/loading/Loading";
import { Error } from "../../../commonComponents/error/error";
import { ROUTES } from "../../../routes/routes";
import Button from "../../../commonComponents/button/Button";
import Input from "../../../commonComponents/input/Input";
import Textarea from "../../../commonComponents/textarea/Textarea";
import Maybe from "graphql/tsutils/Maybe";
import { toast } from "react-toastify";
import { TemplateTooltips, toastMessages } from "../../../utils/constants";
import ValidationMessage from "../../../commonComponents/validationMessage/ValidationMessage";
import SimpleReactValidator from "simple-react-validator";
import ReactTooltip from "react-tooltip";
import { Icon } from "../../../commonComponents/icon/Icon";

type RouteParams = {
  id: string;
};

const validator = new SimpleReactValidator();

const TemplateDetails: React.FC = () => {
  const { id } = useParams<RouteParams>();

  const [template, setTemplate] = useState<Maybe<Template>>(null);
  const [name, setName] = useState<string>("");
  const [templateContent, setTemplateContent] = useState<string>("");
  const [parameters, setParameters] = useState<string[]>([]);

  const [uploadedFileName, setUploadedFileName] = useState<string>("");
  const [templateChanged, setTemplateChanged] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const [validationError, setValidationError] = useState(false);
  let graphqlValidationError;
  const [graphqlValidationErrors, setGraphqlValidationErrors] =
    useState<any>(null);
  const location = useLocation();

  const { customer } = useContext(CustomerContext);
  const customerId = customer && customer.id ? customer.id : "";
  const templateId = id || location.pathname.split("/")[3];

  const { data, error, loading } = useGetTemplateQuery({
    variables: {
      searchCriteria: {
        customerId: customerId,
        templateId: templateId,
      },
    },
  });

  const { data: userRoleData, loading: userRoleLoading } = useGetUserRoleQuery({
    variables: {
      customerId: customerId,
    },
  });

  const { userRole } = userRoleData || {};

  useEffect(() => {
    setTemplate(data?.template);
    setName(data?.template?.name || "");
    setTemplateContent(data?.template?.content || "");
    setParameters(data?.template?.parameters || []);
  }, [data]);

  const [updateTemplate] = useUpdateTemplateMutation({
    variables: {
      input: {
        customerId: customerId,
        id: id,
        name: name,
        content: templateContent,
      },
    },
  });

  const [validateTemplate] = useValidateTemplateMutation();

  let fileReader: FileReader,
    inputFile: any = null;

  const uploadClick = () => {
    inputFile.click();
    return false;
  };

  const handleFileRead = () => {
    setTemplateContent(fileReader.result!.toString());

    validateTemplate({
      variables: {
        input: {
          cloudProvider: template?.cloudProvider || CloudProvider.Aws,
          content: fileReader.result!.toString(),
        },
      },
    })
      .then((validateData) => {
        setParameters(validateData?.data?.validateTemplate.parameters || []);
        setTemplateChanged(true);
        setGraphqlValidationErrors([]);
      })
      .catch((e) => {
        toast.error(toastMessages.TEMPLATE_VALIDATION_ERROR);
        setGraphqlValidationErrors(e.graphQLErrors);
      });
  };

  const handleUploadedFile = (file: any) => {
    fileReader = new FileReader();
    fileReader.onloadend = handleFileRead;
    fileReader.readAsText(file);
    setUploadedFileName(file.name);
  };

  return (
    <div className="main wide">
      <Loading loading={loading}>
        {error && <Error error={error} />}
        {template && (
          <div className="box">
            <div className="columns is-vcentered border-bottom">
              <div className="column is-7">
                <h1 className="title is-marginless is-5 is-capitalized">
                  Template Details
                </h1>
              </div>
              <div className="column is-5">
                <div className="is-pulled-right">
                  <Loading loading={isLoading}>
                    <Link
                      to={ROUTES.disasterRecovery.templates.index.generate()}
                    >
                      <Button className="is-pulled-right">
                        Back to Templates
                      </Button>
                    </Link>
                    {!userRoleLoading && userRole !== UserRole.ReadOnly && (
                      <Button
                        className="is-pulled-right has-margin-right"
                        onClick={() => {
                          setIsLoading(true);
                          validationError && setValidationError(false);
                          if (validator.allValid()) {
                            updateTemplate()
                              .then(() => {
                                toast.success(toastMessages.TEMPLATE_UPDATED);
                                validator.hideMessages();
                                setGraphqlValidationErrors(null);
                                setIsLoading(false);
                                setTemplateChanged(false);
                              })
                              .catch((e: any): any => {
                                toast.error(
                                  toastMessages.TEMPLATE_UPDATE_ERROR
                                );
                                setGraphqlValidationErrors(e.graphQLErrors);
                                setIsLoading(false);
                              });
                          } else {
                            setValidationError(true);
                            validator.showMessages();
                            toast.error(toastMessages.VALIDATION_FAILED);
                            setIsLoading(false);
                          }
                        }}
                        disabled={!templateChanged}
                      >
                        Save
                      </Button>
                    )}
                  </Loading>
                </div>
              </div>
            </div>
            <>
              <div className="columns">
                <div className="column is-4-desktop">
                  <div className="field">
                    <label className="label">Template ID</label>
                    <Input value={template.id} type="text" disabled />
                  </div>

                  <div className="field">
                    <label className="label">
                      Template name*
                      <span className="icon" data-tip={TemplateTooltips.name}>
                        <i className="fa fa-question-circle" />
                      </span>
                    </label>
                    <Input
                      defaultValue={template.name}
                      onChange={(e) => {
                        setName(e.target.value);
                        setTemplateChanged(true);
                      }}
                      type="text"
                      disabled={userRole === UserRole.ReadOnly}
                    />
                    <ValidationMessage>
                      {validator.message("name", name, "required|max:64")}
                    </ValidationMessage>
                    {graphqlValidationErrors &&
                      (graphqlValidationError = graphqlValidationErrors?.find(
                        (e: any) => e.errorInfo === "name"
                      )) && (
                        <p className="validation-error">
                          {graphqlValidationError.message}
                        </p>
                      )}
                  </div>

                  <div className="field">
                    <label className="label">
                      Template cloud provider*
                      <span
                        className="icon"
                        data-tip={TemplateTooltips.cloudProvider}
                      >
                        <i className="fa fa-question-circle" />
                      </span>
                    </label>
                    <Icon
                      height="2.5rem"
                      image={template.cloudProvider}
                      width="2.5rem"
                    />
                  </div>

                  <div className="field">
                    <label className="label">
                      Template content*
                      <span
                        className="icon"
                        data-tip={TemplateTooltips.content}
                      >
                        <i className="fa fa-question-circle" />
                      </span>
                    </label>
                    <Textarea rows={20} value={templateContent} disabled />
                    {userRole !== UserRole.ReadOnly && (
                      <>
                        <Button
                          className="has-margin-right"
                          onClick={() => uploadClick()}
                        >
                          Choose file
                        </Button>
                        {uploadedFileName && <span>{uploadedFileName}</span>}
                        <input
                          type="file"
                          id="file"
                          ref={(input) => {
                            inputFile = input;
                          }}
                          className="input-file"
                          accept=".json, .yml, .yaml"
                          onChange={(e) =>
                            handleUploadedFile(e.target.files?.[0])
                          }
                        />
                      </>
                    )}
                    {graphqlValidationErrors &&
                      (graphqlValidationError = graphqlValidationErrors?.find(
                        (e: any) => e.errorInfo === "content"
                      )) && (
                        <p className="validation-error">
                          {graphqlValidationError.message}
                        </p>
                      )}
                  </div>

                  {parameters.length !== 0 && (
                    <div className="field">
                      <label className="label">Template parameters</label>
                      {parameters.map((parameter: string) => (
                        <div key={parameter} className="field">
                          <Input value={parameter} type="text" disabled />
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <hr />
              <div className="columns">
                <div className="column is-5-desktop">
                  <div className="columns">
                    <div className="column">
                      <small>* - required</small>
                    </div>
                  </div>
                </div>
                <ReactTooltip
                  effect="solid"
                  className="setup-tooltip"
                  multiline={true}
                />
              </div>
            </>
          </div>
        )}
      </Loading>
    </div>
  );
};

export default TemplateDetails;
