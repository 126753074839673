/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { useState, useEffect, useCallback } from "react";
import Widget from "../../../commonComponents/widget/Widget";
import { lastChange } from "../Notes";
import { escapeKeyCode } from "../../../utils/constants";

const ReleaseNotes: React.FC = ({ children }) => {
  const [isSidebarNew, setIsSidebarNew] = useState<boolean>(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const [isStickerMinimized, setIsStickerMinimized] = useState<boolean>(true);
  const releaseNotesVisitsKey = "relaseNotes";

  const lastVisited = localStorage.getItem(releaseNotesVisitsKey);

  const closeOnEscapeKey = useCallback((event) => {
    if (event.keyCode === escapeKeyCode) setIsSidebarOpen(false);
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", closeOnEscapeKey, false);
    return () => {
      document.removeEventListener("keydown", closeOnEscapeKey, false);
    };
  }, []);

  useEffect(() => {
    if (!lastVisited || lastVisited < lastChange) setIsSidebarNew(true);
  }, []);

  const handleClick = () => {
    setIsSidebarNew(false);
    setIsSidebarOpen(!isSidebarOpen);
    setIsStickerMinimized(true);
  };

  return (
    <>
      <Widget
        isNew={isSidebarNew}
        isOpen={isSidebarOpen}
        isStickerMinimized={isStickerMinimized}
        onClose={() => setIsSidebarOpen(!isSidebarOpen)}
        onArrowClick={() => setIsStickerMinimized(!isStickerMinimized)}
        onFeedbackClick={() => {
          setIsStickerMinimized(!isStickerMinimized);
          // @ts-ignore
          window.Ybug.open();
        }}
        onNotesClick={() => {
          localStorage.setItem(releaseNotesVisitsKey, String(+new Date()));
          handleClick();
        }}
      >
        {children}
      </Widget>
    </>
  );
};

export default ReleaseNotes;
