/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { ReactNode } from "react";
import styled from "styled-components";

interface InlineFormProps {
  children: ReactNode;
}

const StyledInlineForm = styled.div<InlineFormProps>`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: stretch;
  margin-bottom: 0.5rem;

  label {
    height: calc(2rem + 2px);
  }

  select,
  label {
    min-width: 6rem;
    width: 5rem;
    padding: 0.5rem 1rem;
    background-color: #e1e1e5;
    appearance: none;
    border: 1px solid #bababa;
    border-radius: 0;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    color: #333333;
    font-weight: regular;
    font-size: 0.75rem;
    height: 2.4rem;

    &:focus {
      outline: 0;
    }
  }

  Input {
    width: 100%;
    border: 1px solid #bababa;
    border-radius: 0;
    background-color: #ffffff;
    padding: 1rem 1.5rem;
    font-size: 0.75rem;

    &:focus {
      outline: 0;
    }

    &:first-of-type {
      border-left: none;
    }
    &:not(:last-of-type) {
      border-right: none;
    }

    &:last-of-type {
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }
  }
`;

const InlineForm: React.FC<InlineFormProps> = (props) => {
  return <StyledInlineForm {...props}>{props.children}</StyledInlineForm>;
};

export default InlineForm;
