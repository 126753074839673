/**
 * Copyright 2021-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { useContext, useEffect, useState } from "react";
import {
  useGetTemplatesQuery,
  useRemoveTemplateMutation,
  UserRole,
} from "../../../generated/graphql";
import { Loading } from "../../../commonComponents/loading/Loading";
import Table from "../../../commonComponents/table/Table";
import { Error } from "../../../commonComponents/error/error";
import Placeholder from "../../../commonComponents/table/Placeholder";
import { History } from "history";
import { ROUTES } from "../../../routes/routes";
import { CustomerContext } from "../../../context/customer";
import Popup from "react-popup";
import { confirmations, toastMessages } from "../../../utils/constants";
import { toast } from "react-toastify";
import errorToast from "../../../utils/errorToast";
import Pagination from "../../../commonComponents/pagination/Pagination";
import { useLocation } from "react-router-dom";
import { Icon } from "../../../commonComponents/icon/Icon";

interface Props {
  userRole?: UserRole | null;
  userRoleLoading: boolean;
  currentPage: number;
  setCurrentPage: any;
  history: History;
}

const PAGE_SIZE = 50;

const TemplatesList: React.FC<Props> = ({
  userRole,
  userRoleLoading,
  currentPage,
  setCurrentPage,
  history,
}) => {
  const { customer } = useContext(CustomerContext);
  const [removeId, setRemoveId] = useState("");
  const location = useLocation();

  const [totalNumberOfTemplates, setTotalNumberOfTemplates] = useState<
    number | undefined
  >(0);
  const [totalNumberOfPages, setTotalNumberOfPages] = useState<
    number | undefined
  >(0);

  const nextPage = () => {
    if (totalNumberOfPages && currentPage < totalNumberOfPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const getTemplatesNumber = () => {
    if (
      data?.templates.data &&
      data?.templates.data.length < PAGE_SIZE &&
      currentPage === 1
    ) {
      return data?.templates.data.length;
    }
    if (
      data?.templates.data &&
      data?.templates.data.length < PAGE_SIZE &&
      currentPage === totalNumberOfPages
    ) {
      return PAGE_SIZE * (totalNumberOfPages - 1) + data?.templates.data.length;
    }
    return PAGE_SIZE * currentPage;
  };

  const customerId = customer && customer.id ? customer.id : "";
  const { data, loading, refetch, error } = useGetTemplatesQuery({
    variables: {
      searchCriteria: {
        customerId: customerId,
        pageNumber: currentPage,
      },
    },
  });

  const [removeTemplate, { loading: removeTemplateLoading }] =
    useRemoveTemplateMutation({
      variables: {
        input: {
          customerId: customerId,
          templateId: removeId,
        },
      },
    });

  useEffect(() => {
    const search = `?page=${currentPage}`;
    if (location.search === search) return;

    if (location.search === "") {
      history.replace({ search });
    } else {
      history.push({ search });
    }
  }, [currentPage, location.search, history]);

  useEffect(() => {
    setTotalNumberOfTemplates(data?.templates.totalNumberOfTemplates);
    setTotalNumberOfPages(data?.templates.totalNumberOfPages);
  }, [data]);

  if (removeId) {
    removeTemplate()
      .then(() => {
        refetch();
        toast.success(toastMessages.TEMPLATE_REMOVED);
      })
      .catch((e) => errorToast(e, toastMessages.TEMPLATE_REMOVAL_ERROR));
    setRemoveId("");
  }

  const onDeleteHandler = (id: string) => {
    Popup.create({
      onClose: () => {
        Popup.close();
      },
      title: `Delete Template`,
      content: (
        <div className="box">
          <p>{confirmations.DELETE_TEMPLATE}</p>
        </div>
      ),
      buttons: {
        right: [
          {
            text: "Cancel",
            className: "btn",
            action: () => {
              Popup.close();
            },
          },
          {
            text: "Delete",
            className: "btn",
            action: async () => {
              setRemoveId(id);
              Popup.close();
            },
          },
        ],
      },
    });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Provider",
        Cell: ({ row }: any) => (
          <div style={{ padding: ".5rem" }}>
            {row.original.cloudProvider && (
              <Icon
                height="1.75rem"
                image={row.original.cloudProvider}
                width="1.75rem"
              />
            )}
          </div>
        ),
      },
      {
        Header: " ",
        Cell: ({ row }: any) => (
          <div>
            <div className="navbar-item has-dropdown is-hoverable is-pulled-right">
              <span className="icon">
                <i className="fa fa-ellipsis-v" />
              </span>
              <div className="navbar-dropdown is-right">
                {userRole !== UserRole.ReadOnly && (
                  <a
                    className="navbar-item"
                    onClick={() => onDeleteHandler(row.original.id)}
                  >
                    <span className="icon">
                      <i className="fa fa-trash" />
                    </span>
                    <span>Delete</span>
                  </a>
                )}
                <a
                  className="navbar-item"
                  onClick={() =>
                    history.push(
                      ROUTES.disasterRecovery.templates.details.generate({
                        id: row.original.id,
                      })
                    )
                  }
                >
                  <span className="icon">
                    <i className="fa fa-eye" />
                  </span>
                  {userRole !== UserRole.ReadOnly ? (
                    <span>View / Edit</span>
                  ) : (
                    <span>View</span>
                  )}
                </a>
              </div>
            </div>
          </div>
        ),
      },
    ],
    [history, userRole]
  );

  return (
    <Loading loading={loading || userRoleLoading}>
      {error && <Error error={error} />}
      {data && data.templates.data.length > 0 ? (
        <>
          <Loading loading={removeTemplateLoading} />
          <Table columns={columns} data={data.templates.data} disableSortBy />
        </>
      ) : (
        <Placeholder message="Currently there are no templates. Create one now." />
      )}
      <Pagination
        totalItems={totalNumberOfTemplates || 0}
        totalPages={totalNumberOfPages || 0}
        currentPage={currentPage}
        showing={getTemplatesNumber()}
        changePage={setCurrentPage}
        previousPage={previousPage}
        nextPage={nextPage}
      />
    </Loading>
  );
};

export default TemplatesList;
