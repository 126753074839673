/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from "react";
import styled from "styled-components";

interface InputProps {
  id?: string;
  value?: string | any;
  defaultValue?: string | any;
  placeholder?: string;
  error?: string;
  hintText?: string;
  type?: string;
  onChange?: (event: any) => void;
  onBlur?: (event: any) => void;
  onClick?: (event: any) => void;
  disabled?: boolean;
  className?: string;
}

const StyledInput = styled.input<InputProps>`
  align-items: center;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 0.25rem;
  box-shadow: none;
  display: inline-flex;
  font-size: 1rem;
  height: 2.375rem;
  justify-content: flex-start;
  line-height: 1.5;
  padding: calc(0.375em - 1px) calc(0.625em - 1px);
  vertical-align: top;
  width: 100%;
  margin: 0 0 0.5rem 0;

  &:focus {
    outline: 0;
  }

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const Input: React.FC<InputProps> = (props) => {
  return <StyledInput {...props} />;
};

export default Input;
