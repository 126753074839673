/**
 * Copyright 2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ApolloQueryResult } from "apollo-boost";
import { ApolloError } from "apollo-client";
import Pluralize from "react-pluralize";
import Popup from "react-popup";
import { toast } from "react-toastify";
import React, { useContext, useEffect, useState } from "react";

import Segment from "../../../commonComponents/segment/Segment";
import Button from "../../../commonComponents/button/Button";
import { toastMessages } from "../../../utils/constants";
import { pluralizeText } from "../../../commonComponents/text/text";
import { CustomerContext } from "../../../context/customer";
import {
  Exact,
  FilterInput,
  GetResourcesQuery,
  ResourcesSearchCriteria,
  useChangeBackupPolicyAndSetupsMutation,
  useChangeBackupStateMutation,
  useGetUserProfileQuery,
  useMatchedResourcesTypesQuery,
  useNumberOfResourcesWithChangedBackupEnabledQuery,
  useTriggerBackupMutation,
} from "../../../generated/graphql";
import { PAGE_SIZE } from "./utils";
import ResourceSetupModal from "./ResourceSetupModal";

interface ResourcesActionButtonsProps {
  filters: Array<FilterInput>;
  selectedResourcesIds: Array<string>;
  totalNumberOfResources: number | undefined;
  getResources: (
    variables?: Exact<{ searchCriteria: ResourcesSearchCriteria }> | undefined
  ) => Promise<ApolloQueryResult<GetResourcesQuery>>;
  isSelectedAll: boolean;
  setIsSelectedAll: React.Dispatch<React.SetStateAction<boolean>>;
}

const ResourcesActionButtons: React.FC<ResourcesActionButtonsProps> = ({
  filters,
  selectedResourcesIds,
  totalNumberOfResources,
  getResources,
  isSelectedAll,
  setIsSelectedAll,
}) => {
  const { customer } = useContext(CustomerContext);
  const customerId = customer?.id || "";

  const [isTagBackupEnabled, setIsTagBackupEnabled] = useState(false);
  const [setupVisible, triggerSetupVisible] = useState(false);
  const [buttonDisabledCondition, setButtonDisabledCondition] = useState(true);

  useEffect(() => {
    setButtonDisabledCondition(
      selectedResourcesIds.length === 0 && !isSelectedAll
    );
  }, [selectedResourcesIds.length, isSelectedAll]);

  const { data: userProfileData } = useGetUserProfileQuery({
    variables: {
      customerId,
    },
  });
  useEffect(() => {
    setIsTagBackupEnabled(
      !!userProfileData?.userProfile?.isBackupControlledByTags
    );
  }, [userProfileData]);

  const { data: matchedTypes } = useMatchedResourcesTypesQuery({
    variables: {
      searchCriteria: {
        customerId,
        filters,
        onlySelectedIds: isSelectedAll ? null : selectedResourcesIds,
      },
    },
  });

  const { refetch: refetchResourcesNumber } =
    useNumberOfResourcesWithChangedBackupEnabledQuery({
      variables: {
        input: {
          customerId,
          filters,
          resourceIDs: isSelectedAll ? null : selectedResourcesIds,
          state: true,
        },
      },
    });

  const [changeBackupState] = useChangeBackupStateMutation();

  const [changeBackupPolicyAndSetupsMutation] =
    useChangeBackupPolicyAndSetupsMutation();

  const [triggerBackupMutation] = useTriggerBackupMutation();

  const clearSetupsAndPolicy = async () => {
    const message =
      selectedResourcesIds.length > 0
        ? `This operation will clear Policies and Setups for matched resources.`
        : "There are no resources selected with Policies or Setups applied.";

    Popup.create({
      title: "Clear Setups and Policies",
      content: <div className="box">{message}</div>,
      buttons: {
        right:
          selectedResourcesIds.length !== 0
            ? [
                {
                  text: "Cancel",
                  className: "btn",
                  action: () => {
                    Popup.close();
                  },
                },
                {
                  text: "Confirm",
                  className: "btn",
                  action: async () => {
                    const data = await changeBackupPolicyAndSetupsMutation({
                      variables: {
                        input: {
                          customerId,
                          filters,
                          resourceIDs: isSelectedAll
                            ? null
                            : selectedResourcesIds,
                          backupPolicyId: null,
                          setupsToResourcesTypesAssignments:
                            matchedTypes?.matchedResourcesTypes.map(
                              (matchedType) => {
                                return {
                                  resourceType: matchedType.resourceTypeName,
                                };
                              }
                            ),
                        },
                      },
                    });

                    if (data && data.data) {
                      toast.success(
                        toastMessages.CHANGE_BACKUP_POLICY_AND_SETUPS
                      );
                      getResources();
                    }
                    Popup.close();
                  },
                },
              ]
            : [
                {
                  text: "Cancel",
                  className: "btn",
                  action: () => {
                    Popup.close();
                  },
                },
              ],
      },
    });
  };

  const changeBackup = async (state: boolean) => {
    const numberOfResources = await refetchResourcesNumber({
      input: {
        customerId,
        filters,
        resourceIDs: isSelectedAll ? null : selectedResourcesIds,
        state,
      },
    });
    const resourceIds = isSelectedAll ? null : selectedResourcesIds;

    const { numberOfResourcesWithChangedBackupEnabled } =
      numberOfResources.data;

    let message = `This operation will ${
      state ? "enable" : "disable"
    } backup for ${numberOfResourcesWithChangedBackupEnabled} of ${totalNumberOfResources} selected resources.`;

    if (!isSelectedAll) {
      message = `This operation will ${
        state ? "enable" : "disable"
      } backup for ${numberOfResourcesWithChangedBackupEnabled} of ${
        selectedResourcesIds.length
      } selected resources.`;

      if (numberOfResourcesWithChangedBackupEnabled === 0) {
        message =
          "There are no resources selected with Policies or Setups applied.";
      }
      if (
        numberOfResourcesWithChangedBackupEnabled < 0 &&
        numberOfResourcesWithChangedBackupEnabled < selectedResourcesIds.length
      ) {
        message = `Some of the resources selected don’t have Policies or Setups applied.
      Do you want to continue for ${numberOfResourcesWithChangedBackupEnabled}
      of ${selectedResourcesIds.length} resources?`;
      }
    }

    Popup.create({
      title: `${state ? "Enable" : "Disable"} backup`,
      content: (
        <div className="box">
          <p>{message}</p>
        </div>
      ),
      buttons: {
        right:
          numberOfResourcesWithChangedBackupEnabled !== 0
            ? [
                {
                  text: "Cancel",
                  className: "btn",
                  action: () => {
                    Popup.close();
                  },
                },
                {
                  text: "Confirm",
                  className: "btn is-primary",
                  action: async () => {
                    const data = await changeBackupState({
                      variables: {
                        input: {
                          customerId,
                          filters,
                          resourceIDs: resourceIds,
                          state,
                        },
                      },
                    });

                    if (data && data.data) {
                      const toastMessage = state
                        ? toastMessages.BACKUP_ENABLED
                        : toastMessages.BACKUP_DISABLED;
                      toast.success(toastMessage);
                      getResources();
                    }
                    Popup.close();
                  },
                },
              ]
            : [
                {
                  text: "Cancel",
                  className: "btn",
                  action: () => {
                    Popup.close();
                  },
                },
              ],
      },
    });
  };

  const triggerBackup = async () => {
    const numberOfResources = await refetchResourcesNumber({
      input: {
        customerId,
        filters,
        resourceIDs: isSelectedAll ? null : selectedResourcesIds,
        state: false,
      },
    });

    const {
      numberOfResourcesWithChangedBackupEnabled:
        numberOfResourcesWithEnabledBackup,
    } = numberOfResources.data;

    const resourcesNumber = isSelectedAll
      ? totalNumberOfResources || 0
      : selectedResourcesIds.length;

    const allResourcesWithBackupEnabled =
      numberOfResourcesWithEnabledBackup === resourcesNumber;
    const message = allResourcesWithBackupEnabled
      ? `This operation will trigger backup for ${pluralizeText(
          resourcesNumber,
          "resource"
        )}.`
      : "Some of the selected resources don’t have enabled backup.";

    let popupButtons;
    if (resourcesNumber !== 0 && allResourcesWithBackupEnabled) {
      popupButtons = [
        {
          text: "Cancel",
          className: "btn",
          action: () => {
            Popup.close();
          },
        },
        {
          text: "Confirm",
          className: "btn",
          action: async () => {
            const data = await triggerBackupMutation({
              variables: {
                input: {
                  customerId,
                  filters,
                  resourceIDs: isSelectedAll ? null : selectedResourcesIds,
                },
              },
            }).catch((e: ApolloError): void => {
              toast.error(e.graphQLErrors.concat());
            });
            if (data && data.data) {
              toast.success(toastMessages.BACKUP_TRIGGERED);
            }
            Popup.close();
          },
        },
      ];
    } else {
      popupButtons = [
        {
          text: "Cancel",
          className: "btn",
          action: () => {
            Popup.close();
          },
        },
      ];
    }

    Popup.create({
      title: "Trigger Backup",
      content: <div className="box">{message}</div>,
      buttons: {
        right: popupButtons,
      },
    });
  };

  return (
    <div>
      {setupVisible && (
        <ResourceSetupModal
          selectedIds={isSelectedAll ? null : selectedResourcesIds}
          filters={filters}
          totalResourcesNumber={totalNumberOfResources || 0}
          onHide={() => triggerSetupVisible(false)}
          refetchResources={() => getResources()}
        />
      )}
      <div className="columns">
        <div className="column is-two-thirds resource-list-buttons">
          <Segment>
            {!isTagBackupEnabled && (
              <>
                <Button
                  disabled={buttonDisabledCondition}
                  size="small"
                  onClick={() => triggerSetupVisible(!setupVisible)}
                >
                  <i className="fa fa-cogs" /> Change policy and/or setup
                </Button>
                <Button
                  disabled={buttonDisabledCondition}
                  size="small"
                  onClick={() => {
                    changeBackup(false);
                  }}
                >
                  <i className="fa fa-times" /> Disable backup
                </Button>
                <Button
                  disabled={buttonDisabledCondition}
                  size="small"
                  onClick={() => {
                    changeBackup(true);
                  }}
                >
                  <i className="fa fa-check" /> Enable backup
                </Button>
                <Button
                  disabled={buttonDisabledCondition}
                  size="small"
                  onClick={clearSetupsAndPolicy}
                >
                  <i className="fa fa-file" /> Clear policy and/or setup
                </Button>
              </>
            )}
            <Button
              disabled={buttonDisabledCondition}
              size="small"
              onClick={triggerBackup}
            >
              <i className="fa fa-rocket" /> Trigger backup
            </Button>
          </Segment>
        </div>
        <div className="column is-one-third">
          {isSelectedAll ? (
            <p style={{ fontSize: ".8rem" }} className="has-text-right">
              <strong>All {totalNumberOfResources} resources</strong> are
              selected.
            </p>
          ) : (
            <p style={{ fontSize: ".8rem" }} className="has-text-right">
              <strong>
                <Pluralize
                  singular={"resource "}
                  plural={"resources "}
                  count={selectedResourcesIds.length}
                />
              </strong>
              on this page
              <Pluralize
                singular={" is "}
                plural={" are "}
                showCount={false}
                count={selectedResourcesIds.length}
              />
              selected.
            </p>
          )}
          {(selectedResourcesIds.length === PAGE_SIZE || isSelectedAll) && (
            <a
              style={{
                fontSize: ".8rem",
                fontWeight: "bold",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              className="is-pulled-right"
              onClick={() => {
                setIsSelectedAll(!isSelectedAll);
              }}
            >
              {isSelectedAll
                ? `Deselect all ${totalNumberOfResources} resources`
                : `Select all ${totalNumberOfResources} resources`}
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResourcesActionButtons;
