/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHoc from '@apollo/react-hoc';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: any }> = { [K in keyof T]: T[K] };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

/**  The Account type describes an account registered in the Backup. */
export type Account = {
  __typename?: 'Account';
  /**  Internal identifier of the account. */
  id: Scalars['String'];
  /**  A primary name of the account. */
  name: Scalars['String'];
  /**  A cloud provider id, e.g. in case of AWS this is the account number, in case of Azure, subscription id. */
  cloudProviderId: Scalars['String'];
  /**  Determines if it's a VAULT or PROTECTED account. */
  type: AccountType;
  /**  Determines the cloud provider in which the account is created. */
  cloudProvider: CloudProvider;
};

/**
 *  The AccountAccessRoleAws type contains URL to
 * IAM role definition which should be created in the user's AWS account.
 */
export type AccountAccessRoleAws = {
  __typename?: 'AccountAccessRoleAws';
  /**  Identifier of the account to which the role belongs. */
  accountId: Scalars['String'];
};

export type AccountAwsAccessRoleTemplateInput = {
  customerId: Scalars['String'];
  /**   The AWS account number (example: 123456789012) */
  accountId: Scalars['String'];
  /**   Account type, PROTECTED or VAULT */
  accountType: AccountType;
};

export type AccountAwsChinaAccessRoleTemplateInput = {
  customerId: Scalars['String'];
  accountId: Scalars['String'];
  accountType: AccountType;
  staticCredentials: StaticAwsCredentialsInput;
};

export type AccountAwsRoleInput = {
  /**   Arn of a role */
  arn: Scalars['String'];
  /**   External id of the role (optional) */
  externalId?: Maybe<Scalars['String']>;
};

export type AccountsPage = {
  __typename?: 'AccountsPage';
  data: Array<Account>;
};

export type AccountsSearchCriteria = {
  /**   Customer ID */
  customerId: Scalars['String'];
  /**   Account type to get (PROTECTED, VAULT) */
  accountType?: Maybe<AccountType>;
};

/**  The enumeration of supported accounts types. */
export enum AccountType {
  Protected = 'PROTECTED',
  Vault = 'VAULT'
}

export type ActivatingSetting = {
  __typename?: 'ActivatingSetting';
  /**   Identifier of a setting */
  id: Scalars['String'];
  /**   A list of values which cause dependent setting to become activated (present on the form) */
  activatingValues: Array<Maybe<Scalars['String']>>;
};

export type AddResourceInput = {
  /**  Resource id from cloud provider e.g ARN in AWS. */
  id: Scalars['String'];
  /**  Customer id from AutoBackup. */
  customerId: Scalars['String'];
  /**  Account id from AutoBackup. */
  accountId: Scalars['String'];
  /**  Resource type. */
  type: Scalars['String'];
  /**  Region where resource is located. */
  region: Scalars['String'];
  tags?: Maybe<Array<Tag>>;
  sizeInBytes?: Maybe<Scalars['String']>;
};

export type AddUserInput = {
  email: Scalars['String'];
};

export type ApiKey = {
  __typename?: 'ApiKey';
  id: Scalars['ID'];
  alias: Scalars['String'];
  owner: Scalars['String'];
  createdAt: Scalars['String'];
  createdBy: Scalars['String'];
  lastUsedAt?: Maybe<Scalars['String']>;
  customerAccesses: CustomerAccessesPage;
};


export type ApiKeyCustomerAccessesArgs = {
  searchCriteria?: Maybe<ApiKeyCustomerAccessesSearchCriteria>;
};

export type ApiKeyCustomerAccessesSearchCriteria = {
  /**   If no page number is provided, the first one will be returned. */
  pageNumber?: Maybe<Scalars['Int']>;
  /**   If no customer ID is provided, all customer accesses will be returned. */
  customerId?: Maybe<Scalars['ID']>;
};

export type ApiKeysPage = {
  __typename?: 'ApiKeysPage';
  data: Array<ApiKey>;
  totalNumberOfPages: Scalars['Int'];
  totalNumberOfApiKeys: Scalars['Int'];
};

export type ApiKeysSearchCriteria = {
  /**   If no page number is provided, the first one will be returned. */
  pageNumber?: Maybe<Scalars['Int']>;
  /**   If provided, only keys owned by the `owner` will be returned. */
  owner?: Maybe<Scalars['String']>;
  /**   If no customer ID is provided, all customer accesses will be returned. */
  customerId?: Maybe<Scalars['ID']>;
};

/**  The AzureCredentialsInput contains required Azure credentials */
export type AzureCredentialsInput = {
  clientId: Scalars['String'];
  clientSecret: Scalars['String'];
  tenantId: Scalars['String'];
};

export type BackupEvent = {
  __typename?: 'BackupEvent';
  id: Scalars['String'];
  /**   Name of backup plan */
  planName: Scalars['String'];
  /**   Total number of resources associated with backup event */
  totalNumberOfResources: Scalars['Int'];
  /**   Number of resources backed up in */
  numberOfBackedUpResources: Scalars['Int'];
  /**   Start datetime of the event (UNIX timestamp) */
  startTimestamp: Scalars['Int'];
  /**   Progress of the event */
  progress: BackupEventProgress;
  /**   Status of the event */
  status: BackupEventStatus;
  /**   Type */
  type: BackupEventType;
};

/**   Progress of the backup event */
export enum BackupEventProgress {
  Created = 'CREATED',
  InProgress = 'IN_PROGRESS',
  Finished = 'FINISHED'
}

export type BackupEventResource = {
  __typename?: 'BackupEventResource';
  /**   Resource associated with the backup event */
  resource: Resource;
  /**   Backup status of the resource */
  status?: Maybe<Scalars['String']>;
  /**   Backup progress of the resource */
  progress: BackupEventProgress;
  snapshot?: Maybe<Snapshot>;
  snapshotReplica?: Maybe<Snapshot>;
  failureReason?: Maybe<Scalars['String']>;
  replicaFailureReason?: Maybe<Scalars['String']>;
};

export type BackupEventResourcesPage = {
  __typename?: 'BackupEventResourcesPage';
  data: Array<BackupEventResource>;
  totalNumberOfPages: Scalars['Int'];
  totalNumberOfResources: Scalars['Int'];
};

export type BackupEventResourcesSearchCriteria = {
  customerId: Scalars['String'];
  backupEventId: Scalars['String'];
  pageNumber?: Maybe<Scalars['Int']>;
};

export type BackupEventSearchCriteria = {
  customerId: Scalars['String'];
  eventId: Scalars['String'];
};

export type BackupEventsPage = {
  __typename?: 'BackupEventsPage';
  data: Array<BackupEvent>;
  totalNumberOfPages: Scalars['Int'];
  totalNumberOfEvents?: Maybe<Scalars['Int']>;
};

/**   BackupEventsSearchCriteria contains filters used for searching the backup events. */
export type BackupEventsSearchCriteria = {
  /**   Id of the customer for which the events will be returned. */
  customerId: Scalars['String'];
  /**   Filters used to search for backup events */
  filters: Array<FilterInput>;
  /**   Optional filter indicating that only events with the provided status should be returned (deprecated use filters). */
  status?: Maybe<BackupEventStatus>;
  /**
   *   Optional filter indicating that only events with the provided backup progress
   * should be returned (deprecated use filters).
   */
  progress?: Maybe<BackupEventProgress>;
  /**   The backup events order. Optional, if not provided default sorting order is descending order. */
  startTimeOrder?: Maybe<Order>;
  /**   The number of the selected page. First page is the default one and is used when this field is empty. */
  pageNumber?: Maybe<Scalars['Int']>;
  /**   The maximal number of events returned on single page. */
  pageSize?: Maybe<Scalars['Int']>;
};

export type BackupEventStats = {
  __typename?: 'BackupEventStats';
  numberOfResourcesMeetingRPO: Scalars['Int'];
  numberOfBackedUpResources: Scalars['Int'];
  totalNumberOfResources: Scalars['Int'];
  successRateHistory: Array<SuccessRateHistoryEntry>;
};

export type BackupEventStatsSearchCriteria = {
  customerId: Scalars['String'];
  backupEventId: Scalars['String'];
};

/**   Status of the backup event */
export enum BackupEventStatus {
  Waiting = 'WAITING',
  Success = 'SUCCESS',
  Failed = 'FAILED',
  PartiallyFailed = 'PARTIALLY_FAILED',
  ReplicationFailed = 'REPLICATION_FAILED',
  FailedStopped = 'FAILED_STOPPED'
}

export enum BackupEventType {
  OnDemand = 'ON_DEMAND',
  Schedule = 'SCHEDULE'
}

export type BackupPoliciesBoundary = {
  __typename?: 'BackupPoliciesBoundary';
  /**  Minimal allowed RPO for the customer. */
  minRPOInHours: Scalars['Int'];
  /**  Max number of retained primary snapshots. */
  maxPrimarySnapshotRetentionQuantity: Scalars['Int'];
  /**  Max number of days for primary snapshot retention. */
  maxPrimarySnapshotRetentionInDays: Scalars['Int'];
  /**  Max number of retained replicated snapshots. */
  maxReplicaSnapshotRetentionQuantity: Scalars['Int'];
  /**  Max number of days for replicated snapshot retention. */
  maxReplicaSnapshotRetentionInDays: Scalars['Int'];
};

/**  The BackupPolicy is a type representing an identifiable set of preconfigured RPO and retention settings. */
export type BackupPolicy = {
  __typename?: 'BackupPolicy';
  /**  Identifier of the backup policy. */
  id: Scalars['String'];
  /**  User friendly name of the policy. */
  name: Scalars['String'];
  /**  Type [RPO, TIME_AWARE], default RPO type. */
  type: PolicyType;
  /**   Friendly tag value */
  tagValue: Scalars['String'];
  /**  RemovePrimarySnapshotsAfterResourceDeletion indicates that primary snapshots should be removed after resource deletion. */
  removePrimarySnapshotsAfterResourceDeletion: Scalars['Boolean'];
  /**  RemoveReplicaSnapshotsAfterResourceDeletion indicates that replica snapshots should be removed after resource deletion. */
  removeReplicaSnapshotsAfterResourceDeletion: Scalars['Boolean'];
  /**  Policy settings */
  policySettings?: Maybe<Array<PolicySettings>>;
  /**  Policy time aware settings, required when type is set to TIME_AWARE. */
  timeAwarePolicySettings?: Maybe<TimeAwarePolicySettings>;
  /**
   *  RPO and retention settings
   * @deprecated use policySettings field
   */
  settings?: Maybe<BackupPolicySettings>;
};

export type BackupPolicySearchCriteria = {
  /**   Identifier of customer of the backup policy. */
  customerId: Scalars['String'];
  /**   ID of backup policy to fetch. */
  backupPolicyId: Scalars['String'];
};

/**  The BackupPolicySettings type represents concrete RPO and retention settings which will be applied to backed up resources. */
export type BackupPolicySettings = {
  __typename?: 'BackupPolicySettings';
  /**  A desirable time interval between resource backups expressed in hours. */
  rpoInHours: Scalars['Int'];
  /**  Backups retention settings. */
  retention: SnapshotsRetention;
};

export type BackupSetting = {
  __typename?: 'BackupSetting';
  /**   ID of a backup setting */
  id: Scalars['String'];
  /**   User-friendly name */
  name: Scalars['String'];
  /**   Label of the setting */
  label: Scalars['String'];
  /**   Description of the setting */
  description: Scalars['String'];
  /**   Value of the setting */
  value: Scalars['String'];
  /**   Type of the value */
  type: BackupSettingType;
};

export type BackupSettingCandidate = {
  __typename?: 'BackupSettingCandidate';
  /**   ID of a backup setting candidate */
  id: Scalars['String'];
  /**   Label of the backup setting */
  label: Scalars['String'];
  /**   Description of the setting */
  description: Scalars['String'];
  /**   Allowed type of the backup setting value */
  type: BackupSettingType;
  /**   Allowed list of values (optional) */
  allowedValues?: Maybe<Array<BackupSettingCandidateValue>>;
  /**
   *  Field deciding if the backup setting should be present on the backup setup creation form.
   * If activatedBy is null, then the setting should always be present.
   * Otherwise, the setting should be present on the form if and only if other backup setting described
   * by ActivatingSetting type has appropriate value.
   * Deprecated, use activatedBySettings
   */
  activatedBy?: Maybe<ActivatingSetting>;
  /**
   *  Field deciding if the backup setting should be present on the backup setup creation form.
   * If activatedBySettings is empty, then the setting should always be present.
   * Otherwise, the setting should be present on the form if and only if other backup settings described
   * by ActivatingSetting type has appropriate value.
   */
  activatedBySettings?: Maybe<Array<ActivatingSetting>>;
  /**   A flag indicating if it is mandatory to provide a value for this backup setting. */
  isRequired: Scalars['Boolean'];
  /**
   *  A list of backup settings identifiers which cause the backup setting to become required. If at least one of the
   * settings pointed by identifiers stored in requiredBy field has a non-empty value,
   * then the backup setting described by an instance of this type should become required,
   * regardless of isRequired flag value
   */
  requiredBy: Array<Scalars['String']>;
  /**   A flag indicating if field will be supported in the future. */
  deprecated: Scalars['Boolean'];
};

export type BackupSettingCandidateValue = {
  __typename?: 'BackupSettingCandidateValue';
  /**   Label of a candidate value */
  label: Scalars['String'];
  value: Scalars['String'];
};

export type BackupSettingInput = {
  /**   Backup setting id (the available list of values can be obtained by query resourceTypeBackupSettings) */
  id: Scalars['String'];
  /**   Backup setting value */
  value: Scalars['String'];
};

export type BackupSettingsSearchCriteria = {
  customerId: Scalars['String'];
  resourceType: Scalars['String'];
};

/**   The list of backup setting value types. */
export enum BackupSettingType {
  String = 'STRING',
  Int = 'INT',
  Boolean = 'BOOLEAN',
  StringList = 'STRING_LIST'
}

export type BackupSetup = {
  __typename?: 'BackupSetup';
  /**   Backup setup ID */
  id: Scalars['String'];
  /**   Customer ID */
  customerId: Scalars['String'];
  /**   Name of backup setup */
  name: Scalars['String'];
  /**   Friendly tag value */
  tagValue: Scalars['String'];
  /**   Resource type, e.g. aws-ebs, aws-ec2 */
  resourceType: Scalars['String'];
  /**   Prehook setup */
  preHook?: Maybe<Hook>;
  /**   Posthook setup */
  postHook?: Maybe<Hook>;
  /**   List of backup settings */
  backupSettings: Array<BackupSetting>;
};

export type BackupSetupInput = {
  /**   Customer ID */
  customerId: Scalars['String'];
  /**   Name of backup setup */
  name: Scalars['String'];
  /**   Resource type, e.g. aws-ebs, aws-ec2 */
  resourceType: Scalars['String'];
  /**   Prehook setup (optional) */
  preHook?: Maybe<HookInput>;
  /**   Posthook setup (optional) */
  postHook?: Maybe<HookInput>;
  /**   List of backup settings */
  backupSettings: Array<BackupSettingInput>;
};

/**   Input used to select a backup which should be return by backupSetup query. */
export type BackupSetupSearchCriteria = {
  /**   Identifier of a customer owning a backup setup. */
  customerId: Scalars['String'];
  /**   Identifier of backup setup owned by customer pointed by customerId field. */
  backupSetupId: Scalars['String'];
};

export type BackupSetupsSearchCriteria = {
  customerId: Scalars['String'];
  /**
   *  Optional resource type for which the list of backup setups is returned. If not provided
   * then the list of all backup setups for given customer is returned.
   */
  resourceType?: Maybe<Scalars['String']>;
};

export type BackupTagNames = {
  __typename?: 'BackupTagNames';
  /**  The list of tag's names that controls whether the backup is enabled or not. */
  backupEnabledTagNames: Array<Scalars['String']>;
  /**  The list of tag's names that contains policy name to be applied. */
  policyTagNames: Array<Scalars['String']>;
  /**  The list of tag's names that contains setup name to be applied. */
  setupTagNames: Array<Scalars['String']>;
};

export type BestAllowedPolicySettings = {
  __typename?: 'BestAllowedPolicySettings';
  /**   Minimal allowed RPO for the customer. */
  minRPOInHours: Scalars['Int'];
  /**  Max number of retained primary snapshots. */
  maxPrimarySnapshotRetentionQuantity: Scalars['Int'];
  /**  Max number of days for primary snapshot retention. */
  maxPrimarySnapshotRetentionInDays: Scalars['Int'];
  /**  Max number of retained replicated snapshots. */
  maxReplicaSnapshotRetentionQuantity: Scalars['Int'];
  /**  Max number of days for replicated snapshot retention. */
  maxReplicaSnapshotRetentionInDays: Scalars['Int'];
};

export type CategoriesWithResouces = {
  __typename?: 'CategoriesWithResouces';
  category: Scalars['String'];
  resources: Array<Scalars['String']>;
};

/**   Change backup policy and/or setups for a set of resources. */
export type ChangeBackupPolicyAndSetupsInput = {
  /**   Identifier of customers whose resources should be modified. */
  customerId: Scalars['String'];
  /**   Filters which determine which resources should be modified. */
  filters: Array<FilterInput>;
  /**
   *  Optional filter containing a list identifiers of resources which should be modified.
   * Note that policies and setups changes will affect only resources with matching identifiers AND matching criteria
   * defined in filters field.
   */
  resourceIDs?: Maybe<Array<Scalars['String']>>;
  /**
   *  Id of backup policy to be set. If omitted or equal to null, then backup policy will be detached from matched resources.
   * This will also cause backup to be disabled for selected resources.
   */
  backupPolicyId?: Maybe<Scalars['String']>;
  /**
   *  List of setup identifiers to be assigned with the selected resources. This field is deprecated.
   * setupsToResourcesTypesAssignments should be used instead. If setupsToResourcesTypesAssignments is not empty,
   * then setupsIds field is ignored.
   */
  setupsIds?: Maybe<Array<Scalars['String']>>;
  /**
   *  A list of pairs defining which setups should be attached to resources having specified type.
   * Note that the list can't contain two or more elements which define setup for the same resource type, because it
   * would be ambiguous. In such case, a validation error is returned.
   */
  setupsToResourcesTypesAssignments?: Maybe<Array<SetupToResourceTypeAssignment>>;
};

/**
 *  Enable or disable backup for a set of resources (given by 'resourceIDs') or
 * resources selected by 'filters'
 */
export type ChangeBackupStateInput = {
  customerId: Scalars['String'];
  filters: Array<FilterInput>;
  resourceIDs?: Maybe<Array<Scalars['String']>>;
  /**   State to set. */
  state: Scalars['Boolean'];
};

export enum CloudProvider {
  Aws = 'AWS',
  AwsChina = 'AWS_CHINA',
  Azure = 'AZURE',
  Gcp = 'GCP',
  Ibmcloud = 'IBMCLOUD'
}

/**  The CreateAccountAwsChinaInput contains data required to register an AWS China account. */
export type CreateAccountAwsChinaInput = {
  /**  The number identifying an AWS account e.g. 123456789012 */
  number: Scalars['String'];
  /**  The account name. */
  name: Scalars['String'];
  /**  An id of the customer who owns the account. */
  customerId: Scalars['String'];
  /**  A field which determines if it's an account under protection or it's used for storing replicas. */
  accountType: AccountType;
  /**
   *  Access credentials for AWS China account. For AWS China, the credentials must contain the staticCredentials field.
   * The rolesChain field is optional. If its value is null, then AutoBackup assumes that a user wants to use a role
   * generated by the awsChinaAccountAccessRoleTemplate query. If a user doesn't want to use roles at all, then the
   * credentials rolesChain field must be explicitly set to empty list.
   */
  credentials: CredentialsAwsInput;
};

/**  The CreateAccountAwsInput contains data required to register an AWS account which is not registered in CMP. */
export type CreateAccountAwsInput = {
  /**  The number identifying an AWS account e.g. 123456789012 */
  number: Scalars['String'];
  /**  The account name. */
  name: Scalars['String'];
  /**  An id of the customer who owns the account. */
  customerId: Scalars['String'];
  /**  A field which determines if it's an account under protection or it's used for storing replicas. */
  accountType: AccountType;
  /**
   *  Optional access credentials for AWS account.
   * If not provided, the AutoBackup will assume that credentials were created by a cloud formation template
   * downloaded from the awsAccountAccessRoleTemplate query.
   */
  credentials?: Maybe<CredentialsAwsInput>;
};

/**  The CreateAccountAzureInput contains data required to register an Azure account which is not registered in CMP. */
export type CreateAccountAzureInput = {
  /**  The number identifying an Azure subscription e.g. 11002211-bdbd-4496-98ee-f2f255699f70 */
  subscriptionId: Scalars['String'];
  /**  The account name. */
  name: Scalars['String'];
  /**  An id of the customer who owns the account. */
  customerId: Scalars['String'];
  /**  Credentials required to access Azure subscription. */
  credentials: AzureCredentialsInput;
};

/**  The CreateAccountGcpInput contains data required to register an GCP account (project). */
export type CreateAccountGcpInput = {
  /**  The identifier identifying an GCP project e.g. example-project */
  projectId: Scalars['String'];
  /**  The account name. */
  name: Scalars['String'];
  /**  An id of the customer who owns the account. */
  customerId: Scalars['String'];
  /**  A field which determines if it's an account under protection or it's used for storing replicas. */
  accountType: AccountType;
  /**  Credentials required to access GCP project. */
  credentials: GcpCredentialsInput;
};

/**  The CreateAccountIbmCloudInput contains data required to register an IBM Cloud account. */
export type CreateAccountIbmCloudInput = {
  /**  ID if the IBM Cloud account. Example: a1b2c3d4e5f61234567890fedcba4321 */
  ibmCloudAccountId: Scalars['String'];
  /**  The account name. */
  name: Scalars['String'];
  /**  An id of the customer who owns the account. */
  customerId: Scalars['String'];
  /**
   *  A field which determines if it's an account under protection or it's used for
   * storing replicas. Only the PROTECTED type is currently supported.
   */
  accountType: AccountType;
  /**  Credentials required to access IBM account. */
  credentials: IbmCloudCredentialsInput;
};

export type CreateApiKeyInput = {
  alias: Scalars['String'];
  owner: Scalars['String'];
};

export type CreateApiKeyResult = {
  __typename?: 'CreateApiKeyResult';
  /**   The API key metadata. */
  apiKey: ApiKey;
  /**   The actual API key string to be used in requests authentication. Please save it as it cannot be retrieved later. */
  apiKeyString: Scalars['String'];
};

export type CreateBackupPolicyInput = {
  customerId: Scalars['String'];
  policyName: Scalars['String'];
  type?: Maybe<PolicyType>;
  policySettings?: Maybe<Array<PolicySettingsInput>>;
  timeAwarePolicySettings?: Maybe<TimeAwarePolicySettingsInput>;
  removePrimarySnapshotsAfterResourceDeletion?: Maybe<Scalars['Boolean']>;
  removeReplicaSnapshotsAfterResourceDeletion?: Maybe<Scalars['Boolean']>;
  /**  Deprecated field */
  rpoInHours?: Maybe<Scalars['Int']>;
  /**  Deprecated field */
  primarySnapshotRetentionCondition?: Maybe<RetentionCondition>;
  /**  Deprecated field */
  primarySnapshotRetentionThreshold?: Maybe<Scalars['Int']>;
  /**  Deprecated field */
  replicaSnapshotRetentionCondition?: Maybe<RetentionCondition>;
  /**  Deprecated field */
  replicaSnapshotRetentionThreshold?: Maybe<Scalars['Int']>;
};

export type CreateDisasterRecoveryPlanInput = {
  /**   Customer ID */
  customerId: Scalars['String'];
  /**   Plan name */
  name: Scalars['String'];
  /**   Region where DR testing should be performed (creating AMI, moving Snapshots). */
  restoreRegion: Scalars['String'];
  /**   Cloud provider */
  cloudProvider: CloudProvider;
  /**   Plan start date (UNIX timestamp) */
  startTimestamp: Scalars['Int'];
  /**   Frequency of disaster recovery plan */
  frequencyInMonths: Scalars['Int'];
  /**   How many days before notification should be sent */
  remindDaysBefore: Scalars['Int'];
  /**   Attached templates */
  templatesResourcesMapping?: Maybe<Array<TemplateResourcesMappingInput>>;
  /**   Notification recipients */
  emails: Array<Scalars['String']>;
  /**
   *   The kmsKey is used by AutoBackup to copy encrypted AWS snapshots to restore region.
   *  Following formats are correct: key ID (for example, key/1234abcd-12ab-34cd-56ef-1234567890ab),
   *  key alias (for example, alias/ExampleAlias), key ARN (for example,
   * arn:aws:kms:us-east-1:012345678910:key/abcd1234-a123-456a-a12b-a123b4cd56ef).
   */
  kmsKey?: Maybe<Scalars['String']>;
};

export type CreateTemplateInput = {
  /**   Customer ID */
  customerId: Scalars['String'];
  /**   Template Name */
  name: Scalars['String'];
  /**   Template Content */
  content: Scalars['String'];
  /**   Cloud provider */
  cloudProvider: CloudProvider;
};

/**   The credentials for AWS account, either as a pair of access key/secret access key, or a list of IAM roles. */
export type CredentialsAwsInput = {
  /**   Static credentials for the account. */
  staticCredentials?: Maybe<StaticAwsCredentialsInput>;
  /**   The list of roles used to get the credentials. */
  rolesChain?: Maybe<Array<AccountAwsRoleInput>>;
};

export type Customer = {
  __typename?: 'Customer';
  /**   Customer ID */
  id: Scalars['String'];
  /**   Customer name */
  name: Scalars['String'];
  /**   The customer role (READ_ONLY, ADMIN, SUPER_ADMIN) */
  role: Scalars['String'];
  /**
   *   The list of accounts for which the logged user has ADMIN rights
   * @deprecated Field no longer supported
   */
  adminAccountIds: Array<Scalars['String']>;
  /**
   *   The list of accounts for which the logged user has READ_ONLY access
   * @deprecated Field no longer supported
   */
  readOnlyAccountIds: Array<Scalars['String']>;
};

export type CustomerAccess = {
  __typename?: 'CustomerAccess';
  customer: CustomerBasic;
  roles: Array<UserRole>;
};

export type CustomerAccessesPage = {
  __typename?: 'CustomerAccessesPage';
  data: Array<Maybe<CustomerAccess>>;
  totalNumberOfPages: Scalars['Int'];
  totalNumberOfCustomers: Scalars['Int'];
};

export type CustomerBasic = {
  __typename?: 'CustomerBasic';
  /**   Customer ID */
  id: Scalars['String'];
  /**   Customer name */
  name: Scalars['String'];
  backupPoliciesBoundary: BackupPoliciesBoundary;
};

/**
 *   The ORPHANED_SNAPSHOTS report contains all non-deleted snapshots for removed
 * resources. reportDate field does not affect generated data (it always returns
 * all snapshots).
 */
export enum CustomerReportType {
  BackupEvent = 'BACKUP_EVENT',
  OrphanedSnapshots = 'ORPHANED_SNAPSHOTS',
  Resources = 'RESOURCES'
}

export type DisasterRecoveryPlan = {
  __typename?: 'DisasterRecoveryPlan';
  /**   Plan ID */
  id: Scalars['String'];
  /**   Customer ID */
  customerId: Scalars['String'];
  /**   Plan name */
  name: Scalars['String'];
  /**   Region where DR testing should be performed (creating AMI, moving Snapshots). */
  restoreRegion: Scalars['String'];
  /**   Cloud provider */
  cloudProvider: CloudProvider;
  /**   Plan start date (UNIX timestamp) */
  startTimestamp: Scalars['Int'];
  /**   Frequency of disaster recovery plan */
  frequencyInMonths: Scalars['Int'];
  /**   How many days before notification should be sent */
  remindDaysBefore: Scalars['Int'];
  /**   Attached plan */
  templatesResourcesMapping?: Maybe<Array<TemplateResourcesMapping>>;
  /**   Notification recipients */
  emails: Array<Scalars['String']>;
  /**
   *   The kmsKey is used by AutoBackup to copy encrypted AWS snapshots to restore region.
   *  Following formats are correct: key ID (for example, key/1234abcd-12ab-34cd-56ef-1234567890ab),
   *  key alias (for example, alias/ExampleAlias), key ARN (for example,
   * arn:aws:kms:us-east-1:012345678910:key/abcd1234-a123-456a-a12b-a123b4cd56ef).
   */
  kmsKey?: Maybe<Scalars['String']>;
};

export type DisasterRecoveryPlanSearchCriteria = {
  /**   Customer ID for which the disaster recovery plan is asked */
  customerId: Scalars['String'];
  /**   Plan ID */
  planId: Scalars['String'];
};

export type DisasterRecoveryPlansPage = {
  __typename?: 'DisasterRecoveryPlansPage';
  data: Array<DisasterRecoveryPlan>;
  totalNumberOfPlans: Scalars['Int'];
  totalNumberOfPages: Scalars['Int'];
};

export type DisasterRecoveryPlansSearchCriteria = {
  /**   Customer ID for which the disaster recovery plans are asked */
  customerId: Scalars['String'];
  pageNumber?: Maybe<Scalars['Int']>;
};

export type DisasterRecoveryRecord = {
  __typename?: 'DisasterRecoveryRecord';
  /**   Record ID */
  id: Scalars['String'];
  /**   Disaster recovery plan */
  plan: DisasterRecoveryPlan;
  /**   Start datetime of the disaster recovery (UNIX timestamp) */
  startTimestamp: Scalars['Int'];
  /**   Finish datetime of the disaster recovery (UNIX timestamp) */
  finishTimestamp?: Maybe<Scalars['Int']>;
  /**   Status of the disaster recovery */
  status: DisasterRecoveryStatus;
  /**   Person who is responsible for performing the disaster recovery */
  assignee: Scalars['String'];
  /**   Region where the restore has been performed. */
  restoreRegion: Scalars['String'];
  /**   Generated by AutoBackup links to the provider website to continue resources recovering. */
  links?: Maybe<Array<TemplateLink>>;
  /**   Error messages during recovering jobs. */
  errorMessages?: Maybe<Array<Scalars['String']>>;
  /**   Note field contains additional information about Disaster recovery, for example: error message, link to documents. */
  note: Scalars['String'];
};

export type DisasterRecoveryRecordsPage = {
  __typename?: 'DisasterRecoveryRecordsPage';
  data: Array<DisasterRecoveryRecord>;
  totalNumberOfRecords: Scalars['Int'];
  totalNumberOfPages: Scalars['Int'];
};

export type DisasterRecoveryRecordsReport = {
  __typename?: 'DisasterRecoveryRecordsReport';
  data: Scalars['String'];
};

export type DisasterRecoveryRecordsReportInput = {
  customerId: Scalars['String'];
  timeRange: TimeRange;
};

export type DisasterRecoveryRecordsSearchCriteria = {
  /**   Customer ID for which the disaster recovery records are asked */
  customerId: Scalars['String'];
  pageNumber?: Maybe<Scalars['Int']>;
};

export type DisasterRecoverySnapshotsSearchCriteria = {
  /**   Customer ID */
  customerId: Scalars['String'];
  /**   Plan ID */
  planId: Scalars['String'];
  /**   The date from snapshots should be restored (UNIX timestamp) */
  recoveryPointTimestamp: Scalars['Int'];
};

/**   Status of the disaster recovery */
export enum DisasterRecoveryStatus {
  InProgress = 'IN_PROGRESS',
  Ready = 'READY',
  Success = 'SUCCESS',
  Failed = 'FAILED'
}

export type DismissMessageInput = {
  customerId: Scalars['String'];
  messageId: Scalars['String'];
};

export type EmailConfig = {
  __typename?: 'EmailConfig';
  address: Scalars['String'];
};

export type EmailConfigInput = {
  address: Scalars['String'];
};

export type ExternallyBackedUpResource = {
  __typename?: 'ExternallyBackedUpResource';
  id: Scalars['String'];
  backupEnabled: Scalars['Boolean'];
  backupProvider: Scalars['String'];
  cloudProvider: CloudProvider;
  updatedAtTimestamp: Scalars['Int'];
  rpoInHours?: Maybe<Scalars['Int']>;
  lastBackupStatus?: Maybe<Scalars['String']>;
  lastBackupErrorMessage?: Maybe<Scalars['String']>;
};

export type ExternallyBackedUpResourcesPage = {
  __typename?: 'ExternallyBackedUpResourcesPage';
  data: Array<ExternallyBackedUpResource>;
  totalNumberOfPages: Scalars['Int'];
  totalNumberOfResources: Scalars['Int'];
};

export type ExternallyBackedUpResourcesSearchCriteria = {
  customerId: Scalars['String'];
  filters: Array<FilterInput>;
  pageNumber?: Maybe<Scalars['Int']>;
};

export type Filter = {
  __typename?: 'Filter';
  /**   Filter identifier */
  id: Scalars['String'];
  /**   The human readable filter label (e.g. Account) */
  name: Scalars['String'];
  /**   The optional list of possible values. */
  values?: Maybe<Array<FilterValue>>;
  /**   The filter type */
  type?: Maybe<FilterType>;
};

/**   The list of possible filter groups */
export enum FilteredDataType {
  BackupEvents = 'BACKUP_EVENTS',
  Resources = 'RESOURCES',
  TemplateResources = 'TEMPLATE_RESOURCES',
  BackupEventResources = 'BACKUP_EVENT_RESOURCES',
  ExternallyBackedUpResources = 'EXTERNALLY_BACKED_UP_RESOURCES'
}

export type FilterInput = {
  /**   Filter ID (the list of available filter ids can be obtained from query 'filters' */
  id: Scalars['String'];
  /**   Filter values */
  values: Array<Scalars['String']>;
};

export type FiltersSearchCriteria = {
  customerId: Scalars['String'];
  /**   The group for which the possible filters are fetched (e.g. RESOURCES to get filters used for searching resources) */
  filteredDataType: FilteredDataType;
};

export enum FilterType {
  Text = 'TEXT',
  Boolean = 'BOOLEAN',
  SelectOne = 'SELECT_ONE',
  SelectMulti = 'SELECT_MULTI',
  Timestamp = 'TIMESTAMP'
}

export type FilterValue = {
  __typename?: 'FilterValue';
  /**   Human readable label for the value */
  label: Scalars['String'];
  value: Scalars['String'];
};

/**  The enumeration of supported snapshots taking frequencies. */
export enum Frequency {
  Hours = 'HOURS',
  Days = 'DAYS',
  Weeks = 'WEEKS',
  Months = 'MONTHS'
}

export type GcpCredentialsInput = {
  type: Scalars['String'];
  projectId: Scalars['String'];
  privateKeyId: Scalars['String'];
  privateKey: Scalars['String'];
  clientEmail: Scalars['String'];
  clientId: Scalars['String'];
  authUri: Scalars['String'];
  tokenUri: Scalars['String'];
  authProviderX509CertUrl: Scalars['String'];
  clientX509CertUrl: Scalars['String'];
};

export type GrantCustomerRoleToApiKeyInput = {
  /**   API key ID. */
  id: Scalars['String'];
  /**   Customer ID. */
  customerId: Scalars['ID'];
  /**   Role to be added for given customer. */
  role: UserRole;
};

export type GrantCustomerRoleToUserInput = {
  /**   User email. */
  email: Scalars['String'];
  /**   Customer ID. */
  customerId: Scalars['ID'];
  /**   Role to be added for given customer. */
  role: UserRole;
};

export type HistoryEntry = {
  __typename?: 'HistoryEntry';
  eventCount: Scalars['Int'];
  month: Scalars['Int'];
};

/**  The hook type contains hook's type and endpoint (http or Lambda's ARN). */
export type Hook = {
  __typename?: 'Hook';
  /**   Type of the hook */
  type: HookType;
  /**   Endpoint, e.g. (URL in case of URL type or Lambda ARN in case of LAMBDA_ARN) */
  endpoint: Scalars['String'];
};

export type HookInput = {
  /**   Type of hook */
  type: HookType;
  endpoint: Scalars['String'];
};

/**  The enumeration of supported hooks types. */
export enum HookType {
  Url = 'URL',
  LambdaArn = 'LAMBDA_ARN'
}

export type IbmCloudCredentialsInput = {
  apiKey: Scalars['String'];
};

/**  The LastBackupStatus type stores information about last backup error messages and status. */
export type LastBackupStatus = {
  __typename?: 'LastBackupStatus';
  /**  Field containing information about an error which occurred during primary backup execution. */
  primaryBackupErrorMessage?: Maybe<Scalars['String']>;
  /**  Field containing information about an error which occurred during replication. */
  replicationErrorMessage?: Maybe<Scalars['String']>;
  /**  Field indicating status of last backup operation. */
  backupStatus: Scalars['String'];
};

export type LatestResourceSnapshotSearchCriteria = {
  customerId: Scalars['String'];
  resourceId: Scalars['String'];
  /**
   *  Optional parameter specifying type of latest snapshot to be returned.
   * By default snapshot of type PRIMARY is returned.
   */
  snapshotType?: Maybe<SnapshotType>;
};

export type MatchedResourcesType = {
  __typename?: 'MatchedResourcesType';
  resourceTypeName: Scalars['String'];
  numberOfResources: Scalars['Int'];
};

export type MatchedResourcesTypesSearchCriteria = {
  customerId: Scalars['String'];
  filters: Array<FilterInput>;
  onlySelectedIds?: Maybe<Array<Scalars['String']>>;
};

export type Message = {
  __typename?: 'Message';
  customerId: Scalars['String'];
  id: Scalars['String'];
  message: Scalars['String'];
  type: MessageType;
  category: MessageCategory;
};

export enum MessageCategory {
  General = 'GENERAL',
  DisasterRecovery = 'DISASTER_RECOVERY'
}

export type MessagesPage = {
  __typename?: 'MessagesPage';
  data: Array<Message>;
  nextPageCursor?: Maybe<Scalars['String']>;
};

export type MessagesSearchCriteria = {
  customerId: Scalars['String'];
  pageCursor?: Maybe<Scalars['String']>;
};

export enum MessagesSendingFrequency {
  OncePerDay = 'ONCE_PER_DAY',
  OncePerEvent = 'ONCE_PER_EVENT'
}

export enum MessageType {
  Critical = 'CRITICAL',
  Warning = 'WARNING',
  Info = 'INFO'
}

export type Mutation = {
  __typename?: 'Mutation';
  /**
   *  The mutation creating a new AWS China account definition.
   * It is used to register an account.
   */
  createAccountAwsChina: Scalars['String'];
  /**
   *  The mutation creating a new AWS account definition.
   * It is used to register an account.
   * IMPORTANT
   * Before calling this mutation either use awsAccountAccessRoleTemplate query to generate role template,
   * apply the template and then call the this mutation, or in the input parameters
   * put the list of roles to use or the static credentials. If there is no errors, it
   * returns the identifier of the new account.
   */
  createAccountAws: AccountAccessRoleAws;
  /**
   *  The mutation creating a new Azure account definition.
   * It is used to register an account.
   * If there are no errors, it returns the identifier of the new account.
   */
  createAccountAzure: Scalars['String'];
  /**
   *  The mutation creating a new GCP account (project) definition.
   * It is used to register an account.
   * If there are no errors, it returns the identifier of the new account.
   */
  createAccountGcp: Scalars['String'];
  /**
   *  The mutation creating a new IBM Cloud account definition.
   * It is used to register an account.
   * If there are no errors, it returns the identifier of the new account.
   */
  createAccountIbmCloud: Scalars['String'];
  /**  The mutation which triggers scan of the whole customer (all accounts) */
  triggerScan: ScanTriggerResult;
  /**  The mutation triggers scan on specific cloud account */
  triggerScanOnCloudAccount: ScanTriggerResult;
  /**  The mutation triggers the event scanStatusChanged (internal usage) */
  notifyAboutScanStatusChange?: Maybe<ScanStatusUpdateResult>;
  /**  The removeAccount mutation removes the cloud account from the Backup. */
  removeAccount: Account;
  /**
   *  The mutation which saves notification group belonging to a specified user.
   * It can be used to create or update the notifications group.
   */
  createNotificationGroup?: Maybe<NotificationGroup>;
  /**  The mutation for removing notification groups. */
  removeNotificationGroup: Scalars['Boolean'];
  /**   It dismiss the internal message. */
  dismissMessage: Scalars['String'];
  /**   Creates backup policy. */
  createBackupPolicy?: Maybe<BackupPolicy>;
  /**   Removes backup policy. */
  removeBackupPolicy: Scalars['Boolean'];
  /**   Creates backup setup. */
  createBackupSetup?: Maybe<BackupSetup>;
  /**   Update backup setup. */
  updateBackupSetup?: Maybe<BackupSetup>;
  /**   Remove backup setups */
  removeBackupSetup: Scalars['Boolean'];
  /**   Enable/disable backup on selected resources. */
  changeBackupState: Scalars['Boolean'];
  /**   The mutation assigning provided backup policy and setups to the set of selected resources. */
  changeBackupPolicyAndSetups: Scalars['Boolean'];
  /**   The mutation allows to trigger backup (generate new event) on selected resources. */
  triggerBackup: Scalars['Boolean'];
  /**   The mutation allows to add new resource. */
  addResource?: Maybe<Resource>;
  /**  The mutation triggers the event newMessage (internal usage) */
  notifyNewMessage?: Maybe<Message>;
  /**  The mutation allows providing the tag definitions that control the backup process. */
  provideBackupTagNames: BackupTagNames;
  /**  The mutation allows to edit user's profile. */
  updateUserProfile: UserProfile;
  /**  The mutation allows to remove disaster recovery template. */
  removeTemplate: Scalars['Boolean'];
  /**  The mutation allows to validate disaster recovery template and returns template's parameters. */
  validateTemplate: ValidateTemplateResult;
  /**   The mutation allows to put the template for the disaster recovery purpose. */
  createTemplate?: Maybe<Template>;
  /**   The mutation allows to update the template for the disaster recovery purpose. */
  updateTemplate?: Maybe<Template>;
  /**   The mutation allows to update disaster recovery record. */
  updateDisasterRecoveryRecord?: Maybe<DisasterRecoveryRecord>;
  /**   The mutation allows to create disaster recovery plan. */
  createDisasterRecoveryPlan?: Maybe<DisasterRecoveryPlan>;
  /**   The mutation allows to update disaster recovery plan. */
  updateDisasterRecoveryPlan?: Maybe<DisasterRecoveryPlan>;
  /**  The mutation allows to remove disaster recovery plan. */
  removeDisasterRecoveryPlan: Scalars['Boolean'];
  /**  The mutation allows to start disaster recovery. */
  startDisasterRecovery?: Maybe<DisasterRecoveryRecord>;
  /**   The mutation adds a new user. */
  addUser: User;
  /**   The mutation removes the user. */
  removeUser: Scalars['Boolean'];
  /**   The mutation grants Super Admin role to the user. */
  grantSuperAdmin: Scalars['Boolean'];
  /**   The mutation revokes Super Admin role from the user. */
  revokeSuperAdmin: Scalars['Boolean'];
  /**   The mutation grants a new customer access role to the user. */
  grantCustomerRoleToUser: Scalars['Boolean'];
  /**   The mutation revokes the customer access role from the user. */
  revokeCustomerRoleFromUser: Scalars['Boolean'];
  /**   The mutation creates a new API key for any entity. Only Super Admin can perform this operation. */
  createApiKey: CreateApiKeyResult;
  /**   The mutation removes the API key. Only Super Admin can perform this operation. */
  removeApiKey: Scalars['Boolean'];
  /**   The mutation grants a new customer access role to the API key. Only Super Admin can perform this operation. */
  grantCustomerRoleToApiKey: Scalars['Boolean'];
  /**   The mutation revokes the customer access role from the API key. Only Super Admin can perform this operation. */
  revokeCustomerRoleFromApiKey: Scalars['Boolean'];
  /**   The mutation creates a new API key for the user requesting it. Anyone can perform this operation. */
  selfCreateApiKey: CreateApiKeyResult;
  /**   The mutation removes the API key belonging to the user requesting it. Anyone can perform this operation. */
  selfRemoveApiKey: Scalars['Boolean'];
  /**
   *  The mutation grants a new customer access role to the API key belonging to the user requesting it.
   * Anyone can perform this operation.
   */
  selfGrantCustomerRoleToApiKey: Scalars['Boolean'];
  /**
   *  The mutation revokes the customer access role from the API key belonging to the user requesting it.
   * Anyone can perform this operation.
   */
  selfRevokeCustomerRoleFromApiKey: Scalars['Boolean'];
};


export type MutationCreateAccountAwsChinaArgs = {
  input: CreateAccountAwsChinaInput;
};


export type MutationCreateAccountAwsArgs = {
  input: CreateAccountAwsInput;
};


export type MutationCreateAccountAzureArgs = {
  input: CreateAccountAzureInput;
};


export type MutationCreateAccountGcpArgs = {
  input: CreateAccountGcpInput;
};


export type MutationCreateAccountIbmCloudArgs = {
  input: CreateAccountIbmCloudInput;
};


export type MutationTriggerScanArgs = {
  input: TriggerScanInput;
};


export type MutationTriggerScanOnCloudAccountArgs = {
  input: TriggerScanOnCloudAccountInput;
};


export type MutationNotifyAboutScanStatusChangeArgs = {
  input: ScanStatusUpdateInput;
};


export type MutationRemoveAccountArgs = {
  input?: Maybe<RemoveAccountInput>;
};


export type MutationCreateNotificationGroupArgs = {
  input: NotificationGroupInput;
};


export type MutationRemoveNotificationGroupArgs = {
  input: RemoveNotificationGroupInput;
};


export type MutationDismissMessageArgs = {
  input: DismissMessageInput;
};


export type MutationCreateBackupPolicyArgs = {
  input: CreateBackupPolicyInput;
};


export type MutationRemoveBackupPolicyArgs = {
  input: RemoveBackupPolicyInput;
};


export type MutationCreateBackupSetupArgs = {
  input: BackupSetupInput;
};


export type MutationUpdateBackupSetupArgs = {
  input: UpdateBackupSetupInput;
};


export type MutationRemoveBackupSetupArgs = {
  input: RemoveBackupSetupInput;
};


export type MutationChangeBackupStateArgs = {
  input: ChangeBackupStateInput;
};


export type MutationChangeBackupPolicyAndSetupsArgs = {
  input: ChangeBackupPolicyAndSetupsInput;
};


export type MutationTriggerBackupArgs = {
  input?: Maybe<TriggerBackupInput>;
};


export type MutationAddResourceArgs = {
  input: AddResourceInput;
};


export type MutationNotifyNewMessageArgs = {
  input: NewMessageUpdateInput;
};


export type MutationProvideBackupTagNamesArgs = {
  input: ProvideBackupTagNamesInput;
};


export type MutationUpdateUserProfileArgs = {
  input: UserProfileInput;
};


export type MutationRemoveTemplateArgs = {
  input: RemoveTemplateInput;
};


export type MutationValidateTemplateArgs = {
  input: ValidateTemplateInput;
};


export type MutationCreateTemplateArgs = {
  input: CreateTemplateInput;
};


export type MutationUpdateTemplateArgs = {
  input: UpdateTemplateInput;
};


export type MutationUpdateDisasterRecoveryRecordArgs = {
  input: UpdateDisasterRecoveryRecordInput;
};


export type MutationCreateDisasterRecoveryPlanArgs = {
  input: CreateDisasterRecoveryPlanInput;
};


export type MutationUpdateDisasterRecoveryPlanArgs = {
  input: UpdateDisasterRecoveryPlanInput;
};


export type MutationRemoveDisasterRecoveryPlanArgs = {
  input: RemoveDisasterRecoveryPlanInput;
};


export type MutationStartDisasterRecoveryArgs = {
  input: StartDisasterRecoveryInput;
};


export type MutationAddUserArgs = {
  input: AddUserInput;
};


export type MutationRemoveUserArgs = {
  email: Scalars['String'];
};


export type MutationGrantSuperAdminArgs = {
  email: Scalars['String'];
};


export type MutationRevokeSuperAdminArgs = {
  email: Scalars['String'];
};


export type MutationGrantCustomerRoleToUserArgs = {
  input: GrantCustomerRoleToUserInput;
};


export type MutationRevokeCustomerRoleFromUserArgs = {
  input: RevokeCustomerRoleFromUserInput;
};


export type MutationCreateApiKeyArgs = {
  input: CreateApiKeyInput;
};


export type MutationRemoveApiKeyArgs = {
  id: Scalars['ID'];
};


export type MutationGrantCustomerRoleToApiKeyArgs = {
  input: GrantCustomerRoleToApiKeyInput;
};


export type MutationRevokeCustomerRoleFromApiKeyArgs = {
  input: RevokeCustomerRoleFromApiKeyInput;
};


export type MutationSelfCreateApiKeyArgs = {
  input: SelfCreateApiKeyInput;
};


export type MutationSelfRemoveApiKeyArgs = {
  id: Scalars['ID'];
};


export type MutationSelfGrantCustomerRoleToApiKeyArgs = {
  input: GrantCustomerRoleToApiKeyInput;
};


export type MutationSelfRevokeCustomerRoleFromApiKeyArgs = {
  input: RevokeCustomerRoleFromApiKeyInput;
};

export type MyApiKeysSearchCriteria = {
  /**   If no page number is provided, the first one will be returned. */
  pageNumber?: Maybe<Scalars['Int']>;
  /**   If no customer ID is provided, all customer accesses will be returned. */
  customerId?: Maybe<Scalars['ID']>;
};

export type NewMessageUpdateInput = {
  customerId: Scalars['String'];
  id: Scalars['String'];
  message: Scalars['String'];
  type: MessageType;
};

export type NotificationChannel = EmailConfig | SlackConfig | PagerDutyConfig;

export type NotificationChannelInput = {
  channelType: NotificationChannelType;
  email?: Maybe<EmailConfigInput>;
  slack?: Maybe<SlackConfigInput>;
  pagerDuty?: Maybe<PagerDutyConfigInput>;
};

export enum NotificationChannelType {
  Email = 'EMAIL',
  Slack = 'SLACK',
  Pagerduty = 'PAGERDUTY'
}

export type NotificationGroup = {
  __typename?: 'NotificationGroup';
  id: Scalars['String'];
  name: Scalars['String'];
  severities: Array<NotificationSeverity>;
  frequency: MessagesSendingFrequency;
  channels: Array<NotificationChannel>;
  excludedAccounts?: Maybe<Array<Account>>;
};

export type NotificationGroupInput = {
  customerId: Scalars['String'];
  name: Scalars['String'];
  severities: Array<NotificationSeverity>;
  frequency: MessagesSendingFrequency;
  channels: Array<NotificationChannelInput>;
  excludedAccounts?: Maybe<Array<Scalars['String']>>;
};

export type NotificationGroupSearchCriteria = {
  customerId: Scalars['String'];
  notificationGroupId: Scalars['String'];
};

export enum NotificationSeverity {
  Error = 'ERROR',
  Warning = 'WARNING',
  Success = 'SUCCESS',
  Info = 'INFO'
}

/**  The Order is an enum for sorting purpose. */
export enum Order {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type PagerDutyConfig = {
  __typename?: 'PagerDutyConfig';
  routingKey: Scalars['String'];
};

export type PagerDutyConfigInput = {
  routingKey: Scalars['String'];
};

export type PolicyCompliance = {
  __typename?: 'PolicyCompliance';
  numberOfBackedUpResources: Scalars['Int'];
  numberOfCompliantResources: Scalars['Int'];
};

/**  The PolicySettings type represents single retention settings */
export type PolicySettings = {
  __typename?: 'PolicySettings';
  /**  A desirable time interval between resource backups */
  rpo: Scalars['Int'];
  /**  Snapshots taking frequencies. */
  frequency: Frequency;
  /**  Backups retention settings. */
  retention: SnapshotsRetention;
};

export type PolicySettingsInput = {
  /**  A desirable time interval between resource backups */
  rpo: Scalars['Int'];
  /**  Snapshots taking frequencies. */
  frequency: Frequency;
  /**  Backups retention settings. */
  retention: SnapshotsRetentionInput;
};

export enum PolicyType {
  Rpo = 'RPO',
  TimeAware = 'TIME_AWARE'
}

export type ProtectedResourcesStats = {
  __typename?: 'ProtectedResourcesStats';
  numberOfResourcesWithEnabledBackup: Scalars['Int'];
  totalNumberOfResources: Scalars['Int'];
  /**   Total amount of data in bytes */
  totalAmountOfDataInBytes: Scalars['String'];
  /**   Protected amount of data in bytes */
  protectedAmountOfDataInBytes: Scalars['String'];
};

export type ProtectedResourcesStatsSearchCriteria = {
  customerId: Scalars['String'];
};

export type ProvideBackupTagNamesInput = {
  /**  The identifier of customer for which tags are provided. */
  customerId: Scalars['String'];
  /**  The list of tag's names that controls whether the backup is enabled or not. */
  backupEnabledTagNames: Array<Scalars['String']>;
  /**  The list of tag's names that contains policy name to be applied. */
  policyTagNames: Array<Scalars['String']>;
  /**  The list of tag's names that contains setup name to be applied. */
  setupTagNames: Array<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  /**   The query returning unpaginated list of all customers. Only Super Admin can perform this operation. */
  customersList: Array<CustomerBasic>;
  /**  The query returning a list of resources backed up by tools other than AutoBackup. */
  externallyBackedUpResources: ExternallyBackedUpResourcesPage;
  /**
   *  The query returning types of resources supported by the Backup, optionally
   * filtered by a cloud provider e.g. aws-ec2, azure-vm.
   */
  resourcesTypes?: Maybe<Array<Scalars['String']>>;
  /**  The query returning regions supported by the Backup for the specified cloud provider. */
  supportedRegions: Array<Scalars['String']>;
  /**  The query returning a page of accounts. */
  accounts: AccountsPage;
  /**  The query returning a list of customers to which the logged user has an access. */
  accessibleCustomers: Array<Customer>;
  /**  The query returning a page of resources. */
  resources: ResourcesPage;
  /**  The status of the last scanning of given customer. */
  scanStatus?: Maybe<ScanStatusResult>;
  /**  The status of the last scanning of given cloud account. */
  scanStatusAccount?: Maybe<ScanStatusResult>;
  /**   Get the S3 url to the CloudFormation template with the role. */
  awsAccountAccessRoleTemplate: Scalars['String'];
  /**   Get the S3 url to the CloudFormation template with the role. */
  awsChinaAccountAccessRoleTemplate?: Maybe<Scalars['String']>;
  /**  The query returning a list of backup events. */
  backupEvents: BackupEventsPage;
  /**  The query returning a page of resources belonging to the specified backup event. */
  backupEventResources: BackupEventResourcesPage;
  /**  The query returning backup event statistics, such as success rates, backup size and the rates history. */
  backupEventStats: BackupEventStats;
  /**  The query returning data for high level report of backups performed in specified time period. */
  summaryBackupsReport: SummaryBackupsReport;
  /**  The query to get the backup event. */
  backupEvent?: Maybe<BackupEvent>;
  /**  Get the list of applicable filters */
  filters: Array<Filter>;
  /**  Get categories with resources mapping */
  categoriesWithResourcesMapping: Array<CategoriesWithResouces>;
  /**  Get the list of internal messages. */
  messages: MessagesPage;
  /**  Get the list of available backup settings for given resource type. */
  resourceTypeBackupSettings: Array<BackupSettingCandidate>;
  /**  Get the list of available backup policies. */
  backupPolicies: Array<BackupPolicy>;
  /**  Get backup policy. */
  backupPolicy?: Maybe<BackupPolicy>;
  /**  The query returning information about resources types and number of resources matching provided search criteria. */
  matchedResourcesTypes: Array<MatchedResourcesType>;
  /**  Get number of resources with changed state of backup enabled. */
  numberOfResourcesWithChangedBackupEnabled: Scalars['Int'];
  backupHistory?: Maybe<Array<HistoryEntry>>;
  notificationGroup?: Maybe<NotificationGroup>;
  notificationGroups: Array<NotificationGroup>;
  /**  Get the list of available backup setups. */
  backupSetups: Array<BackupSetup>;
  /**  Get selected backup setup. */
  backupSetup?: Maybe<BackupSetup>;
  /**  Get the number of resources for which the change in backup setups will be applied. */
  numberOfResourcesWithChangedBackupSetups: Scalars['Int'];
  /**  Get the number of resources for which the change in backup policies will be applied. */
  numberOfResourcesWithChangedBackupPolicy: Scalars['Int'];
  /**  Get the statistics about the protected resources. */
  protectedResourcesStats: ProtectedResourcesStats;
  /**  The the description of the latest resource snapshot. */
  latestResourceSnapshot?: Maybe<Snapshot>;
  /**  Returns the allowed boundaries on backup policies for given customer. */
  bestAllowedPolicySettings?: Maybe<BestAllowedPolicySettings>;
  /**  Returns the user profile data. */
  userProfile?: Maybe<UserProfile>;
  /**  Returns the statistics on backup policy compliance. */
  policyCompliance?: Maybe<PolicyCompliance>;
  /**  The query returning backup tags names. */
  backupTagNames?: Maybe<BackupTagNames>;
  /**  The query returning user role to provided customer. */
  userRole?: Maybe<UserRole>;
  /**   The query returns the details about the report. */
  report?: Maybe<Report>;
  /**   The query returns list of available templates. */
  templates: TemplatesPage;
  /**   The query returns specified template. */
  template?: Maybe<Template>;
  /**   The query returns disaster recovery records. */
  disasterRecoveryRecords: DisasterRecoveryRecordsPage;
  /**   The query returning data for disaster recovery testing performed in specified time period. */
  disasterRecoveryRecordsReport: DisasterRecoveryRecordsReport;
  /**   The query return list of disaster recovery plans. */
  disasterRecoveryPlans: DisasterRecoveryPlansPage;
  /**   The query returns specified disaster recovery plan. */
  disasterRecoveryPlan?: Maybe<DisasterRecoveryPlan>;
  /**   This query returns snapshots that will be used to perform disaster recovery. */
  disasterRecoverySnapshots?: Maybe<Array<Snapshot>>;
  /**   The query returns a single user. */
  user: User;
  /**   The query returns all users. */
  users: UsersPage;
  /**   The query returns a single API key. Only Super Admin can perform this operation. */
  apiKey: ApiKey;
  /**   The query returns all API keys. Only Super Admin can perform this operation. */
  apiKeys: ApiKeysPage;
  /**   The query returns a single API key owned by the user. Anyone can perform this operation. */
  myApiKey: ApiKey;
  /**   The query returns API keys owned by the user. Anyone can perform this operation. */
  myApiKeys: ApiKeysPage;
};


export type QueryExternallyBackedUpResourcesArgs = {
  searchCriteria: ExternallyBackedUpResourcesSearchCriteria;
};


export type QueryResourcesTypesArgs = {
  cloudProvider?: Maybe<CloudProvider>;
};


export type QuerySupportedRegionsArgs = {
  cloudProvider: CloudProvider;
};


export type QueryAccountsArgs = {
  searchCriteria: AccountsSearchCriteria;
};


export type QueryResourcesArgs = {
  searchCriteria: ResourcesSearchCriteria;
};


export type QueryScanStatusArgs = {
  customerId: Scalars['String'];
};


export type QueryScanStatusAccountArgs = {
  input: ScanStatusAccountInput;
};


export type QueryAwsAccountAccessRoleTemplateArgs = {
  input: AccountAwsAccessRoleTemplateInput;
};


export type QueryAwsChinaAccountAccessRoleTemplateArgs = {
  input: AccountAwsChinaAccessRoleTemplateInput;
};


export type QueryBackupEventsArgs = {
  searchCriteria: BackupEventsSearchCriteria;
};


export type QueryBackupEventResourcesArgs = {
  searchCriteria: BackupEventResourcesSearchCriteria;
};


export type QueryBackupEventStatsArgs = {
  searchCriteria: BackupEventStatsSearchCriteria;
};


export type QuerySummaryBackupsReportArgs = {
  searchCriteria: SummaryBackupsReportScope;
};


export type QueryBackupEventArgs = {
  searchCriteria: BackupEventSearchCriteria;
};


export type QueryFiltersArgs = {
  searchCriteria: FiltersSearchCriteria;
};


export type QueryMessagesArgs = {
  searchCriteria: MessagesSearchCriteria;
};


export type QueryResourceTypeBackupSettingsArgs = {
  searchCriteria: BackupSettingsSearchCriteria;
};


export type QueryBackupPoliciesArgs = {
  customerId: Scalars['String'];
};


export type QueryBackupPolicyArgs = {
  searchCriteria: BackupPolicySearchCriteria;
};


export type QueryMatchedResourcesTypesArgs = {
  searchCriteria: MatchedResourcesTypesSearchCriteria;
};


export type QueryNumberOfResourcesWithChangedBackupEnabledArgs = {
  input: ChangeBackupStateInput;
};


export type QueryBackupHistoryArgs = {
  customerId: Scalars['String'];
};


export type QueryNotificationGroupArgs = {
  searchCriteria: NotificationGroupSearchCriteria;
};


export type QueryNotificationGroupsArgs = {
  customerId: Scalars['String'];
};


export type QueryBackupSetupsArgs = {
  searchCriteria?: Maybe<BackupSetupsSearchCriteria>;
};


export type QueryBackupSetupArgs = {
  searchCriteria: BackupSetupSearchCriteria;
};


export type QueryNumberOfResourcesWithChangedBackupSetupsArgs = {
  input: ChangeBackupPolicyAndSetupsInput;
};


export type QueryNumberOfResourcesWithChangedBackupPolicyArgs = {
  input: ChangeBackupPolicyAndSetupsInput;
};


export type QueryProtectedResourcesStatsArgs = {
  searchCriteria: ProtectedResourcesStatsSearchCriteria;
};


export type QueryLatestResourceSnapshotArgs = {
  searchCriteria: LatestResourceSnapshotSearchCriteria;
};


export type QueryBestAllowedPolicySettingsArgs = {
  customerId: Scalars['String'];
};


export type QueryUserProfileArgs = {
  customerId: Scalars['String'];
};


export type QueryPolicyComplianceArgs = {
  customerId: Scalars['String'];
};


export type QueryBackupTagNamesArgs = {
  customerId: Scalars['String'];
};


export type QueryUserRoleArgs = {
  customerId: Scalars['String'];
};


export type QueryReportArgs = {
  input: ReportInput;
};


export type QueryTemplatesArgs = {
  searchCriteria: TemplatesSearchCriteria;
};


export type QueryTemplateArgs = {
  searchCriteria: TemplateSearchCriteria;
};


export type QueryDisasterRecoveryRecordsArgs = {
  searchCriteria: DisasterRecoveryRecordsSearchCriteria;
};


export type QueryDisasterRecoveryRecordsReportArgs = {
  input: DisasterRecoveryRecordsReportInput;
};


export type QueryDisasterRecoveryPlansArgs = {
  searchCriteria: DisasterRecoveryPlansSearchCriteria;
};


export type QueryDisasterRecoveryPlanArgs = {
  searchCriteria: DisasterRecoveryPlanSearchCriteria;
};


export type QueryDisasterRecoverySnapshotsArgs = {
  searchCriteria: DisasterRecoverySnapshotsSearchCriteria;
};


export type QueryUserArgs = {
  email: Scalars['String'];
};


export type QueryUsersArgs = {
  searchCriteria: UsersSearchCriteria;
};


export type QueryApiKeyArgs = {
  id: Scalars['ID'];
};


export type QueryApiKeysArgs = {
  searchCriteria: ApiKeysSearchCriteria;
};


export type QueryMyApiKeyArgs = {
  id: Scalars['ID'];
};


export type QueryMyApiKeysArgs = {
  searchCriteria: MyApiKeysSearchCriteria;
};

export type RemoveAccountInput = {
  /**   Customer ID */
  customerId: Scalars['String'];
  /**   ID of account to remove */
  accountId: Scalars['String'];
};

export type RemoveBackupPolicyInput = {
  customerId: Scalars['String'];
  policyId: Scalars['String'];
};

export type RemoveBackupSetupInput = {
  customerId: Scalars['String'];
  setupId: Scalars['String'];
};

export type RemoveDisasterRecoveryPlanInput = {
  customerId: Scalars['String'];
  planId: Scalars['String'];
};

export type RemoveNotificationGroupInput = {
  customerId: Scalars['String'];
  id: Scalars['String'];
};

export type RemoveTemplateInput = {
  customerId: Scalars['String'];
  templateId: Scalars['String'];
};

export type Report = {
  __typename?: 'Report';
  /**   Report ID */
  id: Scalars['String'];
  /**   URL to download the report */
  url?: Maybe<Scalars['String']>;
  /**   When the report was created */
  createdAt: Scalars['String'];
  /**   The datetime when last time the status was updated. */
  updatedAt: Scalars['String'];
  /**   Status of report creation. */
  status: ReportCreationStatus;
};

export enum ReportCreationStatus {
  InProgress = 'IN_PROGRESS',
  Success = 'SUCCESS',
  Failed = 'FAILED'
}

export type ReportInput = {
  /**   Customer ID for which the report is asked */
  customerId: Scalars['String'];
  /**   Report type to fetch. */
  reportType: CustomerReportType;
  /**   Report date, e.g. for monthly reports put "2020-06" */
  reportDate: Scalars['String'];
};

/**  The Resource type represents a resource known by the Backup. */
export type Resource = {
  __typename?: 'Resource';
  /**  Field used to uniquely identify the resource. */
  id: Scalars['String'];
  /**  User friendly name of the resource. */
  name: Scalars['String'];
  /**  Resource type such as aws-ec2, aws-efs, etc. */
  type: Scalars['String'];
  /**  ID of account generated by cloud provider. */
  accountCloudProviderId: Scalars['String'];
  /**  User friendly name of the account. */
  accountName: Scalars['String'];
  /**  Cloud provider from which the resource comes from. */
  cloudProvider: CloudProvider;
  /**
   *  User friendly name of applied backup setup.
   * @deprecated use backupSetup field
   */
  backupSetupName?: Maybe<Scalars['String']>;
  /**  Backup setup defining additional resource settings. */
  backupSetup?: Maybe<BackupSetup>;
  /**  Backup policy defining retention and RPO applied to the resource. */
  backupPolicy?: Maybe<BackupPolicy>;
  /**  Resource category such as virtual host, database, storage, etc. */
  category: Scalars['String'];
  /**  A boolean flag indicating if the resource is backed up */
  backupEnabled: Scalars['Boolean'];
  /**   Resource size in bytes */
  sizeInBytes?: Maybe<Scalars['String']>;
};

export type ResourceMapping = {
  __typename?: 'ResourceMapping';
  parameter: Scalars['String'];
  resourceId: Scalars['String'];
};

export type ResourceMappingInput = {
  parameter: Scalars['String'];
  resourceId: Scalars['String'];
};

export type ResourcesPage = {
  __typename?: 'ResourcesPage';
  data: Array<ResourceWithLastBackupStatus>;
  /**   Total number of pages with resources */
  totalNumberOfPages: Scalars['Int'];
  /**   Total number of resources */
  totalNumberOfResources: Scalars['Int'];
};

export type ResourcesSearchCriteria = {
  /**   Customer ID */
  customerId: Scalars['String'];
  /**   List of filters */
  filters: Array<FilterInput>;
  /**   Page number */
  pageNumber?: Maybe<Scalars['Int']>;
  /**   Show only removed resources */
  showRemoved?: Maybe<Scalars['Boolean']>;
};

/**  The ResourceWithLastBackupStatus contains information about a resource and its last backup operation status. */
export type ResourceWithLastBackupStatus = {
  __typename?: 'ResourceWithLastBackupStatus';
  /**  Field containing information about a resource. */
  resource: Resource;
  /**
   *  Field containing information about status of the last finished backup operation performed on a resource
   * described by the resource field. The lastBackupStatus is empty when there are no completed backup operations
   * perfored on the resource.
   */
  lastBackupStatus?: Maybe<LastBackupStatus>;
};

/**  The enumeration of supported retention conditions. */
export enum RetentionCondition {
  Quantity = 'QUANTITY',
  TimeInDays = 'TIME_IN_DAYS'
}

/**  The RetentionConfig type describes circumstances under which the backups should be removed. */
export type RetentionConfig = {
  __typename?: 'RetentionConfig';
  /**
   *  Condition determines if the snapshot should be removed after desired number of days or
   * when there are more snapshots than a user has specified.
   */
  condition: RetentionCondition;
  /**  Threshold value related with a condition. It may describe a number of days or number of snapshots. */
  threshold: Scalars['Int'];
};

/**  The RetentionConfig type describes circumstances under which the backups should be removed. */
export type RetentionConfigInput = {
  /**
   *  Condition determines if the snapshot should be removed after desired number of days or
   * when there are more snapshots than a user has specified.
   */
  condition: RetentionCondition;
  /**  Threshold value related with a condition. It may describe a number of days or number of snapshots. */
  threshold: Scalars['Int'];
};

export type RevokeCustomerRoleFromApiKeyInput = {
  /**   API key ID. */
  id: Scalars['String'];
  /**   Customer ID. */
  customerId: Scalars['ID'];
  /**   Role to be removed for given customer. */
  role: UserRole;
};

export type RevokeCustomerRoleFromUserInput = {
  /**   User email. */
  email: Scalars['String'];
  /**   Customer ID. */
  customerId: Scalars['ID'];
  /**   Role to be removed for given customer. */
  role: UserRole;
};

/**   The scan statuses */
export enum ScanStatus {
  Done = 'DONE',
  Pending = 'PENDING',
  Error = 'ERROR'
}

export type ScanStatusAccountInput = {
  customerId: Scalars['String'];
  accountId: Scalars['String'];
};

export type ScanStatusError = {
  __typename?: 'ScanStatusError';
  /**   Account id for which there is an error */
  accountId: Scalars['String'];
  /**   Cloud provider id (e.g. AWS Account ID) */
  cloudProviderId: Scalars['String'];
  /**   The error message */
  message: Scalars['String'];
};

export type ScanStatusResult = {
  __typename?: 'ScanStatusResult';
  /**   Scan job id */
  scanId: Scalars['String'];
  /**
   *  Can be 'pending' | 'error' (there was an error when scanning one of the
   * accounts) | 'done' (all accounts successfully scanned)
   */
  status: ScanStatus;
  /**  Optional field with error messages */
  errors?: Maybe<Array<ScanStatusError>>;
  /**   When the scan job was created */
  createdAt?: Maybe<Scalars['String']>;
  /**   When the status of scan job was updated */
  updatedAt?: Maybe<Scalars['String']>;
};

export type ScanStatusUpdateInput = {
  /**   Customer ID */
  customerId: Scalars['String'];
  /**   Scan job ID */
  scanId?: Maybe<Scalars['String']>;
  /**
   *  Can be 'pending' | 'error' (there was an error when scanning one of the
   * accounts) | 'done' (all accounts successfully scanned)
   */
  status?: Maybe<ScanStatus>;
  /**   When the scan job was created */
  createdAt?: Maybe<Scalars['String']>;
  /**   When the status of scan job was updated */
  updatedAt?: Maybe<Scalars['String']>;
};

export type ScanStatusUpdateResult = {
  __typename?: 'ScanStatusUpdateResult';
  /**   Customer ID */
  customerId: Scalars['String'];
  /**   Scan job ID */
  scanId?: Maybe<Scalars['String']>;
  /**
   *  Can be 'pending' | 'error' (there was an error when scanning one of the
   * accounts) | 'done' (all accounts successfully scanned)
   */
  status?: Maybe<ScanStatus>;
  /**   When the scan job was created */
  createdAt?: Maybe<Scalars['String']>;
  /**   When the status of scan job was updated */
  updatedAt?: Maybe<Scalars['String']>;
};

export type ScanTriggerResult = {
  __typename?: 'ScanTriggerResult';
  /**   Identifier of the scanning job */
  scanId: Scalars['String'];
};

export type SelfCreateApiKeyInput = {
  alias: Scalars['String'];
};

/**   A pair of resource type and identifier of a setup which should be assigned to resources of specified type. */
export type SetupToResourceTypeAssignment = {
  /**   Type of resources. */
  resourceType: Scalars['String'];
  /**
   *  Identifier of a setup which should be assigned to resources of specified type.
   * If omitted or equal to null, then backup setup will be detached from matched resources.
   * This will also cause backup to be disabled for selected resources.
   */
  setupId?: Maybe<Scalars['String']>;
};

export type SlackConfig = {
  __typename?: 'SlackConfig';
  channelName: Scalars['String'];
  webHook: Scalars['String'];
};

export type SlackConfigInput = {
  channelName: Scalars['String'];
  webHook: Scalars['String'];
};

export type Snapshot = {
  __typename?: 'Snapshot';
  id: Scalars['String'];
  accountId: Scalars['String'];
  creationTimestamp: Scalars['Int'];
  region: Scalars['String'];
  resourceId: Scalars['String'];
  /**   Blob containing information needed to restore resource from a snapshot */
  restoreData: Scalars['String'];
  type: SnapshotType;
};

/**  The SnapshotsRetention type describes how the backups should be retained. */
export type SnapshotsRetention = {
  __typename?: 'SnapshotsRetention';
  /**  Describes retention of snapshots stored in the protected accounts. */
  primarySnapshotsRetention: RetentionConfig;
  /**  Describes retention of snapshots stored in the vault accounts. */
  replicaSnapshotsRetention: RetentionConfig;
};

/**  The SnapshotsRetention type describes how the backups should be retained. */
export type SnapshotsRetentionInput = {
  /**  Describes retention of snapshots stored in the protected accounts. */
  primarySnapshotsRetention: RetentionConfigInput;
  /**  Describes retention of snapshots stored in the vault accounts. */
  replicaSnapshotsRetention: RetentionConfigInput;
};

export enum SnapshotType {
  Primary = 'PRIMARY',
  Replica = 'REPLICA'
}

export type StartDisasterRecoveryInput = {
  customerId: Scalars['String'];
  planId: Scalars['String'];
  /**   The date from snapshots should be restored (UNIX timestamp) */
  recoveryPointTimestamp: Scalars['Int'];
  assignee: Scalars['String'];
  restoreRegion: Scalars['String'];
  /**
   *   The kmsKey is used by AutoBackup to copy encrypted AWS snapshots to restore region.
   *  Following formats are correct: key ID (for example, key/1234abcd-12ab-34cd-56ef-1234567890ab),
   *  key alias (for example, alias/ExampleAlias), key ARN (for example,
   * arn:aws:kms:us-east-1:012345678910:key/abcd1234-a123-456a-a12b-a123b4cd56ef).
   */
  kmsKey?: Maybe<Scalars['String']>;
};

/**   Static AWS Credentials */
export type StaticAwsCredentialsInput = {
  accessKeyId: Scalars['String'];
  secretAccessKey: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  scanStatusChanged?: Maybe<ScanStatusUpdateResult>;
  newMessage?: Maybe<Message>;
};


export type SubscriptionScanStatusChangedArgs = {
  customerId?: Maybe<Scalars['String']>;
};


export type SubscriptionNewMessageArgs = {
  customerId: Scalars['String'];
};

export type SuccessRateHistoryEntry = {
  __typename?: 'SuccessRateHistoryEntry';
  numberOfResourcesMeetingRPO: Scalars['Int'];
  totalNumberOfResources: Scalars['Int'];
  startTimestamp: Scalars['Int'];
  backupEventId: Scalars['String'];
};

export type SummaryBackupsReport = {
  __typename?: 'SummaryBackupsReport';
  numberOfAllBackupAttempts: Scalars['Int'];
  numberOfSuccessfulBackups: Scalars['Int'];
  numberOfBackedUpResources: Scalars['Int'];
};

export type SummaryBackupsReportScope = {
  customerId: Scalars['String'];
  timeRange: TimeRange;
};

export type Tag = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type Template = {
  __typename?: 'Template';
  /**   Template ID */
  id: Scalars['String'];
  /**   Template Name */
  name: Scalars['String'];
  /**   Template Content */
  content: Scalars['String'];
  /**   Cloud provider */
  cloudProvider: CloudProvider;
  /**   Parameters */
  parameters?: Maybe<Array<Scalars['String']>>;
};

export type TemplateLink = {
  __typename?: 'TemplateLink';
  /**   Template name */
  templateName: Scalars['String'];
  /**   Link to perform Disaster Recovery */
  link: Scalars['String'];
};

export type TemplateResourcesMapping = {
  __typename?: 'TemplateResourcesMapping';
  template: Template;
  resourcesMapping?: Maybe<Array<ResourceMapping>>;
};

export type TemplateResourcesMappingInput = {
  templateId: Scalars['String'];
  resourcesMapping?: Maybe<Array<ResourceMappingInput>>;
};

export type TemplateSearchCriteria = {
  /**   Customer ID for which the templates is asked */
  customerId: Scalars['String'];
  /**   Template ID */
  templateId: Scalars['String'];
};

export type TemplatesPage = {
  __typename?: 'TemplatesPage';
  data: Array<Template>;
  totalNumberOfTemplates: Scalars['Int'];
  totalNumberOfPages: Scalars['Int'];
};

export type TemplatesSearchCriteria = {
  /**   Customer ID for which the templates are asked */
  customerId: Scalars['String'];
  /**   Template's cloud provider - optional filter. Returns only templates with specified cloud provider. */
  cloudProvider?: Maybe<CloudProvider>;
  pageNumber?: Maybe<Scalars['Int']>;
};

/**  The Policy Settings type represents the schedule of retention settings */
export type TimeAwarePolicySettings = {
  __typename?: 'TimeAwarePolicySettings';
  /**  The cron expression determines in what time intervals the backup has to execute. */
  cronExpression: Scalars['String'];
  /**  The start date determines in what date the first backup has to begin. UNIX timestamp format. */
  startDateTimestamp?: Maybe<Scalars['Int']>;
  /**  Backups window duration time setting. */
  timeWindowDuration: Scalars['Int'];
  /**  Backups retention settings. */
  retention: SnapshotsRetention;
};

/**  The Policy Settings type represents the schedule of retention settings */
export type TimeAwarePolicySettingsInput = {
  /**  The cron expression determines in what time intervals the backup has to execute. */
  cronExpression: Scalars['String'];
  /**  The start date determines in what date the first backup has to begin. UNIX timestamp format. */
  startDateTimestamp?: Maybe<Scalars['Int']>;
  /**  Backups window duration time setting. */
  timeWindowDuration: Scalars['Int'];
  /**  Backups retention settings. */
  retention: SnapshotsRetentionInput;
};

export type TimeRange = {
  dateFromTimestamp: Scalars['Int'];
  dateToTimestamp: Scalars['Int'];
};

/**   Trigger backup for a set of resources (given by 'resourceIDs') or resources selected by 'filters'. */
export type TriggerBackupInput = {
  /**   Customer ID from AutoBackup. */
  customerId: Scalars['String'];
  /**   Filters determine which resources will be backed up. */
  filters: Array<FilterInput>;
  /**
   *  Optional filter containing a list of resource identifiers for which the backup should be triggered.
   * Note that triggering backup will affect only resources with matching identifiers AND matching criteria
   * defined in filters field.
   */
  resourceIDs?: Maybe<Array<Scalars['String']>>;
};

export type TriggerScanInput = {
  /**   Customer ID */
  customerId: Scalars['String'];
};

export type TriggerScanOnCloudAccountInput = {
  customerId: Scalars['String'];
  /**
   *  Account id for which the scan is triggered (format: <provider>__<customer_id>__<cloud provider id>)
   * (example: AWS__d6327173181__01234567891)
   */
  accountId: Scalars['String'];
};

export type UpdateBackupSetupInput = {
  /**   Backup setup id */
  id: Scalars['String'];
  /**   Customer ID */
  customerId: Scalars['String'];
  /**   Name of backup setup */
  name: Scalars['String'];
  /**   Prehook setup (optional) */
  preHook?: Maybe<HookInput>;
  /**   Posthook setup (optional) */
  postHook?: Maybe<HookInput>;
  /**   List of backup settings */
  backupSettings: Array<BackupSettingInput>;
};

export type UpdateDisasterRecoveryPlanInput = {
  /**   Plan ID */
  id: Scalars['String'];
  /**   Customer ID */
  customerId: Scalars['String'];
  /**   Plan name */
  name: Scalars['String'];
  /**   Region where DR testing should be performed (creating AMI, moving Snapshots). */
  restoreRegion: Scalars['String'];
  /**   Plan start date (UNIX timestamp) */
  startTimestamp: Scalars['Int'];
  /**   Frequency of disaster recovery plan */
  frequencyInMonths: Scalars['Int'];
  /**   How many days before notification should be sent */
  remindDaysBefore: Scalars['Int'];
  /**   Attached templates */
  templatesResourcesMapping?: Maybe<Array<TemplateResourcesMappingInput>>;
  /**   Notification recipients */
  emails: Array<Scalars['String']>;
  /**
   *   The kmsKey is used by AutoBackup to copy encrypted AWS snapshots to restore region.
   *  Following formats are correct: key ID (for example, key/1234abcd-12ab-34cd-56ef-1234567890ab),
   *  key alias (for example, alias/ExampleAlias), key ARN (for example,
   * arn:aws:kms:us-east-1:012345678910:key/abcd1234-a123-456a-a12b-a123b4cd56ef).
   */
  kmsKey?: Maybe<Scalars['String']>;
};

export type UpdateDisasterRecoveryRecordInput = {
  id: Scalars['String'];
  customerId: Scalars['String'];
  status: UpdateDisasterRecoveryStatus;
  /**
   *  Note field could be used by user to provide additional information about Disaster recovery, for example: error message,
   * link to documents.
   */
  note: Scalars['String'];
};

/**   Available status during updates of the disaster recovery record. */
export enum UpdateDisasterRecoveryStatus {
  Success = 'SUCCESS',
  Failed = 'FAILED'
}

export type UpdateTemplateInput = {
  /**   Template ID */
  id: Scalars['String'];
  /**   Customer ID */
  customerId: Scalars['String'];
  /**   Template Name */
  name: Scalars['String'];
  /**   Template Content */
  content: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  email: Scalars['String'];
  superAdmin: Scalars['Boolean'];
  customerAccesses: CustomerAccessesPage;
};


export type UserCustomerAccessesArgs = {
  searchCriteria?: Maybe<UserCustomerAccessesSearchCriteria>;
};

export type UserCustomerAccessesSearchCriteria = {
  /**   If no page number is provided, the first one will be returned. */
  pageNumber?: Maybe<Scalars['Int']>;
  /**   If no customer ID is provided, all customer accesses will be returned. */
  customerId?: Maybe<Scalars['ID']>;
};

export type UserProfile = {
  __typename?: 'UserProfile';
  companyName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  bestAllowedPolicySettings: BestAllowedPolicySettings;
  isBackupControlledByTags: Scalars['Boolean'];
};

export type UserProfileInput = {
  customerId: Scalars['String'];
  isBackupControlledByTags: Scalars['Boolean'];
};

/**  The UserRole is an enum listing roles supported by AutoBackup. */
export enum UserRole {
  SuperAdmin = 'SUPER_ADMIN',
  Admin = 'ADMIN',
  ReadOnly = 'READ_ONLY'
}

export type UsersPage = {
  __typename?: 'UsersPage';
  data: Array<User>;
  totalNumberOfPages: Scalars['Int'];
  totalNumberOfUsers: Scalars['Int'];
};

export type UsersSearchCriteria = {
  /**   If no page number is provided, the first one will be returned. */
  pageNumber?: Maybe<Scalars['Int']>;
  /**   If no customer ID is provided, all customer accesses will be returned. */
  customerId?: Maybe<Scalars['ID']>;
  /**   If no email is provided, all users will be returned. You can pass whole email or part of it. */
  email?: Maybe<Scalars['String']>;
};

export type ValidateTemplateInput = {
  cloudProvider: CloudProvider;
  content: Scalars['String'];
};

export type ValidateTemplateResult = {
  __typename?: 'ValidateTemplateResult';
  parameters: Array<Scalars['String']>;
};

export type ChangeBackupPolicyAndSetupsMutationVariables = Exact<{
  input: ChangeBackupPolicyAndSetupsInput;
}>;


export type ChangeBackupPolicyAndSetupsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'changeBackupPolicyAndSetups'>
);

export type ChangeBackupStateMutationVariables = Exact<{
  input: ChangeBackupStateInput;
}>;


export type ChangeBackupStateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'changeBackupState'>
);

export type CreateAccountAwsMutationVariables = Exact<{
  input: CreateAccountAwsInput;
}>;


export type CreateAccountAwsMutation = (
  { __typename?: 'Mutation' }
  & { createAccountAws: (
    { __typename?: 'AccountAccessRoleAws' }
    & Pick<AccountAccessRoleAws, 'accountId'>
  ) }
);

export type CreateAccountAwsChinaMutationVariables = Exact<{
  input: CreateAccountAwsChinaInput;
}>;


export type CreateAccountAwsChinaMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createAccountAwsChina'>
);

export type CreateAccountAzureMutationVariables = Exact<{
  input: CreateAccountAzureInput;
}>;


export type CreateAccountAzureMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createAccountAzure'>
);

export type CreateAccountGcpMutationVariables = Exact<{
  input: CreateAccountGcpInput;
}>;


export type CreateAccountGcpMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createAccountGcp'>
);

export type CreateAccountIbmCloudMutationVariables = Exact<{
  input: CreateAccountIbmCloudInput;
}>;


export type CreateAccountIbmCloudMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createAccountIbmCloud'>
);

export type CreateApiKeyMutationVariables = Exact<{
  input: CreateApiKeyInput;
}>;


export type CreateApiKeyMutation = (
  { __typename?: 'Mutation' }
  & { createApiKey: (
    { __typename?: 'CreateApiKeyResult' }
    & Pick<CreateApiKeyResult, 'apiKeyString'>
    & { apiKey: (
      { __typename?: 'ApiKey' }
      & Pick<ApiKey, 'id' | 'alias' | 'owner' | 'lastUsedAt' | 'createdAt' | 'createdBy'>
    ) }
  ) }
);

export type CreateBackupPolicyMutationVariables = Exact<{
  input: CreateBackupPolicyInput;
}>;


export type CreateBackupPolicyMutation = (
  { __typename?: 'Mutation' }
  & { createBackupPolicy?: Maybe<(
    { __typename?: 'BackupPolicy' }
    & Pick<BackupPolicy, 'id'>
  )> }
);

export type CreateBackupSetupMutationVariables = Exact<{
  input: BackupSetupInput;
}>;


export type CreateBackupSetupMutation = (
  { __typename?: 'Mutation' }
  & { createBackupSetup?: Maybe<(
    { __typename?: 'BackupSetup' }
    & Pick<BackupSetup, 'id'>
  )> }
);

export type CreateDisasterRecoveryPlanMutationVariables = Exact<{
  input: CreateDisasterRecoveryPlanInput;
}>;


export type CreateDisasterRecoveryPlanMutation = (
  { __typename?: 'Mutation' }
  & { createDisasterRecoveryPlan?: Maybe<(
    { __typename?: 'DisasterRecoveryPlan' }
    & Pick<DisasterRecoveryPlan, 'id'>
  )> }
);

export type CreateNotificationGroupMutationVariables = Exact<{
  input: NotificationGroupInput;
}>;


export type CreateNotificationGroupMutation = (
  { __typename?: 'Mutation' }
  & { createNotificationGroup?: Maybe<(
    { __typename?: 'NotificationGroup' }
    & Pick<NotificationGroup, 'name' | 'severities' | 'frequency'>
    & { channels: Array<(
      { __typename: 'EmailConfig' }
      & Pick<EmailConfig, 'address'>
    ) | (
      { __typename: 'SlackConfig' }
      & Pick<SlackConfig, 'channelName' | 'webHook'>
    ) | (
      { __typename: 'PagerDutyConfig' }
      & Pick<PagerDutyConfig, 'routingKey'>
    )> }
  )> }
);

export type CreateTemplateMutationVariables = Exact<{
  input: CreateTemplateInput;
}>;


export type CreateTemplateMutation = (
  { __typename?: 'Mutation' }
  & { createTemplate?: Maybe<(
    { __typename?: 'Template' }
    & Pick<Template, 'id'>
  )> }
);

export type CreateUserMutationVariables = Exact<{
  input: AddUserInput;
}>;


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { addUser: (
    { __typename?: 'User' }
    & Pick<User, 'email' | 'superAdmin'>
    & { customerAccesses: (
      { __typename?: 'CustomerAccessesPage' }
      & Pick<CustomerAccessesPage, 'totalNumberOfCustomers' | 'totalNumberOfPages'>
      & { data: Array<Maybe<(
        { __typename?: 'CustomerAccess' }
        & Pick<CustomerAccess, 'roles'>
        & { customer: (
          { __typename?: 'CustomerBasic' }
          & Pick<CustomerBasic, 'id' | 'name'>
        ) }
      )>> }
    ) }
  ) }
);

export type DisasterRecoveryRecordsReportQueryVariables = Exact<{
  input: DisasterRecoveryRecordsReportInput;
}>;


export type DisasterRecoveryRecordsReportQuery = (
  { __typename?: 'Query' }
  & { disasterRecoveryRecordsReport: (
    { __typename?: 'DisasterRecoveryRecordsReport' }
    & Pick<DisasterRecoveryRecordsReport, 'data'>
  ) }
);

export type DismissMessageMutationVariables = Exact<{
  input: DismissMessageInput;
}>;


export type DismissMessageMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'dismissMessage'>
);

export type GetAccessibleCustomersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAccessibleCustomersQuery = (
  { __typename?: 'Query' }
  & { accessibleCustomers: Array<(
    { __typename?: 'Customer' }
    & Pick<Customer, 'id' | 'name' | 'role'>
  )> }
);

export type GetAccountsQueryVariables = Exact<{
  searchCriteria: AccountsSearchCriteria;
}>;


export type GetAccountsQuery = (
  { __typename?: 'Query' }
  & { accounts: (
    { __typename?: 'AccountsPage' }
    & { data: Array<(
      { __typename?: 'Account' }
      & Pick<Account, 'id' | 'name' | 'type' | 'cloudProvider' | 'cloudProviderId'>
    )> }
  ) }
);

export type GetApiKeyQueryVariables = Exact<{
  id: Scalars['ID'];
  customerSearchCriteria?: Maybe<ApiKeyCustomerAccessesSearchCriteria>;
}>;


export type GetApiKeyQuery = (
  { __typename?: 'Query' }
  & { apiKey: (
    { __typename?: 'ApiKey' }
    & Pick<ApiKey, 'alias' | 'createdAt' | 'createdBy' | 'id' | 'lastUsedAt' | 'owner'>
    & { customerAccesses: (
      { __typename?: 'CustomerAccessesPage' }
      & Pick<CustomerAccessesPage, 'totalNumberOfCustomers' | 'totalNumberOfPages'>
      & { data: Array<Maybe<(
        { __typename?: 'CustomerAccess' }
        & Pick<CustomerAccess, 'roles'>
        & { customer: (
          { __typename?: 'CustomerBasic' }
          & Pick<CustomerBasic, 'id' | 'name'>
          & { backupPoliciesBoundary: (
            { __typename?: 'BackupPoliciesBoundary' }
            & Pick<BackupPoliciesBoundary, 'minRPOInHours' | 'maxPrimarySnapshotRetentionInDays' | 'maxPrimarySnapshotRetentionQuantity' | 'maxReplicaSnapshotRetentionInDays' | 'maxReplicaSnapshotRetentionQuantity'>
          ) }
        ) }
      )>> }
    ) }
  ) }
);

export type GetApiKeysQueryVariables = Exact<{
  searchCriteria: ApiKeysSearchCriteria;
}>;


export type GetApiKeysQuery = (
  { __typename?: 'Query' }
  & { apiKeys: (
    { __typename?: 'ApiKeysPage' }
    & Pick<ApiKeysPage, 'totalNumberOfPages' | 'totalNumberOfApiKeys'>
    & { data: Array<(
      { __typename?: 'ApiKey' }
      & Pick<ApiKey, 'id' | 'alias' | 'owner' | 'createdAt' | 'createdBy' | 'lastUsedAt'>
      & { customerAccesses: (
        { __typename?: 'CustomerAccessesPage' }
        & Pick<CustomerAccessesPage, 'totalNumberOfCustomers' | 'totalNumberOfPages'>
        & { data: Array<Maybe<(
          { __typename?: 'CustomerAccess' }
          & Pick<CustomerAccess, 'roles'>
          & { customer: (
            { __typename?: 'CustomerBasic' }
            & Pick<CustomerBasic, 'id' | 'name'>
            & { backupPoliciesBoundary: (
              { __typename?: 'BackupPoliciesBoundary' }
              & Pick<BackupPoliciesBoundary, 'minRPOInHours' | 'maxPrimarySnapshotRetentionInDays' | 'maxPrimarySnapshotRetentionQuantity' | 'maxReplicaSnapshotRetentionInDays' | 'maxReplicaSnapshotRetentionQuantity'>
            ) }
          ) }
        )>> }
      ) }
    )> }
  ) }
);

export type GetAwsAccountAccessRoleTemplateQueryVariables = Exact<{
  input: AccountAwsAccessRoleTemplateInput;
}>;


export type GetAwsAccountAccessRoleTemplateQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'awsAccountAccessRoleTemplate'>
);

export type GetAwsChinaAccountAccessRoleTemplateQueryVariables = Exact<{
  input: AccountAwsChinaAccessRoleTemplateInput;
}>;


export type GetAwsChinaAccountAccessRoleTemplateQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'awsChinaAccountAccessRoleTemplate'>
);

export type GetBackupEventQueryVariables = Exact<{
  searchCriteria: BackupEventSearchCriteria;
}>;


export type GetBackupEventQuery = (
  { __typename?: 'Query' }
  & { backupEvent?: Maybe<(
    { __typename?: 'BackupEvent' }
    & Pick<BackupEvent, 'id' | 'planName' | 'totalNumberOfResources' | 'numberOfBackedUpResources' | 'startTimestamp' | 'progress' | 'status' | 'type'>
  )> }
);

export type GetBackupEventResourcesQueryVariables = Exact<{
  searchCriteria: BackupEventResourcesSearchCriteria;
}>;


export type GetBackupEventResourcesQuery = (
  { __typename?: 'Query' }
  & { backupEventResources: (
    { __typename?: 'BackupEventResourcesPage' }
    & Pick<BackupEventResourcesPage, 'totalNumberOfResources' | 'totalNumberOfPages'>
    & { data: Array<(
      { __typename?: 'BackupEventResource' }
      & Pick<BackupEventResource, 'progress' | 'status' | 'failureReason' | 'replicaFailureReason'>
      & { resource: (
        { __typename?: 'Resource' }
        & Pick<Resource, 'id' | 'name' | 'type' | 'accountCloudProviderId' | 'accountName' | 'cloudProvider' | 'backupSetupName' | 'category' | 'backupEnabled'>
        & { backupPolicy?: Maybe<(
          { __typename?: 'BackupPolicy' }
          & Pick<BackupPolicy, 'name'>
        )> }
      ), snapshot?: Maybe<(
        { __typename?: 'Snapshot' }
        & Pick<Snapshot, 'id' | 'accountId' | 'region'>
      )>, snapshotReplica?: Maybe<(
        { __typename?: 'Snapshot' }
        & Pick<Snapshot, 'id' | 'accountId' | 'region'>
      )> }
    )> }
  ) }
);

export type GetBackupEventStatsQueryVariables = Exact<{
  searchCriteria: BackupEventStatsSearchCriteria;
}>;


export type GetBackupEventStatsQuery = (
  { __typename?: 'Query' }
  & { backupEventStats: (
    { __typename?: 'BackupEventStats' }
    & Pick<BackupEventStats, 'numberOfResourcesMeetingRPO' | 'numberOfBackedUpResources' | 'totalNumberOfResources'>
    & { successRateHistory: Array<(
      { __typename?: 'SuccessRateHistoryEntry' }
      & Pick<SuccessRateHistoryEntry, 'numberOfResourcesMeetingRPO' | 'totalNumberOfResources' | 'startTimestamp' | 'backupEventId'>
    )> }
  ) }
);

export type GetBackupEventsQueryVariables = Exact<{
  searchCriteria: BackupEventsSearchCriteria;
}>;


export type GetBackupEventsQuery = (
  { __typename?: 'Query' }
  & { backupEvents: (
    { __typename?: 'BackupEventsPage' }
    & Pick<BackupEventsPage, 'totalNumberOfPages' | 'totalNumberOfEvents'>
    & { data: Array<(
      { __typename?: 'BackupEvent' }
      & Pick<BackupEvent, 'id' | 'planName' | 'totalNumberOfResources' | 'numberOfBackedUpResources' | 'startTimestamp' | 'progress' | 'status' | 'type'>
    )> }
  ) }
);

export type GetBackupHistoryQueryVariables = Exact<{
  customerId: Scalars['String'];
}>;


export type GetBackupHistoryQuery = (
  { __typename?: 'Query' }
  & { backupHistory?: Maybe<Array<(
    { __typename?: 'HistoryEntry' }
    & Pick<HistoryEntry, 'eventCount' | 'month'>
  )>> }
);

export type GetBackupPoliciesQueryVariables = Exact<{
  customerId: Scalars['String'];
}>;


export type GetBackupPoliciesQuery = (
  { __typename?: 'Query' }
  & { backupPolicies: Array<(
    { __typename?: 'BackupPolicy' }
    & Pick<BackupPolicy, 'id' | 'name' | 'type' | 'tagValue'>
    & { policySettings?: Maybe<Array<(
      { __typename?: 'PolicySettings' }
      & Pick<PolicySettings, 'rpo' | 'frequency'>
      & { retention: (
        { __typename?: 'SnapshotsRetention' }
        & { primarySnapshotsRetention: (
          { __typename?: 'RetentionConfig' }
          & Pick<RetentionConfig, 'condition' | 'threshold'>
        ), replicaSnapshotsRetention: (
          { __typename?: 'RetentionConfig' }
          & Pick<RetentionConfig, 'condition' | 'threshold'>
        ) }
      ) }
    )>>, timeAwarePolicySettings?: Maybe<(
      { __typename?: 'TimeAwarePolicySettings' }
      & Pick<TimeAwarePolicySettings, 'cronExpression' | 'startDateTimestamp' | 'timeWindowDuration'>
      & { retention: (
        { __typename?: 'SnapshotsRetention' }
        & { primarySnapshotsRetention: (
          { __typename?: 'RetentionConfig' }
          & Pick<RetentionConfig, 'condition' | 'threshold'>
        ), replicaSnapshotsRetention: (
          { __typename?: 'RetentionConfig' }
          & Pick<RetentionConfig, 'condition' | 'threshold'>
        ) }
      ) }
    )> }
  )> }
);

export type GetBackupPolicyQueryVariables = Exact<{
  searchCriteria: BackupPolicySearchCriteria;
}>;


export type GetBackupPolicyQuery = (
  { __typename?: 'Query' }
  & { backupPolicy?: Maybe<(
    { __typename?: 'BackupPolicy' }
    & Pick<BackupPolicy, 'id' | 'name' | 'type' | 'tagValue' | 'removePrimarySnapshotsAfterResourceDeletion' | 'removeReplicaSnapshotsAfterResourceDeletion'>
    & { policySettings?: Maybe<Array<(
      { __typename?: 'PolicySettings' }
      & Pick<PolicySettings, 'rpo' | 'frequency'>
      & { retention: (
        { __typename?: 'SnapshotsRetention' }
        & { primarySnapshotsRetention: (
          { __typename?: 'RetentionConfig' }
          & Pick<RetentionConfig, 'condition' | 'threshold'>
        ), replicaSnapshotsRetention: (
          { __typename?: 'RetentionConfig' }
          & Pick<RetentionConfig, 'condition' | 'threshold'>
        ) }
      ) }
    )>>, timeAwarePolicySettings?: Maybe<(
      { __typename?: 'TimeAwarePolicySettings' }
      & Pick<TimeAwarePolicySettings, 'cronExpression' | 'startDateTimestamp' | 'timeWindowDuration'>
      & { retention: (
        { __typename?: 'SnapshotsRetention' }
        & { primarySnapshotsRetention: (
          { __typename?: 'RetentionConfig' }
          & Pick<RetentionConfig, 'condition' | 'threshold'>
        ), replicaSnapshotsRetention: (
          { __typename?: 'RetentionConfig' }
          & Pick<RetentionConfig, 'condition' | 'threshold'>
        ) }
      ) }
    )> }
  )> }
);

export type GetBackupSetupQueryVariables = Exact<{
  searchCriteria: BackupSetupSearchCriteria;
}>;


export type GetBackupSetupQuery = (
  { __typename?: 'Query' }
  & { backupSetup?: Maybe<(
    { __typename?: 'BackupSetup' }
    & Pick<BackupSetup, 'id' | 'customerId' | 'name' | 'tagValue' | 'resourceType'>
    & { preHook?: Maybe<(
      { __typename?: 'Hook' }
      & Pick<Hook, 'type' | 'endpoint'>
    )>, postHook?: Maybe<(
      { __typename?: 'Hook' }
      & Pick<Hook, 'type' | 'endpoint'>
    )>, backupSettings: Array<(
      { __typename?: 'BackupSetting' }
      & Pick<BackupSetting, 'id' | 'name' | 'type' | 'label' | 'description' | 'value'>
    )> }
  )> }
);

export type GetBackupSetupsQueryVariables = Exact<{
  searchCriteria: BackupSetupsSearchCriteria;
}>;


export type GetBackupSetupsQuery = (
  { __typename?: 'Query' }
  & { backupSetups: Array<(
    { __typename?: 'BackupSetup' }
    & Pick<BackupSetup, 'id' | 'name' | 'tagValue' | 'resourceType'>
    & { preHook?: Maybe<(
      { __typename?: 'Hook' }
      & Pick<Hook, 'type' | 'endpoint'>
    )>, postHook?: Maybe<(
      { __typename?: 'Hook' }
      & Pick<Hook, 'type' | 'endpoint'>
    )>, backupSettings: Array<(
      { __typename?: 'BackupSetting' }
      & Pick<BackupSetting, 'id' | 'label' | 'description' | 'value' | 'type'>
    )> }
  )> }
);

export type GetBackupTagNamesQueryVariables = Exact<{
  customerId: Scalars['String'];
}>;


export type GetBackupTagNamesQuery = (
  { __typename?: 'Query' }
  & { backupTagNames?: Maybe<(
    { __typename?: 'BackupTagNames' }
    & Pick<BackupTagNames, 'backupEnabledTagNames' | 'policyTagNames' | 'setupTagNames'>
  )> }
);

export type GetBestAllowedPolicySettingsQueryVariables = Exact<{
  customerId: Scalars['String'];
}>;


export type GetBestAllowedPolicySettingsQuery = (
  { __typename?: 'Query' }
  & { bestAllowedPolicySettings?: Maybe<(
    { __typename?: 'BestAllowedPolicySettings' }
    & Pick<BestAllowedPolicySettings, 'minRPOInHours' | 'maxPrimarySnapshotRetentionQuantity' | 'maxPrimarySnapshotRetentionInDays' | 'maxReplicaSnapshotRetentionQuantity' | 'maxReplicaSnapshotRetentionInDays'>
  )> }
);

export type GetCategoriesWithResourcesMappingQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCategoriesWithResourcesMappingQuery = (
  { __typename?: 'Query' }
  & { categoriesWithResourcesMapping: Array<(
    { __typename?: 'CategoriesWithResouces' }
    & Pick<CategoriesWithResouces, 'category' | 'resources'>
  )> }
);

export type GetCustomerResourcesQueryVariables = Exact<{
  searchCriteria: ResourcesSearchCriteria;
}>;


export type GetCustomerResourcesQuery = (
  { __typename?: 'Query' }
  & { resources: (
    { __typename?: 'ResourcesPage' }
    & Pick<ResourcesPage, 'totalNumberOfPages' | 'totalNumberOfResources'>
    & { data: Array<(
      { __typename?: 'ResourceWithLastBackupStatus' }
      & { resource: (
        { __typename?: 'Resource' }
        & Pick<Resource, 'id' | 'name' | 'type' | 'accountCloudProviderId' | 'accountName' | 'cloudProvider' | 'backupSetupName' | 'category' | 'backupEnabled'>
        & { backupPolicy?: Maybe<(
          { __typename?: 'BackupPolicy' }
          & Pick<BackupPolicy, 'id' | 'name'>
          & { settings?: Maybe<(
            { __typename?: 'BackupPolicySettings' }
            & Pick<BackupPolicySettings, 'rpoInHours'>
            & { retention: (
              { __typename?: 'SnapshotsRetention' }
              & { primarySnapshotsRetention: (
                { __typename?: 'RetentionConfig' }
                & Pick<RetentionConfig, 'condition' | 'threshold'>
              ), replicaSnapshotsRetention: (
                { __typename?: 'RetentionConfig' }
                & Pick<RetentionConfig, 'condition' | 'threshold'>
              ) }
            ) }
          )> }
        )> }
      ) }
    )> }
  ) }
);

export type GetCustomersListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCustomersListQuery = (
  { __typename?: 'Query' }
  & { customersList: Array<(
    { __typename?: 'CustomerBasic' }
    & Pick<CustomerBasic, 'id' | 'name'>
  )> }
);

export type GetDisasterRecoveryPlanQueryVariables = Exact<{
  searchCriteria: DisasterRecoveryPlanSearchCriteria;
}>;


export type GetDisasterRecoveryPlanQuery = (
  { __typename?: 'Query' }
  & { disasterRecoveryPlan?: Maybe<(
    { __typename?: 'DisasterRecoveryPlan' }
    & Pick<DisasterRecoveryPlan, 'id' | 'customerId' | 'name' | 'cloudProvider' | 'restoreRegion' | 'startTimestamp' | 'frequencyInMonths' | 'remindDaysBefore' | 'emails' | 'kmsKey'>
    & { templatesResourcesMapping?: Maybe<Array<(
      { __typename?: 'TemplateResourcesMapping' }
      & { template: (
        { __typename?: 'Template' }
        & Pick<Template, 'id' | 'name'>
      ), resourcesMapping?: Maybe<Array<(
        { __typename?: 'ResourceMapping' }
        & Pick<ResourceMapping, 'parameter' | 'resourceId'>
      )>> }
    )>> }
  )> }
);

export type GetDisasterRecoveryPlansQueryVariables = Exact<{
  searchCriteria: DisasterRecoveryPlansSearchCriteria;
}>;


export type GetDisasterRecoveryPlansQuery = (
  { __typename?: 'Query' }
  & { disasterRecoveryPlans: (
    { __typename?: 'DisasterRecoveryPlansPage' }
    & Pick<DisasterRecoveryPlansPage, 'totalNumberOfPlans' | 'totalNumberOfPages'>
    & { data: Array<(
      { __typename?: 'DisasterRecoveryPlan' }
      & Pick<DisasterRecoveryPlan, 'id' | 'name' | 'cloudProvider' | 'restoreRegion' | 'startTimestamp' | 'frequencyInMonths' | 'remindDaysBefore' | 'emails'>
      & { templatesResourcesMapping?: Maybe<Array<(
        { __typename?: 'TemplateResourcesMapping' }
        & { template: (
          { __typename?: 'Template' }
          & Pick<Template, 'id' | 'name'>
        ) }
      )>> }
    )> }
  ) }
);

export type GetDisasterRecoveryRecordsQueryVariables = Exact<{
  searchCriteria: DisasterRecoveryRecordsSearchCriteria;
}>;


export type GetDisasterRecoveryRecordsQuery = (
  { __typename?: 'Query' }
  & { disasterRecoveryRecords: (
    { __typename?: 'DisasterRecoveryRecordsPage' }
    & Pick<DisasterRecoveryRecordsPage, 'totalNumberOfRecords' | 'totalNumberOfPages'>
    & { data: Array<(
      { __typename?: 'DisasterRecoveryRecord' }
      & Pick<DisasterRecoveryRecord, 'id' | 'startTimestamp' | 'finishTimestamp' | 'status' | 'assignee' | 'restoreRegion' | 'errorMessages' | 'note'>
      & { plan: (
        { __typename?: 'DisasterRecoveryPlan' }
        & Pick<DisasterRecoveryPlan, 'name' | 'cloudProvider'>
      ), links?: Maybe<Array<(
        { __typename?: 'TemplateLink' }
        & Pick<TemplateLink, 'templateName' | 'link'>
      )>> }
    )> }
  ) }
);

export type GetDisasterRecoverySnapshotsQueryVariables = Exact<{
  searchCriteria: DisasterRecoverySnapshotsSearchCriteria;
}>;


export type GetDisasterRecoverySnapshotsQuery = (
  { __typename?: 'Query' }
  & { disasterRecoverySnapshots?: Maybe<Array<(
    { __typename?: 'Snapshot' }
    & Pick<Snapshot, 'id' | 'creationTimestamp' | 'resourceId'>
  )>> }
);

export type GetExternallyBackedUpResourcesQueryVariables = Exact<{
  searchCriteria: ExternallyBackedUpResourcesSearchCriteria;
}>;


export type GetExternallyBackedUpResourcesQuery = (
  { __typename?: 'Query' }
  & { externallyBackedUpResources: (
    { __typename?: 'ExternallyBackedUpResourcesPage' }
    & Pick<ExternallyBackedUpResourcesPage, 'totalNumberOfPages' | 'totalNumberOfResources'>
    & { data: Array<(
      { __typename?: 'ExternallyBackedUpResource' }
      & Pick<ExternallyBackedUpResource, 'id' | 'backupEnabled' | 'cloudProvider' | 'updatedAtTimestamp' | 'rpoInHours' | 'lastBackupStatus' | 'lastBackupErrorMessage'>
    )> }
  ) }
);

export type GetFiltersQueryVariables = Exact<{
  searchCriteria: FiltersSearchCriteria;
}>;


export type GetFiltersQuery = (
  { __typename?: 'Query' }
  & { filters: Array<(
    { __typename?: 'Filter' }
    & Pick<Filter, 'id' | 'name' | 'type'>
    & { values?: Maybe<Array<(
      { __typename?: 'FilterValue' }
      & Pick<FilterValue, 'label' | 'value'>
    )>> }
  )> }
);

export type GetLatestResourceSnapshotQueryVariables = Exact<{
  searchCriteria: LatestResourceSnapshotSearchCriteria;
}>;


export type GetLatestResourceSnapshotQuery = (
  { __typename?: 'Query' }
  & { latestResourceSnapshot?: Maybe<(
    { __typename?: 'Snapshot' }
    & Pick<Snapshot, 'id' | 'accountId' | 'region' | 'restoreData'>
  )> }
);

export type MatchedResourcesTypesQueryVariables = Exact<{
  searchCriteria: MatchedResourcesTypesSearchCriteria;
}>;


export type MatchedResourcesTypesQuery = (
  { __typename?: 'Query' }
  & { matchedResourcesTypes: Array<(
    { __typename?: 'MatchedResourcesType' }
    & Pick<MatchedResourcesType, 'resourceTypeName' | 'numberOfResources'>
  )> }
);

export type GetMessagesQueryVariables = Exact<{
  searchCriteria: MessagesSearchCriteria;
}>;


export type GetMessagesQuery = (
  { __typename?: 'Query' }
  & { messages: (
    { __typename?: 'MessagesPage' }
    & Pick<MessagesPage, 'nextPageCursor'>
    & { data: Array<(
      { __typename?: 'Message' }
      & Pick<Message, 'id' | 'message' | 'type' | 'category'>
    )> }
  ) }
);

export type GetMyApiKeyQueryVariables = Exact<{
  id: Scalars['ID'];
  customerSearchCriteria?: Maybe<ApiKeyCustomerAccessesSearchCriteria>;
}>;


export type GetMyApiKeyQuery = (
  { __typename?: 'Query' }
  & { myApiKey: (
    { __typename?: 'ApiKey' }
    & Pick<ApiKey, 'alias' | 'createdAt' | 'createdBy' | 'id' | 'lastUsedAt' | 'owner'>
    & { customerAccesses: (
      { __typename?: 'CustomerAccessesPage' }
      & Pick<CustomerAccessesPage, 'totalNumberOfCustomers' | 'totalNumberOfPages'>
      & { data: Array<Maybe<(
        { __typename?: 'CustomerAccess' }
        & Pick<CustomerAccess, 'roles'>
        & { customer: (
          { __typename?: 'CustomerBasic' }
          & Pick<CustomerBasic, 'id' | 'name'>
          & { backupPoliciesBoundary: (
            { __typename?: 'BackupPoliciesBoundary' }
            & Pick<BackupPoliciesBoundary, 'minRPOInHours' | 'maxPrimarySnapshotRetentionInDays' | 'maxPrimarySnapshotRetentionQuantity' | 'maxReplicaSnapshotRetentionInDays' | 'maxReplicaSnapshotRetentionQuantity'>
          ) }
        ) }
      )>> }
    ) }
  ) }
);

export type GetMyApiKeysQueryVariables = Exact<{
  searchCriteria: MyApiKeysSearchCriteria;
}>;


export type GetMyApiKeysQuery = (
  { __typename?: 'Query' }
  & { myApiKeys: (
    { __typename?: 'ApiKeysPage' }
    & Pick<ApiKeysPage, 'totalNumberOfPages' | 'totalNumberOfApiKeys'>
    & { data: Array<(
      { __typename?: 'ApiKey' }
      & Pick<ApiKey, 'id' | 'alias' | 'owner' | 'createdAt' | 'createdBy' | 'lastUsedAt'>
      & { customerAccesses: (
        { __typename?: 'CustomerAccessesPage' }
        & Pick<CustomerAccessesPage, 'totalNumberOfCustomers' | 'totalNumberOfPages'>
        & { data: Array<Maybe<(
          { __typename?: 'CustomerAccess' }
          & Pick<CustomerAccess, 'roles'>
          & { customer: (
            { __typename?: 'CustomerBasic' }
            & Pick<CustomerBasic, 'id' | 'name'>
            & { backupPoliciesBoundary: (
              { __typename?: 'BackupPoliciesBoundary' }
              & Pick<BackupPoliciesBoundary, 'minRPOInHours' | 'maxPrimarySnapshotRetentionInDays' | 'maxPrimarySnapshotRetentionQuantity' | 'maxReplicaSnapshotRetentionInDays' | 'maxReplicaSnapshotRetentionQuantity'>
            ) }
          ) }
        )>> }
      ) }
    )> }
  ) }
);

export type GetNotificationGroupQueryVariables = Exact<{
  searchCriteria: NotificationGroupSearchCriteria;
}>;


export type GetNotificationGroupQuery = (
  { __typename?: 'Query' }
  & { notificationGroup?: Maybe<(
    { __typename?: 'NotificationGroup' }
    & Pick<NotificationGroup, 'id' | 'name' | 'severities' | 'frequency'>
    & { channels: Array<(
      { __typename?: 'EmailConfig' }
      & Pick<EmailConfig, 'address'>
    ) | (
      { __typename?: 'SlackConfig' }
      & Pick<SlackConfig, 'channelName' | 'webHook'>
    ) | (
      { __typename?: 'PagerDutyConfig' }
      & Pick<PagerDutyConfig, 'routingKey'>
    )>, excludedAccounts?: Maybe<Array<(
      { __typename?: 'Account' }
      & Pick<Account, 'id' | 'name' | 'cloudProviderId' | 'type' | 'cloudProvider'>
    )>> }
  )> }
);

export type GetNotificationGroupsQueryVariables = Exact<{
  customerId: Scalars['String'];
}>;


export type GetNotificationGroupsQuery = (
  { __typename?: 'Query' }
  & { notificationGroups: Array<(
    { __typename?: 'NotificationGroup' }
    & Pick<NotificationGroup, 'id' | 'name' | 'severities' | 'frequency'>
    & { channels: Array<(
      { __typename?: 'EmailConfig' }
      & Pick<EmailConfig, 'address'>
    ) | (
      { __typename?: 'SlackConfig' }
      & Pick<SlackConfig, 'channelName' | 'webHook'>
    ) | (
      { __typename?: 'PagerDutyConfig' }
      & Pick<PagerDutyConfig, 'routingKey'>
    )>, excludedAccounts?: Maybe<Array<(
      { __typename?: 'Account' }
      & Pick<Account, 'id' | 'name' | 'cloudProviderId' | 'type' | 'cloudProvider'>
    )>> }
  )> }
);

export type NumberOfResourcesWithChangedBackupEnabledQueryVariables = Exact<{
  input: ChangeBackupStateInput;
}>;


export type NumberOfResourcesWithChangedBackupEnabledQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'numberOfResourcesWithChangedBackupEnabled'>
);

export type NumberOfResourcesWithChangedBackupPolicyQueryVariables = Exact<{
  input: ChangeBackupPolicyAndSetupsInput;
}>;


export type NumberOfResourcesWithChangedBackupPolicyQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'numberOfResourcesWithChangedBackupPolicy'>
);

export type NumberOfResourcesWithChangedBackupSetupsQueryVariables = Exact<{
  input: ChangeBackupPolicyAndSetupsInput;
}>;


export type NumberOfResourcesWithChangedBackupSetupsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'numberOfResourcesWithChangedBackupSetups'>
);

export type GetPolicyComplianceQueryVariables = Exact<{
  customerId: Scalars['String'];
}>;


export type GetPolicyComplianceQuery = (
  { __typename?: 'Query' }
  & { policyCompliance?: Maybe<(
    { __typename?: 'PolicyCompliance' }
    & Pick<PolicyCompliance, 'numberOfBackedUpResources' | 'numberOfCompliantResources'>
  )> }
);

export type GetProtectedResourcesStatsQueryVariables = Exact<{
  searchCriteria: ProtectedResourcesStatsSearchCriteria;
}>;


export type GetProtectedResourcesStatsQuery = (
  { __typename?: 'Query' }
  & { protectedResourcesStats: (
    { __typename?: 'ProtectedResourcesStats' }
    & Pick<ProtectedResourcesStats, 'numberOfResourcesWithEnabledBackup' | 'totalNumberOfResources' | 'totalAmountOfDataInBytes' | 'protectedAmountOfDataInBytes'>
  ) }
);

export type GetReportQueryVariables = Exact<{
  input: ReportInput;
}>;


export type GetReportQuery = (
  { __typename?: 'Query' }
  & { report?: Maybe<(
    { __typename?: 'Report' }
    & Pick<Report, 'id' | 'createdAt' | 'status' | 'updatedAt' | 'url'>
  )> }
);

export type GetResourceTypeBackupSettingsQueryVariables = Exact<{
  searchCriteria: BackupSettingsSearchCriteria;
}>;


export type GetResourceTypeBackupSettingsQuery = (
  { __typename?: 'Query' }
  & { resourceTypeBackupSettings: Array<(
    { __typename?: 'BackupSettingCandidate' }
    & Pick<BackupSettingCandidate, 'id' | 'label' | 'description' | 'type' | 'isRequired' | 'requiredBy' | 'deprecated'>
    & { allowedValues?: Maybe<Array<(
      { __typename?: 'BackupSettingCandidateValue' }
      & Pick<BackupSettingCandidateValue, 'label' | 'value'>
    )>>, activatedBy?: Maybe<(
      { __typename?: 'ActivatingSetting' }
      & Pick<ActivatingSetting, 'id' | 'activatingValues'>
    )>, activatedBySettings?: Maybe<Array<(
      { __typename?: 'ActivatingSetting' }
      & Pick<ActivatingSetting, 'id' | 'activatingValues'>
    )>> }
  )> }
);

export type GetResourcesQueryVariables = Exact<{
  searchCriteria: ResourcesSearchCriteria;
}>;


export type GetResourcesQuery = (
  { __typename?: 'Query' }
  & { resources: (
    { __typename?: 'ResourcesPage' }
    & Pick<ResourcesPage, 'totalNumberOfPages' | 'totalNumberOfResources'>
    & { data: Array<(
      { __typename?: 'ResourceWithLastBackupStatus' }
      & { resource: (
        { __typename?: 'Resource' }
        & Pick<Resource, 'id' | 'name' | 'type' | 'accountCloudProviderId' | 'accountName' | 'cloudProvider' | 'backupSetupName' | 'category' | 'backupEnabled'>
        & { backupSetup?: Maybe<(
          { __typename?: 'BackupSetup' }
          & Pick<BackupSetup, 'id' | 'name'>
        )>, backupPolicy?: Maybe<(
          { __typename?: 'BackupPolicy' }
          & Pick<BackupPolicy, 'id' | 'name' | 'type'>
        )> }
      ), lastBackupStatus?: Maybe<(
        { __typename?: 'LastBackupStatus' }
        & Pick<LastBackupStatus, 'primaryBackupErrorMessage' | 'replicationErrorMessage' | 'backupStatus'>
      )> }
    )> }
  ) }
);

export type GetResourcesTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetResourcesTypesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'resourcesTypes'>
);

export type GetScanStatusQueryVariables = Exact<{
  customerId: Scalars['String'];
}>;


export type GetScanStatusQuery = (
  { __typename?: 'Query' }
  & { scanStatus?: Maybe<(
    { __typename?: 'ScanStatusResult' }
    & Pick<ScanStatusResult, 'scanId' | 'status' | 'createdAt' | 'updatedAt'>
  )> }
);

export type GetSummaryBackupsReportQueryVariables = Exact<{
  searchCriteria: SummaryBackupsReportScope;
}>;


export type GetSummaryBackupsReportQuery = (
  { __typename?: 'Query' }
  & { summaryBackupsReport: (
    { __typename?: 'SummaryBackupsReport' }
    & Pick<SummaryBackupsReport, 'numberOfAllBackupAttempts' | 'numberOfSuccessfulBackups' | 'numberOfBackedUpResources'>
  ) }
);

export type GetSupportedRegionsQueryVariables = Exact<{
  cloudProvider: CloudProvider;
}>;


export type GetSupportedRegionsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'supportedRegions'>
);

export type GetTemplateQueryVariables = Exact<{
  searchCriteria: TemplateSearchCriteria;
}>;


export type GetTemplateQuery = (
  { __typename?: 'Query' }
  & { template?: Maybe<(
    { __typename?: 'Template' }
    & Pick<Template, 'id' | 'name' | 'content' | 'cloudProvider' | 'parameters'>
  )> }
);

export type GetTemplatesQueryVariables = Exact<{
  searchCriteria: TemplatesSearchCriteria;
}>;


export type GetTemplatesQuery = (
  { __typename?: 'Query' }
  & { templates: (
    { __typename?: 'TemplatesPage' }
    & Pick<TemplatesPage, 'totalNumberOfTemplates' | 'totalNumberOfPages'>
    & { data: Array<(
      { __typename?: 'Template' }
      & Pick<Template, 'id' | 'name' | 'cloudProvider' | 'parameters'>
    )> }
  ) }
);

export type GetUserQueryVariables = Exact<{
  email: Scalars['String'];
  customerId?: Maybe<Scalars['ID']>;
}>;


export type GetUserQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'email' | 'superAdmin'>
    & { customerAccesses: (
      { __typename?: 'CustomerAccessesPage' }
      & Pick<CustomerAccessesPage, 'totalNumberOfCustomers' | 'totalNumberOfPages'>
      & { data: Array<Maybe<(
        { __typename?: 'CustomerAccess' }
        & Pick<CustomerAccess, 'roles'>
        & { customer: (
          { __typename?: 'CustomerBasic' }
          & Pick<CustomerBasic, 'id' | 'name'>
          & { backupPoliciesBoundary: (
            { __typename?: 'BackupPoliciesBoundary' }
            & Pick<BackupPoliciesBoundary, 'minRPOInHours' | 'maxPrimarySnapshotRetentionInDays' | 'maxPrimarySnapshotRetentionQuantity' | 'maxReplicaSnapshotRetentionInDays' | 'maxReplicaSnapshotRetentionQuantity'>
          ) }
        ) }
      )>> }
    ) }
  ) }
);

export type GetUserProfileQueryVariables = Exact<{
  customerId: Scalars['String'];
}>;


export type GetUserProfileQuery = (
  { __typename?: 'Query' }
  & { userProfile?: Maybe<(
    { __typename?: 'UserProfile' }
    & Pick<UserProfile, 'companyName' | 'firstName' | 'lastName' | 'email' | 'phoneNumber' | 'isBackupControlledByTags'>
    & { bestAllowedPolicySettings: (
      { __typename?: 'BestAllowedPolicySettings' }
      & Pick<BestAllowedPolicySettings, 'minRPOInHours' | 'maxPrimarySnapshotRetentionQuantity' | 'maxPrimarySnapshotRetentionInDays' | 'maxReplicaSnapshotRetentionQuantity' | 'maxReplicaSnapshotRetentionInDays'>
    ) }
  )> }
);

export type GetUserRoleQueryVariables = Exact<{
  customerId: Scalars['String'];
}>;


export type GetUserRoleQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'userRole'>
);

export type GetUsersQueryVariables = Exact<{
  searchCriteria: UsersSearchCriteria;
}>;


export type GetUsersQuery = (
  { __typename?: 'Query' }
  & { users: (
    { __typename?: 'UsersPage' }
    & Pick<UsersPage, 'totalNumberOfPages' | 'totalNumberOfUsers'>
    & { data: Array<(
      { __typename?: 'User' }
      & Pick<User, 'email' | 'superAdmin'>
      & { customerAccesses: (
        { __typename?: 'CustomerAccessesPage' }
        & Pick<CustomerAccessesPage, 'totalNumberOfCustomers' | 'totalNumberOfPages'>
        & { data: Array<Maybe<(
          { __typename?: 'CustomerAccess' }
          & Pick<CustomerAccess, 'roles'>
          & { customer: (
            { __typename?: 'CustomerBasic' }
            & Pick<CustomerBasic, 'id' | 'name'>
            & { backupPoliciesBoundary: (
              { __typename?: 'BackupPoliciesBoundary' }
              & Pick<BackupPoliciesBoundary, 'minRPOInHours' | 'maxPrimarySnapshotRetentionInDays' | 'maxPrimarySnapshotRetentionQuantity' | 'maxReplicaSnapshotRetentionInDays' | 'maxReplicaSnapshotRetentionQuantity'>
            ) }
          ) }
        )>> }
      ) }
    )> }
  ) }
);

export type GrantCustomerRoleToApiKeyMutationVariables = Exact<{
  input: GrantCustomerRoleToApiKeyInput;
}>;


export type GrantCustomerRoleToApiKeyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'grantCustomerRoleToApiKey'>
);

export type GrantCustomerRoleToUserMutationVariables = Exact<{
  input: GrantCustomerRoleToUserInput;
}>;


export type GrantCustomerRoleToUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'grantCustomerRoleToUser'>
);

export type GrantSuperAdminMutationVariables = Exact<{
  input: Scalars['String'];
}>;


export type GrantSuperAdminMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'grantSuperAdmin'>
);

export type ProvideBackupTagNamesMutationVariables = Exact<{
  input: ProvideBackupTagNamesInput;
}>;


export type ProvideBackupTagNamesMutation = (
  { __typename?: 'Mutation' }
  & { provideBackupTagNames: (
    { __typename?: 'BackupTagNames' }
    & Pick<BackupTagNames, 'backupEnabledTagNames' | 'policyTagNames' | 'setupTagNames'>
  ) }
);

export type RemoveAccountMutationVariables = Exact<{
  input: RemoveAccountInput;
}>;


export type RemoveAccountMutation = (
  { __typename?: 'Mutation' }
  & { removeAccount: (
    { __typename?: 'Account' }
    & Pick<Account, 'id'>
  ) }
);

export type RemoveApiKeyMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveApiKeyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeApiKey'>
);

export type RemoveBackupPolicyMutationVariables = Exact<{
  input: RemoveBackupPolicyInput;
}>;


export type RemoveBackupPolicyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeBackupPolicy'>
);

export type RemoveBackupSetupMutationVariables = Exact<{
  input: RemoveBackupSetupInput;
}>;


export type RemoveBackupSetupMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeBackupSetup'>
);

export type RemoveDisasterRecoveryPlanMutationVariables = Exact<{
  input: RemoveDisasterRecoveryPlanInput;
}>;


export type RemoveDisasterRecoveryPlanMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeDisasterRecoveryPlan'>
);

export type RemoveNotificationGroupMutationVariables = Exact<{
  input: RemoveNotificationGroupInput;
}>;


export type RemoveNotificationGroupMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeNotificationGroup'>
);

export type RemoveTemplateMutationVariables = Exact<{
  input: RemoveTemplateInput;
}>;


export type RemoveTemplateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeTemplate'>
);

export type RemoveUserMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type RemoveUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeUser'>
);

export type RevokeCustomerRoleFromApiKeyMutationVariables = Exact<{
  input: RevokeCustomerRoleFromApiKeyInput;
}>;


export type RevokeCustomerRoleFromApiKeyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'revokeCustomerRoleFromApiKey'>
);

export type RevokeCustomerRoleFromUserMutationVariables = Exact<{
  input: RevokeCustomerRoleFromUserInput;
}>;


export type RevokeCustomerRoleFromUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'revokeCustomerRoleFromUser'>
);

export type RevokeSuperAdminMutationVariables = Exact<{
  input: Scalars['String'];
}>;


export type RevokeSuperAdminMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'revokeSuperAdmin'>
);

export type ScanStatusChangedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type ScanStatusChangedSubscription = (
  { __typename?: 'Subscription' }
  & { scanStatusChanged?: Maybe<(
    { __typename?: 'ScanStatusUpdateResult' }
    & Pick<ScanStatusUpdateResult, 'customerId' | 'scanId' | 'status'>
  )> }
);

export type SelfCreateApiKeyMutationVariables = Exact<{
  input: SelfCreateApiKeyInput;
}>;


export type SelfCreateApiKeyMutation = (
  { __typename?: 'Mutation' }
  & { selfCreateApiKey: (
    { __typename?: 'CreateApiKeyResult' }
    & Pick<CreateApiKeyResult, 'apiKeyString'>
    & { apiKey: (
      { __typename?: 'ApiKey' }
      & Pick<ApiKey, 'id' | 'alias' | 'owner' | 'lastUsedAt' | 'createdAt' | 'createdBy'>
    ) }
  ) }
);

export type SelfGrantCustomerRoleToApiKeyMutationVariables = Exact<{
  input: GrantCustomerRoleToApiKeyInput;
}>;


export type SelfGrantCustomerRoleToApiKeyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'selfGrantCustomerRoleToApiKey'>
);

export type SelfRemoveApiKeyMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SelfRemoveApiKeyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'selfRemoveApiKey'>
);

export type SelfRevokeCustomerRoleFromApiKeyMutationVariables = Exact<{
  input: RevokeCustomerRoleFromApiKeyInput;
}>;


export type SelfRevokeCustomerRoleFromApiKeyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'selfRevokeCustomerRoleFromApiKey'>
);

export type StartDisasterRecoveryMutationVariables = Exact<{
  input: StartDisasterRecoveryInput;
}>;


export type StartDisasterRecoveryMutation = (
  { __typename?: 'Mutation' }
  & { startDisasterRecovery?: Maybe<(
    { __typename?: 'DisasterRecoveryRecord' }
    & Pick<DisasterRecoveryRecord, 'id'>
  )> }
);

export type TriggerBackupMutationVariables = Exact<{
  input: TriggerBackupInput;
}>;


export type TriggerBackupMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'triggerBackup'>
);

export type TriggerScanMutationVariables = Exact<{
  input: TriggerScanInput;
}>;


export type TriggerScanMutation = (
  { __typename?: 'Mutation' }
  & { triggerScan: (
    { __typename?: 'ScanTriggerResult' }
    & Pick<ScanTriggerResult, 'scanId'>
  ) }
);

export type UpdateBackupSetupMutationVariables = Exact<{
  input: UpdateBackupSetupInput;
}>;


export type UpdateBackupSetupMutation = (
  { __typename?: 'Mutation' }
  & { updateBackupSetup?: Maybe<(
    { __typename?: 'BackupSetup' }
    & Pick<BackupSetup, 'id'>
  )> }
);

export type UpdateDisasterRecoveryPlanMutationVariables = Exact<{
  input: UpdateDisasterRecoveryPlanInput;
}>;


export type UpdateDisasterRecoveryPlanMutation = (
  { __typename?: 'Mutation' }
  & { updateDisasterRecoveryPlan?: Maybe<(
    { __typename?: 'DisasterRecoveryPlan' }
    & Pick<DisasterRecoveryPlan, 'id'>
  )> }
);

export type UpdateDisasterRecoveryRecordMutationVariables = Exact<{
  input: UpdateDisasterRecoveryRecordInput;
}>;


export type UpdateDisasterRecoveryRecordMutation = (
  { __typename?: 'Mutation' }
  & { updateDisasterRecoveryRecord?: Maybe<(
    { __typename?: 'DisasterRecoveryRecord' }
    & Pick<DisasterRecoveryRecord, 'id'>
  )> }
);

export type UpdateTemplateMutationVariables = Exact<{
  input: UpdateTemplateInput;
}>;


export type UpdateTemplateMutation = (
  { __typename?: 'Mutation' }
  & { updateTemplate?: Maybe<(
    { __typename?: 'Template' }
    & Pick<Template, 'id'>
  )> }
);

export type UpdateUserProfileMutationVariables = Exact<{
  input: UserProfileInput;
}>;


export type UpdateUserProfileMutation = (
  { __typename?: 'Mutation' }
  & { updateUserProfile: (
    { __typename?: 'UserProfile' }
    & Pick<UserProfile, 'isBackupControlledByTags'>
  ) }
);

export type ValidateTemplateMutationVariables = Exact<{
  input: ValidateTemplateInput;
}>;


export type ValidateTemplateMutation = (
  { __typename?: 'Mutation' }
  & { validateTemplate: (
    { __typename?: 'ValidateTemplateResult' }
    & Pick<ValidateTemplateResult, 'parameters'>
  ) }
);


export const ChangeBackupPolicyAndSetupsDocument = gql`
    mutation changeBackupPolicyAndSetups($input: ChangeBackupPolicyAndSetupsInput!) {
  changeBackupPolicyAndSetups(input: $input)
}
    `;
export type ChangeBackupPolicyAndSetupsMutationFn = ApolloReactCommon.MutationFunction<ChangeBackupPolicyAndSetupsMutation, ChangeBackupPolicyAndSetupsMutationVariables>;
export type ChangeBackupPolicyAndSetupsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ChangeBackupPolicyAndSetupsMutation, ChangeBackupPolicyAndSetupsMutationVariables>, 'mutation'>;

    export const ChangeBackupPolicyAndSetupsComponent = (props: ChangeBackupPolicyAndSetupsComponentProps) => (
      <ApolloReactComponents.Mutation<ChangeBackupPolicyAndSetupsMutation, ChangeBackupPolicyAndSetupsMutationVariables> mutation={ChangeBackupPolicyAndSetupsDocument} {...props} />
    );
    
export type ChangeBackupPolicyAndSetupsProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<ChangeBackupPolicyAndSetupsMutation, ChangeBackupPolicyAndSetupsMutationVariables>
    } & TChildProps;
export function withChangeBackupPolicyAndSetups<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ChangeBackupPolicyAndSetupsMutation,
  ChangeBackupPolicyAndSetupsMutationVariables,
  ChangeBackupPolicyAndSetupsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, ChangeBackupPolicyAndSetupsMutation, ChangeBackupPolicyAndSetupsMutationVariables, ChangeBackupPolicyAndSetupsProps<TChildProps, TDataName>>(ChangeBackupPolicyAndSetupsDocument, {
      alias: 'changeBackupPolicyAndSetups',
      ...operationOptions
    });
};

/**
 * __useChangeBackupPolicyAndSetupsMutation__
 *
 * To run a mutation, you first call `useChangeBackupPolicyAndSetupsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeBackupPolicyAndSetupsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeBackupPolicyAndSetupsMutation, { data, loading, error }] = useChangeBackupPolicyAndSetupsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeBackupPolicyAndSetupsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangeBackupPolicyAndSetupsMutation, ChangeBackupPolicyAndSetupsMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangeBackupPolicyAndSetupsMutation, ChangeBackupPolicyAndSetupsMutationVariables>(ChangeBackupPolicyAndSetupsDocument, baseOptions);
      }
export type ChangeBackupPolicyAndSetupsMutationHookResult = ReturnType<typeof useChangeBackupPolicyAndSetupsMutation>;
export type ChangeBackupPolicyAndSetupsMutationResult = ApolloReactCommon.MutationResult<ChangeBackupPolicyAndSetupsMutation>;
export type ChangeBackupPolicyAndSetupsMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangeBackupPolicyAndSetupsMutation, ChangeBackupPolicyAndSetupsMutationVariables>;
export const ChangeBackupStateDocument = gql`
    mutation changeBackupState($input: ChangeBackupStateInput!) {
  changeBackupState(input: $input)
}
    `;
export type ChangeBackupStateMutationFn = ApolloReactCommon.MutationFunction<ChangeBackupStateMutation, ChangeBackupStateMutationVariables>;
export type ChangeBackupStateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ChangeBackupStateMutation, ChangeBackupStateMutationVariables>, 'mutation'>;

    export const ChangeBackupStateComponent = (props: ChangeBackupStateComponentProps) => (
      <ApolloReactComponents.Mutation<ChangeBackupStateMutation, ChangeBackupStateMutationVariables> mutation={ChangeBackupStateDocument} {...props} />
    );
    
export type ChangeBackupStateProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<ChangeBackupStateMutation, ChangeBackupStateMutationVariables>
    } & TChildProps;
export function withChangeBackupState<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ChangeBackupStateMutation,
  ChangeBackupStateMutationVariables,
  ChangeBackupStateProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, ChangeBackupStateMutation, ChangeBackupStateMutationVariables, ChangeBackupStateProps<TChildProps, TDataName>>(ChangeBackupStateDocument, {
      alias: 'changeBackupState',
      ...operationOptions
    });
};

/**
 * __useChangeBackupStateMutation__
 *
 * To run a mutation, you first call `useChangeBackupStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeBackupStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeBackupStateMutation, { data, loading, error }] = useChangeBackupStateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeBackupStateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangeBackupStateMutation, ChangeBackupStateMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangeBackupStateMutation, ChangeBackupStateMutationVariables>(ChangeBackupStateDocument, baseOptions);
      }
export type ChangeBackupStateMutationHookResult = ReturnType<typeof useChangeBackupStateMutation>;
export type ChangeBackupStateMutationResult = ApolloReactCommon.MutationResult<ChangeBackupStateMutation>;
export type ChangeBackupStateMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangeBackupStateMutation, ChangeBackupStateMutationVariables>;
export const CreateAccountAwsDocument = gql`
    mutation createAccountAws($input: CreateAccountAwsInput!) {
  createAccountAws(input: $input) {
    accountId
  }
}
    `;
export type CreateAccountAwsMutationFn = ApolloReactCommon.MutationFunction<CreateAccountAwsMutation, CreateAccountAwsMutationVariables>;
export type CreateAccountAwsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateAccountAwsMutation, CreateAccountAwsMutationVariables>, 'mutation'>;

    export const CreateAccountAwsComponent = (props: CreateAccountAwsComponentProps) => (
      <ApolloReactComponents.Mutation<CreateAccountAwsMutation, CreateAccountAwsMutationVariables> mutation={CreateAccountAwsDocument} {...props} />
    );
    
export type CreateAccountAwsProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateAccountAwsMutation, CreateAccountAwsMutationVariables>
    } & TChildProps;
export function withCreateAccountAws<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateAccountAwsMutation,
  CreateAccountAwsMutationVariables,
  CreateAccountAwsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateAccountAwsMutation, CreateAccountAwsMutationVariables, CreateAccountAwsProps<TChildProps, TDataName>>(CreateAccountAwsDocument, {
      alias: 'createAccountAws',
      ...operationOptions
    });
};

/**
 * __useCreateAccountAwsMutation__
 *
 * To run a mutation, you first call `useCreateAccountAwsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountAwsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountAwsMutation, { data, loading, error }] = useCreateAccountAwsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAccountAwsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAccountAwsMutation, CreateAccountAwsMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateAccountAwsMutation, CreateAccountAwsMutationVariables>(CreateAccountAwsDocument, baseOptions);
      }
export type CreateAccountAwsMutationHookResult = ReturnType<typeof useCreateAccountAwsMutation>;
export type CreateAccountAwsMutationResult = ApolloReactCommon.MutationResult<CreateAccountAwsMutation>;
export type CreateAccountAwsMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateAccountAwsMutation, CreateAccountAwsMutationVariables>;
export const CreateAccountAwsChinaDocument = gql`
    mutation createAccountAwsChina($input: CreateAccountAwsChinaInput!) {
  createAccountAwsChina(input: $input)
}
    `;
export type CreateAccountAwsChinaMutationFn = ApolloReactCommon.MutationFunction<CreateAccountAwsChinaMutation, CreateAccountAwsChinaMutationVariables>;
export type CreateAccountAwsChinaComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateAccountAwsChinaMutation, CreateAccountAwsChinaMutationVariables>, 'mutation'>;

    export const CreateAccountAwsChinaComponent = (props: CreateAccountAwsChinaComponentProps) => (
      <ApolloReactComponents.Mutation<CreateAccountAwsChinaMutation, CreateAccountAwsChinaMutationVariables> mutation={CreateAccountAwsChinaDocument} {...props} />
    );
    
export type CreateAccountAwsChinaProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateAccountAwsChinaMutation, CreateAccountAwsChinaMutationVariables>
    } & TChildProps;
export function withCreateAccountAwsChina<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateAccountAwsChinaMutation,
  CreateAccountAwsChinaMutationVariables,
  CreateAccountAwsChinaProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateAccountAwsChinaMutation, CreateAccountAwsChinaMutationVariables, CreateAccountAwsChinaProps<TChildProps, TDataName>>(CreateAccountAwsChinaDocument, {
      alias: 'createAccountAwsChina',
      ...operationOptions
    });
};

/**
 * __useCreateAccountAwsChinaMutation__
 *
 * To run a mutation, you first call `useCreateAccountAwsChinaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountAwsChinaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountAwsChinaMutation, { data, loading, error }] = useCreateAccountAwsChinaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAccountAwsChinaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAccountAwsChinaMutation, CreateAccountAwsChinaMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateAccountAwsChinaMutation, CreateAccountAwsChinaMutationVariables>(CreateAccountAwsChinaDocument, baseOptions);
      }
export type CreateAccountAwsChinaMutationHookResult = ReturnType<typeof useCreateAccountAwsChinaMutation>;
export type CreateAccountAwsChinaMutationResult = ApolloReactCommon.MutationResult<CreateAccountAwsChinaMutation>;
export type CreateAccountAwsChinaMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateAccountAwsChinaMutation, CreateAccountAwsChinaMutationVariables>;
export const CreateAccountAzureDocument = gql`
    mutation createAccountAzure($input: CreateAccountAzureInput!) {
  createAccountAzure(input: $input)
}
    `;
export type CreateAccountAzureMutationFn = ApolloReactCommon.MutationFunction<CreateAccountAzureMutation, CreateAccountAzureMutationVariables>;
export type CreateAccountAzureComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateAccountAzureMutation, CreateAccountAzureMutationVariables>, 'mutation'>;

    export const CreateAccountAzureComponent = (props: CreateAccountAzureComponentProps) => (
      <ApolloReactComponents.Mutation<CreateAccountAzureMutation, CreateAccountAzureMutationVariables> mutation={CreateAccountAzureDocument} {...props} />
    );
    
export type CreateAccountAzureProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateAccountAzureMutation, CreateAccountAzureMutationVariables>
    } & TChildProps;
export function withCreateAccountAzure<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateAccountAzureMutation,
  CreateAccountAzureMutationVariables,
  CreateAccountAzureProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateAccountAzureMutation, CreateAccountAzureMutationVariables, CreateAccountAzureProps<TChildProps, TDataName>>(CreateAccountAzureDocument, {
      alias: 'createAccountAzure',
      ...operationOptions
    });
};

/**
 * __useCreateAccountAzureMutation__
 *
 * To run a mutation, you first call `useCreateAccountAzureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountAzureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountAzureMutation, { data, loading, error }] = useCreateAccountAzureMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAccountAzureMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAccountAzureMutation, CreateAccountAzureMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateAccountAzureMutation, CreateAccountAzureMutationVariables>(CreateAccountAzureDocument, baseOptions);
      }
export type CreateAccountAzureMutationHookResult = ReturnType<typeof useCreateAccountAzureMutation>;
export type CreateAccountAzureMutationResult = ApolloReactCommon.MutationResult<CreateAccountAzureMutation>;
export type CreateAccountAzureMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateAccountAzureMutation, CreateAccountAzureMutationVariables>;
export const CreateAccountGcpDocument = gql`
    mutation createAccountGcp($input: CreateAccountGcpInput!) {
  createAccountGcp(input: $input)
}
    `;
export type CreateAccountGcpMutationFn = ApolloReactCommon.MutationFunction<CreateAccountGcpMutation, CreateAccountGcpMutationVariables>;
export type CreateAccountGcpComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateAccountGcpMutation, CreateAccountGcpMutationVariables>, 'mutation'>;

    export const CreateAccountGcpComponent = (props: CreateAccountGcpComponentProps) => (
      <ApolloReactComponents.Mutation<CreateAccountGcpMutation, CreateAccountGcpMutationVariables> mutation={CreateAccountGcpDocument} {...props} />
    );
    
export type CreateAccountGcpProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateAccountGcpMutation, CreateAccountGcpMutationVariables>
    } & TChildProps;
export function withCreateAccountGcp<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateAccountGcpMutation,
  CreateAccountGcpMutationVariables,
  CreateAccountGcpProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateAccountGcpMutation, CreateAccountGcpMutationVariables, CreateAccountGcpProps<TChildProps, TDataName>>(CreateAccountGcpDocument, {
      alias: 'createAccountGcp',
      ...operationOptions
    });
};

/**
 * __useCreateAccountGcpMutation__
 *
 * To run a mutation, you first call `useCreateAccountGcpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountGcpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountGcpMutation, { data, loading, error }] = useCreateAccountGcpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAccountGcpMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAccountGcpMutation, CreateAccountGcpMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateAccountGcpMutation, CreateAccountGcpMutationVariables>(CreateAccountGcpDocument, baseOptions);
      }
export type CreateAccountGcpMutationHookResult = ReturnType<typeof useCreateAccountGcpMutation>;
export type CreateAccountGcpMutationResult = ApolloReactCommon.MutationResult<CreateAccountGcpMutation>;
export type CreateAccountGcpMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateAccountGcpMutation, CreateAccountGcpMutationVariables>;
export const CreateAccountIbmCloudDocument = gql`
    mutation createAccountIbmCloud($input: CreateAccountIbmCloudInput!) {
  createAccountIbmCloud(input: $input)
}
    `;
export type CreateAccountIbmCloudMutationFn = ApolloReactCommon.MutationFunction<CreateAccountIbmCloudMutation, CreateAccountIbmCloudMutationVariables>;
export type CreateAccountIbmCloudComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateAccountIbmCloudMutation, CreateAccountIbmCloudMutationVariables>, 'mutation'>;

    export const CreateAccountIbmCloudComponent = (props: CreateAccountIbmCloudComponentProps) => (
      <ApolloReactComponents.Mutation<CreateAccountIbmCloudMutation, CreateAccountIbmCloudMutationVariables> mutation={CreateAccountIbmCloudDocument} {...props} />
    );
    
export type CreateAccountIbmCloudProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateAccountIbmCloudMutation, CreateAccountIbmCloudMutationVariables>
    } & TChildProps;
export function withCreateAccountIbmCloud<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateAccountIbmCloudMutation,
  CreateAccountIbmCloudMutationVariables,
  CreateAccountIbmCloudProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateAccountIbmCloudMutation, CreateAccountIbmCloudMutationVariables, CreateAccountIbmCloudProps<TChildProps, TDataName>>(CreateAccountIbmCloudDocument, {
      alias: 'createAccountIbmCloud',
      ...operationOptions
    });
};

/**
 * __useCreateAccountIbmCloudMutation__
 *
 * To run a mutation, you first call `useCreateAccountIbmCloudMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountIbmCloudMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountIbmCloudMutation, { data, loading, error }] = useCreateAccountIbmCloudMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAccountIbmCloudMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAccountIbmCloudMutation, CreateAccountIbmCloudMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateAccountIbmCloudMutation, CreateAccountIbmCloudMutationVariables>(CreateAccountIbmCloudDocument, baseOptions);
      }
export type CreateAccountIbmCloudMutationHookResult = ReturnType<typeof useCreateAccountIbmCloudMutation>;
export type CreateAccountIbmCloudMutationResult = ApolloReactCommon.MutationResult<CreateAccountIbmCloudMutation>;
export type CreateAccountIbmCloudMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateAccountIbmCloudMutation, CreateAccountIbmCloudMutationVariables>;
export const CreateApiKeyDocument = gql`
    mutation createApiKey($input: CreateApiKeyInput!) {
  createApiKey(input: $input) {
    apiKey {
      id
      alias
      owner
      lastUsedAt
      createdAt
      createdBy
    }
    apiKeyString
  }
}
    `;
export type CreateApiKeyMutationFn = ApolloReactCommon.MutationFunction<CreateApiKeyMutation, CreateApiKeyMutationVariables>;
export type CreateApiKeyComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateApiKeyMutation, CreateApiKeyMutationVariables>, 'mutation'>;

    export const CreateApiKeyComponent = (props: CreateApiKeyComponentProps) => (
      <ApolloReactComponents.Mutation<CreateApiKeyMutation, CreateApiKeyMutationVariables> mutation={CreateApiKeyDocument} {...props} />
    );
    
export type CreateApiKeyProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateApiKeyMutation, CreateApiKeyMutationVariables>
    } & TChildProps;
export function withCreateApiKey<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateApiKeyMutation,
  CreateApiKeyMutationVariables,
  CreateApiKeyProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateApiKeyMutation, CreateApiKeyMutationVariables, CreateApiKeyProps<TChildProps, TDataName>>(CreateApiKeyDocument, {
      alias: 'createApiKey',
      ...operationOptions
    });
};

/**
 * __useCreateApiKeyMutation__
 *
 * To run a mutation, you first call `useCreateApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApiKeyMutation, { data, loading, error }] = useCreateApiKeyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateApiKeyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateApiKeyMutation, CreateApiKeyMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateApiKeyMutation, CreateApiKeyMutationVariables>(CreateApiKeyDocument, baseOptions);
      }
export type CreateApiKeyMutationHookResult = ReturnType<typeof useCreateApiKeyMutation>;
export type CreateApiKeyMutationResult = ApolloReactCommon.MutationResult<CreateApiKeyMutation>;
export type CreateApiKeyMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateApiKeyMutation, CreateApiKeyMutationVariables>;
export const CreateBackupPolicyDocument = gql`
    mutation createBackupPolicy($input: CreateBackupPolicyInput!) {
  createBackupPolicy(input: $input) {
    id
  }
}
    `;
export type CreateBackupPolicyMutationFn = ApolloReactCommon.MutationFunction<CreateBackupPolicyMutation, CreateBackupPolicyMutationVariables>;
export type CreateBackupPolicyComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateBackupPolicyMutation, CreateBackupPolicyMutationVariables>, 'mutation'>;

    export const CreateBackupPolicyComponent = (props: CreateBackupPolicyComponentProps) => (
      <ApolloReactComponents.Mutation<CreateBackupPolicyMutation, CreateBackupPolicyMutationVariables> mutation={CreateBackupPolicyDocument} {...props} />
    );
    
export type CreateBackupPolicyProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateBackupPolicyMutation, CreateBackupPolicyMutationVariables>
    } & TChildProps;
export function withCreateBackupPolicy<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateBackupPolicyMutation,
  CreateBackupPolicyMutationVariables,
  CreateBackupPolicyProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateBackupPolicyMutation, CreateBackupPolicyMutationVariables, CreateBackupPolicyProps<TChildProps, TDataName>>(CreateBackupPolicyDocument, {
      alias: 'createBackupPolicy',
      ...operationOptions
    });
};

/**
 * __useCreateBackupPolicyMutation__
 *
 * To run a mutation, you first call `useCreateBackupPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBackupPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBackupPolicyMutation, { data, loading, error }] = useCreateBackupPolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBackupPolicyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBackupPolicyMutation, CreateBackupPolicyMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBackupPolicyMutation, CreateBackupPolicyMutationVariables>(CreateBackupPolicyDocument, baseOptions);
      }
export type CreateBackupPolicyMutationHookResult = ReturnType<typeof useCreateBackupPolicyMutation>;
export type CreateBackupPolicyMutationResult = ApolloReactCommon.MutationResult<CreateBackupPolicyMutation>;
export type CreateBackupPolicyMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBackupPolicyMutation, CreateBackupPolicyMutationVariables>;
export const CreateBackupSetupDocument = gql`
    mutation createBackupSetup($input: BackupSetupInput!) {
  createBackupSetup(input: $input) {
    id
  }
}
    `;
export type CreateBackupSetupMutationFn = ApolloReactCommon.MutationFunction<CreateBackupSetupMutation, CreateBackupSetupMutationVariables>;
export type CreateBackupSetupComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateBackupSetupMutation, CreateBackupSetupMutationVariables>, 'mutation'>;

    export const CreateBackupSetupComponent = (props: CreateBackupSetupComponentProps) => (
      <ApolloReactComponents.Mutation<CreateBackupSetupMutation, CreateBackupSetupMutationVariables> mutation={CreateBackupSetupDocument} {...props} />
    );
    
export type CreateBackupSetupProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateBackupSetupMutation, CreateBackupSetupMutationVariables>
    } & TChildProps;
export function withCreateBackupSetup<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateBackupSetupMutation,
  CreateBackupSetupMutationVariables,
  CreateBackupSetupProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateBackupSetupMutation, CreateBackupSetupMutationVariables, CreateBackupSetupProps<TChildProps, TDataName>>(CreateBackupSetupDocument, {
      alias: 'createBackupSetup',
      ...operationOptions
    });
};

/**
 * __useCreateBackupSetupMutation__
 *
 * To run a mutation, you first call `useCreateBackupSetupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBackupSetupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBackupSetupMutation, { data, loading, error }] = useCreateBackupSetupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBackupSetupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBackupSetupMutation, CreateBackupSetupMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBackupSetupMutation, CreateBackupSetupMutationVariables>(CreateBackupSetupDocument, baseOptions);
      }
export type CreateBackupSetupMutationHookResult = ReturnType<typeof useCreateBackupSetupMutation>;
export type CreateBackupSetupMutationResult = ApolloReactCommon.MutationResult<CreateBackupSetupMutation>;
export type CreateBackupSetupMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBackupSetupMutation, CreateBackupSetupMutationVariables>;
export const CreateDisasterRecoveryPlanDocument = gql`
    mutation createDisasterRecoveryPlan($input: CreateDisasterRecoveryPlanInput!) {
  createDisasterRecoveryPlan(input: $input) {
    id
  }
}
    `;
export type CreateDisasterRecoveryPlanMutationFn = ApolloReactCommon.MutationFunction<CreateDisasterRecoveryPlanMutation, CreateDisasterRecoveryPlanMutationVariables>;
export type CreateDisasterRecoveryPlanComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateDisasterRecoveryPlanMutation, CreateDisasterRecoveryPlanMutationVariables>, 'mutation'>;

    export const CreateDisasterRecoveryPlanComponent = (props: CreateDisasterRecoveryPlanComponentProps) => (
      <ApolloReactComponents.Mutation<CreateDisasterRecoveryPlanMutation, CreateDisasterRecoveryPlanMutationVariables> mutation={CreateDisasterRecoveryPlanDocument} {...props} />
    );
    
export type CreateDisasterRecoveryPlanProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateDisasterRecoveryPlanMutation, CreateDisasterRecoveryPlanMutationVariables>
    } & TChildProps;
export function withCreateDisasterRecoveryPlan<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateDisasterRecoveryPlanMutation,
  CreateDisasterRecoveryPlanMutationVariables,
  CreateDisasterRecoveryPlanProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateDisasterRecoveryPlanMutation, CreateDisasterRecoveryPlanMutationVariables, CreateDisasterRecoveryPlanProps<TChildProps, TDataName>>(CreateDisasterRecoveryPlanDocument, {
      alias: 'createDisasterRecoveryPlan',
      ...operationOptions
    });
};

/**
 * __useCreateDisasterRecoveryPlanMutation__
 *
 * To run a mutation, you first call `useCreateDisasterRecoveryPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDisasterRecoveryPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDisasterRecoveryPlanMutation, { data, loading, error }] = useCreateDisasterRecoveryPlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDisasterRecoveryPlanMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDisasterRecoveryPlanMutation, CreateDisasterRecoveryPlanMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateDisasterRecoveryPlanMutation, CreateDisasterRecoveryPlanMutationVariables>(CreateDisasterRecoveryPlanDocument, baseOptions);
      }
export type CreateDisasterRecoveryPlanMutationHookResult = ReturnType<typeof useCreateDisasterRecoveryPlanMutation>;
export type CreateDisasterRecoveryPlanMutationResult = ApolloReactCommon.MutationResult<CreateDisasterRecoveryPlanMutation>;
export type CreateDisasterRecoveryPlanMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateDisasterRecoveryPlanMutation, CreateDisasterRecoveryPlanMutationVariables>;
export const CreateNotificationGroupDocument = gql`
    mutation createNotificationGroup($input: NotificationGroupInput!) {
  createNotificationGroup(input: $input) {
    name
    severities
    frequency
    channels {
      __typename
      ... on EmailConfig {
        address
      }
      ... on SlackConfig {
        channelName
        webHook
      }
      ... on PagerDutyConfig {
        routingKey
      }
    }
  }
}
    `;
export type CreateNotificationGroupMutationFn = ApolloReactCommon.MutationFunction<CreateNotificationGroupMutation, CreateNotificationGroupMutationVariables>;
export type CreateNotificationGroupComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateNotificationGroupMutation, CreateNotificationGroupMutationVariables>, 'mutation'>;

    export const CreateNotificationGroupComponent = (props: CreateNotificationGroupComponentProps) => (
      <ApolloReactComponents.Mutation<CreateNotificationGroupMutation, CreateNotificationGroupMutationVariables> mutation={CreateNotificationGroupDocument} {...props} />
    );
    
export type CreateNotificationGroupProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateNotificationGroupMutation, CreateNotificationGroupMutationVariables>
    } & TChildProps;
export function withCreateNotificationGroup<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateNotificationGroupMutation,
  CreateNotificationGroupMutationVariables,
  CreateNotificationGroupProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateNotificationGroupMutation, CreateNotificationGroupMutationVariables, CreateNotificationGroupProps<TChildProps, TDataName>>(CreateNotificationGroupDocument, {
      alias: 'createNotificationGroup',
      ...operationOptions
    });
};

/**
 * __useCreateNotificationGroupMutation__
 *
 * To run a mutation, you first call `useCreateNotificationGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNotificationGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNotificationGroupMutation, { data, loading, error }] = useCreateNotificationGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNotificationGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateNotificationGroupMutation, CreateNotificationGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateNotificationGroupMutation, CreateNotificationGroupMutationVariables>(CreateNotificationGroupDocument, baseOptions);
      }
export type CreateNotificationGroupMutationHookResult = ReturnType<typeof useCreateNotificationGroupMutation>;
export type CreateNotificationGroupMutationResult = ApolloReactCommon.MutationResult<CreateNotificationGroupMutation>;
export type CreateNotificationGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateNotificationGroupMutation, CreateNotificationGroupMutationVariables>;
export const CreateTemplateDocument = gql`
    mutation createTemplate($input: CreateTemplateInput!) {
  createTemplate(input: $input) {
    id
  }
}
    `;
export type CreateTemplateMutationFn = ApolloReactCommon.MutationFunction<CreateTemplateMutation, CreateTemplateMutationVariables>;
export type CreateTemplateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateTemplateMutation, CreateTemplateMutationVariables>, 'mutation'>;

    export const CreateTemplateComponent = (props: CreateTemplateComponentProps) => (
      <ApolloReactComponents.Mutation<CreateTemplateMutation, CreateTemplateMutationVariables> mutation={CreateTemplateDocument} {...props} />
    );
    
export type CreateTemplateProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateTemplateMutation, CreateTemplateMutationVariables>
    } & TChildProps;
export function withCreateTemplate<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateTemplateMutation,
  CreateTemplateMutationVariables,
  CreateTemplateProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateTemplateMutation, CreateTemplateMutationVariables, CreateTemplateProps<TChildProps, TDataName>>(CreateTemplateDocument, {
      alias: 'createTemplate',
      ...operationOptions
    });
};

/**
 * __useCreateTemplateMutation__
 *
 * To run a mutation, you first call `useCreateTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTemplateMutation, { data, loading, error }] = useCreateTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateTemplateMutation, CreateTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateTemplateMutation, CreateTemplateMutationVariables>(CreateTemplateDocument, baseOptions);
      }
export type CreateTemplateMutationHookResult = ReturnType<typeof useCreateTemplateMutation>;
export type CreateTemplateMutationResult = ApolloReactCommon.MutationResult<CreateTemplateMutation>;
export type CreateTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateTemplateMutation, CreateTemplateMutationVariables>;
export const CreateUserDocument = gql`
    mutation createUser($input: AddUserInput!) {
  addUser(input: $input) {
    email
    superAdmin
    customerAccesses {
      data {
        customer {
          id
          name
        }
        roles
      }
      totalNumberOfCustomers
      totalNumberOfPages
    }
  }
}
    `;
export type CreateUserMutationFn = ApolloReactCommon.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;
export type CreateUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateUserMutation, CreateUserMutationVariables>, 'mutation'>;

    export const CreateUserComponent = (props: CreateUserComponentProps) => (
      <ApolloReactComponents.Mutation<CreateUserMutation, CreateUserMutationVariables> mutation={CreateUserDocument} {...props} />
    );
    
export type CreateUserProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateUserMutation, CreateUserMutationVariables>
    } & TChildProps;
export function withCreateUser<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateUserMutation,
  CreateUserMutationVariables,
  CreateUserProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateUserMutation, CreateUserMutationVariables, CreateUserProps<TChildProps, TDataName>>(CreateUserDocument, {
      alias: 'createUser',
      ...operationOptions
    });
};

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, baseOptions);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = ApolloReactCommon.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const DisasterRecoveryRecordsReportDocument = gql`
    query disasterRecoveryRecordsReport($input: DisasterRecoveryRecordsReportInput!) {
  disasterRecoveryRecordsReport(input: $input) {
    data
  }
}
    `;
export type DisasterRecoveryRecordsReportComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<DisasterRecoveryRecordsReportQuery, DisasterRecoveryRecordsReportQueryVariables>, 'query'> & ({ variables: DisasterRecoveryRecordsReportQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const DisasterRecoveryRecordsReportComponent = (props: DisasterRecoveryRecordsReportComponentProps) => (
      <ApolloReactComponents.Query<DisasterRecoveryRecordsReportQuery, DisasterRecoveryRecordsReportQueryVariables> query={DisasterRecoveryRecordsReportDocument} {...props} />
    );
    
export type DisasterRecoveryRecordsReportProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<DisasterRecoveryRecordsReportQuery, DisasterRecoveryRecordsReportQueryVariables>
    } & TChildProps;
export function withDisasterRecoveryRecordsReport<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DisasterRecoveryRecordsReportQuery,
  DisasterRecoveryRecordsReportQueryVariables,
  DisasterRecoveryRecordsReportProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, DisasterRecoveryRecordsReportQuery, DisasterRecoveryRecordsReportQueryVariables, DisasterRecoveryRecordsReportProps<TChildProps, TDataName>>(DisasterRecoveryRecordsReportDocument, {
      alias: 'disasterRecoveryRecordsReport',
      ...operationOptions
    });
};

/**
 * __useDisasterRecoveryRecordsReportQuery__
 *
 * To run a query within a React component, call `useDisasterRecoveryRecordsReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useDisasterRecoveryRecordsReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDisasterRecoveryRecordsReportQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDisasterRecoveryRecordsReportQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DisasterRecoveryRecordsReportQuery, DisasterRecoveryRecordsReportQueryVariables>) {
        return ApolloReactHooks.useQuery<DisasterRecoveryRecordsReportQuery, DisasterRecoveryRecordsReportQueryVariables>(DisasterRecoveryRecordsReportDocument, baseOptions);
      }
export function useDisasterRecoveryRecordsReportLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DisasterRecoveryRecordsReportQuery, DisasterRecoveryRecordsReportQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DisasterRecoveryRecordsReportQuery, DisasterRecoveryRecordsReportQueryVariables>(DisasterRecoveryRecordsReportDocument, baseOptions);
        }
export type DisasterRecoveryRecordsReportQueryHookResult = ReturnType<typeof useDisasterRecoveryRecordsReportQuery>;
export type DisasterRecoveryRecordsReportLazyQueryHookResult = ReturnType<typeof useDisasterRecoveryRecordsReportLazyQuery>;
export type DisasterRecoveryRecordsReportQueryResult = ApolloReactCommon.QueryResult<DisasterRecoveryRecordsReportQuery, DisasterRecoveryRecordsReportQueryVariables>;
export const DismissMessageDocument = gql`
    mutation dismissMessage($input: DismissMessageInput!) {
  dismissMessage(input: $input)
}
    `;
export type DismissMessageMutationFn = ApolloReactCommon.MutationFunction<DismissMessageMutation, DismissMessageMutationVariables>;
export type DismissMessageComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DismissMessageMutation, DismissMessageMutationVariables>, 'mutation'>;

    export const DismissMessageComponent = (props: DismissMessageComponentProps) => (
      <ApolloReactComponents.Mutation<DismissMessageMutation, DismissMessageMutationVariables> mutation={DismissMessageDocument} {...props} />
    );
    
export type DismissMessageProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DismissMessageMutation, DismissMessageMutationVariables>
    } & TChildProps;
export function withDismissMessage<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DismissMessageMutation,
  DismissMessageMutationVariables,
  DismissMessageProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DismissMessageMutation, DismissMessageMutationVariables, DismissMessageProps<TChildProps, TDataName>>(DismissMessageDocument, {
      alias: 'dismissMessage',
      ...operationOptions
    });
};

/**
 * __useDismissMessageMutation__
 *
 * To run a mutation, you first call `useDismissMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDismissMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dismissMessageMutation, { data, loading, error }] = useDismissMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDismissMessageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DismissMessageMutation, DismissMessageMutationVariables>) {
        return ApolloReactHooks.useMutation<DismissMessageMutation, DismissMessageMutationVariables>(DismissMessageDocument, baseOptions);
      }
export type DismissMessageMutationHookResult = ReturnType<typeof useDismissMessageMutation>;
export type DismissMessageMutationResult = ApolloReactCommon.MutationResult<DismissMessageMutation>;
export type DismissMessageMutationOptions = ApolloReactCommon.BaseMutationOptions<DismissMessageMutation, DismissMessageMutationVariables>;
export const GetAccessibleCustomersDocument = gql`
    query getAccessibleCustomers {
  accessibleCustomers {
    id
    name
    role
  }
}
    `;
export type GetAccessibleCustomersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAccessibleCustomersQuery, GetAccessibleCustomersQueryVariables>, 'query'>;

    export const GetAccessibleCustomersComponent = (props: GetAccessibleCustomersComponentProps) => (
      <ApolloReactComponents.Query<GetAccessibleCustomersQuery, GetAccessibleCustomersQueryVariables> query={GetAccessibleCustomersDocument} {...props} />
    );
    
export type GetAccessibleCustomersProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetAccessibleCustomersQuery, GetAccessibleCustomersQueryVariables>
    } & TChildProps;
export function withGetAccessibleCustomers<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetAccessibleCustomersQuery,
  GetAccessibleCustomersQueryVariables,
  GetAccessibleCustomersProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetAccessibleCustomersQuery, GetAccessibleCustomersQueryVariables, GetAccessibleCustomersProps<TChildProps, TDataName>>(GetAccessibleCustomersDocument, {
      alias: 'getAccessibleCustomers',
      ...operationOptions
    });
};

/**
 * __useGetAccessibleCustomersQuery__
 *
 * To run a query within a React component, call `useGetAccessibleCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccessibleCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccessibleCustomersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccessibleCustomersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAccessibleCustomersQuery, GetAccessibleCustomersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAccessibleCustomersQuery, GetAccessibleCustomersQueryVariables>(GetAccessibleCustomersDocument, baseOptions);
      }
export function useGetAccessibleCustomersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAccessibleCustomersQuery, GetAccessibleCustomersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAccessibleCustomersQuery, GetAccessibleCustomersQueryVariables>(GetAccessibleCustomersDocument, baseOptions);
        }
export type GetAccessibleCustomersQueryHookResult = ReturnType<typeof useGetAccessibleCustomersQuery>;
export type GetAccessibleCustomersLazyQueryHookResult = ReturnType<typeof useGetAccessibleCustomersLazyQuery>;
export type GetAccessibleCustomersQueryResult = ApolloReactCommon.QueryResult<GetAccessibleCustomersQuery, GetAccessibleCustomersQueryVariables>;
export const GetAccountsDocument = gql`
    query getAccounts($searchCriteria: AccountsSearchCriteria!) {
  accounts(searchCriteria: $searchCriteria) {
    data {
      id
      name
      type
      cloudProvider
      cloudProviderId
    }
  }
}
    `;
export type GetAccountsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAccountsQuery, GetAccountsQueryVariables>, 'query'> & ({ variables: GetAccountsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetAccountsComponent = (props: GetAccountsComponentProps) => (
      <ApolloReactComponents.Query<GetAccountsQuery, GetAccountsQueryVariables> query={GetAccountsDocument} {...props} />
    );
    
export type GetAccountsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetAccountsQuery, GetAccountsQueryVariables>
    } & TChildProps;
export function withGetAccounts<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetAccountsQuery,
  GetAccountsQueryVariables,
  GetAccountsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetAccountsQuery, GetAccountsQueryVariables, GetAccountsProps<TChildProps, TDataName>>(GetAccountsDocument, {
      alias: 'getAccounts',
      ...operationOptions
    });
};

/**
 * __useGetAccountsQuery__
 *
 * To run a query within a React component, call `useGetAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountsQuery({
 *   variables: {
 *      searchCriteria: // value for 'searchCriteria'
 *   },
 * });
 */
export function useGetAccountsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAccountsQuery, GetAccountsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAccountsQuery, GetAccountsQueryVariables>(GetAccountsDocument, baseOptions);
      }
export function useGetAccountsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAccountsQuery, GetAccountsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAccountsQuery, GetAccountsQueryVariables>(GetAccountsDocument, baseOptions);
        }
export type GetAccountsQueryHookResult = ReturnType<typeof useGetAccountsQuery>;
export type GetAccountsLazyQueryHookResult = ReturnType<typeof useGetAccountsLazyQuery>;
export type GetAccountsQueryResult = ApolloReactCommon.QueryResult<GetAccountsQuery, GetAccountsQueryVariables>;
export const GetApiKeyDocument = gql`
    query getApiKey($id: ID!, $customerSearchCriteria: ApiKeyCustomerAccessesSearchCriteria) {
  apiKey(id: $id) {
    alias
    createdAt
    createdBy
    id
    lastUsedAt
    owner
    customerAccesses(searchCriteria: $customerSearchCriteria) {
      data {
        customer {
          id
          name
          backupPoliciesBoundary {
            minRPOInHours
            maxPrimarySnapshotRetentionInDays
            maxPrimarySnapshotRetentionQuantity
            maxReplicaSnapshotRetentionInDays
            maxReplicaSnapshotRetentionQuantity
          }
        }
        roles
      }
      totalNumberOfCustomers
      totalNumberOfPages
    }
  }
}
    `;
export type GetApiKeyComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetApiKeyQuery, GetApiKeyQueryVariables>, 'query'> & ({ variables: GetApiKeyQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetApiKeyComponent = (props: GetApiKeyComponentProps) => (
      <ApolloReactComponents.Query<GetApiKeyQuery, GetApiKeyQueryVariables> query={GetApiKeyDocument} {...props} />
    );
    
export type GetApiKeyProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetApiKeyQuery, GetApiKeyQueryVariables>
    } & TChildProps;
export function withGetApiKey<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetApiKeyQuery,
  GetApiKeyQueryVariables,
  GetApiKeyProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetApiKeyQuery, GetApiKeyQueryVariables, GetApiKeyProps<TChildProps, TDataName>>(GetApiKeyDocument, {
      alias: 'getApiKey',
      ...operationOptions
    });
};

/**
 * __useGetApiKeyQuery__
 *
 * To run a query within a React component, call `useGetApiKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApiKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApiKeyQuery({
 *   variables: {
 *      id: // value for 'id'
 *      customerSearchCriteria: // value for 'customerSearchCriteria'
 *   },
 * });
 */
export function useGetApiKeyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApiKeyQuery, GetApiKeyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApiKeyQuery, GetApiKeyQueryVariables>(GetApiKeyDocument, baseOptions);
      }
export function useGetApiKeyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApiKeyQuery, GetApiKeyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApiKeyQuery, GetApiKeyQueryVariables>(GetApiKeyDocument, baseOptions);
        }
export type GetApiKeyQueryHookResult = ReturnType<typeof useGetApiKeyQuery>;
export type GetApiKeyLazyQueryHookResult = ReturnType<typeof useGetApiKeyLazyQuery>;
export type GetApiKeyQueryResult = ApolloReactCommon.QueryResult<GetApiKeyQuery, GetApiKeyQueryVariables>;
export const GetApiKeysDocument = gql`
    query getApiKeys($searchCriteria: ApiKeysSearchCriteria!) {
  apiKeys(searchCriteria: $searchCriteria) {
    data {
      id
      alias
      owner
      createdAt
      createdBy
      lastUsedAt
      customerAccesses {
        data {
          customer {
            id
            name
            backupPoliciesBoundary {
              minRPOInHours
              maxPrimarySnapshotRetentionInDays
              maxPrimarySnapshotRetentionQuantity
              maxReplicaSnapshotRetentionInDays
              maxReplicaSnapshotRetentionQuantity
            }
          }
          roles
        }
        totalNumberOfCustomers
        totalNumberOfPages
      }
    }
    totalNumberOfPages
    totalNumberOfApiKeys
  }
}
    `;
export type GetApiKeysComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetApiKeysQuery, GetApiKeysQueryVariables>, 'query'> & ({ variables: GetApiKeysQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetApiKeysComponent = (props: GetApiKeysComponentProps) => (
      <ApolloReactComponents.Query<GetApiKeysQuery, GetApiKeysQueryVariables> query={GetApiKeysDocument} {...props} />
    );
    
export type GetApiKeysProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetApiKeysQuery, GetApiKeysQueryVariables>
    } & TChildProps;
export function withGetApiKeys<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetApiKeysQuery,
  GetApiKeysQueryVariables,
  GetApiKeysProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetApiKeysQuery, GetApiKeysQueryVariables, GetApiKeysProps<TChildProps, TDataName>>(GetApiKeysDocument, {
      alias: 'getApiKeys',
      ...operationOptions
    });
};

/**
 * __useGetApiKeysQuery__
 *
 * To run a query within a React component, call `useGetApiKeysQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApiKeysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApiKeysQuery({
 *   variables: {
 *      searchCriteria: // value for 'searchCriteria'
 *   },
 * });
 */
export function useGetApiKeysQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApiKeysQuery, GetApiKeysQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApiKeysQuery, GetApiKeysQueryVariables>(GetApiKeysDocument, baseOptions);
      }
export function useGetApiKeysLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApiKeysQuery, GetApiKeysQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApiKeysQuery, GetApiKeysQueryVariables>(GetApiKeysDocument, baseOptions);
        }
export type GetApiKeysQueryHookResult = ReturnType<typeof useGetApiKeysQuery>;
export type GetApiKeysLazyQueryHookResult = ReturnType<typeof useGetApiKeysLazyQuery>;
export type GetApiKeysQueryResult = ApolloReactCommon.QueryResult<GetApiKeysQuery, GetApiKeysQueryVariables>;
export const GetAwsAccountAccessRoleTemplateDocument = gql`
    query getAwsAccountAccessRoleTemplate($input: AccountAwsAccessRoleTemplateInput!) {
  awsAccountAccessRoleTemplate(input: $input)
}
    `;
export type GetAwsAccountAccessRoleTemplateComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAwsAccountAccessRoleTemplateQuery, GetAwsAccountAccessRoleTemplateQueryVariables>, 'query'> & ({ variables: GetAwsAccountAccessRoleTemplateQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetAwsAccountAccessRoleTemplateComponent = (props: GetAwsAccountAccessRoleTemplateComponentProps) => (
      <ApolloReactComponents.Query<GetAwsAccountAccessRoleTemplateQuery, GetAwsAccountAccessRoleTemplateQueryVariables> query={GetAwsAccountAccessRoleTemplateDocument} {...props} />
    );
    
export type GetAwsAccountAccessRoleTemplateProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetAwsAccountAccessRoleTemplateQuery, GetAwsAccountAccessRoleTemplateQueryVariables>
    } & TChildProps;
export function withGetAwsAccountAccessRoleTemplate<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetAwsAccountAccessRoleTemplateQuery,
  GetAwsAccountAccessRoleTemplateQueryVariables,
  GetAwsAccountAccessRoleTemplateProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetAwsAccountAccessRoleTemplateQuery, GetAwsAccountAccessRoleTemplateQueryVariables, GetAwsAccountAccessRoleTemplateProps<TChildProps, TDataName>>(GetAwsAccountAccessRoleTemplateDocument, {
      alias: 'getAwsAccountAccessRoleTemplate',
      ...operationOptions
    });
};

/**
 * __useGetAwsAccountAccessRoleTemplateQuery__
 *
 * To run a query within a React component, call `useGetAwsAccountAccessRoleTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAwsAccountAccessRoleTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAwsAccountAccessRoleTemplateQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAwsAccountAccessRoleTemplateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAwsAccountAccessRoleTemplateQuery, GetAwsAccountAccessRoleTemplateQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAwsAccountAccessRoleTemplateQuery, GetAwsAccountAccessRoleTemplateQueryVariables>(GetAwsAccountAccessRoleTemplateDocument, baseOptions);
      }
export function useGetAwsAccountAccessRoleTemplateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAwsAccountAccessRoleTemplateQuery, GetAwsAccountAccessRoleTemplateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAwsAccountAccessRoleTemplateQuery, GetAwsAccountAccessRoleTemplateQueryVariables>(GetAwsAccountAccessRoleTemplateDocument, baseOptions);
        }
export type GetAwsAccountAccessRoleTemplateQueryHookResult = ReturnType<typeof useGetAwsAccountAccessRoleTemplateQuery>;
export type GetAwsAccountAccessRoleTemplateLazyQueryHookResult = ReturnType<typeof useGetAwsAccountAccessRoleTemplateLazyQuery>;
export type GetAwsAccountAccessRoleTemplateQueryResult = ApolloReactCommon.QueryResult<GetAwsAccountAccessRoleTemplateQuery, GetAwsAccountAccessRoleTemplateQueryVariables>;
export const GetAwsChinaAccountAccessRoleTemplateDocument = gql`
    query getAwsChinaAccountAccessRoleTemplate($input: AccountAwsChinaAccessRoleTemplateInput!) {
  awsChinaAccountAccessRoleTemplate(input: $input)
}
    `;
export type GetAwsChinaAccountAccessRoleTemplateComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAwsChinaAccountAccessRoleTemplateQuery, GetAwsChinaAccountAccessRoleTemplateQueryVariables>, 'query'> & ({ variables: GetAwsChinaAccountAccessRoleTemplateQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetAwsChinaAccountAccessRoleTemplateComponent = (props: GetAwsChinaAccountAccessRoleTemplateComponentProps) => (
      <ApolloReactComponents.Query<GetAwsChinaAccountAccessRoleTemplateQuery, GetAwsChinaAccountAccessRoleTemplateQueryVariables> query={GetAwsChinaAccountAccessRoleTemplateDocument} {...props} />
    );
    
export type GetAwsChinaAccountAccessRoleTemplateProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetAwsChinaAccountAccessRoleTemplateQuery, GetAwsChinaAccountAccessRoleTemplateQueryVariables>
    } & TChildProps;
export function withGetAwsChinaAccountAccessRoleTemplate<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetAwsChinaAccountAccessRoleTemplateQuery,
  GetAwsChinaAccountAccessRoleTemplateQueryVariables,
  GetAwsChinaAccountAccessRoleTemplateProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetAwsChinaAccountAccessRoleTemplateQuery, GetAwsChinaAccountAccessRoleTemplateQueryVariables, GetAwsChinaAccountAccessRoleTemplateProps<TChildProps, TDataName>>(GetAwsChinaAccountAccessRoleTemplateDocument, {
      alias: 'getAwsChinaAccountAccessRoleTemplate',
      ...operationOptions
    });
};

/**
 * __useGetAwsChinaAccountAccessRoleTemplateQuery__
 *
 * To run a query within a React component, call `useGetAwsChinaAccountAccessRoleTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAwsChinaAccountAccessRoleTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAwsChinaAccountAccessRoleTemplateQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAwsChinaAccountAccessRoleTemplateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAwsChinaAccountAccessRoleTemplateQuery, GetAwsChinaAccountAccessRoleTemplateQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAwsChinaAccountAccessRoleTemplateQuery, GetAwsChinaAccountAccessRoleTemplateQueryVariables>(GetAwsChinaAccountAccessRoleTemplateDocument, baseOptions);
      }
export function useGetAwsChinaAccountAccessRoleTemplateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAwsChinaAccountAccessRoleTemplateQuery, GetAwsChinaAccountAccessRoleTemplateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAwsChinaAccountAccessRoleTemplateQuery, GetAwsChinaAccountAccessRoleTemplateQueryVariables>(GetAwsChinaAccountAccessRoleTemplateDocument, baseOptions);
        }
export type GetAwsChinaAccountAccessRoleTemplateQueryHookResult = ReturnType<typeof useGetAwsChinaAccountAccessRoleTemplateQuery>;
export type GetAwsChinaAccountAccessRoleTemplateLazyQueryHookResult = ReturnType<typeof useGetAwsChinaAccountAccessRoleTemplateLazyQuery>;
export type GetAwsChinaAccountAccessRoleTemplateQueryResult = ApolloReactCommon.QueryResult<GetAwsChinaAccountAccessRoleTemplateQuery, GetAwsChinaAccountAccessRoleTemplateQueryVariables>;
export const GetBackupEventDocument = gql`
    query getBackupEvent($searchCriteria: BackupEventSearchCriteria!) {
  backupEvent(searchCriteria: $searchCriteria) {
    id
    planName
    totalNumberOfResources
    numberOfBackedUpResources
    startTimestamp
    progress
    status
    type
  }
}
    `;
export type GetBackupEventComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetBackupEventQuery, GetBackupEventQueryVariables>, 'query'> & ({ variables: GetBackupEventQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetBackupEventComponent = (props: GetBackupEventComponentProps) => (
      <ApolloReactComponents.Query<GetBackupEventQuery, GetBackupEventQueryVariables> query={GetBackupEventDocument} {...props} />
    );
    
export type GetBackupEventProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetBackupEventQuery, GetBackupEventQueryVariables>
    } & TChildProps;
export function withGetBackupEvent<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetBackupEventQuery,
  GetBackupEventQueryVariables,
  GetBackupEventProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetBackupEventQuery, GetBackupEventQueryVariables, GetBackupEventProps<TChildProps, TDataName>>(GetBackupEventDocument, {
      alias: 'getBackupEvent',
      ...operationOptions
    });
};

/**
 * __useGetBackupEventQuery__
 *
 * To run a query within a React component, call `useGetBackupEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBackupEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBackupEventQuery({
 *   variables: {
 *      searchCriteria: // value for 'searchCriteria'
 *   },
 * });
 */
export function useGetBackupEventQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBackupEventQuery, GetBackupEventQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBackupEventQuery, GetBackupEventQueryVariables>(GetBackupEventDocument, baseOptions);
      }
export function useGetBackupEventLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBackupEventQuery, GetBackupEventQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBackupEventQuery, GetBackupEventQueryVariables>(GetBackupEventDocument, baseOptions);
        }
export type GetBackupEventQueryHookResult = ReturnType<typeof useGetBackupEventQuery>;
export type GetBackupEventLazyQueryHookResult = ReturnType<typeof useGetBackupEventLazyQuery>;
export type GetBackupEventQueryResult = ApolloReactCommon.QueryResult<GetBackupEventQuery, GetBackupEventQueryVariables>;
export const GetBackupEventResourcesDocument = gql`
    query getBackupEventResources($searchCriteria: BackupEventResourcesSearchCriteria!) {
  backupEventResources(searchCriteria: $searchCriteria) {
    data {
      resource {
        id
        name
        type
        accountCloudProviderId
        accountName
        cloudProvider
        backupSetupName
        backupPolicy {
          name
        }
        category
        backupEnabled
      }
      progress
      status
      snapshot {
        id
        accountId
        region
      }
      snapshotReplica {
        id
        accountId
        region
      }
      failureReason
      replicaFailureReason
    }
    totalNumberOfResources
    totalNumberOfPages
  }
}
    `;
export type GetBackupEventResourcesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetBackupEventResourcesQuery, GetBackupEventResourcesQueryVariables>, 'query'> & ({ variables: GetBackupEventResourcesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetBackupEventResourcesComponent = (props: GetBackupEventResourcesComponentProps) => (
      <ApolloReactComponents.Query<GetBackupEventResourcesQuery, GetBackupEventResourcesQueryVariables> query={GetBackupEventResourcesDocument} {...props} />
    );
    
export type GetBackupEventResourcesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetBackupEventResourcesQuery, GetBackupEventResourcesQueryVariables>
    } & TChildProps;
export function withGetBackupEventResources<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetBackupEventResourcesQuery,
  GetBackupEventResourcesQueryVariables,
  GetBackupEventResourcesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetBackupEventResourcesQuery, GetBackupEventResourcesQueryVariables, GetBackupEventResourcesProps<TChildProps, TDataName>>(GetBackupEventResourcesDocument, {
      alias: 'getBackupEventResources',
      ...operationOptions
    });
};

/**
 * __useGetBackupEventResourcesQuery__
 *
 * To run a query within a React component, call `useGetBackupEventResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBackupEventResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBackupEventResourcesQuery({
 *   variables: {
 *      searchCriteria: // value for 'searchCriteria'
 *   },
 * });
 */
export function useGetBackupEventResourcesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBackupEventResourcesQuery, GetBackupEventResourcesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBackupEventResourcesQuery, GetBackupEventResourcesQueryVariables>(GetBackupEventResourcesDocument, baseOptions);
      }
export function useGetBackupEventResourcesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBackupEventResourcesQuery, GetBackupEventResourcesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBackupEventResourcesQuery, GetBackupEventResourcesQueryVariables>(GetBackupEventResourcesDocument, baseOptions);
        }
export type GetBackupEventResourcesQueryHookResult = ReturnType<typeof useGetBackupEventResourcesQuery>;
export type GetBackupEventResourcesLazyQueryHookResult = ReturnType<typeof useGetBackupEventResourcesLazyQuery>;
export type GetBackupEventResourcesQueryResult = ApolloReactCommon.QueryResult<GetBackupEventResourcesQuery, GetBackupEventResourcesQueryVariables>;
export const GetBackupEventStatsDocument = gql`
    query getBackupEventStats($searchCriteria: BackupEventStatsSearchCriteria!) {
  backupEventStats(searchCriteria: $searchCriteria) {
    numberOfResourcesMeetingRPO
    numberOfBackedUpResources
    totalNumberOfResources
    successRateHistory {
      numberOfResourcesMeetingRPO
      totalNumberOfResources
      startTimestamp
      backupEventId
    }
  }
}
    `;
export type GetBackupEventStatsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetBackupEventStatsQuery, GetBackupEventStatsQueryVariables>, 'query'> & ({ variables: GetBackupEventStatsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetBackupEventStatsComponent = (props: GetBackupEventStatsComponentProps) => (
      <ApolloReactComponents.Query<GetBackupEventStatsQuery, GetBackupEventStatsQueryVariables> query={GetBackupEventStatsDocument} {...props} />
    );
    
export type GetBackupEventStatsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetBackupEventStatsQuery, GetBackupEventStatsQueryVariables>
    } & TChildProps;
export function withGetBackupEventStats<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetBackupEventStatsQuery,
  GetBackupEventStatsQueryVariables,
  GetBackupEventStatsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetBackupEventStatsQuery, GetBackupEventStatsQueryVariables, GetBackupEventStatsProps<TChildProps, TDataName>>(GetBackupEventStatsDocument, {
      alias: 'getBackupEventStats',
      ...operationOptions
    });
};

/**
 * __useGetBackupEventStatsQuery__
 *
 * To run a query within a React component, call `useGetBackupEventStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBackupEventStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBackupEventStatsQuery({
 *   variables: {
 *      searchCriteria: // value for 'searchCriteria'
 *   },
 * });
 */
export function useGetBackupEventStatsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBackupEventStatsQuery, GetBackupEventStatsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBackupEventStatsQuery, GetBackupEventStatsQueryVariables>(GetBackupEventStatsDocument, baseOptions);
      }
export function useGetBackupEventStatsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBackupEventStatsQuery, GetBackupEventStatsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBackupEventStatsQuery, GetBackupEventStatsQueryVariables>(GetBackupEventStatsDocument, baseOptions);
        }
export type GetBackupEventStatsQueryHookResult = ReturnType<typeof useGetBackupEventStatsQuery>;
export type GetBackupEventStatsLazyQueryHookResult = ReturnType<typeof useGetBackupEventStatsLazyQuery>;
export type GetBackupEventStatsQueryResult = ApolloReactCommon.QueryResult<GetBackupEventStatsQuery, GetBackupEventStatsQueryVariables>;
export const GetBackupEventsDocument = gql`
    query getBackupEvents($searchCriteria: BackupEventsSearchCriteria!) {
  backupEvents(searchCriteria: $searchCriteria) {
    data {
      id
      planName
      totalNumberOfResources
      numberOfBackedUpResources
      startTimestamp
      progress
      status
      type
    }
    totalNumberOfPages
    totalNumberOfEvents
  }
}
    `;
export type GetBackupEventsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetBackupEventsQuery, GetBackupEventsQueryVariables>, 'query'> & ({ variables: GetBackupEventsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetBackupEventsComponent = (props: GetBackupEventsComponentProps) => (
      <ApolloReactComponents.Query<GetBackupEventsQuery, GetBackupEventsQueryVariables> query={GetBackupEventsDocument} {...props} />
    );
    
export type GetBackupEventsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetBackupEventsQuery, GetBackupEventsQueryVariables>
    } & TChildProps;
export function withGetBackupEvents<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetBackupEventsQuery,
  GetBackupEventsQueryVariables,
  GetBackupEventsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetBackupEventsQuery, GetBackupEventsQueryVariables, GetBackupEventsProps<TChildProps, TDataName>>(GetBackupEventsDocument, {
      alias: 'getBackupEvents',
      ...operationOptions
    });
};

/**
 * __useGetBackupEventsQuery__
 *
 * To run a query within a React component, call `useGetBackupEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBackupEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBackupEventsQuery({
 *   variables: {
 *      searchCriteria: // value for 'searchCriteria'
 *   },
 * });
 */
export function useGetBackupEventsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBackupEventsQuery, GetBackupEventsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBackupEventsQuery, GetBackupEventsQueryVariables>(GetBackupEventsDocument, baseOptions);
      }
export function useGetBackupEventsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBackupEventsQuery, GetBackupEventsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBackupEventsQuery, GetBackupEventsQueryVariables>(GetBackupEventsDocument, baseOptions);
        }
export type GetBackupEventsQueryHookResult = ReturnType<typeof useGetBackupEventsQuery>;
export type GetBackupEventsLazyQueryHookResult = ReturnType<typeof useGetBackupEventsLazyQuery>;
export type GetBackupEventsQueryResult = ApolloReactCommon.QueryResult<GetBackupEventsQuery, GetBackupEventsQueryVariables>;
export const GetBackupHistoryDocument = gql`
    query getBackupHistory($customerId: String!) {
  backupHistory(customerId: $customerId) {
    eventCount
    month
  }
}
    `;
export type GetBackupHistoryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetBackupHistoryQuery, GetBackupHistoryQueryVariables>, 'query'> & ({ variables: GetBackupHistoryQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetBackupHistoryComponent = (props: GetBackupHistoryComponentProps) => (
      <ApolloReactComponents.Query<GetBackupHistoryQuery, GetBackupHistoryQueryVariables> query={GetBackupHistoryDocument} {...props} />
    );
    
export type GetBackupHistoryProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetBackupHistoryQuery, GetBackupHistoryQueryVariables>
    } & TChildProps;
export function withGetBackupHistory<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetBackupHistoryQuery,
  GetBackupHistoryQueryVariables,
  GetBackupHistoryProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetBackupHistoryQuery, GetBackupHistoryQueryVariables, GetBackupHistoryProps<TChildProps, TDataName>>(GetBackupHistoryDocument, {
      alias: 'getBackupHistory',
      ...operationOptions
    });
};

/**
 * __useGetBackupHistoryQuery__
 *
 * To run a query within a React component, call `useGetBackupHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBackupHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBackupHistoryQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useGetBackupHistoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBackupHistoryQuery, GetBackupHistoryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBackupHistoryQuery, GetBackupHistoryQueryVariables>(GetBackupHistoryDocument, baseOptions);
      }
export function useGetBackupHistoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBackupHistoryQuery, GetBackupHistoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBackupHistoryQuery, GetBackupHistoryQueryVariables>(GetBackupHistoryDocument, baseOptions);
        }
export type GetBackupHistoryQueryHookResult = ReturnType<typeof useGetBackupHistoryQuery>;
export type GetBackupHistoryLazyQueryHookResult = ReturnType<typeof useGetBackupHistoryLazyQuery>;
export type GetBackupHistoryQueryResult = ApolloReactCommon.QueryResult<GetBackupHistoryQuery, GetBackupHistoryQueryVariables>;
export const GetBackupPoliciesDocument = gql`
    query getBackupPolicies($customerId: String!) {
  backupPolicies(customerId: $customerId) {
    id
    name
    type
    tagValue
    policySettings {
      rpo
      frequency
      retention {
        primarySnapshotsRetention {
          condition
          threshold
        }
        replicaSnapshotsRetention {
          condition
          threshold
        }
      }
    }
    timeAwarePolicySettings {
      cronExpression
      startDateTimestamp
      timeWindowDuration
      retention {
        primarySnapshotsRetention {
          condition
          threshold
        }
        replicaSnapshotsRetention {
          condition
          threshold
        }
      }
    }
  }
}
    `;
export type GetBackupPoliciesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetBackupPoliciesQuery, GetBackupPoliciesQueryVariables>, 'query'> & ({ variables: GetBackupPoliciesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetBackupPoliciesComponent = (props: GetBackupPoliciesComponentProps) => (
      <ApolloReactComponents.Query<GetBackupPoliciesQuery, GetBackupPoliciesQueryVariables> query={GetBackupPoliciesDocument} {...props} />
    );
    
export type GetBackupPoliciesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetBackupPoliciesQuery, GetBackupPoliciesQueryVariables>
    } & TChildProps;
export function withGetBackupPolicies<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetBackupPoliciesQuery,
  GetBackupPoliciesQueryVariables,
  GetBackupPoliciesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetBackupPoliciesQuery, GetBackupPoliciesQueryVariables, GetBackupPoliciesProps<TChildProps, TDataName>>(GetBackupPoliciesDocument, {
      alias: 'getBackupPolicies',
      ...operationOptions
    });
};

/**
 * __useGetBackupPoliciesQuery__
 *
 * To run a query within a React component, call `useGetBackupPoliciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBackupPoliciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBackupPoliciesQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useGetBackupPoliciesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBackupPoliciesQuery, GetBackupPoliciesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBackupPoliciesQuery, GetBackupPoliciesQueryVariables>(GetBackupPoliciesDocument, baseOptions);
      }
export function useGetBackupPoliciesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBackupPoliciesQuery, GetBackupPoliciesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBackupPoliciesQuery, GetBackupPoliciesQueryVariables>(GetBackupPoliciesDocument, baseOptions);
        }
export type GetBackupPoliciesQueryHookResult = ReturnType<typeof useGetBackupPoliciesQuery>;
export type GetBackupPoliciesLazyQueryHookResult = ReturnType<typeof useGetBackupPoliciesLazyQuery>;
export type GetBackupPoliciesQueryResult = ApolloReactCommon.QueryResult<GetBackupPoliciesQuery, GetBackupPoliciesQueryVariables>;
export const GetBackupPolicyDocument = gql`
    query getBackupPolicy($searchCriteria: BackupPolicySearchCriteria!) {
  backupPolicy(searchCriteria: $searchCriteria) {
    id
    name
    type
    tagValue
    removePrimarySnapshotsAfterResourceDeletion
    removeReplicaSnapshotsAfterResourceDeletion
    policySettings {
      rpo
      frequency
      retention {
        primarySnapshotsRetention {
          condition
          threshold
        }
        replicaSnapshotsRetention {
          condition
          threshold
        }
      }
    }
    timeAwarePolicySettings {
      cronExpression
      startDateTimestamp
      timeWindowDuration
      retention {
        primarySnapshotsRetention {
          condition
          threshold
        }
        replicaSnapshotsRetention {
          condition
          threshold
        }
      }
    }
  }
}
    `;
export type GetBackupPolicyComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetBackupPolicyQuery, GetBackupPolicyQueryVariables>, 'query'> & ({ variables: GetBackupPolicyQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetBackupPolicyComponent = (props: GetBackupPolicyComponentProps) => (
      <ApolloReactComponents.Query<GetBackupPolicyQuery, GetBackupPolicyQueryVariables> query={GetBackupPolicyDocument} {...props} />
    );
    
export type GetBackupPolicyProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetBackupPolicyQuery, GetBackupPolicyQueryVariables>
    } & TChildProps;
export function withGetBackupPolicy<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetBackupPolicyQuery,
  GetBackupPolicyQueryVariables,
  GetBackupPolicyProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetBackupPolicyQuery, GetBackupPolicyQueryVariables, GetBackupPolicyProps<TChildProps, TDataName>>(GetBackupPolicyDocument, {
      alias: 'getBackupPolicy',
      ...operationOptions
    });
};

/**
 * __useGetBackupPolicyQuery__
 *
 * To run a query within a React component, call `useGetBackupPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBackupPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBackupPolicyQuery({
 *   variables: {
 *      searchCriteria: // value for 'searchCriteria'
 *   },
 * });
 */
export function useGetBackupPolicyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBackupPolicyQuery, GetBackupPolicyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBackupPolicyQuery, GetBackupPolicyQueryVariables>(GetBackupPolicyDocument, baseOptions);
      }
export function useGetBackupPolicyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBackupPolicyQuery, GetBackupPolicyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBackupPolicyQuery, GetBackupPolicyQueryVariables>(GetBackupPolicyDocument, baseOptions);
        }
export type GetBackupPolicyQueryHookResult = ReturnType<typeof useGetBackupPolicyQuery>;
export type GetBackupPolicyLazyQueryHookResult = ReturnType<typeof useGetBackupPolicyLazyQuery>;
export type GetBackupPolicyQueryResult = ApolloReactCommon.QueryResult<GetBackupPolicyQuery, GetBackupPolicyQueryVariables>;
export const GetBackupSetupDocument = gql`
    query getBackupSetup($searchCriteria: BackupSetupSearchCriteria!) {
  backupSetup(searchCriteria: $searchCriteria) {
    id
    customerId
    name
    tagValue
    resourceType
    preHook {
      type
      endpoint
    }
    postHook {
      type
      endpoint
    }
    backupSettings {
      id
      name
      type
      label
      description
      value
    }
  }
}
    `;
export type GetBackupSetupComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetBackupSetupQuery, GetBackupSetupQueryVariables>, 'query'> & ({ variables: GetBackupSetupQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetBackupSetupComponent = (props: GetBackupSetupComponentProps) => (
      <ApolloReactComponents.Query<GetBackupSetupQuery, GetBackupSetupQueryVariables> query={GetBackupSetupDocument} {...props} />
    );
    
export type GetBackupSetupProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetBackupSetupQuery, GetBackupSetupQueryVariables>
    } & TChildProps;
export function withGetBackupSetup<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetBackupSetupQuery,
  GetBackupSetupQueryVariables,
  GetBackupSetupProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetBackupSetupQuery, GetBackupSetupQueryVariables, GetBackupSetupProps<TChildProps, TDataName>>(GetBackupSetupDocument, {
      alias: 'getBackupSetup',
      ...operationOptions
    });
};

/**
 * __useGetBackupSetupQuery__
 *
 * To run a query within a React component, call `useGetBackupSetupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBackupSetupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBackupSetupQuery({
 *   variables: {
 *      searchCriteria: // value for 'searchCriteria'
 *   },
 * });
 */
export function useGetBackupSetupQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBackupSetupQuery, GetBackupSetupQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBackupSetupQuery, GetBackupSetupQueryVariables>(GetBackupSetupDocument, baseOptions);
      }
export function useGetBackupSetupLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBackupSetupQuery, GetBackupSetupQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBackupSetupQuery, GetBackupSetupQueryVariables>(GetBackupSetupDocument, baseOptions);
        }
export type GetBackupSetupQueryHookResult = ReturnType<typeof useGetBackupSetupQuery>;
export type GetBackupSetupLazyQueryHookResult = ReturnType<typeof useGetBackupSetupLazyQuery>;
export type GetBackupSetupQueryResult = ApolloReactCommon.QueryResult<GetBackupSetupQuery, GetBackupSetupQueryVariables>;
export const GetBackupSetupsDocument = gql`
    query getBackupSetups($searchCriteria: BackupSetupsSearchCriteria!) {
  backupSetups(searchCriteria: $searchCriteria) {
    id
    name
    tagValue
    resourceType
    preHook {
      type
      endpoint
    }
    postHook {
      type
      endpoint
    }
    backupSettings {
      id
      label
      description
      value
      type
    }
  }
}
    `;
export type GetBackupSetupsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetBackupSetupsQuery, GetBackupSetupsQueryVariables>, 'query'> & ({ variables: GetBackupSetupsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetBackupSetupsComponent = (props: GetBackupSetupsComponentProps) => (
      <ApolloReactComponents.Query<GetBackupSetupsQuery, GetBackupSetupsQueryVariables> query={GetBackupSetupsDocument} {...props} />
    );
    
export type GetBackupSetupsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetBackupSetupsQuery, GetBackupSetupsQueryVariables>
    } & TChildProps;
export function withGetBackupSetups<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetBackupSetupsQuery,
  GetBackupSetupsQueryVariables,
  GetBackupSetupsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetBackupSetupsQuery, GetBackupSetupsQueryVariables, GetBackupSetupsProps<TChildProps, TDataName>>(GetBackupSetupsDocument, {
      alias: 'getBackupSetups',
      ...operationOptions
    });
};

/**
 * __useGetBackupSetupsQuery__
 *
 * To run a query within a React component, call `useGetBackupSetupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBackupSetupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBackupSetupsQuery({
 *   variables: {
 *      searchCriteria: // value for 'searchCriteria'
 *   },
 * });
 */
export function useGetBackupSetupsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBackupSetupsQuery, GetBackupSetupsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBackupSetupsQuery, GetBackupSetupsQueryVariables>(GetBackupSetupsDocument, baseOptions);
      }
export function useGetBackupSetupsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBackupSetupsQuery, GetBackupSetupsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBackupSetupsQuery, GetBackupSetupsQueryVariables>(GetBackupSetupsDocument, baseOptions);
        }
export type GetBackupSetupsQueryHookResult = ReturnType<typeof useGetBackupSetupsQuery>;
export type GetBackupSetupsLazyQueryHookResult = ReturnType<typeof useGetBackupSetupsLazyQuery>;
export type GetBackupSetupsQueryResult = ApolloReactCommon.QueryResult<GetBackupSetupsQuery, GetBackupSetupsQueryVariables>;
export const GetBackupTagNamesDocument = gql`
    query getBackupTagNames($customerId: String!) {
  backupTagNames(customerId: $customerId) {
    backupEnabledTagNames
    policyTagNames
    setupTagNames
  }
}
    `;
export type GetBackupTagNamesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetBackupTagNamesQuery, GetBackupTagNamesQueryVariables>, 'query'> & ({ variables: GetBackupTagNamesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetBackupTagNamesComponent = (props: GetBackupTagNamesComponentProps) => (
      <ApolloReactComponents.Query<GetBackupTagNamesQuery, GetBackupTagNamesQueryVariables> query={GetBackupTagNamesDocument} {...props} />
    );
    
export type GetBackupTagNamesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetBackupTagNamesQuery, GetBackupTagNamesQueryVariables>
    } & TChildProps;
export function withGetBackupTagNames<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetBackupTagNamesQuery,
  GetBackupTagNamesQueryVariables,
  GetBackupTagNamesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetBackupTagNamesQuery, GetBackupTagNamesQueryVariables, GetBackupTagNamesProps<TChildProps, TDataName>>(GetBackupTagNamesDocument, {
      alias: 'getBackupTagNames',
      ...operationOptions
    });
};

/**
 * __useGetBackupTagNamesQuery__
 *
 * To run a query within a React component, call `useGetBackupTagNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBackupTagNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBackupTagNamesQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useGetBackupTagNamesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBackupTagNamesQuery, GetBackupTagNamesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBackupTagNamesQuery, GetBackupTagNamesQueryVariables>(GetBackupTagNamesDocument, baseOptions);
      }
export function useGetBackupTagNamesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBackupTagNamesQuery, GetBackupTagNamesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBackupTagNamesQuery, GetBackupTagNamesQueryVariables>(GetBackupTagNamesDocument, baseOptions);
        }
export type GetBackupTagNamesQueryHookResult = ReturnType<typeof useGetBackupTagNamesQuery>;
export type GetBackupTagNamesLazyQueryHookResult = ReturnType<typeof useGetBackupTagNamesLazyQuery>;
export type GetBackupTagNamesQueryResult = ApolloReactCommon.QueryResult<GetBackupTagNamesQuery, GetBackupTagNamesQueryVariables>;
export const GetBestAllowedPolicySettingsDocument = gql`
    query getBestAllowedPolicySettings($customerId: String!) {
  bestAllowedPolicySettings(customerId: $customerId) {
    minRPOInHours
    maxPrimarySnapshotRetentionQuantity
    maxPrimarySnapshotRetentionInDays
    maxReplicaSnapshotRetentionQuantity
    maxReplicaSnapshotRetentionInDays
  }
}
    `;
export type GetBestAllowedPolicySettingsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetBestAllowedPolicySettingsQuery, GetBestAllowedPolicySettingsQueryVariables>, 'query'> & ({ variables: GetBestAllowedPolicySettingsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetBestAllowedPolicySettingsComponent = (props: GetBestAllowedPolicySettingsComponentProps) => (
      <ApolloReactComponents.Query<GetBestAllowedPolicySettingsQuery, GetBestAllowedPolicySettingsQueryVariables> query={GetBestAllowedPolicySettingsDocument} {...props} />
    );
    
export type GetBestAllowedPolicySettingsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetBestAllowedPolicySettingsQuery, GetBestAllowedPolicySettingsQueryVariables>
    } & TChildProps;
export function withGetBestAllowedPolicySettings<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetBestAllowedPolicySettingsQuery,
  GetBestAllowedPolicySettingsQueryVariables,
  GetBestAllowedPolicySettingsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetBestAllowedPolicySettingsQuery, GetBestAllowedPolicySettingsQueryVariables, GetBestAllowedPolicySettingsProps<TChildProps, TDataName>>(GetBestAllowedPolicySettingsDocument, {
      alias: 'getBestAllowedPolicySettings',
      ...operationOptions
    });
};

/**
 * __useGetBestAllowedPolicySettingsQuery__
 *
 * To run a query within a React component, call `useGetBestAllowedPolicySettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBestAllowedPolicySettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBestAllowedPolicySettingsQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useGetBestAllowedPolicySettingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBestAllowedPolicySettingsQuery, GetBestAllowedPolicySettingsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBestAllowedPolicySettingsQuery, GetBestAllowedPolicySettingsQueryVariables>(GetBestAllowedPolicySettingsDocument, baseOptions);
      }
export function useGetBestAllowedPolicySettingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBestAllowedPolicySettingsQuery, GetBestAllowedPolicySettingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBestAllowedPolicySettingsQuery, GetBestAllowedPolicySettingsQueryVariables>(GetBestAllowedPolicySettingsDocument, baseOptions);
        }
export type GetBestAllowedPolicySettingsQueryHookResult = ReturnType<typeof useGetBestAllowedPolicySettingsQuery>;
export type GetBestAllowedPolicySettingsLazyQueryHookResult = ReturnType<typeof useGetBestAllowedPolicySettingsLazyQuery>;
export type GetBestAllowedPolicySettingsQueryResult = ApolloReactCommon.QueryResult<GetBestAllowedPolicySettingsQuery, GetBestAllowedPolicySettingsQueryVariables>;
export const GetCategoriesWithResourcesMappingDocument = gql`
    query getCategoriesWithResourcesMapping {
  categoriesWithResourcesMapping {
    category
    resources
  }
}
    `;
export type GetCategoriesWithResourcesMappingComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCategoriesWithResourcesMappingQuery, GetCategoriesWithResourcesMappingQueryVariables>, 'query'>;

    export const GetCategoriesWithResourcesMappingComponent = (props: GetCategoriesWithResourcesMappingComponentProps) => (
      <ApolloReactComponents.Query<GetCategoriesWithResourcesMappingQuery, GetCategoriesWithResourcesMappingQueryVariables> query={GetCategoriesWithResourcesMappingDocument} {...props} />
    );
    
export type GetCategoriesWithResourcesMappingProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetCategoriesWithResourcesMappingQuery, GetCategoriesWithResourcesMappingQueryVariables>
    } & TChildProps;
export function withGetCategoriesWithResourcesMapping<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCategoriesWithResourcesMappingQuery,
  GetCategoriesWithResourcesMappingQueryVariables,
  GetCategoriesWithResourcesMappingProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetCategoriesWithResourcesMappingQuery, GetCategoriesWithResourcesMappingQueryVariables, GetCategoriesWithResourcesMappingProps<TChildProps, TDataName>>(GetCategoriesWithResourcesMappingDocument, {
      alias: 'getCategoriesWithResourcesMapping',
      ...operationOptions
    });
};

/**
 * __useGetCategoriesWithResourcesMappingQuery__
 *
 * To run a query within a React component, call `useGetCategoriesWithResourcesMappingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoriesWithResourcesMappingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoriesWithResourcesMappingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCategoriesWithResourcesMappingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCategoriesWithResourcesMappingQuery, GetCategoriesWithResourcesMappingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCategoriesWithResourcesMappingQuery, GetCategoriesWithResourcesMappingQueryVariables>(GetCategoriesWithResourcesMappingDocument, baseOptions);
      }
export function useGetCategoriesWithResourcesMappingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCategoriesWithResourcesMappingQuery, GetCategoriesWithResourcesMappingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCategoriesWithResourcesMappingQuery, GetCategoriesWithResourcesMappingQueryVariables>(GetCategoriesWithResourcesMappingDocument, baseOptions);
        }
export type GetCategoriesWithResourcesMappingQueryHookResult = ReturnType<typeof useGetCategoriesWithResourcesMappingQuery>;
export type GetCategoriesWithResourcesMappingLazyQueryHookResult = ReturnType<typeof useGetCategoriesWithResourcesMappingLazyQuery>;
export type GetCategoriesWithResourcesMappingQueryResult = ApolloReactCommon.QueryResult<GetCategoriesWithResourcesMappingQuery, GetCategoriesWithResourcesMappingQueryVariables>;
export const GetCustomerResourcesDocument = gql`
    query getCustomerResources($searchCriteria: ResourcesSearchCriteria!) {
  resources(searchCriteria: $searchCriteria) {
    data {
      resource {
        id
        name
        type
        accountCloudProviderId
        accountName
        cloudProvider
        backupSetupName
        backupPolicy {
          id
          name
          settings {
            rpoInHours
            retention {
              primarySnapshotsRetention {
                condition
                threshold
              }
              replicaSnapshotsRetention {
                condition
                threshold
              }
            }
          }
        }
        category
        backupEnabled
      }
    }
    totalNumberOfPages
    totalNumberOfResources
  }
}
    `;
export type GetCustomerResourcesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCustomerResourcesQuery, GetCustomerResourcesQueryVariables>, 'query'> & ({ variables: GetCustomerResourcesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetCustomerResourcesComponent = (props: GetCustomerResourcesComponentProps) => (
      <ApolloReactComponents.Query<GetCustomerResourcesQuery, GetCustomerResourcesQueryVariables> query={GetCustomerResourcesDocument} {...props} />
    );
    
export type GetCustomerResourcesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetCustomerResourcesQuery, GetCustomerResourcesQueryVariables>
    } & TChildProps;
export function withGetCustomerResources<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCustomerResourcesQuery,
  GetCustomerResourcesQueryVariables,
  GetCustomerResourcesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetCustomerResourcesQuery, GetCustomerResourcesQueryVariables, GetCustomerResourcesProps<TChildProps, TDataName>>(GetCustomerResourcesDocument, {
      alias: 'getCustomerResources',
      ...operationOptions
    });
};

/**
 * __useGetCustomerResourcesQuery__
 *
 * To run a query within a React component, call `useGetCustomerResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerResourcesQuery({
 *   variables: {
 *      searchCriteria: // value for 'searchCriteria'
 *   },
 * });
 */
export function useGetCustomerResourcesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCustomerResourcesQuery, GetCustomerResourcesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCustomerResourcesQuery, GetCustomerResourcesQueryVariables>(GetCustomerResourcesDocument, baseOptions);
      }
export function useGetCustomerResourcesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCustomerResourcesQuery, GetCustomerResourcesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCustomerResourcesQuery, GetCustomerResourcesQueryVariables>(GetCustomerResourcesDocument, baseOptions);
        }
export type GetCustomerResourcesQueryHookResult = ReturnType<typeof useGetCustomerResourcesQuery>;
export type GetCustomerResourcesLazyQueryHookResult = ReturnType<typeof useGetCustomerResourcesLazyQuery>;
export type GetCustomerResourcesQueryResult = ApolloReactCommon.QueryResult<GetCustomerResourcesQuery, GetCustomerResourcesQueryVariables>;
export const GetCustomersListDocument = gql`
    query getCustomersList {
  customersList {
    id
    name
  }
}
    `;
export type GetCustomersListComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCustomersListQuery, GetCustomersListQueryVariables>, 'query'>;

    export const GetCustomersListComponent = (props: GetCustomersListComponentProps) => (
      <ApolloReactComponents.Query<GetCustomersListQuery, GetCustomersListQueryVariables> query={GetCustomersListDocument} {...props} />
    );
    
export type GetCustomersListProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetCustomersListQuery, GetCustomersListQueryVariables>
    } & TChildProps;
export function withGetCustomersList<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCustomersListQuery,
  GetCustomersListQueryVariables,
  GetCustomersListProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetCustomersListQuery, GetCustomersListQueryVariables, GetCustomersListProps<TChildProps, TDataName>>(GetCustomersListDocument, {
      alias: 'getCustomersList',
      ...operationOptions
    });
};

/**
 * __useGetCustomersListQuery__
 *
 * To run a query within a React component, call `useGetCustomersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomersListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomersListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCustomersListQuery, GetCustomersListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCustomersListQuery, GetCustomersListQueryVariables>(GetCustomersListDocument, baseOptions);
      }
export function useGetCustomersListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCustomersListQuery, GetCustomersListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCustomersListQuery, GetCustomersListQueryVariables>(GetCustomersListDocument, baseOptions);
        }
export type GetCustomersListQueryHookResult = ReturnType<typeof useGetCustomersListQuery>;
export type GetCustomersListLazyQueryHookResult = ReturnType<typeof useGetCustomersListLazyQuery>;
export type GetCustomersListQueryResult = ApolloReactCommon.QueryResult<GetCustomersListQuery, GetCustomersListQueryVariables>;
export const GetDisasterRecoveryPlanDocument = gql`
    query getDisasterRecoveryPlan($searchCriteria: DisasterRecoveryPlanSearchCriteria!) {
  disasterRecoveryPlan(searchCriteria: $searchCriteria) {
    id
    customerId
    name
    cloudProvider
    restoreRegion
    startTimestamp
    frequencyInMonths
    remindDaysBefore
    emails
    kmsKey
    templatesResourcesMapping {
      template {
        id
        name
      }
      resourcesMapping {
        parameter
        resourceId
      }
    }
  }
}
    `;
export type GetDisasterRecoveryPlanComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetDisasterRecoveryPlanQuery, GetDisasterRecoveryPlanQueryVariables>, 'query'> & ({ variables: GetDisasterRecoveryPlanQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetDisasterRecoveryPlanComponent = (props: GetDisasterRecoveryPlanComponentProps) => (
      <ApolloReactComponents.Query<GetDisasterRecoveryPlanQuery, GetDisasterRecoveryPlanQueryVariables> query={GetDisasterRecoveryPlanDocument} {...props} />
    );
    
export type GetDisasterRecoveryPlanProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetDisasterRecoveryPlanQuery, GetDisasterRecoveryPlanQueryVariables>
    } & TChildProps;
export function withGetDisasterRecoveryPlan<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetDisasterRecoveryPlanQuery,
  GetDisasterRecoveryPlanQueryVariables,
  GetDisasterRecoveryPlanProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetDisasterRecoveryPlanQuery, GetDisasterRecoveryPlanQueryVariables, GetDisasterRecoveryPlanProps<TChildProps, TDataName>>(GetDisasterRecoveryPlanDocument, {
      alias: 'getDisasterRecoveryPlan',
      ...operationOptions
    });
};

/**
 * __useGetDisasterRecoveryPlanQuery__
 *
 * To run a query within a React component, call `useGetDisasterRecoveryPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDisasterRecoveryPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDisasterRecoveryPlanQuery({
 *   variables: {
 *      searchCriteria: // value for 'searchCriteria'
 *   },
 * });
 */
export function useGetDisasterRecoveryPlanQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDisasterRecoveryPlanQuery, GetDisasterRecoveryPlanQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDisasterRecoveryPlanQuery, GetDisasterRecoveryPlanQueryVariables>(GetDisasterRecoveryPlanDocument, baseOptions);
      }
export function useGetDisasterRecoveryPlanLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDisasterRecoveryPlanQuery, GetDisasterRecoveryPlanQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDisasterRecoveryPlanQuery, GetDisasterRecoveryPlanQueryVariables>(GetDisasterRecoveryPlanDocument, baseOptions);
        }
export type GetDisasterRecoveryPlanQueryHookResult = ReturnType<typeof useGetDisasterRecoveryPlanQuery>;
export type GetDisasterRecoveryPlanLazyQueryHookResult = ReturnType<typeof useGetDisasterRecoveryPlanLazyQuery>;
export type GetDisasterRecoveryPlanQueryResult = ApolloReactCommon.QueryResult<GetDisasterRecoveryPlanQuery, GetDisasterRecoveryPlanQueryVariables>;
export const GetDisasterRecoveryPlansDocument = gql`
    query getDisasterRecoveryPlans($searchCriteria: DisasterRecoveryPlansSearchCriteria!) {
  disasterRecoveryPlans(searchCriteria: $searchCriteria) {
    data {
      id
      name
      cloudProvider
      restoreRegion
      startTimestamp
      frequencyInMonths
      remindDaysBefore
      emails
      templatesResourcesMapping {
        template {
          id
          name
        }
      }
    }
    totalNumberOfPlans
    totalNumberOfPages
  }
}
    `;
export type GetDisasterRecoveryPlansComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetDisasterRecoveryPlansQuery, GetDisasterRecoveryPlansQueryVariables>, 'query'> & ({ variables: GetDisasterRecoveryPlansQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetDisasterRecoveryPlansComponent = (props: GetDisasterRecoveryPlansComponentProps) => (
      <ApolloReactComponents.Query<GetDisasterRecoveryPlansQuery, GetDisasterRecoveryPlansQueryVariables> query={GetDisasterRecoveryPlansDocument} {...props} />
    );
    
export type GetDisasterRecoveryPlansProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetDisasterRecoveryPlansQuery, GetDisasterRecoveryPlansQueryVariables>
    } & TChildProps;
export function withGetDisasterRecoveryPlans<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetDisasterRecoveryPlansQuery,
  GetDisasterRecoveryPlansQueryVariables,
  GetDisasterRecoveryPlansProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetDisasterRecoveryPlansQuery, GetDisasterRecoveryPlansQueryVariables, GetDisasterRecoveryPlansProps<TChildProps, TDataName>>(GetDisasterRecoveryPlansDocument, {
      alias: 'getDisasterRecoveryPlans',
      ...operationOptions
    });
};

/**
 * __useGetDisasterRecoveryPlansQuery__
 *
 * To run a query within a React component, call `useGetDisasterRecoveryPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDisasterRecoveryPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDisasterRecoveryPlansQuery({
 *   variables: {
 *      searchCriteria: // value for 'searchCriteria'
 *   },
 * });
 */
export function useGetDisasterRecoveryPlansQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDisasterRecoveryPlansQuery, GetDisasterRecoveryPlansQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDisasterRecoveryPlansQuery, GetDisasterRecoveryPlansQueryVariables>(GetDisasterRecoveryPlansDocument, baseOptions);
      }
export function useGetDisasterRecoveryPlansLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDisasterRecoveryPlansQuery, GetDisasterRecoveryPlansQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDisasterRecoveryPlansQuery, GetDisasterRecoveryPlansQueryVariables>(GetDisasterRecoveryPlansDocument, baseOptions);
        }
export type GetDisasterRecoveryPlansQueryHookResult = ReturnType<typeof useGetDisasterRecoveryPlansQuery>;
export type GetDisasterRecoveryPlansLazyQueryHookResult = ReturnType<typeof useGetDisasterRecoveryPlansLazyQuery>;
export type GetDisasterRecoveryPlansQueryResult = ApolloReactCommon.QueryResult<GetDisasterRecoveryPlansQuery, GetDisasterRecoveryPlansQueryVariables>;
export const GetDisasterRecoveryRecordsDocument = gql`
    query getDisasterRecoveryRecords($searchCriteria: DisasterRecoveryRecordsSearchCriteria!) {
  disasterRecoveryRecords(searchCriteria: $searchCriteria) {
    data {
      id
      plan {
        name
        cloudProvider
      }
      startTimestamp
      finishTimestamp
      status
      assignee
      restoreRegion
      links {
        templateName
        link
      }
      errorMessages
      note
    }
    totalNumberOfRecords
    totalNumberOfPages
  }
}
    `;
export type GetDisasterRecoveryRecordsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetDisasterRecoveryRecordsQuery, GetDisasterRecoveryRecordsQueryVariables>, 'query'> & ({ variables: GetDisasterRecoveryRecordsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetDisasterRecoveryRecordsComponent = (props: GetDisasterRecoveryRecordsComponentProps) => (
      <ApolloReactComponents.Query<GetDisasterRecoveryRecordsQuery, GetDisasterRecoveryRecordsQueryVariables> query={GetDisasterRecoveryRecordsDocument} {...props} />
    );
    
export type GetDisasterRecoveryRecordsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetDisasterRecoveryRecordsQuery, GetDisasterRecoveryRecordsQueryVariables>
    } & TChildProps;
export function withGetDisasterRecoveryRecords<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetDisasterRecoveryRecordsQuery,
  GetDisasterRecoveryRecordsQueryVariables,
  GetDisasterRecoveryRecordsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetDisasterRecoveryRecordsQuery, GetDisasterRecoveryRecordsQueryVariables, GetDisasterRecoveryRecordsProps<TChildProps, TDataName>>(GetDisasterRecoveryRecordsDocument, {
      alias: 'getDisasterRecoveryRecords',
      ...operationOptions
    });
};

/**
 * __useGetDisasterRecoveryRecordsQuery__
 *
 * To run a query within a React component, call `useGetDisasterRecoveryRecordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDisasterRecoveryRecordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDisasterRecoveryRecordsQuery({
 *   variables: {
 *      searchCriteria: // value for 'searchCriteria'
 *   },
 * });
 */
export function useGetDisasterRecoveryRecordsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDisasterRecoveryRecordsQuery, GetDisasterRecoveryRecordsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDisasterRecoveryRecordsQuery, GetDisasterRecoveryRecordsQueryVariables>(GetDisasterRecoveryRecordsDocument, baseOptions);
      }
export function useGetDisasterRecoveryRecordsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDisasterRecoveryRecordsQuery, GetDisasterRecoveryRecordsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDisasterRecoveryRecordsQuery, GetDisasterRecoveryRecordsQueryVariables>(GetDisasterRecoveryRecordsDocument, baseOptions);
        }
export type GetDisasterRecoveryRecordsQueryHookResult = ReturnType<typeof useGetDisasterRecoveryRecordsQuery>;
export type GetDisasterRecoveryRecordsLazyQueryHookResult = ReturnType<typeof useGetDisasterRecoveryRecordsLazyQuery>;
export type GetDisasterRecoveryRecordsQueryResult = ApolloReactCommon.QueryResult<GetDisasterRecoveryRecordsQuery, GetDisasterRecoveryRecordsQueryVariables>;
export const GetDisasterRecoverySnapshotsDocument = gql`
    query getDisasterRecoverySnapshots($searchCriteria: DisasterRecoverySnapshotsSearchCriteria!) {
  disasterRecoverySnapshots(searchCriteria: $searchCriteria) {
    id
    creationTimestamp
    resourceId
  }
}
    `;
export type GetDisasterRecoverySnapshotsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetDisasterRecoverySnapshotsQuery, GetDisasterRecoverySnapshotsQueryVariables>, 'query'> & ({ variables: GetDisasterRecoverySnapshotsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetDisasterRecoverySnapshotsComponent = (props: GetDisasterRecoverySnapshotsComponentProps) => (
      <ApolloReactComponents.Query<GetDisasterRecoverySnapshotsQuery, GetDisasterRecoverySnapshotsQueryVariables> query={GetDisasterRecoverySnapshotsDocument} {...props} />
    );
    
export type GetDisasterRecoverySnapshotsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetDisasterRecoverySnapshotsQuery, GetDisasterRecoverySnapshotsQueryVariables>
    } & TChildProps;
export function withGetDisasterRecoverySnapshots<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetDisasterRecoverySnapshotsQuery,
  GetDisasterRecoverySnapshotsQueryVariables,
  GetDisasterRecoverySnapshotsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetDisasterRecoverySnapshotsQuery, GetDisasterRecoverySnapshotsQueryVariables, GetDisasterRecoverySnapshotsProps<TChildProps, TDataName>>(GetDisasterRecoverySnapshotsDocument, {
      alias: 'getDisasterRecoverySnapshots',
      ...operationOptions
    });
};

/**
 * __useGetDisasterRecoverySnapshotsQuery__
 *
 * To run a query within a React component, call `useGetDisasterRecoverySnapshotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDisasterRecoverySnapshotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDisasterRecoverySnapshotsQuery({
 *   variables: {
 *      searchCriteria: // value for 'searchCriteria'
 *   },
 * });
 */
export function useGetDisasterRecoverySnapshotsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDisasterRecoverySnapshotsQuery, GetDisasterRecoverySnapshotsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDisasterRecoverySnapshotsQuery, GetDisasterRecoverySnapshotsQueryVariables>(GetDisasterRecoverySnapshotsDocument, baseOptions);
      }
export function useGetDisasterRecoverySnapshotsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDisasterRecoverySnapshotsQuery, GetDisasterRecoverySnapshotsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDisasterRecoverySnapshotsQuery, GetDisasterRecoverySnapshotsQueryVariables>(GetDisasterRecoverySnapshotsDocument, baseOptions);
        }
export type GetDisasterRecoverySnapshotsQueryHookResult = ReturnType<typeof useGetDisasterRecoverySnapshotsQuery>;
export type GetDisasterRecoverySnapshotsLazyQueryHookResult = ReturnType<typeof useGetDisasterRecoverySnapshotsLazyQuery>;
export type GetDisasterRecoverySnapshotsQueryResult = ApolloReactCommon.QueryResult<GetDisasterRecoverySnapshotsQuery, GetDisasterRecoverySnapshotsQueryVariables>;
export const GetExternallyBackedUpResourcesDocument = gql`
    query getExternallyBackedUpResources($searchCriteria: ExternallyBackedUpResourcesSearchCriteria!) {
  externallyBackedUpResources(searchCriteria: $searchCriteria) {
    data {
      id
      backupEnabled
      cloudProvider
      updatedAtTimestamp
      rpoInHours
      lastBackupStatus
      lastBackupErrorMessage
    }
    totalNumberOfPages
    totalNumberOfResources
  }
}
    `;
export type GetExternallyBackedUpResourcesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetExternallyBackedUpResourcesQuery, GetExternallyBackedUpResourcesQueryVariables>, 'query'> & ({ variables: GetExternallyBackedUpResourcesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetExternallyBackedUpResourcesComponent = (props: GetExternallyBackedUpResourcesComponentProps) => (
      <ApolloReactComponents.Query<GetExternallyBackedUpResourcesQuery, GetExternallyBackedUpResourcesQueryVariables> query={GetExternallyBackedUpResourcesDocument} {...props} />
    );
    
export type GetExternallyBackedUpResourcesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetExternallyBackedUpResourcesQuery, GetExternallyBackedUpResourcesQueryVariables>
    } & TChildProps;
export function withGetExternallyBackedUpResources<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetExternallyBackedUpResourcesQuery,
  GetExternallyBackedUpResourcesQueryVariables,
  GetExternallyBackedUpResourcesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetExternallyBackedUpResourcesQuery, GetExternallyBackedUpResourcesQueryVariables, GetExternallyBackedUpResourcesProps<TChildProps, TDataName>>(GetExternallyBackedUpResourcesDocument, {
      alias: 'getExternallyBackedUpResources',
      ...operationOptions
    });
};

/**
 * __useGetExternallyBackedUpResourcesQuery__
 *
 * To run a query within a React component, call `useGetExternallyBackedUpResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExternallyBackedUpResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExternallyBackedUpResourcesQuery({
 *   variables: {
 *      searchCriteria: // value for 'searchCriteria'
 *   },
 * });
 */
export function useGetExternallyBackedUpResourcesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetExternallyBackedUpResourcesQuery, GetExternallyBackedUpResourcesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetExternallyBackedUpResourcesQuery, GetExternallyBackedUpResourcesQueryVariables>(GetExternallyBackedUpResourcesDocument, baseOptions);
      }
export function useGetExternallyBackedUpResourcesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetExternallyBackedUpResourcesQuery, GetExternallyBackedUpResourcesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetExternallyBackedUpResourcesQuery, GetExternallyBackedUpResourcesQueryVariables>(GetExternallyBackedUpResourcesDocument, baseOptions);
        }
export type GetExternallyBackedUpResourcesQueryHookResult = ReturnType<typeof useGetExternallyBackedUpResourcesQuery>;
export type GetExternallyBackedUpResourcesLazyQueryHookResult = ReturnType<typeof useGetExternallyBackedUpResourcesLazyQuery>;
export type GetExternallyBackedUpResourcesQueryResult = ApolloReactCommon.QueryResult<GetExternallyBackedUpResourcesQuery, GetExternallyBackedUpResourcesQueryVariables>;
export const GetFiltersDocument = gql`
    query getFilters($searchCriteria: FiltersSearchCriteria!) {
  filters(searchCriteria: $searchCriteria) {
    id
    name
    type
    values {
      label
      value
    }
  }
}
    `;
export type GetFiltersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetFiltersQuery, GetFiltersQueryVariables>, 'query'> & ({ variables: GetFiltersQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetFiltersComponent = (props: GetFiltersComponentProps) => (
      <ApolloReactComponents.Query<GetFiltersQuery, GetFiltersQueryVariables> query={GetFiltersDocument} {...props} />
    );
    
export type GetFiltersProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetFiltersQuery, GetFiltersQueryVariables>
    } & TChildProps;
export function withGetFilters<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetFiltersQuery,
  GetFiltersQueryVariables,
  GetFiltersProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetFiltersQuery, GetFiltersQueryVariables, GetFiltersProps<TChildProps, TDataName>>(GetFiltersDocument, {
      alias: 'getFilters',
      ...operationOptions
    });
};

/**
 * __useGetFiltersQuery__
 *
 * To run a query within a React component, call `useGetFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFiltersQuery({
 *   variables: {
 *      searchCriteria: // value for 'searchCriteria'
 *   },
 * });
 */
export function useGetFiltersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetFiltersQuery, GetFiltersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetFiltersQuery, GetFiltersQueryVariables>(GetFiltersDocument, baseOptions);
      }
export function useGetFiltersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFiltersQuery, GetFiltersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetFiltersQuery, GetFiltersQueryVariables>(GetFiltersDocument, baseOptions);
        }
export type GetFiltersQueryHookResult = ReturnType<typeof useGetFiltersQuery>;
export type GetFiltersLazyQueryHookResult = ReturnType<typeof useGetFiltersLazyQuery>;
export type GetFiltersQueryResult = ApolloReactCommon.QueryResult<GetFiltersQuery, GetFiltersQueryVariables>;
export const GetLatestResourceSnapshotDocument = gql`
    query getLatestResourceSnapshot($searchCriteria: LatestResourceSnapshotSearchCriteria!) {
  latestResourceSnapshot(searchCriteria: $searchCriteria) {
    id
    accountId
    region
    restoreData
  }
}
    `;
export type GetLatestResourceSnapshotComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetLatestResourceSnapshotQuery, GetLatestResourceSnapshotQueryVariables>, 'query'> & ({ variables: GetLatestResourceSnapshotQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetLatestResourceSnapshotComponent = (props: GetLatestResourceSnapshotComponentProps) => (
      <ApolloReactComponents.Query<GetLatestResourceSnapshotQuery, GetLatestResourceSnapshotQueryVariables> query={GetLatestResourceSnapshotDocument} {...props} />
    );
    
export type GetLatestResourceSnapshotProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetLatestResourceSnapshotQuery, GetLatestResourceSnapshotQueryVariables>
    } & TChildProps;
export function withGetLatestResourceSnapshot<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetLatestResourceSnapshotQuery,
  GetLatestResourceSnapshotQueryVariables,
  GetLatestResourceSnapshotProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetLatestResourceSnapshotQuery, GetLatestResourceSnapshotQueryVariables, GetLatestResourceSnapshotProps<TChildProps, TDataName>>(GetLatestResourceSnapshotDocument, {
      alias: 'getLatestResourceSnapshot',
      ...operationOptions
    });
};

/**
 * __useGetLatestResourceSnapshotQuery__
 *
 * To run a query within a React component, call `useGetLatestResourceSnapshotQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestResourceSnapshotQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestResourceSnapshotQuery({
 *   variables: {
 *      searchCriteria: // value for 'searchCriteria'
 *   },
 * });
 */
export function useGetLatestResourceSnapshotQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLatestResourceSnapshotQuery, GetLatestResourceSnapshotQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLatestResourceSnapshotQuery, GetLatestResourceSnapshotQueryVariables>(GetLatestResourceSnapshotDocument, baseOptions);
      }
export function useGetLatestResourceSnapshotLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLatestResourceSnapshotQuery, GetLatestResourceSnapshotQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLatestResourceSnapshotQuery, GetLatestResourceSnapshotQueryVariables>(GetLatestResourceSnapshotDocument, baseOptions);
        }
export type GetLatestResourceSnapshotQueryHookResult = ReturnType<typeof useGetLatestResourceSnapshotQuery>;
export type GetLatestResourceSnapshotLazyQueryHookResult = ReturnType<typeof useGetLatestResourceSnapshotLazyQuery>;
export type GetLatestResourceSnapshotQueryResult = ApolloReactCommon.QueryResult<GetLatestResourceSnapshotQuery, GetLatestResourceSnapshotQueryVariables>;
export const MatchedResourcesTypesDocument = gql`
    query matchedResourcesTypes($searchCriteria: MatchedResourcesTypesSearchCriteria!) {
  matchedResourcesTypes(searchCriteria: $searchCriteria) {
    resourceTypeName
    numberOfResources
  }
}
    `;
export type MatchedResourcesTypesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MatchedResourcesTypesQuery, MatchedResourcesTypesQueryVariables>, 'query'> & ({ variables: MatchedResourcesTypesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const MatchedResourcesTypesComponent = (props: MatchedResourcesTypesComponentProps) => (
      <ApolloReactComponents.Query<MatchedResourcesTypesQuery, MatchedResourcesTypesQueryVariables> query={MatchedResourcesTypesDocument} {...props} />
    );
    
export type MatchedResourcesTypesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<MatchedResourcesTypesQuery, MatchedResourcesTypesQueryVariables>
    } & TChildProps;
export function withMatchedResourcesTypes<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  MatchedResourcesTypesQuery,
  MatchedResourcesTypesQueryVariables,
  MatchedResourcesTypesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, MatchedResourcesTypesQuery, MatchedResourcesTypesQueryVariables, MatchedResourcesTypesProps<TChildProps, TDataName>>(MatchedResourcesTypesDocument, {
      alias: 'matchedResourcesTypes',
      ...operationOptions
    });
};

/**
 * __useMatchedResourcesTypesQuery__
 *
 * To run a query within a React component, call `useMatchedResourcesTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMatchedResourcesTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMatchedResourcesTypesQuery({
 *   variables: {
 *      searchCriteria: // value for 'searchCriteria'
 *   },
 * });
 */
export function useMatchedResourcesTypesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MatchedResourcesTypesQuery, MatchedResourcesTypesQueryVariables>) {
        return ApolloReactHooks.useQuery<MatchedResourcesTypesQuery, MatchedResourcesTypesQueryVariables>(MatchedResourcesTypesDocument, baseOptions);
      }
export function useMatchedResourcesTypesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MatchedResourcesTypesQuery, MatchedResourcesTypesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MatchedResourcesTypesQuery, MatchedResourcesTypesQueryVariables>(MatchedResourcesTypesDocument, baseOptions);
        }
export type MatchedResourcesTypesQueryHookResult = ReturnType<typeof useMatchedResourcesTypesQuery>;
export type MatchedResourcesTypesLazyQueryHookResult = ReturnType<typeof useMatchedResourcesTypesLazyQuery>;
export type MatchedResourcesTypesQueryResult = ApolloReactCommon.QueryResult<MatchedResourcesTypesQuery, MatchedResourcesTypesQueryVariables>;
export const GetMessagesDocument = gql`
    query getMessages($searchCriteria: MessagesSearchCriteria!) {
  messages(searchCriteria: $searchCriteria) {
    data {
      id
      message
      type
      category
    }
    nextPageCursor
  }
}
    `;
export type GetMessagesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetMessagesQuery, GetMessagesQueryVariables>, 'query'> & ({ variables: GetMessagesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetMessagesComponent = (props: GetMessagesComponentProps) => (
      <ApolloReactComponents.Query<GetMessagesQuery, GetMessagesQueryVariables> query={GetMessagesDocument} {...props} />
    );
    
export type GetMessagesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetMessagesQuery, GetMessagesQueryVariables>
    } & TChildProps;
export function withGetMessages<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetMessagesQuery,
  GetMessagesQueryVariables,
  GetMessagesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetMessagesQuery, GetMessagesQueryVariables, GetMessagesProps<TChildProps, TDataName>>(GetMessagesDocument, {
      alias: 'getMessages',
      ...operationOptions
    });
};

/**
 * __useGetMessagesQuery__
 *
 * To run a query within a React component, call `useGetMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMessagesQuery({
 *   variables: {
 *      searchCriteria: // value for 'searchCriteria'
 *   },
 * });
 */
export function useGetMessagesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMessagesQuery, GetMessagesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMessagesQuery, GetMessagesQueryVariables>(GetMessagesDocument, baseOptions);
      }
export function useGetMessagesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMessagesQuery, GetMessagesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMessagesQuery, GetMessagesQueryVariables>(GetMessagesDocument, baseOptions);
        }
export type GetMessagesQueryHookResult = ReturnType<typeof useGetMessagesQuery>;
export type GetMessagesLazyQueryHookResult = ReturnType<typeof useGetMessagesLazyQuery>;
export type GetMessagesQueryResult = ApolloReactCommon.QueryResult<GetMessagesQuery, GetMessagesQueryVariables>;
export const GetMyApiKeyDocument = gql`
    query getMyApiKey($id: ID!, $customerSearchCriteria: ApiKeyCustomerAccessesSearchCriteria) {
  myApiKey(id: $id) {
    alias
    createdAt
    createdBy
    id
    lastUsedAt
    owner
    customerAccesses(searchCriteria: $customerSearchCriteria) {
      data {
        customer {
          id
          name
          backupPoliciesBoundary {
            minRPOInHours
            maxPrimarySnapshotRetentionInDays
            maxPrimarySnapshotRetentionQuantity
            maxReplicaSnapshotRetentionInDays
            maxReplicaSnapshotRetentionQuantity
          }
        }
        roles
      }
      totalNumberOfCustomers
      totalNumberOfPages
    }
  }
}
    `;
export type GetMyApiKeyComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetMyApiKeyQuery, GetMyApiKeyQueryVariables>, 'query'> & ({ variables: GetMyApiKeyQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetMyApiKeyComponent = (props: GetMyApiKeyComponentProps) => (
      <ApolloReactComponents.Query<GetMyApiKeyQuery, GetMyApiKeyQueryVariables> query={GetMyApiKeyDocument} {...props} />
    );
    
export type GetMyApiKeyProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetMyApiKeyQuery, GetMyApiKeyQueryVariables>
    } & TChildProps;
export function withGetMyApiKey<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetMyApiKeyQuery,
  GetMyApiKeyQueryVariables,
  GetMyApiKeyProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetMyApiKeyQuery, GetMyApiKeyQueryVariables, GetMyApiKeyProps<TChildProps, TDataName>>(GetMyApiKeyDocument, {
      alias: 'getMyApiKey',
      ...operationOptions
    });
};

/**
 * __useGetMyApiKeyQuery__
 *
 * To run a query within a React component, call `useGetMyApiKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyApiKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyApiKeyQuery({
 *   variables: {
 *      id: // value for 'id'
 *      customerSearchCriteria: // value for 'customerSearchCriteria'
 *   },
 * });
 */
export function useGetMyApiKeyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMyApiKeyQuery, GetMyApiKeyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMyApiKeyQuery, GetMyApiKeyQueryVariables>(GetMyApiKeyDocument, baseOptions);
      }
export function useGetMyApiKeyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMyApiKeyQuery, GetMyApiKeyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMyApiKeyQuery, GetMyApiKeyQueryVariables>(GetMyApiKeyDocument, baseOptions);
        }
export type GetMyApiKeyQueryHookResult = ReturnType<typeof useGetMyApiKeyQuery>;
export type GetMyApiKeyLazyQueryHookResult = ReturnType<typeof useGetMyApiKeyLazyQuery>;
export type GetMyApiKeyQueryResult = ApolloReactCommon.QueryResult<GetMyApiKeyQuery, GetMyApiKeyQueryVariables>;
export const GetMyApiKeysDocument = gql`
    query getMyApiKeys($searchCriteria: MyApiKeysSearchCriteria!) {
  myApiKeys(searchCriteria: $searchCriteria) {
    data {
      id
      alias
      owner
      createdAt
      createdBy
      lastUsedAt
      customerAccesses {
        data {
          customer {
            id
            name
            backupPoliciesBoundary {
              minRPOInHours
              maxPrimarySnapshotRetentionInDays
              maxPrimarySnapshotRetentionQuantity
              maxReplicaSnapshotRetentionInDays
              maxReplicaSnapshotRetentionQuantity
            }
          }
          roles
        }
        totalNumberOfCustomers
        totalNumberOfPages
      }
    }
    totalNumberOfPages
    totalNumberOfApiKeys
  }
}
    `;
export type GetMyApiKeysComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetMyApiKeysQuery, GetMyApiKeysQueryVariables>, 'query'> & ({ variables: GetMyApiKeysQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetMyApiKeysComponent = (props: GetMyApiKeysComponentProps) => (
      <ApolloReactComponents.Query<GetMyApiKeysQuery, GetMyApiKeysQueryVariables> query={GetMyApiKeysDocument} {...props} />
    );
    
export type GetMyApiKeysProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetMyApiKeysQuery, GetMyApiKeysQueryVariables>
    } & TChildProps;
export function withGetMyApiKeys<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetMyApiKeysQuery,
  GetMyApiKeysQueryVariables,
  GetMyApiKeysProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetMyApiKeysQuery, GetMyApiKeysQueryVariables, GetMyApiKeysProps<TChildProps, TDataName>>(GetMyApiKeysDocument, {
      alias: 'getMyApiKeys',
      ...operationOptions
    });
};

/**
 * __useGetMyApiKeysQuery__
 *
 * To run a query within a React component, call `useGetMyApiKeysQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyApiKeysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyApiKeysQuery({
 *   variables: {
 *      searchCriteria: // value for 'searchCriteria'
 *   },
 * });
 */
export function useGetMyApiKeysQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMyApiKeysQuery, GetMyApiKeysQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMyApiKeysQuery, GetMyApiKeysQueryVariables>(GetMyApiKeysDocument, baseOptions);
      }
export function useGetMyApiKeysLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMyApiKeysQuery, GetMyApiKeysQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMyApiKeysQuery, GetMyApiKeysQueryVariables>(GetMyApiKeysDocument, baseOptions);
        }
export type GetMyApiKeysQueryHookResult = ReturnType<typeof useGetMyApiKeysQuery>;
export type GetMyApiKeysLazyQueryHookResult = ReturnType<typeof useGetMyApiKeysLazyQuery>;
export type GetMyApiKeysQueryResult = ApolloReactCommon.QueryResult<GetMyApiKeysQuery, GetMyApiKeysQueryVariables>;
export const GetNotificationGroupDocument = gql`
    query getNotificationGroup($searchCriteria: NotificationGroupSearchCriteria!) {
  notificationGroup(searchCriteria: $searchCriteria) {
    id
    name
    severities
    frequency
    channels {
      ... on EmailConfig {
        address
      }
      ... on SlackConfig {
        channelName
        webHook
      }
      ... on PagerDutyConfig {
        routingKey
      }
    }
    excludedAccounts {
      id
      name
      cloudProviderId
      type
      cloudProvider
    }
  }
}
    `;
export type GetNotificationGroupComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetNotificationGroupQuery, GetNotificationGroupQueryVariables>, 'query'> & ({ variables: GetNotificationGroupQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetNotificationGroupComponent = (props: GetNotificationGroupComponentProps) => (
      <ApolloReactComponents.Query<GetNotificationGroupQuery, GetNotificationGroupQueryVariables> query={GetNotificationGroupDocument} {...props} />
    );
    
export type GetNotificationGroupProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetNotificationGroupQuery, GetNotificationGroupQueryVariables>
    } & TChildProps;
export function withGetNotificationGroup<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetNotificationGroupQuery,
  GetNotificationGroupQueryVariables,
  GetNotificationGroupProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetNotificationGroupQuery, GetNotificationGroupQueryVariables, GetNotificationGroupProps<TChildProps, TDataName>>(GetNotificationGroupDocument, {
      alias: 'getNotificationGroup',
      ...operationOptions
    });
};

/**
 * __useGetNotificationGroupQuery__
 *
 * To run a query within a React component, call `useGetNotificationGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationGroupQuery({
 *   variables: {
 *      searchCriteria: // value for 'searchCriteria'
 *   },
 * });
 */
export function useGetNotificationGroupQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetNotificationGroupQuery, GetNotificationGroupQueryVariables>) {
        return ApolloReactHooks.useQuery<GetNotificationGroupQuery, GetNotificationGroupQueryVariables>(GetNotificationGroupDocument, baseOptions);
      }
export function useGetNotificationGroupLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNotificationGroupQuery, GetNotificationGroupQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetNotificationGroupQuery, GetNotificationGroupQueryVariables>(GetNotificationGroupDocument, baseOptions);
        }
export type GetNotificationGroupQueryHookResult = ReturnType<typeof useGetNotificationGroupQuery>;
export type GetNotificationGroupLazyQueryHookResult = ReturnType<typeof useGetNotificationGroupLazyQuery>;
export type GetNotificationGroupQueryResult = ApolloReactCommon.QueryResult<GetNotificationGroupQuery, GetNotificationGroupQueryVariables>;
export const GetNotificationGroupsDocument = gql`
    query getNotificationGroups($customerId: String!) {
  notificationGroups(customerId: $customerId) {
    id
    name
    severities
    frequency
    channels {
      ... on EmailConfig {
        address
      }
      ... on SlackConfig {
        channelName
        webHook
      }
      ... on PagerDutyConfig {
        routingKey
      }
    }
    excludedAccounts {
      id
      name
      cloudProviderId
      type
      cloudProvider
    }
  }
}
    `;
export type GetNotificationGroupsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetNotificationGroupsQuery, GetNotificationGroupsQueryVariables>, 'query'> & ({ variables: GetNotificationGroupsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetNotificationGroupsComponent = (props: GetNotificationGroupsComponentProps) => (
      <ApolloReactComponents.Query<GetNotificationGroupsQuery, GetNotificationGroupsQueryVariables> query={GetNotificationGroupsDocument} {...props} />
    );
    
export type GetNotificationGroupsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetNotificationGroupsQuery, GetNotificationGroupsQueryVariables>
    } & TChildProps;
export function withGetNotificationGroups<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetNotificationGroupsQuery,
  GetNotificationGroupsQueryVariables,
  GetNotificationGroupsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetNotificationGroupsQuery, GetNotificationGroupsQueryVariables, GetNotificationGroupsProps<TChildProps, TDataName>>(GetNotificationGroupsDocument, {
      alias: 'getNotificationGroups',
      ...operationOptions
    });
};

/**
 * __useGetNotificationGroupsQuery__
 *
 * To run a query within a React component, call `useGetNotificationGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationGroupsQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useGetNotificationGroupsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetNotificationGroupsQuery, GetNotificationGroupsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetNotificationGroupsQuery, GetNotificationGroupsQueryVariables>(GetNotificationGroupsDocument, baseOptions);
      }
export function useGetNotificationGroupsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNotificationGroupsQuery, GetNotificationGroupsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetNotificationGroupsQuery, GetNotificationGroupsQueryVariables>(GetNotificationGroupsDocument, baseOptions);
        }
export type GetNotificationGroupsQueryHookResult = ReturnType<typeof useGetNotificationGroupsQuery>;
export type GetNotificationGroupsLazyQueryHookResult = ReturnType<typeof useGetNotificationGroupsLazyQuery>;
export type GetNotificationGroupsQueryResult = ApolloReactCommon.QueryResult<GetNotificationGroupsQuery, GetNotificationGroupsQueryVariables>;
export const NumberOfResourcesWithChangedBackupEnabledDocument = gql`
    query numberOfResourcesWithChangedBackupEnabled($input: ChangeBackupStateInput!) {
  numberOfResourcesWithChangedBackupEnabled(input: $input)
}
    `;
export type NumberOfResourcesWithChangedBackupEnabledComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<NumberOfResourcesWithChangedBackupEnabledQuery, NumberOfResourcesWithChangedBackupEnabledQueryVariables>, 'query'> & ({ variables: NumberOfResourcesWithChangedBackupEnabledQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const NumberOfResourcesWithChangedBackupEnabledComponent = (props: NumberOfResourcesWithChangedBackupEnabledComponentProps) => (
      <ApolloReactComponents.Query<NumberOfResourcesWithChangedBackupEnabledQuery, NumberOfResourcesWithChangedBackupEnabledQueryVariables> query={NumberOfResourcesWithChangedBackupEnabledDocument} {...props} />
    );
    
export type NumberOfResourcesWithChangedBackupEnabledProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<NumberOfResourcesWithChangedBackupEnabledQuery, NumberOfResourcesWithChangedBackupEnabledQueryVariables>
    } & TChildProps;
export function withNumberOfResourcesWithChangedBackupEnabled<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  NumberOfResourcesWithChangedBackupEnabledQuery,
  NumberOfResourcesWithChangedBackupEnabledQueryVariables,
  NumberOfResourcesWithChangedBackupEnabledProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, NumberOfResourcesWithChangedBackupEnabledQuery, NumberOfResourcesWithChangedBackupEnabledQueryVariables, NumberOfResourcesWithChangedBackupEnabledProps<TChildProps, TDataName>>(NumberOfResourcesWithChangedBackupEnabledDocument, {
      alias: 'numberOfResourcesWithChangedBackupEnabled',
      ...operationOptions
    });
};

/**
 * __useNumberOfResourcesWithChangedBackupEnabledQuery__
 *
 * To run a query within a React component, call `useNumberOfResourcesWithChangedBackupEnabledQuery` and pass it any options that fit your needs.
 * When your component renders, `useNumberOfResourcesWithChangedBackupEnabledQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNumberOfResourcesWithChangedBackupEnabledQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNumberOfResourcesWithChangedBackupEnabledQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NumberOfResourcesWithChangedBackupEnabledQuery, NumberOfResourcesWithChangedBackupEnabledQueryVariables>) {
        return ApolloReactHooks.useQuery<NumberOfResourcesWithChangedBackupEnabledQuery, NumberOfResourcesWithChangedBackupEnabledQueryVariables>(NumberOfResourcesWithChangedBackupEnabledDocument, baseOptions);
      }
export function useNumberOfResourcesWithChangedBackupEnabledLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NumberOfResourcesWithChangedBackupEnabledQuery, NumberOfResourcesWithChangedBackupEnabledQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NumberOfResourcesWithChangedBackupEnabledQuery, NumberOfResourcesWithChangedBackupEnabledQueryVariables>(NumberOfResourcesWithChangedBackupEnabledDocument, baseOptions);
        }
export type NumberOfResourcesWithChangedBackupEnabledQueryHookResult = ReturnType<typeof useNumberOfResourcesWithChangedBackupEnabledQuery>;
export type NumberOfResourcesWithChangedBackupEnabledLazyQueryHookResult = ReturnType<typeof useNumberOfResourcesWithChangedBackupEnabledLazyQuery>;
export type NumberOfResourcesWithChangedBackupEnabledQueryResult = ApolloReactCommon.QueryResult<NumberOfResourcesWithChangedBackupEnabledQuery, NumberOfResourcesWithChangedBackupEnabledQueryVariables>;
export const NumberOfResourcesWithChangedBackupPolicyDocument = gql`
    query numberOfResourcesWithChangedBackupPolicy($input: ChangeBackupPolicyAndSetupsInput!) {
  numberOfResourcesWithChangedBackupPolicy(input: $input)
}
    `;
export type NumberOfResourcesWithChangedBackupPolicyComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<NumberOfResourcesWithChangedBackupPolicyQuery, NumberOfResourcesWithChangedBackupPolicyQueryVariables>, 'query'> & ({ variables: NumberOfResourcesWithChangedBackupPolicyQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const NumberOfResourcesWithChangedBackupPolicyComponent = (props: NumberOfResourcesWithChangedBackupPolicyComponentProps) => (
      <ApolloReactComponents.Query<NumberOfResourcesWithChangedBackupPolicyQuery, NumberOfResourcesWithChangedBackupPolicyQueryVariables> query={NumberOfResourcesWithChangedBackupPolicyDocument} {...props} />
    );
    
export type NumberOfResourcesWithChangedBackupPolicyProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<NumberOfResourcesWithChangedBackupPolicyQuery, NumberOfResourcesWithChangedBackupPolicyQueryVariables>
    } & TChildProps;
export function withNumberOfResourcesWithChangedBackupPolicy<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  NumberOfResourcesWithChangedBackupPolicyQuery,
  NumberOfResourcesWithChangedBackupPolicyQueryVariables,
  NumberOfResourcesWithChangedBackupPolicyProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, NumberOfResourcesWithChangedBackupPolicyQuery, NumberOfResourcesWithChangedBackupPolicyQueryVariables, NumberOfResourcesWithChangedBackupPolicyProps<TChildProps, TDataName>>(NumberOfResourcesWithChangedBackupPolicyDocument, {
      alias: 'numberOfResourcesWithChangedBackupPolicy',
      ...operationOptions
    });
};

/**
 * __useNumberOfResourcesWithChangedBackupPolicyQuery__
 *
 * To run a query within a React component, call `useNumberOfResourcesWithChangedBackupPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useNumberOfResourcesWithChangedBackupPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNumberOfResourcesWithChangedBackupPolicyQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNumberOfResourcesWithChangedBackupPolicyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NumberOfResourcesWithChangedBackupPolicyQuery, NumberOfResourcesWithChangedBackupPolicyQueryVariables>) {
        return ApolloReactHooks.useQuery<NumberOfResourcesWithChangedBackupPolicyQuery, NumberOfResourcesWithChangedBackupPolicyQueryVariables>(NumberOfResourcesWithChangedBackupPolicyDocument, baseOptions);
      }
export function useNumberOfResourcesWithChangedBackupPolicyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NumberOfResourcesWithChangedBackupPolicyQuery, NumberOfResourcesWithChangedBackupPolicyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NumberOfResourcesWithChangedBackupPolicyQuery, NumberOfResourcesWithChangedBackupPolicyQueryVariables>(NumberOfResourcesWithChangedBackupPolicyDocument, baseOptions);
        }
export type NumberOfResourcesWithChangedBackupPolicyQueryHookResult = ReturnType<typeof useNumberOfResourcesWithChangedBackupPolicyQuery>;
export type NumberOfResourcesWithChangedBackupPolicyLazyQueryHookResult = ReturnType<typeof useNumberOfResourcesWithChangedBackupPolicyLazyQuery>;
export type NumberOfResourcesWithChangedBackupPolicyQueryResult = ApolloReactCommon.QueryResult<NumberOfResourcesWithChangedBackupPolicyQuery, NumberOfResourcesWithChangedBackupPolicyQueryVariables>;
export const NumberOfResourcesWithChangedBackupSetupsDocument = gql`
    query numberOfResourcesWithChangedBackupSetups($input: ChangeBackupPolicyAndSetupsInput!) {
  numberOfResourcesWithChangedBackupSetups(input: $input)
}
    `;
export type NumberOfResourcesWithChangedBackupSetupsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<NumberOfResourcesWithChangedBackupSetupsQuery, NumberOfResourcesWithChangedBackupSetupsQueryVariables>, 'query'> & ({ variables: NumberOfResourcesWithChangedBackupSetupsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const NumberOfResourcesWithChangedBackupSetupsComponent = (props: NumberOfResourcesWithChangedBackupSetupsComponentProps) => (
      <ApolloReactComponents.Query<NumberOfResourcesWithChangedBackupSetupsQuery, NumberOfResourcesWithChangedBackupSetupsQueryVariables> query={NumberOfResourcesWithChangedBackupSetupsDocument} {...props} />
    );
    
export type NumberOfResourcesWithChangedBackupSetupsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<NumberOfResourcesWithChangedBackupSetupsQuery, NumberOfResourcesWithChangedBackupSetupsQueryVariables>
    } & TChildProps;
export function withNumberOfResourcesWithChangedBackupSetups<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  NumberOfResourcesWithChangedBackupSetupsQuery,
  NumberOfResourcesWithChangedBackupSetupsQueryVariables,
  NumberOfResourcesWithChangedBackupSetupsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, NumberOfResourcesWithChangedBackupSetupsQuery, NumberOfResourcesWithChangedBackupSetupsQueryVariables, NumberOfResourcesWithChangedBackupSetupsProps<TChildProps, TDataName>>(NumberOfResourcesWithChangedBackupSetupsDocument, {
      alias: 'numberOfResourcesWithChangedBackupSetups',
      ...operationOptions
    });
};

/**
 * __useNumberOfResourcesWithChangedBackupSetupsQuery__
 *
 * To run a query within a React component, call `useNumberOfResourcesWithChangedBackupSetupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNumberOfResourcesWithChangedBackupSetupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNumberOfResourcesWithChangedBackupSetupsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNumberOfResourcesWithChangedBackupSetupsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NumberOfResourcesWithChangedBackupSetupsQuery, NumberOfResourcesWithChangedBackupSetupsQueryVariables>) {
        return ApolloReactHooks.useQuery<NumberOfResourcesWithChangedBackupSetupsQuery, NumberOfResourcesWithChangedBackupSetupsQueryVariables>(NumberOfResourcesWithChangedBackupSetupsDocument, baseOptions);
      }
export function useNumberOfResourcesWithChangedBackupSetupsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NumberOfResourcesWithChangedBackupSetupsQuery, NumberOfResourcesWithChangedBackupSetupsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NumberOfResourcesWithChangedBackupSetupsQuery, NumberOfResourcesWithChangedBackupSetupsQueryVariables>(NumberOfResourcesWithChangedBackupSetupsDocument, baseOptions);
        }
export type NumberOfResourcesWithChangedBackupSetupsQueryHookResult = ReturnType<typeof useNumberOfResourcesWithChangedBackupSetupsQuery>;
export type NumberOfResourcesWithChangedBackupSetupsLazyQueryHookResult = ReturnType<typeof useNumberOfResourcesWithChangedBackupSetupsLazyQuery>;
export type NumberOfResourcesWithChangedBackupSetupsQueryResult = ApolloReactCommon.QueryResult<NumberOfResourcesWithChangedBackupSetupsQuery, NumberOfResourcesWithChangedBackupSetupsQueryVariables>;
export const GetPolicyComplianceDocument = gql`
    query getPolicyCompliance($customerId: String!) {
  policyCompliance(customerId: $customerId) {
    numberOfBackedUpResources
    numberOfCompliantResources
  }
}
    `;
export type GetPolicyComplianceComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetPolicyComplianceQuery, GetPolicyComplianceQueryVariables>, 'query'> & ({ variables: GetPolicyComplianceQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetPolicyComplianceComponent = (props: GetPolicyComplianceComponentProps) => (
      <ApolloReactComponents.Query<GetPolicyComplianceQuery, GetPolicyComplianceQueryVariables> query={GetPolicyComplianceDocument} {...props} />
    );
    
export type GetPolicyComplianceProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetPolicyComplianceQuery, GetPolicyComplianceQueryVariables>
    } & TChildProps;
export function withGetPolicyCompliance<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetPolicyComplianceQuery,
  GetPolicyComplianceQueryVariables,
  GetPolicyComplianceProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetPolicyComplianceQuery, GetPolicyComplianceQueryVariables, GetPolicyComplianceProps<TChildProps, TDataName>>(GetPolicyComplianceDocument, {
      alias: 'getPolicyCompliance',
      ...operationOptions
    });
};

/**
 * __useGetPolicyComplianceQuery__
 *
 * To run a query within a React component, call `useGetPolicyComplianceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPolicyComplianceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPolicyComplianceQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useGetPolicyComplianceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPolicyComplianceQuery, GetPolicyComplianceQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPolicyComplianceQuery, GetPolicyComplianceQueryVariables>(GetPolicyComplianceDocument, baseOptions);
      }
export function useGetPolicyComplianceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPolicyComplianceQuery, GetPolicyComplianceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPolicyComplianceQuery, GetPolicyComplianceQueryVariables>(GetPolicyComplianceDocument, baseOptions);
        }
export type GetPolicyComplianceQueryHookResult = ReturnType<typeof useGetPolicyComplianceQuery>;
export type GetPolicyComplianceLazyQueryHookResult = ReturnType<typeof useGetPolicyComplianceLazyQuery>;
export type GetPolicyComplianceQueryResult = ApolloReactCommon.QueryResult<GetPolicyComplianceQuery, GetPolicyComplianceQueryVariables>;
export const GetProtectedResourcesStatsDocument = gql`
    query getProtectedResourcesStats($searchCriteria: ProtectedResourcesStatsSearchCriteria!) {
  protectedResourcesStats(searchCriteria: $searchCriteria) {
    numberOfResourcesWithEnabledBackup
    totalNumberOfResources
    totalAmountOfDataInBytes
    protectedAmountOfDataInBytes
  }
}
    `;
export type GetProtectedResourcesStatsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetProtectedResourcesStatsQuery, GetProtectedResourcesStatsQueryVariables>, 'query'> & ({ variables: GetProtectedResourcesStatsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetProtectedResourcesStatsComponent = (props: GetProtectedResourcesStatsComponentProps) => (
      <ApolloReactComponents.Query<GetProtectedResourcesStatsQuery, GetProtectedResourcesStatsQueryVariables> query={GetProtectedResourcesStatsDocument} {...props} />
    );
    
export type GetProtectedResourcesStatsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetProtectedResourcesStatsQuery, GetProtectedResourcesStatsQueryVariables>
    } & TChildProps;
export function withGetProtectedResourcesStats<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetProtectedResourcesStatsQuery,
  GetProtectedResourcesStatsQueryVariables,
  GetProtectedResourcesStatsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetProtectedResourcesStatsQuery, GetProtectedResourcesStatsQueryVariables, GetProtectedResourcesStatsProps<TChildProps, TDataName>>(GetProtectedResourcesStatsDocument, {
      alias: 'getProtectedResourcesStats',
      ...operationOptions
    });
};

/**
 * __useGetProtectedResourcesStatsQuery__
 *
 * To run a query within a React component, call `useGetProtectedResourcesStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProtectedResourcesStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProtectedResourcesStatsQuery({
 *   variables: {
 *      searchCriteria: // value for 'searchCriteria'
 *   },
 * });
 */
export function useGetProtectedResourcesStatsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProtectedResourcesStatsQuery, GetProtectedResourcesStatsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProtectedResourcesStatsQuery, GetProtectedResourcesStatsQueryVariables>(GetProtectedResourcesStatsDocument, baseOptions);
      }
export function useGetProtectedResourcesStatsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProtectedResourcesStatsQuery, GetProtectedResourcesStatsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProtectedResourcesStatsQuery, GetProtectedResourcesStatsQueryVariables>(GetProtectedResourcesStatsDocument, baseOptions);
        }
export type GetProtectedResourcesStatsQueryHookResult = ReturnType<typeof useGetProtectedResourcesStatsQuery>;
export type GetProtectedResourcesStatsLazyQueryHookResult = ReturnType<typeof useGetProtectedResourcesStatsLazyQuery>;
export type GetProtectedResourcesStatsQueryResult = ApolloReactCommon.QueryResult<GetProtectedResourcesStatsQuery, GetProtectedResourcesStatsQueryVariables>;
export const GetReportDocument = gql`
    query getReport($input: ReportInput!) {
  report(input: $input) {
    id
    createdAt
    status
    updatedAt
    url
  }
}
    `;
export type GetReportComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetReportQuery, GetReportQueryVariables>, 'query'> & ({ variables: GetReportQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetReportComponent = (props: GetReportComponentProps) => (
      <ApolloReactComponents.Query<GetReportQuery, GetReportQueryVariables> query={GetReportDocument} {...props} />
    );
    
export type GetReportProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetReportQuery, GetReportQueryVariables>
    } & TChildProps;
export function withGetReport<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetReportQuery,
  GetReportQueryVariables,
  GetReportProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetReportQuery, GetReportQueryVariables, GetReportProps<TChildProps, TDataName>>(GetReportDocument, {
      alias: 'getReport',
      ...operationOptions
    });
};

/**
 * __useGetReportQuery__
 *
 * To run a query within a React component, call `useGetReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetReportQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetReportQuery, GetReportQueryVariables>) {
        return ApolloReactHooks.useQuery<GetReportQuery, GetReportQueryVariables>(GetReportDocument, baseOptions);
      }
export function useGetReportLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetReportQuery, GetReportQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetReportQuery, GetReportQueryVariables>(GetReportDocument, baseOptions);
        }
export type GetReportQueryHookResult = ReturnType<typeof useGetReportQuery>;
export type GetReportLazyQueryHookResult = ReturnType<typeof useGetReportLazyQuery>;
export type GetReportQueryResult = ApolloReactCommon.QueryResult<GetReportQuery, GetReportQueryVariables>;
export const GetResourceTypeBackupSettingsDocument = gql`
    query getResourceTypeBackupSettings($searchCriteria: BackupSettingsSearchCriteria!) {
  resourceTypeBackupSettings(searchCriteria: $searchCriteria) {
    id
    label
    description
    type
    allowedValues {
      label
      value
    }
    isRequired
    activatedBy {
      id
      activatingValues
    }
    activatedBySettings {
      id
      activatingValues
    }
    requiredBy
    deprecated
  }
}
    `;
export type GetResourceTypeBackupSettingsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetResourceTypeBackupSettingsQuery, GetResourceTypeBackupSettingsQueryVariables>, 'query'> & ({ variables: GetResourceTypeBackupSettingsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetResourceTypeBackupSettingsComponent = (props: GetResourceTypeBackupSettingsComponentProps) => (
      <ApolloReactComponents.Query<GetResourceTypeBackupSettingsQuery, GetResourceTypeBackupSettingsQueryVariables> query={GetResourceTypeBackupSettingsDocument} {...props} />
    );
    
export type GetResourceTypeBackupSettingsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetResourceTypeBackupSettingsQuery, GetResourceTypeBackupSettingsQueryVariables>
    } & TChildProps;
export function withGetResourceTypeBackupSettings<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetResourceTypeBackupSettingsQuery,
  GetResourceTypeBackupSettingsQueryVariables,
  GetResourceTypeBackupSettingsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetResourceTypeBackupSettingsQuery, GetResourceTypeBackupSettingsQueryVariables, GetResourceTypeBackupSettingsProps<TChildProps, TDataName>>(GetResourceTypeBackupSettingsDocument, {
      alias: 'getResourceTypeBackupSettings',
      ...operationOptions
    });
};

/**
 * __useGetResourceTypeBackupSettingsQuery__
 *
 * To run a query within a React component, call `useGetResourceTypeBackupSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResourceTypeBackupSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResourceTypeBackupSettingsQuery({
 *   variables: {
 *      searchCriteria: // value for 'searchCriteria'
 *   },
 * });
 */
export function useGetResourceTypeBackupSettingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetResourceTypeBackupSettingsQuery, GetResourceTypeBackupSettingsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetResourceTypeBackupSettingsQuery, GetResourceTypeBackupSettingsQueryVariables>(GetResourceTypeBackupSettingsDocument, baseOptions);
      }
export function useGetResourceTypeBackupSettingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetResourceTypeBackupSettingsQuery, GetResourceTypeBackupSettingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetResourceTypeBackupSettingsQuery, GetResourceTypeBackupSettingsQueryVariables>(GetResourceTypeBackupSettingsDocument, baseOptions);
        }
export type GetResourceTypeBackupSettingsQueryHookResult = ReturnType<typeof useGetResourceTypeBackupSettingsQuery>;
export type GetResourceTypeBackupSettingsLazyQueryHookResult = ReturnType<typeof useGetResourceTypeBackupSettingsLazyQuery>;
export type GetResourceTypeBackupSettingsQueryResult = ApolloReactCommon.QueryResult<GetResourceTypeBackupSettingsQuery, GetResourceTypeBackupSettingsQueryVariables>;
export const GetResourcesDocument = gql`
    query getResources($searchCriteria: ResourcesSearchCriteria!) {
  resources(searchCriteria: $searchCriteria) {
    data {
      resource {
        id
        name
        type
        accountCloudProviderId
        accountName
        cloudProvider
        backupSetupName
        backupSetup {
          id
          name
        }
        backupPolicy {
          id
          name
          type
        }
        category
        backupEnabled
      }
      lastBackupStatus {
        primaryBackupErrorMessage
        replicationErrorMessage
        backupStatus
      }
    }
    totalNumberOfPages
    totalNumberOfResources
  }
}
    `;
export type GetResourcesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetResourcesQuery, GetResourcesQueryVariables>, 'query'> & ({ variables: GetResourcesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetResourcesComponent = (props: GetResourcesComponentProps) => (
      <ApolloReactComponents.Query<GetResourcesQuery, GetResourcesQueryVariables> query={GetResourcesDocument} {...props} />
    );
    
export type GetResourcesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetResourcesQuery, GetResourcesQueryVariables>
    } & TChildProps;
export function withGetResources<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetResourcesQuery,
  GetResourcesQueryVariables,
  GetResourcesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetResourcesQuery, GetResourcesQueryVariables, GetResourcesProps<TChildProps, TDataName>>(GetResourcesDocument, {
      alias: 'getResources',
      ...operationOptions
    });
};

/**
 * __useGetResourcesQuery__
 *
 * To run a query within a React component, call `useGetResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResourcesQuery({
 *   variables: {
 *      searchCriteria: // value for 'searchCriteria'
 *   },
 * });
 */
export function useGetResourcesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetResourcesQuery, GetResourcesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetResourcesQuery, GetResourcesQueryVariables>(GetResourcesDocument, baseOptions);
      }
export function useGetResourcesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetResourcesQuery, GetResourcesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetResourcesQuery, GetResourcesQueryVariables>(GetResourcesDocument, baseOptions);
        }
export type GetResourcesQueryHookResult = ReturnType<typeof useGetResourcesQuery>;
export type GetResourcesLazyQueryHookResult = ReturnType<typeof useGetResourcesLazyQuery>;
export type GetResourcesQueryResult = ApolloReactCommon.QueryResult<GetResourcesQuery, GetResourcesQueryVariables>;
export const GetResourcesTypesDocument = gql`
    query getResourcesTypes {
  resourcesTypes
}
    `;
export type GetResourcesTypesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetResourcesTypesQuery, GetResourcesTypesQueryVariables>, 'query'>;

    export const GetResourcesTypesComponent = (props: GetResourcesTypesComponentProps) => (
      <ApolloReactComponents.Query<GetResourcesTypesQuery, GetResourcesTypesQueryVariables> query={GetResourcesTypesDocument} {...props} />
    );
    
export type GetResourcesTypesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetResourcesTypesQuery, GetResourcesTypesQueryVariables>
    } & TChildProps;
export function withGetResourcesTypes<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetResourcesTypesQuery,
  GetResourcesTypesQueryVariables,
  GetResourcesTypesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetResourcesTypesQuery, GetResourcesTypesQueryVariables, GetResourcesTypesProps<TChildProps, TDataName>>(GetResourcesTypesDocument, {
      alias: 'getResourcesTypes',
      ...operationOptions
    });
};

/**
 * __useGetResourcesTypesQuery__
 *
 * To run a query within a React component, call `useGetResourcesTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResourcesTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResourcesTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetResourcesTypesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetResourcesTypesQuery, GetResourcesTypesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetResourcesTypesQuery, GetResourcesTypesQueryVariables>(GetResourcesTypesDocument, baseOptions);
      }
export function useGetResourcesTypesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetResourcesTypesQuery, GetResourcesTypesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetResourcesTypesQuery, GetResourcesTypesQueryVariables>(GetResourcesTypesDocument, baseOptions);
        }
export type GetResourcesTypesQueryHookResult = ReturnType<typeof useGetResourcesTypesQuery>;
export type GetResourcesTypesLazyQueryHookResult = ReturnType<typeof useGetResourcesTypesLazyQuery>;
export type GetResourcesTypesQueryResult = ApolloReactCommon.QueryResult<GetResourcesTypesQuery, GetResourcesTypesQueryVariables>;
export const GetScanStatusDocument = gql`
    query getScanStatus($customerId: String!) {
  scanStatus(customerId: $customerId) {
    scanId
    status
    createdAt
    updatedAt
  }
}
    `;
export type GetScanStatusComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetScanStatusQuery, GetScanStatusQueryVariables>, 'query'> & ({ variables: GetScanStatusQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetScanStatusComponent = (props: GetScanStatusComponentProps) => (
      <ApolloReactComponents.Query<GetScanStatusQuery, GetScanStatusQueryVariables> query={GetScanStatusDocument} {...props} />
    );
    
export type GetScanStatusProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetScanStatusQuery, GetScanStatusQueryVariables>
    } & TChildProps;
export function withGetScanStatus<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetScanStatusQuery,
  GetScanStatusQueryVariables,
  GetScanStatusProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetScanStatusQuery, GetScanStatusQueryVariables, GetScanStatusProps<TChildProps, TDataName>>(GetScanStatusDocument, {
      alias: 'getScanStatus',
      ...operationOptions
    });
};

/**
 * __useGetScanStatusQuery__
 *
 * To run a query within a React component, call `useGetScanStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScanStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScanStatusQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useGetScanStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetScanStatusQuery, GetScanStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<GetScanStatusQuery, GetScanStatusQueryVariables>(GetScanStatusDocument, baseOptions);
      }
export function useGetScanStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetScanStatusQuery, GetScanStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetScanStatusQuery, GetScanStatusQueryVariables>(GetScanStatusDocument, baseOptions);
        }
export type GetScanStatusQueryHookResult = ReturnType<typeof useGetScanStatusQuery>;
export type GetScanStatusLazyQueryHookResult = ReturnType<typeof useGetScanStatusLazyQuery>;
export type GetScanStatusQueryResult = ApolloReactCommon.QueryResult<GetScanStatusQuery, GetScanStatusQueryVariables>;
export const GetSummaryBackupsReportDocument = gql`
    query getSummaryBackupsReport($searchCriteria: SummaryBackupsReportScope!) {
  summaryBackupsReport(searchCriteria: $searchCriteria) {
    numberOfAllBackupAttempts
    numberOfSuccessfulBackups
    numberOfBackedUpResources
  }
}
    `;
export type GetSummaryBackupsReportComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetSummaryBackupsReportQuery, GetSummaryBackupsReportQueryVariables>, 'query'> & ({ variables: GetSummaryBackupsReportQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetSummaryBackupsReportComponent = (props: GetSummaryBackupsReportComponentProps) => (
      <ApolloReactComponents.Query<GetSummaryBackupsReportQuery, GetSummaryBackupsReportQueryVariables> query={GetSummaryBackupsReportDocument} {...props} />
    );
    
export type GetSummaryBackupsReportProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetSummaryBackupsReportQuery, GetSummaryBackupsReportQueryVariables>
    } & TChildProps;
export function withGetSummaryBackupsReport<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetSummaryBackupsReportQuery,
  GetSummaryBackupsReportQueryVariables,
  GetSummaryBackupsReportProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetSummaryBackupsReportQuery, GetSummaryBackupsReportQueryVariables, GetSummaryBackupsReportProps<TChildProps, TDataName>>(GetSummaryBackupsReportDocument, {
      alias: 'getSummaryBackupsReport',
      ...operationOptions
    });
};

/**
 * __useGetSummaryBackupsReportQuery__
 *
 * To run a query within a React component, call `useGetSummaryBackupsReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSummaryBackupsReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSummaryBackupsReportQuery({
 *   variables: {
 *      searchCriteria: // value for 'searchCriteria'
 *   },
 * });
 */
export function useGetSummaryBackupsReportQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSummaryBackupsReportQuery, GetSummaryBackupsReportQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSummaryBackupsReportQuery, GetSummaryBackupsReportQueryVariables>(GetSummaryBackupsReportDocument, baseOptions);
      }
export function useGetSummaryBackupsReportLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSummaryBackupsReportQuery, GetSummaryBackupsReportQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSummaryBackupsReportQuery, GetSummaryBackupsReportQueryVariables>(GetSummaryBackupsReportDocument, baseOptions);
        }
export type GetSummaryBackupsReportQueryHookResult = ReturnType<typeof useGetSummaryBackupsReportQuery>;
export type GetSummaryBackupsReportLazyQueryHookResult = ReturnType<typeof useGetSummaryBackupsReportLazyQuery>;
export type GetSummaryBackupsReportQueryResult = ApolloReactCommon.QueryResult<GetSummaryBackupsReportQuery, GetSummaryBackupsReportQueryVariables>;
export const GetSupportedRegionsDocument = gql`
    query getSupportedRegions($cloudProvider: CloudProvider!) {
  supportedRegions(cloudProvider: $cloudProvider)
}
    `;
export type GetSupportedRegionsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetSupportedRegionsQuery, GetSupportedRegionsQueryVariables>, 'query'> & ({ variables: GetSupportedRegionsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetSupportedRegionsComponent = (props: GetSupportedRegionsComponentProps) => (
      <ApolloReactComponents.Query<GetSupportedRegionsQuery, GetSupportedRegionsQueryVariables> query={GetSupportedRegionsDocument} {...props} />
    );
    
export type GetSupportedRegionsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetSupportedRegionsQuery, GetSupportedRegionsQueryVariables>
    } & TChildProps;
export function withGetSupportedRegions<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetSupportedRegionsQuery,
  GetSupportedRegionsQueryVariables,
  GetSupportedRegionsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetSupportedRegionsQuery, GetSupportedRegionsQueryVariables, GetSupportedRegionsProps<TChildProps, TDataName>>(GetSupportedRegionsDocument, {
      alias: 'getSupportedRegions',
      ...operationOptions
    });
};

/**
 * __useGetSupportedRegionsQuery__
 *
 * To run a query within a React component, call `useGetSupportedRegionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupportedRegionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupportedRegionsQuery({
 *   variables: {
 *      cloudProvider: // value for 'cloudProvider'
 *   },
 * });
 */
export function useGetSupportedRegionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupportedRegionsQuery, GetSupportedRegionsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupportedRegionsQuery, GetSupportedRegionsQueryVariables>(GetSupportedRegionsDocument, baseOptions);
      }
export function useGetSupportedRegionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupportedRegionsQuery, GetSupportedRegionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupportedRegionsQuery, GetSupportedRegionsQueryVariables>(GetSupportedRegionsDocument, baseOptions);
        }
export type GetSupportedRegionsQueryHookResult = ReturnType<typeof useGetSupportedRegionsQuery>;
export type GetSupportedRegionsLazyQueryHookResult = ReturnType<typeof useGetSupportedRegionsLazyQuery>;
export type GetSupportedRegionsQueryResult = ApolloReactCommon.QueryResult<GetSupportedRegionsQuery, GetSupportedRegionsQueryVariables>;
export const GetTemplateDocument = gql`
    query getTemplate($searchCriteria: TemplateSearchCriteria!) {
  template(searchCriteria: $searchCriteria) {
    id
    name
    content
    cloudProvider
    parameters
  }
}
    `;
export type GetTemplateComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetTemplateQuery, GetTemplateQueryVariables>, 'query'> & ({ variables: GetTemplateQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetTemplateComponent = (props: GetTemplateComponentProps) => (
      <ApolloReactComponents.Query<GetTemplateQuery, GetTemplateQueryVariables> query={GetTemplateDocument} {...props} />
    );
    
export type GetTemplateProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetTemplateQuery, GetTemplateQueryVariables>
    } & TChildProps;
export function withGetTemplate<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetTemplateQuery,
  GetTemplateQueryVariables,
  GetTemplateProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetTemplateQuery, GetTemplateQueryVariables, GetTemplateProps<TChildProps, TDataName>>(GetTemplateDocument, {
      alias: 'getTemplate',
      ...operationOptions
    });
};

/**
 * __useGetTemplateQuery__
 *
 * To run a query within a React component, call `useGetTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTemplateQuery({
 *   variables: {
 *      searchCriteria: // value for 'searchCriteria'
 *   },
 * });
 */
export function useGetTemplateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTemplateQuery, GetTemplateQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTemplateQuery, GetTemplateQueryVariables>(GetTemplateDocument, baseOptions);
      }
export function useGetTemplateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTemplateQuery, GetTemplateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTemplateQuery, GetTemplateQueryVariables>(GetTemplateDocument, baseOptions);
        }
export type GetTemplateQueryHookResult = ReturnType<typeof useGetTemplateQuery>;
export type GetTemplateLazyQueryHookResult = ReturnType<typeof useGetTemplateLazyQuery>;
export type GetTemplateQueryResult = ApolloReactCommon.QueryResult<GetTemplateQuery, GetTemplateQueryVariables>;
export const GetTemplatesDocument = gql`
    query getTemplates($searchCriteria: TemplatesSearchCriteria!) {
  templates(searchCriteria: $searchCriteria) {
    data {
      id
      name
      cloudProvider
      parameters
    }
    totalNumberOfTemplates
    totalNumberOfPages
  }
}
    `;
export type GetTemplatesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetTemplatesQuery, GetTemplatesQueryVariables>, 'query'> & ({ variables: GetTemplatesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetTemplatesComponent = (props: GetTemplatesComponentProps) => (
      <ApolloReactComponents.Query<GetTemplatesQuery, GetTemplatesQueryVariables> query={GetTemplatesDocument} {...props} />
    );
    
export type GetTemplatesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetTemplatesQuery, GetTemplatesQueryVariables>
    } & TChildProps;
export function withGetTemplates<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetTemplatesQuery,
  GetTemplatesQueryVariables,
  GetTemplatesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetTemplatesQuery, GetTemplatesQueryVariables, GetTemplatesProps<TChildProps, TDataName>>(GetTemplatesDocument, {
      alias: 'getTemplates',
      ...operationOptions
    });
};

/**
 * __useGetTemplatesQuery__
 *
 * To run a query within a React component, call `useGetTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTemplatesQuery({
 *   variables: {
 *      searchCriteria: // value for 'searchCriteria'
 *   },
 * });
 */
export function useGetTemplatesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTemplatesQuery, GetTemplatesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTemplatesQuery, GetTemplatesQueryVariables>(GetTemplatesDocument, baseOptions);
      }
export function useGetTemplatesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTemplatesQuery, GetTemplatesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTemplatesQuery, GetTemplatesQueryVariables>(GetTemplatesDocument, baseOptions);
        }
export type GetTemplatesQueryHookResult = ReturnType<typeof useGetTemplatesQuery>;
export type GetTemplatesLazyQueryHookResult = ReturnType<typeof useGetTemplatesLazyQuery>;
export type GetTemplatesQueryResult = ApolloReactCommon.QueryResult<GetTemplatesQuery, GetTemplatesQueryVariables>;
export const GetUserDocument = gql`
    query getUser($email: String!, $customerId: ID) {
  user(email: $email) {
    email
    superAdmin
    customerAccesses(searchCriteria: {customerId: $customerId}) {
      data {
        customer {
          id
          name
          backupPoliciesBoundary {
            minRPOInHours
            maxPrimarySnapshotRetentionInDays
            maxPrimarySnapshotRetentionQuantity
            maxReplicaSnapshotRetentionInDays
            maxReplicaSnapshotRetentionQuantity
          }
        }
        roles
      }
      totalNumberOfCustomers
      totalNumberOfPages
    }
  }
}
    `;
export type GetUserComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUserQuery, GetUserQueryVariables>, 'query'> & ({ variables: GetUserQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetUserComponent = (props: GetUserComponentProps) => (
      <ApolloReactComponents.Query<GetUserQuery, GetUserQueryVariables> query={GetUserDocument} {...props} />
    );
    
export type GetUserProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetUserQuery, GetUserQueryVariables>
    } & TChildProps;
export function withGetUser<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetUserQuery,
  GetUserQueryVariables,
  GetUserProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetUserQuery, GetUserQueryVariables, GetUserProps<TChildProps, TDataName>>(GetUserDocument, {
      alias: 'getUser',
      ...operationOptions
    });
};

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      email: // value for 'email'
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, baseOptions);
      }
export function useGetUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, baseOptions);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = ApolloReactCommon.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetUserProfileDocument = gql`
    query getUserProfile($customerId: String!) {
  userProfile(customerId: $customerId) {
    companyName
    firstName
    lastName
    email
    phoneNumber
    isBackupControlledByTags
    bestAllowedPolicySettings {
      minRPOInHours
      maxPrimarySnapshotRetentionQuantity
      maxPrimarySnapshotRetentionInDays
      maxReplicaSnapshotRetentionQuantity
      maxReplicaSnapshotRetentionInDays
    }
  }
}
    `;
export type GetUserProfileComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUserProfileQuery, GetUserProfileQueryVariables>, 'query'> & ({ variables: GetUserProfileQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetUserProfileComponent = (props: GetUserProfileComponentProps) => (
      <ApolloReactComponents.Query<GetUserProfileQuery, GetUserProfileQueryVariables> query={GetUserProfileDocument} {...props} />
    );
    
export type GetUserProfileProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetUserProfileQuery, GetUserProfileQueryVariables>
    } & TChildProps;
export function withGetUserProfile<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetUserProfileQuery,
  GetUserProfileQueryVariables,
  GetUserProfileProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetUserProfileQuery, GetUserProfileQueryVariables, GetUserProfileProps<TChildProps, TDataName>>(GetUserProfileDocument, {
      alias: 'getUserProfile',
      ...operationOptions
    });
};

/**
 * __useGetUserProfileQuery__
 *
 * To run a query within a React component, call `useGetUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserProfileQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useGetUserProfileQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserProfileQuery, GetUserProfileQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserProfileQuery, GetUserProfileQueryVariables>(GetUserProfileDocument, baseOptions);
      }
export function useGetUserProfileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserProfileQuery, GetUserProfileQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserProfileQuery, GetUserProfileQueryVariables>(GetUserProfileDocument, baseOptions);
        }
export type GetUserProfileQueryHookResult = ReturnType<typeof useGetUserProfileQuery>;
export type GetUserProfileLazyQueryHookResult = ReturnType<typeof useGetUserProfileLazyQuery>;
export type GetUserProfileQueryResult = ApolloReactCommon.QueryResult<GetUserProfileQuery, GetUserProfileQueryVariables>;
export const GetUserRoleDocument = gql`
    query getUserRole($customerId: String!) {
  userRole(customerId: $customerId)
}
    `;
export type GetUserRoleComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUserRoleQuery, GetUserRoleQueryVariables>, 'query'> & ({ variables: GetUserRoleQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetUserRoleComponent = (props: GetUserRoleComponentProps) => (
      <ApolloReactComponents.Query<GetUserRoleQuery, GetUserRoleQueryVariables> query={GetUserRoleDocument} {...props} />
    );
    
export type GetUserRoleProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetUserRoleQuery, GetUserRoleQueryVariables>
    } & TChildProps;
export function withGetUserRole<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetUserRoleQuery,
  GetUserRoleQueryVariables,
  GetUserRoleProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetUserRoleQuery, GetUserRoleQueryVariables, GetUserRoleProps<TChildProps, TDataName>>(GetUserRoleDocument, {
      alias: 'getUserRole',
      ...operationOptions
    });
};

/**
 * __useGetUserRoleQuery__
 *
 * To run a query within a React component, call `useGetUserRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserRoleQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useGetUserRoleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserRoleQuery, GetUserRoleQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserRoleQuery, GetUserRoleQueryVariables>(GetUserRoleDocument, baseOptions);
      }
export function useGetUserRoleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserRoleQuery, GetUserRoleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserRoleQuery, GetUserRoleQueryVariables>(GetUserRoleDocument, baseOptions);
        }
export type GetUserRoleQueryHookResult = ReturnType<typeof useGetUserRoleQuery>;
export type GetUserRoleLazyQueryHookResult = ReturnType<typeof useGetUserRoleLazyQuery>;
export type GetUserRoleQueryResult = ApolloReactCommon.QueryResult<GetUserRoleQuery, GetUserRoleQueryVariables>;
export const GetUsersDocument = gql`
    query getUsers($searchCriteria: UsersSearchCriteria!) {
  users(searchCriteria: $searchCriteria) {
    data {
      email
      superAdmin
      customerAccesses {
        data {
          customer {
            id
            name
            backupPoliciesBoundary {
              minRPOInHours
              maxPrimarySnapshotRetentionInDays
              maxPrimarySnapshotRetentionQuantity
              maxReplicaSnapshotRetentionInDays
              maxReplicaSnapshotRetentionQuantity
            }
          }
          roles
        }
        totalNumberOfCustomers
        totalNumberOfPages
      }
    }
    totalNumberOfPages
    totalNumberOfUsers
  }
}
    `;
export type GetUsersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUsersQuery, GetUsersQueryVariables>, 'query'> & ({ variables: GetUsersQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetUsersComponent = (props: GetUsersComponentProps) => (
      <ApolloReactComponents.Query<GetUsersQuery, GetUsersQueryVariables> query={GetUsersDocument} {...props} />
    );
    
export type GetUsersProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetUsersQuery, GetUsersQueryVariables>
    } & TChildProps;
export function withGetUsers<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetUsersQuery,
  GetUsersQueryVariables,
  GetUsersProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetUsersQuery, GetUsersQueryVariables, GetUsersProps<TChildProps, TDataName>>(GetUsersDocument, {
      alias: 'getUsers',
      ...operationOptions
    });
};

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      searchCriteria: // value for 'searchCriteria'
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, baseOptions);
      }
export function useGetUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, baseOptions);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = ApolloReactCommon.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const GrantCustomerRoleToApiKeyDocument = gql`
    mutation grantCustomerRoleToApiKey($input: GrantCustomerRoleToApiKeyInput!) {
  grantCustomerRoleToApiKey(input: $input)
}
    `;
export type GrantCustomerRoleToApiKeyMutationFn = ApolloReactCommon.MutationFunction<GrantCustomerRoleToApiKeyMutation, GrantCustomerRoleToApiKeyMutationVariables>;
export type GrantCustomerRoleToApiKeyComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GrantCustomerRoleToApiKeyMutation, GrantCustomerRoleToApiKeyMutationVariables>, 'mutation'>;

    export const GrantCustomerRoleToApiKeyComponent = (props: GrantCustomerRoleToApiKeyComponentProps) => (
      <ApolloReactComponents.Mutation<GrantCustomerRoleToApiKeyMutation, GrantCustomerRoleToApiKeyMutationVariables> mutation={GrantCustomerRoleToApiKeyDocument} {...props} />
    );
    
export type GrantCustomerRoleToApiKeyProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<GrantCustomerRoleToApiKeyMutation, GrantCustomerRoleToApiKeyMutationVariables>
    } & TChildProps;
export function withGrantCustomerRoleToApiKey<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GrantCustomerRoleToApiKeyMutation,
  GrantCustomerRoleToApiKeyMutationVariables,
  GrantCustomerRoleToApiKeyProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, GrantCustomerRoleToApiKeyMutation, GrantCustomerRoleToApiKeyMutationVariables, GrantCustomerRoleToApiKeyProps<TChildProps, TDataName>>(GrantCustomerRoleToApiKeyDocument, {
      alias: 'grantCustomerRoleToApiKey',
      ...operationOptions
    });
};

/**
 * __useGrantCustomerRoleToApiKeyMutation__
 *
 * To run a mutation, you first call `useGrantCustomerRoleToApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGrantCustomerRoleToApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [grantCustomerRoleToApiKeyMutation, { data, loading, error }] = useGrantCustomerRoleToApiKeyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGrantCustomerRoleToApiKeyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GrantCustomerRoleToApiKeyMutation, GrantCustomerRoleToApiKeyMutationVariables>) {
        return ApolloReactHooks.useMutation<GrantCustomerRoleToApiKeyMutation, GrantCustomerRoleToApiKeyMutationVariables>(GrantCustomerRoleToApiKeyDocument, baseOptions);
      }
export type GrantCustomerRoleToApiKeyMutationHookResult = ReturnType<typeof useGrantCustomerRoleToApiKeyMutation>;
export type GrantCustomerRoleToApiKeyMutationResult = ApolloReactCommon.MutationResult<GrantCustomerRoleToApiKeyMutation>;
export type GrantCustomerRoleToApiKeyMutationOptions = ApolloReactCommon.BaseMutationOptions<GrantCustomerRoleToApiKeyMutation, GrantCustomerRoleToApiKeyMutationVariables>;
export const GrantCustomerRoleToUserDocument = gql`
    mutation grantCustomerRoleToUser($input: GrantCustomerRoleToUserInput!) {
  grantCustomerRoleToUser(input: $input)
}
    `;
export type GrantCustomerRoleToUserMutationFn = ApolloReactCommon.MutationFunction<GrantCustomerRoleToUserMutation, GrantCustomerRoleToUserMutationVariables>;
export type GrantCustomerRoleToUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GrantCustomerRoleToUserMutation, GrantCustomerRoleToUserMutationVariables>, 'mutation'>;

    export const GrantCustomerRoleToUserComponent = (props: GrantCustomerRoleToUserComponentProps) => (
      <ApolloReactComponents.Mutation<GrantCustomerRoleToUserMutation, GrantCustomerRoleToUserMutationVariables> mutation={GrantCustomerRoleToUserDocument} {...props} />
    );
    
export type GrantCustomerRoleToUserProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<GrantCustomerRoleToUserMutation, GrantCustomerRoleToUserMutationVariables>
    } & TChildProps;
export function withGrantCustomerRoleToUser<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GrantCustomerRoleToUserMutation,
  GrantCustomerRoleToUserMutationVariables,
  GrantCustomerRoleToUserProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, GrantCustomerRoleToUserMutation, GrantCustomerRoleToUserMutationVariables, GrantCustomerRoleToUserProps<TChildProps, TDataName>>(GrantCustomerRoleToUserDocument, {
      alias: 'grantCustomerRoleToUser',
      ...operationOptions
    });
};

/**
 * __useGrantCustomerRoleToUserMutation__
 *
 * To run a mutation, you first call `useGrantCustomerRoleToUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGrantCustomerRoleToUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [grantCustomerRoleToUserMutation, { data, loading, error }] = useGrantCustomerRoleToUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGrantCustomerRoleToUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GrantCustomerRoleToUserMutation, GrantCustomerRoleToUserMutationVariables>) {
        return ApolloReactHooks.useMutation<GrantCustomerRoleToUserMutation, GrantCustomerRoleToUserMutationVariables>(GrantCustomerRoleToUserDocument, baseOptions);
      }
export type GrantCustomerRoleToUserMutationHookResult = ReturnType<typeof useGrantCustomerRoleToUserMutation>;
export type GrantCustomerRoleToUserMutationResult = ApolloReactCommon.MutationResult<GrantCustomerRoleToUserMutation>;
export type GrantCustomerRoleToUserMutationOptions = ApolloReactCommon.BaseMutationOptions<GrantCustomerRoleToUserMutation, GrantCustomerRoleToUserMutationVariables>;
export const GrantSuperAdminDocument = gql`
    mutation grantSuperAdmin($input: String!) {
  grantSuperAdmin(email: $input)
}
    `;
export type GrantSuperAdminMutationFn = ApolloReactCommon.MutationFunction<GrantSuperAdminMutation, GrantSuperAdminMutationVariables>;
export type GrantSuperAdminComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GrantSuperAdminMutation, GrantSuperAdminMutationVariables>, 'mutation'>;

    export const GrantSuperAdminComponent = (props: GrantSuperAdminComponentProps) => (
      <ApolloReactComponents.Mutation<GrantSuperAdminMutation, GrantSuperAdminMutationVariables> mutation={GrantSuperAdminDocument} {...props} />
    );
    
export type GrantSuperAdminProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<GrantSuperAdminMutation, GrantSuperAdminMutationVariables>
    } & TChildProps;
export function withGrantSuperAdmin<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GrantSuperAdminMutation,
  GrantSuperAdminMutationVariables,
  GrantSuperAdminProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, GrantSuperAdminMutation, GrantSuperAdminMutationVariables, GrantSuperAdminProps<TChildProps, TDataName>>(GrantSuperAdminDocument, {
      alias: 'grantSuperAdmin',
      ...operationOptions
    });
};

/**
 * __useGrantSuperAdminMutation__
 *
 * To run a mutation, you first call `useGrantSuperAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGrantSuperAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [grantSuperAdminMutation, { data, loading, error }] = useGrantSuperAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGrantSuperAdminMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GrantSuperAdminMutation, GrantSuperAdminMutationVariables>) {
        return ApolloReactHooks.useMutation<GrantSuperAdminMutation, GrantSuperAdminMutationVariables>(GrantSuperAdminDocument, baseOptions);
      }
export type GrantSuperAdminMutationHookResult = ReturnType<typeof useGrantSuperAdminMutation>;
export type GrantSuperAdminMutationResult = ApolloReactCommon.MutationResult<GrantSuperAdminMutation>;
export type GrantSuperAdminMutationOptions = ApolloReactCommon.BaseMutationOptions<GrantSuperAdminMutation, GrantSuperAdminMutationVariables>;
export const ProvideBackupTagNamesDocument = gql`
    mutation provideBackupTagNames($input: ProvideBackupTagNamesInput!) {
  provideBackupTagNames(input: $input) {
    backupEnabledTagNames
    policyTagNames
    setupTagNames
  }
}
    `;
export type ProvideBackupTagNamesMutationFn = ApolloReactCommon.MutationFunction<ProvideBackupTagNamesMutation, ProvideBackupTagNamesMutationVariables>;
export type ProvideBackupTagNamesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ProvideBackupTagNamesMutation, ProvideBackupTagNamesMutationVariables>, 'mutation'>;

    export const ProvideBackupTagNamesComponent = (props: ProvideBackupTagNamesComponentProps) => (
      <ApolloReactComponents.Mutation<ProvideBackupTagNamesMutation, ProvideBackupTagNamesMutationVariables> mutation={ProvideBackupTagNamesDocument} {...props} />
    );
    
export type ProvideBackupTagNamesProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<ProvideBackupTagNamesMutation, ProvideBackupTagNamesMutationVariables>
    } & TChildProps;
export function withProvideBackupTagNames<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ProvideBackupTagNamesMutation,
  ProvideBackupTagNamesMutationVariables,
  ProvideBackupTagNamesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, ProvideBackupTagNamesMutation, ProvideBackupTagNamesMutationVariables, ProvideBackupTagNamesProps<TChildProps, TDataName>>(ProvideBackupTagNamesDocument, {
      alias: 'provideBackupTagNames',
      ...operationOptions
    });
};

/**
 * __useProvideBackupTagNamesMutation__
 *
 * To run a mutation, you first call `useProvideBackupTagNamesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProvideBackupTagNamesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [provideBackupTagNamesMutation, { data, loading, error }] = useProvideBackupTagNamesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProvideBackupTagNamesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ProvideBackupTagNamesMutation, ProvideBackupTagNamesMutationVariables>) {
        return ApolloReactHooks.useMutation<ProvideBackupTagNamesMutation, ProvideBackupTagNamesMutationVariables>(ProvideBackupTagNamesDocument, baseOptions);
      }
export type ProvideBackupTagNamesMutationHookResult = ReturnType<typeof useProvideBackupTagNamesMutation>;
export type ProvideBackupTagNamesMutationResult = ApolloReactCommon.MutationResult<ProvideBackupTagNamesMutation>;
export type ProvideBackupTagNamesMutationOptions = ApolloReactCommon.BaseMutationOptions<ProvideBackupTagNamesMutation, ProvideBackupTagNamesMutationVariables>;
export const RemoveAccountDocument = gql`
    mutation removeAccount($input: RemoveAccountInput!) {
  removeAccount(input: $input) {
    id
  }
}
    `;
export type RemoveAccountMutationFn = ApolloReactCommon.MutationFunction<RemoveAccountMutation, RemoveAccountMutationVariables>;
export type RemoveAccountComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveAccountMutation, RemoveAccountMutationVariables>, 'mutation'>;

    export const RemoveAccountComponent = (props: RemoveAccountComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveAccountMutation, RemoveAccountMutationVariables> mutation={RemoveAccountDocument} {...props} />
    );
    
export type RemoveAccountProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<RemoveAccountMutation, RemoveAccountMutationVariables>
    } & TChildProps;
export function withRemoveAccount<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RemoveAccountMutation,
  RemoveAccountMutationVariables,
  RemoveAccountProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, RemoveAccountMutation, RemoveAccountMutationVariables, RemoveAccountProps<TChildProps, TDataName>>(RemoveAccountDocument, {
      alias: 'removeAccount',
      ...operationOptions
    });
};

/**
 * __useRemoveAccountMutation__
 *
 * To run a mutation, you first call `useRemoveAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAccountMutation, { data, loading, error }] = useRemoveAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveAccountMutation, RemoveAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveAccountMutation, RemoveAccountMutationVariables>(RemoveAccountDocument, baseOptions);
      }
export type RemoveAccountMutationHookResult = ReturnType<typeof useRemoveAccountMutation>;
export type RemoveAccountMutationResult = ApolloReactCommon.MutationResult<RemoveAccountMutation>;
export type RemoveAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveAccountMutation, RemoveAccountMutationVariables>;
export const RemoveApiKeyDocument = gql`
    mutation removeApiKey($id: ID!) {
  removeApiKey(id: $id)
}
    `;
export type RemoveApiKeyMutationFn = ApolloReactCommon.MutationFunction<RemoveApiKeyMutation, RemoveApiKeyMutationVariables>;
export type RemoveApiKeyComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveApiKeyMutation, RemoveApiKeyMutationVariables>, 'mutation'>;

    export const RemoveApiKeyComponent = (props: RemoveApiKeyComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveApiKeyMutation, RemoveApiKeyMutationVariables> mutation={RemoveApiKeyDocument} {...props} />
    );
    
export type RemoveApiKeyProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<RemoveApiKeyMutation, RemoveApiKeyMutationVariables>
    } & TChildProps;
export function withRemoveApiKey<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RemoveApiKeyMutation,
  RemoveApiKeyMutationVariables,
  RemoveApiKeyProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, RemoveApiKeyMutation, RemoveApiKeyMutationVariables, RemoveApiKeyProps<TChildProps, TDataName>>(RemoveApiKeyDocument, {
      alias: 'removeApiKey',
      ...operationOptions
    });
};

/**
 * __useRemoveApiKeyMutation__
 *
 * To run a mutation, you first call `useRemoveApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeApiKeyMutation, { data, loading, error }] = useRemoveApiKeyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveApiKeyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveApiKeyMutation, RemoveApiKeyMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveApiKeyMutation, RemoveApiKeyMutationVariables>(RemoveApiKeyDocument, baseOptions);
      }
export type RemoveApiKeyMutationHookResult = ReturnType<typeof useRemoveApiKeyMutation>;
export type RemoveApiKeyMutationResult = ApolloReactCommon.MutationResult<RemoveApiKeyMutation>;
export type RemoveApiKeyMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveApiKeyMutation, RemoveApiKeyMutationVariables>;
export const RemoveBackupPolicyDocument = gql`
    mutation removeBackupPolicy($input: RemoveBackupPolicyInput!) {
  removeBackupPolicy(input: $input)
}
    `;
export type RemoveBackupPolicyMutationFn = ApolloReactCommon.MutationFunction<RemoveBackupPolicyMutation, RemoveBackupPolicyMutationVariables>;
export type RemoveBackupPolicyComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveBackupPolicyMutation, RemoveBackupPolicyMutationVariables>, 'mutation'>;

    export const RemoveBackupPolicyComponent = (props: RemoveBackupPolicyComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveBackupPolicyMutation, RemoveBackupPolicyMutationVariables> mutation={RemoveBackupPolicyDocument} {...props} />
    );
    
export type RemoveBackupPolicyProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<RemoveBackupPolicyMutation, RemoveBackupPolicyMutationVariables>
    } & TChildProps;
export function withRemoveBackupPolicy<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RemoveBackupPolicyMutation,
  RemoveBackupPolicyMutationVariables,
  RemoveBackupPolicyProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, RemoveBackupPolicyMutation, RemoveBackupPolicyMutationVariables, RemoveBackupPolicyProps<TChildProps, TDataName>>(RemoveBackupPolicyDocument, {
      alias: 'removeBackupPolicy',
      ...operationOptions
    });
};

/**
 * __useRemoveBackupPolicyMutation__
 *
 * To run a mutation, you first call `useRemoveBackupPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveBackupPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeBackupPolicyMutation, { data, loading, error }] = useRemoveBackupPolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveBackupPolicyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveBackupPolicyMutation, RemoveBackupPolicyMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveBackupPolicyMutation, RemoveBackupPolicyMutationVariables>(RemoveBackupPolicyDocument, baseOptions);
      }
export type RemoveBackupPolicyMutationHookResult = ReturnType<typeof useRemoveBackupPolicyMutation>;
export type RemoveBackupPolicyMutationResult = ApolloReactCommon.MutationResult<RemoveBackupPolicyMutation>;
export type RemoveBackupPolicyMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveBackupPolicyMutation, RemoveBackupPolicyMutationVariables>;
export const RemoveBackupSetupDocument = gql`
    mutation removeBackupSetup($input: RemoveBackupSetupInput!) {
  removeBackupSetup(input: $input)
}
    `;
export type RemoveBackupSetupMutationFn = ApolloReactCommon.MutationFunction<RemoveBackupSetupMutation, RemoveBackupSetupMutationVariables>;
export type RemoveBackupSetupComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveBackupSetupMutation, RemoveBackupSetupMutationVariables>, 'mutation'>;

    export const RemoveBackupSetupComponent = (props: RemoveBackupSetupComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveBackupSetupMutation, RemoveBackupSetupMutationVariables> mutation={RemoveBackupSetupDocument} {...props} />
    );
    
export type RemoveBackupSetupProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<RemoveBackupSetupMutation, RemoveBackupSetupMutationVariables>
    } & TChildProps;
export function withRemoveBackupSetup<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RemoveBackupSetupMutation,
  RemoveBackupSetupMutationVariables,
  RemoveBackupSetupProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, RemoveBackupSetupMutation, RemoveBackupSetupMutationVariables, RemoveBackupSetupProps<TChildProps, TDataName>>(RemoveBackupSetupDocument, {
      alias: 'removeBackupSetup',
      ...operationOptions
    });
};

/**
 * __useRemoveBackupSetupMutation__
 *
 * To run a mutation, you first call `useRemoveBackupSetupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveBackupSetupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeBackupSetupMutation, { data, loading, error }] = useRemoveBackupSetupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveBackupSetupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveBackupSetupMutation, RemoveBackupSetupMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveBackupSetupMutation, RemoveBackupSetupMutationVariables>(RemoveBackupSetupDocument, baseOptions);
      }
export type RemoveBackupSetupMutationHookResult = ReturnType<typeof useRemoveBackupSetupMutation>;
export type RemoveBackupSetupMutationResult = ApolloReactCommon.MutationResult<RemoveBackupSetupMutation>;
export type RemoveBackupSetupMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveBackupSetupMutation, RemoveBackupSetupMutationVariables>;
export const RemoveDisasterRecoveryPlanDocument = gql`
    mutation removeDisasterRecoveryPlan($input: RemoveDisasterRecoveryPlanInput!) {
  removeDisasterRecoveryPlan(input: $input)
}
    `;
export type RemoveDisasterRecoveryPlanMutationFn = ApolloReactCommon.MutationFunction<RemoveDisasterRecoveryPlanMutation, RemoveDisasterRecoveryPlanMutationVariables>;
export type RemoveDisasterRecoveryPlanComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveDisasterRecoveryPlanMutation, RemoveDisasterRecoveryPlanMutationVariables>, 'mutation'>;

    export const RemoveDisasterRecoveryPlanComponent = (props: RemoveDisasterRecoveryPlanComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveDisasterRecoveryPlanMutation, RemoveDisasterRecoveryPlanMutationVariables> mutation={RemoveDisasterRecoveryPlanDocument} {...props} />
    );
    
export type RemoveDisasterRecoveryPlanProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<RemoveDisasterRecoveryPlanMutation, RemoveDisasterRecoveryPlanMutationVariables>
    } & TChildProps;
export function withRemoveDisasterRecoveryPlan<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RemoveDisasterRecoveryPlanMutation,
  RemoveDisasterRecoveryPlanMutationVariables,
  RemoveDisasterRecoveryPlanProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, RemoveDisasterRecoveryPlanMutation, RemoveDisasterRecoveryPlanMutationVariables, RemoveDisasterRecoveryPlanProps<TChildProps, TDataName>>(RemoveDisasterRecoveryPlanDocument, {
      alias: 'removeDisasterRecoveryPlan',
      ...operationOptions
    });
};

/**
 * __useRemoveDisasterRecoveryPlanMutation__
 *
 * To run a mutation, you first call `useRemoveDisasterRecoveryPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDisasterRecoveryPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDisasterRecoveryPlanMutation, { data, loading, error }] = useRemoveDisasterRecoveryPlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveDisasterRecoveryPlanMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveDisasterRecoveryPlanMutation, RemoveDisasterRecoveryPlanMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveDisasterRecoveryPlanMutation, RemoveDisasterRecoveryPlanMutationVariables>(RemoveDisasterRecoveryPlanDocument, baseOptions);
      }
export type RemoveDisasterRecoveryPlanMutationHookResult = ReturnType<typeof useRemoveDisasterRecoveryPlanMutation>;
export type RemoveDisasterRecoveryPlanMutationResult = ApolloReactCommon.MutationResult<RemoveDisasterRecoveryPlanMutation>;
export type RemoveDisasterRecoveryPlanMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveDisasterRecoveryPlanMutation, RemoveDisasterRecoveryPlanMutationVariables>;
export const RemoveNotificationGroupDocument = gql`
    mutation removeNotificationGroup($input: RemoveNotificationGroupInput!) {
  removeNotificationGroup(input: $input)
}
    `;
export type RemoveNotificationGroupMutationFn = ApolloReactCommon.MutationFunction<RemoveNotificationGroupMutation, RemoveNotificationGroupMutationVariables>;
export type RemoveNotificationGroupComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveNotificationGroupMutation, RemoveNotificationGroupMutationVariables>, 'mutation'>;

    export const RemoveNotificationGroupComponent = (props: RemoveNotificationGroupComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveNotificationGroupMutation, RemoveNotificationGroupMutationVariables> mutation={RemoveNotificationGroupDocument} {...props} />
    );
    
export type RemoveNotificationGroupProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<RemoveNotificationGroupMutation, RemoveNotificationGroupMutationVariables>
    } & TChildProps;
export function withRemoveNotificationGroup<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RemoveNotificationGroupMutation,
  RemoveNotificationGroupMutationVariables,
  RemoveNotificationGroupProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, RemoveNotificationGroupMutation, RemoveNotificationGroupMutationVariables, RemoveNotificationGroupProps<TChildProps, TDataName>>(RemoveNotificationGroupDocument, {
      alias: 'removeNotificationGroup',
      ...operationOptions
    });
};

/**
 * __useRemoveNotificationGroupMutation__
 *
 * To run a mutation, you first call `useRemoveNotificationGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveNotificationGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeNotificationGroupMutation, { data, loading, error }] = useRemoveNotificationGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveNotificationGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveNotificationGroupMutation, RemoveNotificationGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveNotificationGroupMutation, RemoveNotificationGroupMutationVariables>(RemoveNotificationGroupDocument, baseOptions);
      }
export type RemoveNotificationGroupMutationHookResult = ReturnType<typeof useRemoveNotificationGroupMutation>;
export type RemoveNotificationGroupMutationResult = ApolloReactCommon.MutationResult<RemoveNotificationGroupMutation>;
export type RemoveNotificationGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveNotificationGroupMutation, RemoveNotificationGroupMutationVariables>;
export const RemoveTemplateDocument = gql`
    mutation removeTemplate($input: RemoveTemplateInput!) {
  removeTemplate(input: $input)
}
    `;
export type RemoveTemplateMutationFn = ApolloReactCommon.MutationFunction<RemoveTemplateMutation, RemoveTemplateMutationVariables>;
export type RemoveTemplateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveTemplateMutation, RemoveTemplateMutationVariables>, 'mutation'>;

    export const RemoveTemplateComponent = (props: RemoveTemplateComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveTemplateMutation, RemoveTemplateMutationVariables> mutation={RemoveTemplateDocument} {...props} />
    );
    
export type RemoveTemplateProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<RemoveTemplateMutation, RemoveTemplateMutationVariables>
    } & TChildProps;
export function withRemoveTemplate<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RemoveTemplateMutation,
  RemoveTemplateMutationVariables,
  RemoveTemplateProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, RemoveTemplateMutation, RemoveTemplateMutationVariables, RemoveTemplateProps<TChildProps, TDataName>>(RemoveTemplateDocument, {
      alias: 'removeTemplate',
      ...operationOptions
    });
};

/**
 * __useRemoveTemplateMutation__
 *
 * To run a mutation, you first call `useRemoveTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTemplateMutation, { data, loading, error }] = useRemoveTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveTemplateMutation, RemoveTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveTemplateMutation, RemoveTemplateMutationVariables>(RemoveTemplateDocument, baseOptions);
      }
export type RemoveTemplateMutationHookResult = ReturnType<typeof useRemoveTemplateMutation>;
export type RemoveTemplateMutationResult = ApolloReactCommon.MutationResult<RemoveTemplateMutation>;
export type RemoveTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveTemplateMutation, RemoveTemplateMutationVariables>;
export const RemoveUserDocument = gql`
    mutation removeUser($email: String!) {
  removeUser(email: $email)
}
    `;
export type RemoveUserMutationFn = ApolloReactCommon.MutationFunction<RemoveUserMutation, RemoveUserMutationVariables>;
export type RemoveUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveUserMutation, RemoveUserMutationVariables>, 'mutation'>;

    export const RemoveUserComponent = (props: RemoveUserComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveUserMutation, RemoveUserMutationVariables> mutation={RemoveUserDocument} {...props} />
    );
    
export type RemoveUserProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<RemoveUserMutation, RemoveUserMutationVariables>
    } & TChildProps;
export function withRemoveUser<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RemoveUserMutation,
  RemoveUserMutationVariables,
  RemoveUserProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, RemoveUserMutation, RemoveUserMutationVariables, RemoveUserProps<TChildProps, TDataName>>(RemoveUserDocument, {
      alias: 'removeUser',
      ...operationOptions
    });
};

/**
 * __useRemoveUserMutation__
 *
 * To run a mutation, you first call `useRemoveUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserMutation, { data, loading, error }] = useRemoveUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRemoveUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveUserMutation, RemoveUserMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveUserMutation, RemoveUserMutationVariables>(RemoveUserDocument, baseOptions);
      }
export type RemoveUserMutationHookResult = ReturnType<typeof useRemoveUserMutation>;
export type RemoveUserMutationResult = ApolloReactCommon.MutationResult<RemoveUserMutation>;
export type RemoveUserMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveUserMutation, RemoveUserMutationVariables>;
export const RevokeCustomerRoleFromApiKeyDocument = gql`
    mutation revokeCustomerRoleFromApiKey($input: RevokeCustomerRoleFromApiKeyInput!) {
  revokeCustomerRoleFromApiKey(input: $input)
}
    `;
export type RevokeCustomerRoleFromApiKeyMutationFn = ApolloReactCommon.MutationFunction<RevokeCustomerRoleFromApiKeyMutation, RevokeCustomerRoleFromApiKeyMutationVariables>;
export type RevokeCustomerRoleFromApiKeyComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RevokeCustomerRoleFromApiKeyMutation, RevokeCustomerRoleFromApiKeyMutationVariables>, 'mutation'>;

    export const RevokeCustomerRoleFromApiKeyComponent = (props: RevokeCustomerRoleFromApiKeyComponentProps) => (
      <ApolloReactComponents.Mutation<RevokeCustomerRoleFromApiKeyMutation, RevokeCustomerRoleFromApiKeyMutationVariables> mutation={RevokeCustomerRoleFromApiKeyDocument} {...props} />
    );
    
export type RevokeCustomerRoleFromApiKeyProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<RevokeCustomerRoleFromApiKeyMutation, RevokeCustomerRoleFromApiKeyMutationVariables>
    } & TChildProps;
export function withRevokeCustomerRoleFromApiKey<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RevokeCustomerRoleFromApiKeyMutation,
  RevokeCustomerRoleFromApiKeyMutationVariables,
  RevokeCustomerRoleFromApiKeyProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, RevokeCustomerRoleFromApiKeyMutation, RevokeCustomerRoleFromApiKeyMutationVariables, RevokeCustomerRoleFromApiKeyProps<TChildProps, TDataName>>(RevokeCustomerRoleFromApiKeyDocument, {
      alias: 'revokeCustomerRoleFromApiKey',
      ...operationOptions
    });
};

/**
 * __useRevokeCustomerRoleFromApiKeyMutation__
 *
 * To run a mutation, you first call `useRevokeCustomerRoleFromApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeCustomerRoleFromApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeCustomerRoleFromApiKeyMutation, { data, loading, error }] = useRevokeCustomerRoleFromApiKeyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRevokeCustomerRoleFromApiKeyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RevokeCustomerRoleFromApiKeyMutation, RevokeCustomerRoleFromApiKeyMutationVariables>) {
        return ApolloReactHooks.useMutation<RevokeCustomerRoleFromApiKeyMutation, RevokeCustomerRoleFromApiKeyMutationVariables>(RevokeCustomerRoleFromApiKeyDocument, baseOptions);
      }
export type RevokeCustomerRoleFromApiKeyMutationHookResult = ReturnType<typeof useRevokeCustomerRoleFromApiKeyMutation>;
export type RevokeCustomerRoleFromApiKeyMutationResult = ApolloReactCommon.MutationResult<RevokeCustomerRoleFromApiKeyMutation>;
export type RevokeCustomerRoleFromApiKeyMutationOptions = ApolloReactCommon.BaseMutationOptions<RevokeCustomerRoleFromApiKeyMutation, RevokeCustomerRoleFromApiKeyMutationVariables>;
export const RevokeCustomerRoleFromUserDocument = gql`
    mutation revokeCustomerRoleFromUser($input: RevokeCustomerRoleFromUserInput!) {
  revokeCustomerRoleFromUser(input: $input)
}
    `;
export type RevokeCustomerRoleFromUserMutationFn = ApolloReactCommon.MutationFunction<RevokeCustomerRoleFromUserMutation, RevokeCustomerRoleFromUserMutationVariables>;
export type RevokeCustomerRoleFromUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RevokeCustomerRoleFromUserMutation, RevokeCustomerRoleFromUserMutationVariables>, 'mutation'>;

    export const RevokeCustomerRoleFromUserComponent = (props: RevokeCustomerRoleFromUserComponentProps) => (
      <ApolloReactComponents.Mutation<RevokeCustomerRoleFromUserMutation, RevokeCustomerRoleFromUserMutationVariables> mutation={RevokeCustomerRoleFromUserDocument} {...props} />
    );
    
export type RevokeCustomerRoleFromUserProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<RevokeCustomerRoleFromUserMutation, RevokeCustomerRoleFromUserMutationVariables>
    } & TChildProps;
export function withRevokeCustomerRoleFromUser<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RevokeCustomerRoleFromUserMutation,
  RevokeCustomerRoleFromUserMutationVariables,
  RevokeCustomerRoleFromUserProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, RevokeCustomerRoleFromUserMutation, RevokeCustomerRoleFromUserMutationVariables, RevokeCustomerRoleFromUserProps<TChildProps, TDataName>>(RevokeCustomerRoleFromUserDocument, {
      alias: 'revokeCustomerRoleFromUser',
      ...operationOptions
    });
};

/**
 * __useRevokeCustomerRoleFromUserMutation__
 *
 * To run a mutation, you first call `useRevokeCustomerRoleFromUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeCustomerRoleFromUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeCustomerRoleFromUserMutation, { data, loading, error }] = useRevokeCustomerRoleFromUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRevokeCustomerRoleFromUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RevokeCustomerRoleFromUserMutation, RevokeCustomerRoleFromUserMutationVariables>) {
        return ApolloReactHooks.useMutation<RevokeCustomerRoleFromUserMutation, RevokeCustomerRoleFromUserMutationVariables>(RevokeCustomerRoleFromUserDocument, baseOptions);
      }
export type RevokeCustomerRoleFromUserMutationHookResult = ReturnType<typeof useRevokeCustomerRoleFromUserMutation>;
export type RevokeCustomerRoleFromUserMutationResult = ApolloReactCommon.MutationResult<RevokeCustomerRoleFromUserMutation>;
export type RevokeCustomerRoleFromUserMutationOptions = ApolloReactCommon.BaseMutationOptions<RevokeCustomerRoleFromUserMutation, RevokeCustomerRoleFromUserMutationVariables>;
export const RevokeSuperAdminDocument = gql`
    mutation revokeSuperAdmin($input: String!) {
  revokeSuperAdmin(email: $input)
}
    `;
export type RevokeSuperAdminMutationFn = ApolloReactCommon.MutationFunction<RevokeSuperAdminMutation, RevokeSuperAdminMutationVariables>;
export type RevokeSuperAdminComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RevokeSuperAdminMutation, RevokeSuperAdminMutationVariables>, 'mutation'>;

    export const RevokeSuperAdminComponent = (props: RevokeSuperAdminComponentProps) => (
      <ApolloReactComponents.Mutation<RevokeSuperAdminMutation, RevokeSuperAdminMutationVariables> mutation={RevokeSuperAdminDocument} {...props} />
    );
    
export type RevokeSuperAdminProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<RevokeSuperAdminMutation, RevokeSuperAdminMutationVariables>
    } & TChildProps;
export function withRevokeSuperAdmin<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RevokeSuperAdminMutation,
  RevokeSuperAdminMutationVariables,
  RevokeSuperAdminProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, RevokeSuperAdminMutation, RevokeSuperAdminMutationVariables, RevokeSuperAdminProps<TChildProps, TDataName>>(RevokeSuperAdminDocument, {
      alias: 'revokeSuperAdmin',
      ...operationOptions
    });
};

/**
 * __useRevokeSuperAdminMutation__
 *
 * To run a mutation, you first call `useRevokeSuperAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeSuperAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeSuperAdminMutation, { data, loading, error }] = useRevokeSuperAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRevokeSuperAdminMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RevokeSuperAdminMutation, RevokeSuperAdminMutationVariables>) {
        return ApolloReactHooks.useMutation<RevokeSuperAdminMutation, RevokeSuperAdminMutationVariables>(RevokeSuperAdminDocument, baseOptions);
      }
export type RevokeSuperAdminMutationHookResult = ReturnType<typeof useRevokeSuperAdminMutation>;
export type RevokeSuperAdminMutationResult = ApolloReactCommon.MutationResult<RevokeSuperAdminMutation>;
export type RevokeSuperAdminMutationOptions = ApolloReactCommon.BaseMutationOptions<RevokeSuperAdminMutation, RevokeSuperAdminMutationVariables>;
export const ScanStatusChangedDocument = gql`
    subscription scanStatusChanged {
  scanStatusChanged {
    customerId
    scanId
    status
  }
}
    `;
export type ScanStatusChangedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<ScanStatusChangedSubscription, ScanStatusChangedSubscriptionVariables>, 'subscription'>;

    export const ScanStatusChangedComponent = (props: ScanStatusChangedComponentProps) => (
      <ApolloReactComponents.Subscription<ScanStatusChangedSubscription, ScanStatusChangedSubscriptionVariables> subscription={ScanStatusChangedDocument} {...props} />
    );
    
export type ScanStatusChangedProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ScanStatusChangedSubscription, ScanStatusChangedSubscriptionVariables>
    } & TChildProps;
export function withScanStatusChanged<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ScanStatusChangedSubscription,
  ScanStatusChangedSubscriptionVariables,
  ScanStatusChangedProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withSubscription<TProps, ScanStatusChangedSubscription, ScanStatusChangedSubscriptionVariables, ScanStatusChangedProps<TChildProps, TDataName>>(ScanStatusChangedDocument, {
      alias: 'scanStatusChanged',
      ...operationOptions
    });
};

/**
 * __useScanStatusChangedSubscription__
 *
 * To run a query within a React component, call `useScanStatusChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useScanStatusChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScanStatusChangedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useScanStatusChangedSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<ScanStatusChangedSubscription, ScanStatusChangedSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<ScanStatusChangedSubscription, ScanStatusChangedSubscriptionVariables>(ScanStatusChangedDocument, baseOptions);
      }
export type ScanStatusChangedSubscriptionHookResult = ReturnType<typeof useScanStatusChangedSubscription>;
export type ScanStatusChangedSubscriptionResult = ApolloReactCommon.SubscriptionResult<ScanStatusChangedSubscription>;
export const SelfCreateApiKeyDocument = gql`
    mutation selfCreateApiKey($input: SelfCreateApiKeyInput!) {
  selfCreateApiKey(input: $input) {
    apiKey {
      id
      alias
      owner
      lastUsedAt
      createdAt
      createdBy
    }
    apiKeyString
  }
}
    `;
export type SelfCreateApiKeyMutationFn = ApolloReactCommon.MutationFunction<SelfCreateApiKeyMutation, SelfCreateApiKeyMutationVariables>;
export type SelfCreateApiKeyComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SelfCreateApiKeyMutation, SelfCreateApiKeyMutationVariables>, 'mutation'>;

    export const SelfCreateApiKeyComponent = (props: SelfCreateApiKeyComponentProps) => (
      <ApolloReactComponents.Mutation<SelfCreateApiKeyMutation, SelfCreateApiKeyMutationVariables> mutation={SelfCreateApiKeyDocument} {...props} />
    );
    
export type SelfCreateApiKeyProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<SelfCreateApiKeyMutation, SelfCreateApiKeyMutationVariables>
    } & TChildProps;
export function withSelfCreateApiKey<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SelfCreateApiKeyMutation,
  SelfCreateApiKeyMutationVariables,
  SelfCreateApiKeyProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, SelfCreateApiKeyMutation, SelfCreateApiKeyMutationVariables, SelfCreateApiKeyProps<TChildProps, TDataName>>(SelfCreateApiKeyDocument, {
      alias: 'selfCreateApiKey',
      ...operationOptions
    });
};

/**
 * __useSelfCreateApiKeyMutation__
 *
 * To run a mutation, you first call `useSelfCreateApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSelfCreateApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [selfCreateApiKeyMutation, { data, loading, error }] = useSelfCreateApiKeyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSelfCreateApiKeyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SelfCreateApiKeyMutation, SelfCreateApiKeyMutationVariables>) {
        return ApolloReactHooks.useMutation<SelfCreateApiKeyMutation, SelfCreateApiKeyMutationVariables>(SelfCreateApiKeyDocument, baseOptions);
      }
export type SelfCreateApiKeyMutationHookResult = ReturnType<typeof useSelfCreateApiKeyMutation>;
export type SelfCreateApiKeyMutationResult = ApolloReactCommon.MutationResult<SelfCreateApiKeyMutation>;
export type SelfCreateApiKeyMutationOptions = ApolloReactCommon.BaseMutationOptions<SelfCreateApiKeyMutation, SelfCreateApiKeyMutationVariables>;
export const SelfGrantCustomerRoleToApiKeyDocument = gql`
    mutation selfGrantCustomerRoleToApiKey($input: GrantCustomerRoleToApiKeyInput!) {
  selfGrantCustomerRoleToApiKey(input: $input)
}
    `;
export type SelfGrantCustomerRoleToApiKeyMutationFn = ApolloReactCommon.MutationFunction<SelfGrantCustomerRoleToApiKeyMutation, SelfGrantCustomerRoleToApiKeyMutationVariables>;
export type SelfGrantCustomerRoleToApiKeyComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SelfGrantCustomerRoleToApiKeyMutation, SelfGrantCustomerRoleToApiKeyMutationVariables>, 'mutation'>;

    export const SelfGrantCustomerRoleToApiKeyComponent = (props: SelfGrantCustomerRoleToApiKeyComponentProps) => (
      <ApolloReactComponents.Mutation<SelfGrantCustomerRoleToApiKeyMutation, SelfGrantCustomerRoleToApiKeyMutationVariables> mutation={SelfGrantCustomerRoleToApiKeyDocument} {...props} />
    );
    
export type SelfGrantCustomerRoleToApiKeyProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<SelfGrantCustomerRoleToApiKeyMutation, SelfGrantCustomerRoleToApiKeyMutationVariables>
    } & TChildProps;
export function withSelfGrantCustomerRoleToApiKey<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SelfGrantCustomerRoleToApiKeyMutation,
  SelfGrantCustomerRoleToApiKeyMutationVariables,
  SelfGrantCustomerRoleToApiKeyProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, SelfGrantCustomerRoleToApiKeyMutation, SelfGrantCustomerRoleToApiKeyMutationVariables, SelfGrantCustomerRoleToApiKeyProps<TChildProps, TDataName>>(SelfGrantCustomerRoleToApiKeyDocument, {
      alias: 'selfGrantCustomerRoleToApiKey',
      ...operationOptions
    });
};

/**
 * __useSelfGrantCustomerRoleToApiKeyMutation__
 *
 * To run a mutation, you first call `useSelfGrantCustomerRoleToApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSelfGrantCustomerRoleToApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [selfGrantCustomerRoleToApiKeyMutation, { data, loading, error }] = useSelfGrantCustomerRoleToApiKeyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSelfGrantCustomerRoleToApiKeyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SelfGrantCustomerRoleToApiKeyMutation, SelfGrantCustomerRoleToApiKeyMutationVariables>) {
        return ApolloReactHooks.useMutation<SelfGrantCustomerRoleToApiKeyMutation, SelfGrantCustomerRoleToApiKeyMutationVariables>(SelfGrantCustomerRoleToApiKeyDocument, baseOptions);
      }
export type SelfGrantCustomerRoleToApiKeyMutationHookResult = ReturnType<typeof useSelfGrantCustomerRoleToApiKeyMutation>;
export type SelfGrantCustomerRoleToApiKeyMutationResult = ApolloReactCommon.MutationResult<SelfGrantCustomerRoleToApiKeyMutation>;
export type SelfGrantCustomerRoleToApiKeyMutationOptions = ApolloReactCommon.BaseMutationOptions<SelfGrantCustomerRoleToApiKeyMutation, SelfGrantCustomerRoleToApiKeyMutationVariables>;
export const SelfRemoveApiKeyDocument = gql`
    mutation selfRemoveApiKey($id: ID!) {
  selfRemoveApiKey(id: $id)
}
    `;
export type SelfRemoveApiKeyMutationFn = ApolloReactCommon.MutationFunction<SelfRemoveApiKeyMutation, SelfRemoveApiKeyMutationVariables>;
export type SelfRemoveApiKeyComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SelfRemoveApiKeyMutation, SelfRemoveApiKeyMutationVariables>, 'mutation'>;

    export const SelfRemoveApiKeyComponent = (props: SelfRemoveApiKeyComponentProps) => (
      <ApolloReactComponents.Mutation<SelfRemoveApiKeyMutation, SelfRemoveApiKeyMutationVariables> mutation={SelfRemoveApiKeyDocument} {...props} />
    );
    
export type SelfRemoveApiKeyProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<SelfRemoveApiKeyMutation, SelfRemoveApiKeyMutationVariables>
    } & TChildProps;
export function withSelfRemoveApiKey<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SelfRemoveApiKeyMutation,
  SelfRemoveApiKeyMutationVariables,
  SelfRemoveApiKeyProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, SelfRemoveApiKeyMutation, SelfRemoveApiKeyMutationVariables, SelfRemoveApiKeyProps<TChildProps, TDataName>>(SelfRemoveApiKeyDocument, {
      alias: 'selfRemoveApiKey',
      ...operationOptions
    });
};

/**
 * __useSelfRemoveApiKeyMutation__
 *
 * To run a mutation, you first call `useSelfRemoveApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSelfRemoveApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [selfRemoveApiKeyMutation, { data, loading, error }] = useSelfRemoveApiKeyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSelfRemoveApiKeyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SelfRemoveApiKeyMutation, SelfRemoveApiKeyMutationVariables>) {
        return ApolloReactHooks.useMutation<SelfRemoveApiKeyMutation, SelfRemoveApiKeyMutationVariables>(SelfRemoveApiKeyDocument, baseOptions);
      }
export type SelfRemoveApiKeyMutationHookResult = ReturnType<typeof useSelfRemoveApiKeyMutation>;
export type SelfRemoveApiKeyMutationResult = ApolloReactCommon.MutationResult<SelfRemoveApiKeyMutation>;
export type SelfRemoveApiKeyMutationOptions = ApolloReactCommon.BaseMutationOptions<SelfRemoveApiKeyMutation, SelfRemoveApiKeyMutationVariables>;
export const SelfRevokeCustomerRoleFromApiKeyDocument = gql`
    mutation selfRevokeCustomerRoleFromApiKey($input: RevokeCustomerRoleFromApiKeyInput!) {
  selfRevokeCustomerRoleFromApiKey(input: $input)
}
    `;
export type SelfRevokeCustomerRoleFromApiKeyMutationFn = ApolloReactCommon.MutationFunction<SelfRevokeCustomerRoleFromApiKeyMutation, SelfRevokeCustomerRoleFromApiKeyMutationVariables>;
export type SelfRevokeCustomerRoleFromApiKeyComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SelfRevokeCustomerRoleFromApiKeyMutation, SelfRevokeCustomerRoleFromApiKeyMutationVariables>, 'mutation'>;

    export const SelfRevokeCustomerRoleFromApiKeyComponent = (props: SelfRevokeCustomerRoleFromApiKeyComponentProps) => (
      <ApolloReactComponents.Mutation<SelfRevokeCustomerRoleFromApiKeyMutation, SelfRevokeCustomerRoleFromApiKeyMutationVariables> mutation={SelfRevokeCustomerRoleFromApiKeyDocument} {...props} />
    );
    
export type SelfRevokeCustomerRoleFromApiKeyProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<SelfRevokeCustomerRoleFromApiKeyMutation, SelfRevokeCustomerRoleFromApiKeyMutationVariables>
    } & TChildProps;
export function withSelfRevokeCustomerRoleFromApiKey<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SelfRevokeCustomerRoleFromApiKeyMutation,
  SelfRevokeCustomerRoleFromApiKeyMutationVariables,
  SelfRevokeCustomerRoleFromApiKeyProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, SelfRevokeCustomerRoleFromApiKeyMutation, SelfRevokeCustomerRoleFromApiKeyMutationVariables, SelfRevokeCustomerRoleFromApiKeyProps<TChildProps, TDataName>>(SelfRevokeCustomerRoleFromApiKeyDocument, {
      alias: 'selfRevokeCustomerRoleFromApiKey',
      ...operationOptions
    });
};

/**
 * __useSelfRevokeCustomerRoleFromApiKeyMutation__
 *
 * To run a mutation, you first call `useSelfRevokeCustomerRoleFromApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSelfRevokeCustomerRoleFromApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [selfRevokeCustomerRoleFromApiKeyMutation, { data, loading, error }] = useSelfRevokeCustomerRoleFromApiKeyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSelfRevokeCustomerRoleFromApiKeyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SelfRevokeCustomerRoleFromApiKeyMutation, SelfRevokeCustomerRoleFromApiKeyMutationVariables>) {
        return ApolloReactHooks.useMutation<SelfRevokeCustomerRoleFromApiKeyMutation, SelfRevokeCustomerRoleFromApiKeyMutationVariables>(SelfRevokeCustomerRoleFromApiKeyDocument, baseOptions);
      }
export type SelfRevokeCustomerRoleFromApiKeyMutationHookResult = ReturnType<typeof useSelfRevokeCustomerRoleFromApiKeyMutation>;
export type SelfRevokeCustomerRoleFromApiKeyMutationResult = ApolloReactCommon.MutationResult<SelfRevokeCustomerRoleFromApiKeyMutation>;
export type SelfRevokeCustomerRoleFromApiKeyMutationOptions = ApolloReactCommon.BaseMutationOptions<SelfRevokeCustomerRoleFromApiKeyMutation, SelfRevokeCustomerRoleFromApiKeyMutationVariables>;
export const StartDisasterRecoveryDocument = gql`
    mutation startDisasterRecovery($input: StartDisasterRecoveryInput!) {
  startDisasterRecovery(input: $input) {
    id
  }
}
    `;
export type StartDisasterRecoveryMutationFn = ApolloReactCommon.MutationFunction<StartDisasterRecoveryMutation, StartDisasterRecoveryMutationVariables>;
export type StartDisasterRecoveryComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<StartDisasterRecoveryMutation, StartDisasterRecoveryMutationVariables>, 'mutation'>;

    export const StartDisasterRecoveryComponent = (props: StartDisasterRecoveryComponentProps) => (
      <ApolloReactComponents.Mutation<StartDisasterRecoveryMutation, StartDisasterRecoveryMutationVariables> mutation={StartDisasterRecoveryDocument} {...props} />
    );
    
export type StartDisasterRecoveryProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<StartDisasterRecoveryMutation, StartDisasterRecoveryMutationVariables>
    } & TChildProps;
export function withStartDisasterRecovery<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  StartDisasterRecoveryMutation,
  StartDisasterRecoveryMutationVariables,
  StartDisasterRecoveryProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, StartDisasterRecoveryMutation, StartDisasterRecoveryMutationVariables, StartDisasterRecoveryProps<TChildProps, TDataName>>(StartDisasterRecoveryDocument, {
      alias: 'startDisasterRecovery',
      ...operationOptions
    });
};

/**
 * __useStartDisasterRecoveryMutation__
 *
 * To run a mutation, you first call `useStartDisasterRecoveryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartDisasterRecoveryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startDisasterRecoveryMutation, { data, loading, error }] = useStartDisasterRecoveryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartDisasterRecoveryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StartDisasterRecoveryMutation, StartDisasterRecoveryMutationVariables>) {
        return ApolloReactHooks.useMutation<StartDisasterRecoveryMutation, StartDisasterRecoveryMutationVariables>(StartDisasterRecoveryDocument, baseOptions);
      }
export type StartDisasterRecoveryMutationHookResult = ReturnType<typeof useStartDisasterRecoveryMutation>;
export type StartDisasterRecoveryMutationResult = ApolloReactCommon.MutationResult<StartDisasterRecoveryMutation>;
export type StartDisasterRecoveryMutationOptions = ApolloReactCommon.BaseMutationOptions<StartDisasterRecoveryMutation, StartDisasterRecoveryMutationVariables>;
export const TriggerBackupDocument = gql`
    mutation triggerBackup($input: TriggerBackupInput!) {
  triggerBackup(input: $input)
}
    `;
export type TriggerBackupMutationFn = ApolloReactCommon.MutationFunction<TriggerBackupMutation, TriggerBackupMutationVariables>;
export type TriggerBackupComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<TriggerBackupMutation, TriggerBackupMutationVariables>, 'mutation'>;

    export const TriggerBackupComponent = (props: TriggerBackupComponentProps) => (
      <ApolloReactComponents.Mutation<TriggerBackupMutation, TriggerBackupMutationVariables> mutation={TriggerBackupDocument} {...props} />
    );
    
export type TriggerBackupProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<TriggerBackupMutation, TriggerBackupMutationVariables>
    } & TChildProps;
export function withTriggerBackup<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TriggerBackupMutation,
  TriggerBackupMutationVariables,
  TriggerBackupProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, TriggerBackupMutation, TriggerBackupMutationVariables, TriggerBackupProps<TChildProps, TDataName>>(TriggerBackupDocument, {
      alias: 'triggerBackup',
      ...operationOptions
    });
};

/**
 * __useTriggerBackupMutation__
 *
 * To run a mutation, you first call `useTriggerBackupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggerBackupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggerBackupMutation, { data, loading, error }] = useTriggerBackupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTriggerBackupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TriggerBackupMutation, TriggerBackupMutationVariables>) {
        return ApolloReactHooks.useMutation<TriggerBackupMutation, TriggerBackupMutationVariables>(TriggerBackupDocument, baseOptions);
      }
export type TriggerBackupMutationHookResult = ReturnType<typeof useTriggerBackupMutation>;
export type TriggerBackupMutationResult = ApolloReactCommon.MutationResult<TriggerBackupMutation>;
export type TriggerBackupMutationOptions = ApolloReactCommon.BaseMutationOptions<TriggerBackupMutation, TriggerBackupMutationVariables>;
export const TriggerScanDocument = gql`
    mutation triggerScan($input: TriggerScanInput!) {
  triggerScan(input: $input) {
    scanId
  }
}
    `;
export type TriggerScanMutationFn = ApolloReactCommon.MutationFunction<TriggerScanMutation, TriggerScanMutationVariables>;
export type TriggerScanComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<TriggerScanMutation, TriggerScanMutationVariables>, 'mutation'>;

    export const TriggerScanComponent = (props: TriggerScanComponentProps) => (
      <ApolloReactComponents.Mutation<TriggerScanMutation, TriggerScanMutationVariables> mutation={TriggerScanDocument} {...props} />
    );
    
export type TriggerScanProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<TriggerScanMutation, TriggerScanMutationVariables>
    } & TChildProps;
export function withTriggerScan<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TriggerScanMutation,
  TriggerScanMutationVariables,
  TriggerScanProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, TriggerScanMutation, TriggerScanMutationVariables, TriggerScanProps<TChildProps, TDataName>>(TriggerScanDocument, {
      alias: 'triggerScan',
      ...operationOptions
    });
};

/**
 * __useTriggerScanMutation__
 *
 * To run a mutation, you first call `useTriggerScanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggerScanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggerScanMutation, { data, loading, error }] = useTriggerScanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTriggerScanMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TriggerScanMutation, TriggerScanMutationVariables>) {
        return ApolloReactHooks.useMutation<TriggerScanMutation, TriggerScanMutationVariables>(TriggerScanDocument, baseOptions);
      }
export type TriggerScanMutationHookResult = ReturnType<typeof useTriggerScanMutation>;
export type TriggerScanMutationResult = ApolloReactCommon.MutationResult<TriggerScanMutation>;
export type TriggerScanMutationOptions = ApolloReactCommon.BaseMutationOptions<TriggerScanMutation, TriggerScanMutationVariables>;
export const UpdateBackupSetupDocument = gql`
    mutation updateBackupSetup($input: UpdateBackupSetupInput!) {
  updateBackupSetup(input: $input) {
    id
  }
}
    `;
export type UpdateBackupSetupMutationFn = ApolloReactCommon.MutationFunction<UpdateBackupSetupMutation, UpdateBackupSetupMutationVariables>;
export type UpdateBackupSetupComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateBackupSetupMutation, UpdateBackupSetupMutationVariables>, 'mutation'>;

    export const UpdateBackupSetupComponent = (props: UpdateBackupSetupComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateBackupSetupMutation, UpdateBackupSetupMutationVariables> mutation={UpdateBackupSetupDocument} {...props} />
    );
    
export type UpdateBackupSetupProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateBackupSetupMutation, UpdateBackupSetupMutationVariables>
    } & TChildProps;
export function withUpdateBackupSetup<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateBackupSetupMutation,
  UpdateBackupSetupMutationVariables,
  UpdateBackupSetupProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateBackupSetupMutation, UpdateBackupSetupMutationVariables, UpdateBackupSetupProps<TChildProps, TDataName>>(UpdateBackupSetupDocument, {
      alias: 'updateBackupSetup',
      ...operationOptions
    });
};

/**
 * __useUpdateBackupSetupMutation__
 *
 * To run a mutation, you first call `useUpdateBackupSetupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBackupSetupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBackupSetupMutation, { data, loading, error }] = useUpdateBackupSetupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBackupSetupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateBackupSetupMutation, UpdateBackupSetupMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateBackupSetupMutation, UpdateBackupSetupMutationVariables>(UpdateBackupSetupDocument, baseOptions);
      }
export type UpdateBackupSetupMutationHookResult = ReturnType<typeof useUpdateBackupSetupMutation>;
export type UpdateBackupSetupMutationResult = ApolloReactCommon.MutationResult<UpdateBackupSetupMutation>;
export type UpdateBackupSetupMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateBackupSetupMutation, UpdateBackupSetupMutationVariables>;
export const UpdateDisasterRecoveryPlanDocument = gql`
    mutation updateDisasterRecoveryPlan($input: UpdateDisasterRecoveryPlanInput!) {
  updateDisasterRecoveryPlan(input: $input) {
    id
  }
}
    `;
export type UpdateDisasterRecoveryPlanMutationFn = ApolloReactCommon.MutationFunction<UpdateDisasterRecoveryPlanMutation, UpdateDisasterRecoveryPlanMutationVariables>;
export type UpdateDisasterRecoveryPlanComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateDisasterRecoveryPlanMutation, UpdateDisasterRecoveryPlanMutationVariables>, 'mutation'>;

    export const UpdateDisasterRecoveryPlanComponent = (props: UpdateDisasterRecoveryPlanComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateDisasterRecoveryPlanMutation, UpdateDisasterRecoveryPlanMutationVariables> mutation={UpdateDisasterRecoveryPlanDocument} {...props} />
    );
    
export type UpdateDisasterRecoveryPlanProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateDisasterRecoveryPlanMutation, UpdateDisasterRecoveryPlanMutationVariables>
    } & TChildProps;
export function withUpdateDisasterRecoveryPlan<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateDisasterRecoveryPlanMutation,
  UpdateDisasterRecoveryPlanMutationVariables,
  UpdateDisasterRecoveryPlanProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateDisasterRecoveryPlanMutation, UpdateDisasterRecoveryPlanMutationVariables, UpdateDisasterRecoveryPlanProps<TChildProps, TDataName>>(UpdateDisasterRecoveryPlanDocument, {
      alias: 'updateDisasterRecoveryPlan',
      ...operationOptions
    });
};

/**
 * __useUpdateDisasterRecoveryPlanMutation__
 *
 * To run a mutation, you first call `useUpdateDisasterRecoveryPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDisasterRecoveryPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDisasterRecoveryPlanMutation, { data, loading, error }] = useUpdateDisasterRecoveryPlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDisasterRecoveryPlanMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDisasterRecoveryPlanMutation, UpdateDisasterRecoveryPlanMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDisasterRecoveryPlanMutation, UpdateDisasterRecoveryPlanMutationVariables>(UpdateDisasterRecoveryPlanDocument, baseOptions);
      }
export type UpdateDisasterRecoveryPlanMutationHookResult = ReturnType<typeof useUpdateDisasterRecoveryPlanMutation>;
export type UpdateDisasterRecoveryPlanMutationResult = ApolloReactCommon.MutationResult<UpdateDisasterRecoveryPlanMutation>;
export type UpdateDisasterRecoveryPlanMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDisasterRecoveryPlanMutation, UpdateDisasterRecoveryPlanMutationVariables>;
export const UpdateDisasterRecoveryRecordDocument = gql`
    mutation updateDisasterRecoveryRecord($input: UpdateDisasterRecoveryRecordInput!) {
  updateDisasterRecoveryRecord(input: $input) {
    id
  }
}
    `;
export type UpdateDisasterRecoveryRecordMutationFn = ApolloReactCommon.MutationFunction<UpdateDisasterRecoveryRecordMutation, UpdateDisasterRecoveryRecordMutationVariables>;
export type UpdateDisasterRecoveryRecordComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateDisasterRecoveryRecordMutation, UpdateDisasterRecoveryRecordMutationVariables>, 'mutation'>;

    export const UpdateDisasterRecoveryRecordComponent = (props: UpdateDisasterRecoveryRecordComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateDisasterRecoveryRecordMutation, UpdateDisasterRecoveryRecordMutationVariables> mutation={UpdateDisasterRecoveryRecordDocument} {...props} />
    );
    
export type UpdateDisasterRecoveryRecordProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateDisasterRecoveryRecordMutation, UpdateDisasterRecoveryRecordMutationVariables>
    } & TChildProps;
export function withUpdateDisasterRecoveryRecord<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateDisasterRecoveryRecordMutation,
  UpdateDisasterRecoveryRecordMutationVariables,
  UpdateDisasterRecoveryRecordProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateDisasterRecoveryRecordMutation, UpdateDisasterRecoveryRecordMutationVariables, UpdateDisasterRecoveryRecordProps<TChildProps, TDataName>>(UpdateDisasterRecoveryRecordDocument, {
      alias: 'updateDisasterRecoveryRecord',
      ...operationOptions
    });
};

/**
 * __useUpdateDisasterRecoveryRecordMutation__
 *
 * To run a mutation, you first call `useUpdateDisasterRecoveryRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDisasterRecoveryRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDisasterRecoveryRecordMutation, { data, loading, error }] = useUpdateDisasterRecoveryRecordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDisasterRecoveryRecordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDisasterRecoveryRecordMutation, UpdateDisasterRecoveryRecordMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDisasterRecoveryRecordMutation, UpdateDisasterRecoveryRecordMutationVariables>(UpdateDisasterRecoveryRecordDocument, baseOptions);
      }
export type UpdateDisasterRecoveryRecordMutationHookResult = ReturnType<typeof useUpdateDisasterRecoveryRecordMutation>;
export type UpdateDisasterRecoveryRecordMutationResult = ApolloReactCommon.MutationResult<UpdateDisasterRecoveryRecordMutation>;
export type UpdateDisasterRecoveryRecordMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDisasterRecoveryRecordMutation, UpdateDisasterRecoveryRecordMutationVariables>;
export const UpdateTemplateDocument = gql`
    mutation updateTemplate($input: UpdateTemplateInput!) {
  updateTemplate(input: $input) {
    id
  }
}
    `;
export type UpdateTemplateMutationFn = ApolloReactCommon.MutationFunction<UpdateTemplateMutation, UpdateTemplateMutationVariables>;
export type UpdateTemplateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateTemplateMutation, UpdateTemplateMutationVariables>, 'mutation'>;

    export const UpdateTemplateComponent = (props: UpdateTemplateComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateTemplateMutation, UpdateTemplateMutationVariables> mutation={UpdateTemplateDocument} {...props} />
    );
    
export type UpdateTemplateProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateTemplateMutation, UpdateTemplateMutationVariables>
    } & TChildProps;
export function withUpdateTemplate<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateTemplateMutation,
  UpdateTemplateMutationVariables,
  UpdateTemplateProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateTemplateMutation, UpdateTemplateMutationVariables, UpdateTemplateProps<TChildProps, TDataName>>(UpdateTemplateDocument, {
      alias: 'updateTemplate',
      ...operationOptions
    });
};

/**
 * __useUpdateTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTemplateMutation, { data, loading, error }] = useUpdateTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTemplateMutation, UpdateTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateTemplateMutation, UpdateTemplateMutationVariables>(UpdateTemplateDocument, baseOptions);
      }
export type UpdateTemplateMutationHookResult = ReturnType<typeof useUpdateTemplateMutation>;
export type UpdateTemplateMutationResult = ApolloReactCommon.MutationResult<UpdateTemplateMutation>;
export type UpdateTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateTemplateMutation, UpdateTemplateMutationVariables>;
export const UpdateUserProfileDocument = gql`
    mutation updateUserProfile($input: UserProfileInput!) {
  updateUserProfile(input: $input) {
    isBackupControlledByTags
  }
}
    `;
export type UpdateUserProfileMutationFn = ApolloReactCommon.MutationFunction<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>;
export type UpdateUserProfileComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>, 'mutation'>;

    export const UpdateUserProfileComponent = (props: UpdateUserProfileComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateUserProfileMutation, UpdateUserProfileMutationVariables> mutation={UpdateUserProfileDocument} {...props} />
    );
    
export type UpdateUserProfileProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>
    } & TChildProps;
export function withUpdateUserProfile<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateUserProfileMutation,
  UpdateUserProfileMutationVariables,
  UpdateUserProfileProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateUserProfileMutation, UpdateUserProfileMutationVariables, UpdateUserProfileProps<TChildProps, TDataName>>(UpdateUserProfileDocument, {
      alias: 'updateUserProfile',
      ...operationOptions
    });
};

/**
 * __useUpdateUserProfileMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileMutation, { data, loading, error }] = useUpdateUserProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserProfileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>(UpdateUserProfileDocument, baseOptions);
      }
export type UpdateUserProfileMutationHookResult = ReturnType<typeof useUpdateUserProfileMutation>;
export type UpdateUserProfileMutationResult = ApolloReactCommon.MutationResult<UpdateUserProfileMutation>;
export type UpdateUserProfileMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>;
export const ValidateTemplateDocument = gql`
    mutation validateTemplate($input: ValidateTemplateInput!) {
  validateTemplate(input: $input) {
    parameters
  }
}
    `;
export type ValidateTemplateMutationFn = ApolloReactCommon.MutationFunction<ValidateTemplateMutation, ValidateTemplateMutationVariables>;
export type ValidateTemplateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ValidateTemplateMutation, ValidateTemplateMutationVariables>, 'mutation'>;

    export const ValidateTemplateComponent = (props: ValidateTemplateComponentProps) => (
      <ApolloReactComponents.Mutation<ValidateTemplateMutation, ValidateTemplateMutationVariables> mutation={ValidateTemplateDocument} {...props} />
    );
    
export type ValidateTemplateProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<ValidateTemplateMutation, ValidateTemplateMutationVariables>
    } & TChildProps;
export function withValidateTemplate<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ValidateTemplateMutation,
  ValidateTemplateMutationVariables,
  ValidateTemplateProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, ValidateTemplateMutation, ValidateTemplateMutationVariables, ValidateTemplateProps<TChildProps, TDataName>>(ValidateTemplateDocument, {
      alias: 'validateTemplate',
      ...operationOptions
    });
};

/**
 * __useValidateTemplateMutation__
 *
 * To run a mutation, you first call `useValidateTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateTemplateMutation, { data, loading, error }] = useValidateTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useValidateTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ValidateTemplateMutation, ValidateTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<ValidateTemplateMutation, ValidateTemplateMutationVariables>(ValidateTemplateDocument, baseOptions);
      }
export type ValidateTemplateMutationHookResult = ReturnType<typeof useValidateTemplateMutation>;
export type ValidateTemplateMutationResult = ApolloReactCommon.MutationResult<ValidateTemplateMutation>;
export type ValidateTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<ValidateTemplateMutation, ValidateTemplateMutationVariables>;