/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { CustomerContext } from "../../../context/customer";
import {
  BackupSettingInput,
  BackupSetup,
  HookInput,
  HookType,
  useGetBackupSetupQuery,
  useGetUserRoleQuery,
  UserRole,
  useUpdateBackupSetupMutation,
} from "../../../generated/graphql";
import { Loading } from "../../../commonComponents/loading/Loading";
import { Error } from "../../../commonComponents/error/error";
import Input from "../../../commonComponents/input/Input";
import Button from "../../../commonComponents/button/Button";
import { ROUTES } from "../../../routes/routes";
import Select from "react-select";
import {
  BackupSetupTooltips,
  HookOptions,
  toastMessages,
} from "../../../utils/constants";
import Maybe from "graphql/tsutils/Maybe";
import { toast } from "react-toastify";
import SetupSettings from "./SetupSettings";
import ReactTooltip from "react-tooltip";

type RouteParams = {
  id: string;
};

const SetupDetails: React.FC = () => {
  const { id } = useParams<RouteParams>();
  const [backupSetup, setBackupSetup] = useState<Maybe<BackupSetup>>(null);
  const [backupSettings, setBackupSettings] = useState<{
    [key: string]: BackupSettingInput;
  }>({});
  const [setupChanged, setSetupChanged] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [setupId, setSetupId] = useState<string>("");
  const [resourceType, setResourceType] = useState<string>("");
  const [preHook, setPreHook] = useState<Maybe<HookInput>>();
  const [postHook, setPostHook] = useState<Maybe<HookInput>>();
  const [validationErrors, setValidationErrors] = useState<any>(null);
  const customer = useContext(CustomerContext);
  const location = useLocation();

  const customerId =
    customer && customer.customer && customer.customer.id
      ? customer.customer.id
      : "";
  const backupSetupId = id || location.pathname.split("/")[3];

  const { data, error, loading } = useGetBackupSetupQuery({
    variables: {
      searchCriteria: {
        customerId,
        backupSetupId: backupSetupId,
      },
    },
  });

  const { data: userRoleData, loading: userRoleLoading } = useGetUserRoleQuery({
    variables: {
      customerId: customerId,
    },
  });

  const { userRole } = userRoleData || {};

  useEffect(() => {
    setBackupSetup(data?.backupSetup);
    setPreHook(data?.backupSetup?.preHook);
    setPostHook(data?.backupSetup?.postHook);
    setName(data?.backupSetup?.name || "");
    setSetupId(data?.backupSetup?.id || "");
    setResourceType(data?.backupSetup?.resourceType || "");
    if (data?.backupSetup?.backupSettings !== null) {
      const newBackupSettings = data?.backupSetup?.backupSettings?.reduce(
        (settings: any, item: any) => {
          settings[item.id] = { value: item.value };
          return settings;
        },
        {}
      );
      setBackupSettings(newBackupSettings);
    } else {
      setBackupSettings({});
    }
  }, [data]);

  const removeTypename = (obj: any) => {
    const { __typename, ...rest } = obj;
    return rest;
  };

  const [updateBackupSetup] = useUpdateBackupSetupMutation({
    variables: {
      input: {
        customerId: customerId,
        name: name,
        id: setupId,
        preHook: preHook?.endpoint ? removeTypename(preHook) : null,
        postHook: postHook?.endpoint ? removeTypename(postHook) : null,
        backupSettings: backupSettings
          ? Object.keys(backupSettings).map((item: string) => ({
              id: item,
              value: backupSettings[item].value,
            }))
          : [],
      },
    },
  });

  let nameValidationError;

  return (
    <div className="main wide">
      <Loading loading={loading}>
        {error && <Error error={error} />}
        {backupSetup && (
          <div className="box">
            <div className="columns is-vcentered border-bottom">
              <div className="column is-7">
                <h1 className="title is-marginless is-5 is-capitalized">
                  Setup Details
                </h1>
              </div>
              <div className="column is-5">
                <Link to={ROUTES.settings.setups.index.generate()}>
                  <Button className="is-pulled-right">Back to Setups</Button>
                </Link>
                {!userRoleLoading && userRole !== UserRole.ReadOnly && (
                  <Button
                    className="is-pulled-right has-margin-right"
                    onClick={() => {
                      updateBackupSetup()
                        .then(() => {
                          toast.success(toastMessages.SETUP_UPDATED);
                          setValidationErrors(null);
                        })
                        .catch((e: any): any => {
                          toast.error(toastMessages.SETUP_UPDATE_ERROR);
                          setValidationErrors(e.graphQLErrors);
                        });
                    }}
                    disabled={!setupChanged}
                  >
                    Save
                  </Button>
                )}
              </div>
            </div>

            <div className="columns">
              <div className="column is-4-desktop">
                <div className="field">
                  <label className="label">Setup ID</label>
                  <Input value={backupSetup.id} type="text" disabled />
                </div>
                <div className="field">
                  <label className="label">
                    Setup name*
                    <span className="icon" data-tip={BackupSetupTooltips.name}>
                      <i className="fa fa-question-circle" />
                    </span>
                  </label>
                  <Input
                    defaultValue={name}
                    onChange={(e) => {
                      setName(e.target.value);
                      setSetupChanged(true);
                    }}
                    type="text"
                    disabled={userRole === UserRole.ReadOnly}
                  />
                  {validationErrors &&
                    (nameValidationError = validationErrors.find(
                      (e: any) => e.errorInfo === "name"
                    )) && (
                      <p className="validation-error">
                        {nameValidationError.message}
                      </p>
                    )}
                </div>

                <div className="field">
                  <label className="label">Resource Type</label>
                  <Input
                    defaultValue={backupSetup.resourceType}
                    type="text"
                    disabled
                  />
                </div>

                <div className="field">
                  <label className="label">
                    Prehook
                    <span className="icon" data-tip={BackupSetupTooltips.hooks}>
                      <i className="fa fa-question-circle" />
                    </span>
                  </label>
                  <div className="columns">
                    <div className="column is-5">
                      <label>Hook type</label>
                      {userRole === UserRole.ReadOnly ? (
                        <Input
                          defaultValue={backupSetup.preHook?.type}
                          type="text"
                          disabled
                        />
                      ) : (
                        <Select
                          defaultValue={HookOptions.find(
                            (o) => o.value === backupSetup.preHook?.type
                          )}
                          onChange={(e: any) => {
                            setPreHook({
                              endpoint: preHook?.endpoint || "",
                              type: e.value,
                            });
                            setSetupChanged(true);
                          }}
                          options={HookOptions}
                        />
                      )}
                    </div>
                    <div className="column is-7">
                      <label>Hook endpoint</label>
                      <Input
                        defaultValue={preHook?.endpoint}
                        onChange={(e: any) => {
                          setPreHook({
                            endpoint: e.target.value,
                            type: preHook?.type || HookType.LambdaArn,
                          });
                          setSetupChanged(true);
                        }}
                        type="text"
                        disabled={userRole === UserRole.ReadOnly}
                      />
                    </div>
                  </div>
                </div>

                <div className="field">
                  <label className="label">
                    Posthook
                    <span className="icon" data-tip={BackupSetupTooltips.hooks}>
                      <i className="fa fa-question-circle" />
                    </span>
                  </label>
                  <div className="columns">
                    <div className="column is-5">
                      <label>Hook type</label>
                      {userRole === UserRole.ReadOnly ? (
                        <Input
                          defaultValue={backupSetup.postHook?.type}
                          type="text"
                          disabled
                        />
                      ) : (
                        <Select
                          defaultValue={HookOptions.find(
                            (o) => o.value === backupSetup.postHook?.type
                          )}
                          onChange={(e: any) => {
                            setPostHook({
                              endpoint: postHook?.endpoint || "",
                              type: e.value,
                            });
                            setSetupChanged(true);
                          }}
                          options={HookOptions}
                        />
                      )}
                    </div>
                    <div className="column is-7">
                      <label>Hook endpoint</label>
                      <Input
                        defaultValue={postHook?.endpoint}
                        onChange={(e: any) => {
                          setPostHook({
                            endpoint: e.target.value,
                            type: postHook?.type || HookType.LambdaArn,
                          });
                          setSetupChanged(true);
                        }}
                        type="text"
                        disabled={userRole === UserRole.ReadOnly}
                      />
                    </div>
                  </div>
                </div>

                <div className="field">
                  {backupSettings && (
                    <SetupSettings
                      resourceType={resourceType}
                      customerId={customerId}
                      backupSettings={backupSettings}
                      settingsValidationErrors={validationErrors}
                      userRole={userRole}
                      userRoleLoading={userRoleLoading}
                      onChange={(settings) => {
                        setBackupSettings(settings);
                        setSetupChanged(true);
                      }}
                    />
                  )}
                </div>
                <ReactTooltip
                  effect="solid"
                  className="setup-tooltip"
                  multiline={true}
                />
              </div>
            </div>
          </div>
        )}
      </Loading>
    </div>
  );
};

export default SetupDetails;
