/**
 * Copyright 2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from "react";
import { PolicySettingsInput } from "../../generated/graphql";

type RemovePolicySettingsButtonProps = {
  settings: Array<PolicySettingsInput>;
  setter: React.Dispatch<React.SetStateAction<Array<PolicySettingsInput>>>;
  index: number;
};

const RemovePolicySettingsButton = ({
  settings,
  setter,
  index,
}: RemovePolicySettingsButtonProps) => (
  <a
    className="is-pulled-right"
    onClick={() => {
      setter(
        settings.filter(
          (setting: PolicySettingsInput) => setting !== settings[index]
        )
      );
    }}
  >
    Remove RPO
  </a>
);

export default RemovePolicySettingsButton;
