/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { useContext, useState } from "react";
import Pluralize from "react-pluralize";
import { CustomerContext } from "../../../context/customer";
import {
  Frequency,
  GetUserRoleQuery,
  PolicySettings,
  RetentionCondition,
  useGetBackupPoliciesQuery,
  useRemoveBackupPolicyMutation,
  UserRole,
} from "../../../generated/graphql";
import { Loading } from "../../../commonComponents/loading/Loading";
import Table from "../../../commonComponents/table/Table";
import { Error } from "../../../commonComponents/error/error";
import { confirmations, toastMessages } from "../../../utils/constants";
import { toast } from "react-toastify";
import Placeholder from "../../../commonComponents/table/Placeholder";
import Popup from "react-popup";
import errorToast from "../../../utils/errorToast";
import { History } from "history";
import { ROUTES } from "../../../routes/routes";
import { formatType } from "../../../utils/helpers";

interface Props {
  userRole: GetUserRoleQuery | any;
  userRoleLoading: boolean;
  history: History;
}

const PoliciesList: React.FC<Props> = ({
  userRole,
  history,
  userRoleLoading,
}) => {
  const { customer } = useContext(CustomerContext);
  const [removeId, setRemoveId] = useState("");

  const customerId = customer && customer.id ? customer.id : "";
  const { data, loading, refetch, error } = useGetBackupPoliciesQuery({
    variables: {
      customerId: customerId,
    },
  });

  interface IRetentionCondition {
    condition: RetentionCondition;
    threshold: number;
  }

  const formatRetentionCondition = (retention: IRetentionCondition) => {
    const condition = retention?.condition;
    const threshold = retention?.threshold;

    switch (condition) {
      case RetentionCondition.Quantity:
        return <Pluralize singular={"Snapshot"} count={threshold} />;
      case RetentionCondition.TimeInDays:
        return <Pluralize singular={"Day"} count={threshold} />;
      default:
        return <Pluralize singular={condition} count={threshold} />;
    }
  };

  const formatFrequency = (rpo: number, frequency: Frequency) => {
    switch (frequency) {
      case Frequency.Hours:
        return <Pluralize singular={"Hour"} count={rpo} />;
      case Frequency.Days:
        return <Pluralize singular={"Day"} count={rpo} />;
      case Frequency.Weeks:
        return <Pluralize singular={"Week"} count={rpo} />;
      case Frequency.Months:
        return <Pluralize singular={"Month"} count={rpo} />;
      default:
        return <Pluralize singular={frequency} count={rpo} />;
    }
  };

  const [removePolicy, { loading: removePolicyLoading }] =
    useRemoveBackupPolicyMutation({
      variables: {
        input: {
          customerId: customerId,
          policyId: removeId,
        },
      },
    });

  if (removeId) {
    removePolicy()
      .then(() => {
        refetch();
        toast.success(toastMessages.POLICY_REMOVED);
      })
      .catch((error) => errorToast(error, toastMessages.POLICY_REMOVAL_ERROR));
    setRemoveId("");
  }

  const onDeleteHandler = (id: string) => {
    Popup.create({
      onClose: () => {
        Popup.close();
      },
      title: `Delete Policy`,
      content: (
        <div className="box">
          <p>{confirmations.DELETE_POLICY}</p>
        </div>
      ),
      buttons: {
        right: [
          {
            text: "Cancel",
            className: "btn",
            action: () => {
              Popup.close();
            },
          },
          {
            text: "Delete",
            className: "btn",
            action: async () => {
              setRemoveId(id);
              Popup.close();
            },
          },
        ],
      },
    });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Type",
        Cell: ({ row }: any) => formatType(row.original.type),
      },
      {
        Header: "RPO/Cron",
        Cell: ({ row }: any) => (
          <>
            {row.original.policySettings.map((setting: PolicySettings) => (
              <div>{formatFrequency(setting.rpo, setting.frequency)}</div>
            ))}
            {row.original?.timeAwarePolicySettings?.cronExpression}
          </>
        ),
      },
      {
        Header: "Primary snapshot retention",
        Cell: ({ row }: any) => (
          <>
            {row.original.policySettings.map((setting: PolicySettings) => (
              <div>
                {formatRetentionCondition(
                  setting.retention.primarySnapshotsRetention
                )}
              </div>
            ))}
            {row.original?.timeAwarePolicySettings?.retention
              ?.primarySnapshotsRetention &&
              formatRetentionCondition(
                row.original.timeAwarePolicySettings.retention
                  .primarySnapshotsRetention
              )}
          </>
        ),
      },
      {
        Header: "Replica snapshot retention",
        Cell: ({ row }: any) => (
          <>
            {row.original.policySettings.map((setting: PolicySettings) => (
              <div>
                {formatRetentionCondition(
                  setting.retention.replicaSnapshotsRetention
                )}
              </div>
            ))}
            {row.original?.timeAwarePolicySettings?.retention
              ?.replicaSnapshotsRetention &&
              formatRetentionCondition(
                row.original.timeAwarePolicySettings.retention
                  .replicaSnapshotsRetention
              )}
          </>
        ),
      },
      {
        Header: " ",
        Cell: ({ row }: any) => (
          <div>
            <div className="navbar-item has-dropdown is-hoverable is-pulled-right">
              <span className="icon">
                <i className="fa fa-ellipsis-v" />
              </span>
              <div className="navbar-dropdown is-right">
                {userRole?.userRole !== UserRole.ReadOnly && (
                  <a
                    className="navbar-item"
                    onClick={() => onDeleteHandler(row.original.id)}
                    data-cy="delete-policy"
                  >
                    <span className="icon">
                      <i className="fa fa-trash" />
                    </span>
                    <span>Delete</span>
                  </a>
                )}
                <a
                  className="navbar-item"
                  onClick={() =>
                    history.push(
                      ROUTES.settings.policies.details.generate({
                        id: row.original.id,
                      })
                    )
                  }
                >
                  <span className="icon">
                    <i className="fa fa-eye" />
                  </span>
                  <span>View</span>
                </a>
              </div>
            </div>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <Loading loading={loading || userRoleLoading}>
      {error && <Error error={error} />}
      {data && data.backupPolicies.length > 0 ? (
        <>
          <Loading loading={removePolicyLoading} />
          <Table columns={columns} data={data.backupPolicies} disableSortBy />
        </>
      ) : (
        <Placeholder message="Currently there are no backup policies. Create one now." />
      )}
    </Loading>
  );
};

export default PoliciesList;
