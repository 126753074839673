/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { ReactNode } from "react";

interface Props {
  children?: ReactNode;
  onHide: () => void;
  title: string;
}

export const Modal: React.FC<Props> = ({ children, onHide, title }) => {
  return (
    <div id="modal" className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">{title}</p>
          <div
            className="modal-close-btn"
            onClick={(e) => {
              e.stopPropagation();
              onHide();
            }}
          >
            <i className="fa fa-times"></i>
          </div>
        </header>
        <section className="modal-card-body">{children}</section>
      </div>
    </div>
  );
};
