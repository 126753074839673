/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  CustomerAccessesPage,
  Maybe,
  CustomerAccess,
} from "../../generated/graphql";
import Pagination from "../../commonComponents/pagination/Pagination";
import Table from "../../commonComponents/table/Table";
import Placeholder from "../../commonComponents/table/Placeholder";
import { History } from "history";
import CustomersFilter from "./CustomersFilter";
import { Loading } from "../../commonComponents/loading/Loading";
import CustomersRoles from "./CustomersRoles";

interface CustomersTableProps {
  customersData?: CustomerAccessesPage;
  history: History;
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  isModalOpen: boolean;
  setFilters: Dispatch<SetStateAction<string>>;
  filters: string;
  loading: boolean;
  setCustomerData: React.Dispatch<
    React.SetStateAction<CustomerAccess | undefined>
  >;
  customersList: any;
  customersLoading: boolean;
  property: "accessibleCustomers" | "customersList";
}

const PAGE_SIZE = 50;

const CustomersTable: React.FC<CustomersTableProps> = ({
  customersData,
  history,
  currentPage,
  setCurrentPage,
  setIsModalOpen,
  setFilters,
  filters,
  loading,
  setCustomerData,
  customersList,
  customersLoading,
  property,
}) => {
  const [customers, setCustomers] = useState<Array<Maybe<CustomerAccess>>>();

  const [totalNumberOfCustomers, setTotalNumberOfCustomers] = useState<
    number | undefined
  >(0);
  const [totalNumberOfPages, setTotalNumberOfPages] = useState<
    number | undefined
  >(0);

  const nextPage = () => {
    if (totalNumberOfPages && currentPage < totalNumberOfPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const getCustomersAccessDataNumber = () => {
    if (customers && customers.length < PAGE_SIZE && currentPage === 1) {
      return customers.length;
    }
    if (
      customers &&
      customers?.length < PAGE_SIZE &&
      currentPage === totalNumberOfPages
    ) {
      return PAGE_SIZE * (totalNumberOfPages - 1) + customers.length;
    }
    return PAGE_SIZE * currentPage;
  };

  useEffect(() => {
    setTotalNumberOfCustomers(customersData?.totalNumberOfCustomers);
    setTotalNumberOfPages(customersData?.totalNumberOfPages);
    setCustomers(customersData?.data);
  }, [customersData]);

  const columns = useMemo(
    () => [
      {
        Header: "Customer",
        accessor: "customer",
        Cell: ({ row }: any) => {
          const { customer } = row.original;
          return customer ? (
            <div style={{ minWidth: "10rem" }}>
              <span>{customer.name}</span>
            </div>
          ) : (
            <span>User</span>
          );
        },
      },
      {
        Header: "Roles",
        accessor: "roles",
        Cell: ({ row }: any) => {
          const { roles } = row.original;
          return roles ? (
            <div style={{ minWidth: "20rem" }}>
              <CustomersRoles roles={roles} />
            </div>
          ) : (
            <span>Roles</span>
          );
        },
      },
      {
        id: "actions",
        Cell: ({ row }: any) => {
          const { customer, roles } = row.original;

          return (
            <div>
              <div
                className="navbar-item is-hoverable is-pulled-right"
                onClick={(e) => {
                  e.preventDefault();
                  setIsModalOpen(true);
                  setCustomerData(() => ({ customer, roles }));
                }}
              >
                <span className="icon">
                  <i className="fa fa-pencil" />
                </span>
              </div>
            </div>
          );
        },
      },
    ],
    [history]
  );

  return (
    <>
      <div className="columns is-vcentered border-bottom">
        <div className="column is-7">
          <h1 className="title is-marginless is-5 is-capitalized">
            Customers accesses
          </h1>
        </div>
      </div>
      <div className="columns is-vcentered">
        <div className="column">
          <br />
          <CustomersFilter
            setFilters={setFilters}
            filters={filters}
            list={customersList}
            loading={customersLoading}
            property={property}
          />
          <Loading loading={loading}>
            {customers && customers.length > 0 ? (
              <>
                <Table
                  columns={columns}
                  // @ts-ignore
                  data={customers}
                  disableSortBy
                />
              </>
            ) : (
              <Placeholder message="No data found with current filters." />
            )}
            <Pagination
              totalItems={totalNumberOfCustomers || 0}
              totalPages={totalNumberOfPages || 0}
              currentPage={currentPage}
              showing={getCustomersAccessDataNumber()}
              changePage={setCurrentPage}
              previousPage={previousPage}
              nextPage={nextPage}
            />
          </Loading>
        </div>
      </div>
    </>
  );
};

export default CustomersTable;
