/**
 * Copyright 2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from "react";
import Button from "../../../commonComponents/button/Button";
import Segment from "../../../commonComponents/segment/Segment";
import {
  GetBestAllowedPolicySettingsQuery,
  RetentionCondition,
  TimeAwarePolicySettingsInput,
} from "../../../generated/graphql";
import { CONDITIONS } from "./policiesConstants";
import Pluralize from "react-pluralize";
import Input from "../../../commonComponents/input/Input";
import ValidationMessage from "../../../commonComponents/validationMessage/ValidationMessage";
import SimpleReactValidator from "simple-react-validator";
import moment from "moment";
import DatePicker from "react-datepicker";
import GraphqlError, {
  GraphQLErrorCustom,
} from "../../../commonComponents/graphqlError/GraphqlError";
import { BackupPolicyTooltips } from "../../../utils/constants";
import ReactTooltip from "react-tooltip";

type PolicyTimeAwareFormProps = {
  timeAwarePolicySettings: TimeAwarePolicySettingsInput;
  setTimeAwarePolicySettings: React.Dispatch<
    React.SetStateAction<TimeAwarePolicySettingsInput>
  >;
  data: GetBestAllowedPolicySettingsQuery;
  validator: SimpleReactValidator;
  graphqlValidationErrors: Array<GraphQLErrorCustom>;
  setRemovePrimarySnapsAfterResourceDeletion: React.Dispatch<boolean>;
  setRemoveReplicaSnapsAfterResourceDeletion: React.Dispatch<boolean>;
};

const PolicyTimeAwareForm = ({
  timeAwarePolicySettings,
  setTimeAwarePolicySettings,
  data,
  validator,
  graphqlValidationErrors,
  setRemovePrimarySnapsAfterResourceDeletion,
  setRemoveReplicaSnapsAfterResourceDeletion,
}: PolicyTimeAwareFormProps) => (
  <div className="columns">
    <div className="column">
      <div className="field">
        <label className="label">
          Cron expression*
          <span className="icon" data-tip={BackupPolicyTooltips.cronExpression}>
            <i className="fa fa-question-circle" />
          </span>
        </label>
        <Input
          value={timeAwarePolicySettings.cronExpression}
          onChange={(e) => {
            const newTimeAwareSettings = {
              ...timeAwarePolicySettings,
            };
            newTimeAwareSettings.cronExpression = e.target.value;
            setTimeAwarePolicySettings(newTimeAwareSettings);
          }}
          placeholder={"0 0 * * *"}
          data-cy={`cron-expression`}
        />
        <ValidationMessage>
          {validator.message(
            "cronExpression",
            timeAwarePolicySettings.cronExpression,
            "required|regex"
          )}
        </ValidationMessage>
        <GraphqlError
          errorInfo="policy.TimeAwarePolicySettings.CronExpression"
          graphqlValidationErrors={graphqlValidationErrors}
        />
      </div>
      <div className="field">
        <label className="label">
          Backup time window duration in hours*
          <span
            className="icon"
            data-tip={BackupPolicyTooltips.backupTimeWindowDurationInHours}
          >
            <i className="fa fa-question-circle" />
          </span>
        </label>
        <Input
          value={timeAwarePolicySettings.timeWindowDuration}
          onChange={(e) => {
            const newTimeAwareSettings = {
              ...timeAwarePolicySettings,
            };
            newTimeAwareSettings.timeWindowDuration = e.target.value;
            setTimeAwarePolicySettings(newTimeAwareSettings);
          }}
          type="number"
          data-cy={`time-window-duration`}
        />
        <ValidationMessage>
          {validator.message(
            "timeWindowDuration",
            timeAwarePolicySettings.timeWindowDuration,
            "required|min:1|max:24"
          )}
        </ValidationMessage>
        <GraphqlError
          errorInfo="policy.TimeAwarePolicySettings.TimeWindowDuration"
          graphqlValidationErrors={graphqlValidationErrors}
        />
      </div>
      <div className="field">
        <label className="label">
          Start date
          <span className="icon" data-tip={BackupPolicyTooltips.startDate}>
            <i className="fa fa-question-circle" />
          </span>
        </label>
        <DatePicker
          minDate={moment(new Date()).add(1, "days").toDate()}
          isClearable
          className="input border"
          dateFormat={"dd-MM-yyyy"}
          selected={
            timeAwarePolicySettings?.startDateTimestamp !== undefined
              ? new Date(timeAwarePolicySettings?.startDateTimestamp! * 1000)
              : null
          }
          onChange={(e) => {
            const newTimeAwareSettings = {
              ...timeAwarePolicySettings,
            };
            newTimeAwareSettings.startDateTimestamp = undefined;
            if (e) {
              newTimeAwareSettings.startDateTimestamp = moment(e)
                .add(moment().utcOffset(), "minutes")
                .unix();
            }
            setTimeAwarePolicySettings(newTimeAwareSettings);
          }}
          placeholderText="Select Date"
          data-cy={`time-window-duration`}
        />
        <ValidationMessage>
          {validator.message(
            "timeWindowDuration",
            timeAwarePolicySettings.startDateTimestamp,
            "numeric|min:1,num"
          )}
        </ValidationMessage>
        <GraphqlError
          errorInfo="policy.TimeAwarePolicySettings.StartDate"
          graphqlValidationErrors={graphqlValidationErrors}
        />
      </div>
    </div>
    <div className="column">
      <div className="field">
        <label className="label">
          Primary snapshot condition*
          <span
            className="icon"
            data-tip={BackupPolicyTooltips.primarySnapshotCondition}
          >
            <i className="fa fa-question-circle" />
          </span>
        </label>
        <Segment tabs>
          {CONDITIONS.map((button) => (
            <Button
              key={`primary-condition-${button.value}`}
              onClick={() => {
                const newPolicyTimeAwareSettings = {
                  ...timeAwarePolicySettings,
                };
                newPolicyTimeAwareSettings.retention.primarySnapshotsRetention.condition =
                  button.value;

                setTimeAwarePolicySettings(newPolicyTimeAwareSettings);
                if (button.value === RetentionCondition.Quantity) {
                  setRemovePrimarySnapsAfterResourceDeletion(false);
                }
              }}
              className={
                timeAwarePolicySettings.retention.primarySnapshotsRetention
                  .condition === button.value
                  ? "active"
                  : ""
              }
              select
              data-cy={`primary-condition-${button.value}`}
            >
              {button.label}
            </Button>
          ))}
        </Segment>
        <ValidationMessage>
          {validator.message(
            "primary snapshot condition",
            timeAwarePolicySettings.retention.primarySnapshotsRetention
              .condition,
            "required"
          )}
        </ValidationMessage>
        <GraphqlError
          errorInfo="policy.timeAwarePolicySettings.primarySnapshotRetentionCondition"
          graphqlValidationErrors={graphqlValidationErrors}
        />
      </div>
      <div className="field">
        <label className="label">
          Primary snapshot threshold (max.{" "}
          {timeAwarePolicySettings.retention.primarySnapshotsRetention
            .condition === RetentionCondition.Quantity ? (
            <Pluralize
              singular={"snapshot"}
              count={
                data?.bestAllowedPolicySettings
                  ?.maxPrimarySnapshotRetentionQuantity
              }
            />
          ) : (
            <Pluralize
              singular={"day"}
              count={
                data?.bestAllowedPolicySettings
                  ?.maxPrimarySnapshotRetentionInDays
              }
            />
          )}
          )*
          <span
            className="icon"
            data-tip={BackupPolicyTooltips.primarySnapshotThreshold}
          >
            <i className="fa fa-question-circle" />
          </span>
        </label>
        <Input
          value={
            timeAwarePolicySettings.retention.primarySnapshotsRetention
              .threshold
          }
          onChange={(e) => {
            const newPolicyTimeAwareSettings = {
              ...timeAwarePolicySettings,
            };
            newPolicyTimeAwareSettings.retention.primarySnapshotsRetention.threshold =
              e.target.value;

            setTimeAwarePolicySettings(newPolicyTimeAwareSettings);
          }}
          type="number"
          data-cy={`primary-threshold`}
        />
        <ValidationMessage>
          {validator.message(
            "primarySnapshotRetentionThreshold",
            timeAwarePolicySettings.retention.primarySnapshotsRetention
              .threshold,
            "required|numeric|min:0,num"
          )}
        </ValidationMessage>
        <GraphqlError
          errorInfo="policy.timeAwarePolicySettings.primarySnapshotRetentionThreshold"
          graphqlValidationErrors={graphqlValidationErrors}
        />
      </div>
    </div>
    <div className="column">
      <div className="field">
        <label className="label">
          Replica snapshot condition*
          <span
            className="icon"
            data-tip={BackupPolicyTooltips.replicaSnapshotCondition}
          >
            <i className="fa fa-question-circle" />
          </span>
        </label>
        <Segment tabs>
          {CONDITIONS.map((button) => (
            <Button
              key={`replica-condition-${button.value}`}
              onClick={() => {
                const newPolicyTimeAwareSettings = {
                  ...timeAwarePolicySettings,
                };
                newPolicyTimeAwareSettings.retention.replicaSnapshotsRetention.condition =
                  button.value;

                setTimeAwarePolicySettings(newPolicyTimeAwareSettings);
                if (button.value === RetentionCondition.Quantity) {
                  setRemoveReplicaSnapsAfterResourceDeletion(false);
                }
              }}
              className={
                timeAwarePolicySettings.retention.replicaSnapshotsRetention
                  .condition === button.value
                  ? "active"
                  : ""
              }
              select
              data-cy={`replica-condition-${button.value}`}
            >
              {button.label}
            </Button>
          ))}
        </Segment>
        <ValidationMessage>
          {validator.message(
            "replica snapshot condition",
            timeAwarePolicySettings.retention.replicaSnapshotsRetention
              .condition,
            "required"
          )}
        </ValidationMessage>
        <GraphqlError
          errorInfo="policy.timeAwarePolicySettings.replicaSnapshotRetentionCondition"
          graphqlValidationErrors={graphqlValidationErrors}
        />
      </div>
      <div className="field">
        <label className="label">
          Replica snapshot threshold (max.{" "}
          {timeAwarePolicySettings.retention.replicaSnapshotsRetention
            .condition === RetentionCondition.Quantity ? (
            <Pluralize
              singular={"snapshot"}
              count={
                data?.bestAllowedPolicySettings
                  ?.maxReplicaSnapshotRetentionQuantity
              }
            />
          ) : (
            <Pluralize
              singular={"day"}
              count={
                data?.bestAllowedPolicySettings
                  ?.maxReplicaSnapshotRetentionInDays
              }
            />
          )}
          )*
          <span
            className="icon"
            data-tip={BackupPolicyTooltips.replicaSnapshotThreshold}
          >
            <i className="fa fa-question-circle" />
          </span>
        </label>
        <Input
          value={
            timeAwarePolicySettings.retention.replicaSnapshotsRetention
              .threshold
          }
          onChange={(e) => {
            const newPolicyTimeAwareSettings = {
              ...timeAwarePolicySettings,
            };
            newPolicyTimeAwareSettings.retention.replicaSnapshotsRetention.threshold =
              e.target.value;
            setTimeAwarePolicySettings(newPolicyTimeAwareSettings);
          }}
          type="number"
          data-cy={`replica-threshold`}
        />
        <ValidationMessage>
          {validator.message(
            "replicaSnapshotRetentionThreshold",
            timeAwarePolicySettings.retention.replicaSnapshotsRetention
              .threshold,
            "required|numeric|min:0,num"
          )}
        </ValidationMessage>
        <GraphqlError
          errorInfo="policy.timeAwarePolicySettings.replicaSnapshotRetentionThreshold"
          graphqlValidationErrors={graphqlValidationErrors}
        />
      </div>
    </div>
    <ReactTooltip effect="solid" className="setup-tooltip" multiline={true} />
  </div>
);

export default PolicyTimeAwareForm;
