/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { ReactNode } from "react";
import { Link, useLocation } from "react-router-dom";
import Popup from "react-popup";
import { ROUTES } from "../../../routes/routes";

interface Props {
  children: ReactNode;
}

export const ResourcesPageLayout: React.FC<Props> = ({ children }) => {
  const location = useLocation();
  const path = location.pathname;

  return (
    <div className="main">
      <div className="container is-fluid">
        <div className="box">
          <div className="tabs is-toggle">
            <ul>
              <li>
                <Link
                  className={`${
                    path === ROUTES.resources.index.generate() && "active"
                  }`}
                  to={ROUTES.resources.index.generate()}
                >
                  Autobackup protected
                </Link>
              </li>
              <li>
                <Link
                  className={`${
                    path === ROUTES.resources.external.generate() && "active"
                  }`}
                  to={ROUTES.resources.external.generate()}
                >
                  Azure Native VMs
                </Link>
              </li>
              <li>
                <Link
                  className={`${
                    path === ROUTES.resources.removed.generate() && "active"
                  }`}
                  to={ROUTES.resources.removed.generate()}
                >
                  Removed resources
                </Link>
              </li>
            </ul>
          </div>
        </div>
        {children}
        <Popup />
      </div>
    </div>
  );
};
