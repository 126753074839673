/**
 * Copyright 2021-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { Dispatch, SetStateAction, useState } from "react";
import { CustomerAccessesPage, UserRole } from "../../generated/graphql";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import { toastMessages } from "../../utils/constants";
import { Loading } from "../../commonComponents/loading/Loading";
import Select from "react-select";
import { Modal } from "../../commonComponents/modal/Modal";
import ValidationMessage from "../../commonComponents/validationMessage/ValidationMessage";
import Button from "../../commonComponents/button/Button";
import { ObservableQuery } from "apollo-client/core/ObservableQuery";

interface CustomersGrantRoleModalProps {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  customersData?: CustomerAccessesPage["data"];
  type: "id" | "email";
  identifier: string;
  mutation: any;
  customersList: any;
  customersLoading: boolean;
  property: "accessibleCustomers" | "customersList";
  refetch: ObservableQuery["refetch"];
}

const validator = new SimpleReactValidator();

const CustomersGrantRoleModal = ({
  setIsOpen,
  identifier,
  customersData,
  mutation,
  type,
  customersList,
  customersLoading,
  property,
  refetch,
}: CustomersGrantRoleModalProps) => {
  const compare = customersData?.map((e) => e?.customer.id);
  const [validateError, setValidateError] = useState(false);
  const [settingsValidationErrors, setSettingsValidationErrors] =
    useState<any>(null);
  let validationError;
  const [loading, setLoading] = useState(false);

  const [customers, setCustomers] = useState<Array<string>>([]);
  const [roles, setRoles] = useState<Array<UserRole>>([]);

  const rolesEnums = [
    { label: "admin", value: "ADMIN" },
    { label: "read only", value: "READ_ONLY" },
  ];

  const [grantCustomerRole] = mutation();

  const finishGrantingRole = async () => {
    validateError && setValidateError(false);
    if (validator.allValid()) {
      setLoading(true);
      const actions = customers
        .map((customer) =>
          roles.map((role) =>
            grantCustomerRole({
              variables: {
                input: {
                  customerId: customer,
                  role: role,
                  [type]: identifier,
                },
              },
            })
          )
        )
        .flat();
      try {
        await Promise.all(actions);
        toast.success(toastMessages.GRANT_CUSTOMER_ROLE);
        await refetch();
      } catch (e) {
        toast.error(toastMessages.GRANT_CUSTOMER_ROLE_ERROR);
        setSettingsValidationErrors(e.graphQLErrors);
      } finally {
        setLoading(false);
        setIsOpen(false);
      }
    } else {
      validator.showMessages();
      setValidateError(true);
    }
  };

  return (
    <Modal onHide={() => setIsOpen(false)} title="Grant customers accesses">
      <div style={{ minHeight: "20rem", position: "relative" }}>
        <div className="field">
          <label className="label">Customers*</label>
          <Loading loading={customersLoading}>
            {customersList && customersList[property] && (
              <Select
                isMulti
                isClearable={false}
                onChange={(customersVal: any) => {
                  Array.isArray(customersVal)
                    ? setCustomers(customersVal.map(({ value }: any) => value))
                    : setCustomers([]);
                }}
                options={customersList[property]
                  .filter((e: any) => !compare?.includes(e.id))
                  .map((t: any) => ({
                    value: t.id,
                    label: t.name,
                  }))}
              />
            )}
          </Loading>
          {settingsValidationErrors &&
            (validationError = settingsValidationErrors?.find(
              (e: any) => e.errorInfo === "customers"
            )) && <p className="validation-error">{validationError.message}</p>}
          <ValidationMessage>
            {validator.message("customers", customers, "required")}
          </ValidationMessage>
        </div>
        <div className="field">
          <label className="label">Roles*</label>
          <Select
            isMulti
            isClearable={false}
            onChange={(el: any) => {
              Array.isArray(el)
                ? setRoles(el.map(({ value }: any) => value))
                : setRoles([]);
            }}
            options={rolesEnums.map((role) => ({
              value: role.value,
              label: role.label,
            }))}
          />
          {settingsValidationErrors &&
            (validationError = settingsValidationErrors?.find(
              (e: any) => e.errorInfo === "roles"
            )) && <p className="validation-error">{validationError.message}</p>}
          <ValidationMessage>
            {validator.message("roles", roles, "required")}
          </ValidationMessage>
          <Loading loading={loading}>
            <div style={{ position: "absolute", left: 0, bottom: 0 }}>
              <Button onClick={finishGrantingRole}>Save</Button>
            </div>
          </Loading>
        </div>
      </div>
    </Modal>
  );
};

export default CustomersGrantRoleModal;
