/**
 * Copyright 2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from "react";
import {
  Frequency,
  PolicySettingsInput,
  RetentionCondition,
} from "../../generated/graphql";
import Button from "../button/Button";

type AddPolicySettingsButtonProps = {
  settings: Array<PolicySettingsInput>;
  setter: React.Dispatch<React.SetStateAction<Array<PolicySettingsInput>>>;
};

const AddPolicySettingsButton = ({
  settings,
  setter,
}: AddPolicySettingsButtonProps) => {
  const setSettings = () =>
    setter([
      ...settings,
      {
        frequency: Frequency.Hours,
        retention: {
          primarySnapshotsRetention: {
            condition: RetentionCondition.TimeInDays,
          },
          replicaSnapshotsRetention: {
            condition: RetentionCondition.TimeInDays,
          },
        },
      } as PolicySettingsInput,
    ]);

  const isDisabled = () => {
    const settingsLen = settings.length;
    if (settingsLen <= 0) return true;

    const setting = settings[settingsLen - 1];

    return (
      setting.rpo === undefined ||
      setting.retention.primarySnapshotsRetention.threshold === undefined ||
      setting.retention.replicaSnapshotsRetention.threshold === undefined
    );
  };

  return (
    <Button
      data-cy="add-another-rpo"
      disabled={isDisabled()}
      onClick={setSettings}
    >
      Add another RPO
    </Button>
  );
};

export default AddPolicySettingsButton;
