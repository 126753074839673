/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { useContext } from "react";
import { CustomerContext } from "../../../context/customer";
import { useGetBackupPoliciesQuery } from "../../../generated/graphql";
import { Loading } from "../../../commonComponents/loading/Loading";
import Select from "react-select";
import { Error } from "../../../commonComponents/error/error";

interface Props {
  onChange: (policy: any) => void;
  value: string;
}

const PolicySelect: React.FC<Props> = ({ onChange, value }) => {
  const { customer } = useContext(CustomerContext);
  const { data, loading, error } = useGetBackupPoliciesQuery({
    variables: {
      customerId: customer && customer.id ? customer.id : "",
    },
  });
  return (
    <Loading loading={loading}>
      {error && <Error error={error} />}
      {!error && data && data.backupPolicies.length ? (
        <Select
          maxMenuHeight={90}
          isClearable={false}
          defaultInputValue={value}
          onChange={(policy: any) => onChange(policy)}
          options={data.backupPolicies.map((policy) => ({
            value: policy.id,
            label: policy.name,
          }))}
        />
      ) : (
        <p>There are not policies available.</p>
      )}
    </Loading>
  );
};

export default PolicySelect;
