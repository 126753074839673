/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { useContext, useState } from "react";
import Button from "../../../commonComponents/button/Button";
import ValidationMessage from "../../../commonComponents/validationMessage/ValidationMessage";
import Segment from "../../../commonComponents/segment/Segment";
import InlineForm from "../../../commonComponents/inlineForm/InlineForm";
import Input from "../../../commonComponents/input/Input";
import SimpleReactValidator from "simple-react-validator";
import { History } from "history";
import { Loading } from "../../../commonComponents/loading/Loading";
import { ROUTES } from "../../../routes/routes";
import {
  useCreateNotificationGroupMutation,
  NotificationChannelType,
  useGetAccountsQuery,
  Maybe,
} from "../../../generated/graphql";
import { toast } from "react-toastify";
import {
  NotificationGroupTooltips,
  toastMessages,
} from "../../../utils/constants";
import { CustomerContext } from "../../../context/customer";
import { Error } from "../../../commonComponents/error/error";
import { tabsButtons, typeButtons } from "./notificationsGroupsConstants";
import ReactTooltip from "react-tooltip";
import Select from "react-select";

interface Props {
  onClick?: () => void;
  color?: string;
  history: History;
}

const validator = new SimpleReactValidator();

const CreateNotificationGroup: React.FC<Props> = ({ history }) => {
  const { customer } = useContext(CustomerContext);
  const customerId = customer && customer.id ? customer.id : "";
  const [validationError, setValidationError] = useState(false);
  const [name, setName] = useState("");
  const [severities, setSeverities] = useState<Array<any>>([]);
  const [frequency, setFrequency] = useState<any>();
  const [email, setEmail] = useState({
    channelType: NotificationChannelType.Email,
    email: {
      address: "",
    },
  });
  const [slack, setSlack] = useState({
    channelType: NotificationChannelType.Slack,
    slack: {
      channelName: "",
      webHook: "",
    },
  });
  const [pagerDuty, setPagerDuty] = useState({
    channelType: NotificationChannelType.Pagerduty,
    pagerDuty: {
      routingKey: "",
    },
  });
  const [excludedAccounts, setExcludedAccounts] =
    useState<Maybe<Array<string>>>(null);

  let notificationGroupChannels: any = [];
  if (email.email.address !== "")
    notificationGroupChannels = [...notificationGroupChannels, email];
  if (slack.slack.channelName !== "" || slack.slack.webHook !== "")
    notificationGroupChannels = [...notificationGroupChannels, slack];
  if (pagerDuty.pagerDuty.routingKey !== "")
    notificationGroupChannels = [...notificationGroupChannels, pagerDuty];

  const [createNotificationGroup, { loading, error }] =
    useCreateNotificationGroupMutation({
      variables: {
        input: {
          customerId: customerId,
          name: name,
          severities: severities,
          frequency: frequency,
          channels: notificationGroupChannels,
          excludedAccounts: excludedAccounts,
        },
      },
    });

  const {
    data: accountsData,
    error: accountsError,
    loading: accountsLoading,
  } = useGetAccountsQuery({
    variables: {
      searchCriteria: {
        customerId: customerId,
      },
    },
  });

  const toggleSeverities = (type: string): void => {
    severities.includes(type)
      ? setSeverities(severities.filter((e) => e !== type))
      : setSeverities([...severities, type]);
  };

  const setActive = (type: string): string => {
    return severities.includes(type) ? "active" : "";
  };

  return (
    <div className="box">
      {error && <Error error={error} />}
      {accountsError && <Error error={accountsError} />}
      <div className="columns is-vcentered border-bottom">
        <div className="column is-7">
          <h1 className="title is-marginless is-5 is-capitalized">
            Create new notification group
          </h1>
        </div>
        <div className="column is-5">
          <Segment alignRight>
            <Button
              className="margin-right"
              outline
              onClick={() => {
                validator.hideMessages();
                history.push(
                  ROUTES.settings.notificationGroups.index.generate()
                );
              }}
            >
              Cancel
            </Button>
            <Button
              className="margin-right"
              onClick={() => {
                validationError && setValidationError(false);
                if (validator.allValid()) {
                  createNotificationGroup()
                    .then(() => {
                      toast.success(toastMessages.NOTIFICATION_CREATED);
                      validator.hideMessages();
                      history.push(
                        ROUTES.settings.notificationGroups.index.generate()
                      );
                    })
                    .catch((e: any): any =>
                      toast.error(toastMessages.NOTIFICATION_CREATION_ERROR)
                    );
                } else {
                  setValidationError(true);
                  validator.showMessages();
                  toast.error(toastMessages.VALIDATION_FAILED);
                }
              }}
            >
              Save
            </Button>
          </Segment>
        </div>
      </div>
      {<Loading loading={loading} />}
      <div className="columns">
        <div className="column is-4-desktop">
          <div className="field">
            <label className="label">
              Notification group name*
              <span className="icon" data-tip={NotificationGroupTooltips.name}>
                <i className="fa fa-question-circle" />
              </span>
            </label>
            <Input
              value={name}
              onChange={(e) => setName(e.target.value)}
              type="text"
            />
            <ValidationMessage>
              {validator.message("notificationGroupName", name, "required")}
            </ValidationMessage>
          </div>
          <div className="field">
            <label className="label">
              Notification type*
              <span className="icon" data-tip={NotificationGroupTooltips.type}>
                <i className="fa fa-question-circle" />
              </span>
            </label>
            <Segment>
              {typeButtons.map((button) => (
                <Button
                  key={button.type}
                  onClick={() => toggleSeverities(button.type)}
                  className={setActive(button.type)}
                  color={button.activeColor}
                  select
                >
                  {button.label}
                </Button>
              ))}
            </Segment>
            <ValidationMessage>
              {validator.message(
                "notification type",
                severities[0],
                "required"
              )}
            </ValidationMessage>
          </div>
          <div className="field">
            <label className="label">
              Notification frequency*
              <span
                className="icon"
                data-tip={NotificationGroupTooltips.frequency}
              >
                <i className="fa fa-question-circle" />
              </span>
            </label>
            <Segment tabs>
              {tabsButtons.map((button) => (
                <Button
                  key={button.value}
                  onClick={() => setFrequency(button.value)}
                  className={frequency === button.value ? "active" : ""}
                  select
                >
                  {button.label}
                </Button>
              ))}
            </Segment>
            <ValidationMessage>
              {validator.message(
                "notification frequency",
                frequency,
                "required"
              )}
            </ValidationMessage>
          </div>
          <div className="field">
            <label className="label">
              Notification channels*
              <span
                className="icon"
                data-tip={NotificationGroupTooltips.channels}
              >
                <i className="fa fa-question-circle" />
              </span>
            </label>
            <InlineForm>
              <label>Email</label>
              <Input
                onChange={(e) =>
                  setEmail({ ...email, email: { address: e.target.value } })
                }
                onBlur={() => validator.showMessageFor("email")}
                type="text"
                placeholder="Email address"
              />
            </InlineForm>
            <ValidationMessage>
              {validator.message("email", email.email.address, "email")}
            </ValidationMessage>
            <InlineForm>
              <label>Slack</label>
              <Input
                onChange={(e) =>
                  setSlack({
                    ...slack,
                    slack: { ...slack.slack, channelName: e.target.value },
                  })
                }
                type="text"
                placeholder="Channel name"
              />
              <Input
                onChange={(e) =>
                  setSlack({
                    ...slack,
                    slack: { ...slack.slack, webHook: e.target.value },
                  })
                }
                type="text"
                placeholder="Webhook url"
              />
            </InlineForm>
            <InlineForm>
              <label>PagerDuty</label>
              <Input
                onChange={(e) =>
                  setPagerDuty({
                    ...pagerDuty,
                    pagerDuty: { routingKey: e.target.value },
                  })
                }
                type="text"
                placeholder="Messages routing key"
              />
            </InlineForm>
          </div>
          <div className="field">
            <label className="label">
              Excluded Accounts
              <span
                className="icon"
                data-tip={NotificationGroupTooltips.excludedAccounts}
              >
                <i className="fa fa-question-circle" />
              </span>
            </label>
            <Loading loading={accountsLoading}>
              <Select
                isMulti
                isClearable={false}
                value={excludedAccounts?.map((accountId) => ({
                  value: accountId,
                  label: accountsData?.accounts.data.find(
                    (account) => account.id === accountId
                  )?.name,
                }))}
                onChange={(el: any) => {
                  Array.isArray(el)
                    ? setExcludedAccounts(el.map(({ value }: any) => value))
                    : setExcludedAccounts(null);
                }}
                options={accountsData?.accounts.data.map((account) => ({
                  value: account.id,
                  label: `${account.name} (${account.cloudProviderId})`,
                }))}
              />
            </Loading>
          </div>
          <ReactTooltip
            effect="solid"
            className="setup-tooltip"
            multiline={true}
          />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <small>* - required</small>
        </div>
      </div>
    </div>
  );
};

export default CreateNotificationGroup;
