/**
 * Copyright 2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { Dispatch, SetStateAction, useState } from "react";
import { Modal } from "../../commonComponents/modal/Modal";
import SimpleReactValidator from "simple-react-validator";
import Select from "react-select";
import Button from "../../commonComponents/button/Button";
import { UserRole } from "../../generated/graphql";
import { toast } from "react-toastify";
import { toastMessages } from "../../utils/constants";
import { Loading } from "../../commonComponents/loading/Loading";
import { ObservableQuery } from "apollo-client/core/ObservableQuery";
import Input from "../../commonComponents/input/Input";
import { GraphQLError } from "graphql/error/GraphQLError";

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

interface AddNewAccessModalProps {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  customerId: string;
  type: "id" | "email";
  mutationGrant: any;
  refetch: ObservableQuery["refetch"];
}

export const rolesEnums = [
  { label: "admin", value: UserRole.Admin },
  { label: "read only", value: UserRole.ReadOnly },
];

const validator = new SimpleReactValidator();

const AddNewAccessModal = ({
  setIsOpen,
  customerId,
  type,
  mutationGrant,
  refetch,
}: AddNewAccessModalProps) => {
  const [rolesValidationError, setRolesValidationError] =
    useState<GraphQLError>();
  const [typeValidationError, setTypeValidationError] =
    useState<GraphQLError>();
  const [loading, setLoading] = useState(false);

  const [identifier, setIdentifier] = useState<string>("");
  const [roles, setRoles] = useState<Array<UserRole>>([]);

  const [grantRole] = mutationGrant();

  const addingCustomerRolesHandler = async () => {
    if (validator.allValid()) {
      setLoading(true);
      try {
        await Promise.all(
          roles.map(async (el) => {
            return grantRole({
              variables: {
                input: {
                  customerId: customerId,
                  role: el,
                  [type]: identifier,
                },
              },
            });
          })
        );
        toast.success(toastMessages.GRANT_CUSTOMER_ROLE);
        setIsOpen(false);
        await refetch();
      } catch (e) {
        toast.error(toastMessages.GRANT_CUSTOMER_ROLE_ERROR);
        setTypeValidationError(
          e.graphQLErrors &&
            e.graphQLErrors?.find((e: any) => e.errorInfo === type)
        );
        setRolesValidationError(
          e.graphQLErrors &&
            e.graphQLErrors?.find((e: any) => e.errorInfo === "roles")
        );
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Modal onHide={() => setIsOpen(false)} title="Grant accesses">
      <div>
        <div className="field">
          <label className="label">{capitalizeFirstLetter(type)}*</label>
          <Input
            value={identifier}
            onChange={(e) => {
              setIdentifier(e.target.value);
            }}
            type="text"
          />
          {typeValidationError && (
            <p className="validation-error">{typeValidationError.message}</p>
          )}
          <label className="label">Roles*</label>
          <Loading loading={loading}>
            <Select
              isMulti
              isClearable={false}
              value={roles.map((el) => ({
                value: el,
                label: rolesEnums.find((role) => role.value === el)?.label,
              }))}
              onChange={(el) => {
                Array.isArray(el)
                  ? setRoles(el.map(({ value }: any) => value))
                  : setRoles([]);
              }}
              options={rolesEnums.map((role) => ({
                value: role.value,
                label: role.label,
              }))}
            />
            {rolesValidationError && (
              <p className="validation-error">{rolesValidationError.message}</p>
            )}
          </Loading>
        </div>
        <div>
          <Button
            onClick={addingCustomerRolesHandler}
            disabled={roles.length === 0}
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default AddNewAccessModal;
