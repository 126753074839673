/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { useContext, useState } from "react";
import {
  Frequency,
  PolicySettingsInput,
  PolicyType,
  RetentionCondition,
  TimeAwarePolicySettingsInput,
  useCreateBackupPolicyMutation,
  useGetBestAllowedPolicySettingsQuery,
} from "../../../generated/graphql";
import { CustomerContext } from "../../../context/customer";
import SimpleReactValidator from "simple-react-validator";
import Input from "../../../commonComponents/input/Input";
import ValidationMessage from "../../../commonComponents/validationMessage/ValidationMessage";
import Segment from "../../../commonComponents/segment/Segment";
import Button from "../../../commonComponents/button/Button";
import { toast } from "react-toastify";
import { BackupPolicyTooltips, toastMessages } from "../../../utils/constants";
import { History } from "history";
import { ROUTES } from "../../../routes/routes";
import { TYPES } from "./policiesConstants";
import PolicyRpoForm from "./PolicyRpoForm";
import PolicyTimeAwareForm from "./PolicyTimeAwareForm";
import GraphqlError, {
  GraphQLErrorCustom,
} from "../../../commonComponents/graphqlError/GraphqlError";
import {
  conditionalRemovePrimary,
  conditionalRemoveReplica,
} from "../../../utils/helpers";
import ReactTooltip from "react-tooltip";

type PolicyFormProps = {
  history: History;
};

const validator: SimpleReactValidator = new SimpleReactValidator({
  validators: {
    regex: {
      message: "Cron expression is invalid.",
      rule: (val: string) =>
        validator.helpers.testRegex(
          val,
          /^((((\d+,)+\d+|(\d+([\/\-#])\d+)|\d+L?|\*(\/\d+)?|L(-\d+)?|\?) ?){5})$/i
        ),
    },
  },
});

const PolicyForm = ({ history }: PolicyFormProps) => {
  const { customer } = useContext(CustomerContext);
  const customerId = customer && customer.id ? customer.id : "";
  const { data } = useGetBestAllowedPolicySettingsQuery({
    variables: {
      customerId: customerId,
    },
  });

  const [validationError, setValidationError] = useState(false);
  const [policyName, setPolicyName] = useState("");
  const [policyType, setPolicyType] = useState(PolicyType.Rpo);
  const [policySettings, setPolicySettings] = useState<
    Array<PolicySettingsInput>
  >([
    {
      frequency: Frequency.Hours,
      retention: {
        primarySnapshotsRetention: {
          condition: RetentionCondition.TimeInDays,
        },
        replicaSnapshotsRetention: {
          condition: RetentionCondition.TimeInDays,
        },
      },
    } as PolicySettingsInput,
  ]);
  const [timeAwarePolicySettings, setTimeAwarePolicySettings] =
    useState<TimeAwarePolicySettingsInput>({
      cronExpression: "",
      retention: {
        primarySnapshotsRetention: {
          condition: RetentionCondition.TimeInDays,
        },
        replicaSnapshotsRetention: {
          condition: RetentionCondition.TimeInDays,
        },
      },
    } as TimeAwarePolicySettingsInput);

  const [
    removePrimarySnapsAfterResourceDeletion,
    setRemovePrimarySnapsAfterResourceDeletion,
  ] = useState<boolean>(false);
  const [
    removeReplicaSnapsAfterResourceDeletion,
    setRemoveReplicaSnapsAfterResourceDeletion,
  ] = useState<boolean>(false);
  const [graphqlValidationErrors, setGraphqlValidationErrors] = useState<
    Array<GraphQLErrorCustom>
  >([]);

  const [createBackupPolicy] = useCreateBackupPolicyMutation({
    variables: {
      input: {
        customerId: customerId,
        policyName: policyName,
        type: policyType,
        policySettings: policyType === PolicyType.Rpo ? policySettings : null,
        timeAwarePolicySettings:
          policyType === PolicyType.TimeAware ? timeAwarePolicySettings : null,
        removePrimarySnapshotsAfterResourceDeletion:
          removePrimarySnapsAfterResourceDeletion,
        removeReplicaSnapshotsAfterResourceDeletion:
          removeReplicaSnapsAfterResourceDeletion,
      },
    },
  });

  const onSubmit = () => {
    validationError && setValidationError(false);

    if (validator.allValid()) {
      createBackupPolicy()
        .then(() => {
          toast.success(toastMessages.POLICY_CREATED);
          validator.hideMessages();
          history.push(ROUTES.settings.policies.index.generate());
        })
        .catch((e) => {
          toast.error(toastMessages.POLICY_CREATION_ERROR);
          setGraphqlValidationErrors(e.graphQLErrors);
        });
    } else {
      setValidationError(true);
      validator.showMessages();
      toast.error(toastMessages.VALIDATION_FAILED);
    }
  };

  return (
    <div className="box">
      <div className="columns is-vcentered border-bottom">
        <div className="column is-7">
          <h1 className="title is-marginless is-5 is-capitalized">
            Create new backup policy
          </h1>
        </div>
        <div className="column is-5">
          <Segment alignRight>
            <Button
              className="margin-right"
              outline
              onClick={() => {
                validator.hideMessages();
                history.push(ROUTES.settings.policies.index.generate());
              }}
            >
              Cancel
            </Button>
            <Button
              className="margin-right"
              onClick={() => onSubmit()}
              data-cy="save-policy"
            >
              Save
            </Button>
          </Segment>
        </div>
      </div>
      {data?.bestAllowedPolicySettings && (
        <>
          <div className="columns">
            <div className="column is-4-desktop">
              <div className="field">
                <label className="label">
                  Policy name*
                  <span className="icon" data-tip={BackupPolicyTooltips.name}>
                    <i className="fa fa-question-circle" />
                  </span>
                </label>
                <Input
                  value={policyName}
                  onChange={(e) => setPolicyName(e.target.value)}
                  type="text"
                  data-cy="policy-name"
                />
                <ValidationMessage>
                  {validator.message(
                    "policyName",
                    policyName,
                    "required|max:64"
                  )}
                </ValidationMessage>
                <GraphqlError
                  errorInfo="policyName"
                  graphqlValidationErrors={graphqlValidationErrors}
                />
              </div>
              <div className="field">
                <label className="label">
                  Policy type*
                  <span className="icon" data-tip={BackupPolicyTooltips.type}>
                    {" "}
                    <i className="fa fa-question-circle" />
                  </span>
                </label>
                <Segment tabs>
                  {TYPES.map((button) => (
                    <Button
                      key={`type-${button.value}`}
                      onClick={() => {
                        setPolicyType(button.value);
                        validator.purgeFields();
                      }}
                      className={policyType === button.value ? "active" : ""}
                      select
                    >
                      {button.label}
                    </Button>
                  ))}
                </Segment>
              </div>
              {conditionalRemovePrimary(
                policyType,
                policySettings,
                timeAwarePolicySettings
              ) && (
                <div className="field-checkbox">
                  <input
                    type="checkbox"
                    checked={removePrimarySnapsAfterResourceDeletion}
                    onChange={(e) =>
                      setRemovePrimarySnapsAfterResourceDeletion(
                        e.target.checked
                      )
                    }
                  />
                  <label className="label">
                    Remove primary snapshots after resource deletion
                    <span
                      className="icon"
                      data-tip={
                        BackupPolicyTooltips.removePrimarySnapshotsAfterResourceDeletion
                      }
                    >
                      <i className="fa fa-question-circle" />
                    </span>
                  </label>
                  <GraphqlError
                    errorInfo="policremovePrimarySnapshotsAfterResourceDeletionyName"
                    graphqlValidationErrors={graphqlValidationErrors}
                  />
                </div>
              )}
              {conditionalRemoveReplica(
                policyType,
                policySettings,
                timeAwarePolicySettings
              ) && (
                <div className="field-checkbox">
                  <input
                    type="checkbox"
                    checked={removeReplicaSnapsAfterResourceDeletion}
                    onChange={(e) =>
                      setRemoveReplicaSnapsAfterResourceDeletion(
                        e.target.checked
                      )
                    }
                  />
                  <label className="label">
                    Remove replica snapshots after resource deletion
                    <span
                      className="icon"
                      data-tip={
                        BackupPolicyTooltips.removeReplicaSnapshotsAfterResourceDeletion
                      }
                    >
                      <i className="fa fa-question-circle" />
                    </span>
                  </label>
                  <GraphqlError
                    errorInfo="removeReplicaSnapshotsAfterResourceDeletion"
                    graphqlValidationErrors={graphqlValidationErrors}
                  />
                </div>
              )}
            </div>
          </div>
          {policyType === PolicyType.Rpo && (
            <PolicyRpoForm
              policySettings={policySettings}
              setPolicySettings={setPolicySettings}
              data={data}
              validator={validator}
              graphqlValidationErrors={graphqlValidationErrors}
              setRemovePrimarySnapsAfterResourceDeletion={
                setRemovePrimarySnapsAfterResourceDeletion
              }
              setRemoveReplicaSnapsAfterResourceDeletion={
                setRemoveReplicaSnapsAfterResourceDeletion
              }
            />
          )}
          {policyType === PolicyType.TimeAware && (
            <PolicyTimeAwareForm
              timeAwarePolicySettings={timeAwarePolicySettings}
              setTimeAwarePolicySettings={setTimeAwarePolicySettings}
              data={data}
              validator={validator}
              graphqlValidationErrors={graphqlValidationErrors}
              setRemovePrimarySnapsAfterResourceDeletion={
                setRemovePrimarySnapsAfterResourceDeletion
              }
              setRemoveReplicaSnapsAfterResourceDeletion={
                setRemoveReplicaSnapsAfterResourceDeletion
              }
            />
          )}

          <ReactTooltip
            effect="solid"
            className="setup-tooltip"
            multiline={true}
          />
        </>
      )}
      <div className="columns">
        <div className="column">
          <small>* - required</small>
        </div>
      </div>
    </div>
  );
};

export default PolicyForm;
