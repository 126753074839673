/**
 * Copyright 2021-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { useEffect, useState } from "react";
import Input from "../input/Input";
import Segment from "../segment/Segment";
import Button from "../button/Button";

interface SearchFilterProps {
  filters: string;
  applyFilter: (newFilter: string) => void;
  label: string;
  placeholder: string;
}

const SearchFilter: React.FC<SearchFilterProps> = ({
  filters,
  applyFilter,
  label,
  placeholder,
}) => {
  const [newFilter, setNewFilter] = useState<string>("");

  useEffect(() => {
    setNewFilter(filters);
  }, [filters]);

  return (
    <div className="columns is-multiline">
      <div className="field column is-6">
        <label className="label">{label}</label>
        <div className="control">
          <div className="columns">
            <div className="column">
              <Input
                placeholder={placeholder}
                value={newFilter}
                onChange={(e) => {
                  setNewFilter(e.target.value);
                }}
                type="text"
              />
            </div>
            <div className="column">
              <Segment>
                <Button onClick={() => applyFilter(newFilter)}>Search</Button>
                <Button
                  onClick={() => {
                    setNewFilter("");
                    applyFilter("");
                  }}
                >
                  Clear filters
                </Button>
              </Segment>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchFilter;
