/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { useContext } from "react";
import { CustomerContext } from "../../../context/customer";
import { useGetBackupEventStatsQuery } from "../../../generated/graphql";
import { Loading } from "../../../commonComponents/loading/Loading";
import {
  CartesianGrid,
  Cell,
  Line,
  LineChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import moment from "moment";
import { Error } from "../../../commonComponents/error/error";
import { COLORS, DATE_FORMAT_SHORT_YEAR } from "../../../utils/constants";
import { countPercentage } from "../../../utils/helpers";
import { v4 as uuid } from "uuid";

interface Props {
  eventId: string;
}

const EventStats: React.FC<Props> = ({ eventId }) => {
  const customer = useContext(CustomerContext);
  const customerId = customer?.customer?.id || "";

  const { data, error, loading } = useGetBackupEventStatsQuery({
    variables: {
      searchCriteria: {
        customerId: customerId,
        backupEventId: eventId,
      },
    },
  });

  const { backupEventStats: stats } = data || {};

  const numberOfResourcesMeetingRPO = stats?.numberOfResourcesMeetingRPO || 0;
  const totalNumberOfResources = stats?.totalNumberOfResources || 0;
  const numberOfBackedUpResources = stats?.numberOfBackedUpResources || 0;

  const percentageData = countPercentage(
    totalNumberOfResources,
    numberOfResourcesMeetingRPO
  );

  const isThresholdHasBeenReached = percentageData >= 80;
  const successRateData = [
    {
      title: "Number of Resources Meeting RPO",
      value: numberOfResourcesMeetingRPO,
      color: COLORS.success,
    },
    {
      title: "Remaining Resources",
      value: totalNumberOfResources - numberOfResourcesMeetingRPO,
      color: COLORS.failure,
    },
  ];

  const resourcesData = [
    {
      title: "Number of Backed Up Resources",
      value: numberOfBackedUpResources,
    },
    {
      title: "Number of Not Backed Up Resources",
      value: totalNumberOfResources - numberOfBackedUpResources,
    },
  ];
  const numberOfBackedUpResourcesPercentageData = countPercentage(
    totalNumberOfResources,
    numberOfBackedUpResources
  );

  // prettier-ignore
  const isBackedUpThresholdHasBeenReached = numberOfBackedUpResourcesPercentageData >= 80;

  return (
    <div>
      <Loading loading={loading}>
        {error && <Error error={error} />}
        {data && data.backupEventStats ? (
          <div className="columns">
            <div className="column is-4">
              <h2 className="subtitle">Success Rate</h2>
              <div className="box is-clearfix has-text-centered">
                <div className="chart chart--centered">
                  <ResponsiveContainer width="100%">
                    <PieChart>
                      <Pie
                        data={successRateData}
                        cx="50%"
                        cy="50%"
                        innerRadius={60}
                        outerRadius={80}
                        paddingAngle={0}
                        dataKey="value"
                      >
                        {successRateData.map((_, index) => (
                          <Cell key={uuid()} fill={_.color} />
                        ))}
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>
                </div>
                <div
                  className="chart-indicator"
                  style={{
                    color: isThresholdHasBeenReached
                      ? COLORS.success
                      : COLORS.failure,
                  }}
                >
                  {`${percentageData} %`}
                </div>
                <div className="columns">
                  <div className="column">
                    <label style={{ fontSize: ".75rem" }}>
                      Fulfilled Policy:
                    </label>
                    <div
                      className="label"
                      style={{
                        color: COLORS.success,
                      }}
                    >
                      {numberOfResourcesMeetingRPO}
                    </div>
                  </div>
                  <div className="column">
                    <label style={{ fontSize: ".75rem" }}>
                      Unfulfilled Policy:
                    </label>
                    <div
                      className="label"
                      style={{
                        color: COLORS.failure,
                      }}
                    >
                      {totalNumberOfResources - numberOfResourcesMeetingRPO}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="column is-4">
              <h2 className="subtitle">Resources</h2>
              <div className="box is-clearfix has-text-centered">
                <div className="chart chart--centered">
                  <ResponsiveContainer width="100%">
                    <PieChart>
                      <Pie
                        data={resourcesData}
                        cx="50%"
                        cy="50%"
                        innerRadius={60}
                        outerRadius={80}
                        paddingAngle={0}
                        dataKey="value"
                      >
                        {successRateData.map((_, index) => (
                          <Cell key={uuid()} fill={_.color} />
                        ))}
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>
                </div>
                <div
                  className="chart-indicator"
                  style={{
                    color: isBackedUpThresholdHasBeenReached
                      ? COLORS.success
                      : COLORS.failure,
                  }}
                >
                  {`${numberOfBackedUpResourcesPercentageData} %`}
                </div>
                <div className="columns">
                  <div className="column">
                    <label style={{ fontSize: ".75rem" }}>Backed up:</label>
                    <div
                      className="label"
                      style={{
                        color:
                          numberOfBackedUpResources === totalNumberOfResources
                            ? COLORS.success
                            : COLORS.failure,
                      }}
                    >
                      {numberOfBackedUpResources}
                    </div>
                  </div>
                  <div className="column">
                    <label style={{ fontSize: ".75rem" }}>Total:</label>
                    <div
                      className="label"
                      style={{
                        color: COLORS.success,
                      }}
                    >
                      {totalNumberOfResources}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="column is-4">
              <h2 className="subtitle">Success Rate Trend</h2>
              <div className="box is-clearfix">
                {" "}
                {data.backupEventStats.successRateHistory &&
                data.backupEventStats.successRateHistory.length > 0 ? (
                  <ResponsiveContainer width="100%" height={246}>
                    <LineChart
                      data={data.backupEventStats.successRateHistory.map(
                        (e) => ({
                          time: moment
                            .unix(e.startTimestamp)
                            .format(DATE_FORMAT_SHORT_YEAR),
                          rate: countPercentage(
                            e.totalNumberOfResources,
                            e.numberOfResourcesMeetingRPO
                          ),
                        })
                      )}
                    >
                      <CartesianGrid strokeDasharray="5 5" />
                      <Line
                        dot={{ stroke: COLORS.success, strokeWidth: 1 }}
                        stroke={COLORS.success}
                        type="monotone"
                        strokeWidth={3}
                        dataKey="rate"
                      />
                      <XAxis dataKey="time" />
                      <YAxis
                        padding={{ top: 60, bottom: 20 }}
                        domain={[0, 100]}
                        width={35}
                      />
                      <Tooltip />
                    </LineChart>
                  </ResponsiveContainer>
                ) : (
                  "There is no success rate history"
                )}
              </div>
            </div>
          </div>
        ) : (
          "There are no statistics for this event."
        )}
      </Loading>
    </div>
  );
};

export default EventStats;
