/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { useContext, useMemo } from "react";
import { useGetBackupEventsQuery, Order } from "../../../generated/graphql";
import { Loading } from "../../../commonComponents/loading/Loading";
import Button from "../../../commonComponents/button/Button";
import { Error } from "../../../commonComponents/error/error";
import { CustomerContext } from "../../../context/customer";
import Table from "../../../commonComponents/table/Table";
import { DATE_FORMAT, PROGRESS_ID, Progress } from "../../../utils/constants";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { ROUTES } from "../../../routes/routes";

const Events: React.FC = () => {
  const customer = useContext(CustomerContext);
  const customerId = customer?.customer?.id || "";

  const { data, error, loading } = useGetBackupEventsQuery({
    variables: {
      searchCriteria: {
        customerId: customerId,
        filters: [
          {
            id: PROGRESS_ID,
            values: [Progress.Created],
          },
        ],
        startTimeOrder: Order.Asc,
        pageSize: 15,
        pageNumber: 1,
      },
    },
  });

  const columns = useMemo(
    () => [
      {
        Header: "PLAN (policy + setup)",
        accessor: "planName",
        Cell: ({ row }: any) => row.original.planName,
      },
      {
        Header: "Start time",
        accessor: "startTimestamp",
        Cell: ({ row }: any) => (
          <Moment local unix format={DATE_FORMAT}>
            {row.original.startTimestamp}
          </Moment>
        ),
      },
      {
        Header: "Resources",
        accessor: "totalNumberOfResources",
        Cell: ({ row }: any) => row.original.totalNumberOfResources,
      },
      {
        id: "actions",
        Cell: ({ row }: any) => (
          <div>
            <div className="navbar-item is-hoverable is-pulled-right">
              <Link to={ROUTES.events.event.generate({ id: row.original.id })}>
                <span className="icon">
                  <i className="fa fa-info-circle" />
                </span>
              </Link>
            </div>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <>
      <h1 className="subtitle">Upcoming Events</h1>
      <div className="box is-clearfix">
        {error && <Error error={error} />}
        {data && data.backupEvents.data.length > 0 ? (
          <Loading loading={loading}>
            <>
              <Table
                columns={columns}
                data={data.backupEvents.data}
                disableSortBy
              />
              {data.backupEvents.totalNumberOfPages > 1 && (
                <div
                  className="columns is-centered"
                  style={{ marginTop: "1rem" }}
                >
                  <div className="column is-half is-offset-one-third">
                    <div className="content">
                      <Link to={ROUTES.events.index.generate()}>
                        <Button>See more</Button>
                      </Link>
                    </div>
                  </div>
                </div>
              )}
            </>
          </Loading>
        ) : (
          "Currently there are no incoming events"
        )}
      </div>
    </>
  );
};

export default Events;
