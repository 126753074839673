/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { ReactNode, useState } from "react";
import { ApolloError } from "apollo-client";

interface ErrorProps {
  error: ApolloError;
  children?: ReactNode;
  canClose?: boolean;
}

export const Error: React.FC<ErrorProps> = ({ error, canClose = true }) => {
  const [visible, setVisible] = useState(true);
  const message = error.graphQLErrors
    .map((gqlError) => gqlError.message)
    .join(", ");

  const hide = () => setVisible(false);
  return (
    <>
      {visible && (
        <article className="message is-danger">
          <div className="message-header">
            <p>Oooops</p>
            {canClose && (
              <button
                className="delete"
                aria-label="delete"
                onClick={hide}
                data-cy="error-message-delete"
              />
            )}
          </div>
          <div className="message-body">{message}</div>
        </article>
      )}
    </>
  );
};
