/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { CloudProvider } from "../../../generated/graphql";
import { icons } from "../../../commonComponents/icon/icons";

export const SupportedCloudProviders = [
  {
    name: CloudProvider.Aws,
    icon: icons.AWS,
  },
  {
    name: CloudProvider.Azure,
    icon: icons.AZURE,
  },
];

export const GetSupportedFilesFormat = (provider: CloudProvider) => {
  switch (provider) {
    case CloudProvider.Aws:
      return {
        supportedFiles: ".json, .yml, .yaml",
        supportedFilesMessage:
          "Upload YAML or JSON file with template definition.",
      };
    case CloudProvider.Azure:
      return {
        supportedFiles: ".json",
        supportedFilesMessage: "Upload JSON file with template definition.",
      };
    default:
      return {
        supportedFiles: "",
        supportedFilesMessage: "Provider not supported.",
      };
  }
};

export default SupportedCloudProviders;
