/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { ReactNode } from "react";
import NavigationHeader from "./commonComponents/main/NavigationHeader";
import PageHeader from "./commonComponents/main/PageHeader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MessageContainer from "./commonComponents/message/MessageContainer";
import ReleaseNote from "./views/notes/components/ReleaseNote";

interface Props {
  children: ReactNode;
}

const App: React.FC<Props> = ({ children }) => {
  return (
    <div className="App">
      <ToastContainer />
      <NavigationHeader />
      <MessageContainer />
      <ReleaseNote />
      <div className="section">
        <div className="container is-fluid">
          <PageHeader />
        </div>
        {children}
      </div>
    </div>
  );
};

export default App;
