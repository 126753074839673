/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { useContext, useEffect, useState } from "react";
import Input from "../../../commonComponents/input/Input";
import {
  useGetUserProfileQuery,
  useGetUserRoleQuery,
  UserRole,
  useUpdateUserProfileMutation,
} from "../../../generated/graphql";
import { Loading } from "../../../commonComponents/loading/Loading";
import { CustomerContext } from "../../../context/customer";
import Segment from "../../../commonComponents/segment/Segment";
import Button from "../../../commonComponents/button/Button";
import { toast } from "react-toastify";
import { confirmations, toastMessages } from "../../../utils/constants";
import Popup from "react-popup";
import { History } from "history";
import { ROUTES } from "../../../routes/routes";
import { pluralizeText } from "../../../commonComponents/text/text";

interface Props {
  history: History;
}

export const ProfilePage: React.FC<Props> = ({ history }) => {
  const { customer } = useContext(CustomerContext);
  const [controlledByTags, setControlledByTags] = useState<boolean | any>();
  const customerId = customer?.id || "";

  const { data, loading } = useGetUserProfileQuery({
    variables: {
      customerId: customerId,
    },
  });

  const { data: userRoleData, loading: userRoleLoading } = useGetUserRoleQuery({
    variables: {
      customerId: customerId,
    },
  });

  const { userRole } = userRoleData || {};

  const [updateUserProfile] = useUpdateUserProfileMutation({
    variables: {
      input: {
        customerId: customerId,
        isBackupControlledByTags: controlledByTags,
      },
    },
  });

  useEffect(() => {
    setControlledByTags(data?.userProfile?.isBackupControlledByTags);
  }, [data?.userProfile]);

  const conditionSegment = [
    {
      value: true,
      label: "Enabled",
    },
    {
      value: false,
      label: "Disabled",
    },
  ];

  const onClick = (button: { value: boolean; label: string }) => {
    controlledByTags !== button.value &&
      Popup.create({
        onClose: () => {
          Popup.close();
        },
        title: `Tag based backup`,
        content: (
          <div className="box">
            <div className="content">
              <p>
                {controlledByTags
                  ? confirmations.DISABLE_TAG_BASED_BACKUP
                  : confirmations.ENABLE_TAG_BASED_BACKUP}
              </p>
              {!controlledByTags && (
                <p>
                  <span className="icon">
                    <i className="fa fa-exclamation-circle" />
                  </span>
                  <strong>Warning</strong>: Enabling tag-based backup will
                  override manually set up backups.
                </p>
              )}
            </div>
          </div>
        ),
        buttons: {
          right: [
            {
              text: "Cancel",
              className: "btn",
              action: () => {
                Popup.close();
              },
            },
            {
              text: `${controlledByTags ? "Disable" : "Enable"}`,
              className: "btn",
              action: async () => {
                setControlledByTags(!controlledByTags);
                await updateUserProfile({
                  variables: {
                    input: {
                      customerId: customerId,
                      isBackupControlledByTags: !controlledByTags,
                    },
                  },
                }).then(() => {
                  toast.success(toastMessages.USER_PROFILE_UPDATED);
                  history.push(ROUTES.settings.profile.index.generate());
                  Popup.close();
                });
              },
            },
          ],
        },
      });
  };

  return (
    <div className="box">
      <div className="columns is-vcentered border-bottom">
        <div className="column">
          <h1 className="title is-marginless is-5 is-capitalized">
            Customer Information
          </h1>
        </div>
      </div>
      <Loading loading={loading}>
        <div className="columns">
          <div className="column">
            {data?.userProfile?.companyName && (
              <div className="field">
                <label className="label">Company Name</label>
                <Input
                  type="text"
                  value={data?.userProfile?.companyName}
                  disabled
                />
              </div>
            )}
            {data?.userProfile?.email && (
              <div className="field">
                <label className="label">User Email</label>
                <Input type="text" value={data?.userProfile?.email} disabled />
              </div>
            )}
            <Loading loading={userRoleLoading}>
              {data?.userProfile && (
                <div className="field">
                  <label className="label">Backup Controlled by Tags</label>
                  {!userRoleLoading && userRole !== UserRole.ReadOnly ? (
                    <Segment tabs>
                      {conditionSegment.map((button) => (
                        <Button
                          key={button.label}
                          onClick={() => onClick(button)}
                          className={
                            controlledByTags === button.value ? "active" : ""
                          }
                          select
                        >
                          {button.label}
                        </Button>
                      ))}
                    </Segment>
                  ) : (
                    <>
                      <span
                        className={`tag is-medium ${
                          controlledByTags ? "is-success" : "is-danger"
                        }`}
                      >
                        {controlledByTags ? "Enabled" : "Disabled"}
                      </span>
                    </>
                  )}
                </div>
              )}
            </Loading>
          </div>
          <div className="column">
            {data?.userProfile?.firstName && data?.userProfile?.lastName && (
              <div className="field">
                <label className="label">User Name</label>
                <Input
                  type="text"
                  value={`${data?.userProfile?.firstName} ${data?.userProfile?.lastName}`}
                  disabled
                />
              </div>
            )}
            {data?.userProfile?.phoneNumber && (
              <div className="field">
                <label className="label">Phone Number</label>
                <Input
                  type="text"
                  value={data?.userProfile?.phoneNumber}
                  disabled
                />
              </div>
            )}
          </div>
        </div>
        <div className="columns is-vcentered border-bottom">
          <div className="column">
            <h1 className="title is-marginless is-5 is-capitalized">
              RPO Information
            </h1>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            {data?.userProfile?.bestAllowedPolicySettings?.minRPOInHours && (
              <div className="field">
                <label className="label">Minimum RPO Available</label>
                <Input
                  type="text"
                  value={`${data?.userProfile?.bestAllowedPolicySettings?.minRPOInHours.toString()} h`}
                  disabled
                />
              </div>
            )}
          </div>
          <div className="column">
            {data?.userProfile?.bestAllowedPolicySettings
              ?.maxPrimarySnapshotRetentionInDays &&
              data?.userProfile?.bestAllowedPolicySettings
                ?.maxPrimarySnapshotRetentionQuantity && (
                <div className="field">
                  <label className="label">Maximum Retention Available</label>
                  <Input
                    type="text"
                    value={`${pluralizeText(
                      data?.userProfile?.bestAllowedPolicySettings
                        ?.maxPrimarySnapshotRetentionInDays,
                      "day"
                    )}`}
                    disabled
                  />
                  <Input
                    type="text"
                    value={`${pluralizeText(
                      data?.userProfile?.bestAllowedPolicySettings
                        ?.maxPrimarySnapshotRetentionQuantity,
                      "snapshot"
                    )}`}
                    disabled
                  />
                </div>
              )}
            {data?.userProfile?.bestAllowedPolicySettings
              ?.maxReplicaSnapshotRetentionInDays &&
              data?.userProfile?.bestAllowedPolicySettings
                ?.maxReplicaSnapshotRetentionQuantity && (
                <div className="field">
                  <label className="label">
                    Maximum Replica Retention Available
                  </label>
                  <Input
                    type="text"
                    value={`${pluralizeText(
                      data?.userProfile?.bestAllowedPolicySettings
                        ?.maxReplicaSnapshotRetentionInDays,
                      "day"
                    )}`}
                    disabled
                  />
                  <Input
                    type="text"
                    value={`${pluralizeText(
                      data?.userProfile?.bestAllowedPolicySettings
                        ?.maxReplicaSnapshotRetentionQuantity,
                      "snapshot"
                    )}`}
                    disabled
                  />
                </div>
              )}
          </div>
        </div>
      </Loading>
    </div>
  );
};
