/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from "react";
import { Link, useParams } from "react-router-dom";
import { ROUTES } from "../../routes/routes";
import { notesData } from "./Notes";
import slugify from "@sindresorhus/slugify";

const ReleaseNoteDetails: React.FC = () => {
  const { id } = useParams();
  const matchingNote = notesData.find((note) => slugify(note.title) === id);

  return (
    <div className="main">
      <div className="section">
        <div className="box">
          <div className="columns border-bottom">
            <div className="column">
              <p className="title is-4 is-capitalized">What's new? </p>
            </div>
            <div className="column">
              <div style={{ textAlign: "right" }}>
                <Link to={ROUTES.notes.index.generate()}>
                  <button className="button is-primary">
                    Check all Release Notes
                  </button>
                </Link>
              </div>
            </div>
          </div>
          {matchingNote && (
            <>
              <div className="columns">
                <div className="column">
                  <p className="title is-5" style={{ marginTop: "1rem" }}>
                    {matchingNote.title}
                  </p>
                  <div className="content">
                    <ul>
                      {matchingNote.details.map((entry, i) => (
                        <li key={`${matchingNote.date}-${i}`}>{entry}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="column" style={{ textAlign: "right" }}>
                <strong>Published at: </strong>
                {matchingNote.date}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReleaseNoteDetails;
