/**
 * Copyright 2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { ReactNode } from "react";
import { Link, useLocation } from "react-router-dom";
import { ROUTES } from "../../../routes/routes";
import Popup from "react-popup";

interface Props {
  children: ReactNode;
}

export const CustomerAccessesLayout: React.FC<Props> = ({ children }) => {
  const location = useLocation();
  const path = location.pathname;

  return (
    <div className="main wide">
      <div className="container is-fluid">
        <div className="box">
          <div className="tabs is-toggle">
            <ul>
              <li>
                <Link
                  className={`${
                    (path === ROUTES.customerAccesses.index.generate() ||
                      path.includes(
                        ROUTES.customerAccesses.users.generate()
                      )) &&
                    "active"
                  }`}
                  to={ROUTES.customerAccesses.users.generate()}
                >
                  Users
                </Link>
              </li>
              <li>
                <Link
                  className={`${
                    path.includes(ROUTES.customerAccesses.apiKeys.generate()) &&
                    "active"
                  }`}
                  to={ROUTES.customerAccesses.apiKeys.generate()}
                >
                  API Keys
                </Link>
              </li>
            </ul>
          </div>
        </div>
        {children}
        <Popup />
      </div>
    </div>
  );
};
