/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { useContext } from "react";
import Segment from "../../../commonComponents/segment/Segment";
import { CustomerContext } from "../../../context/customer";
import { Link } from "react-router-dom";
import Button from "../../../commonComponents/button/Button";
import PoliciesList from "../components/PoliciesList";
import { Loading } from "../../../commonComponents/loading/Loading";
import { useGetUserRoleQuery, UserRole } from "../../../generated/graphql";
import { RouteComponentProps } from "react-router-dom";
import { ROUTES } from "../../../routes/routes";

export const PoliciesPage: React.FC<RouteComponentProps> = ({ history }) => {
  const { customer } = useContext(CustomerContext);
  const customerId = customer?.id || "";

  const { data: userRoleData, loading: userRoleLoading } = useGetUserRoleQuery({
    variables: {
      customerId: customerId,
    },
  });

  const { userRole } = userRoleData || {};

  return (
    <div className="box">
      <div className="columns is-vcentered border-bottom">
        <div className="column">
          <h1 className="title is-marginless is-5 is-capitalized">
            Backup policies
          </h1>
        </div>
        <div className="column">
          {userRole !== UserRole.ReadOnly && (
            <Segment alignRight>
              <Link to={ROUTES.settings.policies.create.generate()}>
                <Button className="is-pulled-right" data-cy="create-policy">
                  Create new
                </Button>
              </Link>
            </Segment>
          )}
        </div>
      </div>
      <Loading loading={userRoleLoading}>
        <PoliciesList
          history={history}
          userRole={userRoleData}
          userRoleLoading={userRoleLoading}
        />
      </Loading>
    </div>
  );
};
