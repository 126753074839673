/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from "react";
import Placeholder from "../table/Placeholder";
import { useHistory } from "react-router-dom";

interface DetailsNotFoundProps {
  message?: string;
  route: string;
}

const DetailsNotFound: React.FC<DetailsNotFoundProps> = ({
  message,
  route,
}) => {
  const history = useHistory();

  return (
    <div className="container is-fluid container-padding ">
      <div className="box">
        <div className="columns">
          <div className="column">
            <Placeholder message={message} />
            <p className="has-text-centered">
              <a
                onClick={(e) => {
                  e.preventDefault();
                  history.replace(route);
                }}
              >
                Click here to go back
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsNotFound;
