/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import SimpleReactValidator from "simple-react-validator";
import React, { useContext, useState } from "react";
import { CustomerContext } from "../../../context/customer";
import {
  CloudProvider,
  TemplateResourcesMappingInput,
  useCreateDisasterRecoveryPlanMutation,
  useGetSupportedRegionsQuery,
} from "../../../generated/graphql";
import { toast } from "react-toastify";
import {
  DisasterRecoveryPlanTooltips,
  toastMessages,
} from "../../../utils/constants";
import { ROUTES } from "../../../routes/routes";
import { History } from "history";
import Segment from "../../../commonComponents/segment/Segment";
import Button from "../../../commonComponents/button/Button";
import Input from "../../../commonComponents/input/Input";
import ValidationMessage from "../../../commonComponents/validationMessage/ValidationMessage";
import { Loading } from "../../../commonComponents/loading/Loading";
import { Error } from "../../../commonComponents/error/error";
import moment from "moment";
import DatePicker from "react-datepicker";
import Select from "react-select";
import TemplatesSelect from "./TemplatesSelect";
import ReactTooltip from "react-tooltip";
import AddEmailsButton from "./AddEmailsButton";
import RemoveEmailsButton from "./RemoveEmailsButton";
import ProviderSelector from "./ProviderSelector";

interface Props {
  history: History;
}

const validator = new SimpleReactValidator();

const PlanForm: React.FC<Props> = ({ history }) => {
  const { customer } = useContext(CustomerContext);
  const customerId = customer && customer.id ? customer.id : "";
  const [validationError, setValidationError] = useState(false);

  const [name, setName] = useState<string>("");
  const [cloudProvider, setCloudProvider] = useState<CloudProvider>(
    CloudProvider.Aws
  );
  const [restoreRegion, setRestoreRegion] = useState<string>("");
  const [startTimestamp, setStartTimestamp] = useState<number>();
  const [frequencyInMonths, setFrequencyInMonths] = useState<number>(1);
  const [remindDaysBefore, setRemindDaysBefore] = useState<number>(0);
  const [kmsKey, setKMSKey] = useState<string>("");
  const [emails, setEmails] = useState<Array<string>>([""]);
  const [templates, setTemplates] = useState<
    Array<TemplateResourcesMappingInput>
  >([
    {
      templateId: "",
      resourcesMapping: [],
    },
  ]);
  const [graphqlValidationErrors, setGraphqlValidationErrors] =
    useState<any>(null);
  let graphqlValidationError;

  const [createPlan] = useCreateDisasterRecoveryPlanMutation({
    variables: {
      input: {
        customerId: customerId,
        name: name,
        cloudProvider: cloudProvider,
        restoreRegion: restoreRegion,
        startTimestamp: startTimestamp || 0,
        frequencyInMonths: frequencyInMonths,
        remindDaysBefore: remindDaysBefore,
        emails: emails,
        templatesResourcesMapping: templates,
        kmsKey: kmsKey ? kmsKey : null,
      },
    },
  });

  const { data, loading, error } = useGetSupportedRegionsQuery({
    variables: {
      cloudProvider: cloudProvider,
    },
  });

  const onSubmit = () => {
    validationError && setValidationError(false);
    if (validator.allValid()) {
      createPlan()
        .then(() => {
          toast.success(toastMessages.DISASTER_RECOVERY_PLAN_CREATED);
          validator.hideMessages();
          history.push(ROUTES.disasterRecovery.plans.index.generate());
        })
        .catch((e: any): any => {
          toast.error(toastMessages.DISASTER_RECOVERY_PLAN_CREATION_ERROR);
          setGraphqlValidationErrors(e.graphQLErrors);
        });
    } else {
      setValidationError(true);
      validator.showMessages();
      toast.error(toastMessages.VALIDATION_FAILED);
    }
  };

  return (
    <div className="box">
      {error && <Error error={error} />}
      <div className="columns is-vcentered border-bottom">
        <div className="column is-7">
          <h1 className="title is-marginless is-5 is-capitalized">
            Create new plan
          </h1>
        </div>
        <div className="column is-5">
          <Segment alignRight>
            <Button
              className="margin-right"
              outline
              onClick={() => {
                validator.hideMessages();
                history.push(ROUTES.disasterRecovery.plans.index.generate());
              }}
            >
              Cancel
            </Button>
            <Button className="margin-right" onClick={() => onSubmit()}>
              Save
            </Button>
          </Segment>
        </div>
      </div>
      <div className="columns">
        <div className="column is-4-desktop">
          <div className="field">
            <label className="label">
              Plan name*
              <span
                className="icon"
                data-tip={DisasterRecoveryPlanTooltips.planName}
              >
                <i className="fa fa-question-circle" />
              </span>
            </label>
            <Input
              value={name}
              onChange={(e) => setName(e.target.value)}
              type="text"
            />
            <ValidationMessage>
              {validator.message("name", name, "required|max:64")}
            </ValidationMessage>
            {graphqlValidationErrors &&
              (graphqlValidationError = graphqlValidationErrors?.find(
                (e: any) => e.errorInfo === "name"
              )) && (
                <p className="validation-error">
                  {graphqlValidationError.message}
                </p>
              )}
          </div>

          <div className="field">
            <label className="label">
              Plan cloud provider*
              <span
                className="icon"
                data-tip={DisasterRecoveryPlanTooltips.cloudProvider}
              >
                <i className="fa fa-question-circle" />
              </span>
            </label>
            <ProviderSelector
              selectedCloudProvider={cloudProvider}
              onSelect={(provider: CloudProvider) => {
                setCloudProvider(provider);
                setRestoreRegion("");
                setKMSKey("");
                setTemplates([
                  {
                    templateId: "",
                    resourcesMapping: [],
                  },
                ]);
              }}
            />
            {graphqlValidationErrors &&
              (graphqlValidationError = graphqlValidationErrors?.find(
                (e: any) => e.errorInfo === "cloudProvider"
              )) && (
                <p className="validation-error">
                  {graphqlValidationError.message}
                </p>
              )}
          </div>

          <div className="field">
            <label className="label">
              Restore region*
              <span
                className="icon"
                data-tip={DisasterRecoveryPlanTooltips.restoreRegion}
              >
                <i className="fa fa-question-circle" />
              </span>
            </label>
            <Loading loading={loading}>
              <Select
                defaultValue={{ value: restoreRegion, label: restoreRegion }}
                onChange={(e: any) => setRestoreRegion(e.value)}
                options={data?.supportedRegions.map((region) => ({
                  value: region,
                  label: region,
                }))}
              />
            </Loading>
            <ValidationMessage>
              {validator.message("restoreRegion", restoreRegion, "required")}
            </ValidationMessage>
            {graphqlValidationErrors &&
              (graphqlValidationError = graphqlValidationErrors?.find(
                (e: any) => e.errorInfo === "restoreRegion"
              )) && (
                <p className="validation-error">
                  {graphqlValidationError.message}
                </p>
              )}
          </div>

          <div className="field">
            <label className="label">
              Start date*
              <span
                className="icon"
                data-tip={DisasterRecoveryPlanTooltips.startDate}
              >
                <i className="fa fa-question-circle" />
              </span>
            </label>
            <DatePicker
              className="input border"
              dateFormat={"dd-MM-yyyy"}
              selected={
                startTimestamp !== undefined
                  ? new Date(startTimestamp * 1000)
                  : null
              }
              todayButton="Today"
              onChange={(e: any) => {
                if (e !== null) {
                  setStartTimestamp(
                    moment(e).add(moment().utcOffset(), "minutes").unix()
                  );
                }
              }}
              placeholderText="Select Date"
            />
            <ValidationMessage>
              {validator.message(
                "startDate",
                startTimestamp,
                "required|numeric|min:1,num"
              )}
            </ValidationMessage>
            {graphqlValidationErrors &&
              (graphqlValidationError = graphqlValidationErrors?.find(
                (e: any) => e.errorInfo === "startTimestamp"
              )) && (
                <p className="validation-error">
                  {graphqlValidationError.message}
                </p>
              )}
          </div>

          <div className="field">
            <label className="label">
              Test frequency (months)*
              <span
                className="icon"
                data-tip={DisasterRecoveryPlanTooltips.testFrequency}
              >
                <i className="fa fa-question-circle" />
              </span>
            </label>
            <Input
              value={frequencyInMonths}
              onChange={(e) => setFrequencyInMonths(e.target.value)}
              type="number"
            />
            <ValidationMessage>
              {validator.message(
                "frequencyInMonths",
                frequencyInMonths,
                "required|numeric|min:1,num"
              )}
            </ValidationMessage>
            {graphqlValidationErrors &&
              (graphqlValidationError = graphqlValidationErrors?.find(
                (e: any) => e.errorInfo === "frequencyInMonths"
              )) && (
                <p className="validation-error">
                  {graphqlValidationError.message}
                </p>
              )}
          </div>

          <div className="field">
            <label className="label">
              Number of days to send reminder before the test*
              <span
                className="icon"
                data-tip={DisasterRecoveryPlanTooltips.remindDaysBefore}
              >
                <i className="fa fa-question-circle" />
              </span>
            </label>
            <Input
              value={remindDaysBefore}
              onChange={(e) => setRemindDaysBefore(e.target.value)}
              type="number"
            />
            <ValidationMessage>
              {validator.message(
                "remindDaysBefore",
                remindDaysBefore,
                "required|numeric|min:0,num"
              )}
            </ValidationMessage>
            {graphqlValidationErrors &&
              (graphqlValidationError = graphqlValidationErrors?.find(
                (e: any) => e.errorInfo === "remindDaysBefore"
              )) && (
                <p className="validation-error">
                  {graphqlValidationError.message}
                </p>
              )}
          </div>

          {cloudProvider === CloudProvider.Aws && (
            <div className="field">
              <label className="label">
                KMS key
                <span
                  className="icon"
                  data-tip={DisasterRecoveryPlanTooltips.kmsKey}
                >
                  <i className="fa fa-question-circle" />
                </span>
              </label>
              <Input
                value={kmsKey}
                onChange={(e) => setKMSKey(e.target.value)}
                type="text"
              />
            </div>
          )}

          <div className="field">
            <label className="label">
              Recipients
              <span
                className="icon"
                data-tip={DisasterRecoveryPlanTooltips.recipients}
              >
                <i className="fa fa-question-circle" />
              </span>
            </label>
            {emails.map((email: string, index: number) => (
              <div key={`email-${index}`}>
                <Input
                  value={email}
                  onChange={(e) => {
                    const newEmails = [...emails];
                    newEmails[index] = e.target.value;
                    setEmails(newEmails);
                  }}
                  type="text"
                />
                <ValidationMessage>
                  {validator.message("email", email, "required|email")}
                </ValidationMessage>
                {graphqlValidationErrors &&
                  (graphqlValidationError = graphqlValidationErrors?.find(
                    (e: any) => e.errorInfo === `emails[${index}]`
                  )) && (
                    <p className="validation-error">
                      {graphqlValidationError.message}
                    </p>
                  )}
                {emails.length > 1 && (
                  <RemoveEmailsButton
                    recipients={emails}
                    setter={setEmails}
                    index={index}
                  />
                )}
              </div>
            ))}
            <AddEmailsButton recipients={emails} setter={setEmails} />
            <hr />

            <div className="field">
              <label className="label">
                Templates
                <span
                  className="icon"
                  data-tip={DisasterRecoveryPlanTooltips.templates}
                >
                  <i className="fa fa-question-circle" />
                </span>
              </label>
              <TemplatesSelect
                customerId={customerId}
                templatesResourcesMapping={templates}
                templatesResourcesMappingSetter={(
                  templatesInput: Array<TemplateResourcesMappingInput>
                ) => {
                  setTemplates(templatesInput);
                }}
                cloudProvider={cloudProvider}
                readOnly={false}
              />
            </div>

            <ReactTooltip
              effect="solid"
              className="setup-tooltip"
              multiline={true}
            />
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <small>* - required</small>
        </div>
      </div>
    </div>
  );
};

export default PlanForm;
