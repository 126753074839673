/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { useContext } from "react";
import {
  useTriggerScanMutation,
  useGetScanStatusQuery,
  ScanStatus as ScanStatusEnum,
} from "../../../generated/graphql";
import { Loading } from "../../../commonComponents/loading/Loading";
import { Error } from "../../../commonComponents/error/error";
import Button from "../../../commonComponents/button/Button";
import { CustomerContext } from "../../../context/customer";
import { toastMessages } from "../../../utils/constants";
import { toast } from "react-toastify";
import Moment from "react-moment";

const ScanStatus: React.FC = () => {
  const customer = useContext(CustomerContext);
  const customerId = customer?.customer?.id || "";

  const { data, error, loading, refetch } = useGetScanStatusQuery({
    variables: {
      customerId: customerId,
    },
  });

  const [triggerScan, { loading: scanLoading, error: scanError }] =
    useTriggerScanMutation({
      variables: {
        input: { customerId },
      },
    });

  const trigger = (msg: string) => {
    triggerScan()
      .then((e) => {
        toast.success(msg);
        refetch();
      })
      .catch((e) => toast.error(toastMessages.SCAN_TRIGGER_ERROR));
  };

  const status = data?.scanStatus?.status || "";

  return (
    <div className="box is-clearfix">
      {error && <Error error={error} />}
      {scanError && <Error error={scanError} />}
      <Loading loading={loading || scanLoading}>
        {!error && (
          <>
            {status ? (
              <span>
                Last scan status: <span className="tag">{status}</span>
              </span>
            ) : (
              <span>
                Run the initial scan{" "}
                <button
                  className="button is-pulled-right"
                  onClick={() => trigger("Initial scan successfully started.")}
                >
                  Scan my resources
                </button>
              </span>
            )}
            {status === ScanStatusEnum.Pending && (
              <span>
                <br />
                Scanning started at:{" "}
                <Moment format="HH:mm">
                  {data?.scanStatus?.createdAt || ""}
                </Moment>
                <br />
                Last update at:{" "}
                <Moment format="HH:mm">
                  {data?.scanStatus?.updatedAt || ""}
                </Moment>
              </span>
            )}
            {status === ScanStatusEnum.Done && (
              <>
                <Button
                  size="small"
                  className="is-pulled-right"
                  onClick={() =>
                    trigger("Resources scanning successfully initiated.")
                  }
                >
                  Rescan my resources
                </Button>
              </>
            )}
          </>
        )}
        {error && (
          <Button
            size="small"
            className="is-pulled-right"
            onClick={() =>
              trigger("Resources scanning successfully initiated.")
            }
          >
            Scan my resources
          </Button>
        )}
      </Loading>
    </div>
  );
};

export default ScanStatus;
