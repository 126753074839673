/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";

export const TableHead = styled.thead`
  border-bottom: 0.125rem solid #dadada;
`;

export const TableBody = styled.tbody``;

export const TableHeadData = styled.th`
  text-transform: uppercase;
  padding: 0.75rem 0.2rem;
  font-size: 0.9rem;
`;

export const TableRow = styled.tr`
  border-bottom: 0.0625rem solid #dadada;
`;

export const TableData = styled.td`
  padding: 0.75rem 0.2rem;
  font-size: 0.9rem;
  vertical-align: middle;
`;

export const StyledTable = styled.table`
  width: 100%;
  margin-top: 2rem;
`;
