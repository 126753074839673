/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { useContext, useEffect, useState } from "react";
import { CustomerContext } from "../../../context/customer";
import { Loading } from "../../../commonComponents/loading/Loading";
import {
  CloudProvider,
  FilteredDataType,
  FilterInput,
  useGetResourcesQuery,
} from "../../../generated/graphql";
import Table from "../../../commonComponents/table/Table";
import ReactTooltip from "react-tooltip";
import Pagination from "../../../commonComponents/pagination/Pagination";
import Placeholder from "../../../commonComponents/table/Placeholder";
import { Icon } from "../../../commonComponents/icon/Icon";
import { truncate } from "../../../utils/helpers";
import { filterValues } from "../../../utils/constants";
import Popup from "react-popup";
import Filters from "../../../commonComponents/filters/Filters";

const PAGE_SIZE = 50;

interface Props {
  resourceSetter: (resourceId: string) => void;
  cloudProvider: CloudProvider;
}

const TemplateResources: React.FC<Props> = ({
  resourceSetter,
  cloudProvider,
}) => {
  const { customer } = useContext(CustomerContext);
  const customerId = customer?.id || "";

  const defaultFilters = [
    { id: filterValues.backupEnabled, values: ["true"] },
    { id: filterValues.provider, values: [cloudProvider] },
  ];
  const [filters, setFilters] = useState<Array<FilterInput>>(defaultFilters);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalNumberOfPages, setTotalNumberOfPages] = useState<
    number | undefined
  >(0);
  const [totalNumberOfResources, setTotalNumberOfResources] = useState<
    number | undefined
  >(0);
  const [resources, setResources] = useState<any>([]);

  const nextPage = () => {
    if (totalNumberOfPages && currentPage < totalNumberOfPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const getResourcesNumber = () => {
    if (resources) {
      if (resources.length < PAGE_SIZE && currentPage === 1) {
        return resources.length;
      }
      if (resources.length < PAGE_SIZE && currentPage === totalNumberOfPages) {
        return PAGE_SIZE * (totalNumberOfPages - 1) + resources.length;
      }
      return PAGE_SIZE * currentPage;
    }
  };

  const { data, loading } = useGetResourcesQuery({
    variables: {
      searchCriteria: {
        customerId: customerId,
        filters: filters,
        pageNumber: currentPage,
        showRemoved: false,
      },
    },
  });

  useEffect(() => {
    setTotalNumberOfResources(data?.resources?.totalNumberOfResources);
    setTotalNumberOfPages(data?.resources?.totalNumberOfPages);
    setResources(
      data?.resources?.data?.map((resourceWithBackupStatus: any) => {
        return {
          ...resourceWithBackupStatus.resource,
          ...resourceWithBackupStatus.lastBackupStatus,
        };
      })
    );
  }, [data]);

  const applyAllFilters = (newFilters: Array<FilterInput>) => {
    setFilters(newFilters);
    setCurrentPage(1);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Resource ID",
        accessor: "id",
        Cell: ({ row }: any) => (
          <div>
            <div style={{ fontWeight: "bold" }}>
              <a
                onClick={() => {
                  resourceSetter(row.original.id);
                  Popup.close();
                }}
                className="navbar-item"
              >
                {truncate(row.original.id)}
              </a>
            </div>
            <div className="is-flex" style={{ fontSize: ".75rem" }}>
              <div style={{ marginRight: ".5rem" }}>
                <b>Type:</b> {row.original.type}
              </div>
              <div>
                <b>Category:</b> {row.original.category}
              </div>
            </div>
          </div>
        ),
      },
      {
        Header: "Provider",
        accessor: "cloudProvider",
        Cell: ({ row }: any) => (
          <div style={{ padding: ".5rem" }}>
            {row.original.cloudProvider && (
              <Icon
                height="1.75rem"
                image={row.original.cloudProvider}
                width="1.75rem"
              />
            )}
          </div>
        ),
      },
      {
        Header: "Account",
        accessor: "accountName",
      },
    ],
    []
  );

  return (
    <div className="box">
      <div className="columns is-vcentered border-bottom">
        <div className="column">
          <h1 className="title is-marginless is-5 is-capitalized">
            Resources with enabled backup
          </h1>
        </div>
      </div>
      <Loading loading={loading}>
        <Filters
          dataType={FilteredDataType.TemplateResources}
          applyFiltersHandler={applyAllFilters}
          appliedFilters={filters}
          size="is-4"
        />
        {resources && resources.length > 0 ? (
          <>
            <Table columns={columns} data={resources} disableSortBy />
            <ReactTooltip effect="solid" />
            <Pagination
              totalItems={totalNumberOfResources || 0}
              totalPages={totalNumberOfPages || 0}
              currentPage={currentPage}
              showing={getResourcesNumber()}
              changePage={setCurrentPage}
              previousPage={previousPage}
              nextPage={nextPage}
            />
          </>
        ) : (
          <Placeholder message="No data found." />
        )}
      </Loading>
    </div>
  );
};

export default TemplateResources;
