/**
 * Copyright 2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { GraphQLError } from "graphql/error/GraphQLError";
import React from "react";

export type GraphQLErrorCustom = GraphQLError & {
  message?: string;
  errorInfo?: string;
};

type GraphqlErrorProps = {
  graphqlValidationErrors: Array<GraphQLErrorCustom>;
  errorInfo: string;
};

const GraphqlError = ({
  graphqlValidationErrors,
  errorInfo,
}: GraphqlErrorProps) => {
  const error = graphqlValidationErrors?.find(
    (e) => e.errorInfo === errorInfo
  )?.message;
  if (error) {
    return <p className="validation-error">{error}</p>;
  }

  return null;
};

export default GraphqlError;
