/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  CustomerAccess,
  useGetCustomersListQuery,
  useGetUserQuery,
  useGrantCustomerRoleToUserMutation,
  useRevokeCustomerRoleFromUserMutation,
} from "../../../generated/graphql";
import CustomersTable from "../../../shared/customers/CustomersTable";
import { Error } from "../../../commonComponents/error/error";
import { ROUTES } from "../../../routes/routes";
import queryString from "query-string";
import { unstable_batchedUpdates } from "react-dom";
import Popup from "react-popup";
import DetailsNotFound from "../../../commonComponents/detailsNotFound/DetailsNotFound";
import UserApiKeysTable from "./UserApiKeysTable";
import CustomersUpdateRoleModal from "../../../shared/customers/CustomersUpdateRoleModal";
import CustomersGrantRoleModal from "../../../shared/customers/CustomersGrantRoleModal";
import UserBasicInformation from "./UserBasicInformation";
import Button from "../../../commonComponents/button/Button";

const UserDetails: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const { email: email = "" } = useParams();
  const [grantModalIsOpen, setGrantModalIsOpen] = useState<boolean>(false);
  const [updateModalIsOpen, setUpdateModalIsOpen] = useState<boolean>(false);
  const [customerData, setCustomerData] = useState<CustomerAccess>();

  const filtersFromUrl = () => {
    if (location.search === "") return "";

    const params = queryString.parse(location.search);
    return JSON.parse(params.filters as string);
  };

  const [filters, setFilters] = useState<string>(filtersFromUrl);

  const pageFromUrl = (): number => {
    if (location.search === "") return 1;

    const params = queryString.parse(location.search);
    const pageUrl = params.page;
    if (pageUrl) {
      return +pageUrl;
    }

    return 1;
  };

  const [currentPage, setCurrentPage] = useState<number>(pageFromUrl);

  useEffect(() => {
    const search = `?filters=${JSON.stringify(filters)}&page=${currentPage}`;
    if (location.search === search) return;

    if (location.search === "") {
      history.replace({ search });
    } else {
      history.push({ search });
    }
  }, [filters, currentPage]);

  useEffect(() => {
    unstable_batchedUpdates(() => {
      setFilters(filtersFromUrl());
      setCurrentPage(pageFromUrl());
    });
  }, [location]);

  const { data, error, refetch, loading } = useGetUserQuery({
    variables: {
      email,
      ...(filters && { customerId: filters }),
    },
  });

  const { user } = data || {};

  const { data: customersList, loading: customersLoading } =
    useGetCustomersListQuery();

  return (
    <div className="main wide">
      <div className="container is-fluid container-padding ">
        {error && <Error error={error} />}
        {user ? (
          <>
            <UserBasicInformation
              user={user}
              email={email}
              refetch={refetch}
              loading={loading}
            />
            <div className="box is-clearfix">
              <CustomersTable
                customersData={user.customerAccesses}
                history={history}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                setFilters={setFilters}
                filters={filters}
                loading={loading}
                setCustomerData={setCustomerData}
                isModalOpen={updateModalIsOpen}
                setIsModalOpen={setUpdateModalIsOpen}
                customersList={customersList}
                customersLoading={customersLoading}
                property="customersList"
              />
              <Button onClick={() => setGrantModalIsOpen(true)}>
                Grant customers accesses
              </Button>
            </div>
            <Popup />
            {grantModalIsOpen && (
              <CustomersGrantRoleModal
                setIsOpen={setGrantModalIsOpen}
                customersData={user.customerAccesses.data}
                mutation={useGrantCustomerRoleToUserMutation}
                identifier={email}
                type="email"
                customersList={customersList}
                customersLoading={customersLoading}
                property="customersList"
                refetch={refetch}
              />
            )}
            {updateModalIsOpen && customerData && (
              <CustomersUpdateRoleModal
                setIsOpen={setUpdateModalIsOpen}
                customerData={customerData}
                identifier={email}
                type="email"
                mutationGrant={useGrantCustomerRoleToUserMutation}
                mutationRevoke={useRevokeCustomerRoleFromUserMutation}
                refetch={refetch}
              />
            )}
            <UserApiKeysTable owner={email} />
          </>
        ) : (
          <DetailsNotFound
            message={error?.graphQLErrors
              .map((gqlError) => gqlError.message)
              .join(", ")}
            route={ROUTES.users.index.generate()}
          />
        )}
      </div>
    </div>
  );
};

export default UserDetails;
