/**
 * Copyright 2021-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { useMemo } from "react";
import { ROUTES } from "../../../routes/routes";
import Moment from "react-moment";
import { useHistory } from "react-router-dom";

const useApiKeysColumns = () => {
  const history = useHistory();

  const columns = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
        Cell: ({ row }: any) => {
          const { id } = row.original;
          return (
            <div>
              <div
                className="navbar-item is-hoverable"
                onClick={(e) => {
                  e.preventDefault();
                  history.push(ROUTES.apiKeys.apiKey.generate({ id }));
                }}
              >
                <span>{id}</span>
              </div>
            </div>
          );
        },
      },
      {
        Header: "Alias",
        accessor: "alias",
      },
      {
        Header: "Owner",
        accessor: "owner",
      },
      {
        Header: "Customer accesses",
        accessor: "totalNumberOfResources",
        Cell: ({ row }: any) => {
          const { customerAccesses } = row.original;
          return <span>{customerAccesses?.data.length}</span>;
        },
      },
      {
        Header: "Created at",
        accessor: "createdAt",
        Cell: ({ row }: any) => {
          const { createdAt } = row.original;
          return (
            <div style={{ fontWeight: "bold" }}>
              <Moment format="DD-MM-YYYY HH:mm">{createdAt}</Moment>
            </div>
          );
        },
      },
      {
        Header: "Last used at",
        accessor: "lastUsedAt",
        Cell: ({ row }: any) => {
          const { lastUsedAt } = row.original;
          if (!lastUsedAt) {
            return <div style={{ fontWeight: "bold" }}>Not used yet</div>;
          }
          return (
            <div style={{ fontWeight: "bold" }}>
              <Moment format="DD-MM-YYYY HH:mm">{lastUsedAt}</Moment>
            </div>
          );
        },
      },
    ],
    [history]
  );

  return { columns };
};

export default useApiKeysColumns;
