/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from "react";
import { PieChart, Pie, ResponsiveContainer, Cell, Tooltip } from "recharts";
import { COLORS, filterValues } from "../../../utils/constants";
import { countPercentage } from "../../../utils/helpers";
import { v4 as uuid } from "uuid";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../routes/routes";

type DataUnderProtection = {
  totalAmount: string;
  protectedAmount: string;
};

const formatSizeUnits = (bytes: number, decimals?: any) => {
  if (bytes === 0) return "0 B";

  const k = 1024,
    dm = decimals <= 0 ? 0 : decimals || 2,
    sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

const DataUnderProtection: React.FC<DataUnderProtection> = ({
  totalAmount,
  protectedAmount,
}) => {
  const nTotalAmount = Number(totalAmount);
  const nProtectedAmount = Number(protectedAmount);
  const percentageData = countPercentage(nTotalAmount, nProtectedAmount);
  const formatedPercentage = `${percentageData}%`;
  const [protectedAmountData, formatedProtectedAmount] = [
    nProtectedAmount,
    formatSizeUnits(nProtectedAmount),
  ];
  const [unprotectedAmountData, formatedUnprotectedAmount] = [
    nTotalAmount - nProtectedAmount,
    formatSizeUnits(nTotalAmount - nProtectedAmount),
  ];
  const data = [
    {
      name: "Protected",
      value: protectedAmountData,
      color: COLORS.success,
    },
    {
      name: "Unprotected",
      value: unprotectedAmountData,
      color: COLORS.failure,
    },
  ];
  const isMaxDataUnderProtection = percentageData === 100;
  const isMinDataUnderProtection = percentageData === 0;
  const backupDisabledFilter = JSON.stringify([
    {
      id: filterValues.backupEnabled,
      values: ["false"],
    },
  ]);
  const backupEnabledFilter = JSON.stringify([
    {
      id: filterValues.backupEnabled,
      values: ["true"],
    },
  ]);

  return (
    <div>
      <div className="box is-clearfix has-text-centered">
        <div className="chart chart--centered">
          <ResponsiveContainer width="100%">
            <PieChart>
              <Pie
                data={data}
                cx="50%"
                cy="50%"
                innerRadius={60}
                outerRadius={80}
                paddingAngle={
                  isMaxDataUnderProtection || isMinDataUnderProtection ? 0 : 3
                }
                dataKey="value"
              >
                {data.map((_, index) => (
                  <Cell key={uuid()} fill={_.color} />
                ))}
              </Pie>
              <Tooltip
                formatter={(value: any) => {
                  return formatSizeUnits(Number(value));
                }}
              />
            </PieChart>
          </ResponsiveContainer>
        </div>
        <div
          className="chart-indicator"
          style={{
            color: COLORS.label,
          }}
        >
          {formatedPercentage}
        </div>
        <div className="columns">
          <div className="column">
            <label style={{ fontSize: ".75rem" }}>Protected:</label>
            <Link
              to={ROUTES.resources.index.generate(
                {},
                { filters: backupEnabledFilter }
              )}
            >
              <div className="label" style={{ color: COLORS.success }}>
                {formatedProtectedAmount}
              </div>
            </Link>
          </div>
          <div className="column">
            <label style={{ fontSize: ".75rem" }}>Unprotected:</label>
            <Link
              to={ROUTES.resources.index.generate(
                {},
                { filters: backupDisabledFilter }
              )}
            >
              <div className="label" style={{ color: COLORS.failure }}>
                {formatedUnprotectedAmount}
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataUnderProtection;
