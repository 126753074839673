/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from "react";

const RemoveEmailsButton: React.FC<any> = ({ recipients, setter, index }) => (
  <a
    className="is-pulled-right"
    onClick={() => {
      setter(recipients.filter((email: string) => email !== recipients[index]));
    }}
  >
    Remove email
  </a>
);

export default RemoveEmailsButton;
