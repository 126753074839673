/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { generatePath } from "react-router-dom";

const generateRoute = (route: string) => {
  return {
    route: route,
    generate(
      args?: { [key: string]: string },
      params?: { [key: string]: string }
    ) {
      let basePath = generatePath(this.route, args);
      if (!params) {
        return basePath;
      }

      const queryString = Object.keys(params)
        .map((key) => `${key}=${encodeURIComponent(params[key])}`)
        .join("&");

      return `${basePath}?${queryString}`;
    },
  };
};

export const ROUTES = {
  index: generateRoute("/"),
  accounts: {
    index: generateRoute("/accounts"),
    protected: generateRoute("/accounts/protected"),
    vault: generateRoute("/accounts/vault"),
  },
  resources: {
    index: generateRoute("/resources"),
    removed: generateRoute("/resources/removed"),
    external: generateRoute("/resources/external"),
  },
  events: {
    index: generateRoute("/events"),
    event: generateRoute("/events/:id"),
  },
  disasterRecovery: {
    index: generateRoute("/disaster-recovery"),
    templates: {
      index: generateRoute("/disaster-recovery/templates"),
      create: generateRoute("/disaster-recovery/templates/create"),
      details: generateRoute("/disaster-recovery/templates/:id"),
    },
    records: {
      index: generateRoute("/disaster-recovery/records"),
    },
    plans: {
      index: generateRoute("/disaster-recovery/plans"),
      create: generateRoute("/disaster-recovery/plans/create"),
      details: generateRoute("/disaster-recovery/plans/:id"),
      testing: generateRoute("/disaster-recovery/plans/:id/testing"),
    },
  },
  settings: {
    index: generateRoute("/settings"),
    profile: {
      index: generateRoute("/settings/profile"),
    },
    notificationGroups: {
      index: generateRoute("/settings/notification-groups"),
      details: generateRoute("/settings/notification-groups/:id"),
      create: generateRoute("/settings/notification-groups/create"),
    },
    setups: {
      index: generateRoute("/settings/setups"),
      details: generateRoute("/settings/setups/:id"),
      create: generateRoute("/settings/setups/create"),
    },
    policies: {
      index: generateRoute("/settings/policies"),
      details: generateRoute("/settings/policies/:id"),
      create: generateRoute("/settings/policies/create"),
    },
    tags: {
      index: generateRoute("/settings/tags"),
    },
  },
  notes: {
    index: generateRoute("/notes"),
    note: generateRoute("/notes/:id"),
  },
  users: {
    index: generateRoute("/users"),
    user: generateRoute("/users/:email"),
  },
  apiKeys: {
    index: generateRoute("/api-keys"),
    apiKey: generateRoute("/api-keys/:id"),
  },
  myApiKeys: {
    index: generateRoute("/my-api-keys"),
    myApiKey: generateRoute("/my-api-keys/:id"),
  },
  customerAccesses: {
    index: generateRoute("/customer-accesses"),
    users: generateRoute("/customer-accesses/users"),
    apiKeys: generateRoute("/customer-accesses/api-keys"),
  },
};
