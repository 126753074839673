/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import App from "../App";
import { ResourcesPageLayout } from "../views/resources/components/ResourcesPageLayout";
import AccountsForm from "../views/accounts/containers/AccountsForm";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { DashboardPage } from "../views/dashboard";
import { AccountsPage } from "../views/accounts";
import {
  ExternalResourcesPage,
  ResourcesPage,
} from "../views/resources/containers";
import {
  NotificationGroupPage,
  PoliciesPage,
  ProfilePage,
  SettingsFormContainer,
  SettingsPageLayout,
  SetupsPage,
  TagsPage,
} from "../views/settings/containers";
import PolicyDetails from "../views/settings/components/PolicyDetails";
import SetupDetails from "../views/settings/components/SetupDetails";
import ReleaseNoteDetails from "../views/notes/ReleaseNoteDetails";
import EventDetails from "../views/events/components/EventDetails";
import EventsPage from "../views/events/containers/EventsPage";
import ReleaseNotesList from "../views/notes/ReleaseNotesList";
import {
  DisasterRecoveryPageLayout,
  RecordsPage,
  TemplatesPage,
} from "../views/disasterRecovery/containers";
import React from "react";
import { ROUTES } from "./routes";
import { DisasterRecoveryFormContainer } from "../views/disasterRecovery/containers/DisasterRecoveryFormContainer";
import TemplateDetails from "../views/disasterRecovery/components/TemplateDetails";
import { PlansPage } from "../views/disasterRecovery/containers/PlansPage";
import PlanDetails from "../views/disasterRecovery/components/PlanDetails";
import PlansTesting from "../views/disasterRecovery/components/PlansTesting";
import UserDetails from "../views/users/components/UserDetails";
import UsersPage from "../views/users";
import ProtectedRoute from "../commonComponents/auth/ProtectedRoute";
import { UserRole } from "../generated/graphql";
import ApiKeysPage from "../views/apiKeys/containers/ApiKeysPage";
import ApiKeyDetails from "../views/apiKeys/components/apiKeyDetails/ApiKeyDetails";
import MyApiKeysPage from "../views/myApiKeys/containers/MyApiKeysPage";
import MyApiKeyDetails from "../views/myApiKeys/components/MyApiKeyDetails";
import { NotificationGroupDetails } from "../views/settings/components/NotificationGroupDetails";
import CreateNotificationGroup from "../views/settings/components/CreateNotificationGroup";
import {
  CustomerAccessesApiKeysPage,
  CustomerAccessesUsersPage,
} from "../views/customerAcceses/containers";
import { CustomerAccessesLayout } from "../views/customerAcceses/containers/CustomerAccessesLayout";

const Routes = () => (
  <Router>
    <App>
      <Switch>
        <Route exact path={ROUTES.index.route} component={DashboardPage} />
        <Route
          exact
          path={ROUTES.accounts.index.route}
          component={AccountsPage}
        />
        <Route
          path={ROUTES.accounts.protected.route}
          component={() => <AccountsForm typeOfAccount={"protected"} />}
        />
        <Route
          exact
          path={ROUTES.accounts.vault.route}
          component={() => <AccountsForm typeOfAccount={"vault"} />}
        />
        <Route path={ROUTES.resources.index.route}>
          <ResourcesPageLayout>
            <Route
              exact
              path={ROUTES.resources.index.route}
              component={ResourcesPage}
            />
            <Route
              exact
              path={ROUTES.resources.external.route}
              component={ExternalResourcesPage}
            />
            <Route
              exact
              path={ROUTES.resources.removed.route}
              component={ResourcesPage}
            />
          </ResourcesPageLayout>
        </Route>
        <Route path={ROUTES.disasterRecovery.index.route}>
          <DisasterRecoveryPageLayout>
            <Switch>
              <Route
                exact
                path={[
                  ROUTES.disasterRecovery.plans.index.route,
                  ROUTES.disasterRecovery.index.route,
                ]}
                component={PlansPage}
              />
              <Route
                exact
                path={ROUTES.disasterRecovery.plans.create.route}
                component={DisasterRecoveryFormContainer}
              />
              <Route
                exact
                path={ROUTES.disasterRecovery.plans.details.route}
                component={PlanDetails}
              />
              <Route
                exact
                path={ROUTES.disasterRecovery.plans.testing.route}
                component={PlansTesting}
              />
              <Route
                exact
                path={ROUTES.disasterRecovery.templates.create.route}
                component={DisasterRecoveryFormContainer}
              />
              <Route
                exact
                path={ROUTES.disasterRecovery.templates.index.route}
                component={TemplatesPage}
              />
              <Route
                exact
                path={ROUTES.disasterRecovery.templates.details.route}
                component={TemplateDetails}
              />
              <Route
                exact
                path={ROUTES.disasterRecovery.records.index.route}
                component={RecordsPage}
              />
            </Switch>
          </DisasterRecoveryPageLayout>
        </Route>
        <Route path={ROUTES.settings.index.route}>
          <SettingsPageLayout>
            <Switch>
              <Route
                exact
                path={[
                  ROUTES.settings.profile.index.route,
                  ROUTES.settings.index.route,
                ]}
                component={ProfilePage}
              />
              <Route
                exact
                path={ROUTES.settings.notificationGroups.index.route}
                component={NotificationGroupPage}
              />
              <Route
                exact
                path={ROUTES.settings.notificationGroups.create.route}
                component={CreateNotificationGroup}
              />
              <Route
                exact
                path={ROUTES.settings.notificationGroups.details.route}
                component={NotificationGroupDetails}
              />
              <Route
                exact
                path={ROUTES.settings.notificationGroups.create.route}
                component={SettingsFormContainer}
              />
              <Route
                exact
                path={ROUTES.settings.setups.index.route}
                component={SetupsPage}
              />
              <Route
                exact
                path={ROUTES.settings.setups.create.route}
                component={SettingsFormContainer}
              />
              <Route
                exact
                path={ROUTES.settings.setups.details.route}
                component={SetupDetails}
              />
              <Route
                exact
                path={ROUTES.settings.policies.index.route}
                component={PoliciesPage}
              />
              <Route
                exact
                path={ROUTES.settings.policies.create.route}
                component={SettingsFormContainer}
              />
              <Route
                exact
                path={ROUTES.settings.policies.details.route}
                component={PolicyDetails}
              />
              <Route
                exact
                path={ROUTES.settings.tags.index.route}
                component={TagsPage}
              />
            </Switch>
          </SettingsPageLayout>
        </Route>
        <Route path={ROUTES.events.index.route}>
          <Route
            exact
            path={ROUTES.events.index.route}
            component={EventsPage}
          />
          <Route
            exact
            path={ROUTES.events.event.route}
            component={EventDetails}
          />
        </Route>
        <Route path={ROUTES.notes.index.route}>
          <Route
            exact
            path={ROUTES.notes.index.route}
            component={ReleaseNotesList}
          />
          <Route
            exact
            path={ROUTES.notes.note.route}
            component={ReleaseNoteDetails}
          />
        </Route>
        <ProtectedRoute
          path={ROUTES.customerAccesses.index.route}
          requiredRoles={[UserRole.SuperAdmin]}
        >
          <CustomerAccessesLayout>
            <Route
              exact
              path={[
                ROUTES.customerAccesses.index.route,
                ROUTES.customerAccesses.users.route,
              ]}
              component={CustomerAccessesUsersPage}
            />
            <Route
              exact
              path={ROUTES.customerAccesses.apiKeys.route}
              component={CustomerAccessesApiKeysPage}
            />
          </CustomerAccessesLayout>
        </ProtectedRoute>
        <ProtectedRoute
          path={ROUTES.users.index.route}
          requiredRoles={[UserRole.SuperAdmin]}
        >
          <Route exact path={ROUTES.users.index.route} component={UsersPage} />
          <Route exact path={ROUTES.users.user.route} component={UserDetails} />
        </ProtectedRoute>
        <ProtectedRoute
          path={ROUTES.apiKeys.index.route}
          requiredRoles={[UserRole.SuperAdmin]}
        >
          <Route
            exact
            path={ROUTES.apiKeys.index.route}
            component={ApiKeysPage}
          />
          <Route
            exact
            path={ROUTES.apiKeys.apiKey.route}
            component={ApiKeyDetails}
          />
        </ProtectedRoute>
        <Route path={ROUTES.myApiKeys.index.route}>
          <Route
            exact
            path={ROUTES.myApiKeys.index.route}
            component={MyApiKeysPage}
          />
          <Route
            exact
            path={ROUTES.myApiKeys.myApiKey.route}
            component={MyApiKeyDetails}
          />
        </Route>
      </Switch>
    </App>
  </Router>
);

export default Routes;
