/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from "react";
import styled, { css } from "styled-components";

interface ButtonProps {
  children: any;
  onClick?: () => void;
  size?: string;
  margin?: string;
  color?: string;
  select?: boolean;
  outline?: boolean;
  className?: string;
  success?: boolean;
  warning?: boolean;
  disabled?: boolean;
}

const setColor = (color?: string) => (color ? color : "#2e2e47");

const StyledButton = styled.button<ButtonProps>`
  background-color: #2e2e47;
  border: 1px solid #2e2e47;
  color: #ffffff;
  padding: 0.5rem 1.75rem;
  border-radius: 0.25rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
  font-size: 1rem;
  transition: all 0.1s linear;

  &.has-margin-right {
    margin-right: 10px;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}

  ${({ success }) =>
    success &&
    css`
      background-color: #27ae5f;
      border: 1px solid #27ae5f;
    `}

      ${({ warning }) =>
    warning &&
    css`
      background-color: #f39c12;
      border: 1px solid #f39c12;
    `} 
    
    ${({ size }) =>
    size === "small" &&
    css`
      padding: 0.25rem 0.75rem;
      font-size: 0.75rem;
    `} 
    
    ${({ margin }) =>
    margin &&
    css`
      margin: ${margin};
    `} 
    
    ${({ size }) =>
    size === "extra-small" &&
    css`
      padding: 0.125rem 0.35rem;
      font-size: 0.7rem;
    `} 
    
    &:focus {
    outline: 0;
  }

  &:hover,
  .active {
    cursor: pointer;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.2);
  }

  ${({ outline }) =>
    outline &&
    css`
      background-color: #ffffff;
      border: 1px solid #2e2e47;
      color: #2e2e47;

      &:hover,
      &.active {
        cursor: pointer;
      }
    `}

  ${({ select, color }) =>
    select &&
    css`
      background-color: #e1e1e5;
      border: 1px solid #bababa;
      color: #777777;
      padding: 0.5rem 1.5rem;
      border-radius: 0.25rem;
      font-weight: normal;
      font-size: 1rem;

      &:hover {
        border: 1px solid ${setColor(color)};
      }

      &.active {
        cursor: pointer;
        color: #ffffff;
        background-color: ${setColor(color)};
        border: 1px solid ${setColor(color)};
      }
    `}
`;

const Button: React.FC<ButtonProps> = (props) => {
  return (
    <StyledButton
      onClick={props.onClick}
      color={setColor(props.color)}
      {...props}
    >
      {props.children}
    </StyledButton>
  );
};

export default Button;
