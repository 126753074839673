/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { ReactNode } from "react";
import { Maybe } from "../generated/graphql";

type Customer = {
  name: Maybe<String>;
  id: Maybe<string>;
};

interface CustomerContextInterface {
  customer: Maybe<Customer>;
  update(customer: Customer): any;
}

const CustomerContext = React.createContext<CustomerContextInterface>({
  customer: null,
  update: () => {},
});

interface CustomerProviderProps {
  children: ReactNode;
}

class CustomerProvider extends React.Component<
  CustomerProviderProps,
  Customer
> {
  state: Readonly<Customer> = {
    name: null,
    id: null,
  };

  setCustomer = (customer: Customer) => {
    this.setState(customer);
    localStorage.setItem("abCustomer", JSON.stringify(customer));
  };

  componentDidMount() {
    const cachedCustomer = localStorage.getItem("abCustomer");
    if (cachedCustomer) {
      this.setCustomer(JSON.parse(cachedCustomer));
    }
  }

  render() {
    const customer = this.state;
    const { children } = this.props;
    return (
      <CustomerContext.Provider value={{ customer, update: this.setCustomer }}>
        {children}
      </CustomerContext.Provider>
    );
  }
}

export { CustomerProvider, CustomerContext };
