/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { ReactNode } from "react";
import styled, { css } from "styled-components";

interface CardProps {
  children: ReactNode;
  variant?: String;
}

const StyledCard = styled.div<CardProps>`
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  padding: 1.5rem 2rem;
  margin-bottom: 1rem;
  align-items: flex-start;
  transition: all 0.2s ease-in;

  ${({ variant }) =>
    variant === "success" &&
    css`
      border: 1px solid #19703e;
      color: #19703e;

      .title {
        color: #19703e;
      }
    `}

  ${({ variant }) =>
    variant === "info" &&
    css`
      border: 1px solid #0c5460;
      color: #0c5460;
      background-color: #fff3cd;

      strong {
        color: #0c5460;
      }

      .title {
        color: #0c5460;
      }
    `} 
    
    .description {
    font-size: 0.75rem;
  }

  .title {
    font-weight: bold;
    margin-bottom: 0.5rem;
    font-size: 1.25rem;
  }
`;

const InfoCard: React.FC<CardProps> = (props) => (
  <StyledCard {...props}>{props.children}</StyledCard>
);

export default InfoCard;
