/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { Dispatch, SetStateAction, useState } from "react";
import { Modal } from "../../commonComponents/modal/Modal";
import Button from "../../commonComponents/button/Button";
import { toast } from "react-toastify";
import { toastMessages } from "../../utils/constants";

interface ApiKeyStringModalProps {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  data: string;
}

const ApiKeyStringModal: React.FC<ApiKeyStringModalProps> = ({
  setIsOpen,
  data,
}) => {
  const [isCopied, setIsCopied] = useState(false);
  const [copiedText, setCopiedText] = useState<string>("Copy API key");

  const copyTextHandler = async () => {
    try {
      await navigator.clipboard.writeText(data);
      toast.success(toastMessages.COPY_API_KEY_TEXT);
      setCopiedText("API key copied");
      setIsCopied(true);
    } catch (e) {
      toast.error(toastMessages.COPY_API_KEY_TEXT_ERROR);
      setIsCopied(false);
      setCopiedText("Copy API key");
    }
  };

  return (
    <Modal onHide={() => setIsOpen(false)} title="Generated the API key">
      <div style={{ marginBottom: "1.5rem" }}>
        <div style={{ color: "green", fontSize: "1.25rem" }}>Success</div>
        <div style={{ fontSize: "0.9rem" }}>
          This is the <span style={{ fontWeight: "bolder" }}>only</span> time
          that secret access keys can be viewed. You cannot recover them later.
        </div>
      </div>
      <div className="field">
        <label className="label">API KEY</label>
        <table style={{ tableLayout: "fixed" }}>
          <tbody>
            <tr>
              <td className="wrap-word">{data}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <Button onClick={copyTextHandler}>
        <span style={{ marginRight: "0.5rem" }}>{copiedText}</span>
        {isCopied ? (
          <i className="fa fa-check" />
        ) : (
          <i className="fa fa-copy" />
        )}
      </Button>
    </Modal>
  );
};

export default ApiKeyStringModal;
