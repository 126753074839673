/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useContext, useEffect, useState } from "react";
import { CustomerContext } from "../context/customer";
import { useGetUserRoleQuery, UserRole } from "../generated/graphql";

const useUserRole = () => {
  const { customer } = useContext(CustomerContext);
  const [role, setRole] = useState<UserRole | undefined | null>(undefined);

  const customerId = customer && customer?.id ? customer.id : "";
  const { data: userRoleData } = useGetUserRoleQuery({
    variables: {
      customerId: customerId,
    },
  });

  useEffect(() => {
    setRole(userRoleData?.userRole);
  }, [customer, userRoleData]);
  return role;
};

export default useUserRole;
