/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from "react";
import { useGetResourcesTypesQuery } from "../../generated/graphql";
import { Loading } from "../loading/Loading";
import Select from "react-select";

interface Props {
  onChange: (resourceType: string) => void;
}

const ResourceTypesSelect: React.FC<Props> = ({ onChange }) => {
  const { data, loading } = useGetResourcesTypesQuery();

  return (
    <Loading loading={loading}>
      {data && data.resourcesTypes && (
        <Select
          isClearable={false}
          onChange={(type: any) => onChange(type)}
          options={data.resourcesTypes.map((type) => ({
            value: type,
            label: type,
          }))}
        />
      )}
    </Loading>
  );
};

export default ResourceTypesSelect;
