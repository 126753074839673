/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from "react";

const messageTypeClass = (type: String): String => {
  switch (type.toLowerCase()) {
    case "critical": {
      return "is-danger";
    }
    case "warning": {
      return "is-warning";
    }
    case "info": {
      return "is-primary";
    }
    default: {
      return "is-primary";
    }
  }
};

const Message: React.FC<MessageProps> = ({ type, message, clickHandler }) => {
  return (
    <>
      <div className={`notification ${messageTypeClass(type)}`}>
        {type.toLowerCase() !== "critical" && clickHandler && (
          <button onClick={clickHandler} className="delete" />
        )}
        {message}
      </div>
    </>
  );
};

export default Message;
