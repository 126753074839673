/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { useContext } from "react";
import Popup from "react-popup";
import ProtectedAccounts from "../components/ProtectedAccounts";
import VaultAccounts from "../components/VaultAccounts";
import { CustomerContext } from "../../../context/customer";
import { useGetUserRoleQuery } from "../../../generated/graphql";

const AccountsPage: React.FC = () => {
  const { customer } = useContext(CustomerContext);
  const customerId = customer && customer.id ? customer.id : "";

  const { data: userRole, loading: userRoleLoading } = useGetUserRoleQuery({
    variables: {
      customerId: customerId,
    },
  });
  return (
    <div className="main wide">
      <div className="container is-fluid">
        <div className="columns">
          <div className="column">
            <ProtectedAccounts
              userRole={userRole}
              userRoleLoading={userRoleLoading}
            />
          </div>
          <div className="column">
            <VaultAccounts
              userRole={userRole}
              userRoleLoading={userRoleLoading}
            />
          </div>
        </div>
      </div>
      <Popup />
    </div>
  );
};

export default AccountsPage;
