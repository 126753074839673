/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import Select from "react-select";
import { CustomerBasic } from "../../generated/graphql";
import { Loading } from "../../commonComponents/loading/Loading";
import Segment from "../../commonComponents/segment/Segment";
import Button from "../../commonComponents/button/Button";

interface CustomersFilterProps {
  setFilters: Dispatch<SetStateAction<string>>;
  filters: string;
  list: any;
  loading: boolean;
  property: "accessibleCustomers" | "customersList";
}

const CustomersFilter = ({
  setFilters,
  filters,
  list,
  loading,
  property,
}: CustomersFilterProps) => {
  const [usedFilter, setUsedFilter] = useState<CustomerBasic>();

  useEffect(() => {
    if (list) {
      setUsedFilter(list[property].find((el: any) => el.id === filters));
    }
  }, [setUsedFilter, filters, list]);

  return (
    <div className="columns is-multiline">
      <Loading loading={loading}>
        {list && list[property] && (
          <div className="field column is-6">
            <label className="label">Filter by customer</label>
            <div className="control">
              <div className="columns">
                <div className="column">
                  <Select
                    isClearable={false}
                    value={
                      filters
                        ? {
                            value: usedFilter?.id,
                            label: usedFilter?.name,
                          }
                        : null
                    }
                    onChange={(customer: any) => {
                      setFilters(customer.value);
                    }}
                    options={list[property].map((type: any) => ({
                      value: type.id,
                      label: type.name,
                    }))}
                  />
                </div>
                <div className="column">
                  <Segment>
                    <Button onClick={() => setFilters("")}>
                      Clear filters
                    </Button>
                  </Segment>
                </div>
              </div>
            </div>
          </div>
        )}
      </Loading>
    </div>
  );
};
export default CustomersFilter;
