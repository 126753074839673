/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from "react";
import { PieChart, Pie, ResponsiveContainer, Cell, Tooltip } from "recharts";
import { COLORS, filterValues } from "../../../utils/constants";
import { countPercentage } from "../../../utils/helpers";
import { v4 as uuid } from "uuid";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../routes/routes";

type ResourcesWithBackupProps = {
  allResources: number;
  nWithoutBackup: number;
};

export const ResourcesWithBackup: React.FC<ResourcesWithBackupProps> = ({
  allResources,
  nWithoutBackup,
}) => {
  const resourcesWithBackupEnabled = allResources - nWithoutBackup;
  const percentageData = countPercentage(
    allResources,
    resourcesWithBackupEnabled
  );
  const data = [
    {
      name: "Resources with Backup Enabled",
      value: resourcesWithBackupEnabled,
      color: COLORS.success,
    },
    {
      name: "Resources without Backup Enabled",
      value: nWithoutBackup,
      color: COLORS.failure,
    },
  ];
  const backupDisabledFilter = JSON.stringify([
    {
      id: filterValues.backupEnabled,
      values: ["false"],
    },
  ]);
  const backupEnabledFilter = JSON.stringify([
    {
      id: filterValues.backupEnabled,
      values: ["true"],
    },
  ]);

  return (
    <div>
      <div className="box is-clearfix has-text-centered">
        <div className="chart chart--centered">
          <ResponsiveContainer width="100%">
            <PieChart>
              <Pie
                data={data}
                cx="50%"
                cy="50%"
                innerRadius={60}
                outerRadius={80}
                paddingAngle={resourcesWithBackupEnabled === 0 ? 0 : 3}
                dataKey="value"
              >
                {data.map((_, index) => (
                  <Cell key={uuid()} fill={_.color} />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </div>
        <div
          className="chart-indicator"
          style={{
            color: COLORS.label,
          }}
        >
          {`${percentageData}%`}
        </div>
        <div className="columns">
          <div className="column">
            <label style={{ fontSize: ".75rem" }}>Backup Enabled:</label>
            <Link
              to={ROUTES.resources.index.generate(
                {},
                { filters: backupEnabledFilter }
              )}
            >
              <div className="label" style={{ color: COLORS.success }}>
                {resourcesWithBackupEnabled}
              </div>
            </Link>
          </div>
          <div className="column">
            <label style={{ fontSize: ".75rem" }}>Backup Disabled:</label>
            <Link
              to={ROUTES.resources.index.generate(
                {},
                { filters: backupDisabledFilter }
              )}
            >
              <div className="label" style={{ color: COLORS.failure }}>
                {nWithoutBackup}
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
