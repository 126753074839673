/**
 * Copyright 2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ApolloError } from "apollo-client";
import {
  ApiKey,
  ApiKeysPage,
  CustomerAccess,
  Maybe,
  useGrantCustomerRoleToApiKeyMutation,
  useRevokeCustomerRoleFromApiKeyMutation,
} from "../../../generated/graphql";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { History } from "history";
import { Loading } from "../../../commonComponents/loading/Loading";
import { Error } from "../../../commonComponents/error/error";
import Table from "../../../commonComponents/table/Table";
import Placeholder from "../../../commonComponents/table/Placeholder";
import Pagination from "../../../commonComponents/pagination/Pagination";
import CustomersUpdateRoleModal from "../../../shared/customers/CustomersUpdateRoleModal";
import { ObservableQuery } from "apollo-client/core/ObservableQuery";
import Button from "../../../commonComponents/button/Button";
import AddNewAccessModal from "../../../shared/customers/AddNewAccessModal";
import { ApiKeysListColumns } from "./ApiKeysList";

interface Props {
  apiKeysData?: ApiKeysPage;
  apiKeysError?: ApolloError;
  apiKeysLoading: boolean;
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  history: History;
  refetch: ObservableQuery["refetch"];
  customerId: string;
}

const PAGE_SIZE = 50;

const CustomerAccessesApiKeysTable: React.FC<Props> = ({
  apiKeysData,
  apiKeysError,
  apiKeysLoading,
  currentPage,
  setCurrentPage,
  history,
  refetch,
  customerId,
}) => {
  const [apiKeys, setApiKeys] = useState<Maybe<Array<ApiKey>>>();
  const [createModalIsOpen, setCreateModalIsOpen] = useState<boolean>(false);
  const [updateModalIsOpen, setUpdateModalIsOpen] = useState<boolean>(false);
  const [customerData, setCustomerData] = useState<CustomerAccess>();
  const [apiKeyId, setApiKeyId] = useState<string>("");
  const [totalNumberOfApiKeys, setTotalNumberOfApiKeys] = useState<
    number | undefined
  >(0);
  const [totalNumberOfPages, setTotalNumberOfPages] = useState<
    number | undefined
  >(0);

  const nextPage = () => {
    if (totalNumberOfPages && currentPage < totalNumberOfPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const getApiKeysDataNumber = () => {
    if (apiKeys && apiKeys.length < PAGE_SIZE && currentPage === 1) {
      return apiKeys.length;
    }
    if (
      apiKeys &&
      apiKeys?.length < PAGE_SIZE &&
      currentPage === totalNumberOfPages
    ) {
      return PAGE_SIZE * (totalNumberOfPages - 1) + apiKeys.length;
    }
    return PAGE_SIZE * currentPage;
  };

  useEffect(() => {
    setTotalNumberOfApiKeys(apiKeysData?.totalNumberOfApiKeys);
    setTotalNumberOfPages(apiKeysData?.totalNumberOfPages);
    setApiKeys(apiKeysData?.data);
  }, [apiKeysData]);

  return (
    <div className="box is-clearfix">
      <div className="columns is-vcentered border-bottom">
        <div className="column is-7">
          <h1 className="title is-marginless is-5 is-capitalized">API Keys</h1>
        </div>
        <div className="column">
          <div style={{ float: "right" }}>
            <Button onClick={() => setCreateModalIsOpen(true)}>
              Add new access
            </Button>
          </div>
        </div>
      </div>
      <Loading loading={apiKeysLoading}>
        {apiKeysError && <Error error={apiKeysError} />}
        {apiKeys && apiKeys.length > 0 ? (
          <Table
            columns={ApiKeysListColumns({
              customerId,
              setApiKeyId,
              setUpdateModalIsOpen,
              setCustomerData,
              history,
            })}
            data={apiKeys}
            disableSortBy
          />
        ) : (
          <Placeholder message="No data found with current filters." />
        )}
        <Pagination
          totalItems={totalNumberOfApiKeys || 0}
          totalPages={totalNumberOfPages || 0}
          currentPage={currentPage}
          showing={getApiKeysDataNumber()}
          changePage={setCurrentPage}
          previousPage={previousPage}
          nextPage={nextPage}
        />
      </Loading>
      {updateModalIsOpen && customerData && (
        <CustomersUpdateRoleModal
          setIsOpen={setUpdateModalIsOpen}
          customerData={customerData}
          identifier={apiKeyId}
          type="id"
          mutationGrant={useGrantCustomerRoleToApiKeyMutation}
          mutationRevoke={useRevokeCustomerRoleFromApiKeyMutation}
          refetch={refetch}
        />
      )}
      {createModalIsOpen && (
        <AddNewAccessModal
          setIsOpen={setCreateModalIsOpen}
          customerId={customerId}
          type="id"
          mutationGrant={useGrantCustomerRoleToApiKeyMutation}
          refetch={refetch}
        />
      )}
    </div>
  );
};

export default CustomerAccessesApiKeysTable;
