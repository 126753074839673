/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { useContext, useEffect, useState } from "react";
import { Loading } from "../../../commonComponents/loading/Loading";
import { Error } from "../../../commonComponents/error/error";
import Button from "../../../commonComponents/button/Button";
import { CustomerContext } from "../../../context/customer";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Progress, toastMessages } from "../../../utils/constants";
import { setStatusAndProgress } from "../eventsShared";
import EventResources from "./EventResources";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import EventStats from "./EventStats";
import Moment from "react-moment";
import {
  BackupEventType,
  useGetBackupEventQuery,
  useGetBackupEventResourcesQuery,
} from "../../../generated/graphql";
import queryString from "query-string";
import { ROUTES } from "../../../routes/routes";
import DetailsNotFound from "../../../commonComponents/detailsNotFound/DetailsNotFound";

type RouteParams = {
  id: string;
};

const EventDetails: React.FC = () => {
  const customer = useContext(CustomerContext);
  const customerId = customer?.customer?.id || "";
  const location = useLocation();

  const [eventData, setEventData] = useState<{
    className: string;
    label: string;
  }>({
    className: "",
    label: "",
  });

  const { id: eventId = "" } = useParams<RouteParams>();
  const EVENT_ID_LENGTH = 15;

  const { data, error, loading } = useGetBackupEventQuery({
    variables: {
      searchCriteria: {
        customerId,
        eventId,
      },
    },
  });

  const { backupEvent } = data || {};

  useEffect(() => {
    if (backupEvent && backupEvent.status && backupEvent.progress) {
      const { status, progress } = backupEvent;
      const { className, label } = setStatusAndProgress({ status, progress });
      setEventData({ className, label });
    }
  }, [backupEvent]);

  const pageFromUrl = (): number => {
    if (location.search === "") return 1;

    const params = queryString.parse(location.search);
    const pageNumber = params.page;
    if (pageNumber) {
      return +pageNumber;
    }

    return 1;
  };
  const [currentPage, setCurrentPage] = useState<number>(pageFromUrl);
  const {
    data: resourcesData,
    error: resourcesError,
    loading: resourcesLoading,
  } = useGetBackupEventResourcesQuery({
    variables: {
      searchCriteria: {
        customerId: customerId,
        backupEventId: eventId,
        pageNumber: currentPage,
      },
    },
  });

  const formatType = (type: BackupEventType) => {
    switch (type) {
      case BackupEventType.Schedule:
        return "Schedule";
      case BackupEventType.OnDemand:
        return "On Demand";
    }
  };

  return (
    <div className="main wide">
      <Loading loading={loading}>
        {error && <Error error={error} />}
        {backupEvent ? (
          <div className="container is-fluid container-padding ">
            <div className="box">
              <div className="columns">
                <div className="column">
                  <strong className="is-desc-label">Event ID</strong>
                  <br />
                  <div style={{ fontWeight: "bold" }}>
                    {`...${eventId.substring(
                      eventId.length - EVENT_ID_LENGTH
                    )}`}
                    <CopyToClipboard
                      text={eventId}
                      onCopy={() => {
                        toast.success(toastMessages.EVENT_ID_COPIED);
                      }}
                    >
                      <Button outline size="extra-small" margin="0 .25rem">
                        <i className="fa fa-copy" /> copy
                      </Button>
                    </CopyToClipboard>
                  </div>
                </div>
                <div className="column">
                  <strong className="is-desc-label">Plan Name</strong>
                  <br />
                  {backupEvent.planName}
                </div>
                <div className="column">
                  <strong className="is-desc-label">
                    Start Time (local time)
                  </strong>
                  <br />
                  <Moment unix format="DD-MM-YYYY HH:mm">
                    {backupEvent.startTimestamp}
                  </Moment>
                </div>
                <div className="column">
                  <strong className="is-desc-label">Number of Resources</strong>
                  <br />
                  {backupEvent.totalNumberOfResources}
                </div>
              </div>
              <div className="columns">
                <div className="column is-one-quarter">
                  <strong className="is-desc-label">Status & Progress</strong>
                  <br />
                  <span className={`tag ${eventData.className}`}>
                    {eventData.label}
                  </span>
                </div>
                <div className="column is-one-quarter">
                  <strong className="is-desc-label">Type</strong>
                  <br />
                  {formatType(backupEvent.type)}
                </div>
              </div>
            </div>
            {backupEvent.progress === Progress.Finished && (
              <EventStats eventId={eventId} />
            )}
            <EventResources
              resourcesData={resourcesData?.backupEventResources}
              resourcesError={resourcesError}
              resourcesLoading={resourcesLoading}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        ) : (
          <DetailsNotFound
            message={"Backup Event with requested ID does not exist"}
            route={ROUTES.events.index.generate()}
          />
        )}
      </Loading>
    </div>
  );
};

export default EventDetails;
