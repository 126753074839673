/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from "react";

interface IPaginationProps {
  totalItems: number;
  totalPages: number;
  currentPage: number;
  showing: number;
  changePage: any;
  previousPage: any;
  nextPage: any;
}

const Pagination: React.FC<IPaginationProps> = ({
  showing,
  previousPage,
  nextPage,
  totalPages,
  totalItems,
  changePage,
  currentPage,
}) => {
  const OFFSET = 5;
  const ceil = Math.ceil(OFFSET / 2);
  const floor = Math.floor(OFFSET / 2);
  const isSmallCollection = totalPages <= OFFSET;

  let startIndex = 0;
  let endIndex = OFFSET;

  if (currentPage > ceil) {
    startIndex = currentPage - ceil;
    endIndex = currentPage + floor;
  }

  if (currentPage > totalPages - ceil) {
    startIndex = totalPages - OFFSET;
    endIndex = totalPages;
  }

  const PagesButtons = Array(totalPages)
    .fill(0)
    .map((_, index) => {
      const page = index + 1;
      return (
        <li onClick={() => changePage(page)} key={`page-${page}`}>
          <a
            style={{ display: "flex", justifyContent: "center" }}
            className={`pagination-link ${
              currentPage === page && "is-current"
            }`}
            aria-label={`Page ${page}`}
            aria-current="page"
          >
            {page}
          </a>
        </li>
      );
    });

  return (
    <nav className="pagination" style={{ marginTop: "1rem" }}>
      <p>{`Showing ${showing} of ${totalItems}`}</p>
      {totalPages > 1 && (
        <>
          <a className="pagination-previous" onClick={previousPage}>
            Previous page
          </a>
          <a className="pagination-next" onClick={nextPage}>
            Next page
          </a>
          <ul className="pagination-list">
            {!isSmallCollection && currentPage > ceil && (
              <>
                <li onClick={() => changePage(1)} key="page-1">
                  <a
                    style={{ display: "flex", justifyContent: "center" }}
                    className={`pagination-link ${
                      currentPage === 1 && "is-current"
                    }`}
                    aria-label="Page 1"
                    aria-current="page"
                  >
                    {1}
                  </a>
                </li>
                {startIndex !== 1 && <li>...</li>}
              </>
            )}

            {!isSmallCollection
              ? PagesButtons.slice(startIndex, endIndex)
              : PagesButtons}

            {!isSmallCollection && currentPage < totalPages - floor && (
              <>
                {endIndex !== totalPages - 1 && <li>...</li>}
                <li
                  onClick={() => changePage(totalPages)}
                  key={`page-${totalPages}`}
                >
                  <a
                    style={{ display: "flex", justifyContent: "center" }}
                    className={`pagination-link ${
                      currentPage === totalPages && "is-current"
                    }`}
                    aria-label={`Page ${totalPages}`}
                    aria-current="page"
                  >
                    {totalPages}
                  </a>
                </li>
              </>
            )}
          </ul>
        </>
      )}
    </nav>
  );
};

export default Pagination;
