/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { useContext, useState, useEffect } from "react";
import { useGetExternallyBackedUpResourcesQuery } from "../../../generated/graphql";
import { Loading } from "../../../commonComponents/loading/Loading";
import Pagination from "../../../commonComponents/pagination/Pagination";
import Popup from "react-popup";
import { CustomerContext } from "../../../context/customer";
import Table from "../../../commonComponents/table/Table";
import Placeholder from "../../../commonComponents/table/Placeholder";
import { Icon } from "../../../commonComponents/icon/Icon";
import { truncate } from "../../../utils/helpers";
import { toast } from "react-toastify";
import { toastMessages } from "../../../utils/constants";
import Button from "../../../commonComponents/button/Button";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReactTooltip from "react-tooltip";

interface Props {
  filters: any;
}

const ExternalResources: React.FC<Props> = ({ filters }) => {
  const { customer } = useContext(CustomerContext);
  const customerId = customer?.id || "";
  const [currentPage, setCurrentPage] = useState(1);
  const [totalNumberOfPages, setTotalNumberOfPages] = useState<
    number | undefined
  >(0);
  const [totalNumberOfResources, setTotalNumberOfResources] = useState<
    number | undefined
  >(0);
  const [resources, setResources] = useState<any>();
  const PAGE_SIZE = 50;

  const {
    data,
    loading,
    refetch: getResources,
  } = useGetExternallyBackedUpResourcesQuery({
    variables: {
      searchCriteria: {
        customerId: customerId,
        filters: filters,
        pageNumber: currentPage,
      },
    },
  });

  const changePage = (number: number) => {
    setCurrentPage(number);
    getResources().catch((e) => console.log(e));
  };

  const nextPage = () => {
    if (totalNumberOfPages && currentPage < totalNumberOfPages) {
      setCurrentPage(currentPage + 1);
      getResources().catch((e) => console.log(e));
    }
  };

  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      getResources().catch((e) => console.log(e));
    }
  };

  const getResourcesNumber = () => {
    if (resources.length < PAGE_SIZE && currentPage === 1) {
      return resources.length;
    }
    if (resources.length < PAGE_SIZE && currentPage === totalNumberOfPages) {
      return PAGE_SIZE * (totalNumberOfPages - 1) + resources.length;
    }
    return PAGE_SIZE * currentPage;
  };

  useEffect(() => {
    setTotalNumberOfResources(
      data?.externallyBackedUpResources?.totalNumberOfResources
    );
    setTotalNumberOfPages(
      data?.externallyBackedUpResources?.totalNumberOfPages
    );
    setResources(data?.externallyBackedUpResources?.data);
  }, [data]);

  useEffect(() => {
    setCurrentPage(1);
  }, [filters]);

  const showErrorPopup = (message: string) => {
    Popup.create({
      onClose: () => {
        Popup.close();
      },
      title: "Backup status error",
      content: (
        <div className="box">
          <div>
            <label className="label">Error message:</label>
            <p>{message}</p>
          </div>
        </div>
      ),
      buttons: {
        right: [
          {
            text: "Close",
            className: "btn",
            action: async () => {
              Popup.close();
            },
          },
        ],
      },
    });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "",
        accessor: "backupEnabled",
        Cell: ({ row }: any) => (
          <div style={{ padding: ".5rem" }}>
            {row.original.backupEnabled ? (
              <Icon
                height="1.75rem"
                image="STATUS_ENABLED"
                width="1.75rem"
                data-tip="Backup enabled"
              />
            ) : (
              <Icon
                height="1.75rem"
                image="STATUS_DISABLED"
                width="1.75rem"
                data-tip="Backup disabled"
              />
            )}
          </div>
        ),
      },
      {
        Header: "Backup Status",
        accessor: "lastBackupStatus",
        Cell: ({ row }: any) => {
          const tagClass =
            row.original.lastBackupStatus?.toLowerCase() === "error"
              ? "is-danger"
              : "is-success";
          return (
            row.original.lastBackupStatus && (
              <div style={{ padding: ".5rem" }}>
                <span className={`tag ${tagClass}`}>
                  {row.original.lastBackupStatus}
                </span>
              </div>
            )
          );
        },
      },
      {
        Header: "Resource Id",
        accessor: "id",
        Cell: ({ row }: any) => (
          <div>
            {truncate(row.original.id)}
            <CopyToClipboard
              text={row.original.id}
              onCopy={() => {
                toast.success(toastMessages.RESOURCE_ID_COPIED);
              }}
            >
              <Button outline size="extra-small" margin="0 .25rem">
                <i className="fa fa-copy" /> copy
              </Button>
            </CopyToClipboard>
          </div>
        ),
      },
      {
        Header: "Provider",
        accessor: "cloudProvider",
        Cell: ({ row }: any) => (
          <div style={{ padding: ".5rem" }}>
            {row.original.cloudProvider && (
              <Icon
                height="1.75rem"
                image={row.original.cloudProvider}
                width="1.75rem"
              />
            )}
          </div>
        ),
      },
      {
        Header: "RPO",
        accessor: "rpoInHours",
        Cell: ({ row }: any) =>
          row.original.rpoInHours && (
            <div
              style={{ padding: ".5rem" }}
              data-tip="RPO is set by the Azure native backup. You can control it in your Azure portal."
            >
              {`${row.original.rpoInHours} h`}
            </div>
          ),
      },
      {
        id: "actions",
        Cell: ({ row }: any) => (
          <div>
            {row.original.lastBackupStatus === "ERROR" && (
              <div className="navbar-item has-dropdown is-hoverable is-pulled-right">
                <span className="icon">
                  <i className="fa fa-ellipsis-v" />
                </span>
                <div className="navbar-dropdown is-right">
                  <a
                    onClick={() =>
                      showErrorPopup(row.original.lastBackupErrorMessage)
                    }
                    className="navbar-item"
                  >
                    <span className="icon">
                      <i className="fa fa-camera" />
                    </span>
                    <span>Show error</span>
                  </a>
                </div>
              </div>
            )}
          </div>
        ),
      },
    ],
    [filters]
  );

  return (
    <>
      <div className="box">
        <Loading loading={loading}>
          {resources && resources.length > 0 ? (
            <>
              <div className="columns is-vcentered border-bottom">
                <div className="column">
                  <h1 className="title is-marginless is-5 is-capitalized">
                    Azure Native VMs
                  </h1>
                </div>
              </div>
              <Table columns={columns} data={resources} disableSortBy />
              <ReactTooltip effect="solid" />
              <Pagination
                totalItems={totalNumberOfResources || 0}
                totalPages={totalNumberOfPages || 0}
                currentPage={currentPage}
                showing={getResourcesNumber()}
                changePage={changePage}
                previousPage={previousPage}
                nextPage={nextPage}
              />
            </>
          ) : (
            <Placeholder message="No data found." />
          )}
        </Loading>
      </div>
    </>
  );
};

export default ExternalResources;
