/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { ReactNode } from "react";
import styled, { css } from "styled-components";

interface SegmentProps {
  children: ReactNode;
  tabs?: boolean;
  alignRight?: boolean;
}

const StyledSegment = styled.div<SegmentProps>`
  ${({ alignRight }) =>
    alignRight &&
    css`
      text-align: right;
    `};

  a {
    margin-right: 0.5rem;
  }

  Button {
    margin-right: 0.5rem;

    &:last-of-type {
      margin-right: 0;
    }
  }

  ${({ tabs }) =>
    tabs &&
    css`
      display: flex;

      Button {
        height: 2.375rem;
        flex: 1;
        border-radius: 0;
        margin: 0 0 0.5rem 0;

        &:first-of-type {
          border-top-left-radius: 0.25rem;
          border-bottom-left-radius: 0.25rem;
        }

        &:not(:last-of-type) {
          border-right: none;
        }

        &:last-of-type {
          border-top-right-radius: 0.25rem;
          border-bottom-right-radius: 0.25rem;
        }
      }
    `}
`;

const Segment: React.FC<SegmentProps> = (props) => (
  <StyledSegment {...props}>{props.children}</StyledSegment>
);

export default Segment;
