/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from "react";
import ReactDOM from "react-dom";
import { Auth0Client } from "@auth0/auth0-spa-js";
import "./assets/scss/index.scss";
import "font-awesome/css/font-awesome.css";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import * as serviceWorker from "./serviceWorker";
import Rollbar from "rollbar";
import CustomerSelector from "./commonComponents/customers/CustomerSelector";
import { CustomerProvider } from "./context/customer";
import Routes from "./routes";
import { Auth0Provider, AuthWall } from "./commonComponents/auth";
import { AuthorizedApolloProvider } from "./graphql";

declare global {
  interface Window {
    Cypress: any;
  }
}

import("./config").then(({ ROLLBAR_TOKEN }) => {
  new Rollbar({
    accessToken: ROLLBAR_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    enabled: process.env.NODE_ENV !== "development",
    payload: {
      environment: process.env.REACT_APP_STAGE,
      client: {
        javascript: {
          source_map_enabled: true,
          code_version: process.env.REACT_APP_VERSION,
          guess_uncaught_frames: true,
        },
      },
    },
  });
});

const isE2eTest = window.Cypress != null;

const auth0 = new Auth0Client({
  domain: process.env.REACT_APP_AUTH0_DOMAIN || "",
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID || "", // eslint-disable-line
  redirect_uri: window.location.origin, // eslint-disable-line
  cacheLocation: isE2eTest ? "localstorage" : "memory",
});

ReactDOM.render(
  <Auth0Provider client={auth0}>
    <AuthWall>
      <AuthorizedApolloProvider>
        <CustomerProvider>
          <CustomerSelector>
            <Routes />
          </CustomerSelector>
        </CustomerProvider>
      </AuthorizedApolloProvider>
    </AuthWall>
  </Auth0Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
