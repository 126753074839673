/**
 * Copyright 2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ROUTES } from "../../../routes/routes";
import { CustomerAccess, User } from "../../../generated/graphql";
import CustomersRoles from "../../../shared/customers/CustomersRoles";
import React, { Dispatch, SetStateAction } from "react";
import { History } from "history";
import { Cell } from "react-table";

type UsersListProps = {
  customerId: string;
  setEmail: Dispatch<SetStateAction<string>>;
  setUpdateModalIsOpen: Dispatch<SetStateAction<boolean>>;
  setCustomerData: Dispatch<SetStateAction<CustomerAccess | undefined>>;
  history: History;
};

export const UsersListColumns = ({
  customerId,
  setUpdateModalIsOpen,
  setEmail,
  setCustomerData,
  history,
}: UsersListProps) => [
  {
    Header: "User",
    accessor: "email",
    Cell: ({ row }: Cell<User>) => {
      const { email } = row.original;

      return (
        <div
          className="navbar-item is-hoverable"
          onClick={() => {
            history.push(ROUTES.users.user.generate({ email }));
          }}
        >
          <span>{email}</span>
        </div>
      );
    },
  },
  {
    Header: "Type",
    accessor: "superAdmin",
    Cell: ({ row }: Cell<User>) => {
      const { superAdmin } = row.original;
      return superAdmin ? <span>Super Admin</span> : <span>User</span>;
    },
  },
  {
    Header: "Roles",
    accessor: "customerAccesses",
    Cell: ({ row }: Cell<User>) => {
      const roles = row.original.customerAccesses.data.find(
        (customerAccesses: CustomerAccess | null) =>
          customerAccesses?.customer.id === customerId
      )?.roles;
      return roles ? (
        <div style={{ minWidth: "20rem" }}>
          <CustomersRoles roles={roles} />
        </div>
      ) : (
        <span>No roles</span>
      );
    },
  },
  {
    id: "actions",
    Cell: ({ row }: Cell<User>) => {
      const { email, customerAccesses } = row.original;
      const { customer, roles } = customerAccesses.data.find(
        (customerAccesses: CustomerAccess | null) =>
          customerAccesses?.customer.id === customerId
      ) || {
        customer: {
          id: "",
          name: "",
          backupPoliciesBoundary: {
            minRPOInHours: 0,
            maxPrimarySnapshotRetentionQuantity: 0,
            maxPrimarySnapshotRetentionInDays: 0,
            maxReplicaSnapshotRetentionQuantity: 0,
            maxReplicaSnapshotRetentionInDays: 0,
          },
        },
        roles: [],
      };

      return (
        <div>
          <div
            className="navbar-item is-hoverable is-pulled-right"
            onClick={() => {
              setUpdateModalIsOpen(true);
              setEmail(email);
              setCustomerData(() => ({ customer, roles }));
            }}
          >
            <span className="icon">
              <i className="fa fa-pencil" />
            </span>
          </div>
        </div>
      );
    },
  },
];
