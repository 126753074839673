/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { useContext } from "react";
import {
  AccountType,
  GetUserRoleQuery,
  useGetAccountsQuery,
  useRemoveAccountMutation,
  UserRole,
} from "../../../generated/graphql";
import ProtectedAccountsList from "./ProtectedAccountsList";
import { Link } from "react-router-dom";
import { CustomerContext } from "../../../context/customer";
import { Loading } from "../../../commonComponents/loading/Loading";
import { toast } from "react-toastify";
import { toastMessages } from "../../../utils/constants";
import Button from "../../../commonComponents/button/Button";
import { ROUTES } from "../../../routes/routes";

interface Props {
  userRole: GetUserRoleQuery | any;
  userRoleLoading: boolean;
}

const ProtectedAccounts: React.FC<Props> = ({ userRole, userRoleLoading }) => {
  const customer = useContext(CustomerContext);
  const customerId =
    customer && customer.customer && customer.customer.id
      ? customer.customer.id
      : "";
  const {
    data,
    error,
    loading,
    refetch: reload,
  } = useGetAccountsQuery({
    variables: {
      searchCriteria: {
        customerId: customerId,
        accountType: AccountType.Protected,
      },
    },
  });
  const [removeAccount, { loading: removeAccLoading }] =
    useRemoveAccountMutation();

  return (
    <Loading loading={loading || userRoleLoading}>
      <>
        <h1 className="title">Protected Accounts</h1>
        <h2 className="subtitle ">
          This list contains all your accounts that are protected using
          AutoBackup.
        </h2>
        {error && <div>{error.message}</div>}
        {userRole?.userRole !== UserRole.ReadOnly && (
          <div style={{ marginBottom: "1rem" }}>
            <Link to={ROUTES.accounts.protected.generate()}>
              <Button data-cy={"new-protected-account"}>
                Protect New Account
              </Button>
            </Link>
          </div>
        )}
        <div className="box">
          {data && data.accounts && data.accounts.data.length > 0 ? (
            <ProtectedAccountsList
              data={data.accounts.data}
              removeAccount={(removeId) => {
                removeAccount({
                  variables: {
                    input: {
                      customerId: customerId,
                      accountId: removeId,
                    },
                  },
                })
                  .then((e) => {
                    reload();
                    toast.success(toastMessages.ACCOUNT_REMOVED);
                  })
                  .catch(() =>
                    toast.error(toastMessages.ACCOUNT_REMOVAL_ERROR)
                  );
              }}
              loading={removeAccLoading}
              userRole={userRole}
              userRoleLoading={userRoleLoading}
              customerId={customerId}
            />
          ) : (
            <p>There are no accounts configured.</p>
          )}
        </div>
      </>
    </Loading>
  );
};

export default ProtectedAccounts;
