/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from "react";
import CreateNotificationGroup from "../components/CreateNotificationGroup";
import PolicyForm from "../components/PolicyForm";
import SetupForm from "../components/SetupForm";
import { History } from "history";

interface Props {
  history: History;
}

export const SettingsFormContainer: React.FC<Props> = ({ history }) => {
  const currentView = history.location.pathname.split("/")[2];

  return (
    <>
      {currentView === "notification-groups" && (
        <CreateNotificationGroup history={history} />
      )}
      {currentView === "setups" && <SetupForm history={history} />}
      {currentView === "policies" && <PolicyForm history={history} />}
    </>
  );
};
