/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import Button from "../button/Button";
import Sidebar from "react-sidebar";
import { ROUTES } from "../../routes/routes";
import Icons from "../../views/notes/components/Icons";
import { COLORS, TRANSITION } from "../../views/notes/components/constants";
import slugify from "@sindresorhus/slugify";
import { notesData } from "../../views/notes/Notes";

const lastNote = notesData[0];

export interface WidgetProps {
  isOpen?: boolean;
  isNew?: boolean;
  isStickerMinimized?: boolean;
  onClick?: () => void;
  onClose?: () => void;
  onArrowClick?: () => void;
  onFeedbackClick?: () => void;
  onNotesClick?: () => void;
  onSidebarOpen?: () => void;
}

const StyledSticker = styled.div<WidgetProps>`
  z-index: 100;
  position: fixed;
  height: 255px;
  width: 210px;
  right: -190px;
  bottom: 0;
  cursor: pointer;
  text-align: center;
  background-color: white;
  border-radius: 0.25rem 0.25rem 0 0;
  transform: rotate(-90deg);
  transition: ${TRANSITION.set(["padding", "right"])};
  ${({ isStickerMinimized }) =>
    isStickerMinimized === false &&
    css`
      right: -180px;
      > :first-child {
        padding: 1.75rem 0.25rem 2.5rem 0.25rem;
        transition: ${TRANSITION.set(["padding", "right"])};
      }
    `}
  > :first-child {
    font-size: 0.75rem;
    position: relative;
    box-shadow: 1px 8px 28px ${COLORS.Dark.shadowed};
    border-radius: 0.25rem 0.25rem 0 0;
    padding: 0.75rem 0.25rem 1rem 0.25rem;
    border: 1px solid ${COLORS.Dark.shadowed};
    transition: ${TRANSITION.set(["padding", "box-shadow", "right"])};
    :hover {
      box-shadow: 1px 8px 20px ${COLORS.Dark.shadowed};
      transition: ${TRANSITION.set("box-shadow")};
    }
  }
  ${({ isOpen }) =>
    isOpen &&
    css`
      z-index: 0;
    `}
`;

const styles = {
  root: {
    position: "fixed",
    top: "0",
    left: "unset",
    right: "0",
    bottom: "0",
    width: "0",
    overflow: "hidden",
    zIndex: "100",
    transition: "width 0.3s ease-out",
  },
  sidebar: {
    position: "fixed",
    top: "0",
    bottom: "0",
    left: "65%",
    overflowY: "auto",
    backgroundColor: COLORS.Light.main,
    boxShadow: `0 12px 22px ${COLORS.Dark.shadowed}`,
    transition: TRANSITION.set("transform"),
    willChange: "transform",
  },
  content: {
    position: "fixed",
    top: "0",
    left: "unset",
    right: "0",
    bottom: "0",
    overflowY: "auto",
    transition: TRANSITION.set(["right", "left"]),
  },
  overlay: {
    position: "fixed",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    backgroundColor: COLORS.Dark.shadowed,
    transition: TRANSITION.set("backgroundColor"),
  },
  dragHandle: {},
};

const StyledSidebar = styled.div`
  height: 100vh;
  outline: none;
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 1.5rem;
  height: 100%;
`;

const Header = styled.div`
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 1.5rem;
  width: 100%;
  border-bottom: 1px solid ${COLORS.Light.main};
  z-index: 1000;
`;

const Title = styled.h3`
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  margin: 0;
`;

const Content = styled.div`
  text-align: justify;
  margin: 1rem 0 2rem 0;
`;

const ActionSpace = styled.div`
  text-align: right;
  margin: 1rem 0 2rem 0;
`;

const StickerMenuClosed = ({
  onArrowClick,
  onFeedbackClick,
  onNotesClick,
  isNew,
}: {
  onArrowClick?: () => void;
  onFeedbackClick?: () => void;
  onNotesClick?: () => void;
  isNew?: boolean;
}) => {
  return (
    <div
      style={{
        display: "flex",
      }}
      onClick={onArrowClick}
    >
      <div
        style={{
          width: "85%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
      >
        <Icons.Feedback onClick={onFeedbackClick} />
        {!isNew ? (
          <Icons.Bell
            width="28px"
            height="28px"
            viewBox="0 0 26 26"
            onClick={onNotesClick}
          />
        ) : (
          <Icons.BellActive
            width="28px"
            height="28px"
            viewBox="0 0 26 26"
            onClick={onNotesClick}
            style={{
              backgroundColor: COLORS.Unique.main,
            }}
          />
        )}
      </div>
      <div
        style={{
          width: "15%",
          display: "flex",
        }}
      >
        <Icons.ArrowDown onClick={onArrowClick} />
      </div>
    </div>
  );
};

const StickerMenuOpen = ({
  isNew,
  onArrowClick,
}: {
  isNew?: boolean;
  onArrowClick?: () => void;
}) => (
  <div style={{ display: "flex" }} onClick={onArrowClick}>
    <div
      style={{
        justifyContent: "space-around",
        alignItems: "center",
        fontWeight: "bold",
        display: "flex",
        width: "85%",
      }}
    >
      <span>Notification Center</span>
      {!isNew ? <Icons.Bell inversed /> : <Icons.BellActive inversed />}
    </div>
    <div style={{ width: "15%", display: "flex", justifyContent: "center" }}>
      <Icons.ArrowDown
        style={{
          transform: "rotate(180deg)",
        }}
        onClick={onArrowClick}
      />
    </div>
  </div>
);

const Widget: React.FC<WidgetProps> = ({
  isStickerMinimized,
  onFeedbackClick,
  onSidebarOpen,
  onArrowClick,
  onNotesClick,
  onClose,
  onClick,
  isOpen,
  isNew,
  ...props
}: WidgetProps) => {
  styles.root.width = isOpen ? "100%" : "0";
  return (
    <Sidebar
      onSetOpen={onClose}
      styles={styles}
      shadow={false}
      open={isOpen}
      pullRight
      sidebar={
        <StyledSidebar>
          <Header>
            <Title>
              Latest Release Note
              <button className="delete" onClick={onClose} />
            </Title>
          </Header>
          <Container>
            <p className="title is-5">{lastNote.title}</p>
            <p className="subtitle is-6">{lastNote.date}</p>
            <Content>
              <ul>
                {lastNote.entries.map((entry, i) => (
                  <li key={`${lastNote.date}-${i}`}>{entry}</li>
                ))}
              </ul>
            </Content>
            <ActionSpace>
              <Link
                to={ROUTES.notes.note.generate({ id: slugify(lastNote.title) })}
                onClick={onNotesClick}
              >
                <Button>Read more</Button>
              </Link>
            </ActionSpace>
          </Container>
        </StyledSidebar>
      }
      {...props}
    >
      <StyledSticker
        isNew={isNew}
        onClick={onClick}
        isStickerMinimized={isStickerMinimized}
      >
        <div>
          {!isStickerMinimized ? (
            <StickerMenuClosed
              isNew={isNew}
              onArrowClick={onArrowClick}
              onNotesClick={onNotesClick}
              onFeedbackClick={onFeedbackClick}
            />
          ) : (
            <StickerMenuOpen onArrowClick={onArrowClick} isNew={isNew} />
          )}
        </div>
      </StyledSticker>
    </Sidebar>
  );
};

export default Widget;
