/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from "react";
import styled from "styled-components";

interface MessageProps {
  children: string;
  className?: string;
}

const StyledMessage = styled.div<MessageProps>`
  margin: 0 0 0.5rem 0;
  color: #ff3860;
  font-size: 0.75rem;
`;

const ValidationMessage: React.FC<MessageProps> = (props) => {
  return <StyledMessage>{props.children}</StyledMessage>;
};

export default ValidationMessage;
