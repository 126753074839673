/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { useContext } from "react";
import { CustomerContext } from "../../../context/customer";
import SetupSelect from "../components/SetupSelect";
import {
  useGetBackupSetupsQuery,
  useMatchedResourcesTypesQuery,
  Maybe,
} from "../../../generated/graphql";
import { Loading } from "../../../commonComponents/loading/Loading";
import { Error } from "../../../commonComponents/error/error";

interface Props {
  filters: any;
  selectedIds: Maybe<Array<string>>;
  setupIds: Array<any>;
  onChange: (setupIds: any) => void;
}

const SetupSelectsContainer: React.FC<Props> = ({
  filters,
  selectedIds,
  setupIds,
  onChange,
}) => {
  const { customer } = useContext(CustomerContext);

  const { data, loading, error } = useGetBackupSetupsQuery({
    variables: {
      searchCriteria: {
        customerId: customer?.id || "",
      },
    },
  });

  const { data: matchedTypes } = useMatchedResourcesTypesQuery({
    variables: {
      searchCriteria: {
        customerId: customer?.id || "",
        filters: filters,
        onlySelectedIds: selectedIds,
      },
    },
  });

  return (
    <Loading loading={loading}>
      {error && <Error error={error} />}
      {!error &&
      data &&
      data.backupSetups &&
      matchedTypes &&
      matchedTypes.matchedResourcesTypes ? (
        matchedTypes.matchedResourcesTypes.map((type: any) => (
          <SetupSelect
            defaultValue={
              setupIds && setupIds[type.resourceTypeName]
                ? setupIds[type.resourceTypeName].label
                : ""
            }
            type={type.resourceTypeName}
            key={type.resourceTypeName}
            numberOfResources={type.numberOfResources}
            setups={data.backupSetups.filter(
              (setup) => setup.resourceType === type.resourceTypeName
            )}
            onChange={(setup: any) =>
              onChange({
                ...setupIds,
                [type.resourceTypeName]: {
                  value: setup.value,
                  numberOfResources: type.numberOfResources,
                  label: setup.label,
                },
              })
            }
          />
        ))
      ) : (
        <p>There are no backup setups available!</p>
      )}
    </Loading>
  );
};

export default SetupSelectsContainer;
