/**
 * Copyright 2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ROUTES } from "../../../routes/routes";
import { ApiKey, CustomerAccess } from "../../../generated/graphql";
import CustomersRoles from "../../../shared/customers/CustomersRoles";
import Moment from "react-moment";
import React, { Dispatch, SetStateAction } from "react";
import { History } from "history";
import { Cell } from "react-table";

type ApiKeysListProps = {
  customerId: string;
  setApiKeyId: Dispatch<SetStateAction<string>>;
  setUpdateModalIsOpen: Dispatch<SetStateAction<boolean>>;
  setCustomerData: Dispatch<SetStateAction<CustomerAccess | undefined>>;
  history: History;
};

export const ApiKeysListColumns = ({
  customerId,
  setApiKeyId,
  setUpdateModalIsOpen,
  setCustomerData,
  history,
}: ApiKeysListProps) => [
  {
    Header: "ID",
    accessor: "id",
    Cell: ({ row }: Cell<ApiKey>) => {
      const { id } = row.original;

      return (
        <div
          className="navbar-item is-hoverable"
          onClick={() => {
            history.push(ROUTES.apiKeys.apiKey.generate({ id }));
          }}
        >
          <span>{id}</span>
        </div>
      );
    },
  },
  {
    Header: "Alias",
    accessor: "alias",
  },
  {
    Header: "Owner",
    accessor: "owner",
  },
  {
    Header: "Roles",
    accessor: "customerAccesses",
    Cell: ({ row }: Cell<ApiKey>) => {
      const roles = row.original.customerAccesses.data.find(
        (customerAccesses: CustomerAccess | null) =>
          customerAccesses?.customer.id === customerId
      )?.roles;
      return roles ? (
        <div style={{ minWidth: "20rem" }}>
          <CustomersRoles roles={roles} />
        </div>
      ) : (
        <span>No roles</span>
      );
    },
  },
  {
    Header: "Created at",
    accessor: "createdAt",
    Cell: ({ row }: Cell<ApiKey>) => {
      const { createdAt } = row.original;
      return (
        <div style={{ fontWeight: "bold" }}>
          <Moment format="DD-MM-YYYY HH:mm">{createdAt}</Moment>
        </div>
      );
    },
  },
  {
    Header: "Last used at",
    accessor: "lastUsedAt",
    Cell: ({ row }: Cell<ApiKey>) => {
      const { lastUsedAt } = row.original;
      if (!lastUsedAt) {
        return <div style={{ fontWeight: "bold" }}>Not used yet</div>;
      }
      return (
        <div style={{ fontWeight: "bold" }}>
          <Moment format="DD-MM-YYYY HH:mm">{lastUsedAt}</Moment>
        </div>
      );
    },
  },
  {
    id: "actions",
    Cell: ({ row }: Cell<ApiKey>) => {
      const { id, customerAccesses } = row.original;
      const { customer, roles } = customerAccesses.data.find(
        (customerAccesses: CustomerAccess | null) =>
          customerAccesses?.customer.id === customerId
      ) || {
        customer: {
          id: "",
          name: "",
          backupPoliciesBoundary: {
            minRPOInHours: 0,
            maxPrimarySnapshotRetentionQuantity: 0,
            maxPrimarySnapshotRetentionInDays: 0,
            maxReplicaSnapshotRetentionQuantity: 0,
            maxReplicaSnapshotRetentionInDays: 0,
          },
        },
        roles: [],
      };

      return (
        <div>
          <div
            className="navbar-item is-hoverable is-pulled-right"
            onClick={() => {
              setUpdateModalIsOpen(true);
              setApiKeyId(id);
              setCustomerData(() => ({ customer, roles }));
            }}
          >
            <span className="icon">
              <i className="fa fa-pencil" />
            </span>
          </div>
        </div>
      );
    },
  },
];
