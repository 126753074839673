/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { ReactNode } from "react";

type LoadingHocProps = {
  children?: ReactNode;
  loading: boolean;
};

const Loading: React.FC<LoadingHocProps> = ({ loading, children }) =>
  loading ? <div className="loader element is-loading" /> : <>{children}</>;

export { Loading };
