/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from "react";
import SVG, { SVGInterface } from "../../../commonComponents/svg/SVG";
import { COLORS, TRANSFORM } from "./constants";

const regularStyles = {
  transform: TRANSFORM.rotate,
  borderRadius: "5px",
  padding: "0.15rem 0.15rem 0.15rem 0.25rem",
  backgroundColor: COLORS.Dark.main,
  "&:hover": {
    color: COLORS.Unique.main,
  },
};

const inversedStyles = {
  transform: TRANSFORM.rotate,
  padding: 0,
  backgroundColor: "transparent",
};

export const Bell = ({
  width,
  height,
  style,
  viewBox,
  inversed,
  onClick,
}: SVGInterface<string, boolean>) => (
  <SVG
    width={width || "20px"}
    height={height || "20px"}
    viewBox={viewBox || `0 0 22 22`}
    fill={inversed ? COLORS.Dark.main : COLORS.Light.main}
    style={
      inversed
        ? { ...inversedStyles, ...style }
        : { ...regularStyles, ...style }
    }
    onClick={onClick}
  >
    <path
      d="M11.6193 21.9999C9.64976 21.9999 8.04785 20.398 8.04785 18.4285C8.04785 18.0342 8.36785 17.7142 8.76214 17.7142C9.15642 17.7142 9.47642 18.0342 9.47642 18.4285C9.47642 19.6105 10.4375 20.5714 11.6193 20.5714C12.8013 20.5714 13.7621 19.6105 13.7621 18.4285C13.7621 18.0342 14.0821 17.7142 14.4764 17.7142C14.8707 17.7142 15.1907 18.0342 15.1907 18.4285C15.1907 20.398 13.5888 21.9999 11.6193 21.9999V21.9999Z"
      fill={inversed ? COLORS.Dark.main : COLORS.Light.main}
    />
    <path
      d="M19.4766 19.1429H3.76231C2.8433 19.1429 2.0957 18.3953 2.0957 17.4762C2.0957 16.9885 2.30811 16.5267 2.6785 16.2095C4.12713 14.9857 4.95285 13.2068 4.95285 11.322V8.66661C4.95285 4.99055 7.94339 2 11.6195 2C15.2957 2 18.2862 4.99055 18.2862 8.66661V11.322C18.2862 13.2068 19.112 14.9857 20.551 16.2029C20.931 16.5267 21.1434 16.9885 21.1434 17.4762C21.1434 18.3953 20.3958 19.1429 19.4766 19.1429ZM11.6195 3.42857C8.73092 3.42857 6.38142 5.77808 6.38142 8.66661V11.322C6.38142 13.6275 5.37102 15.8048 3.61007 17.2933C3.57659 17.3219 3.52427 17.381 3.52427 17.4762C3.52427 17.6056 3.63292 17.7143 3.76231 17.7143H19.4766C19.6062 17.7143 19.7148 17.6056 19.7148 17.4762C19.7148 17.381 19.6623 17.3219 19.6309 17.2952C17.8681 15.8048 16.8577 13.6275 16.8577 11.322V8.66661C16.8577 5.77808 14.5082 3.42857 11.6195 3.42857Z"
      fill={inversed ? COLORS.Dark.main : COLORS.Light.main}
    />
  </SVG>
);

export const BellActive = ({
  width,
  height,
  style,
  inversed,
  viewBox,
  onClick,
}: SVGInterface<string, boolean>) => (
  <SVG
    width={width || "20px"}
    height={height || "20px"}
    viewBox={viewBox || `0 0 22 22`}
    fill={inversed ? COLORS.Dark.main : COLORS.Light.main}
    style={
      inversed
        ? { ...inversedStyles, ...style }
        : { ...regularStyles, ...style }
    }
    onClick={onClick}
  >
    <path
      d="M11.5 22C9.5709 22 8 20.5049 8 18.6667C8 18.2987 8.3136 18 8.7 18C9.0864 18 9.4 18.2987 9.4 18.6667C9.4 19.7699 10.3427 20.6667 11.5 20.6667C12.6573 20.6667 13.6 19.7699 13.6 18.6667C13.6 18.2987 13.9136 18 14.3 18C14.6864 18 15 18.2987 15 18.6667C15 20.5049 13.4293 22 11.5 22V22Z"
      fill={inversed ? COLORS.Unique.main : COLORS.Light.main}
    />
    <path
      d="M19.3374 19H3.66244C2.74572 19 2 18.2586 2 17.3473C2 16.8636 2.21187 16.4057 2.58134 16.0911C2.60517 16.0703 2.63074 16.0515 2.65736 16.0344C4.05191 14.8245 4.84999 13.084 4.84999 11.246V8.61105C4.84999 4.96563 7.83392 2 11.4999 2C11.6519 2 11.8163 2.00277 11.9684 2.02836C12.3568 2.09252 12.6191 2.4581 12.5544 2.84339C12.4899 3.22869 12.1155 3.48947 11.7346 3.42514C11.6586 3.41286 11.5751 3.41667 11.4999 3.41667C8.61965 3.41667 6.27499 5.74659 6.27499 8.61105V11.246C6.27499 13.5279 5.26886 15.687 3.51719 17.1686C3.50293 17.1801 3.49057 17.1904 3.47527 17.2008C3.4497 17.233 3.425 17.2821 3.425 17.3473C3.425 17.4756 3.53337 17.5833 3.66244 17.5833H19.3374C19.4666 17.5833 19.575 17.4756 19.575 17.3473C19.575 17.281 19.5503 17.233 19.5237 17.2008C19.5094 17.1904 19.4971 17.1801 19.4828 17.1686C17.7301 15.6859 16.725 13.5279 16.725 11.246V10.2167C16.725 9.82573 17.0442 9.5084 17.4375 9.5084C17.8308 9.5084 18.15 9.82573 18.15 10.2167V11.246C18.15 13.085 18.949 14.8264 20.3454 16.0373C20.371 16.0543 20.3957 16.0722 20.4185 16.0921C20.7881 16.4057 21 16.8636 21 17.3473C21 18.2586 20.2543 19 19.3374 19V19Z"
      fill={inversed ? COLORS.Unique.main : COLORS.Light.main}
    />
    <circle
      cx="16"
      cy="5"
      r="3"
      fill={inversed ? COLORS.Unique.main : COLORS.Light.main}
    />
  </SVG>
);

export const Feedback = ({
  fill,
  width,
  height,
  style,
  onClick,
}: SVGInterface<string, boolean>) => (
  <SVG
    width={width || "28px"}
    height={height || "28px"}
    viewBox="0 0 26 26"
    fill={fill}
    style={{ ...regularStyles, ...style }}
    onClick={onClick}
  >
    <path
      d="M13.8159 20.4375H6.125C5.26344 20.4375 4.5625 19.7366 4.5625 18.875V7.07812C4.5625 6.21656 5.26344 5.51562 6.125 5.51562H8.5816V5.90625C8.5816 7.19859 9.63301 8.25 10.9254 8.25C12.2177 8.25 13.2691 7.19859 13.2691 5.90625V5.51562H15.73C16.5916 5.51562 17.2925 6.21656 17.2925 7.07812V12.7812C17.2925 13.2127 17.6423 13.5625 18.0738 13.5625C18.5053 13.5625 18.855 13.2127 18.855 12.7812V7.07812C18.855 5.355 17.4532 3.95312 15.73 3.95312H13.2691V2.78125C13.2691 2.34977 12.9193 2 12.4879 2H9.36285C8.93137 2 8.5816 2.34977 8.5816 2.78125V3.95312H6.125C4.40188 3.95312 3 5.355 3 7.07812V18.875C3 20.5981 4.40188 22 6.125 22H13.816C14.2475 22 14.5972 21.6502 14.5972 21.2188C14.5972 20.7873 14.2474 20.4375 13.8159 20.4375ZM10.1441 3.5625H11.7066V5.90625C11.7066 6.33703 11.3561 6.6875 10.9253 6.6875C10.4945 6.6875 10.1441 6.33703 10.1441 5.90625V3.5625ZM20.2395 17.9996L15.9598 13.7104C15.8689 13.6193 15.757 13.552 15.6338 13.5146L12.5586 12.5806C12.286 12.4977 11.9898 12.57 11.786 12.7689C11.582 12.9679 11.5026 13.2621 11.5786 13.5366L12.4521 16.69C12.4881 16.8197 12.557 16.938 12.6521 17.0333L16.9242 21.3135C17.3811 21.7704 17.9814 21.9989 18.5815 21.9989C19.1817 21.9989 19.7819 21.7704 20.2388 21.3135C21.1527 20.3997 21.1527 18.9128 20.2395 17.9996ZM13.4637 14.4885L14.9921 14.9528L17.8798 17.8469L16.7748 18.9519L13.9032 16.0749L13.4637 14.4885ZM19.134 20.2087C18.8294 20.5133 18.3338 20.5133 18.0296 20.2092L17.8786 20.0579L18.9834 18.953L19.1339 19.1039C19.4386 19.4084 19.4386 19.9041 19.134 20.2087Z"
      fill={fill || COLORS.Light.main}
    />
  </SVG>
);

export const Notes = ({
  fill,
  width,
  height,
  style,
  onClick,
}: SVGInterface<string, boolean>) => (
  <SVG
    width={width || "28px"}
    height={height || "28px"}
    viewBox="0 0 26 26"
    fill={fill}
    style={{ ...regularStyles, ...style }}
    onClick={onClick}
  >
    <path
      d="M21.2188 15.75C21.6502 15.75 22 15.4002 22 14.9688V9.5275C22 8.45699 21.4618 7.47316 20.5602 6.89582L18.6797 5.69168V2.78125C18.6797 2.34977 18.3299 2 17.8984 2H6.10156C5.67008 2 5.32031 2.34977 5.32031 2.78125V5.6884L3.44113 6.89047C2.53875 7.46766 2 8.45176 2 9.52297V18.875C2 20.5981 3.40188 22 5.125 22H18.875C20.5981 22 22 20.5981 22 18.875C22 18.4435 21.6502 18.0938 21.2188 18.0938C20.7873 18.0938 20.4375 18.4435 20.4375 18.875C20.4375 19.7366 19.7366 20.4375 18.875 20.4375H5.125C4.26344 20.4375 3.5625 19.7366 3.5625 18.875V10.5404L10.3498 14.7609C10.8588 15.0774 11.4294 15.2356 12 15.2356C12.5706 15.2356 13.1412 15.0773 13.6502 14.7609L20.4375 10.5404V14.9688C20.4375 15.4002 20.7873 15.75 21.2188 15.75ZM19.7176 8.21168C19.9534 8.3627 20.1389 8.56969 20.2628 8.8091L18.6797 9.79352V7.54703L19.7176 8.21168ZM4.28305 8.20676L5.32031 7.54324V9.79352L3.73543 8.80801C3.85941 8.56691 4.04574 8.35852 4.28305 8.20676ZM12.8251 13.4341C12.3161 13.7505 11.6838 13.7505 11.1749 13.4341L6.88281 10.7651V3.5625H17.1172V10.7651L12.8251 13.4341ZM12.7812 8.21094V11.2578C12.7812 11.6893 12.4315 12.0391 12 12.0391C11.5685 12.0391 11.2188 11.6893 11.2188 11.2578V8.21094C11.2188 7.77945 11.5685 7.42969 12 7.42969C12.4315 7.42969 12.7812 7.77945 12.7812 8.21094ZM12.7812 5.67188C12.7812 6.10336 12.4315 6.45312 12 6.45312C11.5685 6.45312 11.2188 6.10336 11.2188 5.67188C11.2188 5.24039 11.5685 4.89062 12 4.89062C12.4315 4.89062 12.7812 5.24039 12.7812 5.67188Z"
      fill={fill || COLORS.Light.main}
    />
  </SVG>
);

export const ArrowDown = ({
  width,
  height,
  fill,
  style,
  onClick,
}: SVGInterface<string, boolean>) => (
  <SVG
    width={width || "20px"}
    height={height || "20px"}
    viewBox="0 0 20 20"
    fill={fill || COLORS.Dark.main}
    style={style}
    onClick={onClick}
  >
    <path
      d="M12.5463 15.7828L18.7763 9.72295C18.9206 9.5828 19 9.3957 19 9.1962C19 8.99671 18.9206 8.80961 18.7763 8.66945L18.3176 8.22319C18.0188 7.9328 17.533 7.9328 17.2345 8.22319L12.0029 13.3118L6.76547 8.21754C6.62126 8.07738 6.42901 8 6.22402 8C6.0188 8 5.82656 8.07738 5.68224 8.21754L5.22366 8.66381C5.07945 8.80407 5 8.99106 5 9.19056C5 9.39005 5.07945 9.57715 5.22366 9.71731L11.4594 15.7828C11.6041 15.9233 11.7972 16.0004 12.0026 16C12.2087 16.0004 12.4017 15.9233 12.5463 15.7828Z"
      fill={fill || COLORS.Dark.main}
    />
  </SVG>
);

const Icons = {
  Bell,
  BellActive,
  Feedback,
  Notes,
  ArrowDown,
};

export default Icons;
