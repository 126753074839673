/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { useContext, useEffect, useState } from "react";
import Breadcrumbs from "../breadcrumbs/Breadcrumbs";
import { useLocation } from "react-router-dom";
import Segment from "../segment/Segment";
import { Loading } from "../loading/Loading";
import Popup from "react-popup";
import { toast } from "react-toastify";
import { toastMessages } from "../../utils/constants";
import {
  AccountType,
  ScanStatus,
  useGetAccountsQuery,
  useGetUserRoleQuery,
  UserRole,
  useScanStatusChangedSubscription,
  useTriggerScanMutation,
} from "../../generated/graphql";
import { CustomerContext } from "../../context/customer";
import Button from "../button/Button";
import { Error } from "../error/error";

const PageHeader: React.FC = () => {
  const location = useLocation();
  const [pageTitle, setPageTitle] = useState<string | undefined>("");

  const { customer } = useContext(CustomerContext);
  const customerId = customer && customer.id ? customer.id : "";
  const [triggerScan, { loading: scanLoading }] = useTriggerScanMutation({
    variables: {
      input: {
        customerId: customerId,
      },
    },
  });

  const { data: scanStatusData } = useScanStatusChangedSubscription();

  const { data: userRoleData, loading: userRoleLoading } = useGetUserRoleQuery({
    variables: {
      customerId: customerId,
    },
  });

  const {
    data: protectedAccountsData,
    error: protectedAccountsError,
    loading: protectedAccountsLoading,
  } = useGetAccountsQuery({
    variables: {
      searchCriteria: {
        customerId: customerId,
        accountType: AccountType.Protected,
      },
    },
  });

  const forceRescanButtonDisabled =
    protectedAccountsData?.accounts.data.length === 0;

  useEffect(() => {
    const path = location.pathname.split("/");
    let title;
    if (path[1] === "apiKeys" && path.length > 1) {
      title = "API keys";
    } else if (path[1] === "myApiKeys" && path.length > 1) {
      title = "My API keys";
    } else {
      title = path.pop();
    }

    if (path[1] === "events" && path.length > 1) {
      title = "Event Details";
    }

    if (path[1] === "users" && path.length > 1) {
      title = "User Details";
    }

    if (path[1] === "release-notes") {
      title = "Release notes";
    }

    if (title !== "create" && path[2] === "templates" && path.length > 2) {
      title = "Template Details";
    }

    if (title !== "create" && path[2] === "plans" && path.length > 2) {
      title = "Plan Details";
    }

    if (
      title !== "create" &&
      path[2] === "notification-groups" &&
      path.length > 2
    ) {
      title = "Notification Group Details";
    }

    if (title !== "create" && path[2] === "setups" && path.length > 2) {
      title = "Setup Details";
    }

    if (title !== "create" && path[2] === "policies" && path.length > 2) {
      title = "Policy Details";
    }

    setPageTitle(title);
  }, [location]);

  const forceRescan = async () => {
    Popup.create({
      onClose: () => {
        Popup.close();
      },
      title: `Rescan resources`,
      content: (
        <div className="box">
          <p>
            This will trigger a scanning of all your resources which can take up
            to 20 minutes.
          </p>
          <p>Are you sure you want to continue?</p>
        </div>
      ),
      buttons: {
        right: [
          {
            text: "Cancel",
            className: "btn",
            action: () => {
              Popup.close();
            },
          },
          {
            text: "Confirm",
            className: "btn",
            action: async () => {
              Popup.close();
              await triggerScan()
                .then((response: any) => {
                  if (response?.data?.triggerScan?.scanId !== "") {
                    toast.success(toastMessages.RESOURCES_SCAN_STARTED);
                  } else {
                    toast.error(toastMessages.RESOURCES_SCAN_ERROR);
                  }
                })
                .catch((e) => {
                  if (e.graphQLErrors?.length > 0) {
                    toast.error(e.graphQLErrors[0].message);
                  } else {
                    toast.error(toastMessages.RESOURCES_SCAN_ERROR);
                  }
                });
            },
          },
        ],
      },
    });
  };

  return (
    <>
      {pageTitle !== "" && (
        <div className="box">
          <div className="columns is-vcentered">
            <div className="column is-7">
              <h1
                className="title is-marginless is-5 is-capitalized"
                style={{ paddingLeft: "3px" }}
              >
                {pageTitle}
              </h1>
              <Breadcrumbs />
            </div>
            <div className="column is-5 is-pulled-right">
              {protectedAccountsError && (
                <Error error={protectedAccountsError} />
              )}
              {pageTitle === "resources" &&
                !userRoleLoading &&
                userRoleData?.userRole !== UserRole.ReadOnly && (
                  <Segment alignRight>
                    <Loading loading={scanLoading || protectedAccountsLoading}>
                      {scanStatusData?.scanStatusChanged?.status !==
                      ScanStatus.Pending ? (
                        <Button
                          onClick={forceRescan}
                          disabled={forceRescanButtonDisabled}
                        >
                          Force rescan
                        </Button>
                      ) : (
                        <div>Scanning...</div>
                      )}
                    </Loading>
                  </Segment>
                )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PageHeader;
