/**
 * Copyright 2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export const LABELS = {
  owner: "Filter by owner",
  email: "Filter by email",
};

export const PLACEHOLDERS = {
  owner: "Owner...",
  email: "Email...",
};
