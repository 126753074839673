/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { useContext, useState } from "react";
import {
  useCreateBackupSetupMutation,
  HookInput,
  HookType,
  BackupSettingInput,
  useGetUserRoleQuery,
} from "../../../generated/graphql";
import { CustomerContext } from "../../../context/customer";
import SimpleReactValidator from "simple-react-validator";
import Input from "../../../commonComponents/input/Input";
import ValidationMessage from "../../../commonComponents/validationMessage/ValidationMessage";
import Segment from "../../../commonComponents/segment/Segment";
import Button from "../../../commonComponents/button/Button";
import { toast } from "react-toastify";
import {
  toastMessages,
  HookOptions,
  BackupSetupTooltips,
} from "../../../utils/constants";
import { History } from "history";
import ResourceTypesSelect from "../../../commonComponents/resourceTypes/ResourceTypesSelect";
import Select from "react-select";
import SetupSettings from "./SetupSettings";
import { ROUTES } from "../../../routes/routes";
import ReactTooltip from "react-tooltip";

interface Props {
  history: History;
}

const validator = new SimpleReactValidator();

const SetupForm: React.FC<Props> = ({ history }) => {
  const { customer } = useContext(CustomerContext);
  const customerId = customer?.id || "";
  const [validateError, setValidateError] = useState(false);
  const [name, setName] = useState<string>("");
  const [resourceType, setResourceType] = useState<string>("");
  const [settingsValidationErrors, setSettingsValidationErrors] =
    useState<any>(null);
  const [preHook, setPreHook] = useState<HookInput>({
    type: HookType.LambdaArn,
    endpoint: "",
  });
  const [postHook, setPostHook] = useState<HookInput>({
    type: HookType.LambdaArn,
    endpoint: "",
  });

  const [backupSettings, setBackupSettings] = useState<{
    [key: string]: BackupSettingInput;
  }>({});

  let validationError;

  const [createBackupSetup] = useCreateBackupSetupMutation({
    variables: {
      input: {
        customerId: customerId,
        name: name,
        resourceType: resourceType,
        preHook: preHook?.endpoint ? preHook : null,
        postHook: postHook?.endpoint ? postHook : null,
        backupSettings: backupSettings
          ? Object.keys(backupSettings).map((item: string) => ({
              id: item,
              value: backupSettings[item].value,
            }))
          : [],
      },
    },
  });

  const { data: userRoleData, loading: userRoleLoading } = useGetUserRoleQuery({
    variables: {
      customerId: customerId,
    },
  });
  const { userRole } = userRoleData || {};

  return (
    <div className="box">
      <div className="columns is-vcentered border-bottom">
        <div className="column is-7">
          <h1 className="title is-marginless is-5 is-capitalized">
            Create new backup setup
          </h1>
        </div>
        <div className="column is-5">
          <Segment alignRight>
            <Button
              className="margin-right"
              outline
              onClick={() => {
                validator.hideMessages();
                history.push(ROUTES.settings.setups.index.generate());
              }}
            >
              Cancel
            </Button>
            <Button
              className="margin-right"
              data-cy="save-setup"
              onClick={() => {
                validateError && setValidateError(false);
                if (validator.allValid()) {
                  createBackupSetup()
                    .then(() => {
                      toast.success(toastMessages.SETUP_CREATED);
                      validator.hideMessages();
                      history.push(ROUTES.settings.setups.index.generate());
                    })
                    .catch((e: any): any => {
                      toast.error(toastMessages.SETUP_CREATION_ERROR);
                      setSettingsValidationErrors(e.graphQLErrors);
                    });
                } else {
                  validator.showMessages();
                  setValidateError(true);
                  toast.error(toastMessages.VALIDATION_FAILED);
                }
              }}
            >
              Save
            </Button>
          </Segment>
        </div>
      </div>

      <div className="columns">
        <div className="column is-4-desktop">
          <div className="field">
            <label className="label">
              Setup name*
              <span className="icon" data-tip={BackupSetupTooltips.name}>
                <i className="fa fa-question-circle" />
              </span>
            </label>
            <Input
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              type="text"
              data-cy="setup-name"
            />
            {settingsValidationErrors &&
              (validationError = settingsValidationErrors?.find(
                (e: any) => e.errorInfo === "name"
              )) && (
                <p className="validation-error">{validationError.message}</p>
              )}
            <ValidationMessage>
              {validator.message("name", name, "required|max:64")}
            </ValidationMessage>
          </div>
          <div className="field" data-cy="resource-type">
            <label className="label">Resource type*</label>
            <ResourceTypesSelect
              onChange={(type: any) => {
                setBackupSettings({});
                validator.hideMessages();
                setResourceType(type.value);
              }}
            />
            <ValidationMessage>
              {validator.message("resourceType", resourceType, "required")}
            </ValidationMessage>
          </div>
          <div className="field">
            <label className="label">
              Prehook
              <span className="icon" data-tip={BackupSetupTooltips.hooks}>
                <i className="fa fa-question-circle" />
              </span>
            </label>
            <div className="columns">
              <div className="column is-5">
                <label>Hook type</label>
                <Select
                  defaultValue={HookOptions[0]}
                  onChange={(e: any) => {
                    setPreHook({
                      endpoint: preHook.endpoint,
                      type: e.value,
                    });
                  }}
                  options={HookOptions}
                />
              </div>
              <div className="column is-7">
                <label>Hook endpoint</label>
                <Input
                  value={preHook.endpoint}
                  onChange={(e) => {
                    setPreHook({
                      type: preHook.type,
                      endpoint: e.target.value,
                    });
                  }}
                  type="text"
                  hintText="Endpoint"
                />
                {settingsValidationErrors &&
                  (validationError = settingsValidationErrors?.find(
                    (e: any) => e.errorInfo === "preHook"
                  )) && (
                    <p className="validation-error">
                      {validationError.message}
                    </p>
                  )}
              </div>
            </div>
          </div>
          <div className="field">
            <label className="label">
              Posthook
              <span className="icon" data-tip={BackupSetupTooltips.hooks}>
                <i className="fa fa-question-circle" />
              </span>
            </label>
            <div className="columns">
              <div className="column is-5">
                <label>Hook type</label>
                <Select
                  defaultValue={HookOptions[0]}
                  onChange={(e: any) => {
                    setPostHook({
                      endpoint: postHook.endpoint,
                      type: e.value,
                    });
                  }}
                  options={HookOptions}
                />
              </div>
              <div className="column is-7">
                <label>Hook endpoint</label>
                <Input
                  value={postHook.endpoint}
                  onChange={(e) => {
                    setPostHook({
                      type: postHook.type,
                      endpoint: e.target.value,
                    });
                  }}
                  type="text"
                  hintText="Endpoint"
                />
                {settingsValidationErrors &&
                  (validationError = settingsValidationErrors?.find(
                    (e: any) => e.errorInfo === "postHook"
                  )) && (
                    <p className="validation-error">
                      {validationError.message}
                    </p>
                  )}
              </div>
            </div>
          </div>
          {resourceType && (
            <SetupSettings
              resourceType={resourceType}
              customerId={customerId}
              backupSettings={backupSettings}
              userRole={userRole}
              userRoleLoading={userRoleLoading}
              settingsValidationErrors={settingsValidationErrors}
              onChange={(settings) => setBackupSettings(settings)}
            />
          )}
          <ReactTooltip
            effect="solid"
            className="setup-tooltip"
            multiline={true}
          />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <small>* - required</small>
        </div>
      </div>
    </div>
  );
};

export default SetupForm;
