/**
 * Copyright 2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from "react";
import Button from "../../../commonComponents/button/Button";
import Segment from "../../../commonComponents/segment/Segment";
import {
  Frequency,
  GetBestAllowedPolicySettingsQuery,
  PolicySettingsInput,
  RetentionCondition,
} from "../../../generated/graphql";
import { CONDITIONS, FREQUENCIES } from "./policiesConstants";
import Pluralize from "react-pluralize";
import Input from "../../../commonComponents/input/Input";
import ValidationMessage from "../../../commonComponents/validationMessage/ValidationMessage";
import SimpleReactValidator from "simple-react-validator";
import GraphqlError, {
  GraphQLErrorCustom,
} from "../../../commonComponents/graphqlError/GraphqlError";
import AddPolicySettingsButton from "../../../commonComponents/policyButtons/AddPolicySettingsButton";
import RemovePolicySettingsButton from "../../../commonComponents/policyButtons/RemovePolicySettingsButton";
import ReactTooltip from "react-tooltip";
import { BackupPolicyTooltips } from "../../../utils/constants";

const dayInHours = 24;
const weekInHours = 7 * dayInHours;
const monthInHours = 30 * dayInHours;

type PolicyRpoFormProps = {
  policySettings: Array<PolicySettingsInput>;
  setPolicySettings: React.Dispatch<
    React.SetStateAction<Array<PolicySettingsInput>>
  >;
  data: GetBestAllowedPolicySettingsQuery;
  validator: SimpleReactValidator;
  graphqlValidationErrors: Array<GraphQLErrorCustom>;
  setRemovePrimarySnapsAfterResourceDeletion: React.Dispatch<boolean>;
  setRemoveReplicaSnapsAfterResourceDeletion: React.Dispatch<boolean>;
};

const convertMinimalRPO = (rpoInHours: number, frequency: Frequency) => {
  switch (frequency) {
    case Frequency.Days: {
      return (
        <Pluralize
          singular={"day"}
          count={Math.ceil(rpoInHours / dayInHours)}
        />
      );
    }
    case Frequency.Weeks: {
      return (
        <Pluralize
          singular={"week"}
          count={Math.ceil(rpoInHours / weekInHours)}
        />
      );
    }
    case Frequency.Months: {
      return (
        <Pluralize
          singular={"month"}
          count={Math.ceil(rpoInHours / monthInHours)}
        />
      );
    }
    default: {
      return <Pluralize singular={"hour"} count={rpoInHours} />;
    }
  }
};

const PolicyRpoForm = ({
  policySettings,
  setPolicySettings,
  data,
  validator,
  graphqlValidationErrors,
  setRemovePrimarySnapsAfterResourceDeletion,
  setRemoveReplicaSnapsAfterResourceDeletion,
}: PolicyRpoFormProps) => (
  <>
    {policySettings.map((setting: PolicySettingsInput, index: number) => (
      <div key={`policy-setting-${index}`}>
        <div className="columns">
          <div className="column">
            <div className="field">
              <label className="label">
                Snapshot frequency*
                <span
                  className="icon"
                  data-tip={BackupPolicyTooltips.snapshotFrequency}
                >
                  <i className="fa fa-question-circle" />
                </span>
              </label>
              <Segment tabs>
                {FREQUENCIES.map((button) => (
                  <Button
                    key={`frequency-${button.value}`}
                    onClick={() => {
                      const newSettings = [...policySettings];
                      newSettings[index].frequency = button.value;
                      setPolicySettings(newSettings);
                    }}
                    className={
                      setting.frequency === button.value ? "active" : ""
                    }
                    select
                    data-cy={`frequency-${button.label}-${index}`}
                  >
                    {button.label}
                  </Button>
                ))}
              </Segment>
              <GraphqlError
                errorInfo={`policySettings[${index}].frequency`}
                graphqlValidationErrors={graphqlValidationErrors}
              />
            </div>
            <div className="field">
              <label className="label">
                Value (min. RPO:{" "}
                {convertMinimalRPO(
                  data?.bestAllowedPolicySettings!.minRPOInHours,
                  setting.frequency
                )}
                )*
                <span
                  className="icon"
                  data-tip={BackupPolicyTooltips.snapshotFrequencyValue}
                >
                  <i className="fa fa-question-circle" />
                </span>
              </label>
              <Input
                value={setting.rpo}
                onChange={(e) => {
                  const newSettings = [...policySettings];
                  newSettings[index].rpo = parseInt(e.target.value);
                  setPolicySettings(newSettings);
                }}
                type="number"
                data-cy={`policy-rpo-${index}`}
              />
              <ValidationMessage>
                {validator.message("rpoInHours", setting.rpo, "required")}
              </ValidationMessage>
              <GraphqlError
                errorInfo={`policySettings[${index}].rpo`}
                graphqlValidationErrors={graphqlValidationErrors}
              />
            </div>
          </div>
          <div className="column">
            <div className="field">
              <label className="label">
                Primary snapshot condition*
                <span
                  className="icon"
                  data-tip={BackupPolicyTooltips.primarySnapshotCondition}
                >
                  <i className="fa fa-question-circle" />
                </span>
              </label>
              <Segment tabs>
                {CONDITIONS.map((button) => (
                  <Button
                    key={`primary-condition-${button.value}`}
                    onClick={() => {
                      const newSettings = [...policySettings];
                      newSettings[
                        index
                      ].retention.primarySnapshotsRetention.condition =
                        button.value;
                      setPolicySettings(newSettings);
                      if (button.value === RetentionCondition.Quantity) {
                        setRemovePrimarySnapsAfterResourceDeletion(false);
                      }
                    }}
                    className={
                      setting.retention.primarySnapshotsRetention.condition ===
                      button.value
                        ? "active"
                        : ""
                    }
                    select
                    data-cy={`primary-condition-${button.value}-${index}`}
                  >
                    {button.label}
                  </Button>
                ))}
              </Segment>
              <ValidationMessage>
                {validator.message(
                  "primary snapshot condition",
                  setting.retention.primarySnapshotsRetention.condition,
                  "required"
                )}
              </ValidationMessage>
              <GraphqlError
                errorInfo={`policySettings[${index}].primarySnapshotRetentionCondition`}
                graphqlValidationErrors={graphqlValidationErrors}
              />
            </div>
            <div className="field">
              <label className="label">
                Primary snapshot threshold (max.{" "}
                {setting.retention.primarySnapshotsRetention.condition ===
                RetentionCondition.Quantity ? (
                  <Pluralize
                    singular={"snapshot"}
                    count={
                      data?.bestAllowedPolicySettings
                        ?.maxPrimarySnapshotRetentionQuantity
                    }
                  />
                ) : (
                  <Pluralize
                    singular={"day"}
                    count={
                      data?.bestAllowedPolicySettings
                        ?.maxPrimarySnapshotRetentionInDays
                    }
                  />
                )}
                )*
                <span
                  className="icon"
                  data-tip={BackupPolicyTooltips.primarySnapshotThreshold}
                >
                  <i className="fa fa-question-circle" />
                </span>
              </label>
              <Input
                value={setting.retention.primarySnapshotsRetention.threshold}
                onChange={(e) => {
                  const newSettings = [...policySettings];
                  newSettings[
                    index
                  ].retention.primarySnapshotsRetention.threshold =
                    e.target.value;
                  setPolicySettings(newSettings);
                }}
                type="number"
                data-cy={`primary-threshold-${index}`}
              />
              <ValidationMessage>
                {validator.message(
                  "primarySnapshotRetentionThreshold",
                  setting.retention.primarySnapshotsRetention.threshold,
                  "required|numeric|min:0,num"
                )}
              </ValidationMessage>
              <GraphqlError
                errorInfo={`policySettings[${index}].primarySnapshotRetentionThreshold`}
                graphqlValidationErrors={graphqlValidationErrors}
              />
            </div>
          </div>
          <div className="column">
            <div className="field">
              <label className="label">
                Replica snapshot condition*
                <span
                  className="icon"
                  data-tip={BackupPolicyTooltips.replicaSnapshotCondition}
                >
                  <i className="fa fa-question-circle" />
                </span>
              </label>
              <Segment tabs>
                {CONDITIONS.map((button) => (
                  <Button
                    key={`replica-condition-${button.value}`}
                    onClick={() => {
                      const newSettings = [...policySettings];
                      newSettings[
                        index
                      ].retention.replicaSnapshotsRetention.condition =
                        button.value;
                      setPolicySettings(newSettings);
                      if (button.value === RetentionCondition.Quantity) {
                        setRemoveReplicaSnapsAfterResourceDeletion(false);
                      }
                    }}
                    className={
                      setting.retention.replicaSnapshotsRetention.condition ===
                      button.value
                        ? "active"
                        : ""
                    }
                    select
                    data-cy={`replica-condition-${button.value}-${index}`}
                  >
                    {button.label}
                  </Button>
                ))}
              </Segment>
              <ValidationMessage>
                {validator.message(
                  "replica snapshot condition",
                  setting.retention.replicaSnapshotsRetention.condition,
                  "required"
                )}
              </ValidationMessage>
              <GraphqlError
                errorInfo={`policySettings[${index}].replicaSnapshotRetentionCondition`}
                graphqlValidationErrors={graphqlValidationErrors}
              />
            </div>
            <div className="field">
              <label className="label">
                Replica snapshot threshold (max.{" "}
                {setting.retention.replicaSnapshotsRetention.condition ===
                RetentionCondition.Quantity ? (
                  <Pluralize
                    singular={"snapshot"}
                    count={
                      data?.bestAllowedPolicySettings
                        ?.maxReplicaSnapshotRetentionQuantity
                    }
                  />
                ) : (
                  <Pluralize
                    singular={"day"}
                    count={
                      data?.bestAllowedPolicySettings
                        ?.maxReplicaSnapshotRetentionInDays
                    }
                  />
                )}
                )*
                <span
                  className="icon"
                  data-tip={BackupPolicyTooltips.replicaSnapshotThreshold}
                >
                  <i className="fa fa-question-circle" />
                </span>
              </label>
              <Input
                value={setting.retention.replicaSnapshotsRetention.threshold}
                onChange={(e) => {
                  const newSettings = [...policySettings];
                  newSettings[
                    index
                  ].retention.replicaSnapshotsRetention.threshold =
                    e.target.value;
                  setPolicySettings(newSettings);
                }}
                type="number"
                data-cy={`replica-threshold-${index}`}
              />
              <ValidationMessage>
                {validator.message(
                  "replicaSnapshotRetentionThreshold",
                  setting.retention.replicaSnapshotsRetention.threshold,
                  "required|numeric|min:0,num"
                )}
              </ValidationMessage>
              <GraphqlError
                errorInfo={`policySettings[${index}].replicaSnapshotRetentionThreshold`}
                graphqlValidationErrors={graphqlValidationErrors}
              />
            </div>
            {policySettings.length > 1 && (
              <RemovePolicySettingsButton
                settings={policySettings}
                setter={setPolicySettings}
                index={index}
              />
            )}
          </div>
        </div>
        <hr />
      </div>
    ))}
    <AddPolicySettingsButton
      settings={policySettings}
      setter={setPolicySettings}
    />

    <ReactTooltip effect="solid" className="setup-tooltip" multiline={true} />
  </>
);

export default PolicyRpoForm;
