/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { Dispatch, SetStateAction, useState } from "react";
import { Modal } from "../../../commonComponents/modal/Modal";
import { useCreateUserMutation } from "../../../generated/graphql";
import Input from "../../../commonComponents/input/Input";
import { toast } from "react-toastify";
import { toastMessages } from "../../../utils/constants";
import Button from "../../../commonComponents/button/Button";
import SimpleReactValidator from "simple-react-validator";
import ValidationMessage from "../../../commonComponents/validationMessage/ValidationMessage";
import { Loading } from "../../../commonComponents/loading/Loading";
import { ROUTES } from "../../../routes/routes";
import { History } from "history";

interface UserModalProps {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  history: History;
}

const validator = new SimpleReactValidator();

const UserCreateUserModal = ({ setIsOpen, history }: UserModalProps) => {
  const [validateError, setValidateError] = useState(false);
  const [email, setEmail] = useState("");
  const [settingsValidationErrors, setSettingsValidationErrors] =
    useState<any>(null);

  let validationError;

  const [createUser, { loading }] = useCreateUserMutation({
    variables: {
      input: {
        email,
      },
    },
  });

  const finishCreatingUserHandler = () => {
    validateError && setValidateError(false);
    if (validator.allValid()) {
      createUser()
        .then((data) => {
          validator.hideMessages();
          setIsOpen(false);
          history.push(
            ROUTES.users.user.generate({
              email: data.data ? data.data?.addUser.email : "",
            })
          );
          toast.success(toastMessages.ACCOUNT_ADDED);
        })
        .catch((e) => {
          toast.error(toastMessages.ACCOUNT_ADDED_ERROR);
          setSettingsValidationErrors(e.graphQLErrors);
        });
    } else {
      validator.showMessages();
      setValidateError(true);
    }
  };

  return (
    <Modal onHide={() => setIsOpen(false)} title="Create a user">
      <div className="field">
        <label className="label">Email*</label>
        <Input
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          type="text"
        />
        {settingsValidationErrors &&
          (validationError = settingsValidationErrors?.find(
            (e: any) => e.errorInfo === "email"
          )) && <p className="validation-error">{validationError.message}</p>}
        <ValidationMessage>
          {validator.message("email", email, "required|email|max:255")}
        </ValidationMessage>
      </div>
      <Loading loading={loading}>
        <Button onClick={finishCreatingUserHandler}>Create</Button>
      </Loading>
    </Modal>
  );
};

export default UserCreateUserModal;
