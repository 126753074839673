/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { ReactNode } from "react";
import { Link, useLocation } from "react-router-dom";
import Popup from "react-popup";
import { ROUTES } from "../../../routes/routes";

interface Props {
  children: ReactNode;
}

export const DisasterRecoveryPageLayout: React.FC<Props> = ({ children }) => {
  const location = useLocation();
  const path = location.pathname;

  return (
    <div className="main wide">
      <div className="container is-fluid">
        <div className="box">
          <div className="tabs is-toggle">
            <ul>
              <li>
                <Link
                  className={`${
                    (path.includes(
                      ROUTES.disasterRecovery.plans.index.generate()
                    ) ||
                      path === ROUTES.disasterRecovery.index.generate()) &&
                    "active"
                  }`}
                  to={ROUTES.disasterRecovery.plans.index.generate()}
                >
                  Plans
                </Link>
              </li>
              <li>
                <Link
                  className={`${
                    path.includes(
                      ROUTES.disasterRecovery.records.index.generate()
                    ) && "active"
                  }`}
                  to={ROUTES.disasterRecovery.records.index.generate()}
                >
                  Records
                </Link>
              </li>
              <li>
                <Link
                  className={`${
                    path.includes(
                      ROUTES.disasterRecovery.templates.index.generate()
                    ) && "active"
                  }`}
                  to={ROUTES.disasterRecovery.templates.index.generate()}
                >
                  Templates
                </Link>
              </li>
            </ul>
          </div>
        </div>
        {children}
        <Popup />
      </div>
    </div>
  );
};
