/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ApolloQueryResult } from "apollo-boost";
import React, { useContext, useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Popup from "react-popup";
import { toast } from "react-toastify";
import ReactJson from "react-json-view";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";

import { Loading } from "../../../commonComponents/loading/Loading";
import { Icon } from "../../../commonComponents/icon/Icon";
import Button from "../../../commonComponents/button/Button";
import Pagination from "../../../commonComponents/pagination/Pagination";
import { CustomerContext } from "../../../context/customer";
import Table from "../../../commonComponents/table/Table";
import { Status, toastMessages } from "../../../utils/constants";
import Placeholder from "../../../commonComponents/table/Placeholder";
import { formatType, truncate } from "../../../utils/helpers";
import { ROUTES } from "../../../routes/routes";
import {
  Exact,
  FilterInput,
  GetResourcesQuery,
  ResourcesSearchCriteria,
  ResourceWithLastBackupStatus,
  SnapshotType,
  useGetLatestResourceSnapshotQuery,
  useGetUserRoleQuery,
  UserRole,
} from "../../../generated/graphql";
import { PAGE_SIZE, setLabelForStatus } from "./utils";
import ResourcesActionButtons from "./ResourcesActionButtons";
import ScanStatusInfoCard from "./ScanStatusInfoCard";

interface ResourcesProps {
  filters: Array<FilterInput>;
  resourcesData: any; // Cannot use ResourcesPage type, as we don't pull all the fields from API
  resourcesLoading: boolean;
  getResources: (
    variables?: Exact<{ searchCriteria: ResourcesSearchCriteria }> | undefined
  ) => Promise<ApolloQueryResult<GetResourcesQuery>>;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  showRemoved: boolean;
}

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }: any, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      // @ts-ignore
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);

const Resources: React.FC<ResourcesProps> = ({
  filters,
  resourcesData,
  resourcesLoading,
  getResources,
  currentPage,
  setCurrentPage,
  showRemoved,
}) => {
  const { customer } = useContext(CustomerContext);
  const customerId = customer?.id || "";

  const [selectedResourcesIds, setSelectedResourcesIds] = useState<
    Array<string>
  >([]);
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const [totalNumberOfPages, setTotalNumberOfPages] = useState<
    number | undefined
  >(0);
  const [totalNumberOfResources, setTotalNumberOfResources] = useState<
    number | undefined
  >(0);
  const [resources, setResources] = useState<
    Array<ResourceWithLastBackupStatus>
  >([]);

  const { data: userRoleData, loading: userRoleLoading } = useGetUserRoleQuery({
    variables: {
      customerId,
    },
  });

  const userRole = userRoleData?.userRole;

  const nextPage = () => {
    if (totalNumberOfPages && currentPage < totalNumberOfPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const getResourcesNumber = () => {
    if (resources) {
      if (resources.length < PAGE_SIZE && currentPage === 1) {
        return resources.length;
      }
      if (resources.length < PAGE_SIZE && currentPage === totalNumberOfPages) {
        return PAGE_SIZE * (totalNumberOfPages - 1) + resources.length;
      }
      return PAGE_SIZE * currentPage;
    }
    return 0;
  };

  useEffect(() => {
    setTotalNumberOfResources(resourcesData?.totalNumberOfResources);
    setTotalNumberOfPages(resourcesData?.totalNumberOfPages);
    setResources(
      resourcesData?.data?.map(
        (resourceWithBackupStatus: ResourceWithLastBackupStatus) => {
          return {
            ...resourceWithBackupStatus.resource,
            ...resourceWithBackupStatus.lastBackupStatus,
          };
        }
      )
    );
    setIsSelectedAll(false);
  }, [resourcesData]);

  useEffect(() => {
    setIsSelectedAll(false);
  }, [filters, currentPage]);

  useEffect(() => {
    if (selectedResourcesIds.length !== PAGE_SIZE && isSelectedAll) {
      setIsSelectedAll(false);
    }
  }, [selectedResourcesIds.length, isSelectedAll]);

  const { refetch: getLatestResourceSnapshot } =
    useGetLatestResourceSnapshotQuery();

  const showLatestSnapshot = async (
    resourceId: string,
    snapshotType: SnapshotType
  ) => {
    const data = await getLatestResourceSnapshot({
      searchCriteria: {
        customerId,
        resourceId,
        snapshotType,
      },
    });
    const snapshot = data?.data?.latestResourceSnapshot;
    const title =
      snapshotType !== SnapshotType.Replica
        ? `Latest Snapshot`
        : `Latest Snapshot Replica`;

    if (snapshot) {
      Popup.create({
        title: title,
        content: (
          <div className="box">
            <div>
              <label className="label">Snapshot Id:</label>
              <p>{snapshot.id}</p>
            </div>
            <div>
              <label className="label">Account Id:</label>
              <p>{snapshot.accountId}</p>
            </div>
            <div>
              <label className="label">Region:</label>
              <p>{snapshot.region}</p>
            </div>
            <div>
              <label className="label">Restore Data:</label>
              <CopyToClipboard
                text={snapshot.restoreData}
                onCopy={() => {
                  toast.success(toastMessages.RESTORE_DATA_COPIED);
                }}
              >
                <Button
                  outline
                  size="extra-small"
                  margin="0 .25rem 1rem .25rem"
                >
                  <i className="fa fa-copy" /> copy
                </Button>
              </CopyToClipboard>
              <a
                href={`data:text/json;charset=utf-8,${encodeURIComponent(
                  snapshot?.restoreData
                )}`}
                download={`${snapshot.id}.json`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  outline
                  size="extra-small"
                  margin="0 .25rem 1rem .25rem"
                >
                  <i className="fa fa-download" /> download
                </Button>
              </a>
              <ReactJson
                src={JSON.parse(snapshot.restoreData)}
                theme="monokai"
              />
            </div>
          </div>
        ),
        buttons: {
          right: [
            {
              text: "Close",
              className: "btn",
              action: async () => {
                Popup.close();
              },
            },
          ],
        },
      });
    } else {
      Popup.create({
        title: title,
        content: (
          <div className="box">
            {snapshotType === SnapshotType.Primary ? (
              <p>There is no latest snapshot.</p>
            ) : (
              <p>There is no latest snapshot replica.</p>
            )}
          </div>
        ),
        buttons: {
          right: [
            {
              text: "Close",
              className: "btn",
              action: async () => {
                Popup.close();
              },
            },
          ],
        },
      });
    }
  };

  const showErrorPopup = (message: string) => {
    Popup.create({
      title: "Backup status error",
      content: (
        <div className="box">
          <div>
            <label className="label">Error message:</label>
            <p>{message}</p>
          </div>
        </div>
      ),
      buttons: {
        right: [
          {
            text: "Close",
            className: "btn",
            action: async () => {
              Popup.close();
            },
          },
        ],
      },
    });
  };

  const columns = React.useMemo(
    () => [
      {
        id: "selection",
        Header: ({ getToggleAllRowsSelectedProps }: any) => {
          return (
            !showRemoved && (
              <div>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            )
          );
        },
        Cell: ({ row }: any): any => {
          return (
            !showRemoved && (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            )
          );
        },
      },
      {
        Header: "",
        accessor: "backupEnabled",
        Cell: ({ row }: any) => (
          <div style={{ padding: ".5rem" }}>
            {row.original.backupEnabled ? (
              <Icon
                height="1.75rem"
                image="STATUS_ENABLED"
                width="1.75rem"
                data-tip="Backup enabled"
              />
            ) : (
              <Icon
                height="1.75rem"
                image="STATUS_DISABLED"
                width="1.75rem"
                data-tip="Backup disabled"
              />
            )}
          </div>
        ),
      },
      {
        Header: "Last Backup Status",
        accessor: "lastBackupStatus",
        Cell: ({ row }: any) => {
          if (row.original.backupStatus === undefined) {
            return null;
          }
          const { className, label } = setLabelForStatus(
            row.original.backupStatus
          );
          return <span className={`tag ${className}`}>{label}</span>;
        },
      },
      {
        Header: "Resource ID",
        accessor: "id",
        Cell: ({ row }: any) => (
          <div>
            <div style={{ fontWeight: "bold" }}>
              {truncate(row.original.id)}
              <CopyToClipboard
                text={row.original.id}
                onCopy={() => {
                  toast.success(toastMessages.RESOURCE_ID_COPIED);
                }}
              >
                <Button outline size="extra-small" margin="0 .25rem">
                  <i className="fa fa-copy" /> copy
                </Button>
              </CopyToClipboard>
            </div>
            <div className="is-flex" style={{ fontSize: ".75rem" }}>
              <div style={{ marginRight: ".5rem" }}>
                <b>Type:</b> {row.original.type}
              </div>
              <div>
                <b>Category:</b> {row.original.category}
              </div>
            </div>
          </div>
        ),
      },
      {
        Header: "Provider",
        accessor: "cloudProvider",
        Cell: ({ row }: any) => (
          <div style={{ padding: ".5rem" }}>
            {row.original.cloudProvider && (
              <Icon
                height="1.75rem"
                image={row.original.cloudProvider}
                width="1.75rem"
              />
            )}
          </div>
        ),
      },
      {
        Header: "Account",
        accessor: "accountName",
      },
      {
        Header: "Policy",
        accessor: "backupPolicy.name",
        Cell: ({ row }: any) => {
          return (
            row.original.backupPolicy && (
              <Link
                to={ROUTES.settings.policies.details.generate({
                  id: row.original.backupPolicy.id,
                })}
              >
                {`[${formatType(row.original.backupPolicy.type)}] `}
                {row.original.backupPolicy.name}
              </Link>
            )
          );
        },
      },
      {
        Header: "Setup",
        accessor: "backupSetup.name",
        Cell: ({ row }: any) => {
          return (
            row.original.backupSetup && (
              <Link
                to={ROUTES.settings.setups.details.generate({
                  id: row.original.backupSetup.id,
                })}
              >
                {row.original.backupSetup.name}
              </Link>
            )
          );
        },
      },
      {
        id: "actions",
        Cell: ({ row }: any) => (
          <div>
            <div className="navbar-item has-dropdown is-hoverable is-pulled-right">
              <span className="icon">
                <i className="fa fa-ellipsis-v" />
              </span>
              <div className="navbar-dropdown is-right">
                <a
                  onClick={() =>
                    showLatestSnapshot(row.original.id, SnapshotType.Primary)
                  }
                  className="navbar-item"
                >
                  <span className="icon">
                    <i className="fa fa-camera" />
                  </span>
                  <span>Latest snapshot</span>
                </a>
                <a
                  onClick={() =>
                    showLatestSnapshot(row.original.id, SnapshotType.Replica)
                  }
                  className="navbar-item"
                >
                  <span className="icon">
                    <i className="fa fa-camera" />
                  </span>
                  <span>Latest snapshot replica</span>
                </a>
                {(row.original.backupStatus === Status.Failed ||
                  row.original.backupStatus === Status.FailedStopped) && (
                  <a
                    onClick={() =>
                      showErrorPopup(row.original.primaryBackupErrorMessage)
                    }
                    className="navbar-item"
                  >
                    <span className="icon">
                      <i className="fa fa-exclamation-circle" />
                    </span>
                    <span>Show error</span>
                  </a>
                )}
                {row.original.backupStatus === Status.ReplicationFailed && (
                  <a
                    onClick={() =>
                      showErrorPopup(row.original.replicationErrorMessage)
                    }
                    className="navbar-item"
                  >
                    <span className="icon">
                      <i className="fa fa-exclamation-circle" />
                    </span>
                    <span>Show replication error</span>
                  </a>
                )}
              </div>
            </div>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <>
      <ScanStatusInfoCard />
      <div className="box">
        <div className="columns is-vcentered border-bottom">
          <div className="column">
            <h1 className="title is-marginless is-5 is-capitalized">
              Resources list
            </h1>
          </div>
        </div>
        <Loading loading={resourcesLoading || userRoleLoading}>
          {resources && resources.length > 0 ? (
            <>
              {userRole !== UserRole.ReadOnly && !showRemoved && (
                <ResourcesActionButtons
                  filters={filters}
                  selectedResourcesIds={selectedResourcesIds}
                  totalNumberOfResources={totalNumberOfResources}
                  getResources={getResources}
                  isSelectedAll={isSelectedAll}
                  setIsSelectedAll={setIsSelectedAll}
                />
              )}
              <Table
                columns={columns}
                data={resources}
                onSelectedRowsChange={setSelectedResourcesIds}
                disableSortBy
              />
              <ReactTooltip effect="solid" />
              <Pagination
                totalItems={totalNumberOfResources || 0}
                totalPages={totalNumberOfPages || 0}
                currentPage={currentPage}
                showing={getResourcesNumber()}
                changePage={setCurrentPage}
                previousPage={previousPage}
                nextPage={nextPage}
              />
            </>
          ) : (
            <Placeholder message="No data found with current filters." />
          )}
        </Loading>
      </div>
    </>
  );
};

export default Resources;
