/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Loading } from "../../../commonComponents/loading/Loading";
import React, { useState } from "react";
import Popup from "react-popup";
import { ROUTES } from "../../../routes/routes";
import { toast } from "react-toastify";
import { toastMessages } from "../../../utils/constants";
import {
  useGrantSuperAdminMutation,
  User,
  useRemoveUserMutation,
  useRevokeSuperAdminMutation,
} from "../../../generated/graphql";
import { useHistory } from "react-router-dom";

interface UserBasicInformationProps {
  user: User;
  email: string;
  refetch: any;
  loading: boolean;
}

const UserBasicInformation: React.FC<UserBasicInformationProps> = ({
  user,
  email,
  refetch,
  loading,
}) => {
  const [customLoading, setCustomLoading] = useState<boolean>(false);

  const history = useHistory();

  const [removeUser] = useRemoveUserMutation({
    variables: {
      email,
    },
  });

  const [grantSuperAdmin] = useGrantSuperAdminMutation({
    variables: {
      input: email,
    },
  });

  const [revokeSuperAdmin] = useRevokeSuperAdminMutation({
    variables: {
      input: email,
    },
  });

  const onDeleteHandler = () => {
    Popup.create({
      onClose: () => {
        Popup.close();
      },
      title: `Delete account`,
      content: (
        <div className="box">
          <p>{`Do you want to remove ${email}`}</p>
        </div>
      ),
      buttons: {
        right: [
          {
            text: "Cancel",
            className: "btn",
            action: () => {
              Popup.close();
            },
          },
          {
            text: "Delete",
            className: "btn",
            action: async () => {
              try {
                await removeUser();
                history.push(ROUTES.users.index.generate());
                toast.success(toastMessages.ACCOUNT_REMOVED);
              } catch (e) {
                toast.error(toastMessages.ACCOUNT_REMOVAL_ERROR);
              }
              Popup.close();
            },
          },
        ],
      },
    });
  };

  const onUpdateTypeHandler = () => {
    Popup.create({
      onClose: () => {
        Popup.close();
      },
      title: `Change user type`,
      content: (
        <div className="box">
          {user?.superAdmin ? (
            <p>{`Do you want to revoke super admin from ${email}?`}</p>
          ) : (
            <p>{`Do you want to grant super admin to ${email}?`}</p>
          )}
        </div>
      ),
      buttons: {
        right: [
          {
            text: "Cancel",
            className: "btn",
            action: () => {
              Popup.close();
            },
          },
          {
            text: user?.superAdmin ? "Revoke" : "Grant",
            className: "btn",
            action: async () => {
              Popup.close();
              setCustomLoading(true);
              if (user?.superAdmin) {
                try {
                  await revokeSuperAdmin();
                  await refetch();
                  toast.success(toastMessages.SUPER_ADMIN_REVOKE);
                } catch (e) {
                  toast.error(toastMessages.SUPER_ADMIN_REVOKE_ERROR);
                } finally {
                  setCustomLoading(false);
                }
              } else {
                try {
                  await grantSuperAdmin();
                  await refetch();
                  toast.success(toastMessages.SUPER_ADMIN_GRANT);
                } catch (e) {
                  toast.error(toastMessages.SUPER_ADMIN_GRANT_ERROR);
                } finally {
                  setCustomLoading(false);
                }
              }
            },
          },
        ],
      },
    });
  };

  return (
    <div className="box is-clearfix">
      <div className="columns is-vcentered border-bottom">
        <div className="column is-7">
          <h1 className="title is-marginless is-5 is-capitalized">
            User details
          </h1>
        </div>
        <div className="column">
          <div className="navbar-item has-dropdown is-hoverable is-pulled-right">
            <span className="icon">
              <i className="fa fa-ellipsis-v" />
            </span>
            <div className="navbar-dropdown is-right">
              <a className="navbar-item" onClick={onDeleteHandler}>
                <span className="icon">
                  <i className="fa fa-trash" />
                </span>
                <span>Delete</span>
              </a>
              <a className="navbar-item" onClick={onUpdateTypeHandler}>
                <span className="icon">
                  <i className="fa fa-pencil" />
                </span>
                <span>
                  {user.superAdmin ? "Revoke super admin" : "Grant super admin"}
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="columns is-vcentered">
        <div className="column is-5">
          <strong className="is-desc-label">User email</strong>
          <br />
          <Loading loading={loading}>{user.email}</Loading>
        </div>
        <div className="column is-5">
          <strong className="is-desc-label">Type</strong>
          <br />
          <Loading loading={loading || customLoading}>
            {user.superAdmin ? "Super admin" : "User"}
          </Loading>
        </div>
      </div>
    </div>
  );
};

export default UserBasicInformation;
