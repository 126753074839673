/**
 * Copyright 2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ApolloError } from "apollo-client";
import {
  CustomerAccess,
  Maybe,
  useGrantCustomerRoleToUserMutation,
  User,
  useRevokeCustomerRoleFromUserMutation,
  UsersPage,
} from "../../../generated/graphql";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { History } from "history";
import { Loading } from "../../../commonComponents/loading/Loading";
import { Error } from "../../../commonComponents/error/error";
import Table from "../../../commonComponents/table/Table";
import Placeholder from "../../../commonComponents/table/Placeholder";
import Pagination from "../../../commonComponents/pagination/Pagination";
import CustomersUpdateRoleModal from "../../../shared/customers/CustomersUpdateRoleModal";
import { ObservableQuery } from "apollo-client/core/ObservableQuery";
import Button from "../../../commonComponents/button/Button";
import AddNewAccessModal from "../../../shared/customers/AddNewAccessModal";
import { UsersListColumns } from "./UsersList";

interface Props {
  usersData?: UsersPage;
  usersError?: ApolloError;
  usersLoading: boolean;
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  history: History;
  refetch: ObservableQuery["refetch"];
  customerId: string;
}

const PAGE_SIZE = 50;

const CustomerAccessesUsersTable: React.FC<Props> = ({
  usersData,
  usersError,
  usersLoading,
  currentPage,
  setCurrentPage,
  history,
  refetch,
  customerId,
}) => {
  const [users, setUsers] = useState<Maybe<Array<User>>>();
  const [createModalIsOpen, setCreateModalIsOpen] = useState<boolean>(false);
  const [updateModalIsOpen, setUpdateModalIsOpen] = useState<boolean>(false);
  const [customerData, setCustomerData] = useState<CustomerAccess>();
  const [email, setEmail] = useState<string>("");
  const [totalNumberOfUsers, setTotalNumberOfUsers] = useState<
    number | undefined
  >(0);
  const [totalNumberOfPages, setTotalNumberOfPages] = useState<
    number | undefined
  >(0);

  const nextPage = () => {
    if (totalNumberOfPages && currentPage < totalNumberOfPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const getUserDataNumber = () => {
    if (users && users.length < PAGE_SIZE && currentPage === 1) {
      return users.length;
    }
    if (
      users &&
      users?.length < PAGE_SIZE &&
      currentPage === totalNumberOfPages
    ) {
      return PAGE_SIZE * (totalNumberOfPages - 1) + users.length;
    }
    return PAGE_SIZE * currentPage;
  };

  useEffect(() => {
    setTotalNumberOfUsers(usersData?.totalNumberOfUsers);
    setTotalNumberOfPages(usersData?.totalNumberOfPages);
    setUsers(usersData?.data);
  }, [usersData]);

  return (
    <div className="box is-clearfix">
      <div className="columns is-vcentered border-bottom">
        <div className="column is-7">
          <h1 className="title is-marginless is-5 is-capitalized">Users</h1>
        </div>
        <div className="column">
          <div style={{ float: "right" }}>
            <Button onClick={() => setCreateModalIsOpen(true)}>
              Add new access
            </Button>
          </div>
        </div>
      </div>
      <Loading loading={usersLoading}>
        {usersError && <Error error={usersError} />}
        {users && users.length > 0 ? (
          <Table
            columns={UsersListColumns({
              customerId,
              setUpdateModalIsOpen,
              setEmail,
              setCustomerData,
              history,
            })}
            data={users}
            disableSortBy
          />
        ) : (
          <Placeholder message="No data found with current filters." />
        )}
        <Pagination
          totalItems={totalNumberOfUsers || 0}
          totalPages={totalNumberOfPages || 0}
          currentPage={currentPage}
          showing={getUserDataNumber()}
          changePage={setCurrentPage}
          previousPage={previousPage}
          nextPage={nextPage}
        />
      </Loading>
      {updateModalIsOpen && customerData && (
        <CustomersUpdateRoleModal
          setIsOpen={setUpdateModalIsOpen}
          customerData={customerData}
          identifier={email}
          type="email"
          mutationGrant={useGrantCustomerRoleToUserMutation}
          mutationRevoke={useRevokeCustomerRoleFromUserMutation}
          refetch={refetch}
        />
      )}
      {createModalIsOpen && (
        <AddNewAccessModal
          setIsOpen={setCreateModalIsOpen}
          customerId={customerId}
          type="email"
          mutationGrant={useGrantCustomerRoleToUserMutation}
          refetch={refetch}
        />
      )}
    </div>
  );
};

export default CustomerAccessesUsersTable;
