/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { RouteComponentProps, useLocation } from "react-router-dom";
import React, { useState } from "react";
import queryString from "query-string";
import RecordsList from "../components/RecordsList";
import RecordsReport from "../components/RecordsReport";

export const RecordsPage: React.FC<RouteComponentProps> = ({ history }) => {
  const location = useLocation();

  const pageFromUrl = (): number => {
    if (location.search === "") return 1;

    const params = queryString.parse(location.search);
    const page = params.page;
    if (page) {
      return +page;
    }

    return 1;
  };

  const [currentPage, setCurrentPage] = useState<number>(pageFromUrl);

  return (
    <>
      <RecordsReport />
      <div className="box">
        <div className="columns is-vcentered border-bottom">
          <div className="column">
            <h1 className="title is-marginless is-5 is-capitalized">Records</h1>
          </div>
        </div>
        <RecordsList
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          history={history}
        />
      </div>
    </>
  );
};
