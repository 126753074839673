/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import React from "react";

interface TextareaProps {
  value?: string;
  rows?: number;
  disabled?: boolean;
  onChange?: (event: any) => void;
}

const StyledTextarea = styled.textarea`
  width: 100%;
  resize: vertical;
  padding: calc(0.375em - 1px) calc(0.625em - 1px);
`;

const Textarea: React.FC<TextareaProps> = (props) => {
  return <StyledTextarea {...props} />;
};

export default Textarea;
