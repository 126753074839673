/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from "react";
import Segment from "../../../commonComponents/segment/Segment";
import SUPPORTED_CLOUD_PROVIDERS from "./constants";
import svgToMiniDataURI from "mini-svg-data-uri";
import Button from "../../../commonComponents/button/Button";
import { CloudProvider } from "../../../generated/graphql";

interface Props {
  selectedCloudProvider: CloudProvider;
  onSelect(provider: CloudProvider): void;
}

const ProviderSelector: React.FC<Props> = ({
  selectedCloudProvider,
  onSelect,
}) => {
  return (
    <Segment>
      {SUPPORTED_CLOUD_PROVIDERS.map((provider) => (
        <Button
          key={provider.name}
          onClick={() => {
            onSelect(provider.name);
          }}
          className={selectedCloudProvider === provider.name ? "active" : ""}
          select
        >
          <img
            src={svgToMiniDataURI(provider.icon)}
            className="provider-icon-size-selector"
            alt={provider.name}
          />
        </Button>
      ))}
    </Segment>
  );
};

export default ProviderSelector;
