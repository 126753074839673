/**
 * Copyright 2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { RouteComponentProps, useLocation } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import queryString from "query-string";
import { useGetApiKeysQuery } from "../../../generated/graphql";
import { unstable_batchedUpdates } from "react-dom";
import CustomerAccessesApiKeysTable from "./CustomerAccessesApiKeysTable";
import { CustomerContext } from "../../../context/customer";
import SearchFilter from "../../../commonComponents/filters/SearchFilter";
import {
  LABELS,
  PLACEHOLDERS,
} from "../../../commonComponents/filters/constants";

export const CustomerAccessesApiKeysPage: React.FC<RouteComponentProps> = ({
  history,
}) => {
  const location = useLocation();
  const filtersFromUrl = () => {
    if (location.search === "") return "";

    const params = queryString.parse(location.search);
    return JSON.parse(params.filters as string);
  };

  const customer = useContext(CustomerContext);
  const customerId = customer?.customer?.id || "";

  const pageFromUrl = (): number => {
    if (location.search === "") return 1;

    const params = queryString.parse(location.search);
    const pageUrl = params.page;
    if (pageUrl) {
      return +pageUrl;
    }

    return 1;
  };

  const [filters, setFilters] = useState<string>(filtersFromUrl);
  const [currentPage, setCurrentPage] = useState<number>(pageFromUrl);

  const { data, error, refetch, loading } = useGetApiKeysQuery({
    variables: {
      searchCriteria: {
        pageNumber: currentPage,
        customerId: customerId,
        ...(filters && { owner: filters }),
      },
    },
  });

  useEffect(() => {
    const search = `?filters=${JSON.stringify(filters)}&page=${currentPage}`;
    if (location.search === search) return;

    if (location.search === "") {
      history.replace({ search });
    } else {
      history.push({ search });
    }
  }, [currentPage, location.search, history]);

  useEffect(() => {
    unstable_batchedUpdates(() => {
      setCurrentPage(pageFromUrl());
    });
  }, [location]);

  const applyFilter = (newFilters: string) => {
    setFilters(newFilters);
    setCurrentPage(1);
  };

  return (
    <div>
      <SearchFilter
        filters={filters}
        applyFilter={applyFilter}
        label={LABELS.owner}
        placeholder={PLACEHOLDERS.owner}
      />
      <CustomerAccessesApiKeysTable
        apiKeysData={data?.apiKeys}
        apiKeysError={error}
        apiKeysLoading={loading}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        history={history}
        refetch={refetch}
        customerId={customerId}
      />
    </div>
  );
};
