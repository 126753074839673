/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { useContext } from "react";
import { useGetAccessibleCustomersQuery } from "../../generated/graphql";
import { CustomerContext } from "../../context/customer";
import { Loading } from "../loading/Loading";
import { Error } from "../error/error";
import { NordcloudLogo } from "../../assets/nordcloudLogo";
import { useAuth0 } from "../auth/Auth0";

const CustomerSelector: React.FC = ({ children }) => {
  const { data, error, loading } = useGetAccessibleCustomersQuery();
  const { accessibleCustomers } = data || { accessibleCustomers: null };
  const { logout } = useAuth0();

  const customer = useContext(CustomerContext);

  return (
    <Loading loading={loading}>
      {error ? (
        <section className="hero is-fullheight is-medium is-primary is-bold">
          <div className="hero-body">
            <div className="container">
              <div className="columns is-centered">
                <div className="column is-half ">
                  <NordcloudLogo fillColor="#000000" />
                  <h1 className="title is-primary is-size-5">
                    Please select customer
                  </h1>
                  <div className="box">
                    <Error error={error} canClose={false} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <>
          {!!customer.customer &&
          customer.customer.id &&
          accessibleCustomers ? (
            children
          ) : accessibleCustomers ? (
            <section className="hero is-fullheight is-medium is-primary is-bold">
              <div className="hero-body">
                <div className="container">
                  <div className="columns is-centered">
                    <div className="column is-half ">
                      <NordcloudLogo fillColor="#ffffff" />
                      <h1 className="title is-primary is-size-5">
                        Please select customer
                      </h1>
                      <div className="box">
                        <ul className="customer-selector">
                          {accessibleCustomers.map(({ name, id }) => (
                            <li className="is-size-5">
                              <a
                                href={`#{name}`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  customer.update({ id, name });
                                }}
                              >
                                {name}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <p className="is-size-7 customer-selector-bottom">
                        <a
                          href={`#{logout}`}
                          onClick={() => {
                            logout({
                              returnTo: window.location.origin,
                            });
                          }}
                        >
                          Logout
                        </a>{" "}
                        | Access requests -{" "}
                        <a href={"https://mc.nordcloud.com"}>
                          mc.nordcloud.com
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ) : (
            <></>
          )}
        </>
      )}
    </Loading>
  );
};

export default CustomerSelector;
