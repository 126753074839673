/**
 * Copyright 2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  MessagesSendingFrequency,
  NotificationSeverity,
} from "../../../generated/graphql";

enum activeColor {
  error = "#e74d3c",
  warning = "#f39c12",
  success = "#27ae5f",
  info = "#1e88ae",
}

export const typeButtons = [
  {
    type: NotificationSeverity.Error,
    label: "Error",
    activeColor: activeColor.error,
  },
  {
    type: NotificationSeverity.Warning,
    label: "Warning",
    activeColor: activeColor.warning,
  },
  {
    type: NotificationSeverity.Success,
    label: "Success",
    activeColor: activeColor.success,
  },
  {
    type: NotificationSeverity.Info,
    label: "Info",
    activeColor: activeColor.info,
  },
];

export const tabsButtons = [
  {
    value: MessagesSendingFrequency.OncePerEvent,
    label: "Once per event",
  },
  {
    value: MessagesSendingFrequency.OncePerDay,
    label: "Once per day",
  },
];
