/**
 * Copyright 2021-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { useEffect, useState } from "react";
import { useGetUsersQuery } from "../../../generated/graphql";
import { RouteComponentProps, useLocation } from "react-router-dom";
import queryString from "query-string";
import { unstable_batchedUpdates } from "react-dom";
import UsersTable from "./UsersTable";
import UserCreateUserModal from "../components/UserCreateUserModal";
import SearchFilter from "../../../commonComponents/filters/SearchFilter";
import {
  LABELS,
  PLACEHOLDERS,
} from "../../../commonComponents/filters/constants";

const UsersPage: React.FC<RouteComponentProps> = ({ history }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const filtersFromUrl = () => {
    if (location.search === "") return "";

    const params = queryString.parse(location.search);
    return JSON.parse(params.filters as string);
  };

  const pageFromUrl = (): number => {
    if (location.search === "") return 1;

    const params = queryString.parse(location.search);
    const pageUrl = params.page;
    if (pageUrl) {
      return +pageUrl;
    }

    return 1;
  };

  const [filters, setFilters] = useState<string>(filtersFromUrl);
  const [currentPage, setCurrentPage] = useState<number>(pageFromUrl);

  const { data, error, loading } = useGetUsersQuery({
    variables: {
      searchCriteria: {
        pageNumber: currentPage,
        ...(filters && { email: filters }),
      },
    },
  });

  useEffect(() => {
    const search = `?filters=${JSON.stringify(filters)}&page=${currentPage}`;
    if (location.search === search) return;

    if (location.search === "") {
      history.replace({ search });
    } else {
      history.push({ search });
    }
  }, [currentPage, location.search, history]);

  useEffect(() => {
    unstable_batchedUpdates(() => {
      setCurrentPage(pageFromUrl());
    });
  }, [location]);

  const applyFilter = (newFilters: string) => {
    setFilters(newFilters);
    setCurrentPage(1);
  };

  return (
    <div className="main wide">
      <div className="container is-fluid container-padding">
        <SearchFilter
          filters={filters}
          applyFilter={applyFilter}
          label={LABELS.email}
          placeholder={PLACEHOLDERS.email}
        />
        <UsersTable
          usersData={data?.users}
          usersError={error}
          usersLoading={loading}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          history={history}
          setCreateModalIsOpen={setIsOpen}
        />
        {modalIsOpen && (
          <UserCreateUserModal setIsOpen={setIsOpen} history={history} />
        )}
      </div>
    </div>
  );
};

export default UsersPage;
