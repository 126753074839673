/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { useContext, useEffect, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { RouteComponentProps, useLocation } from "react-router-dom";
import queryString from "query-string";

import Filters from "../../../commonComponents/filters/Filters";
import { CustomerContext } from "../../../context/customer";
import EventsTable from "./EventsTable";
import { PROGRESS_ID, START_DATE_ORDER_ID } from "../../../utils/constants";
import {
  BackupEventProgress,
  FilteredDataType,
  FilterInput,
  Maybe,
  Order,
  useGetBackupEventsQuery,
} from "../../../generated/graphql";

const EventsPage: React.FC<RouteComponentProps> = ({ history }) => {
  const { customer } = useContext(CustomerContext);
  const customerId = customer?.id || "";
  const location = useLocation();

  const filtersFromUrl = () => {
    if (location.search === "") return [];

    const params = queryString.parse(location.search);
    return JSON.parse(params.filters as string);
  };

  const pageFromUrl = (): number => {
    if (location.search === "") return 1;

    const params = queryString.parse(location.search);
    const pageFromUrl = params.page;
    if (pageFromUrl) {
      return +pageFromUrl;
    }

    return 1;
  };

  const [filters, setFilters] = useState<Array<FilterInput>>(filtersFromUrl);
  const [currentPage, setCurrentPage] = useState<number>(pageFromUrl);

  const progressFilter = filters.filter((f) => f.id === PROGRESS_ID);
  const setProgressFilter = (newFilter: string) => {
    let newFilters = filters.filter((f) => f.id !== PROGRESS_ID);

    if (newFilter) {
      newFilters = newFilters.concat([
        {
          id: PROGRESS_ID,
          values: [newFilter],
        },
      ]);
    }

    setFilters(newFilters);
  };
  const dateOrderFilter = filters.filter((f) => f.id === START_DATE_ORDER_ID);

  const { data, error, loading } = useGetBackupEventsQuery({
    variables: {
      searchCriteria: {
        customerId,
        filters,
        pageNumber: currentPage,
        startTimeOrder: dateOrderFilter[0]?.values[0] as Maybe<Order>,
      },
    },
  });

  const applyAllFilters = (newFilters: Array<FilterInput>) => {
    setFilters(newFilters);
    setCurrentPage(1);
  };

  useEffect(() => {
    const search = `?filters=${JSON.stringify(filters)}&page=${currentPage}`;
    if (location.search === search) return;

    if (location.search === "") {
      history.replace({ search });
    } else {
      history.push({ search });
    }
  }, [filters, currentPage]);

  useEffect(() => {
    unstable_batchedUpdates(() => {
      setFilters(filtersFromUrl());
      setCurrentPage(pageFromUrl());
    });
  }, [location]);

  return (
    <div className="main wide">
      <div className="container is-fluid container-padding">
        <Filters
          dataType={FilteredDataType.BackupEvents}
          applyFiltersHandler={applyAllFilters}
          appliedFilters={filters}
          size="is-4"
        />
        <EventsTable
          eventsData={data?.backupEvents}
          eventsError={error}
          eventsLoading={loading}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          progressFilter={
            progressFilter[0]?.values[0] as Maybe<BackupEventProgress>
          }
          setProgressFilter={setProgressFilter}
          history={history}
        />
      </div>
    </div>
  );
};

export default EventsPage;
