/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { useContext, useEffect, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { RouteComponentProps, useLocation } from "react-router-dom";
import queryString from "query-string";

import Resources from "../components/Resources";
import { CustomerContext } from "../../../context/customer";
import {
  FilteredDataType,
  FilterInput,
  useGetResourcesQuery,
} from "../../../generated/graphql";
import Filters from "../../../commonComponents/filters/Filters";

const ResourcesPage: React.FC<RouteComponentProps> = ({ history }) => {
  const location = useLocation();
  const { customer } = useContext(CustomerContext);
  const customerId = customer?.id || "";
  const showRemoved = location.pathname.split("/")[2] === "removed";

  const filtersFromUrl = () => {
    if (location.search === "") return [];

    const params = queryString.parse(location.search);
    return JSON.parse(params.filters as string);
  };

  const pageFromUrl = (): number => {
    if (location.search === "") return 1;

    const params = queryString.parse(location.search);
    const pageFromUrl = params.page;
    if (pageFromUrl) {
      return +pageFromUrl;
    }

    return 1;
  };

  const [filters, setFilters] = useState<Array<FilterInput>>(filtersFromUrl);
  const [currentPage, setCurrentPage] = useState<number>(pageFromUrl);

  const {
    data,
    loading,
    refetch: getResources,
  } = useGetResourcesQuery({
    variables: {
      searchCriteria: {
        customerId,
        filters,
        pageNumber: currentPage,
        showRemoved: showRemoved,
      },
    },
  });

  useEffect(() => {
    const search = `?filters=${JSON.stringify(filters)}&page=${currentPage}`;
    if (location.search === search) return;

    if (location.search === "") {
      history.replace({ search });
    } else {
      history.push({ search });
    }
  }, [filters, currentPage]);

  useEffect(() => {
    unstable_batchedUpdates(() => {
      setFilters(filtersFromUrl());
      setCurrentPage(pageFromUrl());
    });
  }, [location]);

  const onApplyFiltersClick = (newFilters: any) => {
    setFilters(newFilters);
    setCurrentPage(1);
  };

  return (
    <>
      <Filters
        dataType={FilteredDataType.Resources}
        applyFiltersHandler={onApplyFiltersClick}
        appliedFilters={filters ? filters : []}
        size="is-3"
      />
      <Resources
        filters={filters}
        resourcesData={data?.resources}
        resourcesLoading={loading}
        getResources={getResources}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        showRemoved={showRemoved}
      />
    </>
  );
};

export default ResourcesPage;
