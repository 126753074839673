/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { useContext, useState, useEffect } from "react";
import Segment from "../../../commonComponents/segment/Segment";
import { Link } from "react-router-dom";
import Button from "../../../commonComponents/button/Button";
import Input from "../../../commonComponents/input/Input";
import { Loading } from "../../../commonComponents/loading/Loading";
import { CustomerContext } from "../../../context/customer";
import Popup from "react-popup";
import { toast } from "react-toastify";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  useGetBackupTagNamesQuery,
  useProvideBackupTagNamesMutation,
  useGetUserProfileQuery,
  useGetBackupSetupsQuery,
  useGetBackupPoliciesQuery,
  useGetUserRoleQuery,
  UserRole,
} from "../../../generated/graphql";
import { confirmations, toastMessages } from "../../../utils/constants";
import { ROUTES } from "../../../routes/routes";

export const TagsPage: React.FC = () => {
  const { customer } = useContext(CustomerContext);
  const customerId = customer?.id || "";

  const [backupEnabledTags, setBackupEnabledTags] = useState<Array<string>>([]);
  const [policyTags, setPolicyTags] = useState<Array<string>>([]);
  const [setupsTags, setSetupsTags] = useState<Array<string>>([]);
  const [isTagBackupEnabled, setIsTagBackupEnabled] = useState<boolean>(false);

  const { data: setupsData } = useGetBackupSetupsQuery({
    variables: {
      searchCriteria: {
        customerId: customerId,
      },
    },
  });

  const { data: policiesData } = useGetBackupPoliciesQuery({
    variables: {
      customerId: customerId,
    },
  });

  const { data, loading } = useGetBackupTagNamesQuery({
    variables: {
      customerId,
    },
  });

  const { data: userProfileData, loading: userProfileLoading } =
    useGetUserProfileQuery({
      variables: {
        customerId: customerId,
      },
    });

  useEffect(() => {
    setIsTagBackupEnabled(
      !!userProfileData?.userProfile?.isBackupControlledByTags
    );
  }, [userProfileData]);

  const [provideBackupTagNames, { loading: provideBackupTagNamesLoading }] =
    useProvideBackupTagNamesMutation({
      variables: {
        input: {
          customerId: customerId,
          backupEnabledTagNames: backupEnabledTags,
          policyTagNames: policyTags,
          setupTagNames: setupsTags,
        },
      },
    });

  const onSaveBackupTags = () => {
    Popup.create({
      onClose: () => {
        Popup.close();
      },
      title: `Save tag-based configuration`,
      content: (
        <div className="box">
          <p>{confirmations.SAVE_TAG_BASED_CONFIGURATION}</p>
        </div>
      ),
      buttons: {
        right: [
          {
            text: "Cancel",
            className: "btn",
            action: () => {
              Popup.close();
            },
          },
          {
            text: "Save",
            className: "btn",
            action: async () => {
              provideBackupTagNames().then(() => {
                toast.success(toastMessages.TAG_BASED_CONFIGURATION_SAVED);
              });
              Popup.close();
            },
          },
        ],
      },
    });
  };

  const onShowPoliciesHandler = () => {
    Popup.create({
      onClose: () => {
        Popup.close();
      },
      title: `Available polices`,
      content: (
        <div className="box">
          {policiesData?.backupPolicies.map((policy) => (
            <div className="border-bottom" style={{ paddingBottom: "0.5rem" }}>
              <div>
                <strong>Policy name:</strong>{" "}
                <span onClick={() => Popup.close()}>
                  <Link
                    to={ROUTES.settings.policies.details.generate({
                      id: policy.id,
                    })}
                  >
                    {policy.name}
                  </Link>
                </span>
              </div>
              <div>
                <strong>Tag value:</strong> {policy.tagValue}
                <CopyToClipboard
                  text={policy.tagValue}
                  onCopy={() => {
                    toast.success(toastMessages.POLICY_TAG_VALUE_COPIED);
                  }}
                >
                  <Button outline size="extra-small" margin="0 .25rem">
                    <i className="fa fa-copy" /> copy
                  </Button>
                </CopyToClipboard>
              </div>
            </div>
          ))}
        </div>
      ),
      buttons: {
        right: [
          {
            text: "Ok",
            className: "btn",
            action: () => {
              Popup.close();
            },
          },
        ],
      },
    });
  };

  const onShowSetupsHandler = () => {
    Popup.create({
      onClose: () => {
        Popup.close();
      },
      title: `Available setups`,
      content: (
        <div className="box">
          {setupsData?.backupSetups.map((setup) => (
            <div className="border-bottom" style={{ paddingBottom: "0.5rem" }}>
              <div>
                <strong>Setup name:</strong>{" "}
                <span onClick={() => Popup.close()}>
                  <Link
                    to={ROUTES.settings.setups.details.generate({
                      id: setup.id,
                    })}
                  >
                    {setup.name}
                  </Link>
                </span>
              </div>
              <div>
                <strong>Resource type:</strong> {setup.resourceType}
              </div>
              <div>
                <strong>Tag value:</strong> {setup.tagValue}
                <CopyToClipboard
                  text={setup.tagValue}
                  onCopy={() => {
                    toast.success(toastMessages.SETUP_TAG_VALUE_COPIED);
                  }}
                >
                  <Button outline size="extra-small" margin="0 .25rem">
                    <i className="fa fa-copy" /> copy
                  </Button>
                </CopyToClipboard>
              </div>
            </div>
          ))}
        </div>
      ),
      buttons: {
        right: [
          {
            text: "Ok",
            className: "btn",
            action: () => {
              Popup.close();
            },
          },
        ],
      },
    });
  };

  const onShowBackupEnabledHandler = () => {
    Popup.create({
      onClose: () => {
        Popup.close();
      },
      title: `Available backup enabled values`,
      content: (
        <div className="box">
          <div>
            <div>
              <strong>value:</strong> true
              <CopyToClipboard
                text={true}
                onCopy={() => {
                  toast.success(toastMessages.BACKUP_ENABLED_VALUE_COPIED);
                }}
              >
                <Button outline size="extra-small" margin="0 .25rem">
                  <i className="fa fa-copy" /> copy
                </Button>
              </CopyToClipboard>
            </div>
          </div>
          <div style={{ padding: "0.5rem 0" }}>
            <div>
              <strong>value:</strong> false
              <CopyToClipboard
                text={false}
                onCopy={() => {
                  toast.success(toastMessages.BACKUP_ENABLED_VALUE_COPIED);
                }}
              >
                <Button outline size="extra-small" margin="0 .25rem">
                  <i className="fa fa-copy" /> copy
                </Button>
              </CopyToClipboard>
            </div>
          </div>
        </div>
      ),
      buttons: {
        right: [
          {
            text: "Ok",
            className: "btn",
            action: () => {
              Popup.close();
            },
          },
        ],
      },
    });
  };

  const AddTagButton: React.FC<any> = ({ tagsCollection, setter }) => (
    <Button
      outline
      disabled={
        tagsCollection.length > 0 &&
        tagsCollection[tagsCollection.length - 1] === ""
      }
      onClick={() => {
        setter([...tagsCollection, ""]);
      }}
    >
      Add new tag
    </Button>
  );

  const RemoveTagButton: React.FC<any> = ({
    tagsCollection,
    setter,
    index,
  }) => (
    <a
      className={"is-pulled-right"}
      onClick={() => {
        setter(
          tagsCollection.filter((tag: any) => tag !== tagsCollection[index])
        );
      }}
    >
      Remove tag
    </a>
  );

  useEffect(() => {
    if (data?.backupTagNames) {
      setBackupEnabledTags(data.backupTagNames.backupEnabledTagNames);
      setPolicyTags(data.backupTagNames.policyTagNames);
      setSetupsTags(data.backupTagNames.setupTagNames);
    }
  }, [data]);

  const { data: userRoleData, loading: userRoleLoading } = useGetUserRoleQuery({
    variables: {
      customerId: customerId,
    },
  });

  const { userRole } = userRoleData || {};

  return (
    <div className="box">
      <Loading
        loading={
          loading ||
          provideBackupTagNamesLoading ||
          userProfileLoading ||
          userRoleLoading
        }
      >
        {isTagBackupEnabled ? (
          userRole !== UserRole.ReadOnly ? (
            <>
              <div className="columns is-vcentered border-bottom">
                <div className="column">
                  <h1 className="title is-marginless is-5 is-capitalized">
                    Tag-based configuration
                  </h1>
                </div>
                <div className="column">
                  <Segment alignRight>
                    <Link to={ROUTES.settings.tags.index.generate()}>
                      <Button
                        className="is-pulled-right"
                        onClick={onSaveBackupTags}
                      >
                        Save
                      </Button>
                    </Link>
                  </Segment>
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <div>
                    <h1 className="is-6">
                      <strong>
                        Backup enabled tags ({backupEnabledTags.length})
                      </strong>
                    </h1>
                    <Button
                      size="extra-small"
                      onClick={onShowBackupEnabledHandler}
                    >
                      Show values
                    </Button>
                  </div>
                  {backupEnabledTags.map((tag: string, index: number) => (
                    <div className="field">
                      <Input
                        type="text"
                        value={tag}
                        key={index}
                        onChange={(event: any) => {
                          const BackupEnabledTags = [...backupEnabledTags];
                          BackupEnabledTags[index] = event.target.value;
                          setBackupEnabledTags(BackupEnabledTags);
                        }}
                      />
                      <RemoveTagButton
                        tagsCollection={backupEnabledTags}
                        setter={setBackupEnabledTags}
                        index={index}
                      />
                      <hr />
                    </div>
                  ))}
                  <AddTagButton
                    tagsCollection={backupEnabledTags}
                    setter={setBackupEnabledTags}
                  />
                </div>
                <div className="column">
                  <div>
                    <h1 className="is-6">
                      <strong>Policy tags ({policyTags.length})</strong>
                    </h1>
                    <Button size="extra-small" onClick={onShowPoliciesHandler}>
                      Show policies
                    </Button>
                  </div>

                  {policyTags.map((tag: string, index: number) => (
                    <div className="field">
                      <Input
                        type="text"
                        value={tag}
                        key={index}
                        onChange={(event: any) => {
                          const PolicyTags = [...policyTags];
                          PolicyTags[index] = event.target.value;
                          setPolicyTags(PolicyTags);
                        }}
                      />
                      <RemoveTagButton
                        tagsCollection={policyTags}
                        setter={setPolicyTags}
                        index={index}
                      />
                      <hr />
                    </div>
                  ))}
                  <AddTagButton
                    tagsCollection={policyTags}
                    setter={setPolicyTags}
                  />
                </div>
                <div className="column">
                  <div>
                    <h1 className="is-6">
                      <strong>Setup tags ({setupsTags.length})</strong>
                    </h1>
                    <Button size="extra-small" onClick={onShowSetupsHandler}>
                      Show setups
                    </Button>
                  </div>
                  {setupsTags.map((tag: string, index: number) => (
                    <div className="field">
                      <Input
                        type="text"
                        value={tag}
                        key={index}
                        onChange={(event: any) => {
                          const SetupsTags = [...setupsTags];
                          SetupsTags[index] = event.target.value;
                          setSetupsTags(SetupsTags);
                        }}
                      />
                      <RemoveTagButton
                        tagsCollection={setupsTags}
                        setter={setSetupsTags}
                        index={index}
                      />
                      <hr />
                    </div>
                  ))}
                  <AddTagButton
                    tagsCollection={setupsTags}
                    setter={setSetupsTags}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="columns is-vcentered border-bottom">
                <div className="column">
                  <h1 className="title is-marginless is-5 is-capitalized">
                    Tag-based configuration
                  </h1>
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <div>
                    <h1 className="is-6">
                      <strong>
                        Backup enabled tags ({backupEnabledTags.length})
                      </strong>
                    </h1>
                    <Button
                      size="extra-small"
                      onClick={onShowBackupEnabledHandler}
                    >
                      Show values
                    </Button>
                  </div>
                  {backupEnabledTags.map((tag: string, index: number) => (
                    <div className="field">
                      <Input key={index} type="text" value={tag} disabled />
                      <hr />
                    </div>
                  ))}
                </div>
                <div className="column">
                  <div>
                    <h1 className="is-6">
                      <strong>Policy tags ({policyTags.length})</strong>
                    </h1>
                    <Button size="extra-small" onClick={onShowPoliciesHandler}>
                      Show policies
                    </Button>
                  </div>

                  {policyTags.map((tag: string, index: number) => (
                    <div className="field">
                      <Input key={index} type="text" value={tag} disabled />
                      <hr />
                    </div>
                  ))}
                </div>
                <div className="column">
                  <div>
                    <h1 className="is-6">
                      <strong>Setup tags ({setupsTags.length})</strong>
                    </h1>
                    <Button size="extra-small" onClick={onShowSetupsHandler}>
                      Show setups
                    </Button>
                  </div>
                  {setupsTags.map((tag: string, index: number) => (
                    <div className="field">
                      <Input key={index} type="text" value={tag} disabled />
                      <hr />
                    </div>
                  ))}
                </div>
              </div>
            </>
          )
        ) : userRole !== UserRole.ReadOnly ? (
          <p>
            The tag-based configuration is disabled on this account. You can
            enable it in your{" "}
            <Link to={ROUTES.settings.profile.index.generate()}>profile</Link>.
          </p>
        ) : (
          <p>
            The tag-based configuration is <strong>disabled</strong> on this
            account.
          </p>
        )}
      </Loading>
    </div>
  );
};
