/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from "react";

type Size = string | number;

export interface SVGInterface<T extends Size, U> {
  style?: React.CSSProperties;
  children?: React.ReactNode;
  onClick?: () => void;
  inversed?: U;
  viewBox?: T;
  height?: T;
  width?: T;
  fill?: T;
}
const SVG = ({
  children,
  viewBox,
  onClick,
  height,
  width,
  style,
  fill,
}: Partial<SVGInterface<string, boolean>>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox={viewBox}
    height={height}
    width={width}
    fill={fill}
    style={style}
    onClick={onClick}
  >
    {children}
  </svg>
);

export default SVG;
