/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { useState, useContext } from "react";
import { CustomerContext } from "../../../context/customer";
import Button from "../../../commonComponents/button/Button";
import { Modal } from "../../../commonComponents/modal/Modal";
import SetupSelectsContainer from "../containers/SetupSelectsContainer";
import PolicySelect from "./PolicySelect";
import {
  useChangeBackupPolicyAndSetupsMutation,
  Maybe,
} from "../../../generated/graphql";
import ResourceSetupDetails from "./ResourceSetupDetails";
import { toast } from "react-toastify";
import { toastMessages } from "../../../utils/constants";
import SimpleReactValidator from "simple-react-validator";
import ValidationMessage from "../../../commonComponents/validationMessage/ValidationMessage";

interface Props {
  selectedIds: Maybe<Array<string>>;
  filters: any;
  totalResourcesNumber: number;
  onHide: () => void;
  refetchResources: () => void;
}
const validator = new SimpleReactValidator();

const ResourceSetupModal: React.FC<Props> = ({
  selectedIds,
  filters,
  totalResourcesNumber,
  onHide,
  refetchResources,
}) => {
  const customer = useContext(CustomerContext);
  const customerId = customer?.customer?.id || "";
  const [step, setStep] = useState(1);
  const [setupIds, setSetupIds] = useState<any>();
  const [policyId, setPolicyId] = useState("");
  const [validationError, setValidationError] = useState(false);

  const [changeBackupPolicyAndSetups] = useChangeBackupPolicyAndSetupsMutation({
    variables: {
      input: {
        customerId: customerId,
        filters: filters,
        resourceIDs: selectedIds,
        backupPolicyId: policyId,
        setupsIds: setupIds
          ? Object.keys(setupIds).map((key) => setupIds[key].value)
          : [],
      },
    },
  });

  const validateForm = () => {
    validationError && setValidationError(false);
    if (validator.allValid()) {
      setStep(2);
    } else {
      setValidationError(true);
      validator.showMessages();
      toast.error(toastMessages.VALIDATION_FAILED);
    }
  };

  return (
    <Modal onHide={() => onHide()} title="Apply policies and setups">
      {step === 1 ? (
        <>
          <div className="columns is-vcentered border-bottom">
            <div className="column is-7">
              <h2 className="title has-text-weight-bold is-size-6">
                Selected {selectedIds?.length || totalResourcesNumber}{" "}
                resources.
              </h2>
              <div className="field">
                <label className="label">Select Policy:</label>
                <PolicySelect
                  onChange={(policy) => setPolicyId(policy.value)}
                  value={policyId}
                />
                <ValidationMessage>
                  {validator.message("policyId", policyId, "required")}
                </ValidationMessage>
              </div>
              <div className="field">
                <label className="label">Select Setup(s)</label>
              </div>
              <SetupSelectsContainer
                filters={filters}
                selectedIds={selectedIds}
                setupIds={setupIds}
                onChange={(setupIds) => setSetupIds(setupIds)}
              />
            </div>
          </div>
          <div className="modal-buttons">
            <Button onClick={() => onHide()}>Cancel</Button>
            <Button onClick={() => validateForm()}>Continue</Button>
          </div>
        </>
      ) : (
        <ResourceSetupDetails
          onSubmit={() =>
            changeBackupPolicyAndSetups()
              .then(() => {
                toast.success(toastMessages.RESOURCE_UPDATE);
                onHide();
                refetchResources();
              })
              .catch((e: any): any =>
                toast.error(toastMessages.RESOURCE_UPDATE_ERROR)
              )
          }
          setupIds={
            setupIds
              ? Object.keys(setupIds).map((key) => setupIds[key].value)
              : []
          }
          setupApplyCount={
            setupIds
              ? Object.keys(setupIds)
                  .map((key) => setupIds[key].numberOfResources)
                  .reduce((prev, next) => prev + next)
              : 0
          }
          resourceIds={selectedIds}
          changedResources={
            selectedIds ? selectedIds.length : totalResourcesNumber
          }
          policyId={policyId}
          filters={filters}
          onBack={() => setStep(1)}
        />
      )}
    </Modal>
  );
};

export default ResourceSetupModal;
