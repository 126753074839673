/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { ReactNode } from "react";
import styled from "styled-components";

interface CardProps {
  children: ReactNode;
}

const StyledCard = styled.div<CardProps>`
  border: 1px solid #dadada;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin-bottom: 1rem;
  align-items: center;
  transition: all 0.2s ease-in;

  &:hover {
    border: 1px solid #333333;
    cursor: pointer;

    .title {
      text-decoration: underline;
    }
  }

  .icon {
    width: 10rem;
    height: 10rem;
  }

  .description {
    font-size: 0.75rem;
  }

  .title {
    font-weight: bold;
    font-size: 1.25rem;
  }
`;

const Card: React.FC<CardProps> = (props) => (
  <StyledCard {...props}>{props.children}</StyledCard>
);

export default Card;
