/**
 * Copyright 2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { useContext } from "react";
import InfoCard from "../../../commonComponents/card/InfoCard";
import Moment from "react-moment";
import { Loading } from "../../../commonComponents/loading/Loading";
import { ScanStatus, useGetScanStatusQuery } from "../../../generated/graphql";
import useInterval from "../../../utils/useInterval";
import { CustomerContext } from "../../../context/customer";

const ScanStatusInfoCard: React.FC = () => {
  const { customer } = useContext(CustomerContext);
  const customerId = customer?.id || "";

  const {
    data: scanStatusData,
    loading: scanStatusLoading,
    refetch: getStatus,
  } = useGetScanStatusQuery({
    variables: {
      customerId,
    },
  });

  useInterval(() => {
    getStatus();
  }, 30 * 1000);

  return (
    <Loading loading={scanStatusLoading}>
      {scanStatusData?.scanStatus?.status === ScanStatus.Pending && (
        <InfoCard variant="info">
          <h2 className="title">Scanning in progress.</h2>
          <p className="description">
            One of your accounts is being currently scanned for resources.
            Scanning started at{" "}
            <Moment format="HH:mm">
              {scanStatusData?.scanStatus?.createdAt || ""}
            </Moment>
            , last update{" "}
            <Moment format="HH:mm">
              {scanStatusData?.scanStatus?.updatedAt || ""}
            </Moment>
            . Please wait…
          </p>
        </InfoCard>
      )}
    </Loading>
  );
};

export default ScanStatusInfoCard;
