/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { ApiKeysPage, ApiKey, Maybe } from "../../generated/graphql";
import { Loading } from "../../commonComponents/loading/Loading";
import { Error } from "../../commonComponents/error/error";
import Table from "../../commonComponents/table/Table";
import Placeholder from "../../commonComponents/table/Placeholder";
import Pagination from "../../commonComponents/pagination/Pagination";
import { ApolloError } from "apollo-client";
import { Column } from "react-table";
import Button from "../../commonComponents/button/Button";

interface ApiKeysTableProps {
  apiKeysData?: ApiKeysPage;
  apiKeysError: ApolloError | undefined;
  apiKeysLoading: boolean;
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  title: string;
  columns: Column<{}>[];
  setCreateModalIsOpen?: Dispatch<SetStateAction<boolean>>;
}

const PAGE_SIZE = 50;

const ApiKeysTable: React.FC<ApiKeysTableProps> = ({
  apiKeysData,
  apiKeysLoading,
  apiKeysError,
  currentPage,
  setCurrentPage,
  title,
  columns,
  setCreateModalIsOpen,
}) => {
  const [apiKeys, setApiKeys] = useState<Maybe<Array<ApiKey>>>();
  const [totalNumberOfApiKeys, setTotalNumberOfApiKeys] = useState<
    number | undefined
  >(0);
  const [totalNumberOfPages, setTotalNumberOfPages] = useState<
    number | undefined
  >(0);

  const nextPage = () => {
    if (totalNumberOfPages && currentPage < totalNumberOfPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const getApiKeysNumber = () => {
    if (apiKeys && apiKeys.length < PAGE_SIZE && currentPage === 1) {
      return apiKeys.length;
    }
    if (
      apiKeys &&
      apiKeys?.length < PAGE_SIZE &&
      currentPage === totalNumberOfPages
    ) {
      return PAGE_SIZE * (totalNumberOfPages - 1) + apiKeys.length;
    }
    return PAGE_SIZE * currentPage;
  };
  useEffect(() => {
    setTotalNumberOfApiKeys(apiKeysData?.totalNumberOfApiKeys);
    setTotalNumberOfPages(apiKeysData?.totalNumberOfPages);
    setApiKeys(apiKeysData?.data);
  }, [apiKeysData]);

  return (
    <div className="box is-clearfix">
      <div className="columns is-vcentered border-bottom">
        <div className="column is-7">
          <h1 className="title is-marginless is-5 is-capitalized">{title}</h1>
        </div>
        {setCreateModalIsOpen && (
          <div className="column">
            <div style={{ float: "right" }}>
              <Button onClick={() => setCreateModalIsOpen(true)}>
                Create an API key
              </Button>
            </div>
          </div>
        )}
      </div>
      <Loading loading={apiKeysLoading}>
        {apiKeysError && <Error error={apiKeysError} />}
        {apiKeys && apiKeys.length > 0 ? (
          <Table columns={columns} data={apiKeys} disableSortBy />
        ) : (
          <Placeholder message="No data found with current filters." />
        )}
        <Pagination
          totalItems={totalNumberOfApiKeys || 0}
          totalPages={totalNumberOfPages || 0}
          currentPage={currentPage}
          showing={getApiKeysNumber()}
          changePage={setCurrentPage}
          previousPage={previousPage}
          nextPage={nextPage}
        />
      </Loading>
    </div>
  );
};
export default ApiKeysTable;
