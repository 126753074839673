/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { useContext } from "react";
import { CustomerContext } from "../../../context/customer";
import {
  useNumberOfResourcesWithChangedBackupSetupsQuery,
  useNumberOfResourcesWithChangedBackupPolicyQuery,
  Maybe,
} from "../../../generated/graphql";
import Button from "../../../commonComponents/button/Button";
import { Loading } from "../../../commonComponents/loading/Loading";

interface Props {
  onSubmit: () => void;
  setupIds: Array<string>;
  resourceIds: Maybe<Array<string>>;
  changedResources: number;
  policyId: string;
  filters: any;
  setupApplyCount: number;
  onBack: () => void;
}

const ResourceSetupDetails: React.FC<Props> = ({
  onSubmit,
  setupIds,
  resourceIds,
  changedResources,
  policyId,
  filters,
  setupApplyCount,
  onBack,
}) => {
  const { customer } = useContext(CustomerContext);
  const { data: setupsData, loading: setupLoading } =
    useNumberOfResourcesWithChangedBackupSetupsQuery({
      variables: {
        input: {
          customerId: customer && customer.id ? customer.id : "",
          filters: filters,
          resourceIDs: resourceIds,
          backupPolicyId: policyId,
          setupsIds: setupIds,
        },
      },
    });

  const { data: policyData, loading: policyLoading } =
    useNumberOfResourcesWithChangedBackupPolicyQuery({
      variables: {
        input: {
          customerId: customer && customer.id ? customer.id : "",
          filters: filters,
          resourceIDs: resourceIds,
          backupPolicyId: policyId,
          setupsIds: setupIds,
        },
      },
    });

  return (
    <>
      <div className="content border-bottom">
        <h4 className="is-size-6">You are making the following changes:</h4>
        <Loading loading={policyLoading || setupLoading}>
          <p>
            Applying policies to resources: <strong>{changedResources}</strong>{" "}
            <br />
            <span className="has-text-danger">
              Changing policies for resources:{" "}
              <strong className="has-text-danger">
                {policyData
                  ? policyData.numberOfResourcesWithChangedBackupPolicy
                  : 0}
              </strong>
            </span>
          </p>
          <p>
            Applying setups to resources:{" "}
            <strong>{setupApplyCount ? setupApplyCount : 0}</strong>
            <br />
            <span className="has-text-danger">
              Changing setups for resources:{" "}
              <strong className="has-text-danger">
                {setupsData
                  ? setupsData.numberOfResourcesWithChangedBackupSetups
                  : 0}
              </strong>
            </span>
          </p>
        </Loading>
      </div>
      <div className="modal-buttons">
        <Button onClick={() => onBack()}>Back</Button>
        <Button className="is-pulled-right" onClick={() => onSubmit()}>
          Confirm & Apply
        </Button>
      </div>
    </>
  );
};

export default ResourceSetupDetails;
