/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { useEffect, useState } from "react";
import { Loading } from "../../../commonComponents/loading/Loading";
import {
  ActivatingSetting,
  BackupSettingCandidate,
  BackupSettingInput,
  BackupSettingType,
  Maybe,
  useGetResourceTypeBackupSettingsQuery,
  UserRole,
} from "../../../generated/graphql";
import Input from "../../../commonComponents/input/Input";
import Select from "react-select";
import { Error } from "../../../commonComponents/error/error";
import ReactTooltip from "react-tooltip";

interface Props {
  resourceType: string;
  customerId: string;
  backupSettings: { [key: string]: BackupSettingInput };
  settingsValidationErrors?: any;
  userRole?: Maybe<UserRole>;
  userRoleLoading: boolean;
  onChange: (settings: any) => void;
}

const SetupSettings: React.FC<Props> = ({
  resourceType,
  customerId,
  backupSettings,
  settingsValidationErrors,
  userRole,
  userRoleLoading,
  onChange,
}) => {
  const { data, loading, error } = useGetResourceTypeBackupSettingsQuery({
    variables: {
      searchCriteria: {
        customerId: customerId,
        resourceType: resourceType,
      },
    },
  });

  const [resourceTypeBackupSettings, setResourceTypeBackupSettings] = useState<
    Array<BackupSettingCandidate>
  >([]);

  useEffect(() => {
    setResourceTypeBackupSettings(data?.resourceTypeBackupSettings || []);
  }, [data]);

  const shouldActivateField = (field: BackupSettingCandidate): boolean => {
    if (field.activatedBySettings?.length == 0) return true;

    let shouldBeActivated = true;
    field.activatedBySettings?.every((activatingSetting: ActivatingSetting) => {
      const activatingValue =
        backupSettings[activatingSetting.id]?.value || "false";
      shouldBeActivated =
        activatingSetting.activatingValues.includes(activatingValue);
      return shouldBeActivated;
    });

    return shouldBeActivated;
  };

  const isDeprecatedField = (field: BackupSettingCandidate): boolean => {
    return field.deprecated && backupSettings[field.id] === undefined;
  };

  return (
    <Loading loading={loading || userRoleLoading}>
      {!error &&
        resourceTypeBackupSettings &&
        resourceTypeBackupSettings.length > 0 && (
          <>
            <label className="label">Backup Settings</label>
            {resourceTypeBackupSettings.map((field, index) => {
              if (!shouldActivateField(field)) {
                delete backupSettings[field.id];
                onChange(backupSettings);
              }
              if (!shouldActivateField(field) || isDeprecatedField(field))
                return null;

              let validationError;

              return (
                <div className="field" key={index} data-cy={field.id}>
                  {field.type === BackupSettingType.Boolean ? (
                    <div className="field-checkbox">
                      <input
                        type="checkbox"
                        defaultChecked={
                          backupSettings[field.id]?.value === "true"
                        }
                        disabled={userRole === UserRole.ReadOnly}
                        onChange={(e) => {
                          onChange({
                            ...backupSettings,
                            [field.id]: { value: e.target.checked.toString() },
                          });
                        }}
                      />
                      <label className="label">
                        {field.label}
                        {field.isRequired && <>*</>}
                        {field.deprecated && <> (deprecated)</>}
                        <span className="icon" data-tip={field.description}>
                          <i className="fa fa-question-circle" />
                        </span>
                      </label>
                    </div>
                  ) : field.type === BackupSettingType.String &&
                    field.allowedValues ? (
                    <div className="field">
                      <label className="label">
                        {field.label}
                        {field.isRequired && <>*</>}
                        {field.deprecated && <> (deprecated)</>}
                        <span className="icon" data-tip={field.description}>
                          <i className="fa fa-question-circle" />
                        </span>
                      </label>
                      <Select
                        defaultValue={
                          backupSettings
                            ? field.allowedValues.find(
                                (v: any) =>
                                  v.value === backupSettings[field.id]?.value
                              )
                            : {}
                        }
                        disabled={userRole === UserRole.ReadOnly}
                        onChange={(e: any) =>
                          onChange({
                            ...backupSettings,
                            [field.id]: { value: e.value },
                          })
                        }
                        options={field.allowedValues}
                      />
                    </div>
                  ) : field.type === BackupSettingType.StringList ? (
                    <div className="field">
                      <label className="label">
                        {field.label}
                        {field.isRequired && <>*</>}
                        {field.deprecated && <> (deprecated)</>}
                        <span className="icon" data-tip={field.description}>
                          <i className="fa fa-question-circle" />
                        </span>
                      </label>
                      <Input
                        type="text"
                        defaultValue={
                          backupSettings[field.id]?.value
                            ? JSON.parse(backupSettings[field.id]?.value)
                            : null
                        }
                        disabled={userRole === UserRole.ReadOnly}
                        onBlur={(e) =>
                          onChange({
                            ...backupSettings,
                            [field.id]: {
                              value: JSON.stringify(
                                e.target.value
                                  .split(",")
                                  .map((value: string) => value.trim())
                              ),
                            },
                          })
                        }
                      />
                    </div>
                  ) : (
                    <div className="field">
                      <label className="label">
                        {field.label}
                        {field.isRequired && <>*</>}
                        {field.deprecated && <> (deprecated)</>}
                        <span className="icon" data-tip={field.description}>
                          <i className="fa fa-question-circle" />
                        </span>
                      </label>
                      <Input
                        type="text"
                        defaultValue={backupSettings[field.id]?.value || ""}
                        disabled={userRole === UserRole.ReadOnly}
                        onChange={(e) =>
                          onChange({
                            ...backupSettings,
                            [field.id]: { value: e.target.value },
                          })
                        }
                      />
                    </div>
                  )}
                  {settingsValidationErrors &&
                    (validationError = settingsValidationErrors.find(
                      (e: any) => e.errorInfo === field.id
                    )) && (
                      <p className="validation-error">
                        {validationError.message}
                      </p>
                    )}
                  <ReactTooltip
                    effect="solid"
                    className="setup-tooltip"
                    multiline={true}
                  />
                </div>
              );
            })}
          </>
        )}
      {error && <Error error={error} />}
    </Loading>
  );
};

export default SetupSettings;
